import { apiWithToken } from '../api';

const token = localStorage.getItem('@token');
let api = apiWithToken(token);
const resouceUrl = processo_id => `/processo/${processo_id}/retificacao`;

export async function load(processo_id) {
    try {
        const res = await api.get(resouceUrl(processo_id));
        return res.data;
    } catch (error) {
        throw error;
    }
}

export async function store(processo_id, payload) {
    try {
        const res = await api.post(resouceUrl(processo_id), payload);
        return res.data;
    } catch (error) {
        throw error;
    }
}

export async function updateModification(processo_id, retificacao_id, modificacao_id, payload) {
    try {
        const res = await api.post(
            resouceUrl(processo_id) + `/${retificacao_id}/modificacao/${modificacao_id}`, {
                ...payload,
                _method: 'PUT'
            }
        );
        return res.data;
    } catch (error) {
        throw error;
    }
}

export async function generatePDF(processo_id, retificacao_id) {
    try {
        const res = await api.get(
            resouceUrl(processo_id) + `/${retificacao_id}/generate-pdf`
        );
        return res.data;
    } catch (error) {
        throw error;
    }
}

export async function getPDFResource(processo_id, retificacao_id) {
    try {
        const res = await api.get(
            resouceUrl(processo_id) + `/${retificacao_id}/get-pdf-resource`
        );
        return res.data;
    } catch (error) {
        throw error;
    }
}
