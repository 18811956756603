import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import {
  storeDocumentoProcesso,
  loadDocumentoProcessos,
  changeStatus
} from '~/store/modules/documento_processo/action';
import FileInput from '~/components/FileInput';

const FormDocumento = ({ id, nome, obrigatorio, processo_id }) => {
  const dispatch = useDispatch();
  const { status_response, errors } = useSelector(
    ({ DocumentoProcessoReducer }) => DocumentoProcessoReducer
  );
  const [dataForm, setDataForm] = useState(
    { processo_id, nome, anexo: null }
    );

  useEffect(() => {
    if (status_response === 201) {
      Swal.fire(
        'Sucesso',
        'Arquivo adicionado com sucesso!',
        'success'
      );

      dispatch(
        loadDocumentoProcessos({
          fields: 'id,nome,processo_id,anexo',
          where: `processo_id:${processo_id}`,
          raw_list: true,
        })
      );

      dispatch(changeStatus(null));
    }
  },[status_response, processo_id, dispatch]);

  useEffect(() => {
    if (Object.keys(errors).length) {
      Swal.fire(
        'Error',
        'Error ao adicionar o anexo',
        'error'
      );

      console.log(errors);
    }
  }, [errors])

  const handleSubmit = (event) => {
    event.preventDefault();

    Swal.fire({
      title: 'Adicionar esse arquivo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não',
    }).then(async result => {
      if (result.isConfirmed) {
        let form = new FormData();

        for (let key in dataForm) {
          form.append(key, dataForm[key]);
        }

        await dispatch(storeDocumentoProcesso(form));
      }
    });
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <div className="input-group">
        <FileInput
          id={id}
          name="anexo"
          data={dataForm}
          dataError={false}
          setData={setDataForm}
          isRequired={obrigatorio}
        />
        <div className="input-group-append">
          <button className="btn btn-success">
            Enviar
          </button>
        </div>
      </div>
    </form>
  );
};

export default FormDocumento;
