import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { loadResponsavelTecnicos } from '~/store/modules/responsavel_tecnico/action';
import formatForMask from '~/utils/formatForMask';

const SelectAtividades = ({ setUserAnotacaoTecnica }) => {
  const dispatch = useDispatch();
  const { responsavel_tecnicos } = useSelector(
    ({ ResponsavelTecnicoReducer }) => ResponsavelTecnicoReducer
  );
  const [showMsg, setShowMsgs] = useState(false);
  const [options, setOptions] = useState([
    { value: null, label: '.::Selecione::.' },
  ]);

  const [optionSelected, setOptionSelected] = useState([
    { value: null, label: '.::Selecione::.' },
  ]);

  const formatLabel = (nomeUser, docType, docUser, maskLabel) => {
    return `${nomeUser} - ${docType}: ${formatForMask(docUser, maskLabel)}`;
  }

  useEffect(() => {
    dispatch(
      loadResponsavelTecnicos({
        raw_list: true,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    let optionSet = [{ value: null, label: '.::Selecione::.' }];
    setOptionSelected({ value: null, label: '.::Selecione::.' });
    let label = '';
    if (responsavel_tecnicos.data && responsavel_tecnicos.data[0]) {
      responsavel_tecnicos.data.forEach(({ id, responsavel_tecnico }) => {
        if(responsavel_tecnico.pessoa_tecnico_profissional.cpf)
        {
          label = formatLabel(responsavel_tecnico.nome, 'CPF', responsavel_tecnico.pessoa_tecnico_profissional.cpf, '###.###.###-##');
        }
        if(responsavel_tecnico.pessoa_tecnico_profissional.cnpj)
        {
          label = formatLabel(responsavel_tecnico.nome, 'CNPJ', responsavel_tecnico.pessoa_tecnico_profissional.cnpj, '##.###.###/####-##');
        }
        optionSet.push({
          value: id,
          label: label,
        });
      });
      setShowMsgs(false);
    } else {
      setShowMsgs(true);
    }

    setOptions(optionSet);
  }, [responsavel_tecnicos]);
  const handleOnChange = (e) => {
    setOptionSelected(e);
    setUserAnotacaoTecnica(e);
  };

  return (
    <>
      <Form.Group>
        <Form.Label>Selecione o Técnico Consultor</Form.Label>
        <Select
          isLoading={!responsavel_tecnicos.data}
          onChange={handleOnChange}
          options={options}
          value={optionSelected}
        />
      </Form.Group>

      {showMsg && (
        <div className="form-group">
          Caso não tenha um técnico vinculado selecione um{' '}
          <Link
            to={{
              pathname: '/tecnicos/responsavel_tecnico/create',
              state: { modal: true },
            }}
          >
            clicando aqui
          </Link>
        </div>
      )}
    </>
  );
};

export default SelectAtividades;
