import React from 'react';
import { Content } from '~/layouts/Dash/components';
import { useSelector } from 'react-redux';

const BasicContent = ({breadcrumb, children}) => {

  const { user_auth } = useSelector(({ LoginReducer }) => LoginReducer);


  const breadcrumbs = [
    {
      label: 'DashBoard',
      to: '/dashboard',
      active: false,
    },
    breadcrumb,
  ];
  return (
    <Content
      breadcrumbs={breadcrumbs}
      title={`Bem vindo ${user_auth.user ? user_auth.user.nome : null}! `}
    >
      {children}
    </Content>
  );
}

export default BasicContent;
