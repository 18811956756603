import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  header: {
    width: '100%',
    marginBottom: '20px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  table: {
    width: '100%',
    marginTop: '5px',
    borderTop: '0.5px solid black',
    borderBottom: '0.5px solid black'
  },
  tableRow: {
    width: '100%',
    flexDirection: 'row',
    borderTop: '0.5px solid black',
    borderRight: '1px solid black',
    borderBottom: '0.5px solid black'
  },
  tableCol: {
    fontSize: '11px',
    padding: '2px 5px',
    borderLeft: '1px solid black'
  },
  tableHeader: {
    backgroundColor: '#BFBFBF'
  }
});
