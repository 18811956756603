import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { loadMenus, addMenu } from '~/store/modules/tipo_user/action';

const MenuTree = () => {
  const dispatch = useDispatch();
  const { menus_tipo_user, errors } = useSelector(({ TipoUserReducer }) => TipoUserReducer);
  const [saved, setSaved] = useState(false);
  const [menusIds, setMenusIds] = useState([]);
  const [boxes, setBoxes] = useState([]);
  let history = useHistory();
  const { tipo_user_id } = useParams();



  useEffect(() => {
    let menus = [];
    if (menus_tipo_user[0]) {
      menus_tipo_user.forEach(({ id, checked }) => {
        if (checked) {
          menus.push(id);
        }
      });
      setBoxes(menus_tipo_user);
    }
    setMenusIds(menus);

  }, [menus_tipo_user]);


  useEffect(() => {
    if (errors.save) {
      setSaved(errors.save);
    }
  }, [errors]);

  useEffect(() => {
    if (saved) {
      Swal.fire('Sucesso', 'Menus atualizados com sucesso!', 'success').then(
        () => {
          dispatch(loadMenus(tipo_user_id));
          setSaved(false)
          history.replace();
        }
      );
    }
  }, [dispatch, saved, tipo_user_id, history]);


  const handleCheck = (event) => {
    let value =  event.target.value;
    if (event.target.checked) {
      setMenusIds([...menusIds, parseInt(value)]);
    } else {
      setMenusIds(menusIds.filter(item => parseInt(item) !== parseInt(value)));
    }
  };
  const handleSubmit = async (event)  =>  {
    event.preventDefault();

    await dispatch(addMenu(tipo_user_id, {'menu_id': menusIds}));
  };
  return (
    <div className="card">
      <div className="card-header">
        <h2 className="card-title">Menus Acessos</h2>
        <div className="card-tools">
          <button
            className="btn btn-success"
            form="form_update_menus"
            type="submit"
          >
            {' '}
            <i className="fa fa-sync" /> Atualizar
          </button>
        </div>
      </div>
      <div className="card-body">
        <form id="form_update_menus" onSubmit={handleSubmit}>
          <table className="table table-borderad table-sm table-striped table-hover">
            <thead>
              <tr>
                <td>Menu</td>
                <td>path</td>
                <td>Permitir</td>
              </tr>
            </thead>
            <tbody>
              {boxes &&
                boxes.map(
                  ({ id, icon, nome, menu_pai, path_name, checked }) =>
                    menu_pai ? (
                      <tr key={id}>
                        <th>
                          <i className={`fa fa-${icon}`} /> {nome}
                        </th>
                        <th>{path_name}</th>
                        <th>
                          <Form.Check
                            className="mb-3"
                            defaultChecked={checked}
                            id={`menu_id-${id}`}
                            label="Permitir"
                            name="menu_id[]"
                            onClick={handleCheck}
                            type="switch"
                            value={id}
                          />
                        </th>
                      </tr>
                    ) : (
                      <tr key={id}>
                        <td>
                          <ul>
                            <li>
                              <i className={`fa fa-${icon}`} /> {nome}
                            </li>
                          </ul>
                        </td>
                        <td>{path_name}</td>
                        <td>
                          <Form.Check
                            className="mb-3"
                            defaultChecked={checked}
                            id={`menu_id-${id}`}
                            label="Permitir"
                            name="menu_id[]"
                            onClick={handleCheck}
                            type="switch"
                            value={id}
                          />
                        </td>
                      </tr>
                    )
                )}
            </tbody>
          </table>
        </form>
      </div>
      <div className="card-footer clearfix" />
    </div>
  );
};

export default MenuTree;
