import { apiWithToken } from '../api';

const resouceUrl = '/ods/indicador';
const token = localStorage.getItem('@token');
let api = apiWithToken(token);

export const load_indicadores_ods = async (meta_id, params) => {
    return await api.get(`${resouceUrl}/indicador_meta/${meta_id}`, {
        params: { ...params}
    });
}
