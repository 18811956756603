import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Loading } from '~/components';
import { apiWithToken } from '~/services/api';
import Swal from 'sweetalert2';


const ExtratoPublicacaoAmbiental = ({ processo_id, type }) => {
    const token = localStorage.getItem('@token');
    const api = apiWithToken(token);
    const [show, setShow] = useState(false);
    const [document, setDocument] = useState(null);

    async function getDocument(type) {
        try {
            const response = await api.get(
                `/processos/${processo_id}/extrato-publicacao-ambiental?type=${type}`
            );
            setDocument(response.data);
            setShow(true);
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erro ao gerar o extrato de publicação ambiental!'
            });
        }
    }

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Extrato de Publicação Ambiental</h3>
                </div>
                <div className="card-body">
                    <button
                        className="btn btn-primary btn-block"
                        onClick={() => getDocument(type)}
                    >
                        <i className="fa fa-file-pdf"/>&nbsp;&nbsp;
                        {type}
                    </button>
                </div>
            </div>
            <Modal
                animation
                aria-labelledby="example-custom-modal-styling-title"
                dialogClassName="modal-xl"
                onHide={() => setShow(false)}
                show={show}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Extrato de Publicação Ambiental - {type}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {document ? (
                        <iframe
                            src={document}
                            style={{ width: '100%', height: '70vh' }}
                            title={`Extrato de Publicação Ambiental - ${type}`}
                        />
                    ) : (
                        <Loading />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-default"
                        onClick={() => setShow(false)}
                        type="button"
                    >
                        <i className="fa fa-window-close" /> Fechar
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ExtratoPublicacaoAmbiental;
