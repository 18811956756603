import * as actions from './actionType';

export const loadQuestaoAnalises = (params) => ({
  type: actions.LOAD_QUESTAO_ANALISES,
  params
});
export const getQuestaoAnalises = questao_analises => ({
  type: actions.QUESTAO_ANALISES_DATA,
  questao_analises,
});

export const getSelectQuestaoAnalise = questao_analises => ({
  type: actions.SELECT_QUESTAO_ANALISES,
  questao_analises,
});

export const storeQuestaoAnalise = questao_analise => ({
  type: actions.STORE_QUESTAO_ANALISE,
  questao_analise,

});
export const getQuestaoAnalise = questao_analise => ({
  type: actions.GET_QUESTAO_ANALISE,
  questao_analise,
});

export const removerQuestaoAnalise = (id) => ({
  type: actions.REMOVER_QUESTAO_ANALISE,
  id
});

export const showQuestaoAnalise = (id, params) => ({
  type: actions.SHOW_QUESTAO_ANALISE,
  id,
  params
});

export const updateQuestaoAnalise = (questao_analise, id) => ({
  type: actions.UPDATE_QUESTAO_ANALISE,
  questao_analise,
  id
});

export const ativarQuestaoAnalise = id => ({
  type: actions.ACTIVATE_QUESTAO_ANALISE,
  id,
});

export const getErros = errors => ({
  type: actions.QUESTAO_ANALISE_ERROR,
  errors,
});


export const changeStatus = status => ({
  type: actions.CHANGE_STATUS_CODE,
  status,
});

