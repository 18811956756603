import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FiEdit3 } from 'react-icons/fi';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router';
import { finalizarAnalise, showAnalise } from '~/store/modules/analise/action';
import { loadMeioAnalisesWithRespostas, loadMeioAnalisesWithRespostasApia } from '~/store/modules/meio_analise/action';

import { ModalAnalise, ModalResumo } from './components/';

const AnaliseTecImpacto = ({ processo_id, tipo_licenca_id, analise_id, analise_previa, onlyView }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  let [show, setShow] = useState(false);
  let [showResumo, setShowResumo] = useState(false);
  let [btnFinalizar, setBtnFinalizar] = useState(false);
  let [modalAnalise, setModalAnalise] = useState({
    tipo_meio_analise: undefined,
    show: false,
    analise_id,
    handleHide: () => false,
  });
  let [meioAnalises, setMeioAnalises] = useState();
  let [meio_analises, setMeio_analises] = useState();

  const { meio_analises_with_respostas, meio_analises_with_respostas_apia } = useSelector(
    ({ MeioAnaliseReducer }) => MeioAnaliseReducer
  );

  const { analise } = useSelector(({ AnaliseReducer }) => AnaliseReducer);

  useEffect(() => {

    if (tipo_licenca_id && analise_id && processo_id) {
      if (analise_previa) {
        dispatch(
          loadMeioAnalisesWithRespostasApia(tipo_licenca_id, analise_id)
        );

      } else {
        dispatch(
          loadMeioAnalisesWithRespostas(tipo_licenca_id, analise_id)
        );
      }

      dispatch(showAnalise(analise_id));
    }
  }, [tipo_licenca_id, analise_id, processo_id, analise_previa, dispatch]);


  useEffect(() => {
    let meio_analises_format = [];
    let meio_analise;
    if (analise_previa) {
      meio_analise = meio_analises_with_respostas_apia;

    } else {
      meio_analise = meio_analises_with_respostas;
    }

    if (meio_analise.data) {
      for (let meio of meio_analise.data) {
        let tipo_meio = [];
        let isOk = true;
        for (let tipo of meio.tipo_meio_analises) {
          let checked = true;
          if (analise_previa) {
            for (let { resposta_questao_analise_previas } of tipo.questao_analises) {
              if (!resposta_questao_analise_previas[0]) {
                checked = false;
                isOk = false;
                break;
              }
            }
          } else {
            for (let { resposta_questao_analise } of tipo.questao_analises) {
              if (!resposta_questao_analise[0]) {
                checked = false;
                isOk = false;
                break;
              }
            }
          }

          tipo_meio.push({
            id: tipo.id,
            nome: tipo.nome,
            checked,
          });
        }
        meio_analises_format.push({
          id: meio.id,
          nome: meio.nome,
          checked: isOk,
          tipo_meio_analises: tipo_meio,
        });
      }
      setMeioAnalises(meio_analises_format);
      setMeio_analises(meio_analise);
    }
  }, [meio_analises_with_respostas, meio_analises_with_respostas_apia, analise_previa]);

  useEffect(() => {
    let isOk = true;
    if (meioAnalises && meioAnalises[0]) {
      for (let { checked } of meioAnalises) {
        if (!checked) {
          isOk = false;
          break;
        }
      }
      if (analise && analise.concluida) {
        setBtnFinalizar(false);
      } else {
        setBtnFinalizar(isOk);
      }
    }
  }, [meioAnalises, analise]);

  const handleOpenAnalise = (tipo_meio_analise) => {
    setModalAnalise({
      tipo_meio_analise,
      show: true,
      analise_id,
      handleHide,
      analise_previa
    });
  };

  const handleHide = () => {
    setModalAnalise({
      tipo_meio_analise: undefined,
      show: false,
      analise_id,
      handleHide: () => false,
      analise_previa
    });
    if (analise_previa) {
      dispatch(
        loadMeioAnalisesWithRespostasApia(tipo_licenca_id, analise_id)
      );

    } else {
      dispatch(
        loadMeioAnalisesWithRespostas(tipo_licenca_id, analise_id)
      );
    }
  };

  const handleFinalizarAnalise = () => {
    Swal.fire({
      title: 'Finalizar Analise?',
      text: 'Deseja Finalizar a análise?!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.value) {
        try {
          await dispatch(finalizarAnalise(analise_id));
          await Swal.fire('Sucesso', 'Finalizado com sucesso!', 'success');
          await history.go();
          if (analise_previa) {
            dispatch(
              loadMeioAnalisesWithRespostasApia(tipo_licenca_id, analise_id)
            );
          } else {
            dispatch(
              loadMeioAnalisesWithRespostas(tipo_licenca_id, analise_id)
            );
          }
        } catch (error) {
          await Swal.fire('Erro', 'Erro ao Finalizar', 'error');
        }
      }
    });
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Análise {analise_previa ? 'Prévia' : 'Técnica'} de Impacto Ambiental</h3>
        </div>
        <div className="card-body">
          <button
            className={`btn ${
              !analise.concluida ? 'btn-success' : 'btn-primary'
            } btn-block`}
            onClick={() => setShow(true)}
          >
            <span
              className={`${
                !analise.concluida &&
                'badge badge-pill badge-danger  float-right'
              }`}
            >
              <FiEdit3 />
            </span>
            {!analise.concluida && <>Preencher </>}
            Análise {analise_previa ? 'Prévia' : 'Técnica'}  de Impacto Ambiental
          </button>
        </div>
      </div>
      <Modal
        animation
        aria-labelledby="example-custom-modal-styling-title"
        dialogClassName="modal-xl"
        onHide={() => setShow(false)}
        show={show}
      >
        <Modal.Header closeButton>
          <Modal.Title>Análise {analise_previa ? 'Prévia' : 'Técnica'}  de Impacto Ambiental</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Selecione o meio e preencha os campos a partir da vistoria previa e
            das informações apresentadas pelo empreendedor
          </p>

          <div className="card-body">
            <div className="row">
              {meioAnalises &&
                meioAnalises.map(
                  ({ id, nome, tipo_meio_analises, checked }) => (
                    <div
                      className="col-4 card"
                      key={id}
                    >
                      <div className="card-header">
                        <strong>{nome}</strong>

                        {checked ? (
                          <span className="float-right badge badge-success">
                            <i className="fa fa-check" />
                          </span>
                        ) : (
                          <span className="float-right badge badge-danger">
                            <i className="fa fa-window-close" />
                          </span>
                        )}
                      </div>
                      <div className="card-body">
                        {tipo_meio_analises.map((tipo_meio_analise) => (
                          <span key={tipo_meio_analise.id}>
                            {tipo_meio_analise.checked ? (
                              <>
                                <button
                                  className="btn btn-info btn-block m-2"
                                  onClick={() =>
                                    handleOpenAnalise(tipo_meio_analise)
                                  }
                                >
                                  {tipo_meio_analise.nome}
                                  <span className="float-right badge badge-success">
                                    <i className="fa fa-check" />
                                  </span>
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="btn btn-success btn-block m-2"
                                  onClick={() =>
                                    handleOpenAnalise(tipo_meio_analise)
                                  }
                                >
                                  {tipo_meio_analise.nome}
                                  <span className="float-right badge badge-danger">
                                    <i className="fa fa-window-close" />
                                  </span>
                                </button>
                              </>
                            )}
                          </span>
                        ))}
                      </div>
                    </div>
                  )
                )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-group">
            {btnFinalizar && !onlyView && (
              <button
                className="btn btn-success"
                onClick={handleFinalizarAnalise}
                type="button"
              >
                <i className="fa fa-check" /> Finalizar Análise
              </button>
            )}
            {analise.concluida && <>
              <button
                className="btn btn-success"
                onClick={()=>(setShowResumo(true))}
              >Resumo do Geral de Impacto Ambiental </button>
            </>}
            <button
              className="btn btn-default"
              onClick={() => setShow(false)}
              type="button"
            >
              <i className="fa fa-window-close" /> Fechar
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <ModalAnalise {...modalAnalise} />
      {meio_analises && meio_analises.data &&
      <ModalResumo
        analise_previa={analise_previa}
        handleHide={()=>(setShowResumo(false))}
        meio_analises={meio_analises.data}
        show={showResumo}
      />}

    </>
  );
};
export default AnaliseTecImpacto;
