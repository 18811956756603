import React from 'react';
import {Page, Text, View, Document} from '@react-pdf/renderer';
import styles from '../../../components/ProjetoGeralEmpreendimento/styles';
import { Header, Footer } from '~/components/PDF';
import InfoTable from '~/components/PDF/InfoTable'


export default function CertidaoPDF({
    process
}) {
    return (
        <Document title="CERTIDÃO DE PROCESSO AMBIENTAL">
            <Page size="A4" style={styles.page} wrap>
                <Header
                    logoEntity={process?.entidade?.logo_base64}
                    entityName={process?.entidade?.nome}
                    organName={process?.entidade?.nome_orgao_ambiental}
                    LogoCounty={process?.entidade?.logo_municipio_base64}
                    styles={styles}
                />
                <View style={{ marginTop: '-10px' }}>
                    <Text style={[styles.title, { textAlign: 'center' }]}>
                        CERTIDÃO DE PROCESSO AMBIENTAL - CPA
                    </Text>
                </View>
                <View style={styles.section}>
                    <InfoTable
                        process={process}
                    />
                </View>
                <View style={{ marginTop: '-10px' }}>
                    <Text style={[styles.title, { marginTop: '20px' }]}>
                        Resultado
                    </Text>
                    <Text style={[styles.subtitle]}>
                        O documento CPA tem validade de certificação de protocolo de processo ambiental.
                    </Text>
                </View>
                <Footer
                    logoSiblam={process?.entidade?.src_siblam_logo_base64}
                    styles={styles}
                />
            </Page>
        </Document>
    );
};
