import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { RetificacaoContext } from '../context';
import { generatePDF } from '~/services/Retificacao';
import Swal from 'sweetalert2';
import ModalAnexos from '../../../../../components/ModalAnexos';

const retificacaoStatusLabel = {
    'ANALISE': 'Em Análise',
    'APROVADA': 'Aprovada',
    'REPROVADA': 'Reprovada',
    'APROVADA_PARCIALMENTE': 'Aprovada Parcialmente'
};

const retificacaoBtnClass = {
    'ANALISE': 'btn-default',
    'APROVADA': 'btn-success',
    'REPROVADA': 'btn-danger',
    'APROVADA_PARCIALMENTE': 'btn-warning'
};

export default function List ({ authUser, handlePageChange, setSelectedRetificacaoIndex }) {
    const [showPDF, setShowPDF] = useState(0);
    const [gerandoPDF, setGerandoPDF] = useState(0);
    const [retificacoes, setRetificacoes] = useContext(RetificacaoContext);
    const { processo: { id: processo_id, user_id: processo_user_id, pessoa_responsavel_tecnico_id: processo_consultor_id } } = useSelector(({ ProcessoReducer }) => ProcessoReducer);

    async function handlePDFGeneration(retificacao_index) {
        try {
            setGerandoPDF(retificacoes[retificacao_index].id);

            const res = await generatePDF(processo_id, retificacoes[retificacao_index].id);

            let data = [...retificacoes];
            let retificacao = data[retificacao_index];

            retificacao.file_path = res.file_path;

            setRetificacoes(data);
            setGerandoPDF(0);
            setShowPDF(retificacao.id);
        } catch (error) {
            console.log(error);

            return Swal.fire('Erro', 'Não foi possível gerar o PDF', 'error').then(() => { setGerandoPDF(0); });
        }
    }

    return (
        <div className="table-responsive">
            {(authUser.id === processo_user_id || authUser.id === processo_consultor_id) && (
                <button
                    type="button"
                    className="btn btn-success"
                    style={{ marginBottom: '10px' }}
                    onClick={() => handlePageChange('FORM')}
                >
                    <i className="fas fa-plus">&nbsp;</i>
                    Nova Retificação
                </button>
            )}
            <table className="table table-hover table-striped">
                <thead>
                    <tr>
                        <th>Solicitante</th>
                        <th>Status</th>
                        <th>Data Solicitação</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {retificacoes.map((retificacao, index) => (
                        <tr key={`retificacao-${retificacao.id}`}>
                            <td>{retificacao.solicitante.nome}</td>
                            <td>
                                <span className={`btn btn-sm ${retificacaoBtnClass[retificacao.status]}`}>
                                    {retificacaoStatusLabel[retificacao.status]}
                                </span>
                            </td>
                            <td>{retificacao.created_at}</td>
                            <td>
                                <div className="btn-group">
                                    <button
                                        type="button"
                                        title="Modificações"
                                        className="btn btn-default"
                                        onClick={() => {
                                            handlePageChange('MODIFICACOES')
                                            setSelectedRetificacaoIndex(index)
                                        }}
                                    >
                                        <i className="fas fa-list"></i>
                                    </button>
                                    {retificacao.status !== 'ANALISE' && retificacao.status_processo_id === 7 && (
                                        <button
                                            type="button"
                                            title="Imprimir"
                                            className="btn btn-success"
                                            onClick={() => handlePDFGeneration(index)}
                                            disabled={gerandoPDF === retificacao.id}
                                        >
                                            {gerandoPDF === retificacao.id ? (
                                                <i className="fas fa-spinner fa-spin"></i>
                                            ) : (
                                                <i className="fas fa-print"></i>
                                            )}
                                        </button>
                                    )}
                                </div>

                                {showPDF === retificacao.id && (
                                    <ModalAnexos
                                        handleHide={() => setShowPDF(0)}
                                        show={showPDF === retificacao.id}
                                        title={`Retificação - ${retificacao.created_at}`}
                                        path={`${process.env.REACT_APP_API_URL}/processo/${processo_id}/retificacao/${retificacao.id}/get-pdf-resource`}
                                    />
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
