import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as TecnicoProfissional from '~/services/TecnicoProfissional';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadTecnicoProfissionaisWorker({params}) {
  try {
    const response = yield call(TecnicoProfissional.load, params);
    yield put(actions.getTecnicoProfissionais(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* loadMinhasApiasWorker({ params }) {
  try {
    const response = yield call(TecnicoProfissional.load_minhas_apias, params);
    yield put(actions.getMinhasApias(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* showTecnicoProfissionalWorker({id}) {
  try {
    const response = yield call(TecnicoProfissional.show, id);
    yield put(actions.getTecnicoProfissional(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updateTecnicoProfissionalWorker({ tecnico_profissional, id}) {
  try {
    const response = yield call(TecnicoProfissional.update, id, tecnico_profissional);
    yield put(actions.getTecnicoProfissional(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeTecnicoProfissionalWorker({tecnico_profissional}) {
  try {
    const response = yield call(TecnicoProfissional.store, tecnico_profissional);
    yield put(actions.getTecnicoProfissional(response));
    yield put(actions.getErros({save:true}));
    yield put(getErros({save:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* removerTecnicoProfissionalWorker({id}) {
  try {
    yield call(TecnicoProfissional.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherTecnicoProfissional() {
  yield takeLatest(actionType.LOAD_TECNICO_PROFISSIONAIS, loadTecnicoProfissionaisWorker);
  yield takeLatest(actionType.STORE_TECNICO_PROFISSIONAL, storeTecnicoProfissionalWorker);
  yield takeLatest(actionType.SHOW_TECNICO_PROFISSIONAL, showTecnicoProfissionalWorker);
  yield takeLatest(actionType.UPDATE_TECNICO_PROFISSIONAL, updateTecnicoProfissionalWorker);
  yield takeLatest(actionType.REMOVER_TECNICO_PROFISSIONAL, removerTecnicoProfissionalWorker);
  yield takeLatest(actionType.LOAD_MINHAS_APIAS, loadMinhasApiasWorker);
}

export default function* saga() {
  yield all([watcherTecnicoProfissional()]);
}
