import React from 'react';
import { Form } from 'react-bootstrap';

export default function FileInputCsv({ name, setData, data, dataError }) {

  const handleArquivo = (event) => {
    setData(event.target.files[0]);
  }

  return (
    <div className="custom-file">
      <Form.File
        custom
        id="formcheck-api-custom"
      >
        <Form.File.Input
          accept=".csv"
          isInvalid={typeof (dataError) === 'string'}
          name={name}
          onChange={handleArquivo}
          required
        />
        <Form.File.Label data-browse="Buscar">
          {data?.name ? data.name : 'Selecione o arquivo'}
        </Form.File.Label>
        <Form.Control.Feedback type="invalid">{dataError}</Form.Control.Feedback>
      </Form.File>
    </div>
  );
}
