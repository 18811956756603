import React from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const FormCad = ({ handleSubmit, schema, atividade, setAtividade, errors }) => {
  const handleChange = event => {
    const { name, value, files } = event.target;
    setAtividade({
      ...atividade,
      [name]: files ? files[0] : value,
    });
  };
  const { potencial_poluidores } = useSelector(({ PotencialPoluidorReducer }) => PotencialPoluidorReducer);

  return (
    <Form
      id="form_atividade"
      onSubmit={handleSubmit}
      schema={schema}
    >
      <div className="form-group">
        <label htmlFor="nome"> Nome </label>
        <Form.Control
          id="nome"
          isInvalid={!!errors.nome}
          name="nome"
          onChange={handleChange}
          placeholder="Nome da atividade"
          type="text"
          value={atividade.nome || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.nome}
        </Form.Control.Feedback>
      </div>

      <div className="form-group">
        <label htmlFor="codigo"> Código </label>
        <Form.Control
          id="codigo"
          isInvalid={!!errors.codigo}
          name="codigo"
          onChange={handleChange}
          placeholder="Código da atividade"
          type="text"
          value={atividade.codigo || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.codigo}
        </Form.Control.Feedback>
      </div>
      
      <div className="form-group">
        <label htmlFor="potencial_poluidor_id">Potencial poluidor</label>
        <Form.Control
          as="select"
          id="potencial_poluidor_id"
          isInvalid={!!errors.potencial_poluidor_id}
          name="potencial_poluidor_id"
          onChange={handleChange}
          type="number"
          value={atividade.potencial_poluidor_id}
        >
          {potencial_poluidores.data &&
            potencial_poluidores.data.map(potencial_poluidor => (
              <option
                key={potencial_poluidor.id}
                value={potencial_poluidor.id}
              >
                {potencial_poluidor.nome}
              </option>
            ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {errors.potencial_poluidor}
        </Form.Control.Feedback>
      </div>
    </Form>
  );
};

export default FormCad;
