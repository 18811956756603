export const LOAD_NOTA_TEC_AMBIENTALS = 'LOAD_NOTA_TEC_AMBIENTALS';
export const SELECT_NOTA_TEC_AMBIENTALS = 'SELECT_NOTA_TEC_AMBIENTALS';
export const NOTA_TEC_AMBIENTAL_ERROR = 'NOTA_TEC_AMBIENTAL_ERROR';
export const NOTA_TEC_AMBIENTAL_GET_ERRORS = 'NOTA_TEC_AMBIENTAL_GET_ERRORS';
export const NOTA_TEC_AMBIENTALS_DATA = 'NOTA_TEC_AMBIENTALS_DATA';
export const STORE_NOTA_TEC_AMBIENTAL = 'STORE_NOTA_TEC_AMBIENTAL';
export const GET_NOTA_TEC_AMBIENTAL = 'GET_NOTA_TEC_AMBIENTAL';
export const REMOVER_NOTA_TEC_AMBIENTAL = 'REMOVER_NOTA_TEC_AMBIENTAL';
export const SHOW_NOTA_TEC_AMBIENTAL = 'SHOW_NOTA_TEC_AMBIENTAL';
export const UPDATE_NOTA_TEC_AMBIENTAL = 'UPDATE_NOTA_TEC_AMBIENTAL';
