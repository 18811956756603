import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Switch, Route, Link, useLocation } from 'react-router-dom';
import { BasicContent } from '../components';
import { Paginate } from '~/components';
import { FaList } from 'react-icons/fa';
import { loadVistoriasEntidade } from '../../../store/modules/vistoria/action';
import formatDataHora from '~/utils/formatDataHora';
import SearchForm from '~/components/SearchForm';
// import Show from '../Show';
import Edit from '../Edit'

export default function List() {
  // let location = useLocation();
  // let modal = location.state && location.state.modal;
  const dispatch = useDispatch();
  const { vistorias, meta } = useSelector(({ VistoriaReducer }) => VistoriaReducer);
  const [params, setParams] = useState({
    with: `veiculo,motorista,processo.user,processo.user_avaliador`,
    order_by: 'id:desc',
  });

  useEffect(() => {
      dispatch(
        loadVistoriasEntidade(params)
      );
  }, [dispatch, params]);

  const breadcrumb = {
    label: 'Lista',
    to: '/vistorias',
    active: false,
  };
  
  return (
    <>
      {/* {modal && (
        <Switch>
          <Route children={<Show/>} exact path="/vistorias/:vistoria_id/detalhe"/>
        </Switch>
      )} */}
      <BasicContent breadcrumb={breadcrumb}>
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">
              <FaList />&nbsp;PROCESSOS
            </h3>
            <SearchForm
              load={loadVistoriasEntidade}
              initialParams={params}
              setInitialParams={setParams}
              searchInitialType="veiculo;modelo"
              searchInitialLabel="Modelo"
              options={[
                {value: 'veiculo;modelo', label: 'Modelo'},
                {value: 'veiculo;placa', label: 'Placa'},
                {value: 'processo.user;nome', label: 'Requerente'},
                {value: 'processo.user_avaliador;nome', label: 'Avaliador'},
                {value: 'data_saida', label: 'Data Saída'},
                {value: 'data_chegada', label: 'Data Chegada'}
              ]}
            />
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-hover table-striped table-bordered">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Processo</th>
                    <th>Requerente</th>
                    <th>Avaliador</th>
                    <th>Veículo</th>
                    <th>Motorista</th>
                    <th>Saída</th>
                    <th>Chegada</th>
                    <th>Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {vistorias.data.map(vistoria => (
                    <tr key={`vistoria-${vistoria.id}`}>
                      <td>{vistoria.id}</td>
                      <td>{vistoria.processo?.protocolo}</td>
                      <td>{vistoria.processo?.user?.nome}</td>
                      <td>{vistoria.processo?.user_avaliador?.nome}</td>
                      <td>{vistoria.veiculo?.modelo} - {vistoria.veiculo?.placa}</td>
                      <td>{vistoria.motorista?.nome}</td>
                      <td>{formatDataHora(vistoria.data_saida)}</td>
                      <td>{formatDataHora(vistoria.data_chegada)}</td>
                      <td>
                          <Edit
                            vistoria={vistoria}
                            />
                      </td>
                      {/* <td>
                        <Link
                          className="btn btn-info"
                          to={{
                            pathname: `/vistorias/${vistoria.id}/detalhe`,
                            state: { modal: true },
                          }}
                        >
                          <FaEye /> Detalhes
                        </Link>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card-footer clearfix">
            <Paginate
              load={() => loadVistoriasEntidade(params)}
              meta={meta}
            />
          </div>
        </div>
      </BasicContent>
    </>
  );
}
