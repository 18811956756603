import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { loadVeiculos } from '~/store/modules/veiculo/action';
import { loadMotoristas } from '~/store/modules/motorista/action';
import { storeVistoria } from '~/store/modules/vistoria/action';

const NovoAgendamento = ({ processo_id }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [messageError, setMessageError] = useState('');
  let [show, setShow] = useState(false);
  let [dataForm, setDataForm] = useState({
    veiculo_id: null,
    motorista_id: null,
    data_hora_saida: null,
    data_hora_chegada: null
  });

  const { veiculos } = useSelector(({ VeiculoReducer }) => VeiculoReducer);
  const { motoristas } = useSelector(({ MotoristaReducer }) => MotoristaReducer);
  const { errors } = useSelector(({ VistoriaReducer }) => VistoriaReducer);


  useEffect(()=> {
    dispatch(loadVeiculos({raw_list:true}))
    dispatch(loadMotoristas({raw_list:true}))
  }, [processo_id, dispatch]);

  useEffect(()=>{
    if(errors && errors.data){
      setMessageError(errors.data['data'].message);
    }

  }, [errors])

  const handleSubmit = async (event) => {
    event.preventDefault();
    await dispatch(storeVistoria({
        motorista_entidade_id: dataForm.motorista_id,
        veiculo_entidade_id: dataForm.veiculo_id,
        processo_id,
        data_saida: dataForm.data_hora_saida.toString().split('T')[0],
        data_chegada: dataForm.data_hora_chegada.toString().split('T')[0],
        hora_saida: dataForm.data_hora_saida.toString().split('T')[1],
        hora_chegada: dataForm.data_hora_chegada.toString().split('T')[1],

    }));
    await Swal.fire('Sucesso', 'Vistoria agendada com sucesso!', 'success');
    await history.go();
  };

  const handleChangeDataForm = (event) => {
    setDataForm({...dataForm, [event.target.name]: event.target.value });
  };

  return (
    <>
      <button
        className="btn btn-success"
        onClick={() => setShow(true)}
      >
        <i className="fa fa-plus" /> Novo Agendamento
      </button>

      <Modal
        animation
        aria-labelledby="example-custom-modal-styling-title"
        dialogClassName="modal-xl"
        onHide={() => setShow(false)}
        show={show}
      >
        <Modal.Header closeButton>
          <Modal.Title>AGENDAR VISTORIA TÉCNICA AMBIENTAL E MONITORAMENTO</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            id="form_agendar_vistoria"
            onSubmit={handleSubmit}
          >
            <div className="form-group">
              <label className="label">Veículo</label>
              <select
                className="form-control"
                name="veiculo_id"
                onChange={handleChangeDataForm}
                required="required"
                value={dataForm.veiculo_id || ''}
              >
                <option value="">.:: SELECIONE ::.</option>
                { veiculos && veiculos.data &&  veiculos.data.map((veiculo, index) => (<option
                  key={index}
                  value={veiculo.id}
                >{veiculo.modelo} {veiculo.ano_fabricacao}/{veiculo.ano_modelo} - {veiculo.placa} </option>))}
              </select>
            </div>

            <div className="form-group">
              <label className="label">Motorista</label>
              <select
                className="form-control"
                name="motorista_id"
                onChange={handleChangeDataForm}
                required="required"
                value={dataForm.motorista_id || ''}
              >
                <option value="">.:: SELECIONE ::.</option>
                { motoristas && motoristas.data &&  motoristas.data.map((motorista, index) => (<option
                  key={index}
                  value={motorista.id}
                >{motorista.nome} - {motorista.cpf} </option>))}
              </select>
            </div>
            <div className="row">
                <div className="col col-sm-6 form-group">
                    <label className='label'>Data/Hora saída</label>
                    <input
                        className="form-control"
                        type="datetime-local"
                        name='data_hora_saida'
                        required="required"
                        onChange={handleChangeDataForm}
                        value={dataForm.data_hora_saida || ''}
                    />
                </div>
                <div className="col col-sm-6 form-group">
                    <label className='label'>Data/Hora chegada</label>
                    <input
                        className="form-control"
                        type="datetime-local"
                        name='data_hora_chegada'
                        required="required"
                        onChange={handleChangeDataForm}
                        value={dataForm.data_hora_chegada || ''}
                    />
                </div>
              {/* <div className="form-group col-6">
                <label className="label">Data saída</label>
                <input
                  className="form-control"
                  name="data_saida"
                  onChange={handleChangeDataForm}
                  required="required"
                  type="date"
                  value={dataForm.data_saida || ''}
                />
              </div>
              <div className="form-group col-2">
                <label className="label">Hora saída</label>

                <select
                  className="form-control"
                  id="select_hora_saida"
                  name="hora_de_saida"
                  onChange={handleChangeDataForm}
                  required="required"
                  value={dataForm.hora_de_saida || ''}
                >
                  <option value="">.:: SELECIONE ::.</option>
                  {Array.from(Array(24)).map((_, i) => (<option
                    key={i}
                    value={i <10 ? `0${i}` : i}
                  >{i <10 ? `0${i}` : i }</option>))}
                </select>
              </div>
              <div className="form-group col-2">
                <label className="label">Minutos saída</label>
                <select
                  className="form-control"
                  id="select_minutos_saida"
                  name="minutos_de_saida"
                  onChange={handleChangeDataForm}
                  required="required"
                  value={dataForm.minutos_de_saida || ''}
                >
                  <option value="">.:: SELECIONE ::.</option>
                  {Array.from(Array(60)).map((_, i) => (<option
                    key={i}
                    value={i <10 ? `0${i}` : i}
                  >{i <10 ? `0${i}` : i }</option>))}
                </select>
              </div>
              <div className="form-group col-2">
                <label className="label">Segundos saída</label>
                <select
                  className="form-control"
                  id="select_segundos_saida"
                  name="segundos_de_saida"
                  onChange={handleChangeDataForm}
                  required="required"
                  value={dataForm.segundos_de_saida || ''}
                >
                  <option value="">.:: SELECIONE ::.</option>
                  {Array.from(Array(60)).map((_, i) => (<option
                    key={i}
                    value={i <10 ? `0${i}` : i}
                  >{i <10 ? `0${i}` : i }</option>))}
                </select>
              </div> */}
            </div>
            {/* <div className="row">
              <div className="form-group col-6">
                <label className="label">Data chegada</label>
                <input
                  className="form-control"
                  name="data_chegada"
                  onChange={handleChangeDataForm}
                  required="required"
                  type="date"
                  value={dataForm.data_chegada || ''}
                />
              </div>
              <div className="form-group col-2">
                <label className="label">Hora chegada</label>

                <select
                  className="form-control"
                  id="select_hora_chegada"
                  name="hora_de_chegada"
                  onChange={handleChangeDataForm}
                  required="required"
                  value={dataForm.hora_de_chegada || ''}
                >
                  <option value="">.:: SELECIONE ::.</option>
                  {Array.from(Array(24)).map((_, i) => (<option
                    key={i}
                    value={i <10 ? `0${i}` : i}
                  >{i <10 ? `0${i}` : i }</option>))}
                </select>
              </div>
              <div className="form-group col-2">
                <label className="label">Minutos chegada</label>
                <select
                  className="form-control"
                  id="select_minutos_chegada"
                  name="minutos_de_chegada"
                  onChange={handleChangeDataForm}
                  required="required"
                  value={dataForm.minutos_de_chegada || ''}
                >
                  <option value="">.:: SELECIONE ::.</option>
                  {Array.from(Array(60)).map((_, i) => (<option
                    key={i}
                    value={i <10 ? `0${i}` : i}
                  >{i <10 ? `0${i}` : i }</option>))}
                </select>
              </div>
              <div className="form-group col-2">
                <label className="label">Segundos chegada</label>
                <select
                  className="form-control"
                  id="select_segundos_chegada"
                  name="segundos_de_chegada"
                  onChange={handleChangeDataForm}
                  required="required"
                  value={dataForm.segundos_de_chegada || ''}
                >
                  <option value="">.:: SELECIONE ::.</option>
                  {Array.from(Array(60)).map((_, i) => (<option
                    key={i}
                    value={i <10 ? `0${i}` : i}
                  >{i <10 ? `0${i}` : i }</option>))}
                </select>
              </div>
            </div> */}
            {messageError ?
              <div
                className="alert alert-danger"
                role="alert"
              >
                {messageError}</div> : ''}
          </form>

        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success"
            form="form_agendar_vistoria"
            type="submit"
          >
            <i className="fa fa-save"/> Salvar
          </button>
          <button
            className="btn btn-default"
            onClick={() => setShow(false)}
            type="button"
          >
            <i className="fa fa-window-close" /> Fechar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default NovoAgendamento;
