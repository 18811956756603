export const LOAD_OBSERVACOES = 'LOAD_OBSERVACOES';
export const LOAD_SELECT_OBSERVACOES = 'LOAD_SELECT_OBSERVACOES';
export const SELECT_OBSERVACOES = 'SELECT_OBSERVACOES';
export const OBSERVACAO_ERROR = 'OBSERVACAO_ERROR';
export const OBSERVACAO_GET_ERRORS = 'OBSERVACAO_GET_ERRORS';
export const OBSERVACOES_DATA = 'OBSERVACOES_DATA';
export const STORE_OBSERVACAO = 'STORE_OBSERVACAO';
export const GET_OBSERVACAO = 'GET_OBSERVACAO';
export const REMOVER_OBSERVACAO = 'REMOVER_OBSERVACAO';
export const SHOW_OBSERVACAO = 'SHOW_OBSERVACAO';
export const UPDATE_OBSERVACAO = 'UPDATE_OBSERVACAO';
