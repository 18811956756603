import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa';
import Content from '~/layouts/Dash/components/Content';
import { CheckList } from './components';


const CheckListDocs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const breadcrumbs = [
    {
      label: 'Processos',
      to: '/processos',
      active: false,
    }, {
      label: 'Lista',
      to: '/processos',
      active: true,
    }
  ];
  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="NOVO PROCESSO"
    >
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Passo 2 -   Processo  xxxxxxxxxxxxxxx </h3>
          <div className="card-tools">

            <Link
              className="btn btn-light"
              to="/processos"
            >
              <FaArrowAltCircleLeft /> Cancelar
            </Link>

          </div>
        </div>
        <div className="card-body table-responsive">
          <table className="table table-striped table-hover">
            <tbody>
              <tr>
                <th>Local</th>
                <td> Santana - AP</td>
              </tr>
              <tr>
                <th>Tipo de licença ambiental</th>
                <td> 1. Licença Prévia - LP </td>
              </tr>
              <tr>
                <th>Técnico Consultor</th>
                <td> Vitor Hugo da Silva </td>
              </tr>
            </tbody>
          </table>
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th colSpan="4">Atividades Selecionadas</th>
              </tr>
              <tr>
                <th>Código</th>
                <th>Atividade</th>
                <th>Termo de Referência</th>
                <th>Tipo de licença</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>0111-3/02</td>
                <td>Cultivo de milho</td>
                <td>TdR. 0111-3/02</td>
                <td>1. Licença Prévia - LP[i]</td>
              </tr>
              <tr>
                <td>1610-2/01</td>
                <td>Serraria com desdobramento de madeira</td>
                <td>TdR. 1610-2/01</td>
                <td>1. Licença Prévia - LP[i]</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <hr />
      <div className="card">
        <div className="card-header">DOCUMENOS ANEXAR</div>
        <div className="card-body">
          <CheckList />
        </div>
        <div className="card-footer">
          <div className="form-group">
            <Link
              className="btn btn-light"
              to="/processos/create/pass_5"
            >
              <FaArrowAltCircleLeft /> Voltar
            </Link>
            <Link
              className="btn btn-success"
              to="/processos/apia"
            >
              <FaArrowAltCircleRight /> AVALIAÇÃO PRÉVIA DE IMPACTO AMBIENTAL - APIA
            </Link>
          </div>
        </div>
      </div>
    </Content>
  );
};
export default CheckListDocs;
