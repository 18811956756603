import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import { Content } from '~/layouts/Dash/components';

const Reports = () => {
    const breadcrumbs = [
        {
            label: 'Relatórios',
            to: '/relatorios',
            active: true,
        }
    ];

    return (
        <Content title="RELATÓRIOS" breadcrumbs={breadcrumbs}>
            <Row>
                <Col sm={12} md={6} lg={3}>
                    <Card>
                        <Card.Body>
                            <Link to="/relatorios/relatorio-gestao-licenciamento-ambiental" className="btn btn-primary w-100">
                                Relatório de Gestão do Licenciamento Ambiental
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={3}>
                    <Card>
                        <Card.Body>
                            <Link to="/relatorios/relatorio-tecnico-licenciamento-ambiental" className="btn btn-primary w-100">
                                Relatório Técnico do Licenciamento Ambiental
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={3}>
                    <Card>
                        <Card.Body>
                            <Link to="/relatorios/relatorio-arrecadacao-financeira-ambiental" className="btn btn-primary w-100">
                                Relatório de Arrecadação Financeira Ambiental
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={3}>
                    <Card>
                        <Card.Body>
                            <Link to="/relatorios/relatorio-producao-licenciamento-ambiental" className="btn btn-primary w-100">
                                Relatório de Produção do Licenciamento Ambiental
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={3}>
                    <Card>
                        <Card.Body>
                            <Link to="/relatorios/relatorio-cadastro-consultores-ambientais" className="btn btn-primary w-100">
                                Relatório de Cadastro de Consultores Ambientais
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={3}>
                    <Card>
                        <Card.Body>
                            <Link to="/relatorios/relatorio-ambiental-desenvolvimento-economico" className="btn btn-primary w-100">
                                Relatório Ambiental de Desenvolvimento Econômico
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Content>
    );
}

export default Reports;
