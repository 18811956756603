export const initialData = {
  tipo_user_id: 5,
  nome: '',
  cpf_cnpj: '',
  rg: '',
  uf_rg_id: 1,
  data_nasc: '',
  cep: '',
  logradouro: '',
  numero_endereco: '',
  bairro: '',
  cidade: '',
  uf_endereco_id: 1,
  email: '',
  youtube: '',
  facebook: '',
  linkdin: '',
  twiter: '',
  telefone: '',
  numero_whatsapp: '',
  formacao: '',
  formacao_2: '',
  especializacao: '',
  mestrado: '',
  doutorado: '',
  anexo_cpf_cnpj: '',
  anexo_rg: '',
  anexo_endereco: '',
  anexo_formacao: '',
  anexo_formacao_2: '',
  anexo_especializacao: '',
  anexo_mestrado: '',
  anexo_doutorado: '',
};

export default initialData;
