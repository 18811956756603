import React from 'react';
import { Link, withRouter } from 'react-router-dom';


const Menu = ({ menu, location }) => {
  return (
    <li
      className="nav-item has-treeview"
      key={menu.menu_pai.id}
    >
      <Link
        className={`nav-link ${menu.menu_pai.path_name.replace('/', '') ===
          location.pathname.split('/')[1]
         && 'active'}`}
        to={menu.menu_pai.path_name}
      >
        <i className={`nav-icon fas fa-${menu.menu_pai.icon}`} />
        <p>
          {menu.menu_pai.nome}
          <i className="right fas fa-angle-left" />
        </p>
      </Link>
      <ul className="nav nav-treeview">
        {menu.sub_menus &&
            menu.sub_menus.map(sub_menu => (
              <li
                className="nav-item"
                key={sub_menu.id}
              >
                <Link
                  style={{ paddingLeft: '30px' }}
                  className={`nav-link ${new RegExp(sub_menu.path_name).test(
                    location.pathname
                  ) && 'active'}`}
                  to={sub_menu.path_name}
                >
                  <i className={`fa fa-${sub_menu.icon} nav-icon`} />
                  <p>{sub_menu.nome}</p>
                </Link>
              </li>
            ))}
      </ul>
    </li>
  );
};

export default withRouter(Menu);
