import { apiWithToken } from '../api';

const token = localStorage.getItem('@token');
let api = apiWithToken(token);

const resouceUrl = '/menu';

export const load = async () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let page = (urlParams.has('page')) ? `&page=${urlParams.get('page')}`:'';
  let search = (urlParams.has('search')) ? `&search=${urlParams.get('search')}`:'';
  return await api.get(`${resouceUrl}?where=menu_pai:1${page}${search}`);
}

export const loadSubMenu = async (menu_id) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let page = urlParams.has('page') ? `&page=${urlParams.get('page')}` : '';
  let search = urlParams.has('search')
    ? `&search=${urlParams.get('search')}`
    : '';
  return await api.get(`${resouceUrl}?where=menu_pai_id:${menu_id}${page}${search}`);
};

export const loadMenuTree = async () => {
  return await api.get(`${resouceUrl}/menu_tree`);
};

export const show = async (id) => {
  return await api.get(`${resouceUrl}/${id}`);
}

export const update = async (id, menu) => {
  return await api.put(`${resouceUrl}/${id}`, menu);
}

export const store = async (menu) => {
  let response = await api.post(resouceUrl , menu);
  return response;
}

export const destroy = async (id) => {
  return await api.delete(`${resouceUrl}/${id}`);
}
