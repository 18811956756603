import * as Views from '~/views/Entidade';

const basePath = '/entidade';

export default [
  { component: Views.EntidadeList, path: basePath, exact: true },
  { component: Views.EntidadeCreate, path: `${basePath}/create`, exact: true },
  { component: Views.EntidadeShow, path: `${basePath}/:entidade_id/detalhe`, exact: true },
  { component: Views.EntidadeEdit, path: `${basePath}/:entidade_id/edit`, exact: true },
  { component: Views.EntidadeShow, path: `${basePath}/:entidade_id/motorista/*`, exact: true },
  { component: Views.EntidadeShow, path: `${basePath}/:entidade_id/veiculo/*`, exact: true },
  { component: Views.EntidadeShow, path: `${basePath}/:entidade_id/atividade/*`, exact: true },
  { component: Views.EntidadeShow, path: `${basePath}/:entidade_id/condicionante/*`, exact: true },
  { component: Views.EntidadeShow, path: `${basePath}/:entidade_id/observacao/*`, exact: true },
  { component: Views.EntidadeShow, path: `${basePath}/:entidade_id/usuario_autorizacao/*`, exact: true }
];
