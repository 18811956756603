import * as actions from './actionType';

export const loadAlternativaQuestaoAnalises = (params) => ({
  type: actions.LOAD_ALTERNATIVA_QUESTAO_ANALISES,
  params
});
export const getAlternativaQuestaoAnalises = alternativa_questao_analises => ({
  type: actions.ALTERNATIVA_QUESTAO_ANALISES_DATA,
  alternativa_questao_analises,
});

export const getSelectAlternativaQuestaoAnalise = alternativa_questao_analises => ({
  type: actions.SELECT_ALTERNATIVA_QUESTAO_ANALISES,
  alternativa_questao_analises,
});

export const storeAlternativaQuestaoAnalise = alternativa_questao_analise => ({
  type: actions.STORE_ALTERNATIVA_QUESTAO_ANALISE,
  alternativa_questao_analise,

});
export const getAlternativaQuestaoAnalise = alternativa_questao_analise => ({
  type: actions.GET_ALTERNATIVA_QUESTAO_ANALISE,
  alternativa_questao_analise,
});

export const removerAlternativaQuestaoAnalise = (id) => ({
  type: actions.REMOVER_ALTERNATIVA_QUESTAO_ANALISE,
  id
});

export const ativarAlternativaQuestaoAnalise = id => ({
  type: actions.ACTIVATE_ALTERNATIVA_QUESTAO_ANALISE,
  id,
});


export const showAlternativaQuestaoAnalise = (id, params) => ({
  type: actions.SHOW_ALTERNATIVA_QUESTAO_ANALISE,
  id,
  params
});

export const updateAlternativaQuestaoAnalise = (alternativa_questao_analise, id) => ({
  type: actions.UPDATE_ALTERNATIVA_QUESTAO_ANALISE,
  alternativa_questao_analise,
  id
});

export const getErros = errors => ({
  type: actions.ALTERNATIVA_QUESTAO_ANALISE_ERROR,
  errors,
});


export const changeStatus = status => ({
  type: actions.CHANGE_STATUS_CODE,
  status,
});

