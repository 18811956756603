import styled from 'styled-components';

export const Container = styled.div`
  padding: 15px;
`;

export const BoxTitle = styled.div`
  display:flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: baseline;
  > div {
    padding: 10px;
  }
`;

export const ProgressBar = styled.div`
  width:${props => props.percents}%;
`;
