import * as actions from './actionType';

export const ObservacaoReducer = (state = {select_observacoes: [], observacoes: [], observacao: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.OBSERVACOES_DATA:
      return {
        ...state,
        observacoes: action.observacoes.data,
        links: action.observacoes.data.links,
        meta: action.observacoes.data.meta,
      };
    case actions.SELECT_OBSERVACOES:
      return {
        ...state,
        select_observacoes: action.observacoes.data
      };
    case actions.GET_OBSERVACAO:
      return {
        ...state,
        observacao: action.observacao.data.data,
        status_response: action.observacao.status,
      };
    case actions.OBSERVACAO_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
