import React, { useEffect, useState } from 'react';
import { Header, CopyRight, MenuTop } from '../../components';

const Simple = props => {
  const [consultProcess, setConsultProcess] = useState(false);
  const { children } = props;

  useEffect(() => {
    if(children.props.match.url === '/consultar-processo' || children.props.match.url === '/cadastro-sigau-siblam'){
      setConsultProcess(true);
    }
     
    return () => setConsultProcess(false);
    
  },[children.props.match.url]);

  return (
    <div className="bg-white">
      <Header />
      <MenuTop consultProcess={consultProcess} />
      <main>{children}</main>
      <CopyRight />
    </div>
  )
}

export default Simple;
