import React from 'react';

import { FaWindowClose } from 'react-icons/fa';

const BtnClear = ({search}) => {
  if (search) {
    return (
      <a className="btn btn-warning btn-navbar" href="?">
        <FaWindowClose />
      </a>
    );
  }
  return <></>;
};

export default BtnClear;
