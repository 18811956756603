import { UF_DATA, UF_RESPONSE, UF_ERROR } from './actionType';

export const cadastro_pessoa_response = () => {
  return {
    type: UF_RESPONSE
  }
}

export const cadastro_pessoa_error = error => {
  return {
    type: UF_ERROR,
    error
  }
}

export const cadastro_pessoa_data = data => {
  return {
    type: UF_DATA,
    data
  }
}
