import { apiWithToken } from '../api';

const resouceUrl = '/processo';
const token = localStorage.getItem('@token');
let api = apiWithToken(token);

const load_default = async (route, params) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let page = urlParams.has('page') ? { page: urlParams.get('page') } : {};
    let search = urlParams.has('search')
        ? { search: urlParams.get('search') }
        : {};
    return await api.get(route, {
        params: {
            ...params,
            ...page,
            ...search,
        },
    });
};

export const load = async (params) => {
    return await load_default(`${resouceUrl}`, params);
};

export const load_tramites = async (id) => {
    return await api.get(`${resouceUrl}/tramites/${id}`);
};

export const load_condicionantes = async (id) => {
    return await api.get(`${resouceUrl}/condicionantes/${id}`);
};

export const update_condicionantes = async (id, data) => {
    return await api.put(`${resouceUrl}/condicionantes/${id}`, data);
};

export const load_distribuicao = async (params) => {
    return await load_default(`${resouceUrl}/distribuicao`, params);
};

export const load_autorizacao = async (params) => {
    return await load_default(`${resouceUrl}/lista_autorizacao`, params);
};

export const load_lista_analise = async (params) => {
    return await load_default(`${resouceUrl}/lista_analise`, params);
};

export const show = async (id, params) => {
    return await api.get(`${resouceUrl}/${id}`, {
        params,
    });
};

export const update = async (id, processo) => {
    return await api.put(`${resouceUrl}/${id}`, processo);
};

export const finalizar_processo = async (id, processo) => {
    return await api.put(`${resouceUrl}/${id}/finalizar_processo`, processo);
};

export const enviar_para_analista = async (id, data) => {
    return await api.put(`${resouceUrl}/${id}/enviar_para_analista`, data);
};

export const add_atividade_tipo_licenca = async (id, data) => {
    return await api.put(
        `${resouceUrl}/add_atividade_tipo_licenca/${id}`,
        data
    );
};

export const enviar_para_apia = async (id) => {
    return await api.put(`${resouceUrl}/enviar_para_apia/${id}`);
};
export const enviar_para_distribuicao = async (id) => {
    return await api.put(`${resouceUrl}/enviar_para_distribuicao/${id}`);
};

export const enviar_para_autorizacao = async (id) => {
    return await api.put(`${resouceUrl}/enviar_para_autorizacao/${id}`);
};

export const enviar_licenca_email = async (id) => {
    return await api.get(`/enviar_licenca/${id}`);
};

export const gerar_cobranca = async (id) => {
    return await api.put(`${resouceUrl}/${id}/gerar_cobranca`);
};

export const add_pessoa_responsavel_tecnico = async (id, data) => {
    const form = new FormData();
    for (let key in data) {
        form.append(key, data[key]);
    }
    return await api.post(
        `${resouceUrl}/add_pessoa_responsavel_tecnico/${id}`,
        form
    );
};

export const store = async (processo) => {
    let response = await api.post(resouceUrl, processo);
    return response;
};

export const destroy = async (id) => {
    return await api.delete(`${resouceUrl}/${id}`);
};

export const getBoleto = async (info) => {
    // console.log('gerar boleto info: ', info);
    return await api.post(`${resouceUrl}/boleto`, info);
};

export const lista = async () => {
    return await api.get(`${resouceUrl}/lista_processos`);
};

export const load_process_ref = async (id) => {
    return await api.get(`${resouceUrl}/${id}/lista_processos_referencia`);
};

export const get_processo_ccla = async (protocolo, processo_id) => {
    return await api.get(
        `/consultar_processo_ccla/${protocolo}/${processo_id}`
    );
};
