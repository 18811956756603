import React from 'react';
import AsyncSelect from 'react-select/async';

const CustomAsyncSelect = ({errorsMessage, ...rest}) => {
  return (
    <>
      <AsyncSelect {...rest} />
      {errorsMessage ? (
        <span className="text-danger">{errorsMessage}</span>
      ) : ''}
    </>
  )
}

export default CustomAsyncSelect;


