const formatForMask = (value, mascara) => {
  let saida = '';
  let point = 0;

  for (let index = 0; index < mascara.length; index++) {
    const mask = mascara[index];

    if (String(mask) === String('#')) {
      saida += value.substring(point, point + 1);
      point++;
    } else {
      saida += mask;
    }
  }
  return saida;
};

export default formatForMask;
