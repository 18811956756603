import React from 'react';
import { Form } from 'react-bootstrap';

const FormCad = ({ handleSubmit, schema, potencialPoluidor, setPotencialPoluidor, errors }) => {
  const handleChange = event => {
    const { name, value, files } = event.target;
    setPotencialPoluidor({
      ...potencialPoluidor,
      [name]: files ? files[0] : value,
    });
  };

  return (
    <Form id="form_potencial_poluidor" onSubmit={handleSubmit} schema={schema}>
      <div className="form-group">
        <label htmlFor="nome"> Nome </label>
        <Form.Control
          id="nome"
          isInvalid={!!errors.nome}
          name="nome"
          onChange={handleChange}
          placeholder="Nome do potencial poluidor"
          type="text"
          value={potencialPoluidor.nome || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.nome}
        </Form.Control.Feedback>
      </div>
    </Form>
  );
};

export default FormCad;
