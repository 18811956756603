import React from 'react';
import TableQuestions from '../TableQuestions';
import Impacto from '../Impacto';

const MeioBiotico = () => {
  const genericQuestions = [
    { val: '0,0 - 1,9', q: 'Sem evidências' },
    { val: '2,0 - 3,9', q: 'Suposta' },
    { val: '4,0 - 5,9', q: 'Comprovada sem influência' },
    { val: '6,0 - 7,9', q: 'Comprovada' },
    { val: '8,0 - 10,0', q: 'Comprovada com influência' },
  ];
  const dataImpactoFlora = [
    TableQuestions({
      titulo:
        'O empreendimento causará alterações em áreas de ocorrência de espécies endêmicas?',
      questoes: genericQuestions,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará alterações em áreas de ocorrência de espécies raras?',
      questoes: genericQuestions,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará alterações em áreas de ocorrência de espécies ameaçadas de extinção?',
      questoes: genericQuestions,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará introdução de espécies exóticas na região, causando contaminação biológica?',
      questoes: genericQuestions,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará diminuição da abundância de espécies?',
      questoes: genericQuestions,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará diminuição de área de ocorrência de espécies nativas?',
      questoes: genericQuestions,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará redução da variabilidade genética?',
      questoes: genericQuestions,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará perda da diversidade biológica na vegetação?',
      questoes: genericQuestions,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará efeito de borda?',
      questoes: genericQuestions,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará a contribuição para extinção de espécie?',
      questoes: genericQuestions,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará fragmentação de habitats - isolamento?',
      questoes: genericQuestions,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará formação de áreas isoladas - insularização?',
      questoes: genericQuestions,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará invasão de espécies exóticas já adaptadas à região?',
      questoes: genericQuestions,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará perda de cobertura vegetal nativa (floresta, campo)?',
      questoes: genericQuestions,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará perda de conexão entre fragmentos?',
      questoes: genericQuestions,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará aumento da distancia de conexão entre fragmentos?',
      questoes: genericQuestions,
    }),
    TableQuestions({
      titulo:
        'Qual a localização do empreendimento ou atividade em relação a áreas protegidas (Unidades de Conservação)?',
      questoes: [
        {
          val: '0,0 - 1,9',
          q: 'O empreendimento está distante de UC - Sem influência',
        },
        {
          val: '2,0 - 3,9',
          q: 'Limite à borda pelo lado de fora da Zona de Amortecimento',
        },
        {
          val: '4,0 - 5,9',
          q:
            'O empreendimento está parcial ou totalmente inserido à Zona de Amortecimento',
        },
        {
          val: '6,0 - 7,9',
          q:
            'Limite da borda da UC, pelo lado de dentro da Zona de Amortecimento',
        },
        { val: '8,0 - 1,0', q: 'Totalmente inserido à UC' },
      ],
    }),
    TableQuestions({
      titulo:
        'Qual a localização do empreendimento ou atividade em relação às áreas de preservação permanente (APP) definidas pela legislação?',
      questoes: [
        { val: '0,0 - 1,9', q: 'Sem influência' },
        { val: '2,0 - 3,9', q: 'Limite à borda pelo lado de fora da APP' },
        { val: '4,0 - 5,9', q: 'Parcialmente inserido à APP' },
        { val: '6,0 - 7,9,0', q: 'Limite à borda pelo lado de dentro da APP' },
        { val: '8,0 - 10,0', q: 'Totalmente inserido APP' },
      ],
    }),
    TableQuestions({
      titulo:
        'O empreendimento ou atividade causará morte ou dano à fauna, decorrente de ação à flora?',
      questoes: [
        { val: '0,0 - 1,9', q:'Sem influência' },
        { val: '2,0 - 3,9', q:'Suposta' },
        { val: '4,0 - 5,9', q:'Sem evidências' },
        { val: '6,0 - 7,9,0', q:'Fortes indícios' },
        { val: '8,0 - 10,0', q:'Comprovado' },
      ],
    }),
    TableQuestions({
      titulo:
        'O emprendimento ou atividade causará dano ao patrimônio cultural, histórico, artístico, arqueológico e turístico, e /ou a monumentos naturais em decorrência de ação à flora?',
      questoes: [
        { val: '0,0 - 1,9', q:'Sem influência'},
        { val: '2,0 - 3,9', q:'Suposta'},
        { val: '4,0 - 5,9', q:'Sem evidências'},
        { val: '6,0 - 7,9,0', q:'Fortes indícios'},
        { val: '8,0 - 10,0', q:'Comprovado'},
      ],
    }),
    TableQuestions({
      titulo:
        'O empreendimento ou atividade objetiva a comercialização dos produtos florestias madeireiros ou não-madeireiros?',
      questoes: [
        { val: '0,0 - 1,9', q:'Não se aplica pois não há comercialização.'},
        { val: '2,0 - 3,9', q:'Comercializa eventualmente'},
        { val: '4,0 - 5,9', q:'A comercialização é períódica, porém não é atividade principal ou secundária.'},
        { val: '6,0 - 7,9,0', q:'É atividade secundária do empreendimento.'},
        { val: '8,0 - 10,0', q:'É atividade principal do empreendimento.'},
      ],
    }),
    TableQuestions({
      titulo:
        'O empreendimento ou atividade causará alteração em nichos ecológicos?',
      questoes: [
        { val: '0,0 - 1,9', q:'Sem influência'},
        { val: '2,0 - 3,9', q:'Suposta'},
        { val: '4,0 - 5,9', q:'Sem evidências'},
        { val: '6,0 - 7,9,0', q:'Fortes indícios'},
        { val: '8,0 - 10,0', q:'Comprovado'},
      ],
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará dano à um processo de regeneção independente do estágio?',
      questoes: genericQuestions,
    }),
    TableQuestions({
      titulo:
        'Qual a previsão de reequilíbrio (natural) do empreendimento após o término da implantação?',
      questoes: [
        { val: '0,0 - 1,9', q:'Até 1 ano'},
        { val: '2,0 - 3,9', q:'Até 3 anos'},
        { val: '4,0 - 5,9', q:'Até 5 anos'},
        { val: '6,0 - 7,9,0', q: 'Entre 5 e 10 anos'},
        { val: '8,0 - 10,0', q:'Acima de 10 amos'},
      ],
    }),
    TableQuestions({
      titulo:
        'Informe a importância relativa do empreendimento frente à outro(s), ou seja, qual a possibilidade de ocorrência na região, de atividade similar, quanti-qualitativamente, à degradada, numa área similar?',
      questoes: [
        { val: '0,0 - 1,9', q:'Não se aplica'},
        { val: '2,0 - 3,9', q:'Sem influência'},
        { val: '4,0 - 5,9', q:'Até 10 vezes maior que o empreendimento analisado'},
        { val: '6,0 - 7,9,0', q:'Entre 10 e 30 vezes maior que o empreendimento analisado'},
        { val: '8,0 - 10,0', q:'Maior que 30 vezes ao empreendimento analisado.'},
      ],
    }),
  ];
  const genericQuestions2 = [
    { val: '0,0 - 1,9', q:'Sem influência'},
    { val: '2,0 - 3,9', q:'Suposta'},
    { val: '4,0 - 5,9', q:'Sem evidências'},
    { val: '6,0 - 7,9,0', q:'Fortes indícios'},
    { val: '8,0 - 10,0', q:'Comprovado'},
  ];
  const dataImpactoFauna = [
    TableQuestions({
      titulo: 'O empreendimento causará destruição de habitats?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará alteração da composição da fauna?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará aparecimento de espécies exóticas da fauna?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará desequilíbrio ecológico?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará isolamento de populações?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará dispersão de espécies?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará empobrecimento genético da fauna?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento afetará espécies endêmicas da fauna?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento afetará espécies raras da fauna?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento afetará espécies ameaçadas de extinção?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará redução de estoques populacionais?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará aumento da pressão de caça ilegal da fauna terrestre?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará aumento da pressão de pesca ilegal?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará riscos de atropelamento de animais?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará interrupção da migração de espécies da fauna terrestre?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará interrupção da migração de espécies da fauna aquática?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará mortandade de peixes?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará prejuízo de outros animais da fauna aquática?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará aumento da pressão de caça ilegal da avifauna?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará interrupção da migração de espécies da avifauna?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará mortandade de espécies da avifauna?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento afeta a fauna de áreas protegidas (Unidades de Conservação: parques, reservas, estações ecológicas, etc.?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'Ocorrência de espécies ameaçadas de extinção (baseado na Portaria IBAMA nº 1522, de 19/12/89). Não ocorre; Suposta ou sem evidências; Comprovada',
      questoes: [
        { val: '0,0 - 1,9', q: 'Não ocorre' },
        { val: '2,0 - 3,9', q: 'Suposta' },
        { val: '4,0 - 5,9', q: 'Sem evidências' },
        { val: '6,0 - 7,9,0', q: 'Comprovada' },
        { val: '8,0 - 10,0', q: 'Comprovada com prenhes ou ovada' },
      ],
    }),
    TableQuestions({
      titulo:
        'Há ocorrência de fêmeas na área de influência direta no empreendimento?',
      questoes: [
        { val: '0,0 - 1,9', q: 'Não ocorre' },
        { val: '2,0 - 3,9', q: 'Suposta' },
        { val: '4,0 - 5,9', q: 'Sem evidências' },
        { val: '6,0 - 7,9,0', q: 'Comprovada' },
        { val: '8,0 - 10,0', q: 'Comprovada com prenhes ou ovada' },
      ],
    }),
    TableQuestions({
      titulo: 'O empreendimento objetiva a comercialização?',
      questoes: [
        { val: '0,0 - 1,9', q: 'Não se aplica pois não há comercialização.' },
        { val: '2,0 - 3,9', q: 'Comercializa eventualmente' },
        {
          val: '4,0 - 5,9',
          q:
            'A comercialização é períódica, porém não é atividade principal ou secundária.',
        },
        { val: '6,0 - 7,9,0', q: 'É atividade secundária do empreendimento.' },
        { val: '8,0 - 10,0', q: 'É atividade principal do empreendimento.' },
      ],
    }),
    TableQuestions({
      titulo:
        'Informe a importância relativa do empreendimento frente à outro(s), ou seja, qual a possibilidade de ocorrência na região, de atividade similar, quanti-qualitativamente, à degradada, numa área similar?',
      questoes: [
        { val: '0,0 - 1,9', q: 'Não se aplica' },
        { val: '2,0 - 3,9', q: 'Sem influência' },
        {
          val: '4,0 - 5,9',
          q: 'Até 10 vezes maior que o empreendimento analisado',
        },
        {
          val: '6,0 - 7,9,0',
          q: 'Entre 10 e 30 vezes maior que o empreendimento analisado',
        },
        {
          val: '8,0 - 10,0',
          q: 'Maior que 30 vezes ao empreendimento analisado.',
        },
      ],
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará morte ou dano à flora, decorrente do dano à fauna?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento caursará alteração dos nichos ecológicos com ocorrencia na área diretamente afetada?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'Qual a previsão de reequilíbrio (natural) após o encerramento do empreendimento ou atividade?',
      questoes: [
        {
          val: '0,0 - 1,9',
          q: 'Em até 01 ano'
        },
        {
          val: '2,0 - 3,9',
          q: 'Em até 03 anos'
        },
        {
          val: '4,0 - 5,9',
          q: 'Em até 05 anos',
        },
        {
          val: '6,0 - 7,9,0',
          q: 'Em até 08 anos',
        },
        {
          val: '8,0 - 10,0',
          q: 'Em até ou mais de 10 anos',
        },
      ],
    }),
  ];

  return (
    <div
      aria-labelledby="custom-tabs-two-meio-biotico-tab"
      className="tab-pane fade show active"
      id="custom-tabs-two-meio-biotico"
      role="tabpanel"
    >
      <div className="row">
        <div className="col-md-2">
          <div
            aria-orientation="vertical"
            className="nav flex-column nav-tabs h-100"
            id="vert-tabs-tab"
            role="tablist"
          >
            <a
              aria-controls="vert-tabs-flora"
              aria-selected="true"
              className="nav-link active"
              data-toggle="pill"
              href="#vert-tabs-flora"
              id="vert-tabs-flora-tab"
              role="tab"
            >Flora</a>
            <a
              aria-controls="vert-tabs-fauna"
              aria-selected="false"
              className="nav-link"
              data-toggle="pill"
              href="#vert-tabs-fauna"
              id="vert-tabs-fauna-tab"
              role="tab"
            >Fauna</a>
          </div>
        </div>
        <div className="col-md-10">
          <div
            className="tab-content"
            id="v-pills-tabContent"
          >
            <div
              aria-labelledby="vert-tabs-flora-tab"
              className="tab-pane text-left fade show active"
              id="vert-tabs-flora"
              role="tabpanel"
            >
              {Impacto(
                'Flora',
                dataImpactoFlora.map(table => table),
                'Impactos na dinâmica da comunidade, para maciços maiores que 10,0 ha. Neste caso, analisar danos aos indivíduos e à comunidade.'
              )}
            </div>
            <div
              aria-labelledby="vert-tabs-fauna-tab"
              className="tab-pane text-left fade"
              id="vert-tabs-fauna"
              role="tabpanel"
            >
              {Impacto(
                'Fauna',
                dataImpactoFauna.map(table => table),
                'Impactos na dinâmica da comunidade, para mais de um indivíduo. Neste caso, analisar danos aos indivíduos e à comunidade.'
              )}
            </div>
          </div>
        </div>
      </div>

    </div>
  )
};

export default MeioBiotico;
