import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight, FaCircleNotch } from 'react-icons/fa';
import { Form } from 'react-bootstrap';
import { Link, useParams, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { SelectAtividades, SelectTipoLicenca, SelectGroupActivities } from '../../Forms';
import { Loading } from '~/components';
import {
  showProcesso,
  addAtividadeTipoLicenca,
  changeStatus
} from '~/store/modules/processo/action';
import { showAtividade } from '~/store/modules/atividade/action';
import { showTipoLicenca } from '~/store/modules/tipo_licenca/action';
import formatForMask from '~/utils/formatForMask';
// import SimuladorTaxaAmbiental from '../../SimuladorTaxaAmbiental';


export default function Passo2() {
    let { processo_id } = useParams();
    let history = useHistory();
    const dispatch = useDispatch();
    const [processoShow, setProcessoShow] = useState();
    const [atividades, setAtividade] = useState();
    const [tipoLicenca, setTipoLicenca] = useState();
    const [loading, setLoading] = useState(false);
    const [activityGroups, setActivityGroups] = useState();
    const { processo, status_code } = useSelector(({ ProcessoReducer }) => ProcessoReducer);
    const [isReference, setIsReference] = useState(false);
  // const { atividade } = useSelector(({ AtividadeReducer }) => AtividadeReducer);
  // const { tipo_licenca } = useSelector(({ TipoLicencaReducer }) => TipoLicencaReducer);

  const [dataForm, setDataForm] = useState({
    passo: 3,
    atividade_id: null,
    tipo_licenca_id: null,
    activity_group: []
  });

  useEffect(() => {
    if (status_code === 200) {
      Swal.fire(
        'Sucesso',
        'Processo iniciado com sucesso!',
        'success'
      ).then(() => {
        dispatch(changeStatus(null));
        history.push(
          `/processos/create/pass_${processo.passo}/${processo.id}`
        );
      });
    }
  }, [dispatch, history, processo.id, processo.passo, status_code]);

  useEffect(() => {
    if (atividades) {
      setDataForm((dataForm) => ({
        ...dataForm,
        atividade_id: atividades.value,
        tipo_licenca_id: null,
      }));
    }
  }, [atividades]);

  useEffect(() => {
    if (tipoLicenca) {
      setDataForm((dataForm) => ({
        ...dataForm,
        tipo_licenca_id: tipoLicenca.value,
      }));
    }
  }, [tipoLicenca]);

  useEffect(() => {
    if (activityGroups) {
      setDataForm((dataForm) => ({
        ...dataForm,
        activity_group: activityGroups,
      }));
    }
  }, [activityGroups]);

  useEffect(() => {
    dispatch(showProcesso(processo_id,{
      with:
           'pessoa,estado,'
          + 'analise_previa,'
          + 'pessoa,'
          + 'estado,'
          + 'documentos,'
          + 'municipio,'
          + 'pessoa_responsavel_tecnico,'
          + 'tipo_licenca.valor_referencias,'
          + 'status_processo,'
          + 'porte_empreendimento,'
          + 'atividade.potencial_poluidor,'
          + 'atividade.legislacao_atividade;deleted_at is null,'
          + 'user_avaliador.pessoa_tecnico_profissional,'
          + 'entidade'
    }));
  }, [processo_id, dispatch]);

  useEffect(() => {
    if (atividades) {
      dispatch(showAtividade(atividades.value, {
        with: `potencial_poluidor`
      }));
    }
  }, [atividades, dispatch ])

  useEffect(() => {
    if(tipoLicenca) {
      dispatch(showTipoLicenca(tipoLicenca.value, {
        with: 'valor_referencias'
      }));
    }
  }, [tipoLicenca, dispatch]);

  useEffect(() => {
    if (processo.id === parseInt(processo_id)) {
      setProcessoShow(processo);
    }
  }, [processo, processo_id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    await dispatch(addAtividadeTipoLicenca(processo_id, dataForm));
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">
          Processo (
          {processoShow && processoShow.protocolo && processoShow.protocolo}) -
          Passo 2 - Selecione a Atividade a ser Licenciada e o Tipo de licença
        </h3>
        <div className="card-tools">
          <Link
            className="btn btn-light"
            to="/processos"
          >
            <FaArrowAltCircleLeft /> Cancelar
          </Link>
        </div>
      </div>
      <div className="card-body">
        <form
          id="form_atividade_licenca"
          onSubmit={handleSubmit}
        >
          {!!processo_id && !!processoShow &&
          !!processoShow.pessoa &&
          processoShow.id === parseInt(processo_id) ? (
              <>
                <Form.Group>
                  <Form.Label>Empreendimento</Form.Label>
                  <Form.Control
                    id="pessoa"
                    name="pessoa"
                    placeholder="Selecione o Empreendimento"
                    readOnly
                    type="text"
                    value={`${processoShow.pessoa.nome} - ${
                      processoShow.pessoa.cnpj
                        ? `CNPJ: ${formatForMask(
                          processoShow.pessoa.cnpj,
                          '##.###.###/####-##'
                        )}` : ''
                    } ${
                      processoShow.pessoa.cpf
                        ? `CPF: ${formatForMask(
                          processoShow.pessoa.cpf,
                          '###.###.###-##'
                        )}` : ''
                    }`}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Estado</Form.Label>
                  <Form.Control
                    id="estado"
                    name="estado"
                    placeholder="Selecione o Estado"
                    readOnly
                    type="text"
                    value={processoShow.estado.nome}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Município</Form.Label>
                  <Form.Control
                    id="municipio"
                    name="municipio"
                    placeholder="Selecione o Município"
                    readOnly
                    type="text"
                    value={processoShow.municipio.nome}
                  />
                </Form.Group>

                {processoShow.entidade && processoShow.entidade?.cnae_group ? (
                    <SelectGroupActivities
                        entidade_id={processoShow.entidade_id}
                        setAtividade={setAtividade}
                        setActivityGroups={setActivityGroups}
                        setIsReference={setIsReference}
                        isReference={isReference}
                    />
                ) : (
                    <SelectAtividades
                        entidade_id={processoShow.entidade_id}
                        setAtividade={setAtividade}
                    />
                ) }

                {atividades && atividades.value && (
                  <>
                    <SelectTipoLicenca
                      atividade_id={atividades.value}
                      setTipoLicenca={setTipoLicenca}
                    />

                    {dataForm.tipo_licenca_id && (
                      <div className="form-group">
                        <Link
                          className="btn btn-light"
                          to="/processos"
                        >
                          <FaArrowAltCircleLeft /> Cancelar
                        </Link>
                        <button
                          className="btn btn-success"
                          form="form_atividade_licenca"
                          disabled={loading}
                        >
                          {loading ?
                            <FaCircleNotch className="spinner"/> : <FaArrowAltCircleRight />
                          } Escolher e avançar
                        </button>
                        {/* <SimuladorTaxaAmbiental processo={processo} atividade={atividade} tipo_licenca={tipo_licenca} /> */}
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <Loading />
            )}
        </form>
      </div>
    </div>
  );
}
