import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Loading, ModalAnexos } from '~/components';
import {
  removerDocumentoProcesso,
  loadDocumentoProcessos,
} from '~/store/modules/documento_processo/action';
import Swal from 'sweetalert2';
import { FormDocumento } from '..';

const CheckList = ({ processo_id, lista }) => {
  const dispatch = useDispatch();
  const { errors } = useSelector(
    ({ DocumentoProcessoReducer }) => DocumentoProcessoReducer
  );
  const [modalArquivo, setModalArquivo] = useState({
    title: '',
    path: '',
    show: false,
    handleHide: () => false,
  });

  const handleOpenModalAnexo = (title, path) => {
    setModalArquivo({
      title,
      path,
      show: true,
      handleHide,
    });
  };

  const handleHide = () => {
    setModalArquivo({
      title: '',
      path: '',
      show: false,
      handleHide: () => false,
    });
  };

  useEffect(() => {
    if (errors.deleted) {
      Swal.fire(
        'Sucesso',
        'Arquivo removido com sucesso!',
        'success'
      );

      dispatch(
        loadDocumentoProcessos({
          fields: 'id,nome,processo_id,anexo',
          where: `processo_id:${processo_id}`,
          raw_list: true,
        })
      );
    } else if (errors.deleted === false) {
      Swal.fire(
        'Error',
        'Error ao remover o anexos ',
        'error'
      );

      console.log(errors);
    }
  }, [errors, processo_id, dispatch]);

  const handleRemoveDocumento = (id, nome) => {
    Swal.fire({
      title: 'Remover Arquivo?',
      text: `${nome}!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(removerDocumentoProcesso(id));
      }
    });
  }
  return (
    <>
      <div className="table-responsive">
        <table
          style={{
            minWidth: '1000px'
          }}
          className="table table-striped table-hover table-bordered table-sm"
        >
          <tbody>
            {lista.map(({ id, nome, obrigatorio, anexos }, index) => (
              <tr key={index}>
                <th>{++index}</th>
                <th>{nome}</th>
                <td style={{ width: '500px', height: '1px' }}>
                  {anexos[0] ? (
                    anexos.map((documento) => (
                      <div
                        className="btn-group"
                        key={documento.id}
                      >
                        <button
                          className="btn btn-default ml-2"
                          onClick={() =>
                            handleOpenModalAnexo(nome, documento.anexo)
                          }
                        >
                          <i className="fa fa-paperclip" /> Visualizar
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={() =>
                            handleRemoveDocumento(documento.id, documento.nome)
                          }
                        >
                          <i className="fa fa-trash" /> Remover
                        </button>
                      </div>
                    ))
                  ) : (
                    <FormDocumento
                      id={id}
                      nome={nome}
                      obrigatorio={obrigatorio}
                      processo_id={processo_id}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {!lista[0] && <Loading />}
      </div>
      <ModalAnexos {...modalArquivo} />
    </>
  );
};

export default CheckList;
