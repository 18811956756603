export const LOAD_QUESTAO_ANALISES = 'LOAD_QUESTAO_ANALISES';
export const SELECT_QUESTAO_ANALISES = 'SELECT_QUESTAO_ANALISES';
export const QUESTAO_ANALISE_ERROR = 'QUESTAO_ANALISE_ERROR';
export const QUESTAO_ANALISE_GET_ERRORS = 'QUESTAO_ANALISE_GET_ERRORS';
export const QUESTAO_ANALISES_DATA = 'QUESTAO_ANALISES_DATA';
export const STORE_QUESTAO_ANALISE = 'STORE_QUESTAO_ANALISE';
export const GET_QUESTAO_ANALISE = 'GET_QUESTAO_ANALISE';
export const REMOVER_QUESTAO_ANALISE = 'REMOVER_QUESTAO_ANALISE';
export const SHOW_QUESTAO_ANALISE = 'SHOW_QUESTAO_ANALISE';
export const UPDATE_QUESTAO_ANALISE = 'UPDATE_QUESTAO_ANALISE';
export const CHANGE_STATUS_CODE = 'CHANGE_STATUS_CODE';
export const ACTIVATE_QUESTAO_ANALISE = 'ACTIVATE_QUESTAO_ANALISE';
