import React, { useState } from 'react';
import { Content } from '~/layouts/Dash/components';
import {
  Row, Col, Card, Table,
  Form, Button
} from 'react-bootstrap';
import { FiFilter } from 'react-icons/fi';
import { apiWithToken } from '~/services/api';
import Swal from 'sweetalert2';
import { BlobProvider } from '@react-pdf/renderer';
import procedureTypes from '../procedureTypes';
import RGLAPDF from './PDF';

const breadcrumbs = [
  {
    label: 'Relatório',
    to: '/relatorios',
    active: false,
  }, {
    label: 'Relatório de Gestão de Licenciamento Ambiental',
    to: '/relatorios/relatorio-gestao-licenca-ambiental',
    active: true,
  }
];

const months = [
  'Meses',
  'Jan', 'Fev', 'Mar',
  'Abr', 'Mai', 'Jun',
  'Jul', 'Ago', 'Set',
  'Out', 'Nov', 'Dez'
];

const currentYear = new Date().getFullYear();

export default function RGLA() {
  const token = localStorage.getItem('@token');
  let api = apiWithToken(token);
  const [params, setParams] = useState({});
  const [procedureType, setProcedureType] = useState('');
  const [period, setPeriod] = useState('');
  const [entityName, setEntityName] = useState('');
  const [organName, setOrganName] = useState('');
  const [logoSiblam, setLogoSiblam] = useState('');
  const [logoEntity, setLogoEntity] = useState('');
  const [countLicensesByDate, setCountLicensesByDate] = useState(null);
  const [countLicensesByType, setCountLicensesByType] = useState(null);
  const [countLicensesByActivity, setCountLicensesByActivity] = useState(null);
  const [logoCounty, setLogoCounty] = useState('');

  function handleChange({ target }) {
    const { name, value } = target;

    setParams({
      ...params,
      [name]: value
    })
  }

  async function getRGLA(event) {
    event.preventDefault();

    try {
      const res = await api.get(
        'relatorio-gestao-licenciamento-ambiental',
        { params }
      );

      const {
        procedure_type, period, entity_name,
        organ_name, logo_siblam, logo_entity,
        counts, logo_county
      } = res.data;

      const {
        licenses_by_date, licenses_by_type,
        licenses_by_activity
      } = counts;

      setProcedureType(procedure_type);
      setPeriod(period);
      setEntityName(entity_name);
      setOrganName(organ_name);
      setLogoSiblam(logo_siblam);
      setLogoEntity(logo_entity);
      setCountLicensesByDate(licenses_by_date);
      setCountLicensesByType(licenses_by_type);
      setCountLicensesByActivity(licenses_by_activity);
      setLogoCounty(logo_county);
    } catch (error) {
      await Swal.fire(
        'Erro',
        'Erro interno',
        'error'
      );
      console.log(error.response);
    }
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Relatório de Gestão de Licenciamento Ambiental"
    >
      <Row>
        <Col md="3">
          <Card>
            <Card.Header>
              <Card.Title>Filtros</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={getRGLA}>
                <Form.Group>
                  <Form.Label>Data Início</Form.Label>
                  <Form.Control
                    type="date"
                    name="begin_date"
                    onChange={handleChange}
                    required
                  >
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Data Fim</Form.Label>
                  <Form.Control
                    type="date"
                    name="end_date"
                    onChange={handleChange}
                    required
                  >
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    name="procedure_type"
                    onChange={handleChange}
                    required
                    custom
                  >
                    <option>Selecione</option>
                    {procedureTypes.map(({ label, value }, index) => (
                      <option key={`procedure-type-${index}`} value={value}>
                        {label}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Button
                  block
                  type="submit"
                  variant="light"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <FiFilter style={{ marginRight: '5px'}}/>
                  Filtrar
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col md="9">
          <Card>
            <Card.Header>
              <div
                className="card-tools"
                style={{
                  width: '100%',
                  display: 'flex',
                  marginRight: '0px',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Card.Title>Relatório</Card.Title>
                {
                  (
                    !!countLicensesByDate &&
                    !!countLicensesByType &&
                    !!countLicensesByActivity
                  ) && (
                    <div className="btn-group">
                      <BlobProvider document={
                        <RGLAPDF
                          months={months}
                          procedureType={procedureType}
                          period={period}
                          entityName={entityName}
                          organName={organName}
                          logoSiblam={logoSiblam}
                          logoEntity={logoEntity}
                          countLicensesByDate={countLicensesByDate}
                          countLicensesByType={countLicensesByType}
                          countLicensesByActivity={countLicensesByActivity}
                          logoCounty={logoCounty}
                        />
                      }>
                        {({ url }) => (
                          <a
                            href={url}
                            target="_blank"
                            className="btn btn-default"
                            rel="noopener noreferrer"
                          >
                            <i className="fas fa-eye">&nbsp;</i>
                            Visualizar PDF
                          </a>
                        )}
                      </BlobProvider>
                    </div>
                  )
                }
              </div>
            </Card.Header>
            <Card.Body>
              <Form.Label>
                1. Quantidade de licenças emitidas no período
              </Form.Label>
              <Table responsive bordered hover style={{ minWidth: '435px' }}>
                <thead>
                  <tr>
                    <th>Período</th>
                    <th>Quantidade</th>
                    {!countLicensesByDate ? (
                      <>
                        <th>Período</th>
                        <th>Quantidade</th>
                      </>
                    ) : (
                      !countLicensesByDate.length ||
                      countLicensesByDate.length > 6
                    ) && (
                      <>
                        <th>Período</th>
                        <th>Quantidade</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                {!!countLicensesByDate && (
                    (!countLicensesByDate.length && (
                      <tr>
                        <td colSpan="4">
                          Sem Registros
                        </td>
                      </tr>
                    )) || countLicensesByDate.map(
                      ({ month, year, count }, index) => index < 6 && (
                        <tr key={month}>
                          <td>{`${months[month]}/${year}`}</td>
                          <td>{count}</td>
                          {countLicensesByDate.length > 6 ? (
                            !!countLicensesByDate[index + 6] ? (
                              <>
                                <td>{`${months[countLicensesByDate[index + 6].month]}/${year}`}</td>
                                <td>{countLicensesByDate[index + 6].count}</td>
                              </>
                            ) : (<><td/><td/></>)
                          ) : (<></>)}
                        </tr>
                      )
                    )
                  )}

                  {!countLicensesByDate && months.map(
                    (month, index) => (index > 0 && index <= 6) && (
                      <tr key={month}>
                        <td>{`${month}/${currentYear}`}</td>
                        <td>0</td>
                        <td>{`${months[index + 6]}/${currentYear}`}</td>
                        <td>0</td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>

              <Form.Label>
                2. Quantidade de licenças emitidas por CNAE (Tipo de Atividade)
              </Form.Label>
              <Table responsive bordered hover style={{ minWidth: '435px' }}>
                <thead>
                  <tr>
                    <th>CNAE</th>
                    <th>Nome</th>
                    <th>Quantidade</th>
                  </tr>
                </thead>
                <tbody>
                  {!!countLicensesByActivity && (
                    (
                      !countLicensesByActivity.length && (
                        <tr>
                          <td colSpan="3">
                            Sem Registros
                          </td>
                        </tr>
                      )
                    ) || countLicensesByActivity.map(
                      ({cnae, activity, count}, index) => (
                        <tr key={`activity-${index}`}>
                          <td>{cnae}</td>
                          <td>{activity}</td>
                          <td>{count}</td>
                        </tr>
                      )
                    )
                  )}

                  {!countLicensesByActivity && (
                    <>
                      <tr><td/><td/><td/></tr>
                      <tr><td/><td/><td/></tr>
                      <tr><td/><td/><td/></tr>
                    </>
                  )}
                </tbody>
              </Table>

              <Form.Label>
                3. Quantidade de licenças emitidas por tipo de licença
              </Form.Label>
              <Table responsive bordered hover style={{ minWidth: '435px' }}>
                <thead>
                  <tr>
                    <th>Sigla</th>
                    <th>Nome</th>
                    <th>Quantidade</th>
                  </tr>
                </thead>
                <tbody>
                  {!!countLicensesByType && (
                    (
                      !countLicensesByType.length && (
                        <tr>
                          <td colSpan="3">
                            Sem Registros
                          </td>
                        </tr>
                      )
                    ) || countLicensesByType.map(
                      ({initials, license_type, count}, index) => (
                        <tr key={`license-type-${index}`}>
                          <td>{initials}</td>
                          <td>{license_type}</td>
                          <td>{count}</td>
                        </tr>
                      )
                    )
                  )}

                  {!countLicensesByType && (
                    <>
                      <tr><td/><td/><td/></tr>
                      <tr><td/><td/><td/></tr>
                      <tr><td/><td/><td/></tr>
                    </>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Content>
  );
}
