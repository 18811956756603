import * as Yup from 'yup';
import { validateCep } from 'validations-br';

const schema = Yup.object().shape({
  logradouro: Yup.string().required('O logradouro é obrigatório.'),
  bairro: Yup.string().required('O Birro é obrigatório.'),
  cep: Yup.string()
  .test(
    "is-cep",
    "CEP não é valido",
    (value) => validateCep(value)
  ),
});
export default schema;
