import { all, put, takeEvery, call } from 'redux-saga/effects';
import  * as actionType from './actionType';
import * as actions from './action';
import * as IndicadorProcesso from '~/services/IndicadorProcesso';
import { getErros } from '~/store/modules/errors/action';

function* loadIndicadoresProcessoWorker({ processo_id }) {
  try {
    const response = yield call(IndicadorProcesso.list_indicadores_processo, processo_id);
    // console.log('IndicadorAmbiental loadIndicadorAmbientalWorker: ',response);
    yield put(actions.getIndicadoresProcessos(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response.data));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeIndicadorProcessoWorker({ indicador_processo }) {
  try {
    const response = yield call(IndicadorProcesso.store_indicador_processo, indicador_processo);
    yield put(actions.getIndicadorProcesso(response));
    // console.log("storeIndicadorAmbientalWorker response: ",response);
    yield put(actions.changeStatus(response.status));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      // console.log("storeIndicadorAmbientalWorker error: ",error.response);
      yield put(actions.getErros(error.response.data));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* removerIndicadorProcessoWorker({ id }) {
  try {
    yield call(IndicadorProcesso.destroy_indicador_processo, id);
    yield put(actions.getIndicadorProcesso({ data: { data: {} }}))
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherIndicadorAmbientalProcesso() {
  yield takeEvery(actionType.LOAD_INDICADORES_PROCESSO, loadIndicadoresProcessoWorker);
  yield takeEvery(actionType.STORE_INDICADOR_PROCESSO, storeIndicadorProcessoWorker);
  yield takeEvery(actionType.REMOVER_INDICADOR_PROCESSO, removerIndicadorProcessoWorker);
}


export default function* saga() {
  yield all([watcherIndicadorAmbientalProcesso()]);
}
