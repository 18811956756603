import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { FaPrint } from 'react-icons/fa';
import { Loading } from '~/components';

import formatForMask from '~/utils/formatForMask';

const TaxaAmbiental = ({ processo }) => {
  let [show, setShow] = useState(false);

  return (
    <>
      {processo && processo.pessoa ? (
        <>
          <div className="col-md-4">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">TAXA AMBIENTAL</h3>
              </div>
              <div className="card-body">
                <button
                  className="btn btn-success btn-block"
                  onClick={() => setShow(true)}
                >
                  <i
                    aria-hidden="true"
                    className="fa fa-barcode"
                  /> TAXA
                  AMBIENTAL
                </button>
              </div>
            </div>
          </div>

          <Modal
            animation
            aria-labelledby="example-custom-modal-styling-title"
            dialogClassName="modal-xl"
            onHide={() => setShow(false)}
            show={show}
          >
            <Modal.Header closeButton>
              <Modal.Title>TAXA AMBIENTAL</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="card">
                <div className="card-header">
                  Dados da tipologia do empreendimento / atividade
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover table-striped table-bordered table-sm">
                      <tbody>
                        <tr>
                          <th>Processo</th>
                          <td>{processo.protocolo}</td>
                        </tr>
                        <tr>
                          <th>Empreendimento</th>
                          <td>
                            {' '}
                            {`${processo.pessoa.nome} - ${
                              processo.pessoa.cnpj
                                ? formatForMask(
                                  processo.pessoa.cnpj,
                                  '##.###.###/####-##'
                                )
                                : ''
                            } ${
                              processo.pessoa.cpf
                                ? formatForMask(
                                  processo.pessoa.cpf,
                                  '###.###.###-##'
                                )
                                : ''
                            }`}
                          </td>
                        </tr>
                        <tr>
                          <th>Porte Empreendimento</th>
                          <td>
                            {processo.porte_empreendimento &&
                        processo.porte_empreendimento.nome}
                          </td>
                        </tr>
                        <tr>
                          <th>Local</th>
                          <td>
                            {' '}
                            {processo.municipio.nome} - {processo.estado.uf}
                          </td>
                        </tr>
                        <tr>
                          <th>Atividade</th>
                          <td>
                            {' '}
                            {processo.atividade && processo.atividade.nome}
                          </td>
                        </tr>
                        <tr>
                          <th>Tipo de licença</th>
                          <td> {processo.tipo_licenca.nome}</td>
                        </tr>
                        <tr>
                          <th>Técnico Consultor</th>
                          <td>
                            {processo.pessoa_responsavel_tecnico &&
                        processo.pessoa_responsavel_tecnico
                          .responsavel_tecnico &&
                        processo.pessoa_responsavel_tecnico
                          .responsavel_tecnico.pessoa_tecnico_profissional &&
                        processo.pessoa_responsavel_tecnico
                          .responsavel_tecnico.pessoa_tecnico_profissional
                          .nome}{' '}
                      -
                            {processo.pessoa_responsavel_tecnico &&
                        processo.pessoa_responsavel_tecnico
                          .responsavel_tecnico &&
                        processo.pessoa_responsavel_tecnico
                          .responsavel_tecnico.pessoa_tecnico_profissional &&
                        processo.pessoa_responsavel_tecnico
                          .responsavel_tecnico.pessoa_tecnico_profissional
                          .cnpj ? formatForMask(processo.pessoa_responsavel_tecnico
                                .responsavel_tecnico.pessoa_tecnico_profissional
                                .cnpj, '##.###.###/####-##') : '' - 
                        
                        processo.pessoa_responsavel_tecnico
                          .responsavel_tecnico.pessoa_tecnico_profissional
                          .cpf ? formatForMask( processo.pessoa_responsavel_tecnico
                                  .responsavel_tecnico.pessoa_tecnico_profissional
                                  .cpf, '###.###.###-##') : ''
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped table-hover">
                      <thead>
                        <tr>
                          <th
                            className="text-center"
                            colSpan="9"
                          >
                            CÁLCULO DA TAXA DE LICENCIAMENTO
                          </th>
                        </tr>
                        <tr>
                          <th>X</th>
                          <th>A</th>
                          <th>B</th>
                          <th>C</th>
                          <th>D1</th>
                          <th>D2</th>
                          <th>D3</th>
                          <th>E</th>
                          <th>P</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {processo.valor_potencial_poluidor &&
                            processo.valor_porte &&
                            parseFloat(parseFloat(processo.valor_potencial_poluidor) + parseFloat(processo.valor_porte)).toFixed(2)}
                          </td>
                          <td>{processo && processo.qtd_tec_para_analise && processo.qtd_tec_para_analise}</td>
                          <td>{processo && processo.qtd_hora_tec_necessaria && processo.qtd_hora_tec_necessaria}</td>
                          <td>{processo && processo.valor_hora_tec && parseFloat(processo.valor_hora_tec).toFixed(2)}</td>
                          <td>{processo && processo.qtd_diarias && parseFloat(processo.qtd_diarias).toFixed(2)}</td>
                          <td>{processo && processo.valor_combustivel_vistoria && parseFloat(processo.valor_combustivel_vistoria).toFixed(2)}</td>
                          <td>{processo && processo.valor_combustivel_vistoria && parseFloat(parseFloat(processo.valor_combustivel_vistoria)/2).toFixed(2)}</td>
                          <td>{processo && processo.qtd_viagem_vistoria && processo.qtd_viagem_vistoria}</td>
                          <td>{processo && processo.valor_total && parseFloat(processo.valor_total).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>X</td>
                          <td>{processo.valor_potencial_poluidor &&
                            processo.valor_porte &&
                            parseFloat(parseFloat(processo.valor_potencial_poluidor) + parseFloat(processo.valor_porte)).toFixed(2)}</td>
                          <td colSpan="2">Potencial Poluidor =</td>
                          <td><strong>{processo.atividade &&  processo.atividade.potencial_poluidor &&
                        processo.atividade.potencial_poluidor.nome}</strong></td>
                          <td>Porte = </td>
                          <td colSpan="2"><strong>{processo.porte_empreendimento &&
                        processo.porte_empreendimento.nome}</strong></td>

                          <td />
                        </tr>
                        <tr>
                          <td>A</td>
                          <td>{processo && processo.qtd_tec_para_analise && processo.qtd_tec_para_analise}</td>
                          <td colSpan="7">
                            Quantidade de Técnicos envolvidos na análise
                          </td>
                        </tr>
                        <tr>
                          <td>B</td>
                          <td>{processo && processo.qtd_hora_tec_necessaria && processo.qtd_hora_tec_necessaria}</td>
                          <td colSpan="7">
                            Quantidade de Horas/Técnica necessárias para análise
                          </td>
                        </tr>
                        <tr>
                          <td>C</td>
                          <td>{processo && processo.valor_hora_tec && parseFloat(processo.valor_hora_tec).toFixed(2)}</td>
                          <td colSpan="7">
                            Valor em UFIR da Hora/Técnico para análise fixado em
                            50 UFIR
                          </td>
                        </tr>
                        <tr>
                          <td>D1</td>
                          <td>{processo && processo.qtd_diarias && parseFloat(processo.qtd_diarias).toFixed(2)}</td>
                          <td colSpan="7">Total de Diárias</td>
                        </tr>
                        <tr>
                          <td>D2</td>
                          <td>{processo && processo.valor_combustivel_vistoria && parseFloat(processo.valor_combustivel_vistoria).toFixed(2)}</td>
                          <td colSpan="7">
                            Valor do combustível usado na vistoria
                          </td>
                        </tr>
                        <tr>
                          <td>D3</td>
                          <td>{processo && processo.valor_combustivel_vistoria && parseFloat(parseFloat(processo.valor_combustivel_vistoria)/2).toFixed(2)}</td>
                          <td colSpan="7">Manutenção do Veículo (50% de D2)</td>
                        </tr>
                        <tr>
                          <td>E</td>
                          <td>{processo && processo.qtd_viagem_vistoria && processo.qtd_viagem_vistoria}</td>
                          <td colSpan="7">
                            Quantidade de Viagens necessárias para a vistoria
                          </td>
                        </tr>
                        <tr>
                          <td>P</td>
                          <td>{processo && processo.valor_total && parseFloat(processo.valor_total).toFixed(2)}</td>
                          <td colSpan="7">Resultado do cálculo</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>Valor da Taxa</th>
                          <td>R$ </td>
                          <td colSpan="7">{processo && processo.valor_total && parseFloat(processo.valor_total).toFixed(2)}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <div className="card-footer text-center">
                  <a
                    className="btn btn-lg btn-info"
                    href={processo.url_boleto}
                    target="new"
                  >
                    <FaPrint /> GERAR BOLETO
                  </a>
                  <hr />
                  <Link
                    className="btn  btn-primary"
                    to="/processos/certidao-processo"
                  >
                    Ver Certidão
                  </Link>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-default"
                onClick={() => setShow(false)}
                type="button"
              >
                <i className="fa fa-window-close" /> Fechar
              </button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};
export default TaxaAmbiental;
