import React, {useState, useEffect} from 'react';
import { Form, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { load_objetivos_ods } from "~/services/OdsOjetivos";
import { load_metas_ods } from "~/services/OdsMeta";
import { load_indicadores_ods } from "~/services/OdsIndicador";

const FormODS = ({indicadorAmbiental, setIndicadorAmbiental}) => {
    const [show, setShow] = useState(false);
    const [objetivos, setOjetivos] = useState();
    const [metas, setMetas] = useState();
    const [indicador, setIndicador] = useState();
    const [disabledButton, setDisabledButton] = useState();
    const [selectedIndicador, setSelectedIndicador] = useState({});
    // Objetivos
    const [options, setOptions] = useState([
        { value: null, label: '.::Selecione::.' },
    ]);
    const [optionSelected, setOptionSelected] = useState([
        { value: null, label: '.::Selecione::.' },
    ]);
    // Metas
    const [optionsMetas, setOptionsMetas] = useState([
        { value: null, label: '.::Selecione::.' },
    ]);
    const [optionSelectedMetas, setOptionSelectedMetas] = useState([
        { value: null, label: '.::Selecione::.' },
    ]);
    // Indicadores
    const [optionsIndicador, setOptionsIndicador] = useState([
        { value: null, label: '.::Selecione::.' },
    ]);
    const [optionSelectedIndicador, setOptionSelectedIndicador] = useState([
        { value: null, label: '.::Selecione::.' },
    ]);

    const handleClose = () => setShow(false);

    const handleShow = async () => {
        setShow(true);
        setDisabledButton(false)
        const { data} = await load_objetivos_ods({
            raw_list: true
        });
        setOjetivos(data)
    }
    // Objetivos
    useEffect(() => {
        let optionSet = [{ value: null, label: '.::Selecione::.' }];
        setOptionSelected({ value: null, label: '.::Selecione::.' });
        if (objetivos && objetivos.data) {
            objetivos.data.forEach(({ id, nome, ordem }) => {
            optionSet.push({ value: id, label: `${ordem} - ${nome}` });
        });
        }
        setOptions(optionSet);
    }, [objetivos]);
    // Metas
    useEffect(() => {
        let optionSet = [{ value: null, label: '.::Selecione::.' }];
        setOptionSelectedMetas({ value: null, label: '.::Selecione::.' });
        if (metas && metas.data) {
            metas.data.forEach(({ id, nome, ordem }) => {
            optionSet.push({ value: id, label: `${ordem} - ${nome}` });
        });
        }
        setOptionsMetas(optionSet);
    }, [metas]);
    // Indicador
    useEffect(() => {
        let optionSet = [{ id: null, label: '.::Selecione::.' }];
        setOptionSelectedIndicador({ value: null, label: '.::Selecione::.' });
        if (indicador && indicador.data) {
            indicador.data.forEach(({ id, nome, ordem }) => {
            optionSet.push({ id: id, label: `${ordem} - ${nome}` });
        });
        }
        setOptionsIndicador(optionSet);
    }, [indicador]);

    const handleOnChangeObjetivo = async (e) => {
        setOptionSelected(e)
        const { data } = await load_metas_ods(e.value, {
            raw_list: true
        })
        setMetas(data || []);
    };
    const handleOnChangeMetas = async (e) => {
        setOptionSelectedMetas(e)
        const { data } = await load_indicadores_ods(e.value, {
            raw_list: true
        })
        setIndicador(data || []);
    };
    const handleOnChangeIndicador = async (e) => {
        setOptionSelectedIndicador(e)
        setSelectedIndicador(e)
        setDisabledButton(true)
    };
    const handleAdd = () => {
        setIndicadorAmbiental({
            ...indicadorAmbiental,
            indicadores_ods: [...indicadorAmbiental.indicadores_ods, { ...selectedIndicador }]
        });
        setShow(false)
        setOjetivos(null)
        setMetas(null)
        setIndicador(null)
    }

    return (
        <div>
            <Button
                className="btn btn-success"
                onClick={handleShow}
            >
                Adicionar ODS
            </Button>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                dialogClassName="modal-xl"
                show={show}
                onHide={handleClose}
                animation={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        ODS
                    </Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                    <div className="card">
                        <div className="card-header">
                            <h2 className="card-title">Novo</h2>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <label htmlFor="ods"> Selecione o objetivo da ODS </label>
                                <Select
                                    onChange={handleOnChangeObjetivo}
                                    options={options}
                                    value={optionSelected}
                                />
                                <Form.Control.Feedback type="invalid">
                                {/* {errors.ods} */}
                                </Form.Control.Feedback>
                            </div>
                            {metas && metas.data && (
                                <div className="form-group">
                                    <label htmlFor="ods"> Selecione o meta do objetivo </label>
                                    <Select
                                        onChange={handleOnChangeMetas}
                                        options={optionsMetas}
                                        value={optionSelectedMetas}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                    </Form.Control.Feedback>
                                </div>
                            )}
                            {metas && indicador && indicador.data && (
                                <div className="form-group">
                                    <label htmlFor="ods"> Selecione o indicador da meta </label>
                                    <Select
                                        // name="indicador_ods"
                                        onChange={handleOnChangeIndicador}
                                        options={optionsIndicador}
                                        value={optionSelectedIndicador}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                    </Form.Control.Feedback>
                                </div>
                            )}
                        </div>
                    </div>
                    </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-default"
                        onClick={handleClose}
                    >
                        <i className="fa fa-window-close" /> Cancelar
                    </button>
                    <button
                        className="btn btn-success"
                        onClick={handleAdd}
                        disabled={!disabledButton}
                        >
                        {' '}
                        <i className="fa fa-save" /> Adicionar
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
        );

};

export default FormODS;
