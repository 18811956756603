import * as actions from './actionType';

export const loadConfigAutorizacoes = (params) => ({
  type: actions.LOAD_CONFIG_AUTORIZACOES,
  params,
});

export const loadListConfigAutorizacoes = (params) => ({
  type: actions.LOAD_LIST_CONFIG_AUTORIZACOES,
  params,
});

export const getConfigAutorizacoes = (config_autorizacoes) => ({
  type: actions.CONFIG_AUTORIZACOES_DATA,
  config_autorizacoes,
});

export const getSelectConfigAutorizacao = (config_autorizacoes) => ({
  type: actions.SELECT_CONFIG_AUTORIZACOES,
  config_autorizacoes,
});

export const storeConfigAutorizacao = (config_autorizacao) => ({
  type: actions.STORE_CONFIG_AUTORIZACAO,
  config_autorizacao,
});
export const getConfigAutorizacao = (config_autorizacao) => ({
  type: actions.GET_CONFIG_AUTORIZACAO,
  config_autorizacao,
});

export const removerConfigAutorizacao = (id) => ({
  type: actions.REMOVER_CONFIG_AUTORIZACAO,
  id,
});

export const showConfigAutorizacao = (id, params) => ({
  type: actions.SHOW_CONFIG_AUTORIZACAO,
  id,
  params,
});

export const updateConfigAutorizacao = (config_autorizacao, id) => ({
  type: actions.UPDATE_CONFIG_AUTORIZACAO,
  config_autorizacao,
  id,
});

export const getErros = (errors) => ({
  type: actions.CONFIG_AUTORIZACAO_ERROR,
  errors,
});

export const changeStatus = (status) => ({
  type: actions.CHANGE_STATUS_CODE,
  status,
});

export const ativarConfigAutorizacao = (id) => ({
  type: actions.ACTIVATE_CONFIG_AUTORIZACAO,
  id,
});
