export const LOAD_PORTE_EMPREENDIEMENTOS = 'LOAD_PORTE_EMPREENDIEMENTOS';
export const SELECT_PORTE_EMPREENDIEMENTOS = 'SELECT_PORTE_EMPREENDIEMENTOS';
export const PORTE_EMPREENDIEMENTO_ERROR = 'PORTE_EMPREENDIEMENTO_ERROR';
export const PORTE_EMPREENDIEMENTO_GET_ERRORS = 'PORTE_EMPREENDIEMENTO_GET_ERRORS';
export const PORTE_EMPREENDIEMENTOS_DATA = 'PORTE_EMPREENDIEMENTOS_DATA';
export const STORE_PPORTE_EMPREENDIEMENTO = 'STORE_PPORTE_EMPREENDIEMENTO';
export const GET_PORTE_EMPREENDIEMENTO = 'GET_PORTE_EMPREENDIEMENTO';
export const REMOVER_PORTE_EMPREENDIEMENTO = 'REMOVER_PORTE_EMPREENDIEMENTO';
export const SHOW_PORTE_EMPREENDIEMENTO = 'SHOW_PORTE_EMPREENDIEMENTO';
export const UPDATE_PORTE_EMPREENDIEMENTO = 'UPDATE_PORTE_EMPREENDIEMENTO';
