import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as Vistoria from '~/services/Vistoria';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadVistoriasWorker({params}) {
  try {
    const response = yield call(Vistoria.load, params);
    yield put(actions.getVistorias(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* loadVistoriaWorker({ vistoria_id }) {
  try {
    const response = yield call(Vistoria.getVistoria, vistoria_id);
    yield put(actions.getVistoria(response.data));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}


function* showVistoriaWorker({processo_id}) {
  try {
    const response = yield call(Vistoria.show, processo_id);
    yield put(actions.getVistoria(response.data));
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updateVistoriaWorker({ vistoria, id}) {
  try {
    const response = yield call(Vistoria.update, id, vistoria);
    yield put(actions.getVistoria(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}


function* storeVistoriaWorker({vistoria}) {
  try {
    const response = yield call(Vistoria.store, vistoria);
    yield put(actions.getVistoria(response));
    yield put(actions.getErros({save:true}));
    yield put(getErros({save:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* removerVistoriaWorker({id}) {
  try {
    yield call(Vistoria.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* loadVistoriaEntidadework({params}) {
  try {
    const response = yield call(Vistoria.entidade, params);
    yield put(actions.getVistorias(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}


function* watcherVistoria() {
  yield takeLatest(actionType.LOAD_VISTORIAS, loadVistoriasWorker);
  yield takeLatest(actionType.LOAD_VISTORIA, loadVistoriaWorker);
  yield takeLatest(actionType.STORE_VISTORIA, storeVistoriaWorker);
  yield takeLatest(actionType.SHOW_VISTORIA, showVistoriaWorker);
  yield takeLatest(actionType.UPDATE_VISTORIA, updateVistoriaWorker);
  yield takeLatest(actionType.REMOVER_VISTORIA, removerVistoriaWorker);
  yield takeLatest(actionType.LOAD_VISTORIA_ENTIDADE, loadVistoriaEntidadework);
}

export default function* saga() {
  yield all([watcherVistoria()]);
}
