import * as actions from './actionType';

export const MenuReducer = (state = {menus: [], menu: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.MENU_DATA:
      return {
        ...state,
        menus: action.menus.data,
        links: action.menus.data.links,
        meta: action.menus.data.meta,
      };
    case actions.GET_MENU:
      return {
        ...state,
        menu: action.menu.data.data,
        status_response: action.menu.status,
      };
    case actions.MENU_TREE_DATA:
      return {
        ...state,
        menu_tree: action.menu_tree.data.data,
        status_response: action.menu_tree.status,
      };
    case actions.SUBMENU_DATA:
      return {
        ...state,
        sub_menus: action.sub_menu.data.data,
        status_response: action.sub_menu.status,
      };
    case actions.MENU_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
