import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { FaArrowAltCircleLeft, FaPrint } from 'react-icons/fa';

import { Container, BoxTitle } from './styles';


const TaxaAmbiental = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Container>
      <BoxTitle>
        <div>
          <h2>TAXA AMBIENTAL</h2>
        </div>
        <div>
          <Link className="btn btn-light" to="/processos">
            <FaArrowAltCircleLeft /> Cancelar
          </Link>
        </div>
      </BoxTitle>
      <div className="card">
        <div className="card-header">
          Dados da tipologia do empreendimento / atividade
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered table-striped">
              <tbody>
                <tr>
                  <th>Atividade</th>
                  <td>Fabricação de Bebidas alcoolicas</td>
                </tr>
                <tr>
                  <th>Porte</th>
                  <td>MÉDIO</td>
                </tr>
                <tr>
                  <th>Potencial Poluidor</th>
                  <td>PEQUENO</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered table-striped table-hover">
              <thead>
                <tr>
                  <th colSpan="9" className="text-center">
                    CÁLCULO DA TAXA DE LICENCIAMENTO
                  </th>
                </tr>
                <tr>
                  <th>X</th>
                  <th>A</th>
                  <th>B</th>
                  <th>C</th>
                  <th>D1</th>
                  <th>D2</th>
                  <th>D3</th>
                  <th>E</th>
                  <th>P</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>200</td>
                  <td>1</td>
                  <td>20</td>
                  <td>53,21</td>
                  <td>0</td>
                  <td>10</td>
                  <td>5</td>
                  <td>1</td>
                  <td>1279,2</td>
                </tr>
                <tr>
                  <td>X</td>
                  <td>200</td>
                  <td colSpan="2">Potencial Poluidor =</td>
                  <td>MÉDIO</td>
                  <td>Porte = </td>
                  <td colSpan="2">PEQUENO</td>

                  <td></td>
                </tr>
                <tr>
                  <td>A</td>
                  <td>1</td>
                  <td colSpan="7">
                    Quantidade de Técnicos envolvidos na análise
                  </td>
                </tr>
                <tr>
                  <td>B</td>
                  <td>20</td>
                  <td colSpan="7">
                    Quantidade de Horas/Técnica necessárias para análise
                  </td>
                </tr>
                <tr>
                  <td>C</td>
                  <td>53,21</td>
                  <td colSpan="7">
                    Valor em UFIR da Hora/Técnico para análise fixado em 50 UFIR
                  </td>
                </tr>
                <tr>
                  <td>D1</td>
                  <td>1,5</td>
                  <td colSpan="7">Total de Diárias</td>
                </tr>
                <tr>
                  <td>D2</td>
                  <td>10</td>
                  <td colSpan="7">Valor do combustível usado na vistoria</td>
                </tr>
                <tr>
                  <td>D3</td>
                  <td>5</td>
                  <td colSpan="7">Manutenção do Veículo (50% de D2)</td>
                </tr>
                <tr>
                  <td>E</td>
                  <td>1</td>
                  <td colSpan="7">
                    Quantidade de Viagens necessárias para a vistoria
                  </td>
                </tr>
                <tr>
                  <td>P</td>
                  <td>1.279,20</td>
                  <td colSpan="7">Resultado do cálculo</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Valor da Taxa</th>
                  <td>R$ </td>
                  <td colSpan="7">1279,20</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div className="card-footer text-center">
          <button className="btn btn-lg btn-info"><FaPrint/> GERAR BOLETO</button>
          <hr/>
          <Link className="btn  btn-primary" to="/processos/certidao-processo">Ver Certidão</Link>
        </div>
      </div>
    </Container>
  );
};
export default TaxaAmbiental;
