import * as actions from './actionType';

export const MotoristaReducer = (state = {select_motoristas: [], motoristas: [], motorista: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.MOTORISTAS_DATA:
      return {
        ...state,
        motoristas: action.motoristas.data,
        links: action.motoristas.data.links,
        meta: action.motoristas.data.meta,
      };
    case actions.SELECT_MOTORISTAS:
      return {
        ...state,
        select_motoristas: action.motoristas.data
      };
    case actions.GET_MOTORISTA:
      return {
        ...state,
        motorista: action.motorista.data.data,
        status_response: action.motorista.status,
      };
    case actions.MOTORISTA_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
