import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as PagamentoPendente from '~/services/PagamentoPendente';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadPagamentosPendentesWorker({params}) {
  try {
    const response = yield call(PagamentoPendente.load, params);
    // console.log('loadPagamentosPendentesWorker:',response);
    yield put(actions.getPagamentosPendentes(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* ChangeStatusPaymentProcessWorker({params}) {
  try {
    const response = yield call(PagamentoPendente.changeStatusPayment, params);
    console.log('ChangeStatusPaymentProcessWorker:',response);
    // yield put(actions.getPagamentosPendentes(response));
    yield put(actions.loadPagamentosPendentes());
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherLoadPagamentosPendentes() {
  yield takeLatest(actionType.LOAD_PAGAMENTOS_PENDENTES, loadPagamentosPendentesWorker);
  yield takeLatest(actionType.MUDAR_STATUS_PAGAMENTO_PROCESSO, ChangeStatusPaymentProcessWorker);
}

export default function* saga() {
  yield all([watcherLoadPagamentosPendentes()]);
}
