import React from 'react';
import { PasswordInput } from "./components";

function CustomForm() {
  return (
    <div></div>
  );
}

CustomForm.PasswordInput = PasswordInput;

export default CustomForm;
