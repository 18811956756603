import React from 'react';
import {
  Navbar,
  Nav
} from 'react-bootstrap';

const MenuTop = ({consultProcess}) => {

   const detectar_mobile = () => {
      if( navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
      ){
        return "https://api.whatsapp.com/send?phone=5596991953535";
      }
      else {
        return "https://web.whatsapp.com/send?phone=5596991953535";
      }
   }
  return (
    <Navbar
      className="navbar-dark navbar-success"
      expand="md"
    >
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse
        className="justify-content-end"
        id="basic-navbar-nav"
      >
        {consultProcess ? (
          <Nav className="">
            <Nav.Link
              className="text-white"
              href="/"
            >
          Voltar ao login
            </Nav.Link>
          </Nav>
        ): (
          
          <Nav className="">
            <Nav.Link
              className="text-white"
              href="/"
            >
            Inicio
            </Nav.Link>
            <Nav.Link
              className="text-white"
              href="/selecione-cadastro"
            >
            Cadastre-se
            </Nav.Link>
            <Nav.Link
              className="text-white"
              href="/consultar-processo"
            >
            Consultar processo
            </Nav.Link>
            <Nav.Link
              href={detectar_mobile()}
              target="_blank"
              className="text-white"
            >
              <i className="fab fa-whatsapp" />
              &ensp;Perguntas, Dúvidas e Sugestões.
            </Nav.Link>

          </Nav>
        )}
        {/* <Form inline>
          <InputGroup className="mr-sm-2">
            <FormControl
              aria-describedby="basic-addon2"
              aria-label="Pesquisar"
              placeholder="Pesquisar"
            />
            <InputGroup.Append>
              <Button variant="default">
                <IoIosSearch />
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Form> */}
      </Navbar.Collapse>
    </Navbar>
  );
}
export default MenuTop;

