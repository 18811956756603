import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { Loading } from '~/components';
import { showTecnicoAvaliador } from '~/store/modules/tecnico_avaliador/action';
import { ModalAnexos } from '~/components';

const Show = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { tecnico_avaliador_id } = useParams();

  const [modalArquivo, setModalArquivo] = useState({
    title: '',
    path: '',
    show: false,
    handleHide: () => false,
  });

  const { tecnico_avaliador } = useSelector(
    ({ TecnicoAvaliadorReducer }) => TecnicoAvaliadorReducer
  );

  useEffect(() => {
    dispatch(
      showTecnicoAvaliador(
        tecnico_avaliador_id, {
          with: 'pessoa.estado_endereco'
        }
      )
    );
  }, [dispatch, tecnico_avaliador_id]);

  const handleClose = () => {
    history.push('/tecnicos_avaliadores');
  };

  const handleHide = () => {
    setModalArquivo({
      title: '',
      path: '',
      show: false,
      handleHide: () => false,
    });
  };

  const handleOpenModalAnexo = (title, path) => {
    setModalArquivo({
      title,
      path,
      show: true,
      handleHide,
    });
  };

  const formatData = (data) => {
    let part = data.split('-');
    return `${part[2]}/${part[1]}/${part[0]}`;
  }

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-xl"
        onHide={handleClose}
        show
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Técnico Avaliador
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header">
              <h2 className="card-title">Detalhes</h2>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                {tecnico_avaliador.id === parseInt(tecnico_avaliador_id) ? (
                  <table className="table table-hover table-striped table-sm">
                    <tbody>
                      <tr>
                        <th>id</th>
                        <td>{tecnico_avaliador.id}</td>
                      </tr>
                      <tr>
                        <th>Nome</th>
                        <td>{tecnico_avaliador.nome}</td>
                      </tr>
                      <tr>
                        <th>E-mail</th>
                        <td>{tecnico_avaliador.email}</td>
                      </tr>
                      <tr>
                        <th>Data Nascimento</th>
                        <td>
                          {formatData(tecnico_avaliador.pessoa.data_nasc)}
                        </td>
                      </tr>

                      {tecnico_avaliador.pessoa ? (
                        <>
                          <tr>
                            <th>CPF/CNPJ</th>
                            <td>
                              {tecnico_avaliador.pessoa.cnpj ? (
                                <>
                                  {tecnico_avaliador.pessoa.cnpj}
                                  {tecnico_avaliador.pessoa.anexo_cnpj ? (
                                    <button
                                      className="btn btn-default ml-2 float-right"
                                      onClick={() =>
                                        handleOpenModalAnexo(
                                          'Anexo CNPJ',
                                          tecnico_avaliador.pessoa.anexo_cnpj
                                        )
                                      }
                                    >
                                      <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                                    </button>
                                  ) : ''}
                                </>
                              ) : ''}
                              {tecnico_avaliador.pessoa.cpf ? (
                                <>
                                  {tecnico_avaliador.pessoa.cpf}
                                  {tecnico_avaliador.pessoa.anexo_cpf ? (
                                    <button
                                      className="btn btn-default ml-2 float-right"
                                      onClick={() =>
                                        handleOpenModalAnexo(
                                          'Anexo CPF',
                                          tecnico_avaliador.pessoa.anexo_cpf
                                        )
                                      }
                                    >
                                      <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                                    </button>
                                  ) : ''}
                                </>
                              ) : ''}
                            </td>
                          </tr>
                          <tr>
                            <th>RG</th>
                            <td>
                              {tecnico_avaliador.pessoa.rg}
                              {tecnico_avaliador.pessoa.anexo_rg ? (
                                <button
                                  className="btn btn-default ml-2 float-right"
                                  onClick={() =>
                                    handleOpenModalAnexo(
                                      'Anexo RG',
                                      tecnico_avaliador.pessoa.anexo_rg
                                    )
                                  }
                                >
                                  <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                                </button>
                              ) : ''}
                            </td>
                          </tr>

                          <tr>
                            <th>CEP</th>
                            <td>{tecnico_avaliador.pessoa.cep}</td>
                          </tr>
                          <tr>
                            <th>Endereço</th>
                            <td>
                              {tecnico_avaliador.pessoa?.logradouro ?`${tecnico_avaliador.pessoa.logradouro}, ` : ''}
                              {tecnico_avaliador.pessoa?.bairro ? `${tecnico_avaliador.pessoa?.bairro}, ` : ''}
                              {tecnico_avaliador.pessoa?.cidade ? `${tecnico_avaliador.pessoa?.cidade} - ` : ''}
                              {tecnico_avaliador.pessoa?.estado_endereco?.uf ? `${tecnico_avaliador.pessoa?.estado_endereco?.uf}`: ''}
                              {tecnico_avaliador.pessoa?.anexo_endereco ? (
                                <button
                                  className="btn btn-default ml-2 float-right"
                                  onClick={() =>
                                    handleOpenModalAnexo(
                                      'Comprovante de endereço',
                                      tecnico_avaliador.pessoa?.anexo_endereco
                                    )
                                  }
                                >
                                  <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                                </button>
                              ) : ''}
                            </td>
                          </tr>
                          <tr>
                            <th>Número de WhatsApp</th>
                            <td>{tecnico_avaliador.pessoa.numero_whatsapp}</td>
                          </tr>
                          <tr>
                            <th>Fone de contato</th>
                            <td>{tecnico_avaliador.pessoa.telefone}</td>
                          </tr>
                          <tr>
                            <th>Youtube</th>
                            <td>{tecnico_avaliador.pessoa.youtube}</td>
                          </tr>
                          <tr>
                            <th>Facebook</th>
                            <td>{tecnico_avaliador.pessoa.facebook}</td>
                          </tr>
                          <tr>
                            <th>Linkdin</th>
                            <td>{tecnico_avaliador.pessoa.linkdin}</td>
                          </tr>
                          <tr>
                            <th>Twiter</th>
                            <td>{tecnico_avaliador.pessoa.twiter}</td>
                          </tr>
                          <tr>
                            <th>Formação</th>
                            <td>
                              {tecnico_avaliador.pessoa.formacao}
                              <button
                                className="btn btn-default ml-2 float-right"
                                onClick={() =>
                                  handleOpenModalAnexo(
                                    'Anexo formação',
                                    tecnico_avaliador.pessoa.anexo_formacao
                                  )
                                }
                              >
                                <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                              </button>
                            </td>
                          </tr>
                          {tecnico_avaliador.pessoa.formacao_2 &&
                            <tr>
                              <th>Formação 2</th>
                              <td>
                                {tecnico_avaliador.pessoa.formacao_2}
                                {tecnico_avaliador.pessoa.anexo_formacao_2 ? (
                                  <button
                                    className="btn btn-default ml-2 float-right"
                                    onClick={() =>
                                      handleOpenModalAnexo(
                                        'Anexo formação 2',
                                        tecnico_avaliador.pessoa.anexo_formacao_2
                                      )
                                    }
                                  >
                                    <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                                  </button>
                                ) : ''}
                              </td>
                            </tr>
                          }

                          {tecnico_avaliador.pessoa.especializacao &&
                            <tr>
                              <th>Especialização</th>
                              <td>
                                {tecnico_avaliador.pessoa.especializacao}
                                {tecnico_avaliador.pessoa.anexo_especializacao ? (
                                  <button
                                    className="btn btn-default ml-2 float-right"
                                    onClick={() =>
                                      handleOpenModalAnexo(
                                        'Anexo formação 2',
                                        tecnico_avaliador.pessoa.anexo_especializacao
                                      )
                                    }
                                  >
                                    <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                                  </button>
                                ) : ''}
                              </td>
                            </tr>
                          }

                          {tecnico_avaliador.pessoa.mestrado &&
                            <tr>
                              <th>Mestrado</th>
                              <td>
                                {tecnico_avaliador.pessoa.mestrado}
                                {tecnico_avaliador.pessoa.anexo_mestrado ? (
                                  <button
                                    className="btn btn-default ml-2 float-right"
                                    onClick={() =>
                                      handleOpenModalAnexo(
                                        'Anexo Mestrado',
                                        tecnico_avaliador.pessoa.anexo_mestrado
                                      )
                                    }
                                  >
                                    <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                                  </button>
                                ) : ''}
                              </td>
                            </tr>
                          }

                          {tecnico_avaliador.pessoa.doutorado &&
                            <tr>
                              <th>Doutorado</th>
                              <td>
                                {tecnico_avaliador.pessoa.doutorado}
                                {tecnico_avaliador.pessoa.anexo_doutorado ? (
                                  <button
                                    className="btn btn-default ml-2 float-right"
                                    onClick={() =>
                                      handleOpenModalAnexo(
                                        'Anexo Doutorado',
                                        tecnico_avaliador.pessoa.anexo_doutorado
                                      )
                                    }
                                  >
                                    <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                                  </button>
                                ) : ''}
                              </td>
                            </tr>
                          }
                        </>
                      ) : ''}
                    </tbody>
                  </table>
                ) : (
                  <Loading />
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Link
            className="btn btn-default"
            to="/tecnicos_avaliadores"
          >
            <i className="fa fa-window-close" /> Fechar
          </Link>
        </Modal.Footer>
      </Modal>
      <ModalAnexos {...modalArquivo} />
    </>
  );
};

export default Show;
