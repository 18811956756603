import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Loading } from '~/components';
import { FormCad } from '../components';
import initialData from './initialData';
import schema from './schema'
import { updateTipoUser, showTipoUser, loadTipoUsers } from '~/store/modules/tipo_user/action';



const Edit = () => {
  let history = useHistory();
  const [tipoUserEdit, setTipoUserEdit] = useState(initialData);
  const [errorsValidate, setErrorsValidate] = useState([]);
  const [updated, setUpdated] = useState(false);
  const dispatch = useDispatch();
  const { tipo_user_id } = useParams();
  const { tipo_user, errors } = useSelector(({ TipoUserReducer }) => TipoUserReducer);

  useEffect(() => {
    dispatch(showTipoUser(tipo_user_id));
  }, [dispatch, tipo_user_id]);

  useEffect(() => {
    if (tipo_user.id === parseInt(tipo_user_id)) {
      setTipoUserEdit(tipo_user);
    }
  }, [tipo_user, tipo_user_id]);

  useEffect(() => {
    if (errors.status) {
      let server_errors = {};
      const response_errors = errors.data.errors;
      switch (errors.status) {
        case 422:
          for (let name of Object.keys(response_errors)) {
            Object.assign(server_errors, { [name]: response_errors[name][0] });
          }
          setErrorsValidate(server_errors);
          break;
        case 401:
          Swal.fire(
            'ERROR',
            'Você não tem permissão para acessar esse recurso',
            'error'
          ).then(() => {
            history.goBack();
          });
          break;
        default:
          break;
      }
    }
    if (errors.update) {
      setUpdated(true);
    }
  }, [errors, history]);

  useEffect(() => {
    if (updated) {
      Swal.fire('Sucesso', 'Registro atualizado com sucesso!', 'success').then(
        () => {
          history.goBack();
          dispatch(loadTipoUsers());
        }
      );
    }
  }, [updated, history, dispatch]);

  const handleClose = () => {
    history.goBack('/tipo_user');
  };


  const handleSubmit = async event => {
    event.preventDefault();
    if (!schema.isValidSync(tipoUserEdit)) {
      let yup_errors = {};
      schema.validate(tipoUserEdit, { abortEarly: false }).catch(err => {
        for (let error of err.inner) {
          const { path, message } = error;
          Object.assign(yup_errors, { [path]: message });
        }
        setErrorsValidate(yup_errors);
      });
    } else {
      await dispatch(updateTipoUser(tipoUserEdit, tipo_user_id));
    }

  };
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-xl"
      onHide={handleClose}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">TipoUser</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Editar</h2>
          </div>
          <div className="card-body">
            {tipo_user.id === parseInt(tipo_user_id) ? (
              <FormCad
                errors={errorsValidate}
                handleSubmit={handleSubmit}
                schema={schema}
                setTipoUser={setTipoUserEdit}
                tipo_user={tipoUserEdit}
              />
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Link
          className="btn btn-default"
          to="/tipo_user"
        >
          <i className="fa fa-window-close" /> Cancelar
        </Link>
        <button
          className="btn btn-success"
          form="form_tipo_user"
          type="submint"
        >
          <i className="fa fa-save" /> Salvar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Edit;
