import { all, put, call, take, takeLatest } from 'redux-saga/effects';
import * as TipoUser from '~/services/TipoUser';
import * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadTipoUsersWorker({ params }) {
  try {
    const response = yield call(TipoUser.load, params);
    // console.log('tipo_users loadTipoUsersWorker: ',response);
    yield put(actions.getTipoUsers(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* listCustomTipoUsersWorker() {
  try {
    const response = yield call(TipoUser.loadListCustom);
    // console.log('tipo_users loadTipoUsersWorker: ',response);
    yield put(actions.getTipoUsers(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* loadMenusTipoUsersWorker({ id }) {
  try {
    yield take(actionType.GET_TIPO_USER);
    const response = yield call(TipoUser.loadMenusSelect, id);
    yield put(actions.getMenus(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* loadRecursosTipoUsersWorker({ id }) {
  try {
    yield take(actionType.GET_TIPO_USER);
    const response = yield call(TipoUser.loadRecursosSelect, id);
    yield put(actions.getRecursos(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* showTipoUserWorker({ id }) {
  try {
    const response = yield call(TipoUser.show, id);
    yield put(actions.getTipoUser(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* updateTipoUserWorker({ tipo_user, id }) {
  try {
    const response = yield call(TipoUser.update, id, tipo_user);
    yield put(actions.getTipoUser(response));
    yield put(actions.getErros({ update: true }));
    yield put(getErros({ update: true }));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* storeTipoUserWorker({ tipo_user }) {
  try {
    const response = yield call(TipoUser.store, tipo_user);
    yield put(actions.getTipoUser(response));
    yield put(actions.getErros({ save: true }));
    yield put(getErros({ save: true }));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* addMenuTipoUserWorker({ id, menu_id }) {
  try {
    yield call(TipoUser.addMenu, id, menu_id);
    yield put(actions.getErros({ save: true }));
    yield put(getErros({ save: true }));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}
function* addRecursoTipoUserWorker({ id, recurso_id }) {
  try {
    const response = yield call(TipoUser.addRecurso, id, recurso_id);

    console.log(response);
    yield put(actions.getErros({ save: true }));
    yield put(getErros({ save: true }));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* removerTipoUserWorker({ id }) {
  try {
    const response = yield call(TipoUser.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

    console.log(response);
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* watcherTipoUser() {
  yield takeLatest(actionType.LOAD_TIPO_USERS, loadTipoUsersWorker);
  yield takeLatest(actionType.STORE_TIPO_USER, storeTipoUserWorker);
  yield takeLatest(actionType.SHOW_TIPO_USER, showTipoUserWorker);
  yield takeLatest(actionType.UPDATE_TIPO_USER, updateTipoUserWorker);
  yield takeLatest(actionType.REMOVER_TIPO_USER, removerTipoUserWorker);
  yield takeLatest(actionType.LOAD_MENUS_TIPO_USER, loadMenusTipoUsersWorker);
  yield takeLatest(actionType.LOAD_RECURSOS_TIPO_USER, loadRecursosTipoUsersWorker);
  yield takeLatest(actionType.ADD_MENUS_TIPO_USER, addMenuTipoUserWorker);
  yield takeLatest(actionType.ADD_RECURSOS_TIPO_USER, addRecursoTipoUserWorker);
  yield takeLatest(actionType.LIST_CUSTOM_TIPO_USER, listCustomTipoUsersWorker);
}

export default function* saga() {
  yield all([watcherTipoUser()]);
}
