import React from 'react';
import { Row, Col, Tabs, Tab, Form } from 'react-bootstrap';
import FileInput from '~/components/FileInput';
import styles from './styles';
import maskCEP from '~/utils/formatCep'
import formatPhone from '~/utils/formatPhone'
import formatCpfCnpj from '~/utils/formatCpfCnpj'

export default function FieldsPF({
  fields, setFields, ufData,
  handleBuscaCep, handleChange
}) {
  return (
    <Tabs defaultActiveKey="dados_pessoais">
      <Tab title="Dados Pessoais" eventKey="dados_pessoais" style={styles.tab}>
        <Row>
          <Form.Group as={Col} md="6">
            <Form.Label>Nome completo</Form.Label>
            <Form.Control
              // isInvalid={!!errors.nome}
              name="nome"
              // onChange={handleChange}
              placeholder="Nome completo"
              type="text"
              value={fields.nome || ''}
              readOnly
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.nome}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              // isInvalid={!!errors.email}
              name="email"
              // onChange={handleChange}
              placeholder="E-mail"
              type="email"
              value={fields.email || ''}
              readOnly
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>CPF</Form.Label>
            <Form.Control
              // isInvalid={!!errors.cpf}
              maxLength="11"
              name="cpf"
              // onChange={handleChange}
              placeholder="CPF"
              type="text"
              value={formatCpfCnpj(fields.cpf ?? '') || ''}
              readOnly
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.cpf}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Anexo</Form.Label>
            <FileInput
              // dataError={errors.anexo_cpf}
              name="anexo_cpf"
              data={fields}
              // setData={setFields}
              disabled
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.anexo_cpf}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>RG UF</Form.Label>
            <Form.Control
              as="select"
              // isInvalid={!!errors.uf_rg_id}
              name="uf_rg_id"
              // onChange={handleChange}
              placeholder="UF RG"
              type="text"
              value={fields.uf_rg_id || '' ? fields.uf_rg_id || '' : 1}
              disabled
            >
              {ufData[0] && ufData.map(
                uf => (
                  <option key={uf.id} value={uf.id}>
                    {uf.uf}
                  </option>
                )
              )}
            </Form.Control>
            {/* <Form.Control.Feedback type="invalid">
              {errors.uf_rg_id}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>RG</Form.Label>
            <Form.Control
              // isInvalid={!!errors.rg}
              name="rg"
              // onChange={handleChange}
              placeholder="RG"
              type="text"
              value={fields.rg || ''}
              readOnly
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.rg}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>Anexo</Form.Label>
            <FileInput
              // dataError={errors.anexo_rg}
              name="anexo_rg"
              data={fields}
              // setData={setFields}
              disabled
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.anexo_rg}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Fone de Contato</Form.Label>
            <Form.Control
              // isInvalid={!!errors.telefone}
              name="telefone"
              onChange={handleChange}
              placeholder="Fone de contato"
              type="text"
              maxLength="15"
              value={formatPhone(fields.telefone ?? '') || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.telefone}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Número de WhatsApp</Form.Label>
            <Form.Control
              // isInvalid={!!errors.numero_whatsapp}
              name="numero_whatsapp"
              onChange={handleChange}
              placeholder="Número de WhatsAp"
              type="text"
              maxLength="15"
              value={formatPhone(fields.numero_whatsapp ?? '') || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.numero_whatsapp}
            </Form.Control.Feedback> */}
          </Form.Group>
        </Row>
      </Tab>
      <Tab title="Endereço" eventKey="endereco" style={styles.tab}>
        <Row>
          <Form.Group as={Col} md="2">
            <Form.Label>CEP</Form.Label>
            <Form.Control
              // isInvalid={!!errors.cep}
              name="cep"
              onBlur={handleBuscaCep}
              onChange={handleChange}
              placeholder="CEP"
              type="text"
              maxLength="9"
              value={maskCEP(fields.cep ?? '') || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.cep}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>Logradouro</Form.Label>
            <Form.Control
              // isInvalid={!!errors.logradouro}
              name="logradouro"
              onChange={handleChange}
              placeholder="Logradouro"
              type="text"
              value={fields.logradouro || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.logradouro}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="2">
            <Form.Label>Número</Form.Label>
            <Form.Control
              // isInvalid={!!errors.numero_endereco}
              name="numero_endereco"
              onChange={handleChange}
              placeholder="Número"
              type="text"
              value={fields.numero_endereco || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.numero_endereco}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>Bairro</Form.Label>
            <Form.Control
              // isInvalid={!!errors.bairro}
              name="bairro"
              onChange={handleChange}
              placeholder="Bairro"
              type="text"
              value={fields.bairro || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.bairro}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>Cidade</Form.Label>
            <Form.Control
              // isInvalid={!!errors.cidade}
              name="cidade"
              onChange={handleChange}
              placeholder="Cidade"
              type="text"
              value={fields.cidade || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.cidade}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>Estado</Form.Label>
            <Form.Control
              as="select"
              // isInvalid={!!errors.uf_endereco_id}
              name="uf_endereco_id"
              onChange={handleChange}
              placeholder="UF"
              type="text"
              value={fields.uf_endereco_id || '' ? fields.uf_endereco_id || '' : 1}
            >
              {ufData[0] && ufData.map(
                uf => (
                  <option key={uf.id} value={uf.id}>
                    {uf.uf}
                  </option>
                )
              )}
            </Form.Control>
            {/* <Form.Control.Feedback type="invalid">
              {errors.uf_endereco_id}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>Comprovante</Form.Label>
            <FileInput
              // dataError={errors.anexo_endereco}
              name="anexo_endereco"
              data={fields}
              setData={setFields}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.anexo_endereco}
            </Form.Control.Feedback> */}
          </Form.Group>
        </Row>
      </Tab>
    </Tabs>
  );
}
