export const LOAD_VISTORIAS = 'LOAD_VISTORIAS';
export const SELECT_VISTORIAS = 'SELECT_VISTORIAS';
export const VISTORIA_ERROR = 'VISTORIA_ERROR';
export const VISTORIA_GET_ERRORS = 'VISTORIA_GET_ERRORS';
export const VISTORIAS_DATA = 'VISTORIAS_DATA';
export const STORE_VISTORIA = 'STORE_VISTORIA';
export const LOAD_VISTORIA = 'LOAD_VISTORIA';
export const GET_VISTORIA = 'GET_VISTORIA';
export const REMOVER_VISTORIA = 'REMOVER_VISTORIA';
export const SHOW_VISTORIA = 'SHOW_VISTORIA';
export const UPDATE_VISTORIA = 'UPDATE_VISTORIA';
export const LOAD_VISTORIA_ENTIDADE = 'LOAD_VISTORIA_ENTIDADE';
