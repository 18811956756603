import React, { useCallback, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Table } from 'react-bootstrap';
import { FaList, FaEdit, FaDownload} from 'react-icons/fa';
import { Content } from '~/layouts/Dash/components';
import { useSelector, useDispatch } from 'react-redux';
import { Loading, Paginate } from '~/components';
import {
  loadPagamentosPendentes,
  changeStatusPayment
} from '~/store/modules/pagamentos_pendentes/action';

export default function List() {
  const breadcrumbs = [
    {
      label: 'Pagamentos Pendentes',
      to: '/pagamentos-pendentes',
      active: false,
    },
    {
      label: 'Lista',
      to: '/pagamentos-pendentes',
      active: true,
    },
  ];
  // const processos = {data:[
  //       { protocolo: '01-0015-2019-13845598000196-01', created_at: '20.02.2020', data_distribuicao: '22.02.2020', atividade_id: 'Piscicultura', pago:'pago'},
  //       { protocolo: '01-0015-2019-13845598000196-02', created_at: '20.02.2021', data_distribuicao: '22.02.2021', atividade_id: 'Piscicultura', pago:'pendente'},
  //       { protocolo: '01-0015-2019-13845598000196-03', created_at: '20.02.2022', data_distribuicao: '22.02.2022', atividade_id: 'Piscicultura', pago:'atrasado'},
  //       { protocolo: '01-0015-2019-13845598000196-04', created_at: '20.02.2023', data_distribuicao: '22.02.2023', atividade_id: 'Piscicultura', pago:'expirado'},
  //       { protocolo: '01-0015-2019-13845598000196-05', created_at: '20.02.2024', data_distribuicao: '22.02.2024', atividade_id: 'Piscicultura', pago:'pendente'},
  //       { protocolo: '01-0015-2019-13845598000196-06', created_at: '20.02.2025', data_distribuicao: '22.02.2025', atividade_id: 'Piscicultura', pago:'expirado'},
  //       { protocolo: '01-0015-2019-13845598000196-07', created_at: '20.02.2026', data_distribuicao: '22.02.2026', atividade_id: 'Piscicultura', pago:'pago'},
  // ]}

  const dispatch = useDispatch();
  const { processos, meta } = useSelector(
    ({ PagamentosPendentesReducer }) => PagamentosPendentesReducer
  );
// console.log('processos: ',processos);
  useEffect(() => {
    dispatch(loadPagamentosPendentes({}));
  }, [dispatch]);

  const handleChangeStatusPayment = useCallback(async id => {
    Swal.fire({
      title: 'Pagar?',
      text: 'Deseja pagar este boleto?!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then(async result => {
      if (result.value) {
        try {
          await dispatch(changeStatusPayment({ id }));
          await Swal.fire(
            'Sucesso',
            'Boleto pago com sucesso!',
            'success'
          );
        } catch (error) {
          await Swal.fire(
            'Erro',
            'Erro ao pagar',
            'error'
          );
        }
      }
    });
  },[dispatch]);

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="PAGAMENTOS PENDENTES"
    >
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">
            <FaList />  Processos com pagamentos pendentes
            <form className="form-inline">
              <div className="form-group">
                <label>Busca de Processo: </label>
                <input
                  className="form-control"
                  placeholder="N. do Processo"
                  type="text"
                />
              </div>
            </form>
          </h3>
        </div>
        <div className="card-body table-responsive">
          <Table
            bordered
            hover
            striped
          >
            <thead>
              <tr>
                <th> Número Processo </th>
                <th> Data Entrada </th>
                <th> Data Distribuição </th>
                <th> Atividade </th>
                <th> Status do pagamento </th>
                <th> Ações </th>
              </tr>
            </thead>
            <tbody>
              { processos.data && processos.data.map(({id, protocolo, created_at, atividade_id, pago, url_boleto})=> {
                console.log('url_boleto: ',url_boleto);
                return (
                  <tr>
                    <td>{protocolo}</td>
                    <td>{created_at}</td>
                    <td>{created_at}</td>
                    <td>{atividade_id}</td>
                    <td>
                      <div className="btn-group">
                        <div
                          className={pago?'btn btn-success':
                            'btn btn-danger'}
                        >
                          {pago?'PAGO':'PAGAMENTO PENDENTE'}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="btn-group float-left">
                        <a
                          className="btn btn-default"
                          href={url_boleto}
                          target="new"
                        >
                          <FaDownload /> Baixar boleto
                        </a>
                        <button
                          className="btn btn-info"
                          onClick={() => handleChangeStatusPayment(id)}
                        >
                          <FaEdit />&nbsp;
                          Confirmar Pagamento
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {!processos.data && <Loading />}
        </div>
        <div className="card-footer clearfix">
          <Paginate
            load={loadPagamentosPendentes}
            meta={meta}
          />
        </div>
      </div>
    </Content>
  );
}
