import * as Yup from 'yup';
import { validateCNPJ, validateCPF, validatePhone, validateCep } from 'validations-br';

const schema = Yup.object().shape({
  nome: Yup.string().required('Nome obrigatório'),
  cpf_cnpj_tecnico: Yup.string()
    .test(
      "is-cpf_cnpj_tecnico",
      "O CPF/CNPJ não é valido",
      (value) => {
        if(validateCPF(value) || validateCNPJ(value)) {
          return true;
        }
        return false;
      }
    )
    .required('O CPF/CNPJ é obrigatório'),
  anexo_cpf_cnpj: Yup.string().required('Anexo do CPF/CNPJ é obrigatório'),
  rg: Yup.string().required('RG obrigatório'),
  anexo_rg: Yup.string().required('Anexo do RG é obrigatório'),
  uf_rg_id: Yup.number().required('Estado obrigatório'),
  data_nasc: Yup.date().required('Data obrigatória').nullable()
    .transform((curr, orig) => orig === '' ? null : curr),
  logradouro: Yup.string().required('Logradouro obrigatório'),
  anexo_endereco: Yup.string().required('Anexo do logradouro obrigatório'),
  numero_endereco: Yup.string().required('Nº obrigatório'),
  bairro: Yup.string().required('bairro obrigatório'),
  cidade: Yup.string().required('Cidade obrigatório'),
  cep: Yup.string()
  .test(
    "is-cep",
    "CEP não é valido",
    (value) => validateCep(value)
  ),
  numero_whatsapp: Yup.string().nullable()
  .test(
    "is-numero_whatsapp",
    "Whatsapp não é valido",
    (value) => {
      if(!!value) {
        return validatePhone(value)
      }
      return true;
    }
    ),
  telefone: Yup.string()
  .test(
    "is-telefone",
    "Fone de contato não é valido",
    (value) => validatePhone(value)
  )
  .required('Fone obrigatório'),
  uf_endereco_id: Yup.number().required('Estado obrigatório'),
  youtube: Yup.string().url('URL inválida'),
  facebook: Yup.string().url('URL inválida'),
  linkdin: Yup.string().url('URL inválida'),
  twiter: Yup.string().url('URL inválida'),
  formacao:  Yup.string().required('Formação obrigatório'),
  anexo_formacao: Yup.string().required('Anexo da formação obrigatório'),
  formacao_2: Yup.string(),
  especializacao:  Yup.string(),
  mestrado:  Yup.string(),
  doutorado:  Yup.string(),
  check_termos: Yup.boolean().required('Termos de uso obrigatório'),
  email: Yup.string()
    .email()
    .required('E-mail obrigatório'),
  password: Yup.string().required('Senha obrigatória'),
  confirmacao_senha: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Senhas não conferem.')
    .required('Senha de confirmação obrigatória'),
});

export default schema;
