import React from 'react';
import plugins from 'suneditor/src/plugins';
import SunEditor from 'suneditor-react';
import { Form } from 'react-bootstrap';

const FormCad = ({ handleSubmit, schema,  legislacao, setLegislacao, errors }) => {
  const btnForEditor = [
    ['undo', 'redo'],
    ['font', 'fontSize', 'formatBlock'],
    ['paragraphStyle', 'blockquote'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    ['fontColor', 'hiliteColor', 'textStyle'],
    ['removeFormat'],
    '/', // Line break
    ['outdent', 'indent'],
    ['align', 'horizontalRule', 'list', 'lineHeight'],
    ['table', 'link'], // You must add the 'katex' library at options to use the 'math' plugin.
    ['fullScreen', 'showBlocks', 'codeView'],
    ['preview'],
  ];

  const handleChange = event => {
    const { name, value, files } = event.target;

    setLegislacao({
      ...legislacao,
      [name]: files ? files[0] : value,
    });
  };

  const handleDescricaoChange = (content) => {
    setLegislacao(prevValue => ({
      ...prevValue,
      descricao: content
    }));
  };

  return (
    <Form
      id="form_legislacao"
      onSubmit={handleSubmit}
      schema={schema}
    >
      <div className="form-group">
        <label htmlFor="tipo"> Tipo </label>
        <Form.Control
          id="tipo"
          isInvalid={!!errors.tipo}
          name="tipo"
          onChange={handleChange}
          placeholder="Descrição"
          rows={8}
          type="text"
          value={legislacao.tipo || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.tipo}
        </Form.Control.Feedback>
      </div>
      <div className="form-group">
        <label htmlFor="legislacao"> Legislação </label>
        <Form.Control
          id="legislacao"
          isInvalid={!!errors.legislacao}
          name="legislacao"
          onChange={handleChange}
          placeholder="Descrição"
          rows={8}
          type="text"
          value={legislacao.legislacao || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.legislacao}
        </Form.Control.Feedback>
      </div>
      <div className="form-group">
        <label htmlFor="descricao"> Descrição </label>
        <SunEditor
          // disable={onlyView}
          lang="pt_br"
          name="descricao"
          onChange={handleDescricaoChange}
          setContents={legislacao.descricao}
          setOptions={{
            plugins: [plugins],
            height: 200,
            buttonList: btnForEditor,
          }}
        />
      </div>
    </Form>
  );
};

export default FormCad;
