import * as Yup from 'yup';

const schema = Yup.object().shape({
  nome: Yup.string().required('Nome obrigatório'),
  email: Yup.string().email().required('Valor requerido'),
  password: Yup.string().required('Valor requerido'),
  confirmacao_senha: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Senhas não conferem'
  ).required('Valor requerido'),
  cpf_cnpj: Yup.string().required('Valor requerido'),
  rg: Yup.string().required('Valor requerido'),
  logradouro:Yup.string().required('Valor requerido'),
  bairro:Yup.string().required('Valor requerido'),
  cep:Yup.string().required('Valor requerido'),
  cidade:Yup.string().required('Valor requerido'),
  telefone: Yup.string().required('Valor requerido'),
  numero_whatsapp: Yup.string().required('Valor requerido'),
  formacao: Yup.string().required('Valor requerido'),
  anexo_cpf_cnpj: Yup.string().required('Valor requerido'),
  anexo_rg: Yup.string().required('Valor requerido'),
  anexo_endereco: Yup.string().required('Valor requerido'),
  anexo_formacao: Yup.string().required('Valor requerido')
});

export default schema;
