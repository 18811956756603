import * as Yup from 'yup';
import {
  format as formatCPF,
  validate as validateCPF,
} from 'gerador-validador-cpf';

const testCPF = input => {
  const cpf = formatCPF(input);
  return validateCPF(cpf);
};

const schema = Yup.object().shape({
  nome: Yup.string().required('Valor requerido'),
  cpf: Yup.string()
    .test('valid_cpf', 'CPF inválido', val => {
      if (
        val !== null &&
        val !== '' &&
        val !== undefined &&
        val.length === 11
      ) {
        return testCPF(val);
      }
      return true;
    })
    .length(11, 'Tamanho incorreto')
    .required('Valor requerido'),
  rg: Yup.string().required('Valor requerido'),
  logradouro: Yup.string().required('Valor requerido'),
  bairro: Yup.string().required('Valor requerido'),
  cep: Yup.string().required('Valor requerido'),
  numero_whatsapp: Yup.string(),
  telefone: Yup.string(),
  email: Yup.string()
    .email()
    .required('Valor requerido'),
  password: Yup.string().required('Valor requerido'),
  confirmacao_senha: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Senhas não conferem')
    .required('Valor requerido'),
});

export default schema;
