import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

export default function PasswordInput({ ...rest }) {
  const [visible, setVisible] = useState(false);

  return (
    <InputGroup>
      <Form.Control
        type={visible ? 'text' : 'password'}
        {...rest}
      />
      <InputGroup.Append>
        <button
          type="button"
          className="btn btn-default"
          onClick={() => setVisible(!visible)}
        >
          <i className={`fas fa-${visible ? 'eye-slash' : 'eye'}`}></i>
        </button>
      </InputGroup.Append>
    </InputGroup>
  );
}
