import * as Yup from 'yup';
import { validateCNPJ, validateCPF, validatePhone } from 'validations-br';
const schema = Yup.object().shape({
  cpf_cnpj_tecnico: Yup.string()
    .test(
      "is-cpf_cnpj_tecnico",
      "O CPF/CNPJ não é valido",
      (value) => {
        if(validateCPF(value) || validateCNPJ(value)) {
          return true;
        }
        return false;
      }
    )
    .required('O CPF/CNPJ é obrigatório'),
  // anexo_cpf_cnpj: Yup.string().required('Anexo do CPF/CNPJ é obrigatório'),
  rg: Yup.string().required('RG obrigatório'),
  // anexo_rg: Yup.string().required('Anexo do RG é obrigatório'),
  uf_rg_id: Yup.number().required('Estado obrigatório'),
  data_nasc: Yup.date().required('Data obrigatória').nullable()
    .transform((curr, orig) => orig === '' ? null : curr),
  logradouro: Yup.string().required('Logradouro obrigatório'),
  anexo_endereco: Yup.string().required('Anexo do logradouro obrigatório'),
  numero_endereco: Yup.string().required('Nº obrigatório'),
  bairro: Yup.string().required('bairro obrigatório'),
  cidade: Yup.string().required('Cidade obrigatório'),
  cep: Yup.string().required('CEP obrigatório'),
  numero_whatsapp: Yup.string().nullable()
  .test(
    "is-numero_whatsapp",
    "Whatsapp não é valido",
    (value) => {
      if(!!value) {
        return validatePhone(value)
      }
      return true;
    }
    ),
  telefone: Yup.string()
  .test(
    "is-telefone",
    "Fone de contato não é valido",
    (value) => validatePhone(value)
  )
  .required('Fone obrigatório'),
  uf_endereco_id: Yup.number().required('Estado obrigatório'),
  youtube: Yup.string().url('URL inválida').nullable(),
  facebook: Yup.string().url('URL inválida').nullable(),
  linkdin: Yup.string().url('URL inválida').nullable(),
  twiter: Yup.string().url('URL inválida').nullable(),
  formacao:  Yup.string().required('Formação obrigatório'),
  anexo_formacao: Yup.string().required('Anexo da formação obrigatório'),
  formacao_2: Yup.string().nullable(),
  especializacao:  Yup.string().nullable(),
  mestrado:  Yup.string().nullable(),
  doutorado:  Yup.string().nullable(),
});

export default schema;
