import React from 'react';
import { Form } from 'react-bootstrap';

const FormCad = ({ handleSubmit, schema, estado, setEstado, errors }) => {
  const handleChange = event => {
    const { name, value, files } = event.target;
    setEstado({
      ...estado,
      [name]: files ? files[0] : value,
    });
  };

  return (
    <Form id="form_estado" onSubmit={handleSubmit} schema={schema}>
      <div className="form-group">
        <label htmlFor="nome"> Nome </label>
        <Form.Control
          id="nome"
          isInvalid={!!errors.nome}
          name="nome"
          onChange={handleChange}
          placeholder="Nome do estado"
          type="text"
          value={estado.nome || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.nome}
        </Form.Control.Feedback>
      </div>
      <div className="form-group">
        <label htmlFor="uf"> UF </label>
        <Form.Control
          id="uf"
          isInvalid={!!errors.uf}
          name="uf"
          onChange={handleChange}
          placeholder="UF do estado"
          type="text"
          value={estado.uf || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.uf}
        </Form.Control.Feedback>
      </div>

      <div className="form-group">
        <label htmlFor="cod_ibge"> Código IBGE </label>
        <Form.Control
          id="cod_ibge"
          isInvalid={!!errors.cod_ibge}
          name="cod_ibge"
          onChange={handleChange}
          placeholder="Código IBGE"
          type="text"
          value={estado.cod_ibge || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.cod_ibge}
        </Form.Control.Feedback>
      </div>
    </Form>
  );
};

export default FormCad;
