import * as actions from './actionType';

export const NotaTecAmbientalReducer = (state = {select_nota_tec_ambientals: [], nota_tec_ambientals: [], nota_tec_ambiental: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.NOTA_TEC_AMBIENTALS_DATA:
      return {
        ...state,
        nota_tec_ambientals: action.nota_tec_ambientals.data,
        links: action.nota_tec_ambientals.data.links,
        meta: action.nota_tec_ambientals.data.meta,
      };
    case actions.SELECT_NOTA_TEC_AMBIENTALS:
      return {
        ...state,
        select_nota_tec_ambientals: action.nota_tec_ambientals.data
      };
    case actions.GET_NOTA_TEC_AMBIENTAL:
      return {
        ...state,
        nota_tec_ambiental: action.nota_tec_ambiental.data.data,
        status_response: action.nota_tec_ambiental.status,
      };
    case actions.NOTA_TEC_AMBIENTAL_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
