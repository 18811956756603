export const LOAD_USERS = 'LOAD_USERS';
export const LOAD_USER = 'LOAD_USER';
export const LOAD_USER_PESSOA = 'LOAD_USER_PESSOA';
export const SELECT_USERS = 'SELECT_USERS';
export const USER_ERROR = 'USER_ERROR';
export const USER_GET_ERRORS = 'USER_GET_ERRORS';
export const USERS_DATA = 'USERS_DATA';
export const STORE_USER = 'STORE_USER';
export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const GET_USER_PESSOA = 'GET_USER_PESSOA';
export const REMOVE_USER = 'REMOVE_USER';
export const RESTORE_USER = 'RESTORE_USER';
export const SHOW_USER = 'SHOW_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const LOAD_USERS_ANALISTA_ENTIDADE = 'LOAD_USERS_ANALISTA_ENTIDADE';
export const USERS_ANALISTAS_ENTIDADE_DATA = 'USERS_ANALISTAS_ENTIDADE_DATA';
export const CHANGE_STATUS_CODE = 'CHANGE_STATUS_CODE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const MUTATE_PASSWORD = 'MUTATE_PASSWORD';
export const FILTER_USERS = 'FILTER_USERS';
