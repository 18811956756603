import { CNPJ_DATA, CNPJ_ERROR } from './actionType';

export const CadastroJuridicaReducer = (state = {}, action) => {
  switch (action.type) {
    case CNPJ_ERROR:
      return {
        ...state,
        cnpj_error: action.error
      }
    case CNPJ_DATA:
      return {
        ...state,
        cnpj_error: action.data
      }
    default:
      return state;
  }
}
