import React, {  useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Loading } from '~/components';
import { loadMensagems } from '~/store/modules/mensagem/action'
import { NovaMensagem, LerMensagem } from './components';
import formatDataHora  from '~/utils/formatDataHora';
import { useHistory } from 'react-router-dom';

const MensagensProcesso = ({ processo }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let [show, setShow] = useState(false);
  let [mensagens, setMensagens] = useState([]);
  const { mensagems } = useSelector(({ MensagemReducer }) => MensagemReducer);
  const { user_auth } = useSelector(({ LoginReducer }) => LoginReducer);

  useEffect(() => {
    if (processo && processo.id) {
      dispatch(loadMensagems(processo.id))
    }
  }, [dispatch, processo]);

  useEffect(()=> {
    if(mensagems && mensagems.data) {

      setMensagens(mensagems.data)
    } else {
      setMensagens([]);
    }
  }, [mensagems]);

  const closeModal = () => {
    history.go();
  };

  return (
    <>
      {mensagems  ? (
        <>
          <div className="card">
            <div className="card-header">
              <h3 className="card-title"> Mensagens</h3>
            </div>
            <div className="card-body">
              <button
                className="btn btn-primary btn-block"
                onClick={() => setShow(true)}
              >
                <i
                  aria-hidden="true"
                  className="fa fa-comments"
                /> Mensagens
                do Processo
              </button>
            </div>
          </div>
          <Modal
            animation
            aria-labelledby="example-custom-modal-styling-title"
            dialogClassName="modal-xl"
            onHide={closeModal}
            show={show}
          >
            <Modal.Header closeButton>
              <Modal.Title> <i className="fa fa-comments"></i>  Mensagens do Processo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
               <div className="col-md-12">
                  <div className="card card-primary card-outline">
                    <div className="card-header">
                      <h3 className="card-title"><i className="fas fa-inbox"></i> Mensagens</h3>

                      <div className="card-tools">
                        {processo && (<NovaMensagem processo={processo} />)}

                      </div>
                    </div>
                    <div className="card-body p-0">
                      <div className="table-responsive mailbox-messages">
                        <table className="table table-hover table-striped">
                          <thead>
                          <tr>
                            <th>Enviado por</th>
                            <th colSpan={2}>Assunto</th>
                            <th>Data hora</th>
                          </tr>
                          </thead>
                          <tbody>
                          {mensagens && mensagens[0] && mensagens.map((msg) => (
                            <tr key={msg.id}>
                              <td className="mailbox-name">
                                { (!msg.data_hora_leitura) ? (<b>
                                  { msg.remetente && (<>
                                    {(msg.user_remetente_id === user_auth.user.id) ? (<>{'Eu: '}</>) : (<>{msg.remetente.nome}: </>)}
                                  </>)}
                                </b>) : (<>
                                  {(msg.user_remetente_id === user_auth.user.id) ? (<>{'Eu: '} </>) : (<>{ msg.remetente && msg.remetente.nome}: </>)}

                                  </>) }
                              </td>
                              <td className="mailbox-subject">
                                { (!msg.data_hora_leitura) ? (<b>{msg.titulo}</b>) : (<>{msg.titulo}</>) }
                              </td>
                              <td className="mailbox-attachment"> { msg.anexos && msg.anexos[0] && <i className="fas fa-paperclip"/> } </td>
                              <td className="mailbox-date">
                                { (!msg.data_hora_leitura) ? (<b>{formatDataHora(msg.created_at)}</b>) : (<>{formatDataHora(msg.created_at)}</>) }
                              </td>
                              <td>
                                <LerMensagem mensagem={msg} processo={processo}/>
                              </td>
                            </tr>
                          ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
               </div>
            </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-default"
                onClick={closeModal}
                type="button"
              >
                <i className="fa fa-window-close" /> Fechar
              </button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default MensagensProcesso;
