import React, { Fragment } from 'react';
import {
  Page, Text, View, Document
} from '@react-pdf/renderer';
import styles from './styles';
import { Header, Footer, Table, Row, Col } from '~/components/PDF';
import procedureTypes from '../procedureTypes';

export default function RTLAPDF({
  months, procedureType, period, entityName, organName,
  logoSiblam, logoEntity, countLicensesByDate,
  countGia, countMeasuredIndicators, logoCounty
}) {
  const status = procedureType ? procedureTypes.filter(
  procedure_type => procedure_type.value === procedureType
  )[0].label : null;

  return (
    <Document title="Relatório Técnico de Licenciamento Ambiental">
      <Page size="A4" style={styles.page} wrap>
        <Header
          logoEntity={logoEntity}
          entityName={entityName}
          organName={organName}
          LogoCounty={logoCounty}
          styles={styles}
        />

        <View style={{ marginTop: '-10px' }}>
          <Text style={[styles.title, { textAlign: 'center' }]}>
            Relatório Técnico de Licenciamento Ambiental
          </Text>
        </View>

        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          {!!status && (
            <View style={[styles.section, { alignItems: 'flex-start', flexGrow: 1 }]}>
              <View
                style={{
                  flexDirection: 'row',
                  borderTop: '1px solid black',
                  borderLeft: '1px solid black'
                }}
              >
                <Text style={[styles.cell, styles.semibold]}>
                  Status
                </Text>
                <Text style={[styles.cell, styles.th]}>
                  {status}
                </Text>
              </View>
            </View>
          )}

          <View style={[styles.section, { alignItems: 'flex-end', flexGrow: 1 }]}>
            <View
              style={{
                flexDirection: 'row',
                borderTop: '1px solid black',
                borderLeft: '1px solid black'
              }}
            >
              <Text style={[styles.cell, styles.semibold]}>
                Período
              </Text>
              <Text style={[styles.cell, styles.th]}>
                {period}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.section} wrap={false}>
          <Text style={styles.label}>
            1. Quantidade de licenças emitidas no período
          </Text>
          <Table>
            <Row header>
              <Col style={styles.semibold}>
                Periodo
              </Col>
              <Col style={styles.semibold}>
                Quantidade
              </Col>
              {(countLicensesByDate.length > 6 || !countLicensesByDate.length) && (
                <Fragment>
                  <Col style={styles.semibold}>
                    Periodo
                  </Col>
                  <Col style={styles.semibold}>
                    Quantidade
                  </Col>
                </Fragment>
              )}
            </Row>

            {!countLicensesByDate.length && (
              <Row>
                <Col>Sem Registros</Col>
              </Row>
            )}

            {countLicensesByDate.map(
              ({ month, year, count }, index) => index < 6 && (
                <Row key={`table-one-key-${index}`}>
                  <Col>{`${months[month]}/${year}`}</Col>
                  <Col>{count}</Col>

                  {countLicensesByDate.length > 6 && (
                    !!countLicensesByDate[index + 6] ? (
                      <Fragment>
                        <Col>{`${months[countLicensesByDate[index + 6].month]}/${year}`}</Col>
                        <Col>{countLicensesByDate[index + 6].count}</Col>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Col/>
                        <Col/>
                      </Fragment>
                    )
                  )}
                </Row>
              )
            )}
          </Table>
        </View>

        <View style={styles.section}>
          <Text style={styles.label}>
            2. Quantidade de atividades/empreendimentos por grau de impacto ambiental
          </Text>
          <Table>
            <Row header>
              <Col style={styles.semibold}>
                Grau de Impacto Ambiental
              </Col>
              <Col style={styles.semibold}>
                Quantidade
              </Col>
            </Row>

            <Row>
              <Col>Baixo</Col>
              <Col>{countGia.count_low}</Col>
            </Row>

            <Row>
              <Col>Médio</Col>
              <Col>{countGia.count_middle}</Col>
            </Row>

            <Row>
              <Col>Alto</Col>
              <Col>{countGia.count_high}</Col>
            </Row>

            <Row>
              <Col>Sem Mensuração</Col>
              <Col>{countGia.count_immeasurable}</Col>
            </Row>
          </Table>
        </View>

        <View style={styles.section}>
          <Text style={styles.label}>
            3. Quantidade de indicadores ambientais mensurados
          </Text>
          <Table>
            <Row header>
              <Col style={styles.semibold}>
                Código
              </Col>
              <Col style={styles.semibold} widthPercentage={60}>
                Nome
              </Col>
              <Col style={styles.semibold}>
                Quantidade
              </Col>
            </Row>

            {!countMeasuredIndicators.length && (
              <Row>
                <Col>Sem Registros</Col>
              </Row>
            )}

            {countMeasuredIndicators.map(
              ({code, name, count}, index) => (
                <Row key={`table-three-key-${index}`}>
                  <Col>{code}</Col>
                  <Col widthPercentage={60}>
                    {name}
                  </Col>
                  <Col>{count}</Col>
                </Row>
              )
            )}
          </Table>
        </View>

        <Footer
          logoSiblam={logoSiblam}
          styles={styles}
        />
      </Page>
    </Document>
  );
}
