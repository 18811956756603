import React, { Children } from 'react';
import styles from './styles';
import { View } from '@react-pdf/renderer';

const Table = ({ children }) => {
  return (
    <View style={styles.table}>
      {Children.map(children, (child) => child)}
    </View>
  );
}

export default Table;
