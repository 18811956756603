import React from 'react';
import Select from 'react-select';

const SelectMultiple = ({ name, options, value, onChange, isLoading }) => {
  return (
    <Select
      className="basic-multi-select"
      classNamePrefix="select"
      isLoading={isLoading}
      isMulti
      name={name}
      noOptionsMessage={() => 'Sem resultados.'}
      onChange={onChange}
      options={options}
      placeholder="Selecione"
      value={value}
    />
  );
};

export default SelectMultiple;
