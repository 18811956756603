import * as actions from './actionType';

export const loadProjetoProcessos = params => ({
  type: actions.LOAD_PROJETO_PROCESSOS,
  params
});
export const getProjetoProcessos = projeto_processos => ({
  type: actions.PROJETO_PROCESSOS_DATA,
  projeto_processos,
});

export const getSelectProjetoProcesso = projeto_processos => ({
  type: actions.SELECT_PROJETO_PROCESSOS,
  projeto_processos,
});

export const storeProjetoProcesso = projeto_processo => ({
  type: actions.STORE_PROJETO_PROCESSO,
  projeto_processo,

});
export const getProjetoProcesso = projeto_processo => ({
  type: actions.GET_PROJETO_PROCESSO,
  projeto_processo,
});

export const removerProjetoProcesso = (id) => ({
  type: actions.REMOVER_PROJETO_PROCESSO,
  id
});

export const showProjetoProcesso = id => ({
  type: actions.SHOW_PROJETO_PROCESSO,
  id,
});

export const updateProjetoProcesso = (projeto_processo, id) => ({
  type: actions.UPDATE_PROJETO_PROCESSO,
  projeto_processo,
  id
});


export const updateProjetoProcessoParecer = (id, projeto_processo) => ({
  type: actions.UPDATE_PROJETO_PROCESSO_PARECER,
  id,
  projeto_processo
});

export const getErros = errors => ({
  type: actions.PROJETO_PROCESSO_ERROR,
  errors,
});
