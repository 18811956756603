import * as actions from './actionType';

export const loadAnalises = (params) => ({
  type: actions.LOAD_ANALISES,
  params
});
export const getAnalises = analises => ({
  type: actions.ANALISES_DATA,
  analises,
});

export const getSelectAnalise = analises => ({
  type: actions.SELECT_ANALISES,
  analises,
});

export const storeAnalise = analise => ({
  type: actions.STORE_ANALISE,
  analise,

});

export const finalizarAnalise = (id) => ({
  type: actions.FINALIZAR_ANALISE,
  id,

});
export const getAnalise = analise => ({
  type: actions.GET_ANALISE,
  analise,
});

export const removerAnalise = (id) => ({
  type: actions.REMOVER_ANALISE,
  id
});

export const showAnalise = (id, params) => ({
  type: actions.SHOW_ANALISE,
  id,
  params
});

export const updateAnalise = (analise, id) => ({
  type: actions.UPDATE_ANALISE,
  analise,
  id
});

export const getErros = errors => ({
  type: actions.ANALISE_ERROR,
  errors,
});
