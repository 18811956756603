import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as RespostaQuestaoMemorial from '~/services/RespostaQuestaoMemorial';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadRespostaQuestaoMemorialsWorker({ params }) {
  try {
    const response = yield call(RespostaQuestaoMemorial.load, params);
    yield put(actions.getRespostaQuestaoMemorials(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}


function* showRespostaQuestaoMemorialWorker({id, params}) {
  try {
    const response = yield call(RespostaQuestaoMemorial.show, id, params);
    yield put(actions.getRespostaQuestaoMemorial(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updateRespostaQuestaoMemorialWorker({ resposta_questao_memorial, id}) {
  try {
    const response = yield call(RespostaQuestaoMemorial.update, id, resposta_questao_memorial);
    yield put(actions.getRespostaQuestaoMemorial(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeRespostaQuestaoMemorialWorker({resposta_questao_memorial}) {
  try {
    const response = yield call(RespostaQuestaoMemorial.store, resposta_questao_memorial);
    yield put(actions.getRespostaQuestaoMemorial(response));
    yield put(actions.getErros({save:true}));
    yield put(getErros({save:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* removerRespostaQuestaoMemorialWorker({id}) {
  try {
    yield call(RespostaQuestaoMemorial.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherRespostaQuestaoMemorial() {
  yield takeLatest(actionType.LOAD_RESPOSTA_QUESTAO_MEMORIALS, loadRespostaQuestaoMemorialsWorker);
  yield takeLatest(actionType.STORE_RESPOSTA_QUESTAO_MEMORIAL, storeRespostaQuestaoMemorialWorker);
  yield takeLatest(actionType.SHOW_RESPOSTA_QUESTAO_MEMORIAL, showRespostaQuestaoMemorialWorker);
  yield takeLatest(actionType.UPDATE_RESPOSTA_QUESTAO_MEMORIAL, updateRespostaQuestaoMemorialWorker);
  yield takeLatest(actionType.REMOVER_RESPOSTA_QUESTAO_MEMORIAL, removerRespostaQuestaoMemorialWorker);
}

export default function* saga() {
  yield all([watcherRespostaQuestaoMemorial()]);
}
