import * as Yup from 'yup';
import { validateCNPJ, validateCPF, validatePhone, validateCep } from 'validations-br';
const schema = Yup.object().shape({
  nome: Yup.string().required('Razão social obrigatório'),
  nome_fantasia: Yup.string().required('Nome fantasia obrigatório'),
  cnpj: Yup.string()
    .test(
      'is-cnpj',
      'CNPJ não é valido',
      (value) => validateCNPJ(value)
    )
    .required('CNPJ é obrigatório'),
  anexo_cnpj: Yup.string().required('Anexo do CNPJ obrigatório'),
  certidao_simplificada_jc: Yup.boolean().required('Certidão simplificada da junta comercial é obrigatório'),
  anexo_certidao_simplificada_jc: Yup.string()
    .when('certidao_simplificada_jc', {
      is: true,
      then: Yup.string().required('Anexo da certidão simplificada da junta comercial é obrigatório')
    }),
  logradouro: Yup.string().required('Logradouro obrigatório'),
  anexo_endereco: Yup.string().required('Anexo do endereço obrigatório'),
  numero_endereco: Yup.string().required('Nº do endereço obrigatório'),
  cidade: Yup.string().required('Cidade obrigatória'),
  bairro: Yup.string().required('bairro obrigatório'),
  cep: Yup.string()
  .test(
    "is-cep",
    "CEP não é valido",
    (value) => validateCep(value)
  ),
  numero_whatsapp: Yup.string().nullable()
  .test(
    "is-numero_whatsapp",
    "Whatsapp não é valido",
    (value) => {
      if(!!value) {
        return validatePhone(value)
      }
      return true;
    }
    ),
  telefone: Yup.string()  
  .test(
    "is-telefone",
    "Fone de contato não é valido",
    (value) => validatePhone(value)
  )
  .required('Fone obrigatório'),
  site: Yup.string().url('URL inválida').nullable(),
  uf_endereco_id: Yup.number().required('Estado obrigatório'),
  porte_empreendimento_id: Yup.number().required('Porte do empreendimento obrigatório'),
  nome_responsavel_legal: Yup.string().required('Nome obrigatório'),
  funcao_responsavel_legal: Yup.string().required('Função obrigatório'),
  cpf_responsavel_legal: Yup.string()
    .test(
      "is-cpf",
      "CPF não é valido",
      (value) => validateCPF(value)
    )
    .required('CPF obrigatório'),
  anexo_cpf_responsavel_legal: Yup.string().required('Anexo do CPF obrigatório'),
  rg_responsavel_legal: Yup.string().required('RG obrigatório'),
  anexo_rg_responsavel_legal: Yup.string().required('Anexo do RG obrigatório'),
  endereco_responsavel_legal: Yup.string().required('Endereço do responável legal obrigatório'),
  anexo_endereco_responsavel_legal: Yup.string().required('Anexo endereço do responável legal obrigatório'),
  telefone_responsavel_legal: Yup.string().required('Fone do representante legal obrigatório')
    .test(
      "is-telefone",
      "Fone do representante legal obrigatório",
      (value) => validatePhone(value)
    ),
  email_responsavel_legal: Yup.string().email().required('E-mail do representante legal obrigatório'),
  email: Yup.string().email().required('E-mail de acesso obrigatório'),
  check_termos: Yup.boolean().required('Termos de uso requerido'),
  password: Yup.string().required('Senha obrigatório'),
  confirmacao_senha: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Senhas não conferem')
    .required('Senha de confirmação obrigatório'),
});

export default schema;
