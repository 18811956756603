import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as Recurso from '~/services/Recurso';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadRecursosWorker() {
  try {
    const response = yield call(Recurso.load);
    yield put(actions.getRecursos(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* loadSelectRecursosWorker() {
  try {
    const response = yield call(Recurso.select);
    yield put(actions.getSelectRecursos(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* showRecursoWorker({id}) {
  try {
    const response = yield call(Recurso.show, id);
    yield put(actions.getRecurso(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updateRecursoWorker({ recurso, id}) {
  try {
    const response = yield call(Recurso.update, id, recurso);
    yield put(actions.getRecurso(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeRecursoWorker({recurso}) {
  try {
    const response = yield call(Recurso.store, recurso);
    yield put(actions.getRecurso(response));
    yield put(actions.getErros({save:true}));
    yield put(getErros({save:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* removerRecursoWorker({id}) {
  try {
    yield call(Recurso.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherRecurso() {
  yield takeLatest(actionType.LOAD_RECURSOS, loadRecursosWorker);
  yield takeLatest(actionType.LOAD_SELECT_RECURSOS, loadSelectRecursosWorker);
  yield takeLatest(actionType.STORE_RECURSO, storeRecursoWorker);
  yield takeLatest(actionType.SHOW_RECURSO, showRecursoWorker);
  yield takeLatest(actionType.UPDATE_RECURSO, updateRecursoWorker);
  yield takeLatest(actionType.REMOVER_RECURSO, removerRecursoWorker);
}

export default function* saga() {
  yield all([watcherRecurso()]);
}
