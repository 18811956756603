import React from 'react';
import { Form } from 'react-bootstrap';
import formatCpfCnpj from '~/utils/formatCpfCnpj'

const FormCad = ({ handleSubmit, schema, motorista, setMotorista, errors }) => {
  const handleChange = event => {
    const { name, value, files } = event.target;
    setMotorista({
      ...motorista,
      [name]: files ? files[0] : value,
    });
  };
  // const { potencial_poluidores } = useSelector(({ PotencialPoluidorReducer }) => PotencialPoluidorReducer);

  return (
    <Form
      id="form_motorista"
      onSubmit={handleSubmit}
      schema={schema}
    >
      <div className="form-group">
        <label htmlFor="nome"> Nome </label>
        <Form.Control
          id="nome"
          isInvalid={!!errors.nome}
          name="nome"
          onChange={handleChange}
          placeholder="Nome do Motorista"
          type="text"
          value={motorista.nome || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.nome}
        </Form.Control.Feedback>
      </div>

      <div className="form-group">
        <label htmlFor="cpf"> CPF </label>
        <Form.Control
          id="cpf"
          isInvalid={!!errors.cpf}
          name="cpf"
          onChange={handleChange}
          placeholder="CPF do motorista"
          type="text"
          maxLength="14"
          value={formatCpfCnpj(motorista.cpf ?? '') || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.cpf}
        </Form.Control.Feedback>
      </div>
    </Form>
  );
};

export default FormCad;
