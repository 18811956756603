import React from 'react';
import { Link } from 'react-router-dom';
import { Paginate } from '~/components';
import { FaEye, FaTrash, FaRegEdit, FaTimesCircle, FaPlusCircle } from 'react-icons/fa';
import { Button } from 'react-bootstrap';

const List = ({
  action,
  displayName,
  displayWith,
  displayOrderBy,
  id,
  createUrl,
  title,
  labelCol1,
  labelCol2,
  labelCol3,
  labelCol4,
  valueCol1,
  valueCol2,
  valueCol3,
  valueCol4,
  values,
  detalhe,
  path_detalhe,
  editar,
  remover,
  path_editar,
  handleRemove,
  styleCol1,
  styleCol2,
  styleCol3,
  handleActivate,
  desativar,
  importDataUrl
}) => {
  let path_detalhe_ = ['', ''];
  if (detalhe) {
    path_detalhe_ = path_detalhe.split('*');
  }

  return (
    <div className="card">
      <div className="card-header">
        <h2 className="card-title">{title}</h2>
        <div className="card-tools">
          <Link
            className="btn btn-success mr-1"
            to={{
              pathname: createUrl,
              state: { modal: true }
            }}
          >
            <i className="fa fa-plus" /> Adicionar
          </Link>
          {importDataUrl && (
            <Link
              className="btn btn-primary"
              to={{
                pathname: importDataUrl,
                state: { modal: true }
              }}
            >
              <i className="fa fa-plus-circle" /> Importar dados
            </Link>
          )}

        </div>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-bordered table-hover table-striped table-sm">
            <thead>
              <tr>
                {labelCol1 && (<th style={styleCol1 || {}}>{labelCol1}</th>)}
                {labelCol2 && (<th style={styleCol2 || {}}>{labelCol2}</th>)}
                {labelCol3 && (<th style={styleCol3 || {}}>{labelCol3}</th>)}
                {labelCol4 && (<th style={styleCol3 || {}}>{labelCol4}</th>)}
                <th className="text-right">Ações</th>
              </tr>
            </thead>
            <tbody>
              {values.data &&
                values.data.map((value, index) => (
                  <tr key={index}>
                    {
                      valueCol1 &&
                      (
                        <td>{value[valueCol1]}</td>
                      )
                    }

                    {
                      valueCol2 &&
                      (
                        <td>{value[valueCol2]}</td>
                      )
                    }

                    {
                      valueCol3 &&
                      (
                        <td>{valueCol3 === 'potencial_poluidor' ? (value[valueCol3] ? value[valueCol3].nome : value[valueCol3]) : value[valueCol3] ?
                          valueCol3 === 'potencial_poluidor' ? (value[valueCol3] ? value[valueCol3].nome : value[valueCol3]) : value[valueCol3] : 'Não informado'}
                        </td>
                      )
                    }
                    {
                      valueCol4 &&
                      (
                        <td>{value[valueCol4] ? 'SIM' : 'NÃO'}</td>
                      )
                    }

                    <td className="text-right">
                      <div className="btn-group">
                        {/* <Link
                            className="btn btn-default"
                            to={{
                              pathname: `/atividade/${entidade_id}/sub_menu/${atividade.id}/edit`,
                              state: { modal: true },
                            }}
                          >
                            <FaEdit /> Editar
                          </Link> */}
                        {
                          detalhe &&
                          (
                            <Link
                              className="btn btn-default"
                              to={{
                                pathname: path_detalhe_[0] + value.id + path_detalhe_[1]
                              }}
                            >
                              <FaEye /> Detalhes
                            </Link>
                          )
                        }
                        {
                          editar && !value.deleted_at &&
                          (
                            <Link
                              className="btn btn-info"
                              to={{
                                pathname: path_editar + value.id,
                                state: { modal: true }
                              }}
                            >
                              <FaRegEdit /> Editar
                            </Link>
                          )
                        }
                        {
                          remover && (
                            <button
                              className="btn btn-danger"
                              onClick={() => handleRemove(value.id)}
                            >
                              <FaTrash /> Remover
                            </button>
                          )
                        }
                        {
                          desativar && !value.deleted_at &&
                          (
                            <Button
                              className="btn btn-danger"
                              onClick={() => handleRemove(value.id)}
                            >
                              <FaTimesCircle /> Desativar
                            </Button>
                          )
                        }
                        {
                          !!value.deleted_at &&
                          (
                            <Button
                              className="btn btn-success"
                              onClick={() => handleActivate(value.id)}
                            >
                              <FaPlusCircle />  Ativar
                            </Button>
                          )
                        }
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="card-footer clearfix">
        <Paginate
          load={action}
          loadParans={{
            where: `${displayName}:${id}`,
            with: `${displayWith}`,
            ...(displayOrderBy ? { order_by: `${displayOrderBy}` } : {})
          }}
          meta={values.meta || values}
        />
      </div>
    </div>
  );
};

export default List;
