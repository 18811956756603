import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import buscaCep from '~/services/cep';
import FileInput from '~/components/FileInput';
import maskCEP from '~/utils/formatCep'
import formatPhone from '~/utils/formatPhone'
import formatCpfCnpj from '~/utils/formatCpfCnpj';

const FieldsPessoaJuridica = ({ pessoa, setPessoa, errors }) => {
  const { uf_data } = useSelector(({ UfDataReducer }) => UfDataReducer);

  const handleChange = event => {
    const { name, value } = event.target;
    setPessoa({
      ...pessoa,
      [name]: value
    });
  };

  const handleBuscaCep = async (event) => {
    const { value } = event.target;
    const cep = value;
    try {
      const data = await buscaCep.get(`${cep}/json`);
      const endereco = data.data;
      const finded = {
        logradouro: endereco.logradouro,
        bairro: endereco.bairro,
        cidade: endereco.localidade
      }
      Object.assign(pessoa, finded);
      setPessoa({
        ...pessoa
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div style={{ border: '1px solid black', borderRadius: '3px',padding: '15px 20px', margin: '10px 0px' }}>
      <h5>Dados de Pessoa Jurídica</h5>
      <Form.Group controlId="nome">
        <Form.Label>Razão Social</Form.Label>
        <Form.Control
          isInvalid={!!errors.nome}
          name="nome"
          onChange={handleChange}
          placeholder="Razão Social"
          type="text"
          value={pessoa.nome || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.nome}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="nome_fantasia">
        <Form.Label>Nome Fantasia</Form.Label>
        <Form.Control
          isInvalid={!!errors.nome_fantasia}
          name="nome_fantasia"
          onChange={handleChange}
          placeholder="Nome Fantasia"
          type="text"
          value={pessoa.nome_fantasia || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.nome_fantasia}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Row}>
        <Col sm={6}>
          <Form.Label>CNPJ (Somente números)</Form.Label>
          <Form.Control
            isInvalid={!!errors.cnpj}
            maxLength="18"
            minLength="18"
            name="cnpj"
            onChange={handleChange}
            placeholder="CNPJ"
            type="text"
            value={formatCpfCnpj(pessoa.cnpj ?? '') || ''}
          />
          <Form.Control.Feedback type="invalid">
            {errors.cnpj}
          </Form.Control.Feedback>
        </Col>
        <Col>
          <Form.Label>Anexo</Form.Label>
          <FileInput
            dataError={errors.anexo_cnpj}
            name="anexo_cnpj"
            data={pessoa}
            setData={setPessoa}
          />
          <Form.Control.Feedback type="invalid">
            {errors.anexo_cnpj}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col sm={6}>
          <Form.Label>Certidão Simplificada da Junta Comercial</Form.Label>
          <Form.Control
            as="select"
            isInvalid={!!errors.certidao_simplificada_jc}
            name="certidao_simplificada_jc"
            onChange={handleChange}
            placeholder="Certidão Simplificada da Junta Comercial"
            value={pessoa.certidao_simplificada_jc || ''}
          >
            <option value={1}> SIM </option>
            <option value={0}> NÃO </option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.certidao_simplificada_jc}
          </Form.Control.Feedback>
        </Col>
        <Col>
          <Form.Label>Anexo</Form.Label>
          <FileInput
            dataError={errors.anexo_certidao_simplificada_jc}
            name="anexo_certidao_simplificada_jc"
            data={pessoa}
            setData={setPessoa}
          />
          <Form.Control.Feedback type="invalid">
            {errors.anexo_certidao_simplificada_jc}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group>
        <Form.Label>Porte Empreendimento</Form.Label>
        <Form.Control
          as="select"
          isInvalid={!!errors.porte_empreendimento_id}
          name="porte_empreendimento_id"
          onChange={handleChange}
          placeholder="UF"
          value={pessoa.porte_empreendimento_id || ''}
        >
          <option value="2">MEI</option>
          <option value="3">MICRO EMPRESA</option>
          <option value="4">EMPRESA PEQUENO PORTE</option>
          <option value="5">EMPRESA MÉDIO PORTE</option>
          <option value="6">EMPRESA GRANDE PORTE</option>
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {errors.porte_empreendimento_id}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>CEP</Form.Label>
        <Form.Control
          isInvalid={!!errors.cep}
          name="cep"
          onBlur={handleBuscaCep}
          onChange={handleChange}
          placeholder="CEP"
          type="text"
          maxLength="9"
          value={maskCEP(pessoa.cep ?? '') || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.cep}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Row}>
        <Col sm={6}>
          <Form.Label>Logradouro</Form.Label>
          <Form.Control
            isInvalid={!!errors.logradouro}
            name="logradouro"
            onChange={handleChange}
            placeholder="Logradouro"
            type="text"
            value={pessoa.logradouro || ''}
          />
          <Form.Control.Feedback type="invalid">
            {errors.logradouro}
          </Form.Control.Feedback>
        </Col>
        <Col>
          <Form.Label>Anexo</Form.Label>
          <FileInput
            dataError={errors.anexo_endereco}
            name="anexo_endereco"
            data={pessoa}
            setData={setPessoa}
          />
          <Form.Control.Feedback type="invalid">
            {errors.anexo_endereco}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group>
        <Form.Label>Número</Form.Label>
        <Form.Control
          isInvalid={!!errors.numero_endereco}
          name="numero_endereco"
          onChange={handleChange}
          placeholder="Número"
          type="text"
          value={pessoa.numero_endereco || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.numero_endereco}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Bairro</Form.Label>
        <Form.Control
          isInvalid={!!errors.bairro}
          name="bairro"
          onChange={handleChange}
          placeholder="Bairro"
          type="text"
          value={pessoa.bairro || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.bairro}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Cidade</Form.Label>
        <Form.Control
          isInvalid={!!errors.cidade}
          name="cidade"
          onChange={handleChange}
          placeholder="Cidade"
          type="text"
          value={pessoa.cidade || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.cidade}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>UF</Form.Label>
        <Form.Control
          as="select"
          isInvalid={!!errors.uf_endereco_id}
          name="uf_endereco_id"
          onChange={handleChange}
          placeholder="UF"
          value={pessoa.uf_endereco_id || ''}
        >
          {uf_data[0] &&
            uf_data.map((uf) => (
              <option key={uf.id} value={uf.id}>
                {uf.uf}
              </option>
            ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {errors.uf_endereco_id}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Número de WhatsApp</Form.Label>
        <Form.Control
          isInvalid={!!errors.numero_whatsapp}
          name="numero_whatsapp"
          onChange={handleChange}
          placeholder="Número de WhatsAp"
          type="text"
          maxLength="15"
          value={formatPhone(pessoa.numero_whatsapp ?? '') || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.numero_whatsapp}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Fone de contato</Form.Label>
        <Form.Control
          isInvalid={!!errors.telefone}
          name="telefone"
          onChange={handleChange}
          placeholder="Fone de contato"
          type="text"
          maxLength="15"
          value={formatPhone(pessoa.telefone ?? '') || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.telefone}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Homepage da empresa</Form.Label>
        <Form.Control
          isInvalid={!!errors.site}
          name="site"
          onChange={handleChange}
          placeholder="Homepage da empresa"
          type="text"
          value={pessoa.site || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.site}
        </Form.Control.Feedback>
      </Form.Group>
      <hr style={{ backgroundColor: '#FFF' }} />
      <Form.Group>
        <Form.Label>Dados Responsavel legal:</Form.Label>
      </Form.Group>
      <Form.Group>
        <Form.Label>Nome do Responsável Legal</Form.Label>
        <Form.Control
          isInvalid={!!errors.nome_responsavel_legal}
          name="nome_responsavel_legal"
          onChange={handleChange}
          placeholder="Nome do Responsável Lega"
          type="text"
          value={pessoa.nome_responsavel_legal || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.nome_responsavel_legal}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Função</Form.Label>
        <Form.Control
          isInvalid={!!errors.funcao_responsavel_legal}
          name="funcao_responsavel_legal"
          onChange={handleChange}
          placeholder="Função"
          type="text"
          value={pessoa.funcao_responsavel_legal || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.funcao_responsavel_legal}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Row}>
        <Col sm={6}>
          <Form.Label>CPF Responsável Legal</Form.Label>
          <Form.Control
            isInvalid={!!errors.cpf_responsavel_legal}
            name="cpf_responsavel_legal"
            onChange={handleChange}
            placeholder="CPF Responsável Legal"
            type="text"
            maxLength="14"
            value={formatCpfCnpj(pessoa.cpf_responsavel_legal ?? '') || ''}
          />
          <Form.Control.Feedback type="invalid">
            {errors.cpf_responsavel_legal}
          </Form.Control.Feedback>
        </Col>
        <Col>
          <Form.Label>Anexo</Form.Label>
          <FileInput
            dataError={errors.anexo_cpf_responsavel_legal}
            name="anexo_cpf_responsavel_legal"
            data={pessoa}
            setData={setPessoa}
          />
          <Form.Control.Feedback type="invalid">
            {errors.anexo_cpf_responsavel_legal}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col sm={6}>
          <Form.Label>RG Responsável Legal</Form.Label>
          <Form.Control
            isInvalid={!!errors.rg_responsavel_legal}
            name="rg_responsavel_legal"
            onChange={handleChange}
            placeholder="RG Responsável Legal"
            type="text"
            value={pessoa.rg_responsavel_legal || ''}
          />
          <Form.Control.Feedback type="invalid">
            {errors.rg_responsavel_legal}
          </Form.Control.Feedback>
        </Col>
        <Col>
          <Form.Label>Anexo</Form.Label>
          <FileInput
            dataError={errors.anexo_rg_responsavel_legal}
            name="anexo_rg_responsavel_legal"
            data={pessoa}
            setData={setPessoa}
          />
          <Form.Control.Feedback type="invalid">
            {errors.anexo_rg_responsavel_legal}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col sm={6}>
          <Form.Label>Endereço Responsável Legal</Form.Label>
          <Form.Control
            isInvalid={!!errors.endereco_responsavel_legal}
            name="endereco_responsavel_legal"
            onChange={handleChange}
            placeholder="Endereço Responsável Legal"
            type="text"
            value={pessoa.endereco_responsavel_legal || ''}
          />
          <Form.Control.Feedback type="invalid">
            {errors.endereco_responsavel_legal}
          </Form.Control.Feedback>
        </Col>
        <Col>
          <Form.Label>Anexo</Form.Label>
          <FileInput
            dataError={errors.anexo_endereco_responsavel_legal}
            name="anexo_endereco_responsavel_legal"
            data={pessoa}
            setData={setPessoa}
          />
          <Form.Control.Feedback type="invalid">
            {errors.anexo_endereco_responsavel_legal}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group>
        <Form.Label>Fone de contato do Representante Legal</Form.Label>
        <Form.Control
          isInvalid={!!errors.telefone_responsavel_legal}
          name="telefone_responsavel_legal"
          onChange={handleChange}
          placeholder="Fone de contato do Representante Legal"
          type="text"
          maxLength="15"
          value={formatPhone(pessoa.telefone_responsavel_legal ?? '') || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.telefone_responsavel_legal}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>E-mail do Representante Legal</Form.Label>
        <Form.Control
          isInvalid={!!errors.email_responsavel_legal}
          name="email_responsavel_legal"
          onChange={handleChange}
          placeholder="E-mail do Representante Legal"
          type="text"
          value={pessoa.email_responsavel_legal || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.email_responsavel_legal}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
}

export default FieldsPessoaJuridica;
