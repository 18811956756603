import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormEdit } from '../components';
import Swal from 'sweetalert2';
import schema from './schema'
import { showCondicionante, loadCondicionantes, updateCondicionante } from '~/store/modules/condicionante/action';
import { Loading } from '~/components';

const Edit = ({entidade_id}) => {
  let history = useHistory();
  const [condicionante_edit, setCondicionanteEdit] = useState(null);
  const [saved, setSaved] = useState(false);
  const { condicionante_id }  = useParams();
  const [errorsValidate, setErrorsValidate] = useState([]);
  const dispatch = useDispatch();
  const { status_response, errors, condicionante } = useSelector(({ CondicionanteReducer }) => CondicionanteReducer);

  useEffect(() => {
    dispatch(showCondicionante(condicionante_id));
  }, [dispatch, condicionante_id]);


  useEffect(() => {
    // console.log('errors Edit: ',errors);
    if (errors.status && errors.status === 422) {
      let server_errors = {};
      const response_errors = errors.data.errors;
      for (let name of Object.keys(response_errors)) {
        Object.assign(server_errors, { [name]: response_errors[name][0] });
      }
      setErrorsValidate(server_errors);
    }
    if(errors.update) {
      setSaved(errors.update);
    }
  }, [errors]);

  useEffect(() => {
    // console.log('Sucesso Edit motorista: ',status_response);
    if (condicionante.length) {
      const set_condicionante = condicionante[0];
      setCondicionanteEdit({
        nome: set_condicionante.nome,
        especifica: set_condicionante.especifica,
        meio_analise_id: set_condicionante.meio_analise_id,
        tipo_licenca_id: set_condicionante.tipo_licenca_id,
        entidade_id: set_condicionante.entidade_id,
        atividade_id: set_condicionante.atividade_id,
      });
    }
  }, [condicionante, status_response]);

  useEffect(() => {
    // console.log('Sucesso Edit Motorista: ',status_response);
    if (saved && status_response === 200) {
      Swal.fire('Sucesso', 'Registro editado com sucesso!', 'success').then(
        () => {
          history.goBack();
          dispatch(loadCondicionantes({
            where: `entidade_id:${entidade_id}`
          }));
        }
      );
    }
  }, [status_response, history, dispatch, saved, entidade_id]);


  const handleClose = () => {
    history.goBack();
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setErrorsValidate([]);
    if (!schema.isValidSync(condicionante_edit)) {
      let yup_errors = {};
      schema.validate(condicionante_edit, { abortEarly: false }).catch(err => {
        for (let error of err.inner) {
          const { path, message } = error;
          Object.assign(yup_errors, { [path]: message });
        }
        setErrorsValidate(yup_errors);
      });
    } else {
      const isEspecifica = condicionante_edit.especifica === 'true' || condicionante_edit.especifica === true ? true : false;
      const condicionante = condicionante_edit;
      condicionante.especifica = isEspecifica;
      condicionante.meio_analise_id = isEspecifica ? parseInt(condicionante.meio_analise_id) : null;
      condicionante.entidade_id = parseInt(entidade_id);
      await dispatch(updateCondicionante(condicionante, condicionante_id));
      // console.log('atividade_edit update: ',condicionante);
    }
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-xl"
      onHide={handleClose}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Condicionante
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Editar</h2>
          </div>
          <div className="card-body">
            {
              condicionante_edit?
                (
                  <FormEdit
                    condicionante={condicionante_edit}
                    entidade_id={entidade_id}
                    errors={errorsValidate}
                    handleSubmit={handleSubmit}
                    schema={schema}
                    setCondicionante={setCondicionanteEdit}
                  />
                ):
                (
                  <Loading />
                )
            }
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-default"
          onClick={handleClose}
        >
          <i className="fa fa-window-close" /> Cancelar
        </button>
        <button
          className="btn btn-success"
          form="form_condicionante"
          type="submint"
        >
          {' '}
          <i className="fa fa-save" /> Editar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Edit;
