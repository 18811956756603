import React from 'react';
import { Form } from 'react-bootstrap';

const FormCad = ({ handleSubmit, schema, menu, setMenu, errors }) => {
  const handleChange = event => {
    const { name, value, files } = event.target;

    setMenu({
      ...menu,
      [name]: files ? files[0] : value,
    });
  };

  return (
    <Form id="form_menu" onSubmit={handleSubmit} schema={schema}>
      <div className="form-group">
        <label htmlFor="nome"> Nome </label>
        <Form.Control
          id="nome"
          isInvalid={!!errors.nome}
          name="nome"
          onChange={handleChange}
          placeholder="Nome do menu"
          type="text"
          value={menu.nome || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.nome}
        </Form.Control.Feedback>
      </div>
      <div className="form-group">
        <label htmlFor="icon"> Ícone </label>
        <Form.Control
          id="icon"
          isInvalid={!!errors.icon}
          name="icon"
          onChange={handleChange}
          placeholder="ìcone"
          type="text"
          value={menu.icon || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.icon}
        </Form.Control.Feedback>
      </div>

      <div className="form-group">
        <label htmlFor="path_name"> Path Name </label>
        <Form.Control
          id="path_name"
          isInvalid={!!errors.path_name}
          name="path_name"
          onChange={handleChange}
          placeholder="Path Name"
          type="text"
          value={menu.path_name || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.path_name}
        </Form.Control.Feedback>
      </div>
    </Form>
  );
};

export default FormCad;
