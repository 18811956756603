import * as actions from './actionType';

export const loadIndicadoresAmbientais = (params) => ({
  type: actions.LOAD_INDICADOR_AMBIENTAL,
  params
});
export const getIndicadoresAmbientais = indicadores_ambientais => ({
  type: actions.INDICADOR_AMBIENTAL_DATA,
  indicadores_ambientais,
});
export const storeIndicadorAmbiental = indicador_ambiental => ({
  type: actions.STORE_INDICADOR_AMBIENTAL,
  indicador_ambiental,

});

export const updateIndicadorAmbiental = (indicador_ambiental, id) => ({
  type: actions.UPDATE_INDICADOR_AMBIENTAL,
  indicador_ambiental,
  id
});

export const removerIndicadorAmbiental = (id) => ({
  type: actions.DESATIVAR_INDICADOR_AMBIENTAL,
  id
});

export const ativarIndicadorAmbiental = id => ({
  type: actions.ACTIVATE_INDICADOR_AMBIENTAL,
  id,
});

export const getIndicadorAmbiental = indicador_ambiental => ({
  type: actions.GET_INDICADOR_AMBIENTAL,
  indicador_ambiental,
});

export const getErros = errors => ({
  type: actions.INDICADOR_AMBIENTAL_ERROR,
  errors,
});

export const showIndicadorAmbiental= (id,params) => ({
  type: actions.SHOW_INDICADOR_AMBIENTAL,
  payload:{
    id:id,
    params:params
  }
});

export const changeStatus = status => ({
  type: actions.CHANGE_STATUS_CODE,
  status,
});
