import * as actions from './actionType';

export const IndicadorProcessoReducer = (state = {indicadores_processo: [], indicador_processo: {}, status_response: null, errors:{}}, action,) => {
  switch (action.type) {
    case actions.INDICADORES_PROCESSO_DATA:
      return {
        ...state,
        indicadores_processo: action.indicadores_processo.data,
      };
    case actions.GET_INDICADOR_PROCESSO:
      return {
        ...state,
        indicador_processo: action.indicador_processo.data.data,
      };
    case actions.INDICADOR_PROCESSO_ERROR:
      return {
        ...state,
        errors: action.errors
      };
    case actions.CHANGE_STATUS_CODE:
      return {
        ...state,
        status_response: action.status,
      };
    default:
      return state;
  }
}
