import React, { useEffect, useState } from 'react';
import {
  useParams,
  useHistory,
  Switch,
  Route,
  useLocation,
  Link,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { Loading } from '~/components';
import { showMeioAnalise } from '~/store/modules/meio_analise/action';
import {
  loadTipoMeioAnalises,
  removerTipoMeioAnalise,
} from '~/store/modules/tipo_meio_analise/action';
// import { loadIndicadoresAmbientais } from '~/store/modules/indicadorAmbiental/action';
// import { loadListaDocumentos } from '~/store/modules/lista_documento/action';
import { BasicContent } from '../components';
import { SubMenuList } from '../components/SubMenu';
// import CreateInd from '../../IndicadoresAmbientais/Create';
import CreateTipoMeio from '../../tipo_meio_analise/Create';
import ImportTipoMeioAnalise from '../../tipo_meio_analise/ImportTipoMeioAnalise';
import EditTipoMeio from '../../tipo_meio_analise/Edit';
import Swal from 'sweetalert2';
// import CreateDoc from '../../ListaDocumentos/Create';

const List = () => {
  let location = useLocation();
  let modal = location.state && location.state.modal;
  let history = useHistory();
  const dispatch = useDispatch();
  const { meio_analise_id } = useParams();
  const [meio_analiseShow, setMeio_analiseShow] = useState({});
  const [tipoMeioAnaliseCurrentPage, setTipoMeioAnaliseCurrentPage] =
    useState(1);

  const { meio_analise } = useSelector(
    ({ MeioAnaliseReducer }) => MeioAnaliseReducer
  );
  const { tipo_meio_analises, tipo_meio_analise } = useSelector(
    ({ TipoMeioAnaliseReducer }) => TipoMeioAnaliseReducer
  );
  // const { lista_documentos } = useSelector(({ ListaDocumentoReducer }) => ListaDocumentoReducer);

  useEffect(() => {
    dispatch(
      showMeioAnalise(meio_analise_id, {
        with: 'tipo_licenca,tipo_licenca.atividade,tipo_licenca.atividade.entidade',
        with_trashed: true,
      })
    );
  }, [dispatch, meio_analise_id]);

  useEffect(() => {
    if (Object.keys(tipo_meio_analises).length) {
      setTipoMeioAnaliseCurrentPage(tipo_meio_analises.current_page);
    }
  }, [tipo_meio_analises]);

  useEffect(() => {
    dispatch(
      loadTipoMeioAnalises({
        where: `meio_analise_id:${meio_analise_id}`,
        with_trashed: true,
        page: tipoMeioAnaliseCurrentPage,
      })
    );
  }, [
    dispatch,
    meio_analise_id,
    tipo_meio_analise,
    tipoMeioAnaliseCurrentPage,
  ]);

  useEffect(() => {
    if (meio_analise.id === parseInt(meio_analise_id)) {
      setMeio_analiseShow(meio_analise);
    }
  }, [meio_analise, meio_analise_id]);

  const back = () => {
    history.goBack();
  };

  const handleActivationTipoMeioAnalise = (tipoMeioAnalise) => {
    const action = tipoMeioAnalise.deleted_at ? 'ativar' : 'desativar';

    Swal.fire({
      title: `${action.charAt(0).toUpperCase() + action.slice(1)}?`,
      text: `Deseja ${action} esse item?!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.value) {
        try {
          await dispatch(removerTipoMeioAnalise(tipoMeioAnalise.id));
          await dispatch(
            loadTipoMeioAnalises({
              where: `meio_analise_id:${meio_analise_id}`,
              with_trashed: true,
            })
          );
          await Swal.fire(
            'Sucesso',
            `${tipoMeioAnalise.deleted_at ? 'Ativado' : 'Desativado'
            } com sucesso!`,
            'success'
          );
          history.go(0);
        } catch (error) {
          await Swal.fire(
            'Erro',
            `Erro ao ${tipoMeioAnalise.deleted_at ? 'Ativar' : 'Desativar'}`,
            'error'
          );
        }
      }
    });
  };

  const breadcrumb = [
    {
      label: 'Entidade',
      to: `/entidade/${meio_analiseShow?.tipo_licenca?.atividade?.entidade_id}/detalhe`,
      active: true,
    },
    {
      label: 'Atividade',
      to: `/atividade/${meio_analiseShow?.tipo_licenca?.atividade_id}/detalhe`,
      active: true,
    },
    {
      label: 'Tipo Licença',
      to: `/tipo_licenca/${meio_analiseShow?.tipo_licenca_id}/detalhe`,
      active: true,
    },
    {
      label: 'Meio de analise',
      to: `/meio_analise/${meio_analise_id}/detalhe`,
      active: true,
    },
    {
      label: 'Detalhes',
      to: `/meio_analise/${meio_analise_id}/detalhe`,
      active: false,
    },
  ];

  return (
    <>
      {modal && (
        <Switch>
          <Route
            children={<CreateTipoMeio />}
            path={'/meio_analise/:meio_analise_id/tipo_meio_analise/novo'}
          />
          <Route
            children={<EditTipoMeio currentPage={tipoMeioAnaliseCurrentPage} />}
            path={
              '/meio_analise/:meio_analise_id/tipo_meio_analise/:tipo_meio_analise_id/edit'
            }
          />
          <Route
            children={<ImportTipoMeioAnalise />}
            path={'/meio_analise/:meio_analise_id/tipo_meio_analise/import_data'}
          />
        </Switch>
      )}
      <BasicContent breadcrumb={breadcrumb}>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Detalhes</h2>
            <div className="card-tools">
              <button
                className="btn btn-default"
                onClick={back}
              >
                <FaArrowAltCircleLeft /> Voltar
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              {meio_analiseShow.id === parseInt(meio_analise_id) ? (
                <table className="table table-hover table-striped">
                  <tbody>
                    <tr>
                      <th>Entidade</th>
                      <td>
                        <Link
                          to={`/entidade/${meio_analiseShow?.tipo_licenca?.atividade?.entidade_id}/detalhe`}
                        >
                          {
                            meio_analiseShow?.tipo_licenca?.atividade?.entidade
                              ?.nome
                          }
                        </Link>
                      </td>
                    </tr>

                    <tr>
                      <th>Atividade</th>
                      <td>
                        <Link
                          to={`/atividade/${meio_analiseShow?.tipo_licenca?.atividade_id}/detalhe`}
                        >
                          {meio_analiseShow?.tipo_licenca?.atividade?.nome}
                        </Link>
                      </td>
                    </tr>

                    <tr>
                      <th>Tipo Licença</th>
                      <td>
                        <Link
                          to={`/tipo_licenca/${meio_analiseShow?.tipo_licenca_id}/detalhe`}
                        >
                          {meio_analiseShow?.tipo_licenca?.nome}
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th>id</th>
                      <td>{meio_analise.id}</td>
                    </tr>
                    <tr>
                      <th>Nome</th>
                      <td>{meio_analise.nome}</td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <Loading />
              )}
            </div>
          </div>
        </div>
        {tipo_meio_analises.data ? (
          <>
            <SubMenuList
              action={loadTipoMeioAnalises}
              createPath={
                '/meio_analise/:meio_analise_id/tipo_meio_analise/novo'
              }
              createUrl={`/meio_analise/${meio_analise_id}/tipo_meio_analise/novo`}
              detalhe
              displayName={'meio_analise_id'}
              handleActivation={handleActivationTipoMeioAnalise}
              id={meio_analise_id}
              importDataUrl={`/meio_analise/${meio_analise_id}/tipo_meio_analise/import_data`}
              labelCol1={'Nome'}
              labelCol2={'Descricao'}
              path_detalhe={'/tipo_meio_analise/*/detalhe'}
              title={'Impactos ambientais'}
              valueCol1={'nome'}
              valueCol2={'descricao'}
              values={tipo_meio_analises}
            />
          </>
        ) : (
          <div className="card">
            <div className="card-header">
              <h2 className="card-title">Impactos ambientais</h2>
            </div>
            <div className="card-body">
              <Loading />
            </div>
          </div>
        )}
      </BasicContent>
    </>
  );
};

export default List;
