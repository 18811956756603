import React from 'react';
import { Form } from 'react-bootstrap';

const FormCad = ({
  handleSubmit,
  schema,
  questaoAnalise,
  setQuestaoAnalise,
  errors }) => {
  const handleChange = event => {
    const { name, value, files } = event.target;
    setQuestaoAnalise({
      ...questaoAnalise,
      [name]: files ? files[0] : value,
    });
  };

  return (
    <Form
      id="form_questaoAnalise"
      onSubmit={handleSubmit}
      schema={schema}
    >
      <div className="form-group">
        <label htmlFor="nome"> Enunciado </label>
        <Form.Control
          id="nome"
          isInvalid={!!errors.nome}
          name="nome"
          onChange={handleChange}
          placeholder="Enunciado"
          type="text"
          value={questaoAnalise.nome || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.nome}
        </Form.Control.Feedback>
      </div>

    </Form>
  );
};

export default FormCad;
