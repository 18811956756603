import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Form } from 'react-bootstrap';
import { FaArrowAltCircleLeft, FaCircleNotch, FaSave } from 'react-icons/fa';
import Swal from 'sweetalert2';
import {
  showEntidade,
  registerFinancialAccount,
  changeStatus
} from '~/store/modules/entidade/action';


const RegisterFinancialAccount = ({ entidade }) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { status_response } = useSelector(({ EntidadeReducer }) => EntidadeReducer);

  const handleClose = useCallback(() => {
    history.push(`/entidade/${entidade.id}/detalhe`);
  }, [history, entidade.id]);

  useEffect(() => () => {
    dispatch(changeStatus(null));
  }, [dispatch]);

  useEffect(() => {
    if (status_response === 200) {
      dispatch(showEntidade(entidade.id, {
        with:'estado,municipio'
      }));
      Swal.fire(
        'Sucesso',
        'Conta financeira registrada!',
        'success'
      ).then(() => {
        handleClose();
      });
    }
  }, [dispatch, entidade.id, handleClose, status_response]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const form = new FormData();
    form.append('_method', 'PUT');
    form.append('password', password);

    await dispatch(
      registerFinancialAccount(
        entidade.id, form
      )
    );
  }

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-md"
      onHide={handleClose}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Registrar Senha de Integração BIPP
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="form_financial_password" onSubmit={handleSubmit}>
          <Row style={{ paddingLeft: '20%', paddingRight: '20%' }}>
            <Form.Group as={Col} md="12" style={{ margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '10px' }}>
              <Form.Label>Senha</Form.Label>
              <Form.Control
                type="password"
                style={{ textAlign: 'center' }}
                onChange={(event) => setPassword(event.target.value)}
                readOnly={loading}
                required
              />
              <small style={{ fontWeight: 600 }}>
                <span style={{ color: '#F00' }}>*</span>
                A senha será criptografada
              </small>
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between' }}>
        <button
          type="button"
          className="btn btn-default"
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={handleClose}
        >
          <FaArrowAltCircleLeft/>&nbsp;Voltar
        </button>
        <button
          form="form_financial_password"
          className="btn btn-success"
          style={{ display: 'flex', alignItems: 'center' }}
          disabled={loading}
        >
          {loading ? <FaCircleNotch className="spinner"/> : <FaSave/>}&nbsp;Salvar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default RegisterFinancialAccount;
