import { all, put, call, takeLatest } from 'redux-saga/effects';
import * as TipoMeioAnalise from '~/services/TipoMeioAnalise';
import * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadTipoMeioAnalisesWorker({ params }) {
  try {
    const response = yield call(TipoMeioAnalise.load, params);
    yield put(actions.getTipoMeioAnalises(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}


function* showTipoMeioAnaliseWorker({ id, params }) {
  try {
    const response = yield call(TipoMeioAnalise.show, id, params);
    yield put(actions.getTipoMeioAnalise(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* updateTipoMeioAnaliseWorker({ tipo_meio_analise, id }) {
  try {
    const response = yield call(TipoMeioAnalise.update, id, tipo_meio_analise);
    yield put(actions.getTipoMeioAnalise(response));
    yield put(actions.getErros({ update: true }));
    yield put(getErros({ update: true }));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* storeTipoMeioAnaliseWorker({ tipo_meio_analise }) {
  try {
    const response = yield call(TipoMeioAnalise.store, tipo_meio_analise);
    yield put(actions.getTipoMeioAnalise(response));
    yield put(actions.changeStatus(response.status));
    yield put(actions.getErros({ save: true }));
    yield put(getErros({ save: true }));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      // yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* removerTipoMeioAnaliseWorker({ id }) {
  try {
    yield call(TipoMeioAnalise.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* watcherTipoMeioAnalise() {
  yield takeLatest(actionType.LOAD_TIPO_MEIO_ANALISES, loadTipoMeioAnalisesWorker);
  yield takeLatest(actionType.STORE_TIPO_MEIO_ANALISE, storeTipoMeioAnaliseWorker);
  yield takeLatest(actionType.SHOW_TIPO_MEIO_ANALISE, showTipoMeioAnaliseWorker);
  yield takeLatest(actionType.UPDATE_TIPO_MEIO_ANALISE, updateTipoMeioAnaliseWorker);
  yield takeLatest(actionType.REMOVER_TIPO_MEIO_ANALISE, removerTipoMeioAnaliseWorker);
}

export default function* saga() {
  yield all([watcherTipoMeioAnalise()]);
}
