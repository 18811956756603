import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaList } from 'react-icons/fa';
import { ProgressBar } from './styles';
import { Loading, Paginate } from '~/components';
import { BasicContent } from '../components';
import { loadMinhasApias } from '~/store/modules/tecnico_profissional/action';


export default function List() {


  const dispatch = useDispatch();
  const { processos, meta } = useSelector(
    ({ TecnicoProfissionalReducer }) => TecnicoProfissionalReducer
  );

  useEffect(() => {
    dispatch(loadMinhasApias({
      with:'status_processo',
      order_by: 'created_at:desc'
    }));

  }, [dispatch]);


  const breadcrumb = {
    label: 'Minhas APIAs',
    to: '/analise-processo/minhas-analises',
    active: false,
  };

  const formatDataHora = (data_hora) => {
    let data_hora_raw = data_hora.split(' ');
    let data_format = data_hora_raw[0].split('-');
    let hora = data_hora_raw[1];
    let data = `${data_format[2]}/${data_format[1]}/${data_format[0]}`;
    return `${data} ${hora}`;
  }

  return (
    <>
      <BasicContent breadcrumb={breadcrumb}>
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">
              <FaList /> PROCESSOS
            </h3>
            {/* <div className="card-tools">
              <Link className="btn  btn-success" to="/processos/create/pass_1">
                <FaPlus /> Novo Processo
              </Link>
            </div> */}
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-bordered table-sm table-striped">
                <thead>
                  <tr>
                    <th> N. processo </th>
                    <th>Data Abertura</th>
                    <th>Progresso</th>
                    <th> Status </th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {processos && processos.data &&
                    processos.data.map(
                      (
                        { id, protocolo, passo, created_at, status_processo },
                        index
                      ) => {
                        return (
                          <tr key={index}>
                            <td>{protocolo}</td>
                            <td>{formatDataHora(created_at)}</td>
                            <td className="project_progress">
                              <div className="progress progress-sm">
                                <ProgressBar
                                  ariaVolumemax="100"
                                  ariaVolumemin="0"
                                  ariaVolumenow={(passo*100)/20}
                                  className="progress-bar bg-green"
                                  percents={(passo*100)/20}
                                  role="progressbar"
                                />
                              </div>
                              <small>{(passo*100)/20}% completo</small>
                            </td>
                            <td>
                              <span className="badge badge-success">
                                {status_processo.nome}
                              </span>
                            </td>
                            <td>
                              <div className="btn-group">
                                {(status_processo.id === 2 || status_processo.id === 3 || status_processo.id === 4) && (
                                  <Link
                                    className="btn btn-info"
                                    to={`/analise-processo/minhas-analises/${id}`}
                                  >
                                    Analise -APIA
                                  </Link>
                                )}

                                {(status_processo.id >= 8 || status_processo.id >= 7 || status_processo.id >= 5 ) && (
                                  <Link
                                    className="btn btn-info"
                                    to={`/analise-processo/minhas-analises/${id}`}
                                  >
                                    Detalhes do projeto
                                  </Link>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    )}
                </tbody>
              </table>
              {!processos && <Loading />}
            </div>
          </div>
          <div className="card-footer">
            <Paginate
              load={() => loadMinhasApias({
                with:'status_processo',
                order_by: 'created_at:desc'
              })}
              meta={meta}
            />
          </div>
        </div>
      </BasicContent>
    </>
  );
}
