import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as PotencialPoluidor from '~/services/PotencialPoluidor';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadPotencialPoluidoresWorker() {
  try {
    const response = yield call(PotencialPoluidor.load);
    yield put(actions.getPotencialPoluidores(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}


function* showPotencialPoluidorWorker({id}) {
  try {
    const response = yield call(PotencialPoluidor.show, id);
    yield put(actions.getPotencialPoluidor(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updatePotencialPoluidorWorker({ potencial_poluidor, id}) {
  try {
    const response = yield call(PotencialPoluidor.update, id, potencial_poluidor);
    yield put(actions.getPotencialPoluidor(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storePotencialPoluidorWorker({potencial_poluidor}) {
  try {
    const response = yield call(PotencialPoluidor.store, potencial_poluidor);
    yield put(actions.getPotencialPoluidor(response));
    yield put(actions.getErros({save:true}));
    yield put(getErros({save:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* removerPotencialPoluidorWorker({id}) {
  try {
    yield call(PotencialPoluidor.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherPotencialPoluidor() {
  yield takeLatest(actionType.LOAD_POTENCIAL_POLUIDORES, loadPotencialPoluidoresWorker);
  yield takeLatest(actionType.STORE_POTENCIAL_POLUIDOR, storePotencialPoluidorWorker);
  yield takeLatest(actionType.SHOW_POTENCIAL_POLUIDOR, showPotencialPoluidorWorker);
  yield takeLatest(actionType.UPDATE_POTENCIAL_POLUIDOR, updatePotencialPoluidorWorker);
  yield takeLatest(actionType.REMOVER_POTENCIAL_POLUIDOR, removerPotencialPoluidorWorker);
}

export default function* saga() {
  yield all([watcherPotencialPoluidor()]);
}
