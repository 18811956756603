import * as actions from './actionType';

export const loadVistorias = params => ({
  type: actions.LOAD_VISTORIAS,
  params
});

export const getVistorias = vistorias => ({
  type: actions.VISTORIAS_DATA,
  vistorias,
});

export const getSelectVistoria = vistorias => ({
  type: actions.SELECT_VISTORIAS,
  vistorias,
});

export const storeVistoria = vistoria => ({
  type: actions.STORE_VISTORIA,
  vistoria,

});

export const loadVistoria = vistoria_id => ({
  type: actions.LOAD_VISTORIA,
  vistoria_id,
});

export const getVistoria = vistoria => ({
  type: actions.GET_VISTORIA,
  vistoria,
});

export const removerVistoria = (id) => ({
  type: actions.REMOVER_VISTORIA,
  id
});

export const showVistoria = processo_id => ({
  type: actions.SHOW_VISTORIA,
  processo_id,
});

export const updateVistoria = (vistoria, id) => ({
  type: actions.UPDATE_VISTORIA,
  vistoria,
  id
});

export const getErros = errors => ({
  type: actions.VISTORIA_ERROR,
  errors,
});

export const loadVistoriasEntidade = (params) => ({
  type: actions.LOAD_VISTORIA_ENTIDADE,
  params
});
