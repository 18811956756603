import * as actions from './actionType';

export const ArquivoReducer = (state = {arquivo: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.GET_ARQUIVO:
      return {
        ...state,
        arquivo: action.arquivo.data,
      };
    case actions.ARQUIVO_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
