import * as Yup from 'yup';

const schema = Yup.object().shape({
  nome: Yup.string().required('Campo obrigatório.'),
  sigla: Yup.string().required('Campo obrigatório.'),
  expiration_unit: Yup.string().nullable().required('Campo obrigatório.'),
  expiration_quantity: Yup.number().typeError('Deve ser um número').required('Campo obrigatório.').test(
    'is-number', 'Valor inválido', value => !isNaN(value)
  ),
});

export default schema;
