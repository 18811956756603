import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { loadTramitesProcesso } from '~/store/modules/processo/action';
import { Loading } from '~/components';

const MovimentacaoProcesso = ({ processo_id }) => {
  const dispatch = useDispatch();
  let [show, setShow] = useState(false);

  const { tramites } = useSelector(({ ProcessoReducer }) => ProcessoReducer);

  useEffect(() => {
    if (processo_id) {
      dispatch(loadTramitesProcesso(processo_id));
    }
  }, [dispatch, processo_id]);


  return (
    <>
      {tramites ? (
        <>
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Movimentação do Processo</h3>
            </div>
            <div className="card-body">
              <button
                className="btn btn-primary btn-block"
                onClick={() => setShow(true)}
              >
                <i
                  aria-hidden="true"
                  className="fa fa-list-alt"
                /> Movimentação
                do Processo
              </button>
            </div>
          </div>
          <Modal
            animation
            aria-labelledby="example-custom-modal-styling-title"
            dialogClassName="modal-xl"
            onHide={() => setShow(false)}
            show={show}
          >
            <Modal.Header closeButton>
              <Modal.Title>Movimentação do Processo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="table-responsive">
                <table className="table table-hover table-striped">
                  <thead>
                    <tr>
                      <th> Movimentação do Processo </th>
                      <th> Data Hora</th>
                      {/* <th> Dias Úteis de Execução </th>
                      <th> Dias Úteis Acumulados </th>
                      <th> Finalizado </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {tramites &&
                      tramites[0] &&
                      tramites.map(({ nome, created_at }, index) => (
                        <tr key={index}>
                          <td>{nome}</td>
                          <td>{created_at.split(' ')[0].split('-')[2]}/{created_at.split(' ')[0].split('-')[1]}/{created_at.split(' ')[0].split('-')[0]} {created_at.split(' ')[1]}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-default"
                onClick={() => setShow(false)}
                type="button"
              >
                <i className="fa fa-window-close" /> Fechar
              </button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default MovimentacaoProcesso;
