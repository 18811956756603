import * as actions from './actionType';

export const AlternativaQuestaoAnaliseReducer = (state = {select_alternativa_questao_analises: [], alternativa_questao_analises: [], alternativa_questao_analise: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.ALTERNATIVA_QUESTAO_ANALISES_DATA:
      return {
        ...state,
        alternativa_questao_analises: action.alternativa_questao_analises.data,
        links: action.alternativa_questao_analises.data.links,
        meta: action.alternativa_questao_analises.data.meta,
      };
    case actions.SELECT_ALTERNATIVA_QUESTAO_ANALISES:
      return {
        ...state,
        select_alternativa_questao_analises: action.alternativa_questao_analises.data
      };
    case actions.GET_ALTERNATIVA_QUESTAO_ANALISE:
      return {
        ...state,
        alternativa_questao_analise: action.alternativa_questao_analise.data.data,
        status_response: action.alternativa_questao_analise.status,
      };
    case actions.ALTERNATIVA_QUESTAO_ANALISE_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    case actions.CHANGE_STATUS_CODE:
      return {
        ...state,
        status_response: action.status,
      };
    default:
      return state;
  }
}
