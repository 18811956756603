import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import SunEditor from 'suneditor-react';
import plugins from 'suneditor/src/plugins';
import 'suneditor/dist/css/suneditor.min.css';
import { Loading } from '~/components';
import Swal from 'sweetalert2';
import { FaPrint } from 'react-icons/fa';
import { updateProjetoProcessoParecer } from '~/store/modules/projeto_processo/action';
import { BlobProvider } from '@react-pdf/renderer';
import ParecerProjetoPDF from '../../../../Process/components/ProjetoGeralEmpreendimento/PDF';

const ParecerProjeto = ({
  processo_id,
  projeto,
  setParecerIsOk,
  onlyView,
  isRef = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let [show, setShow] = useState(false);
  let [parecerIntroducao, setParecerIntroducao] = useState();
  let [parecerDesenvolvimento, setParecerDesenvolvimento] = useState();
  let [parecerConclusao, setParecerConclusao] = useState();
  let [projetoData, setProjetoData] = useState();
  const { processo } = useSelector(({ ProcessoReducer }) => ProcessoReducer);

  useEffect(() => {
    if (projeto && projeto.id) {
      setParecerIntroducao(projeto.introducao_parecer);
      setParecerDesenvolvimento(projeto.desenvolvimento_parecer);
      setParecerConclusao(projeto.conclusao_parecer);
      setProjetoData(projeto);
    }
  }, [dispatch, projeto]);

  const handleOnChangeParecerIntroducao = (content) => {
    setParecerIntroducao(content);
  };

  const handleOnChangeParecerDesenvolvimento = (content) => {
    setParecerDesenvolvimento(content);
  };

  const handleOnChangeParecerConclusao = (content) => {
    setParecerConclusao(content);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    let parecer_data = {};
    let form_data = new FormData(event.target);
    for (let data of form_data) {
      parecer_data = {
        ...parecer_data,
        [data[0]]: data[1],
      };
    }
    await dispatch(updateProjetoProcessoParecer(projeto.id, parecer_data));
    await Swal.fire('Sucesso', 'Parecer Projeto salvo com sucesso!', 'success');
  };

  const handleClose = async () => {
    await setShow(false);
    await history.go();
  };

  const btnForEditor = () => [
    ['undo', 'redo'],
    ['font', 'fontSize', 'formatBlock'],
    ['paragraphStyle', 'blockquote'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    ['fontColor', 'hiliteColor', 'textStyle'],
    ['removeFormat'],
    '/', // Line break
    ['outdent', 'indent'],
    ['align', 'horizontalRule', 'list', 'lineHeight'],
    ['table', 'link', 'image'], // You must add the 'katex' library at options to use the 'math' plugin.
    ['fullScreen', 'showBlocks', 'codeView'],
    ['preview'],
  ];

  const verificationData =
    projeto?.introducao_aceita &&
    projeto?.desenvolvimento_aceita &&
    projeto?.conclusao_aceita &&
    projeto?.projeto_aceito &&
    !projeto?.editavel;

  useEffect(() => {
    if (
      projeto.introducao_aceita &&
      projeto.desenvolvimento_aceita &&
      projeto.conclusao_aceita &&
      projeto.projeto_aceito &&
      !projeto.editavel
    ) {
      setParecerIsOk(true);
    } else {
      setParecerIsOk(false);
    }
  }, [
    setParecerIsOk,
    projeto.editavel,
    projeto.projeto_aceito,
    projeto.conclusao_aceita,
    projeto.introducao_aceita,
    projeto.desenvolvimento_aceita,
  ]);

  return (
    <>
      {processo_id && projetoData ? (
        <>
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h3 className="card-title w-100">
                Projeto geral do empreendimento ou Atividades
              </h3>
              {verificationData && (
                // <a
                //   className="btn btn-info d-flex flex-row align-items-center"
                //   href={`/parecer-projeto/${processo_id}/imprimir`}
                //   rel="noopener noreferrer"
                //   target="_blank"
                // >
                //   <FaPrint style={{ marginRight: 5 }} /> Imprimir
                // </a>
                <BlobProvider document={
                    <ParecerProjetoPDF
                        process={processo}
                    />
                }>
                    {({ url }) => (
                        <a
                            href={url}
                            target="_blank"
                            className="btn btn-info d-flex flex-row align-items-center"
                            rel="noopener noreferrer"
                        >
                            <FaPrint style={{ marginRight: 5 }} /> Imprimir
                        </a>
                    )}
                </BlobProvider>           
              )}
            </div>
            <div className="card-body">
              <button
                className={`btn ${
                  verificationData ? 'btn-primary' : 'btn-success'
                } btn-block`}
                disabled={isRef}
                onClick={() => setShow(true)}
              >
                <i aria-hidden="true" className="fa fa-list-alt" /> Projeto do
                empreendimento ou atividade
              </button>
            </div>
          </div>
          <Modal
            animation
            aria-labelledby="example-custom-modal-styling-title"
            dialogClassName="modal-xl"
            onHide={handleClose}
            show={show}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Projeto geral do empreendimento ou Atividade
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group float-right">
                {!onlyView && (
                  <button
                    className="btn btn-success"
                    form="form-save-parecer"
                    type="submit"
                  >
                    <i className="fa fa-save" /> Salvar
                  </button>
                )}
              </div>
              <form id="form-save-parecer" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Introdução</label>
                  <div
                    className="m-3"
                    dangerouslySetInnerHTML={{
                      __html: projeto.introducao,
                    }}
                  />
                  <label>Parecer Introdução</label>
                  <SunEditor
                    disable={onlyView}
                    lang="pt_br"
                    name="introducao_parecer"
                    onChange={handleOnChangeParecerIntroducao}
                    setContents={parecerIntroducao}
                    setOptions={{
                      plugins: [plugins],
                      height: 200,
                      buttonList: btnForEditor(),
                    }}
                  />
                </div>
                <div className="form-group">
                  {!onlyView && (
                    <>
                      <label className="m-3">
                        Aceitar Introdução
                        <select
                          className="m-2 p-2"
                          defaultValue={projetoData.introducao_aceita}
                          name="introducao_aceita"
                        >
                          <option value>Sim</option>
                          <option value={false}>Não</option>
                        </select>
                      </label>
                      <button
                        className="btn btn-success"
                        form="form-save-parecer"
                        type="submit"
                      >
                        <i className="fa fa-save" /> Salvar{' '}
                      </button>
                    </>
                  )}
                </div>
                <hr />
                <div className="form-group">
                  <label>Desenvolvimento</label>
                  <div
                    className="m-3"
                    dangerouslySetInnerHTML={{
                      __html: projeto.desenvolvimento,
                    }}
                  />

                  <label>Parecer Desenvolvimento</label>
                  <SunEditor
                    disable={onlyView}
                    lang="pt_br"
                    name="desenvolvimento_parecer"
                    onChange={handleOnChangeParecerDesenvolvimento}
                    setContents={parecerDesenvolvimento}
                    setOptions={{
                      plugins: [plugins],
                      height: 200,
                      buttonList: btnForEditor(),
                    }}
                  />
                </div>
                <div className="form-group">
                  {!onlyView && (
                    <>
                      <label className="m-3">
                        Aceitar Desenvolvimento
                        <select
                          className="m-2 p-2"
                          defaultValue={projetoData.desenvolvimento_aceita}
                          name="desenvolvimento_aceita"
                        >
                          <option value>Sim</option>
                          <option value={false}>Não</option>
                        </select>
                      </label>
                      <button
                        className="btn btn-success"
                        form="form-save-parecer"
                        type="submit"
                      >
                        <i className="fa fa-save" /> Salvar{' '}
                      </button>
                    </>
                  )}
                </div>

                <hr />
                <div className="form-group">
                  <label>Conclusão</label>
                  <div
                    className="m-3"
                    dangerouslySetInnerHTML={{
                      __html: projeto.conclusao,
                    }}
                  />
                  <label>Parecer Conclusão</label>
                  <SunEditor
                    disable={onlyView}
                    lang="pt_br"
                    name="conclusao_parecer"
                    onChange={handleOnChangeParecerConclusao}
                    setContents={parecerConclusao}
                    setOptions={{
                      plugins: [plugins],
                      height: 200,
                      buttonList: btnForEditor(),
                    }}
                  />
                </div>
                <div className="form-group">
                  {!onlyView && (
                    <>
                      <label className="m-3">
                        Aceitar Conclusão
                        <select
                          className="m-2 p-2"
                          defaultValue={projetoData.conclusao_aceita}
                          name="conclusao_aceita"
                        >
                          <option value>Sim</option>
                          <option value={false}>Não</option>
                        </select>
                      </label>
                      <button
                        className="btn btn-success"
                        form="form-save-parecer"
                        type="submit"
                      >
                        <i className="fa fa-save" /> Salvar{' '}
                      </button>
                    </>
                  )}
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              {!onlyView && (
                <>
                  <label className="m-3 ">
                    Abrir projeto para edição
                    <select
                      className="m-2 p-2"
                      defaultValue={projetoData.editavel}
                      form="form-save-parecer"
                      name="editavel"
                    >
                      <option value>Sim</option>
                      <option value={false}>Não</option>
                    </select>
                  </label>
                  <label className="m-3 ">
                    Aceitar projeto
                    <select
                      className="m-2 p-2"
                      defaultValue={projetoData.projeto_aceito}
                      form="form-save-parecer"
                      name="projeto_aceito"
                    >
                      <option value>Sim</option>
                      <option value={false}>Não</option>
                    </select>
                  </label>

                  <button
                    className="btn btn-success"
                    form="form-save-parecer"
                    type="submit"
                  >
                    <i className="fa fa-save" /> Salvar
                  </button>
                </>
              )}
              <button
                className="btn btn-default"
                onClick={handleClose}
                type="button"
              >
                <i className="fa fa-window-close" /> Fechar
              </button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default ParecerProjeto;
