import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ModalDetalheLegislacao from './ModalDetalheLegislacao'
import './styles.css';

export default function ModalLegislacoes({
  showLegisModal = false,
  legislacoes = [],
  closeModal = () => {}
}) {
  const [showLegisDetalheModal, setShowLegisDetalheModal] = useState(false);
  const [legislacao, setLegislacao] = useState({});
  return (
    <>
    <ModalDetalheLegislacao
        showLegisDetalheModal={showLegisDetalheModal}
        legislacao={legislacao }
        closeModal={() => setShowLegisDetalheModal(false)}
      />
    <Modal
      show={showLegisModal}
      dialogClassName="modal-80w"
      onHide={closeModal}
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Legislações
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {legislacoes.map(
          (legislacao, index) => (
            <div key={`legislacao-${index}`}>
              {index > 0 && (<hr/>)}
              <div className='d-flex' >
                <div className='mr-2'>
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={() => {
                      setLegislacao(legislacao);
                      setShowLegisDetalheModal(true)
                    }}
                  >
                    Visualizar
                  </button>
                </div>
              <div>
                <h4>{legislacao.tipo} {legislacao.legislacao}</h4>
              </div>

              </div>
            </div>
          )
        )}
      </Modal.Body>
    </Modal>
    </>
  );
}
