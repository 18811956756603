const formatDataHora = (data_hora) => {
  let data_hora_raw = data_hora.split(' ');
  let hora = data_hora_raw[1];
  return `${formatData(data_hora)} ${hora}`;
};

export function formatData(data_hora) {
  let data_hora_raw = data_hora.split(' ');
  let data_format = data_hora_raw[0].split('-');
  let data = `${data_format[2]}/${data_format[1]}/${data_format[0]}`;
  return `${data}`;
}

export default formatDataHora;
