import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Sidebar, Header, Footer } from './components';

const Dash = props => {
  let history = useHistory();
  const { user_auth } = useSelector(({ LoginReducer }) => LoginReducer);
  const { errors } = useSelector(({ ErrorsReducer }) => ErrorsReducer);
  const { children } = props;
  const [showSide, setShowSide] = useState(false);
  const handleLogout = async () => {
    await localStorage.clear();
    window.location.replace('/');
  }

  useEffect(() => {
    // console.log('errors: ',errors);
    if (errors.data) {
      let error = errors.data;
      let errorsMessage = error?.errors
      let message = '';
      let action = () => history.goBack();
      switch (errors.status) {
        case 401:
          message = 'Sua sessão expirou!';
          action = () => handleLogout();
          break;
        case 403:
          message = error.message ?? 'Você não tem permissão para acessar esse recurso';
          break;
        case 404:
          message = error.message ?? 'A informação solicitada não foi encontrada!';
          break;
        case 422:
          if (errorsMessage) {
            const errorFields = Object.keys(errorsMessage).map(fieldName => {
              const fieldErrors = errorsMessage[fieldName].join(', ');
              return `${fieldName}: ${fieldErrors}`;
            });
            message = `Erro(s) no formulário:\n${errorFields.join('\n')}`;
          } else {
            message = error.message ?? 'Você esqueceu de algo no formulário';
          }
          action = () => false;
          break;
        case 500:
          message = error.message ?? 'Interno no servidor';
          break;
        default:
          console.log(errors)
          message = error.message ?? 'Erro ao realizar sua solitação';
          break;
      }
      Swal.fire('ERRO', message, 'error').then(action);
    }
  }, [errors, history]);

  return (
    <>
      <Header
        onHandleLogout={handleLogout}
        setShowSide={setShowSide}
        showSide={showSide}
      />
      <Sidebar
        name={user_auth.user ? user_auth.user.nome : null}
        photo="https://abad.com.br/wp-content/uploads/2019/11/user-icon.svg"
        showSide={showSide}
      />

      <div className="content-wrapper">
        {children}
      </div>
      <Footer />
    </>
  );
}
export default Dash;
