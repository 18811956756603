import { apiWithToken } from '../api';

const token = localStorage.getItem('@token');
let api = apiWithToken(token);

const resouceUrl = '/user';

export const load_lista_analista_entidade = async (params) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let page = urlParams.has('page')
        ? { page: urlParams.get('page') }
        : {};
    let search = urlParams.has('search')
        ? { search: urlParams.get('search') }
        : {};
    return await api.get(`${resouceUrl}/lista_analista_entidade`, {
        params: {
            ...params,
            ...page,
            ...search
        }
    })
}

export const load_users = async (params) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let page = urlParams.has('page')
        ? { page: urlParams.get('page') }
        : {};
    let search = urlParams.has('search')
        ? { search: urlParams.get('search') }
        : {};
    return await api.get(`${resouceUrl}`, {
        params: {
            ...params,
            ...page,
            ...search
        }
    })
}

export const load_user = async (id_user) => {
    return await api.get(`${resouceUrl}/${id_user}`)
}

export const load_user_pessoa = async id_user => {
    return await api.get(`${resouceUrl}/${id_user}/pessoa`);
}

export const change_password_users = async (usuario) => {
    let response = await api.post(`${resouceUrl}/recuperar_senha`, usuario);
    return response;
}

export const mutate_password_users = async (usuario) => {
    let response = await api.post(`${resouceUrl}/trocar_senha`, usuario);
    return response;
}

export const store_users = async (usuario) => {
    let response = await api.post(resouceUrl, usuario);
    return response;
}

export const update_users = async (payload) => {
    let response = await api.post(`${resouceUrl}/${payload.id}`, payload.usuario);
    return response;
}

export const loadUsersAsOptions = async (params) => {
    return await api.get(
        `${resouceUrl}/options`,
        { params }
    );
}

export const filterUsers = async (params) => {
    return await api.get(`${resouceUrl}/get_filter_users`, {
        params: {
            ...params,
        }
    })
}

export const removeUser = async (payload) => {
    return await api.delete(`${resouceUrl}/${payload.id}`);
}

export const restoreUser = async (payload) => {
    return await api.patch(`${resouceUrl}/${payload.id}/restore`);
}

