import * as actions from './actionType';

export const loadGerentes = params => ({
  type: actions.LOAD_GERENTES,
  params
});

export const getGerentes = gerentes => ({
  type: actions.GERENTES_DATA,
  gerentes,
});

export const showGerente = (id, params) => ({
  type: actions.SHOW_GERENTE,
  id, params
});

export const getGerente = gerente => ({
  type: actions.GET_GERENTE,
  gerente,
});

export const storeGerente = gerente => ({
  type: actions.STORE_GERENTE,
  gerente
});

export const updateGerente = (id, gerente) => ({
  type: actions.UPDATE_GERENTE,
  id, gerente
});

export const changeStatus = status => ({
  type: actions.CHANGE_STATUS_CODE,
  status,
});

export const getErros = errors => ({
  type: actions.GERENTE_ERROR,
  errors,
});
