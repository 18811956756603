import * as actions from './actionType';


const INITIAL_STATE = { 
    processos: [],
    processo: {},
    errors: {}
}

export const PagamentosPendentesReducer = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case actions.GET_PAGAMENTOS_PENDENTES:
      return {
        ...state,
        processos: action.processos.data,
        meta: action.processos.data.meta,
        links: action.processos.data.links,
      };
    case actions.PAGAMENTOS_PENDENTES_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
};
