import * as Yup from 'yup';

const schema = Yup.object().shape({
  nome: Yup.string().required('Valor requerido'),
  obrigatorio: Yup.bool().required('Valor requerido'),
  doc_tecnica: Yup.bool().required('Valor requerido'),
  ordem: Yup.number().required('Valor requerido')
});

export default schema;
