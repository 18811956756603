import * as actions from './actionType';

export const TecnicoAvaliadorReducer = (
  state = {
    tecnicos_avaliadores: {},
    tecnico_avaliador: {},
    status_response: null,
    errors: {}
  },
  action
) => {
  switch (action.type) {
    case actions.TECNICOS_AVALIADORES_DATA:
      return {
        ...state,
        tecnicos_avaliadores: action.tecnicos_avaliadores.data,
      };
    case actions.GET_TECNICO_AVALIADOR:
      return {
        ...state,
        tecnico_avaliador: action.tecnico_avaliador.data.data
      };
    case actions.CHANGE_STATUS_CODE:
      return {
        ...state,
        status_response: action.status,
      };
    case actions.TECNICO_AVALIADOR_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
