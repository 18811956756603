import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormEdit } from '../components';
import Swal from 'sweetalert2';
import { Loading } from '~/components';
import initialData from './initialData';
import schema from './schema'
import { updateListaDocumento, loadListaDocumentos, changeStatus, showListaDocumento } from '~/store/modules/lista_documento/action';

const Edit = () => {
  let history = useHistory();
  const [documento_edit, setDocumentoEdit] = useState(initialData);
  const { tipo_licenca_id, docId } = useParams();
  const [updateListaDoc, setUpdateListaDoc] = useState(false);
  const [errorsValidate, setErrorsValidate] = useState([]);
  const dispatch = useDispatch();
  const { errors, lista_documento } = useSelector(({ ListaDocumentoReducer }) => ListaDocumentoReducer);


  useEffect(() => {
    let documento_id = parseInt(docId);
    dispatch(showListaDocumento(documento_id));
  },[docId, dispatch]);

  useEffect(() => {
    if (lista_documento.id === parseInt(docId)) {
      setDocumentoEdit(lista_documento);
    }
  }, [lista_documento, docId]);

  useEffect(() => {
    if (errors.status && errors.status === 422) {
      let server_errors = {};
      const response_errors = errors.data.errors;
      for (let name of Object.keys(response_errors)) {
        Object.assign(server_errors, { [name]: response_errors[name][0] });
      }
      setErrorsValidate(server_errors);
    }
  }, [errors]);

  useEffect(() => {
    if (updateListaDoc) {
      Swal.fire('Sucesso', 'Registro salvo com sucesso!', 'success').then(
        () => {
          history.goBack();
          dispatch(loadListaDocumentos({
            where:`tipo_licenca_id:${tipo_licenca_id}`
          }));
          dispatch(changeStatus(null));
        }
      );
    }
  }, [updateListaDoc,history, dispatch, tipo_licenca_id]);


  const handleClose = () => {
    history.goBack();
  };

  const handleSubmit = async event => {
    event.preventDefault();
    // console.log('documento store: ',documento);
    if (!schema.isValidSync(documento_edit)) {
      console.log('Error documento_edit store: ',documento_edit);
      let yup_errors = {};
      schema.validate(documento_edit, { abortEarly: false }).catch(err => {
        for (let error of err.inner) {
          const { path, message } = error;
          Object.assign(yup_errors, { [path]: message });
        }
        setErrorsValidate(yup_errors);
      });
    } else {
      const obrigatorio = documento_edit.obrigatorio === 'true' || documento_edit.obrigatorio === true ? true : false;
      const doc_tecnica = documento_edit.doc_tecnica === 'true' || documento_edit.doc_tecnica === true ? true : false;
      const documento_ = documento_edit;
      documento_.obrigatorio = obrigatorio;
      documento_.doc_tecnica = doc_tecnica;
      documento_.tipo_licenca_id = parseInt(tipo_licenca_id);
      await dispatch(updateListaDocumento(documento_, documento_.id));
      await setUpdateListaDoc(true);
    }
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-xl"
      onHide={handleClose}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Documento
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Editar</h2>
          </div>
          <div className="card-body">
            {
              documento_edit?
                (
                  <FormEdit
                    documento={documento_edit}
                    errors={errorsValidate}
                    handleSubmit={handleSubmit}
                    schema={schema}
                    setDocumento={setDocumentoEdit}
                  />
                ):
                (
                  <Loading />
                )
            }
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-default"
          onClick={handleClose}
        >
          <i className="fa fa-window-close" /> Cancelar
        </button>
        <button
          className="btn btn-success"
          form="form_documento"
          type="submint"
        >
          {' '}
          <i className="fa fa-save" /> Salvar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Edit;

