import React from 'react';
import { boxCopyright as BoxCopyRight } from './styles';

const CopyRight = () => {
  return (
    <BoxCopyRight className="text-center">
      Copyright ©
      <a
        href="http://tuxtu.com.br/"
      >
        TUXTU
      </a>
      -
      {new Date().getFullYear()}
      <br/> <strong>SIBLAM</strong> (Sistema Brasileiro de Licenciamento Ambiental) - BETA
    </BoxCopyRight>
  )
}

export default CopyRight;
