import React, { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { RetificacaoContext } from '../context';
import { updateModification } from '~/services/Retificacao';
import { showProcesso } from '~/store/modules/processo/action';

const modificacaoStatusLabel = {
    'ANALISE': 'Em Análise',
    'APROVADA': 'Aprovada',
    'REPROVADA': 'Reprovada',
};

const modificacaoBtnClass = {
    'ANALISE': 'btn-default',
    'APROVADA': 'btn-success',
    'REPROVADA': 'btn-danger'
};

export default function Modificacao({ authUser, handlePageChange, retificacaoIndex }) {
    const dispatch = useDispatch();
    const [retificacoes, setRetificacoes] = useContext(RetificacaoContext);
    const { processo: { id: processo_id } } = useSelector(({ ProcessoReducer }) => ProcessoReducer);

    const reloadProcess = useCallback(() => {
        dispatch(
            showProcesso(processo_id, {
              with:
                'analise_processo,' +
                'analise_previa,' +
                'pessoa,' +
                'documentos,' +
                'estado,' +
                'municipio,' +
                'atividade.legislacao_atividade;deleted_at is null,' +
                'pessoa_responsavel_tecnico,' +
                'tipo_licenca,' +
                'status_processo,' +
                'porte_empreendimento,' +
                'projeto,' +
                'vistorias.relatorio',
            })
        );
    }, [dispatch, processo_id]);

    useEffect(() => { return () => handlePageChange('LIST'); }, [ handlePageChange ]);

    async function handleModificationApproval(retificacao_index, modificacao_index, status) {
        try{
            let payload = { status };
            let swalFireOptions = {
                title: 'Atenção',
                icon: 'question',
                showCancelButton: true,
                showConfirmButton: true,
                cancelButtonColor: '#DC3545',
                confirmButtonColor: '#28A745'
            };

            if (status === 'APROVADA') {
                swalFireOptions = {
                    ...swalFireOptions,
                    text: 'Qual campo deseja modificar?',
                    input: 'select',
                    inputAttributes: {
                        required: true
                    },
                    inputOptions: {
                        'cep': 'CEP',
                        'bairro': 'Bairro',
                        'logradouro': 'Logradouro',
                        'complemento': 'Complemento',
                        'numero_endereco': 'Numero Endereço'
                    },
                    inputPlaceholder: 'Selecione',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Enviar'
                };
            } else {
                swalFireOptions = {
                    ...swalFireOptions,
                    text: 'Deseja reprovar esta modificação?',
                    cancelButtonText: 'Não',
                    confirmButtonText: 'Sim'
                };
            }

            const { value: campo_processo, isConfirmed } = await Swal.fire(swalFireOptions);

            if (status === 'APROVADA') payload.campo_processo = campo_processo;

            if (isConfirmed) {
                let data = [...retificacoes];
                let retificacao = data[retificacao_index];
                let modificacao = retificacao['modificacoes'][modificacao_index];

                const res = await updateModification(processo_id, retificacao.id, modificacao.id, payload);

                Swal.fire(
                    'Sucesso',
                    res.message,
                    'success'
                ).then(() => {
                    modificacao.status = status;
                    setRetificacoes(data);

                    if (res.retificacao_updated) reloadProcess();
                });
            }
        } catch (error) {
            console.log(error.response);

            Swal.fire(
                'Erro',
                error.response.data.message,
                'error'
            );
        }
    }

    return (
        <div className="table-responsive">
            <button
                type="button"
                className="btn btn-default"
                style={{ marginBottom: '10px' }}
                onClick={() => handlePageChange('LIST')}
            >
                <i className="fas fa-reply">&nbsp;</i>
                Voltar
            </button>
            <table className="table table-hover table-striped">
                <thead>
                    <tr>
                        <th>Onde Se Lê</th>
                        <th>Leia</th>
                        <th>Status</th>
                        {authUser.tipo_user_id === 5 && (
                            <th>Ações</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {retificacoes[retificacaoIndex].modificacoes.map((modificacao, index) => (
                        <tr key={`retificacao-modificacao-${modificacao.id}`}>
                            <td>{modificacao.onde_se_le}</td>
                            <td>{modificacao.leia}</td>
                            <td>
                                <span className={`btn btn-sm ${modificacaoBtnClass[modificacao.status]}`}>
                                    {modificacaoStatusLabel[modificacao.status]}
                                </span>
                            </td>
                            {authUser.tipo_user_id === 5 && (
                                <td>
                                    {modificacao.status === 'ANALISE' && (
                                        <div className="btn-group">
                                            <button
                                                type="button"
                                                title="Aprovar"
                                                className="btn btn-success"
                                                onClick={() => handleModificationApproval(retificacaoIndex, index, 'APROVADA')}
                                            >
                                                <i className="fas fa-check"></i>
                                            </button>
                                            <button
                                                type="button"
                                                title="Reprovar"
                                                className="btn btn-danger"
                                                onClick={() => handleModificationApproval(retificacaoIndex, index, 'REPROVADA')}
                                            >
                                                <i className="fas fa-times"></i>
                                            </button>
                                        </div>
                                    )}
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
