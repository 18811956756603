import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormGerente } from '../components';
import Swal from 'sweetalert2';
import initialData from '../initialData';
import schema from './schema';
import { storeGerente } from '~/store/modules/gerente/action';
// import { Loading } from '~/components';

const Create = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { user_auth } = useSelector(
    ({ LoginReducer }) => LoginReducer
  );
  const { errors, status_response } = useSelector(
    ({ GerenteReducer }) => GerenteReducer
  );
  const [saved, setSaved] = useState(false);
  const [errorsForm, setErrors] = useState({});
  const [gerenteForm, setGerenteForm] = useState({
    ...initialData,
    entidade_id: user_auth.user.entidade_id
  });

  useEffect(() => {
    if (errors.status && errors.status === 422) {
      let server_errors = {};
      const response_errors = errors.data.errors;

      for (let name of Object.keys(response_errors)) {
        Object.assign(
          server_errors, {
            [name]: response_errors[name][0]
          }
        );
      }

      setErrors(server_errors);
    }
    if(errors.save) {
      setSaved(errors.save);
    }
  }, [errors, saved]);

  useEffect(() => {
    if (
      saved &&
      (status_response === 200 || status_response === 201)
    ) {
      Swal.fire(
        'Sucesso',
        'Registro salvo com sucesso!',
        'success'
      ).then(
        () => history.push('/gerentes')
      );
    }
  }, [dispatch, saved, status_response, history]);

  const handleClose = () => {
    history.push('/gerentes');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!schema.isValidSync(gerenteForm)) {
      let yup_errors = {};
      schema.validate(gerenteForm, { abortEarly: false }).catch(err => {
        for (let error of err.inner) {
          const { path, message } = error;
          Object.assign(yup_errors, { [path]: message });
        }
        setErrors(yup_errors);
      });
    } else {
      const form = new FormData();
      for (let key in gerenteForm) {
        if (gerenteForm[key]) {
          form.append(key, gerenteForm[key]);
        }
      }
      dispatch(storeGerente(form));
    }
  }

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-xl"
      onHide={handleClose}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Cadastrar Gerente
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGerente
          schema={schema}
          errors={errorsForm}
          handleSubmit={handleSubmit}
          gerente={gerenteForm}
          setGerente={setGerenteForm}
        />
      </Modal.Body>
    </Modal>
  );
};

export default Create;
