import * as actions from './actionType';

export const loadFotosVistoria = params => ({
  type: actions.LOAD_FOTOS_VISTORIA,
  params
});

export const getFotosVistoria = fotos_vistoria => ({
  type: actions.GET_FOTOS_VISTORIA,
  fotos_vistoria,
});

export const storeFotoVistoria = foto_vistoria => ({
  type: actions.STORE_FOTO_VISTORIA,
  foto_vistoria
});

export const getFotoVistoria = foto_vistoria => ({
  type: actions.GET_FOTO_VISTORIA,
  foto_vistoria,
});

export const removeFotoVistoria = (id) => ({
  type: actions.REMOVE_FOTO_VISTORIA,
  id
});

export const changeStatus = status => ({
  type: actions.CHANGE_STATUS_CODE,
  status,
});

export const getErros = errors => ({
  type: actions.FOTO_VISTORIA_ERROR,
  errors,
});
