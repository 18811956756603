import React from 'react';
import { Content } from '~/layouts/Dash/components';


const BasicContent = ({breadcrumb, children}) => {

  const breadcrumbs = [
    {
      label: 'Distribuição',
      to: '/distribuicao',
      active: false,
    },
    breadcrumb,
  ];
  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="CENTRAL DE DISTRIBUIÇÃO DE PROCESSO"
    >
      {children}
    </Content>
  );
}

export default BasicContent;
