import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import buscaCep from '~/services/cep';
import { useSelector } from 'react-redux';
import FileInput from '~/components/FileInput';
import maskCEP from '~/utils/formatCep'
import formatPhone from '~/utils/formatPhone'
import formatCpfCnpj from '~/utils/formatCpfCnpj';

const FieldsTecnicoProfissional = ({ tecnico, setTecnico, errors }) => {
  const { uf_data } = useSelector(({ UfDataReducer }) => UfDataReducer);

  const handleChange = event => {
    const { name, value } = event.target;
    setTecnico({
      ...tecnico,
      [name]: value
    });
  };

  const handleBuscaCep = async (event) => {
    const { value } = event.target;
    const cep = value;
    try {
      const data = await buscaCep.get(`${cep}/json`);
      const endereco = data.data;
      const finded = {
        logradouro: endereco.logradouro,
        bairro: endereco.bairro,
        cidade: endereco.localidade
      }
      Object.assign(tecnico, finded);
      setTecnico({
        ...tecnico
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div style={{ border: '1px solid black', borderRadius: '3px',padding: '15px 20px', margin: '10px 0px' }}>
      <h5>Dados do Técnico</h5>
      <Form.Group as={Row}>
        <Col sm={6}>
          <Form.Label>CPF/CNPJ (Somente números)</Form.Label>
          <Form.Control
            isInvalid={!!errors.cpf_cnpj_tecnico}
            maxLength="18"
            minLength="14"
            name="cpf_cnpj_tecnico"
            onChange={handleChange}
            placeholder="CPF/CNPJ"
            type="text"
            value={formatCpfCnpj(tecnico.cpf_cnpj_tecnico ?? '') || ''}
          />
          <Form.Control.Feedback type="invalid">
            {errors.cpf_cnpj_tecnico}
          </Form.Control.Feedback>
        </Col>
        <Col>
          <Form.Label>Anexo</Form.Label>
          <FileInput
            dataError={errors.anexo_cpf_cnpj}
            name="anexo_cpf_cnpj"
            data={tecnico}
            setData={setTecnico}
          />
          <Form.Control.Feedback type="invalid">
            {errors.anexo_cpf_cnpj}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col sm={6}>
          <Form.Label>RG (Somente números)</Form.Label>
          <Form.Control
            isInvalid={!!errors.rg}
            name="rg"
            onChange={handleChange}
            placeholder="RG (Somente números)"
            type="text"
            value={tecnico.rg || ''}
          />
          <Form.Control.Feedback type="invalid">
            {errors.rg}
          </Form.Control.Feedback>
        </Col>
        <Col>
          <Form.Label>Anexo</Form.Label>
          <FileInput
            dataError={errors.anexo_rg}
            name="anexo_rg"
            data={tecnico}
            setData={setTecnico}
          />
          <Form.Control.Feedback type="invalid">
            {errors.anexo_rg}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group>
        <Form.Label>RG UF</Form.Label>
        <Form.Control
          as="select"
          isInvalid={!!errors.uf_rg_id}
          name="uf_rg_id"
          onChange={handleChange}
          placeholder="UF RG"
          type="text"
          value={tecnico.uf_rg_id || ''}
        >
          {uf_data[0] && uf_data.map(uf =>
            <option
              key={uf.id}
              value={uf.id}
            >
              {uf.uf}
            </option>)}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {errors.uf_rg_id}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Data Nascimento</Form.Label>
        <Form.Control
          isInvalid={!!errors.data_nasc}
          name="data_nasc"
          onChange={handleChange}
          placeholder="Data Nascimento"
          type="date"
          value={tecnico.data_nasc || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.data_nasc}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>CEP</Form.Label>
        <Form.Control
          isInvalid={!!errors.cep}
          name="cep"
          onBlur={handleBuscaCep}
          onChange={handleChange}
          placeholder="CEP"
          type="text"
          maxLength="9"
          value={maskCEP(tecnico.cep ?? '') || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.cep}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Row}>
        <Col sm={6}>
          <Form.Label>Logradouro</Form.Label>
          <Form.Control
            isInvalid={!!errors.logradouro}
            name="logradouro"
            onChange={handleChange}
            placeholder="Logradouro"
            type="text"
            value={tecnico.logradouro || ''}
          />
          <Form.Control.Feedback type="invalid">
            {errors.logradouro}
          </Form.Control.Feedback>
        </Col>
        <Col>
          <Form.Label>Anexo</Form.Label>
          <FileInput
            dataError={errors.anexo_endereco}
            name="anexo_endereco"
            data={tecnico}
            setData={setTecnico}
          />
          <Form.Control.Feedback type="invalid">
            {errors.anexo_endereco}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group>
        <Form.Label>Número</Form.Label>
        <Form.Control
          isInvalid={!!errors.numero_endereco}
          name="numero_endereco"
          onChange={handleChange}
          placeholder="Número"
          type="text"
          value={tecnico.numero_endereco || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.numero_endereco}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Bairro</Form.Label>
        <Form.Control
          isInvalid={!!errors.bairro}
          name="bairro"
          onChange={handleChange}
          placeholder="Bairro"
          type="text"
          value={tecnico.bairro || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.bairro}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Cidade</Form.Label>
        <Form.Control
          isInvalid={!!errors.cidade}
          name="cidade"
          onChange={handleChange}
          placeholder="Cidade"
          type="text"
          value={tecnico.cidade || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.cidade}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>UF</Form.Label>
        <Form.Control
          as="select"
          isInvalid={!!errors.uf_endereco_id}
          name="uf_endereco_id"
          onChange={handleChange}
          placeholder="UF"
          type="text"
          value={tecnico.uf_endereco_id || ''}
        >
          {uf_data[0] && uf_data.map(uf =>
            <option
              key={uf.id}
              value={uf.id}
            >
              {uf.uf}
            </option>)}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {errors.uf_endereco_id}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Fone de contato</Form.Label>
        <Form.Control
          isInvalid={!!errors.telefone}
          name="telefone"
          onChange={handleChange}
          placeholder="Fone de contato"
          type="text"
          maxLength="15"
          value={formatPhone(tecnico.telefone ?? '') || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.telefone}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Número de WhatsApp</Form.Label>
        <Form.Control
          isInvalid={!!errors.numero_whatsapp}
          name="numero_whatsapp"
          onChange={handleChange}
          placeholder="Número de WhatsAp"
          type="text"
          maxLength="15"
          value={formatPhone(tecnico.numero_whatsapp ?? '') || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.numero_whatsapp}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Youtube</Form.Label>
        <Form.Control
          isInvalid={!!errors.youtube}
          name="youtube"
          onChange={handleChange}
          placeholder="Youtube"
          type="text"
          value={tecnico.youtube || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.youtube}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Facebook</Form.Label>
        <Form.Control
          isInvalid={!!errors.facebook}
          name="facebook"
          onChange={handleChange}
          placeholder="Facebook"
          type="text"
          value={tecnico.facebook || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.facebook}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Linkdin</Form.Label>
        <Form.Control
          isInvalid={!!errors.linkdin}
          name="linkdin"
          onChange={handleChange}
          placeholder="Linkdin"
          type="text"
          value={tecnico.linkdin || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.linkdin}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Twiter</Form.Label>
        <Form.Control
          isInvalid={!!errors.twiter}
          name="twiter"
          onChange={handleChange}
          placeholder="Twiter"
          type="text"
          value={tecnico.twiter || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.twiter}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Row}>
        <Col sm={6}>
          <Form.Label>Formação</Form.Label>
          <Form.Control
            isInvalid={!!errors.formacao}
            name="formacao"
            onChange={handleChange}
            placeholder="Formação"
            type="text"
            value={tecnico.formacao || ''}
          />
          <Form.Control.Feedback type="invalid">
            {errors.formacao}
          </Form.Control.Feedback>
        </Col>
        <Col>
          <Form.Label>Anexo</Form.Label>
          <FileInput
            dataError={errors.anexo_formacao}
            name="anexo_formacao"
            data={tecnico}
            setData={setTecnico}
          />
          <Form.Control.Feedback type="invalid">
            {errors.anexo_formacao}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Col sm={6}>
          <Form.Label>Formação 2 (caso tenha)</Form.Label>
          <Form.Control
            isInvalid={!!errors.formacao_2}
            name="formacao_2"
            onChange={handleChange}
            placeholder="Formação 2 (caso tenha)"
            type="text"
            value={tecnico.formacao_2 || ''}
          />
          <Form.Control.Feedback type="invalid">
            {errors.formacao_2}
          </Form.Control.Feedback>
        </Col>
        <Col>
          <Form.Label>Anexo</Form.Label>
          <FileInput
            dataError={errors.anexo_formacao_2}
            name="anexo_formacao_2"
            data={tecnico}
            setData={setTecnico}
          />
          <Form.Control.Feedback type="invalid">
            {errors.anexo_formacao_2}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col sm={6}>
          <Form.Label>Especialização</Form.Label>
          <Form.Control
            isInvalid={!!errors.especializacao}
            name="especializacao"
            onChange={handleChange}
            placeholder="Especialização"
            type="text"
            value={tecnico.especializacao || ''}
          />
          <Form.Control.Feedback type="invalid">
            {errors.especializacao}
          </Form.Control.Feedback>
        </Col>
        <Col>
          <Form.Label>Anexo</Form.Label>
          <FileInput
            dataError={errors.anexo_especializacao}
            name="anexo_especializacao"
            data={tecnico}
            setData={setTecnico}
          />
          <Form.Control.Feedback type="invalid">
            {errors.anexo_especializacao}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col sm={6}>
          <Form.Label>Mestrado</Form.Label>
          <Form.Control
            isInvalid={!!errors.mestrado}
            name="mestrado"
            onChange={handleChange}
            placeholder="Mestrado"
            type="text"
            value={tecnico.mestrado || ''}
          />
          <Form.Control.Feedback type="invalid">
            {errors.mestrado}
          </Form.Control.Feedback>
        </Col>
        <Col>
          <Form.Label>Anexo</Form.Label>
          <FileInput
            dataError={errors.anexo_mestrado}
            name="anexo_mestrado"
            data={tecnico}
            setData={setTecnico}
          />
          <Form.Control.Feedback type="invalid">
            {errors.anexo_mestrado}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col sm={6}>
          <Form.Label>Doutorado</Form.Label>
          <Form.Control
            isInvalid={!!errors.doutorado}
            name="doutorado"
            onChange={handleChange}
            placeholder="Doutorado"
            type="text"
            value={tecnico.doutorado || ''}
          />
          <Form.Control.Feedback type="invalid">
            {errors.doutorado}
          </Form.Control.Feedback>
        </Col>
        <Col>
          <Form.Label>Anexo</Form.Label>
          <FileInput
            dataError={errors.anexo_doutorado}
            name="anexo_doutorado"
            data={tecnico}
            setData={setTecnico}
          />
          <Form.Control.Feedback type="invalid">
            {errors.anexo_doutorado}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
    </div>
  )
}

export default FieldsTecnicoProfissional;
