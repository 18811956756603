import { Dash } from '~/layouts';
import * as ProcessViews from '~/views/Process';
// import * as CertPrintViews from '~/views/CertidaoPrint';

const basePath = '/processos';

export default [
  { component: ProcessViews.List, path: basePath, layout: Dash, exact: true },
  { component: ProcessViews.New, path: `${basePath}/create/:passo`, layout: Dash, exact: true },
  { component: ProcessViews.New, path: `${basePath}/create/:passo/:processo_id`, layout: Dash, exact: true },
  { component: ProcessViews.Detalhe, path: `${basePath}/:processo_id/detalhe`, layout: Dash, exact: true },
  { component: ProcessViews.CheckListDocs, path: `${basePath}/check-list`, layout: Dash, exact: true },
  { component: ProcessViews.Apia, path: `${basePath}/apia`, layout: Dash, exact: true },
  { component: ProcessViews.PreProcess, path: `${basePath}/pre-processo`, layout: Dash, exact: true },
  { component: ProcessViews.TaxaAmbiental, path: `${basePath}/taxa-processo`, layout: Dash, exact: true },
  { component: ProcessViews.Certidao, path: `${basePath}/certidao-processo`, layout: Dash, exact: true },
  // { component: CertPrintViews.CertidaoPrint, path: `${basePath}/certidao-processo/:processo_id/imprimir`, layout: Blank, exact: true },
];
