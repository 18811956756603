import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';

import { BasicContent } from '../components';
import { Passo1, Passo2, Passo3, Passo4 } from './components/Passos';

export default function New() {
  let { passo} = useParams();
  let Passo = undefined;
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  switch (passo) {
    case 'pass_1':
      Passo = Passo1;
      break;
    case 'pass_2':
      Passo = Passo2;
      break;
    case 'pass_3':
      Passo = Passo3;
      break;
    case 'pass_4':
      Passo = Passo4;
      break;
    default:
      //Passo = Passo1;
      break;
  }
  const breadcrumb = {
    label: 'Novo',
    to: '/processos',
    active: false,
  };
  return (
    <BasicContent breadcrumb={breadcrumb}>
      <Passo/>
    </BasicContent>
  );
}
