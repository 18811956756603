import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { BasicContent } from '../components';
import { FaEye } from 'react-icons/fa';
import { Paginate } from '~/components';

import {
  loadEntidades,
  ativarDesativarEntidade,
} from '~/store/modules/entidade/action';

const List = () => {
  const dispatch = useDispatch();
  const { user_auth } = useSelector(({ LoginReducer }) => LoginReducer);
  const { entidades } = useSelector(({ EntidadeReducer }) => EntidadeReducer);

  useEffect(() => {
    dispatch(loadEntidades());
  }, [dispatch]);

  // useEffect(() => {
  //   console.log(entidades);
  // }, [entidades]);


  const breadcrumb = {
    label: 'Lista',
    to: '/entidade',
    active: false,
  };

  const handleSwitchActiveStatus = async (entidade) => {
    const activeStatusMsg1 = entidade.ativo ? 'Desativar' : 'Ativar';
    const activeStatusMsg2 = entidade.ativo ? 'Desativado' : 'Ativado';

    Swal.fire({
      title: `${activeStatusMsg1}?`,
      text: `Deseja ${activeStatusMsg1} essa entidade`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then(async result => {
      if (result.value) {
        try {
          await dispatch(ativarDesativarEntidade(entidade.id));
          await Swal.fire(
            'Sucesso',
            `Entidade ${activeStatusMsg2} com sucesso!`,
            'success'
          );
          dispatch(loadEntidades());
        } catch (error) {
          await Swal.fire(
            'Erro',
            `Erro ao ${activeStatusMsg1}`,
            'error'
          );
        }
      }
    });
  };

  return (
    <BasicContent breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-header">
          <h2 className="card-title">Lista</h2>
          <div className="card-tools">
            {user_auth.user.tipo_user_id === 1 ? (
              <Link
                className="btn btn-success"
                to="entidade/create"
              >
                {' '}
                <i className="fa fa-plus" /> Novo
              </Link>
            ) : ''}
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-hover table-striped">
              <thead>
                <tr>
                  <th>id</th>
                  <th>Nome</th>
                  <th>Ativa</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                {entidades.data &&
                  entidades.data.map((entidade, index) => (
                    <tr key={index}>
                      <td>{entidade.id}</td>
                      <td>{entidade.nome}</td>
                      <td>{entidade.ativo ? 'SIM' : 'NÃO'}</td>
                      <td>
                        <div className="btn-group">
                          <Link
                            className="btn btn-info"
                            to={`/entidade/${entidade.id}/detalhe`}
                          >
                            <FaEye /> Detalhes
                          </Link>
                          {user_auth.user.tipo_user_id === 1 ? (
                            <>
                              {entidade.ativo ? (
                                <button
                                  className="btn btn-danger"
                                  onClick={() => handleSwitchActiveStatus(entidade)}
                                >
                                    Desativar
                                </button>
                              ): (
                                <button
                                  className="btn btn-success"
                                  onClick={() => handleSwitchActiveStatus(entidade)}
                                >
                                    Ativar
                                </button>
                              )}
                            </>
                          ) : ''}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="card-footer clearfix">
          <Paginate
            load={loadEntidades}
            meta={entidades}
          />
        </div>
      </div>
    </BasicContent>
  );
};

export default List;
