import React from 'react';
import { Link } from 'react-router-dom';
import { FaExclamationCircle, FaArrowCircleLeft } from 'react-icons/fa';
import {
  boxSelectCurso as BoxSelectCurso,
  boxActions as BoxActions,
} from './styles';

export default function SelectCadastro() {
  return (
    <BoxSelectCurso className="d-flex d-flex-row box-base-green">
      <h3> COMO DESEJA SE CADASTRAR? </h3>

      <div
        className="btn btn-success btn-block"
      >
        <Link
          to="/cadastro-pessoa-fisica"
          style={{color:'#fff'}}
        >
          Cadastro Pessoa Física (CPF) <FaExclamationCircle />
        </Link>
      </div>
      
      <div
        className="btn btn-success btn-block"
      >
        <Link
          style={{color:'#fff'}}
          to="/cadastro-pessoa-juridica"
        >
          Cadastro Pessoa Jurídica (CPJ) <FaExclamationCircle />
        </Link>
      </div>
      
      <div
        className="btn btn-success btn-block"
      >
        <Link
          style={{color:'#fff'}}
          to="/cadastro-tec-prof"
        >
        Cadastro Técnico Consultor (CTC) <FaExclamationCircle />
        </Link>
      </div>
      
      <BoxActions>
        <Link
          className="btn btn-musgo"
          to="/"
        >
          <FaArrowCircleLeft /> Voltar
        </Link>
      </BoxActions>
    </BoxSelectCurso>
  );
}
