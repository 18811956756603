export const LOAD_PROCESSOS = 'LOAD_PROCESSOS';
export const LOAD_PROCESSO = 'LOAD_PROCESSO';
export const LOAD_TRAMITES_PROCESSO = 'LOAD_TRAMITES_PROCESSO';
export const TRAMITES_PROCESSOS_DATA = 'TRAMITES_PROCESSOS_DATA';
export const GET_TRAMITES_PROCESSO = 'GET_TRAMITES_PROCESSO';
export const PROCESSOS_DATA = 'PROCESSOS_DATA';
export const PROCESSO_ERROR = 'PROCESSO_ERROR';
export const PROCESSO_DATA = 'PROCESSO_DATA';
export const STORE_PROCESSO = 'STORE_PROCESSO';
export const GET_PROCESSO = 'GET_PROCESSO';
export const DESATIVAR_PROCESSO = 'DESATIVAR_PROCESSO';
export const REMOVER_PROCESSO = 'REMOVER_PROCESSO';
export const SHOW_PROCESSO = 'SHOW_PROCESSO';
export const UPDATE_PROCESSO = 'UPDATE_PROCESSO';
export const ADD_ATIVIDADE_TIPO_LICENCA = 'ADD_ATIVIDADE_TIPO_LICENCA';
export const ADD_PESSOA_RESPONSAVEL_TECNICO = 'ADD_PESSOA_RESPONSAVEL_TECNICO';
export const ENVIAR_PARA_APIA = 'ENVIAR_PARA_APIA';
export const ENVIAR_PARA_DISTRIBUICAO = 'ENVIAR_PARA_DISTRIBUICAO';
export const GERAR_COBRANCA = 'GERAR_COBRANCA';
export const LOAD_PROCESSO_DISTRIBUICAO = 'LOAD_PROCESSO_DISTRIBUICAO';
export const ENVIAR_PARA_ANALISTA = 'ENVIAR_PARA_ANALISTA';
export const LOAD_PROCESSO_LISTA_ANALISE = 'LOAD_PROCESSO_LISTA_ANALISE';
export const LOAD_CONDICIONANTES_PROCESSO = 'LOAD_CONDICIONANTES_PROCESSO';
export const CONDICIONANTES_PROCESSOS_DATA = 'CONDICIONANTES_PROCESSOS_DATA';
export const UPDATE_CONDICIONANTES_PROCESSO = 'UPDATE_CONDICIONANTES_PROCESSO';
export const GET_BOLETO = 'GET_BOLETO';
export const SET_BOLETO = 'SET_BOLETO';
export const ENVIAR_PARA_AUTORIZACAO = 'ENVIAR_PARA_AUTORIZACAO';
export const CHANGE_STATUS_CODE = 'CHANGE_STATUS_CODE';
export const LOAD_PROCESSO_AUTORIZACAO = 'LOAD_PROCESSO_AUTORIZACAO';
export const FINALIZAR_PROCESSO = 'FINALIZAR_PROCESSO';
export const ENVIAR_LICENCA = 'ENVIAR_LICENCA';
export const LISTA_PROCESSOS = 'LISTA_PROCESSOS';
export const LISTA_PROCESSOS_POR_USUARIOS = 'LISTA_PROCESSOS_POR_USUARIOS';
export const GET_PROCESSOS_POR_REFERENCIA = 'GET_PROCESSOS_POR_REFERENCIA';
export const LIST_PROCESSOS_POR_REFERENCIA = 'LIST_PROCESSOS_POR_REFERENCIA';
export const GET_PROCESSOS_CCLA = 'GET_PROCESSOS_CCLA';
export const LISTA_PROCESSOS_CCLA = 'LISTA_PROCESSOS_CCLA';
