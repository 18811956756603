import React, { useState, useEffect } from 'react';
import { Content } from '~/layouts/Dash/components';
import { Paginate } from '~/components';
import { FaList } from 'react-icons/fa';
import { apiWithToken } from '../../services/api';
import formatDataHora from '../../utils/formatDataHora';

const token = localStorage.getItem('@token');
let api = apiWithToken(token);

const breadcrumbs = [
  { label: 'Lista', to: '/remessas', active: true }
];

export default function List() {
  const [remittances, setRemittances] = useState({
    data: [],
    last_page: 0,
    current_page: 0,
    from: '',
    to: '',
    total: 0
  });

  async function getRemittances() {
    const res = await api.get('remessas');

    setRemittances(res.data);
  }

  useEffect(() => {
    getRemittances();
  }, []);

  async function handleDownload(remittance) {
    const res = await api.get(
      `remessas/${remittance.id}/download`, {
        responseType: 'blob'
      }
    );

    const link = document.createElement('a');
    const href = URL.createObjectURL(res.data);
    const [,,,fileName] = remittance.file_path.split('/');

    link.href = href;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  // async function handleRemove() {
  //   //
  // }

  return (
    <Content breadcrumbs={breadcrumbs} title="Remessas">
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">
            <FaList /> LISTAGEM
          </h3>
          <div className="card-tools">
            {/* <Link
              className="btn  btn-success"
              to="/processos/create/pass_1"
            >
              <FaPlus /> Novo Processo
            </Link> */}
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered table-sm table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Data</th>
                  <th style={{ width: '400px' }}>
                    Ações
                  </th>
                </tr>
              </thead>
              <tbody>
                {remittances.data.map((remittance, index) => (
                  <tr key={`remessa-${index}`}>
                    <td>{remittance.id}</td>
                    <td>{formatDataHora(remittance.created_at)}</td>
                    <td>
                      <div className="btn-group" style={{ width: '100%' }}>
                        <button
                          type="button"
                          className="btn btn-default"
                        >
                          Processos
                        </button>
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() => handleDownload(remittance)}
                        >
                          Fazer Download
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                        >
                          Upload Retorno
                        </button>
                        {/* <button
                          type="button"
                          className="btn btn-danger"
                          onClick={handleRemove}
                        >
                          Remover
                        </button> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="card-footer">
          <Paginate
            load={getRemittances}
            meta={{
              last_page: remittances.last_page,
              current_page: remittances.current_page,
              from: remittances.from,
              to: remittances.to,
              total: remittances.total
            }}
          />
        </div>
      </div>
    </Content>
  );
}
