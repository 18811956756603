import React, { useEffect, useState } from 'react';
import { BasicContent } from './components';
import { Generica, Administrador} from './components/Layouts';
import { useSelector } from 'react-redux';
import {Loading} from '~/components'
import {apiWithToken} from '~/services/api';
const token = localStorage.getItem('@token');
let api = apiWithToken(token);

const Dashboard = () => {

  const { user_auth } = useSelector(({ LoginReducer }) => LoginReducer);
  const [myInformation, setMyInformation] = useState([]);

  const breadcrumb = {
    label: 'DashBoard',
    to: '/dashboard',
    active: false,
  };

  useEffect(() => {
    const getUserData = async () => {
      if(user_auth.user.tipo_user_id === 8 || user_auth.user.tipo_user_id === 6){
        const response = await api.get(`meu_dashboard/${user_auth.user.id}`);
        setMyInformation(response.data);
      }
    }
    getUserData();
  },[user_auth.user.tipo_user_id, user_auth.user.id])

  // console.log(myInformation);
  const showContent = () => {
    if (user_auth.user) {
      switch (user_auth?.user.tipo_user_id) {
        case 2:
          return <Administrador/>

        default:
          return <Generica myInformation={myInformation}/>;
      }
    }

  }

  return (
    <BasicContent breadcrumb={breadcrumb}>

      { user_auth ? (<>
        {showContent()}
      </>) : (<Loading/>) }
    </BasicContent>
  );

};

export default Dashboard;
