import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormCad } from '../components';
import Swal from 'sweetalert2';
import initialData from './initialData';
// import schema from './schema'
import { storeUser } from '~/store/modules/user/action';
import { Loading } from '~/components';
import { loadTipoUsers } from '~/store/modules/tipo_user/action';
import { loadEntidades } from '~/store/modules/entidade/action';
import { initialData as pfInitialData } from '../../CadPessoaFisica/components/FormCad/InitialData';
import { initialData as pjInitialData } from '../../CadPessoaJuridica/components/FormCad/InitialData';
import { initialData as tpInitialData } from '../../CadTecnicoProfissional/components/FormCad/InitialData';
import shemaPessoaFisisca from './schemaValidationsPessoaFisica';
import schemaPessoaJuridica from './schemaValidationsPessoaJuridica';
import schemaTecnicoProfissional from './schemaValidationsTecnicoProdissional';
import removeMask from '~/utils/removeMask';

const Create = () => {
    let history = useHistory();
    const pessoaFields = Object.assign(
        tpInitialData, pjInitialData, pfInitialData
    );
    const [usuario, setUsuario] = useState(initialData);
    const [pessoa, setPessoa] = useState(pessoaFields);
    const [saved, setSaved] = useState(false);
    // const [errorsValidate, setErrorsValidate] = useState([]);
    const dispatch = useDispatch();
    const [errorsForm, setErrors] = useState({});
    const { status_response } = useSelector(({ UserReducer }) => UserReducer);
    const { errors } = useSelector(({ UserReducer }) => UserReducer);
    const { tipo_users } = useSelector(({ TipoUserReducer }) => TipoUserReducer);
    const { entidades } = useSelector(({ EntidadeReducer }) => EntidadeReducer);
    // console.log('status_response: ',status_response);

    useEffect(() => {
        dispatch(loadTipoUsers());
        dispatch(loadEntidades({ raw_list: true }));
    }, [dispatch]);

    useEffect(() => {
        if (errors.status && errors.status === 422) {
            let server_errors = {};
            const response_errors = errors.data.errors;
            for (let name of Object.keys(response_errors)) {
                Object.assign(server_errors, { [name]: response_errors[name][0] });
            }
            setErrors(server_errors);
        }
        if (errors.save) {
            setSaved(errors.save);
        }
    }, [errors, saved]);

    const handleClose = () => {
        history.push('/cad_users');
    };

    useEffect(() => {
        if (saved && (status_response === 200 || status_response === 201)) {
            Swal.fire('Sucesso', 'Registro salvo com sucesso!', 'success').then(
                () => {
                    history.push('/cad_users');
                    // dispatch(loadResponsavelTecnicos());
                }
            );
        }
    }, [status_response, history, dispatch, saved]);


    const handleSubmit = async (event) => {
        event.preventDefault();
        let yup_errors = {};
        const fields = Object.assign(pessoa, usuario);
        fields.tipo_user_id = parseInt(fields.tipo_user_id)
        if (
            (fields.tipo_user_id === 1 ||
                fields.tipo_user_id === 2 ||
                fields.tipo_user_id === 3 ||
                fields.tipo_user_id === 4 ||
                fields.tipo_user_id === 8) &&
            !shemaPessoaFisisca.isValidSync(fields)
        ) {
            shemaPessoaFisisca.validate(fields, { abortEarly: false }).catch(err => {
                for (let error of err.inner) {
                    const { path, message } = error;
                    Object.assign(yup_errors, { [path]: message });
                }
                setErrors(yup_errors);
            });
        } else if (
            fields.tipo_user_id === 6 && !schemaPessoaJuridica.isValidSync(fields)
        ) {
            schemaPessoaJuridica.validate(fields, { abortEarly: false }).catch(err => {
                for (let error of err.inner) {
                    const { path, message } = error;
                    Object.assign(yup_errors, { [path]: message });
                }
                setErrors(yup_errors);
            });
        } else if (
            (
                fields.tipo_user_id === 5 ||
                fields.tipo_user_id === 7
            ) && !schemaTecnicoProfissional.isValidSync(fields)

        ) {
            schemaTecnicoProfissional.validate(fields, { abortEarly: false }).catch(err => {
                for (let error of err.inner) {
                    const { path, message } = error;
                    Object.assign(yup_errors, { [path]: message });
                }
                setErrors(yup_errors);
            });
        }
        else {
            switch (fields.tipo_user_id) {
                case 1: case 2: case 3: case 4: case 8:
                    fields.cpf_cnpj = removeMask(fields.cpf ?? '')
                    break;
                case 6:
                    fields.cnpj = removeMask(fields.cnpj ?? '')
                    fields.cpf_cnpj = removeMask(fields.cnpj ?? '')
                    break;
                case 7: case 5:
                    fields.cpf_cnpj = removeMask(fields.cpf_cnpj_tecnico ?? '')
                    break;
                default:
                    break;
            }

            fields.cep = removeMask(fields.cep ?? '');
            fields.cpf_responsavel_legal = removeMask(fields.cpf_responsavel_legal ?? '');
            fields.numero_whatsapp = removeMask(fields.numero_whatsapp ?? '');
            fields.cpf = removeMask(fields.cpf ?? '')
            fields.cnpj = removeMask(fields.cnpj ?? '')
            fields.telefone = removeMask(fields.telefone ?? '')
            fields.telefone_responsavel_legal = removeMask(fields.telefone_responsavel_legal ?? '')

            const form = new FormData();

            for (let key in fields) {
                form.append(key, fields[key]);
            }

            dispatch(storeUser(form));
        }
    }

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="modal-xl"
            onHide={handleClose}
            show
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Cadastrar usuário
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    (tipo_users.data && entidades.data) && (
                        <FormCad
                            entidades={entidades.data}
                            tipo_users={tipo_users.data}
                            errors={errorsForm}
                            onHandleSubmit={handleSubmit}
                            usuario={usuario}
                            setErrors={setErrors}
                            setUsuario={setUsuario}
                            pessoa={pessoa}
                            setPessoa={setPessoa}
                        />
                    )
                }

                {(!tipo_users.data || !entidades.data) && <Loading />}
            </Modal.Body>
        </Modal>
    );
};

export default Create;
