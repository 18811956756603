import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as TipoLicenca from '~/services/TipoLicenca';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadTipoLicencasWorker({params}) {
  try {
    const response = yield call(TipoLicenca.load, params);
    yield put(actions.getTipoLicencas(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}


function* showTipoLicencaWorker({id, params}) {
  try {
    const response = yield call(TipoLicenca.show, id, params);
    yield put(actions.getTipoLicenca(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updateTipoLicencaWorker({ tipo_licenca, id}) {
  try {
    const response = yield call(TipoLicenca.update, id, tipo_licenca);
    yield put(actions.getTipoLicenca(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeTipoLicencaWorker({tipo_licenca}) {
  try {
    const response = yield call(TipoLicenca.store, tipo_licenca);
    // yield put(actions.getTipoLicenca(response));
    yield put(actions.changeStatus(response.status));
    yield put(actions.getErros({save:true}));
    yield put(getErros({save:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* removerTipoLicencaWorker({id}) {
  try {
    yield call(TipoLicenca.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}


function* ativarTipoLicencaWorker({id}) {
  try {
    yield call(TipoLicenca.activate, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherTipoLicenca() {
  yield takeLatest(actionType.LOAD_TIPO_LICENCAS, loadTipoLicencasWorker);
  yield takeLatest(actionType.STORE_TIPO_LICENCA, storeTipoLicencaWorker);
  yield takeLatest(actionType.SHOW_TIPO_LICENCA, showTipoLicencaWorker);
  yield takeLatest(actionType.UPDATE_TIPO_LICENCA, updateTipoLicencaWorker);
  yield takeLatest(actionType.REMOVER_TIPO_LICENCA, removerTipoLicencaWorker);
  yield takeLatest(actionType.ACTIVATE_TIPO_LICENCA, ativarTipoLicencaWorker);
}

export default function* saga() {
  yield all([watcherTipoLicenca()]);
}
