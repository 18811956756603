import { apiWithToken } from '../api';

const resouceUrl = '/autorizacao_processos';
const token = localStorage.getItem('@token');
let api = apiWithToken(token);

export const load = async (params) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let page = urlParams.has('page') ? { page: urlParams.get('page') } : {};
  let search = urlParams.has('search')
    ? { search: urlParams.get('search') }
    : {};
  return await api.get(`${resouceUrl}`, {
    params: {
      ...params,
      ...page,
      ...search,
    },
  });
};

export const select = async () => {
  return await api.get(`${resouceUrl}?fields=id,nome&raw_list`);
};

export const show = async (id) => {
  return await api.get(`${resouceUrl}/${id}`);
};

export const update = async (id, autorizacao_processo) => {
  return await api.put(`${resouceUrl}/${id}`, autorizacao_processo);
};

export const store = async (autorizacao_processo) => {
  let response = await api.post(resouceUrl, autorizacao_processo);
  return response;
};

export const destroy = async (id) => {
  return await api.delete(`${resouceUrl}/${id}`);
};
