export const LOAD_TIPO_MEIO_ANALISES = 'LOAD_TIPO_MEIO_ANALISES';
export const SELECT_TIPO_MEIO_ANALISES = 'SELECT_TIPO_MEIO_ANALISES';
export const TIPO_MEIO_ANALISE_ERROR = 'TIPO_MEIO_ANALISE_ERROR';
export const TIPO_MEIO_ANALISE_GET_ERRORS = 'TIPO_MEIO_ANALISE_GET_ERRORS';
export const TIPO_MEIO_ANALISES_DATA = 'TIPO_MEIO_ANALISES_DATA';
export const STORE_TIPO_MEIO_ANALISE = 'STORE_TIPO_MEIO_ANALISE';
export const GET_TIPO_MEIO_ANALISE = 'GET_TIPO_MEIO_ANALISE';
export const REMOVER_TIPO_MEIO_ANALISE = 'REMOVER_TIPO_MEIO_ANALISE';
export const SHOW_TIPO_MEIO_ANALISE = 'SHOW_TIPO_MEIO_ANALISE';
export const UPDATE_TIPO_MEIO_ANALISE = 'UPDATE_TIPO_MEIO_ANALISE';
export const CHANGE_STATUS_CODE = 'CHANGE_STATUS_CODE';
