export const initialData = {   
    nome: '',
    tipo_user_id: 1,
    entidade_id: 1,
    email: '',
    password: '',
    confirmacao_senha: '',

    // rg: '',
    // cpf: '',
    // cep: '',
    // logradouro: '',
    // bairro: '',
    // uf_endereco_id: '',
    // telefone: '',
    // numero_endereco: '',
    // numero_whatsapp: '',
    // cidade: '',
    // uf_endereco: '',
    // anexo_cpf: '',
  };
  
  export default initialData;
  