import * as actions from './actionType';

export const loadPagamentosPendentes = (params) => ({
  type: actions.LOAD_PAGAMENTOS_PENDENTES,
  params,
});

export const getPagamentosPendentes = processos => ({
  type: actions.GET_PAGAMENTOS_PENDENTES,
  processos,
});

export const getErros = errors => ({
  type: actions.PAGAMENTOS_PENDENTES_ERROR,
  errors,
});

export const changeStatusPayment = (params) => ({
  type: actions.MUDAR_STATUS_PAGAMENTO_PROCESSO,
  params
});
