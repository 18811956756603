import * as actions from './actionType';

export const loadVeiculos = (params) => ({
  type: actions.LOAD_VEICULOS,
  params
});
export const getVeiculos = veiculos => ({
  type: actions.VEICULOS_DATA,
  veiculos,
});

export const loadSelectVeiculos = veiculos => ({
  type: actions.LOAD_SELECT_VEICULOS,
  veiculos,
});

export const getSelectVeiculos = veiculos => ({
  type: actions.SELECT_VEICULOS,
  veiculos,
});

export const storeVeiculo = veiculo => ({
  type: actions.STORE_VEICULO,
  veiculo,

});
export const getVeiculo = veiculo => ({
  type: actions.GET_VEICULO,
  veiculo,
});

export const removerVeiculo = (id) => ({
  type: actions.REMOVER_VEICULO,
  id
});

export const showVeiculo = id => ({
  type: actions.SHOW_VEICULO,
  id,
});

export const updateVeiculo = (veiculo, id) => ({
  type: actions.UPDATE_VEICULO,
  veiculo,
  id
});

export const getErros = errors => ({
  type: actions.VEICULO_ERROR,
  errors,
});
