import React from 'react';
import { Link } from 'react-router-dom';
// import Swal from 'sweetalert2';
import { Paginate } from '~/components';
import { FaEye, FaEdit, FaTimesCircle, FaPlusCircle } from 'react-icons/fa';
// import { loadTipoLicencas } from '~/store/modules/tipo_licenca/action';
// import Show from '../../../../Atividade/Show';
// import Edit from '../Edit';
import { Button } from 'react-bootstrap';


const List = ( {
  action,
  displayName,
  id,
  // createPath,
  createUrl,
  title,
  labelsCol,
  valuesCol,
  values,
  detalhe,
  edit,
  path_detalhe,
  handleRemove,
  handleActivate,
  importDataUrl
} ) => {
  let path_detalhe_ = ['',''];
  if(detalhe){
    path_detalhe_ = path_detalhe.split('*');
  }

  return (
    <div className="card">
      <div className="card-header">
        <h2 className="card-title">{title}</h2>
        <div className="card-tools">
          <Link
            className="btn btn-success"
            to={{
              pathname: createUrl,
              state: { modal: true }
            }}
          >
            <i className="fa fa-plus" /> Adicionar
          </Link>
          {importDataUrl && (
            <Link
              className="btn btn-primary"
              to={{
                pathname: importDataUrl,
                state: { modal: true }
              }}
            >
              <i className="fa fa-plus-circle" /> Importar dados
            </Link>
          )}
        </div>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-bordered table-hover table-striped table-sm">
            <thead>
              <tr>
                {labelsCol.map((label, index)=>(<th key={index}>{label}</th>))}
                <th className="text-right">Ações</th>
              </tr>
            </thead>
            <tbody>
              {values.data &&
                  values.data.map((value, index) => (
                    <tr key={index}>
                      {
                        valuesCol && valuesCol.map((val, index)=>
                          (<td key={index}>{value[val]}</td>))
                      }


                      <td className="text-right">
                        <div className="btn-group">
                          {
                            edit && !value.deleted_at &&
                            (
                              <Link
                                className="btn btn-info"
                                to={{
                                  pathname: `/questao_analise/${id}/alternativa/${value.id}/editar`,
                                  state: { modal: true },
                                }}
                              >
                                <FaEdit /> Editar
                              </Link>
                            )
                          }
                          {
                            detalhe &&
                            (
                              <Link
                                className="btn btn-default"
                                to={{
                                  pathname: path_detalhe_[0] + value.id + path_detalhe_[1]
                                }}
                              >
                                <FaEye /> Detalhes
                              </Link>
                            )
                          }
                          {
                            !value.deleted_at &&
                            (
                              <Button
                                className="btn btn-danger"
                                onClick={() => handleRemove(value.id)}
                              >
                                <FaTimesCircle /> Desativar
                              </Button>
                            )
                          }
                          {
                            !!value.deleted_at &&
                            (
                              <Button
                                className="btn btn-success"
                                onClick={() => handleActivate(value.id)}
                              >
                                <FaPlusCircle />  Ativar
                              </Button>
                            )
                          }
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="card-footer clearfix">
        <Paginate
          load={action}
          loadParans={{where:`${displayName}:${id}`}}
          meta={values}
        />
      </div>
    </div>
  );
};

export default List;
