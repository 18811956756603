import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Loading } from '~/components';
import { loadQuestaoAnalises } from '~/store/modules/questao_analise/action';


const ModalAnalise = ({ tipo_meio_analise, show, handleHide, analise_id}) => {
  const [tipoMeioAnalise, setTipoMeioAnalise] = useState();
  const [questaoAnalises, setQuestaoAnalises] = useState();
  const [totalValorAnalise, setTotalValorAnalise] = useState(0);
  const dispatch = useDispatch();

  const { questao_analises } = useSelector(
    ({ QuestaoAnaliseReducer }) => QuestaoAnaliseReducer
  );

  useEffect(() => {
    if (tipo_meio_analise && tipo_meio_analise.id) {
      setTipoMeioAnalise(tipo_meio_analise);
      dispatch(
        loadQuestaoAnalises({
          where: `tipo_meio_analise_id:${tipo_meio_analise.id}`,
          raw_list: true,
          with: `alternativa_questao_analises,resposta_questao_analise_previas;analise_processo_id:${analise_id}`,
        })
      );
    }
  }, [tipo_meio_analise, analise_id, dispatch]);

  useEffect(() => {
    if (questao_analises.data) {
      setQuestaoAnalises(questao_analises.data);
    }
  }, [questao_analises]);

  useEffect(() => {
    if (questaoAnalises && questaoAnalises[0]) {
      let total = 0;
      for (let { resposta_questao_analise_previas } of questaoAnalises) {
        if (resposta_questao_analise_previas && resposta_questao_analise_previas[0]) {
          total += parseFloat(resposta_questao_analise_previas[0].valor);
        }
      }
      setTotalValorAnalise(total);
    }
  }, [questaoAnalises]);



  return (
    <>
      <Modal
        animation
        aria-labelledby="example-custom-modal-styling-title"
        centered
        dialogClassName="modal-xl"
        onHide={handleHide}
        show={show}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Impacto {tipoMeioAnalise && tipoMeioAnalise.nome}{' '}
            {!tipoMeioAnalise && <Loading />}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {tipoMeioAnalise && tipoMeioAnalise.descricao}{' '}
          {!tipoMeioAnalise && <Loading />}
          {questaoAnalises && (
            <table className="table table-sm table-striped table-hover table-bordered">
              <tbody>
                {questaoAnalises.map(
                  (
                    {
                      id,
                      nome,
                      alternativa_questao_analises,
                      resposta_questao_analise_previas,
                    },
                    index
                  ) => (
                    <Fragment key={index}>
                      <tr>
                        <th
                          className="align-text-middle"
                          rowSpan="6"
                        >
                          {' '}
                          <br /> <br /> <br /> <br />
                          {++index}
                        </th>
                        <th colSpan="2">{nome}</th>
                        <td
                          className="align-text-bottom text-center"
                          rowSpan="6"
                          width="15%"
                        >
                          <br /> <br />
                          {resposta_questao_analise_previas &&
                          resposta_questao_analise_previas[0] && (
                              <>
                                <br />
                                <strong>
                                  {resposta_questao_analise_previas[0].valor}
                                </strong>
                                <br />

                              </>
                            )}
                        </td>
                      </tr>
                      {alternativa_questao_analises &&
                        alternativa_questao_analises.map(
                          ({ nome, min_valor, max_valor }, i) => (
                            <tr key={i}>
                              <th
                                className="text-center"
                                width="10%"
                              >
                                {min_valor} - {max_valor}
                              </th>

                              <td>{nome}</td>
                            </tr>
                          )
                        )}
                    </Fragment>
                  )
                )}

                <tr>
                  <th colSpan="3">
                    Total impacto {tipoMeioAnalise && tipoMeioAnalise.nome}
                  </th>

                  <td className="text-center">{totalValorAnalise}</td>
                </tr>
              </tbody>
            </table>
          )}
          {!questaoAnalises && <Loading />}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-default"
            onClick={handleHide}
            type="button"
          >
            <i className="fa fa-window-close" /> Fechar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalAnalise;
