export const initialData = {
  nome: '',
  estado_id: 1,
  municipio_id: 1,
  nome_governador: '',
  nome_prefeito: '',
  nome_gestor_ambiental: '',
  site_secretaria: '',
  telefone_secretaria: '',
  secretaria_faz_licenciamento: 'true',
  // legislacao_federal:'',
  // legislacao_estadual:'',
  // legislacao_municipal:'',
  funcao_gestor_ambiental: '',
  nome_orgao_ambiental: '',
  // // instrucoes_normativas:'',
  secretaria_tem_conselho_meio_ambiente: 'true',
  legislacao_conselho: '',
  conselho_tem_preiodicidade_de_reunioes: 'false',
  secretaria_possui_fundo_de_ambiente: 'true',
  tem_pf_ou_pj_realizando_servicos_ambientais: 'true',
  // legislacao_fundo_meio_ambiente:'',
  email: '',
  cnpj: '',
  telefone_fixo: '',
  telefone_movel: '',
  cep: '',
  logradouro: '',
  numero: '',
  bairro: '',
  logo: '',
  logo_municipio: '',
  assinatura_secretario: '',
  payment_gateway: '',
  bb_sia_username: '',
  bb_sia_password: '',
  cnae_group: 'false',
  formula_calc_grupo: 'media',
};
export default initialData;
