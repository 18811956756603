import styled from 'styled-components';


export const Container = styled.div`
    /* margin: auto auto; */
    margin-top: 20px;
    width: 256mm;
    height: 1000px;
    background-color: #fff;
    font-family: "Arial";
`;

export const TitleSup1 = styled.div`
    align-self: flex-start;
    font-size: 10px;
    margin-left: 4px;
`;

export const ButtonPrint = styled.button`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  background-color: #56DA35;
  height: 30px;
  border-radius: 50px;
`;
