import * as actions from './actionType';

export const EntidadeReducer = (
  state = {
    entidades: {}, entidade: {},
    status_response: null, errors: {}
  },
  action
) => {
  switch (action.type) {
    case actions.ENTIDADE_DATA:
      return {
        ...state,
        entidades: action.entidades.data,
      };
    case actions.GET_ENTIDADE:
      return {
        ...state,
        entidade: action.entidade.data.data,
      };
    case actions.ENTIDADE_ERROR:
      return {
        ...state,
        errors: action.errors
      };
    case actions.CHANGE_STATUS_CODE:
      return {
        ...state,
        status_response: action.status,
      };
    default:
      return state;
  }
}
