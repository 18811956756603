export const LOAD_TIPO_USERS = 'LOAD_TIPO_USERS';
export const TIPO_USER_ERROR = 'TIPO_USER_ERROR';
export const TIPO_USER_GET_ERRORS = 'TIPO_USER_GET_ERRORS';
export const TIPO_USERS_DATA = 'TIPO_USERS_DATA';
export const STORE_TIPO_USER = 'STORE_TIPO_USER';
export const GET_TIPO_USER = 'GET_TIPO_USER';
export const REMOVER_TIPO_USER = 'REMOVER_TIPO_USER';
export const SHOW_TIPO_USER = 'SHOW_TIPO_USER';
export const UPDATE_TIPO_USER = 'UPDATE_TIPO_USER';
export const LIST_CUSTOM_TIPO_USER = 'LIST_CUSTOM_TIPO_USER';

export const LOAD_MENUS_TIPO_USER = 'LOAD_MENUS_TIPO_USER';
export const DATA_MENUS_TIPO_USER = 'DATA_MENUS_TIPO_USER';
export const ADD_MENUS_TIPO_USER = 'ADD_MENU_TIPO_USER';
export const GET_MENUS_TIPO_USER = 'GET_MENUS_TIPO_USER';

export const LOAD_RECURSOS_TIPO_USER = 'LOAD_RECURSOS_TIPO_USER';
export const DATA_RECURSOS_TIPO_USER = 'DATA_RECURSOS_TIPO_USER';
export const ADD_RECURSOS_TIPO_USER = 'ADD_RECURSO_TIPO_USER';
export const GET_RECURSOS_TIPO_USER = 'GET_RECURSO_TIPO_USER';
