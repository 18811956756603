import React  from 'react';

const Administrador = () => {
  return (
    <>

    </>
  );
}

export default Administrador;
