import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Loading } from '~/components';
import { FormCad } from '../components';
import initialData from './initialData';
import schema from './schema';
import { Button } from 'react-bootstrap';
import { listCustomTipoUsers } from '~/store/modules/tipo_user/action';
import {
  loadConfigAutorizacoes,
  showConfigAutorizacao,
  updateConfigAutorizacao,
} from '~/store/modules/config_autorizacao/action';

const Edit = () => {
  let history = useHistory();
  const [configAutorizacaoEdit, setConfigAutorizacaoEdit] =
    useState(initialData);
  const [errorsValidate, setErrorsValidate] = useState([]);
  const [updated, setUpdated] = useState(false);
  const dispatch = useDispatch();
  const { entidade_id, config_autorizacao_id } = useParams();
  const { config_autorizacao, errors } = useSelector(
    ({ ConfigAutorizacaoReducer }) => ConfigAutorizacaoReducer
  );
  const { tipo_users } = useSelector(({ TipoUserReducer }) => TipoUserReducer);

  useEffect(() => {
    dispatch(
      showConfigAutorizacao(config_autorizacao_id, {
        with_trash: true,
      })
    );
  }, [dispatch, config_autorizacao_id]);

  useEffect(() => {
    dispatch(listCustomTipoUsers());
  }, [dispatch]);

  useEffect(() => {
    if (config_autorizacao.id === parseInt(config_autorizacao_id)) {
      setConfigAutorizacaoEdit(config_autorizacao);
    }
  }, [config_autorizacao, config_autorizacao_id]);

  useEffect(() => {
    if (errors.status && errors.status === 422) {
      let server_errors = {};
      const response_errors = errors.data.errors;
      for (let name of Object.keys(response_errors)) {
        Object.assign(server_errors, { [name]: response_errors[name][0] });
      }
      setErrorsValidate(server_errors);
    }
    if (errors.update) {
      setUpdated(true);
    }
  }, [errors]);

  useEffect(() => {
    if (updated) {
      Swal.fire('Sucesso', 'Registro atualizado com sucesso!', 'success').then(
        () => {
          history.goBack();
          dispatch(
            dispatch(
              loadConfigAutorizacoes({
                where: `entidade_id:${entidade_id}`,
                with_trash: true,
              })
            )
          );
        }
      );
    }
  }, [updated, history, dispatch, entidade_id]);

  const handleClose = () => {
    history.goBack('/tipo_licenca');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!schema.isValidSync(configAutorizacaoEdit)) {
      let yup_errors = {};
      schema
        .validate(configAutorizacaoEdit, { abortEarly: false })
        .catch((err) => {
          for (let error of err.inner) {
            const { path, message } = error;
            Object.assign(yup_errors, { [path]: message });
          }
          setErrorsValidate(yup_errors);
        });
    } else {
      const config = configAutorizacaoEdit;
      config.obrigatoria = parseInt(config.obrigatoria) ? true : false;
      await dispatch(updateConfigAutorizacao(config, config_autorizacao_id));
    }
  };
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-xl"
      onHide={handleClose}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Configurações de autorização
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Editar</h2>
          </div>
          <div className="card-body">
            {config_autorizacao.id === parseInt(config_autorizacao_id) ? (
              <FormCad
                config_autorizacao={configAutorizacaoEdit}
                errors={errorsValidate}
                handleSubmit={handleSubmit}
                schema={schema}
                setConfigAutorizacao={setConfigAutorizacaoEdit}
                tipo_users={tipo_users}
              />
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-default" onClick={handleClose}>
          <i className="fa fa-window-close" /> Cancelar
        </Button>
        <button
          className="btn btn-success"
          form="form_config_autorizacao"
          type="submint"
        >
          <i className="fa fa-save" /> Salvar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Edit;
