import * as actions from './actionType';

export const loadTipoMeioAnalises = (params) => ({
  type: actions.LOAD_TIPO_MEIO_ANALISES,
  params
});
export const getTipoMeioAnalises = tipo_meio_analises => ({
  type: actions.TIPO_MEIO_ANALISES_DATA,
  tipo_meio_analises,
});

export const getSelectTipoMeioAnalise = tipo_meio_analises => ({
  type: actions.SELECT_TIPO_MEIO_ANALISES,
  tipo_meio_analises,
});

export const storeTipoMeioAnalise = tipo_meio_analise => ({
  type: actions.STORE_TIPO_MEIO_ANALISE,
  tipo_meio_analise,

});
export const getTipoMeioAnalise = tipo_meio_analise => ({
  type: actions.GET_TIPO_MEIO_ANALISE,
  tipo_meio_analise,
});

export const removerTipoMeioAnalise = (id) => ({
  type: actions.REMOVER_TIPO_MEIO_ANALISE,
  id
});

export const showTipoMeioAnalise = (id, params) => ({
  type: actions.SHOW_TIPO_MEIO_ANALISE,
  id,
  params
});

export const updateTipoMeioAnalise = (tipo_meio_analise, id) => ({
  type: actions.UPDATE_TIPO_MEIO_ANALISE,
  tipo_meio_analise,
  id
});

export const getErros = errors => ({
  type: actions.TIPO_MEIO_ANALISE_ERROR,
  errors,
});

export const changeStatus = status => ({
  type: actions.CHANGE_STATUS_CODE,
  status,
});
