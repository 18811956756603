import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Form } from 'react-bootstrap';
import { FaArrowAltCircleLeft, FaCircleNotch, FaSave } from 'react-icons/fa';
import Swal from 'sweetalert2';
import {
  showEntidade,
  changeFinancialPassword,
  changeStatus
} from '~/store/modules/entidade/action';


const ResetFinancialPassword = ({ entidade }) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const { status_response } = useSelector(({ EntidadeReducer }) => EntidadeReducer);

  const handleClose = useCallback(() => {
    history.push(`/entidade/${entidade.id}/detalhe`);
  }, [history, entidade.id]);

  useEffect(() => () => {
    dispatch(changeStatus(null));
  }, [dispatch]);

  useEffect(() => {
    if (status_response === 200) {
      dispatch(showEntidade(entidade.id, {
        with:'estado,municipio'
      }));
      setLoading(false);
      Swal.fire(
        'Sucesso',
        'Senha da conta financeira atualizada!',
        'success'
      ).then(() => {
        handleClose();
      });
    }
  }, [dispatch, entidade.id, handleClose, status_response]);


  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const form = new FormData();
    form.append('_method', 'PUT');
    form.append('password', oldPassword);
    form.append('new_password', newPassword);
    form.append('new_password_confirmation', newPasswordConfirm);

    await dispatch(
      changeFinancialPassword(
        entidade.id, form
      )
    );
  }

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-md"
      onHide={handleClose}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Redefinir Senha da Conta Financeira
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="form_financial_password" onSubmit={handleSubmit}>
          <Row style={{ paddingLeft: '20%', paddingRight: '20%' }}>
            <Form.Group as={Col} md="12" style={{ margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '10px' }}>
              <Form.Label>Senha Atual</Form.Label>
              <Form.Control
                type="password"
                style={{ textAlign: 'center' }}
                onChange={(event) => setOldPassword(event.target.value)}
                readOnly={loading}
                required
              />
            </Form.Group>
            <Form.Group as={Col} md="12" style={{ margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '10px' }}>
              <Form.Label>Nova Senha</Form.Label>
              <Form.Control
                type="password"
                style={{ textAlign: 'center' }}
                onChange={(event) => setNewPassword(event.target.value)}
                readOnly={loading}
                required
              />
            </Form.Group>
            <Form.Group as={Col} md="12" style={{ margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Form.Label>Confirmação Nova Senha</Form.Label>
              <Form.Control
                type="password"
                style={{ textAlign: 'center' }}
                onChange={(event) => setNewPasswordConfirm(event.target.value)}
                readOnly={loading}
                required
              />
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between' }}>
        <button
          type="button"
          className="btn btn-default"
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={handleClose}
        >
          <FaArrowAltCircleLeft/>&nbsp;Voltar
        </button>
        <button
          form="form_financial_password"
          className="btn btn-success"
          style={{ display: 'flex', alignItems: 'center' }}
          disabled={loading}
        >
          {loading ? <FaCircleNotch className="spinner"/> : <FaSave/>}&nbsp;Salvar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ResetFinancialPassword;
