import * as actions from './actionType';

export const loadNotaTecAmbientals = params => ({
  type: actions.LOAD_NOTA_TEC_AMBIENTALS,
  params
});
export const getNotaTecAmbientals = nota_tec_ambientals => ({
  type: actions.NOTA_TEC_AMBIENTALS_DATA,
  nota_tec_ambientals,
});

export const getSelectNotaTecAmbiental = nota_tec_ambientals => ({
  type: actions.SELECT_NOTA_TEC_AMBIENTALS,
  nota_tec_ambientals,
});

export const storeNotaTecAmbiental = nota_tec_ambiental => ({
  type: actions.STORE_NOTA_TEC_AMBIENTAL,
  nota_tec_ambiental,

});
export const getNotaTecAmbiental = nota_tec_ambiental => ({
  type: actions.GET_NOTA_TEC_AMBIENTAL,
  nota_tec_ambiental,
});

export const removerNotaTecAmbiental = (id) => ({
  type: actions.REMOVER_NOTA_TEC_AMBIENTAL,
  id
});

export const showNotaTecAmbiental = processo_id => ({
  type: actions.SHOW_NOTA_TEC_AMBIENTAL,
  processo_id,
});

export const updateNotaTecAmbiental = (nota_tec_ambiental, id) => ({
  type: actions.UPDATE_NOTA_TEC_AMBIENTAL,
  nota_tec_ambiental,
  id
});



export const getErros = errors => ({
  type: actions.NOTA_TEC_AMBIENTAL_ERROR,
  errors,
});
