export const LOAD_ALTERNATIVA_QUESTAO_ANALISES = 'LOAD_ALTERNATIVA_QUESTAO_ANALISES';
export const SELECT_ALTERNATIVA_QUESTAO_ANALISES = 'SELECT_ALTERNATIVA_QUESTAO_ANALISES';
export const ALTERNATIVA_QUESTAO_ANALISE_ERROR = 'ALTERNATIVA_QUESTAO_ANALISE_ERROR';
export const ALTERNATIVA_QUESTAO_ANALISE_GET_ERRORS = 'ALTERNATIVA_QUESTAO_ANALISE_GET_ERRORS';
export const ALTERNATIVA_QUESTAO_ANALISES_DATA = 'ALTERNATIVA_QUESTAO_ANALISES_DATA';
export const STORE_ALTERNATIVA_QUESTAO_ANALISE = 'STORE_ALTERNATIVA_QUESTAO_ANALISE';
export const GET_ALTERNATIVA_QUESTAO_ANALISE = 'GET_ALTERNATIVA_QUESTAO_ANALISE';
export const REMOVER_ALTERNATIVA_QUESTAO_ANALISE = 'REMOVER_ALTERNATIVA_QUESTAO_ANALISE';
export const SHOW_ALTERNATIVA_QUESTAO_ANALISE = 'SHOW_ALTERNATIVA_QUESTAO_ANALISE';
export const UPDATE_ALTERNATIVA_QUESTAO_ANALISE = 'UPDATE_ALTERNATIVA_QUESTAO_ANALISE';
export const CHANGE_STATUS_CODE = 'CHANGE_STATUS_CODE';
export const ACTIVATE_ALTERNATIVA_QUESTAO_ANALISE = 'ACTIVATE_ALTERNATIVA_QUESTAO_ANALISE';
