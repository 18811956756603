import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { FiCheckCircle } from 'react-icons/fi';
import schema from './schemaValidations';
import FieldsPessoaFisica from './FieldsPessoaFisica';
import FieldsPessoaJuridica from './FieldsPessoaJuridica';
import FieldsTecnicoProfissional from './FieldsTecnicoProfissional';
import { initialData as pfInitialData } from '../../../CadPessoaFisica/components/FormCad/InitialData';
import { initialData as pjInitialData } from '../../../CadPessoaJuridica/components/FormCad/InitialData';
import { initialData as tpInitialData } from '../../../CadTecnicoProfissional/components/FormCad/InitialData';

const FormCad = ({
  onHandleSubmit,
  usuario, setUsuario,
  pessoa, setPessoa,
  errors, tipo_users, entidades
}) => {
  const [tipoUsuarioId, setTipoUsuarioId] = useState(usuario.tipo_user_id);
  const [tipoPessoaId, setTipoPessoaId] = useState(pessoa.tipo_pessoa_id);

  useEffect(() => {
    setTipoUsuarioId(parseInt(usuario.tipo_user_id));

    switch (parseInt(usuario.tipo_user_id)) {
      case 1: case 2: case 3: case 4: case 8:
        setTipoPessoaId(1);
        break;
      case 6:
        setTipoPessoaId(2);
        break;
      case 5: case 7:
        setTipoPessoaId(3);
        break;
      default:
        break;
    }
  }, [usuario.tipo_user_id]);

  useEffect(() => {
    if (pessoa.tipo_pessoa_id !== tipoPessoaId) {
      const pessoaFields = Object.assign(
        tpInitialData, pjInitialData, pfInitialData
      );
      setPessoa({
        ...pessoaFields,
        tipo_pessoa_id: tipoPessoaId
      });
    }
  }, [pessoa.tipo_pessoa_id, setPessoa, tipoPessoaId]);

  const handleChange = event => {
    const { name, value, files } = event.target;

    setUsuario({
      ...usuario,
      [name]: files ? files[0] : value
    });
  };

  return (
    <div className="card">
      <div className="card-body">
        <Form
          onSubmit={onHandleSubmit}
          schema={schema}
        >
          <Form.Group controlId="validation1">
            <Form.Label>Nome completo</Form.Label>
            <Form.Control
              isInvalid={!!errors.nome}
              name="nome"
              onChange={handleChange}
              placeholder="Nome completo"
              type="text"
              value={usuario.nome}
            />
            <Form.Control.Feedback type="invalid">
              {errors.nome}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>Tipo de usuário</Form.Label>
            <Form.Control
              as="select"
              isInvalid={!!errors.tipo_user_id}
              name="tipo_user_id"
              onChange={handleChange}
              placeholder="Tipo de usuário"
              type="text"
              value={usuario.tipo_user_id ? usuario.tipo_user_id : 1}
            >
              {tipo_users[0] &&
                tipo_users.map(tipo => (
                  <option
                    key={tipo.id}
                    value={tipo.id}
                  >
                    {tipo.nome}
                  </option>
                ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.tipo_user_id}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Entidade</Form.Label>
            <Form.Control
              as="select"
              isInvalid={!!errors.entidade_id}
              name="entidade_id"
              onChange={handleChange}
              placeholder="Entidade"
              type="text"
              value={usuario.entidade_id ? usuario.entidade_id : 1}
            >
              {entidades[0] &&
                entidades.map(entidade => (
                  <option
                    key={entidade.id}
                    value={entidade.id}
                  >
                    {entidade.nome}
                  </option>
                ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.entidade_id}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              isInvalid={!!errors.email}
              name="email"
              onChange={handleChange}
              placeholder="E-mail"
              type="email"
              value={usuario.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>Senha</Form.Label>
            <Form.Control
              isInvalid={!!errors.password}
              name="password"
              onChange={handleChange}
              placeholder="Senha"
              type="password"
            />
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>Confirmação Senha</Form.Label>
            <Form.Control
              isInvalid={!!errors.confirmacao_senha}
              name="confirmacao_senha"
              onChange={handleChange}
              placeholder="Confirmar Senha"
              type="password"
            />
            <Form.Control.Feedback type="invalid">
              {errors.confirmacao_senha}
            </Form.Control.Feedback>
          </Form.Group>

          {
            (
              tipoUsuarioId === 1 ||
              tipoUsuarioId === 2 ||
              tipoUsuarioId === 3 ||
              tipoUsuarioId === 4 ||
              tipoUsuarioId === 8
            ) ? (
                <FieldsPessoaFisica
                  errors={errors}
                  pessoa={pessoa}
                  setPessoa={setPessoa}
                />
              ) : ''
          }
          {
            tipoUsuarioId === 6 ? (
              <FieldsPessoaJuridica
                errors={errors}
                pessoa={pessoa}
                setPessoa={setPessoa}
              />
            ) : ''
          }
          {
            (
              tipoUsuarioId === 5 ||
              tipoUsuarioId === 7
            ) ? (
                <FieldsTecnicoProfissional
                  errors={errors}
                  setTecnico={setPessoa}
                  tecnico={pessoa}
                />
              ) : ''
          }

          <div className="d-flex justify-content-between">
            <Link
              className="btn btn-default"
              to="/cad_users"
            >
              <FaArrowCircleLeft /> Voltar
            </Link>
            <button
              className="btn btn-success"
              type="submit"
            >
              <FiCheckCircle /> Finalizar
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default FormCad;
