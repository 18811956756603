import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  .new-msg {
  animation: shake 1.02s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  animation-iteration-count: infinite;
}
body {
  padding-right: 0 !important;
}

.required-input {
  color: red;
}
`;
