import * as actions from './actionType';

export const ListaDocumentoReducer = (
  state = {
    select_lista_documentos: [],
    lista_documentos: { data: [] },
    status_response: null,
    lista_documento: {},
    errors: {}
  }, action
) => {
  switch (action.type) {
    case actions.LISTA_DOCUMENTOS_DATA:
      return {
        ...state,
        lista_documentos: action.lista_documentos.data,
        links: action.lista_documentos.data.links,
        meta: action.lista_documentos.data.meta,
      };
    case actions.SELECT_LISTA_DOCUMENTOS:
      return {
        ...state,
        select_lista_documentos: action.lista_documentos.data
      };
    case actions.GET_LISTA_DOCUMENTO:
      return {
        ...state,
        lista_documento: action.lista_documento.data.data,
        status_response: action.lista_documento.status,
      };
    case actions.LISTA_DOCUMENTO_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    case actions.CHANGE_STATUS_CODE:
      return {
        ...state,
        status_response: action.status,
      };
    default:
      return state;
  }
}
