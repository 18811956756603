import styled from 'styled-components';

export const BoxCadPFisica = styled.div`
  flex: 1;
  flex-direction: column;
  max-width: 900px;
  margin: auto;
  align-items: center;
  text-align: justify;
  margin-top: 20px;
  padding: 20px;
  > h3 {
    margin-top: 20px;
  }
  > form {
    width: 90%;
  }
  span {
    color: #ff0000;
  }
`;

export const BoxDeclaraRespo = styled.p`
  overflow: auto;
  min-height: 100px !important;
  text-align: justify;
`;

export const BoxTermos = styled.div`
  display: flex;
`;
