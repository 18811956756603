import * as actions from './actionType';

export const MensagemReducer = (state = {select_mensagems: [], mensagems: [], mensagem: {}, mensagens_n_lidas: [],  errors: {}}, action) => {
  switch (action.type) {
    case actions.MENSAGEMS_DATA:
      return {
        ...state,
        mensagems: action.mensagems.data,
        links: action.mensagems.data.links,
        meta: action.mensagems.data.meta,
      };
    case actions.SELECT_MENSAGEMS:
      return {
        ...state,
        select_mensagems: action.mensagems.data
      };
    case actions.GET_MENSAGENS_N_LIDAS:
      return {
        ...state,
        mensagens_n_lidas: action.mensagems.data
      };
    case actions.GET_MENSAGEM:
      return {
        ...state,
        mensagem: action.mensagem.data.data,
        status_response: action.mensagem.status,
      };
    case actions.MENSAGEM_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
