import { apiWithToken } from '../api';

const resouceUrl = '/mensagem';
const token = localStorage.getItem('@token');
let api = apiWithToken(token);

export const load = async (processo_id, params) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let page = urlParams.has('page') ? { page: urlParams.get('page') } : {};
  let search = urlParams.has('search')
    ? { search: urlParams.get('search') }
    : {};
  return await api.get(`${resouceUrl}/${processo_id}`, {
    params: {
      ...params,
      ...page,
      ...search,
    },
  });
}

export const store = async (processo_id, mensagem) => {
  let response = await api.post(`${resouceUrl}/${processo_id}` , mensagem);
  return response;
}

export const responder = async (mensagem_id, resposta) => {
  let response = await api.post(`${resouceUrl}/${mensagem_id}/responder`,resposta);
  return response;
}


export const marcar_como_lido = async (mensagem_id) => {
  let response = await api.put(`${resouceUrl}/${mensagem_id}/marcar_lida`);
  return response;
}


export const get_nao_lidas = async () => {
  let response = await api.get(`${resouceUrl}/usuario/novas_mensagens`);
  return response;
}
