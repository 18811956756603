import * as actions from './actionType';


export const loadUsers = (params) => ({
  type: actions.LOAD_USERS,
  params
});

export const loadUser = (id_user) => ({
  type: actions.LOAD_USER,
  id_user
});

export const loadUserPessoa = id_user => ({
  type: actions.LOAD_USER_PESSOA,
  id_user
});

export const showUser = (params) => ({
  type: actions.SHOW_USER,
  params
});

export const storeUser = usuario => ({
  type: actions.STORE_USER,
  usuario
});

export const updateUser = (id,usuario) => ({
  type: actions.UPDATE_USER,
  payload:{
    id:id,
    usuario:usuario
  }
});

export const getUsers = users => ({
  type: actions.GET_USERS,
  users,
});

export const getUser = user => ({
  type: actions.GET_USER,
  user,
});

export const getUserPessoa = user_pessoa => ({
  type: actions.GET_USER_PESSOA,
  user_pessoa,
});

export const changeStatus = status => ({
  type: actions.CHANGE_STATUS_CODE,
  status,
});

export const loadListaAnalistasEntidades = (params) => ({
  type: actions.LOAD_USERS_ANALISTA_ENTIDADE,
  params
});

export const getAnalistasEntidades = user_analistas_entidades => ({
  type: actions.USERS_ANALISTAS_ENTIDADE_DATA,
  user_analistas_entidades,
});


export const getErros = errors => ({
  type: actions.USER_ERROR,
  errors,
});

export const changePassword = user => ({
  type: actions.CHANGE_PASSWORD,
  user,
});

export const mutatePassword = user => ({
  type: actions.MUTATE_PASSWORD,
  user,
});

export const filterUsers = (params) => ({
  type: actions.FILTER_USERS,
  params
});

export const removeUser = (user, params) => ({
    type: actions.REMOVE_USER,
    user, params
});

export const restoreUser = (user, params) => ({
    type: actions.RESTORE_USER,
    user, params
});
