import * as actions from './actionType';

export const loadObservacoes = (params) => ({
  type: actions.LOAD_OBSERVACOES,
  params
});
export const getObservacoes = observacoes => ({
  type: actions.OBSERVACOES_DATA,
  observacoes,
});

export const loadSelectObservacoes = observacoes => ({
  type: actions.LOAD_SELECT_OBSERVACOES,
  observacoes,
});

export const getSelectObservacoes = observacoes => ({
  type: actions.SELECT_OBSERVACOES,
  observacoes,
});

export const storeObservacao = observacao => ({
  type: actions.STORE_OBSERVACAO,
  observacao,

});
export const getObservacao = observacao => ({
  type: actions.GET_OBSERVACAO,
  observacao,
});

export const removerObservacao = (id) => ({
  type: actions.REMOVER_OBSERVACAO,
  id
});

export const showObservacao = id => ({
  type: actions.SHOW_OBSERVACAO,
  id,
});

export const updateObservacao = (observacao, id) => ({
  type: actions.UPDATE_OBSERVACAO,
  observacao,
  id
});

export const getErros = errors => ({
  type: actions.OBSERVACAO_ERROR,
  errors,
});
