import * as actions from './actionType';

export const loadTecnicoProfissionais = (params) => ({
  type: actions.LOAD_TECNICO_PROFISSIONAIS,
  params
});


export const loadMinhasApias = (params) => ({
  type: actions.LOAD_MINHAS_APIAS,
  params
});

export const getMinhasApias = (processos) => ({
  type: actions.MINHAS_APIAS_DATA,
  processos,
});

export const getTecnicoProfissionais = tecnico_profissionais => ({
  type: actions.TECNICO_PROFISSIONAIS_DATA,
  tecnico_profissionais,
});

export const getSelectTecnicoProfissional = tecnico_profissionais => ({
  type: actions.SELECT_TECNICO_PROFISSIONAIS,
  tecnico_profissionais,
});

export const storeTecnicoProfissional = tecnico_profissional => ({
  type: actions.STORE_TECNICO_PROFISSIONAL,
  tecnico_profissional,

});
export const getTecnicoProfissional = tecnico_profissional => ({
  type: actions.GET_TECNICO_PROFISSIONAL,
  tecnico_profissional,
});

export const removerTecnicoProfissional = (id) => ({
  type: actions.REMOVER_TECNICO_PROFISSIONAL,
  id
});

export const showTecnicoProfissional = id => ({
  type: actions.SHOW_TECNICO_PROFISSIONAL,
  id,
});

export const updateTecnicoProfissional = (tecnico_profissional, id) => ({
  type: actions.UPDATE_TECNICO_PROFISSIONAL,
  tecnico_profissional,
  id
});

export const getErros = errors => ({
  type: actions.TECNICO_PROFISSIONAL_ERROR,
  errors,
});
