import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as Arquivo from '~/services/Arquivo';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadArquivoWorker({path, params}) {
  try {
    const response = yield call(Arquivo.loadArquivo, path, params);
    yield put(actions.getArquivo(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherPotencialPoluidor() {
  yield takeLatest(actionType.LOAD_ARQUIVO, loadArquivoWorker);
}

export default function* saga() {
  yield all([watcherPotencialPoluidor()]);
}
