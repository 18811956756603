import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const RouteLayout = props => {
  const { layout: Layout, component: Component, ...rest } = props;
  const isAuth = localStorage.getItem('@token') ? true : false;
  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          {isAuth ? (
            <Component {...matchProps} />
          )
            :
            <Redirect to="/login" />
          }
        </Layout>
      )}
    />
  )
}

RouteLayout.prototype = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
}

export default RouteLayout;
