import styled, { keyframes } from 'styled-components';

const FadeAnimation = keyframes`
  from {
    right: -100%;
  }
  to {
    right: -5px;
  }
`;
export const Container = styled.div`
  right: -5px;
  height: 80px;
  position: fixed;
  top: 20px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${FadeAnimation} 1s ease-in-out;
`;

