import * as actions from './actionType';

export const ConfigAutorizacaoReducer = (state = {select_config_autorizacoes: [], config_autorizacoes: [], status_response:null, config_autorizacao: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.CONFIG_AUTORIZACOES_DATA:
      return {
        ...state,
        config_autorizacoes: action.config_autorizacoes.data,
        links: action.config_autorizacoes.data.links,
        meta: action.config_autorizacoes.data.meta,
      };
    case actions.SELECT_CONFIG_AUTORIZACOES:
      return {
        ...state,
        select_config_autorizacoes: action.config_autorizacoes.data
      };
    case actions.GET_CONFIG_AUTORIZACAO:
      return {
        ...state,
        config_autorizacao: action.config_autorizacao.data.data,
        status_response: action.config_autorizacao.status,
      };
    case actions.CONFIG_AUTORIZACAO_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    case actions.CHANGE_STATUS_CODE:
      return {
        ...state,
        status_response: action.status,
      };
    default:
      return state;
  }
}
