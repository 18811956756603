export const LOAD_MENSAGEMS = 'LOAD_MENSAGEMS';
export const SELECT_MENSAGEMS = 'SELECT_MENSAGEMS';
export const MENSAGEM_ERROR = 'MENSAGEM_ERROR';
export const MENSAGEM_GET_ERRORS = 'MENSAGEM_GET_ERRORS';
export const MENSAGEMS_DATA = 'MENSAGEMS_DATA';
export const STORE_MENSAGEM = 'STORE_MENSAGEM';
export const RESPONDER_MENSAGEM = 'RESPONDER_MENSAGEM';
export const MARACAR_COMO_LIDA = 'MARACAR_COMO_LIDA';
export const GET_MENSAGEM = 'GET_MENSAGEM';
export const GET_MENSAGENS_N_LIDAS = 'GET_MENSAGENS_N_LIDAS';
export const MENSAGENS_N_LIDAS_REQUEST = 'MENSAGENS_N_LIDAS_REQUEST';
export const REMOVER_MENSAGEM = 'REMOVER_MENSAGEM';
export const SHOW_MENSAGEM = 'SHOW_MENSAGEM';
export const UPDATE_MENSAGEM = 'UPDATE_MENSAGEM';
export const FINALIZAR_MENSAGEM = 'FINALIZAR_MENSAGEM';
