import * as actions from './actionType';

export const AnaliseReducer = (state = {select_analises: [], analises: [], analise: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.ANALISES_DATA:
      return {
        ...state,
        analises: action.analises.data,
        links: action.analises.data.links,
        meta: action.analises.data.meta,
      };
    case actions.SELECT_ANALISES:
      return {
        ...state,
        select_analises: action.analises.data
      };
    case actions.GET_ANALISE:
      return {
        ...state,
        analise: action.analise.data.data,
        status_response: action.analise.status,
      };
    case actions.ANALISE_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
