import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as QuestaoEstudoAmbiental from '~/services/QuestaoEstudoAmbiental';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadQuestaoEstudoAmbientalsWorker({params}) {
  try {
    const response = yield call(QuestaoEstudoAmbiental.load, params);
    yield put(actions.getQuestaoEstudoAmbientals(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* showQuestaoEstudoAmbientalWorker({id}) {
  try {
    const response = yield call(QuestaoEstudoAmbiental.show, id);
    yield put(actions.getQuestaoEstudoAmbiental(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updateQuestaoEstudoAmbientalWorker({ questao_estudo_ambiental, id}) {
  try {
    const response = yield call(QuestaoEstudoAmbiental.update, id, questao_estudo_ambiental);
    yield put(actions.getQuestaoEstudoAmbiental(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeQuestaoEstudoAmbientalWorker({questao_estudo_ambiental}) {
  try {
    const response = yield call(QuestaoEstudoAmbiental.store, questao_estudo_ambiental);
    yield put(actions.getQuestaoEstudoAmbiental(response));
    yield put(actions.getErros({save:true}));
    yield put(getErros({save:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* removerQuestaoEstudoAmbientalWorker({id}) {
  try {
    yield call(QuestaoEstudoAmbiental.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherQuestaoEstudoAmbiental() {
  yield takeLatest(actionType.LOAD_QUESTAO_ESTUDO_AMBIENTALS, loadQuestaoEstudoAmbientalsWorker);
  yield takeLatest(actionType.STORE_QUESTAO_ESTUDO_AMBIENTAL, storeQuestaoEstudoAmbientalWorker);
  yield takeLatest(actionType.SHOW_QUESTAO_ESTUDO_AMBIENTAL, showQuestaoEstudoAmbientalWorker);
  yield takeLatest(actionType.UPDATE_QUESTAO_ESTUDO_AMBIENTAL, updateQuestaoEstudoAmbientalWorker);
  yield takeLatest(actionType.REMOVER_QUESTAO_ESTUDO_AMBIENTAL, removerQuestaoEstudoAmbientalWorker);
}

export default function* saga() {
  yield all([watcherQuestaoEstudoAmbiental()]);
}
