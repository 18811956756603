import { all, put, takeEvery, call } from 'redux-saga/effects';
import  * as actionType from './actionType';
import * as actions from './action';
import * as IndicadorAmbiental from '~/services/IndicadorAmbiental';
import { getErros } from '~/store/modules/errors/action';

function* loadIndicadorAmbientalWorker({params}) {
  try {
    const response = yield call(IndicadorAmbiental.list_indicador_ambiental, params);
    // console.log('IndicadorAmbiental loadIndicadorAmbientalWorker: ',response);
    yield put(actions.changeStatus(response.status));
    yield put(actions.getIndicadoresAmbientais(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response.data));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeIndicadorAmbientalWorker({indicador_ambiental}) {
  try {
    const response = yield call(IndicadorAmbiental.store_indicador_ambiental, indicador_ambiental);
    // console.log('storeIndicadorAmbientalWorker response: ',response);
    yield put(actions.changeStatus(response.status));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      // console.log('storeIndicadorAmbientalWorker error: ',error.response);
      yield put(actions.getErros(error.response.data));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updateIndicadorAmbientalWorker({ indicador_ambiental, id}) {
  try {
    const response = yield call(IndicadorAmbiental.update_indicador_ambiental, id, indicador_ambiental);
    yield put(actions.getIndicadoresAmbientais(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* showIndicadorAmbientalWorker({payload}) {
  try {
    const response = yield call(IndicadorAmbiental.show_indicador_ambiental, payload);
    // console.log('showIndicadorAmbientalWorker response: ',response);
    yield put(actions.getIndicadorAmbiental(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* removerIndicadorAmbientalWorker({id}) {
  try {
    yield call(IndicadorAmbiental.destroy_indicador_ambiental, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* ativarIndicadorAmbientalWorker({id}) {
  try {
    yield call(IndicadorAmbiental.activate_indicador_ambiental, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherIndicadorAmbiental() {
  yield takeEvery(actionType.LOAD_INDICADOR_AMBIENTAL, loadIndicadorAmbientalWorker);
  yield takeEvery(actionType.STORE_INDICADOR_AMBIENTAL, storeIndicadorAmbientalWorker);
  yield takeEvery(actionType.SHOW_INDICADOR_AMBIENTAL, showIndicadorAmbientalWorker);
  yield takeEvery(actionType.UPDATE_INDICADOR_AMBIENTAL, updateIndicadorAmbientalWorker);
  yield takeEvery(actionType.DESATIVAR_INDICADOR_AMBIENTAL, removerIndicadorAmbientalWorker);
  yield takeEvery(actionType.ACTIVATE_INDICADOR_AMBIENTAL, ativarIndicadorAmbientalWorker);
}


export default function* saga() {
  yield all([watcherIndicadorAmbiental()]);
}
