import * as actions from './actionType';

export const RespostaQuestaoMemorialReducer = (state = {select_resposta_questao_memorials: [], resposta_questao_memorials: [], resposta_questao_memorial: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.RESPOSTA_QUESTAO_MEMORIALS_DATA:
      return {
        ...state,
        resposta_questao_memorials: action.resposta_questao_memorials.data,
        links: action.resposta_questao_memorials.data.links,
        meta: action.resposta_questao_memorials.data.meta,
      };
    case actions.SELECT_RESPOSTA_QUESTAO_MEMORIALS:
      return {
        ...state,
        select_resposta_questao_memorials: action.resposta_questao_memorials.data
      };
    case actions.GET_RESPOSTA_QUESTAO_MEMORIAL:
      return {
        ...state,
        resposta_questao_memorial: action.resposta_questao_memorial.data.data,
        status_response: action.resposta_questao_memorial.status,
      };
    case actions.RESPOSTA_QUESTAO_MEMORIAL_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
