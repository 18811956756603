import * as actions from './actionType';

export const loadResponsavelTecnicos = (params) => ({
  type: actions.LOAD_RESPONSAVEL_TECNICOS,
  params
});

export const getResponsavelTecnicos = responsavel_tecnicos => ({
  type: actions.RESPONSAVEL_TECNICOS_DATA,
  responsavel_tecnicos,
});


export const loadListForAdd = (params) => ({
  type: actions.LOAD_LIST_FOR_ADD,
  params
});


export const getListForAdd = (tecnicos) => ({
  type: actions.GET_LIST_FOR_ADD,
  tecnicos,
});


export const getSelectResponsavelTecnico = responsavel_tecnicos => ({
  type: actions.SELECT_RESPONSAVEL_TECNICOS,
  responsavel_tecnicos,
});

export const storeResponsavelTecnico = responsavel_tecnico => ({
  type: actions.STORE_RESPONSAVEL_TECNICO,
  responsavel_tecnico,

});
export const getResponsavelTecnico = responsavel_tecnico => ({
  type: actions.GET_RESPONSAVEL_TECNICO,
  responsavel_tecnico,
});

export const removerResponsavelTecnico = (id) => ({
  type: actions.REMOVER_RESPONSAVEL_TECNICO,
  id
});

export const showResponsavelTecnico = id => ({
  type: actions.SHOW_RESPONSAVEL_TECNICO,
  id,
});

export const updateResponsavelTecnico = (responsavel_tecnico, id) => ({
  type: actions.UPDATE_RESPONSAVEL_TECNICO,
  responsavel_tecnico,
  id
});

export const getErros = errors => ({
  type: actions.RESPONSAVEL_TECNICO_ERROR,
  errors,
});
