import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { FaList } from 'react-icons/fa';
import SearchForm from '~/components/SearchForm';
import { BasicContent, BtnEnviarAnalista } from '../components';
import { Loading, Paginate } from '~/components';
import { loadDistribuicao } from '~/store/modules/processo/action';

export default function List() {
  const breadcrumb = {
    label: 'Lista',
    to: '/distribuicao',
    active: true,
  };

  const dispatch = useDispatch();
  const { processos, meta } = useSelector(
    ({ ProcessoReducer }) => ProcessoReducer
  );
  const [params, setParams] = useState({
    with: 'tipo_licenca,atividade,tramites;tipo:distribuicao,user_avaliador,status_processo',
    order_by: 'created_at:desc'
  });

  useEffect(() => {
    dispatch(loadDistribuicao(params));
  }, [dispatch, params]);

  const formatDataHora = (data_hora) => {
    let data_hora_raw = data_hora.split(' ');
    let data_format = data_hora_raw[0].split('-');
    let hora = data_hora_raw[1];
    let data = `${data_format[2]}/${data_format[1]}/${data_format[0]}`;
    return `${data} ${hora}`;
  };

  return (
    <BasicContent breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">
            <FaList />
            &nbsp;PROCESSOS
          </h3>
          <SearchForm
            initialParams={params}
            load={loadDistribuicao}
            options={[
              { value: 'protocolo', label: 'Protocolo' },
              { value: 'pessoa;cpf', label: 'CPF Requerente' },
              { value: 'pessoa;cnpj', label: 'CNPJ Requerente' },
            ]}
            relationships="tipo_licenca,atividade,tramites;tipo:distribuicao,user_avaliador,status_processo"
            setInitialParams={setParams}
          />
        </div>
        <div className="card-body table-responsive">
          <Table bordered hover size="sm" striped>
            <thead>
              <tr>
                <th> Número Processo </th>
                <th> Atividade/Licença </th>
                <th> Data Hora Entrada </th>
                <th> Encaminhado para </th>

                <th> Ações </th>
              </tr>
            </thead>
            <tbody>
              {processos.data &&
                processos.data.map(
                  (
                    {
                      id,
                      protocolo,
                      atividade,
                      tipo_licenca,
                      tramites,
                      user_avaliador_id,
                      user_avaliador,
                      status_processo_id,
                    },
                    index
                  ) => (
                    <tr key={index}>
                      <td>{protocolo}</td>
                      <td>
                        {atividade && atividade.nome} /{' '}
                        {tipo_licenca && tipo_licenca.nome}
                      </td>
                      <td>
                        {tramites &&
                          tramites[0] &&
                          formatDataHora(tramites[0].created_at)}
                      </td>
                      <td>
                        {user_avaliador_id
                          ? user_avaliador?.nome
                          : 'Ainda não vinculado'}
                      </td>

                      <td>
                        <div className="btn-group">
                          <Link
                            className="btn btn-info"
                            to={`/distribuicao/${id}/detalhe`}
                          >
                            Detalhes
                          </Link>
                          {!user_avaliador_id && status_processo_id !== 7 && (
                            <BtnEnviarAnalista
                              processo_id={id}
                              redistribuicao={false}
                            />
                          )}
                          {user_avaliador_id && status_processo_id !== 7 && (
                            <BtnEnviarAnalista
                              processo_id={id}
                              redistribuicao
                            />
                          )}

                          {/* <Link
                            className="btn btn-info"
                            to="/distribuicao/show"
                          >
                            <FaEye /> Ver status
                          </Link>
                          <Link
                            className="btn btn-primary"
                            to="/distribuicao/projeto"
                          >
                            <FaProjectDiagram /> ParecerProjeto
                          </Link> */}
                        </div>
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </Table>
          {!processos.data && <Loading />}
        </div>
        <div className="card-footer">
          <Paginate load={() => loadDistribuicao(params)} meta={meta} />
        </div>
      </div>
    </BasicContent>
  );
}
