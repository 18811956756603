import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import styles from './styles';
import { Header, Footer, Table, Row, Col } from '~/components/PDF';
import { toBRL } from '~/utils/formatCurrency';

export default function RAFAPDF({
    period,
    entityName,
    organName,
    logoSiblam,
    logoEntity,
    processes,
    LogoCounty,
}) {
    return (
        <Document title="Relatório de Arrecadação Financeira Ambiental">
            <Page size="A4" style={styles.page} wrap>
                <Header
                    logoEntity={logoEntity}
                    entityName={entityName}
                    organName={organName}
                    LogoCounty={LogoCounty}
                    styles={styles}
                />

                <View style={{ marginTop: '-10px' }}>
                    <Text style={[styles.title, { textAlign: 'center' }]}>
                        Relatório de Arrecadação Financeira Ambiental
                    </Text>
                </View>

                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <View
                        style={[
                            styles.section,
                            { alignItems: 'flex-end', flexGrow: 1 },
                        ]}
                    >
                        <View
                            style={{
                                borderTop: '1px solid black',
                                borderLeft: '1px solid black',
                                flexDirection: 'row',
                            }}
                        >
                            <Text style={[styles.cell, styles.semibold]}>
                                Período
                            </Text>
                            <Text style={[styles.cell, styles.th]}>
                                {period}
                            </Text>
                        </View>
                    </View>
                </View>

                <View style={styles.section}>
                    <Table>
                        <Row header>
                            <Col
                                style={[
                                    styles.semibold,
                                    { width: '4%', fontSize: '10px' },
                                ]}
                            >
                                N°
                            </Col>
                            <Col
                                style={[
                                    styles.semibold,
                                    { width: '18%', fontSize: '10px' },
                                ]}
                            >
                                Cód. Receita
                            </Col>
                            <Col
                                style={[
                                    styles.semibold,
                                    { width: '32%', fontSize: '10px' },
                                ]}
                            >
                                Descrição
                            </Col>
                            <Col
                                style={[
                                    styles.semibold,
                                    { width: '36%', fontSize: '10px' },
                                ]}
                            >
                                N° Processo
                            </Col>
                            <Col
                                style={[
                                    styles.semibold,
                                    { width: '10%', fontSize: '10px' },
                                ]}
                            >
                                Valor
                            </Col>
                        </Row>
                        {!processes.length && (
                            <Row>
                                <Col>Sem dados para o período</Col>
                            </Row>
                        )}

                        {processes.map(({ protocolo, valor_total }, index) => (
                            <Row key={`table-one-key-${index + 1}`}>
                                <Col style={{ width: '4%', fontSize: '10px' }}>
                                    {(index + 1).toString().padStart(2, '0')}
                                </Col>
                                <Col style={{ width: '18%', fontSize: '10px' }}>
                                    1121.04.1.1.00.02
                                </Col>
                                <Col style={{ width: '32%', fontSize: '10px' }}>
                                    Taxa de Licenciamento Ambiental
                                </Col>
                                <Col style={{ width: '36%', fontSize: '10px' }}>
                                    {protocolo
                                        .match(/.{1,30}/g)
                                        .map((part, i) => (
                                            <Text key={i}>
                                                {part}
                                                {i <
                                                protocolo.match(/.{1,30}/g)
                                                    .length -
                                                    1
                                                    ? '\n'
                                                    : ''}
                                            </Text>
                                        ))}
                                </Col>
                                <Col style={{ width: '10%', fontSize: '10px' }}>
                                    {toBRL(valor_total)}
                                </Col>
                            </Row>
                        ))}
                    </Table>
                </View>

                <Footer logoSiblam={logoSiblam} styles={styles} />
            </Page>
        </Document>
    );
}
