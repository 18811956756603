import * as actions from './actionType';

export const IndicadorAmbientalReducer = (state = {indicadores_ambientais: [], indicador_ambiental: {},status_response: null, errors:{}}, action,) => {
  switch (action.type) {
    case actions.INDICADOR_AMBIENTAL_DATA:
      return {
        ...state,
        indicadores_ambientais: action.indicadores_ambientais.data,
      };
    case actions.GET_INDICADOR_AMBIENTAL:
      return {
        ...state,
        indicador_ambiental: action.indicador_ambiental.data.data,
      };
    case actions.INDICADOR_AMBIENTAL_ERROR:
      return {
        ...state,
        errors: action.errors
      };
    case actions.CHANGE_STATUS_CODE:
      return {
        ...state,
        status_response: action.status,
      };
    default:
      return state;
  }
}
