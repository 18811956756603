import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as ValorReferencia from '~/services/ValorReferencia';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadValoresReferenciasWorker({params}) {
  try {
    const response = yield call(ValorReferencia.load, params);
    yield put(actions.getValoresReferencias(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* loadSelectValoresReferenciasWorker() {
  try {
    const response = yield call(ValorReferencia.select);
    yield put(actions.getSelectValorReferencias(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* showValorReferenciaWorker({id}) {
  try {
    const response = yield call(ValorReferencia.show, id);
    yield put(actions.getValorReferencia(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* getCustomValorReferenciaWorker({ tipo_licenca_id }) {
  try {
    const response = yield call(ValorReferencia.custom_valor_referencia, tipo_licenca_id);
    yield put(actions.getValorReferencia(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updateValorReferenciaWorker({ valor_referencia, id}) {
  try {
    const response = yield call(ValorReferencia.update, id, valor_referencia);
    yield put(actions.getValorReferencia(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeValorReferenciaWorker({ valor_referencia }) {
  try {
    const response = yield call(ValorReferencia.store, valor_referencia);
    yield put(actions.getValorReferencia(response));
    yield put(actions.getErros({save:true}));
    yield put(getErros({save:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* removerValorReferenciaWorker({id}) {
  try {
    yield call(ValorReferencia.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* ativarValorReferenciaWorker({id}) {
  try {
    yield call(ValorReferencia.activate, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherVeiculo() {
  yield takeLatest(actionType.LOAD_VALORES_REFERENCIAS, loadValoresReferenciasWorker);
  yield takeLatest(actionType.LOAD_SELECT_VALORES_REFERENCIAS, loadSelectValoresReferenciasWorker);
  yield takeLatest(actionType.STORE_VALOR_REFERENCIA, storeValorReferenciaWorker);
  yield takeLatest(actionType.SHOW_VALOR_REFERENCIA, showValorReferenciaWorker);
  yield takeLatest(actionType.UPDATE_VALOR_REFERENCIA, updateValorReferenciaWorker);
  yield takeLatest(actionType.REMOVER_VALOR_REFERENCIA, removerValorReferenciaWorker);
  yield takeLatest(actionType.ACTIVE_VALOR_REFERENCIA, ativarValorReferenciaWorker);
  yield takeLatest(actionType.GET_CUSTOM_VALOR_REFERENCIA, getCustomValorReferenciaWorker);
}

export default function* saga() {
  yield all([watcherVeiculo()]);
}
