import React, { useEffect, useState, Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Loading } from '~/components';
import { FormResposta } from '../';
import { loadQuestaoAnalises } from '~/store/modules/questao_analise/action';
import { removerRespostaQuestao } from '~/store/modules/resposta_questao/action';
import { FormAll } from '../FormResposta';

const ModalAnalise = ({ tipo_meio_analise, show, handleHide, analise_id, analise_previa}) => {
  const [tipoMeioAnalise, setTipoMeioAnalise] = useState();
  const [questaoAnalises, setQuestaoAnalises] = useState();
  const [paramsLoad, setParamsLoad] = useState({});
  const [totalValorAnalise, setTotalValorAnalise] = useState(0);
  const dispatch = useDispatch();

  const { questao_analises } = useSelector(
    ({ QuestaoAnaliseReducer }) => QuestaoAnaliseReducer
  );

  const { analise } = useSelector(({ AnaliseReducer }) => AnaliseReducer);


  useEffect(() => {
    if (tipo_meio_analise && tipo_meio_analise.id) {
      setTipoMeioAnalise(tipo_meio_analise);
      if (analise_previa) {
        setParamsLoad({
          where: `tipo_meio_analise_id:${tipo_meio_analise.id}`,
          raw_list: true,
          with: `alternativa_questao_analises,resposta_questao_analise_previas;analise_processo_id:${analise_id}`,
        });

        dispatch(
          loadQuestaoAnalises({
            where: `tipo_meio_analise_id:${tipo_meio_analise.id}`,
            raw_list: true,
            with: `alternativa_questao_analises,resposta_questao_analise_previas;analise_processo_id:${analise_id}`,
          })
        );
      }else {
        setParamsLoad({
          where: `tipo_meio_analise_id:${tipo_meio_analise.id}`,
          raw_list: true,
          with: `alternativa_questao_analises,resposta_questao_analise;analise_processo_id:${analise_id}`,
        });

        dispatch(
          loadQuestaoAnalises({
            where: `tipo_meio_analise_id:${tipo_meio_analise.id}`,
            raw_list: true,
            with: `alternativa_questao_analises,resposta_questao_analise;analise_processo_id:${analise_id}`,
          })
        );
      }

    }
  }, [tipo_meio_analise, analise_id, analise_previa, dispatch]);


  useEffect(() => {
    if (questao_analises.data) {
      setQuestaoAnalises(questao_analises.data);
    }
  }, [questao_analises]);

  useEffect(() => {
    if (questaoAnalises && questaoAnalises[0]) {
      let total = 0;
      if (analise_previa) {
        for (let { resposta_questao_analise_previas } of questaoAnalises) {
          if (resposta_questao_analise_previas && resposta_questao_analise_previas[0]) {
            total += parseFloat(resposta_questao_analise_previas[0].valor);
          }
        }
      } else {
        for (let { resposta_questao_analise } of questaoAnalises) {
          if (resposta_questao_analise && resposta_questao_analise[0]) {
            total += parseFloat(resposta_questao_analise[0].valor);
          }
        }
      }
      setTotalValorAnalise(total);
    }
  }, [questaoAnalises, analise_previa]);

  const toReloadQuestaoAnalises = useCallback(async(paramsload) => {
    await dispatch(loadQuestaoAnalises(paramsload));
  },[dispatch]);

  const handleRemove = async (id) => {
    await dispatch(removerRespostaQuestao(id));
    if (paramsLoad && paramsLoad.with) {
      await dispatch(
        loadQuestaoAnalises(paramsLoad)
      );
      await toReloadQuestaoAnalises(paramsLoad);
    }
  };

  return (
    <>
      <Modal
        animation
        aria-labelledby="example-custom-modal-styling-title"
        centered
        dialogClassName="modal-xl"
        onHide={handleHide}
        show={show}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Impacto {tipoMeioAnalise && tipoMeioAnalise.nome}{' '}
            {!tipoMeioAnalise && <Loading />}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {tipoMeioAnalise && tipoMeioAnalise.descricao}{' '}
          {!tipoMeioAnalise && <Loading />}
          {questaoAnalises && (
            <>
              <FormAll
                analise_id={analise_id}
                questions={questaoAnalises}
                questionsLoadParams={paramsLoad}
              />
              <table className="table table-sm table-striped table-hover table-bordered">
                <tbody>
                  {questaoAnalises.map(
                    (
                      {
                        id,
                        nome,
                        alternativa_questao_analises,
                        resposta_questao_analise_previas,
                        resposta_questao_analise
                      },
                      index
                    ) => (
                      <Fragment key={index}>
                        <tr>
                          <th
                            className="align-text-middle"
                            rowSpan={alternativa_questao_analises.length+1}
                          >
                            {' '}
                            <br /> <br /> <br /> <br />
                            {++index}
                          </th>
                          <th colSpan="2">{nome}</th>
                          <td
                            rowSpan={alternativa_questao_analises.length+1}
                            width="15%"
                            style={{
                              height: 1,
                              minHeight: 1
                            }}
                          >
                            <div style={{
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              flexGrow: 1,
                              padding: 10
                            }}>
                            {analise_previa ?
                              <>
                                {resposta_questao_analise_previas &&
                                resposta_questao_analise_previas[0] ? (
                                    <>
                                      <button className="btn btn-default btn-lg" type="button">
                                        <strong>
                                            {resposta_questao_analise_previas[0].valor}
                                        </strong>
                                      </button>
                                      <br />
                                      {!analise.concluida && (<button
                                        className="btn btn-danger"
                                        onClick={(e) =>
                                          handleRemove(
                                            resposta_questao_analise_previas[0].id, e.target
                                          )
                                        }
                                      >
                                        {' '}
                                        <i className="fa fa-trash" /> Remover
                                      </button>)}

                                    </>
                                  ) : (
                                    <>
                                      {tipoMeioAnalise && tipoMeioAnalise.id && (
                                        <FormResposta
                                          analise_id={analise_id}
                                          params_load={paramsLoad}
                                          questao_id={id}
                                        />
                                      )}
                                    </>
                                  )}
                              </> :
                              <>
                                {resposta_questao_analise &&
                                resposta_questao_analise[0] ? (
                                    <>
                                      <strong>
                                        {resposta_questao_analise[0].valor}
                                      </strong>
                                      <br />
                                      {!analise.concluida && (<button
                                        className="btn btn-danger"
                                        onClick={(e) =>
                                          handleRemove(
                                            resposta_questao_analise[0].id, e.target
                                          )
                                        }
                                      >
                                        {' '}
                                        <i className="fa fa-trash" /> Remover
                                      </button>)}
                                    </>
                                  ) : (
                                    <>
                                      {tipoMeioAnalise && tipoMeioAnalise.id && (
                                        <FormResposta
                                          analise_id={analise_id}
                                          params_load={paramsLoad}
                                          questao_id={id}
                                        />
                                      )}
                                    </>
                                  )}
                              </>}
                            </div>
                          </td>
                        </tr>
                        {alternativa_questao_analises &&
                          alternativa_questao_analises.map(
                            ({ nome, min_valor, max_valor }, i) => (
                              <tr key={i}>
                                <th
                                  className="text-center"
                                  width="10%"
                                >
                                  {min_valor} - {max_valor}
                                </th>

                                <td>{nome}</td>
                              </tr>
                            )
                          )}
                      </Fragment>
                    )
                  )}

                  <tr>
                    <th colSpan="3">
                      Total impacto {tipoMeioAnalise && tipoMeioAnalise.nome}
                    </th>

                    <td className="text-center">{totalValorAnalise}</td>
                  </tr>
                </tbody>
              </table>
            </>
          )}


          {!questaoAnalises && <Loading />}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-default"
            onClick={handleHide}
            type="button"
          >
            <i className="fa fa-window-close" /> Fechar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalAnalise;
