import * as actions from './actionType';

export const loadRespostaQuestaoMemorials = (params) => ({
  type: actions.LOAD_RESPOSTA_QUESTAO_MEMORIALS,
  params
});
export const getRespostaQuestaoMemorials = resposta_questao_memorials => ({
  type: actions.RESPOSTA_QUESTAO_MEMORIALS_DATA,
  resposta_questao_memorials,
});

export const getSelectRespostaQuestaoMemorial = resposta_questao_memorials => ({
  type: actions.SELECT_RESPOSTA_QUESTAO_MEMORIALS,
  resposta_questao_memorials,
});

export const storeRespostaQuestaoMemorial = resposta_questao_memorial => ({
  type: actions.STORE_RESPOSTA_QUESTAO_MEMORIAL,
  resposta_questao_memorial,

});
export const getRespostaQuestaoMemorial = resposta_questao_memorial => ({
  type: actions.GET_RESPOSTA_QUESTAO_MEMORIAL,
  resposta_questao_memorial,
});

export const removerRespostaQuestaoMemorial = (id) => ({
  type: actions.REMOVER_RESPOSTA_QUESTAO_MEMORIAL,
  id
});

export const showRespostaQuestaoMemorial = (id, params) => ({
  type: actions.SHOW_RESPOSTA_QUESTAO_MEMORIAL,
  id,
  params
});

export const updateRespostaQuestaoMemorial = (resposta_questao_memorial, id) => ({
  type: actions.UPDATE_RESPOSTA_QUESTAO_MEMORIAL,
  resposta_questao_memorial,
  id
});

export const getErros = errors => ({
  type: actions.RESPOSTA_QUESTAO_MEMORIAL_ERROR,
  errors,
});
