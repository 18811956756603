import React from 'react';
import { Form } from 'react-bootstrap';

const FormCad = ({ handleSubmit, schema, recurso, setRecurso, errors }) => {
  const handleChange = event => {
    const { name, value, files } = event.target;
    setRecurso({
      ...recurso,
      [name]: files ? files[0] : value,
    });
  };

  return (
    <Form id="form_recurso" onSubmit={handleSubmit} schema={schema}>
      <div className="form-group">
        <label htmlFor="nome"> Nome </label>
        <Form.Control
          id="nome"
          isInvalid={!!errors.nome}
          name="nome"
          onChange={handleChange}
          placeholder="Nome do recurso"
          type="text"
          value={recurso.nome || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.nome}
        </Form.Control.Feedback>
      </div>
      <div className="form-group">
        <label htmlFor="nome_rota"> Nome da Rota </label>
        <Form.Control
          id="nome_rota"
          isInvalid={!!errors.nome_rota}
          name="nome_rota"
          onChange={handleChange}
          placeholder="nome da rota ou regex"
          type="text"
          value={recurso.nome_rota || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.nome_rota}
        </Form.Control.Feedback>
      </div>
    </Form>
  );
};

export default FormCad;
