import * as actions from './actionType';

export const PotencialPoluidorReducer = (state = {select_potencial_poluidores: [], potencial_poluidores: [], potencial_poluidor: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.POTENCIAL_POLUIDORES_DATA:
      return {
        ...state,
        potencial_poluidores: action.potencial_poluidores.data,
        links: action.potencial_poluidores.data.links,
        meta: action.potencial_poluidores.data.meta,
      };
    case actions.SELECT_POTENCIAL_POLUIDORES:
      return {
        ...state,
        select_potencial_poluidores: action.potencial_poluidores.data
      };
    case actions.GET_POTENCIAL_POLUIDOR:
      return {
        ...state,
        potencial_poluidor: action.potencial_poluidor.data.data,
        status_response: action.potencial_poluidor.status,
      };
    case actions.POTENCIAL_POLUIDOR_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
