import React from 'react';
import { useDispatch } from 'react-redux';
import { login_user_error } from '~/store/modules/login/action';

import { Container } from './styles';

export default function Alert({ message }) {
  const dispatch = useDispatch();
  if (message) {
    setTimeout(() => {
      dispatch(login_user_error({}));
    }, 4000)
    return (
      <Container className="alert alert-danger">
        {message}
      </Container>
    );
  } else {
    return null;
  }
}
