import React from 'react';

const ListOds = ({indicadores_ods, handleDeleteOds}) => {

    return (
        <div className='card'>
            <div className="table-responsive">
                <table className="table table-hover table-striped">
                    <thead>
                        <tr>
                            <th>Indicador da ODS</th>
                            <th>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {indicadores_ods.map(({id, nome, ordem, label}, index) => (
                            <tr key={index}>
                            {nome ? (
                                <>
                                    <td>{ordem} - {nome}</td>
                                </>
                            ): (
                                <td>{label}</td>
                            )}
                            <td>
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleDeleteOds(id)}
                                    >
                                        Desativar
                                </button>
                            </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
        );
    }

    export default ListOds;
