import { apiWithToken } from '../api';

const resouceUrl = '/processo';
const token = localStorage.getItem('@token');
let api = apiWithToken(token);

export const load = async (params) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let page = urlParams.has('page') ? { page: urlParams.get('page') } : {};
  let search = urlParams.has('search')
    ? { search: urlParams.get('search') }
    : {};
  // return {
  //     data:[
  //       { numero: '01-0015-2019-13845598000196-01', data_entrada: '20.02.2020', data_distribuicao: '22.02.2020', atividade: 'Piscicultura', satus_pagamento:'pago'},
  //       { numero: '01-0015-2019-13845598000196-02', data_entrada: '20.02.2021', data_distribuicao: '22.02.2021', atividade: 'Piscicultura', satus_pagamento:'pendente'},
  //       { numero: '01-0015-2019-13845598000196-03', data_entrada: '20.02.2022', data_distribuicao: '22.02.2022', atividade: 'Piscicultura', satus_pagamento:'atrasado'},
  //       { numero: '01-0015-2019-13845598000196-04', data_entrada: '20.02.2023', data_distribuicao: '22.02.2023', atividade: 'Piscicultura', satus_pagamento:'expirado'},
  //       { numero: '01-0015-2019-13845598000196-05', data_entrada: '20.02.2024', data_distribuicao: '22.02.2024', atividade: 'Piscicultura', satus_pagamento:'pendente'},
  //       { numero: '01-0015-2019-13845598000196-06', data_entrada: '20.02.2025', data_distribuicao: '22.02.2025', atividade: 'Piscicultura', satus_pagamento:'expirado'},
  //       { numero: '01-0015-2019-13845598000196-07', data_entrada: '20.02.2026', data_distribuicao: '22.02.2026', atividade: 'Piscicultura', satus_pagamento:'pago'},
  //     ]
  // };

  return await api.get(`${resouceUrl}/pagamentos/pagamentos_pendentes`, {
    params: {
      ...params,
      ...page,
      ...search,
    },
  });
};

export const changeStatusPayment = async (params) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let page = urlParams.has('page') ? { page: urlParams.get('page') } : {};
  let search = urlParams.has('search')
    ? { search: urlParams.get('search') }
    : {};
  console.log('params.id:',params.id);
  return await api.put(`${resouceUrl}/${params.id}/confirma_pagamento`, {
    params: {
      ...page,
      ...search,
    },
  });
};

export const show = async (id, params) => {
  return await api.get(`${resouceUrl}/${id}`, {
    params
  });
};

export const update = async (id, processo) => {
  return await api.put(`${resouceUrl}/${id}`, processo);
};

export const destroy = async (id) => {
  return await api.delete(`${resouceUrl}/${id}`);
};
