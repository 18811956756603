import React, { useEffect } from 'react';
import { Link, Switch, Route, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { BasicContent } from '../components';
import { Loading, Paginate } from '~/components';
import {
  loadResponsavelTecnicos,
  removerResponsavelTecnico,
} from '~/store/modules/responsavel_tecnico/action';
import Create from '../Create';
import Edit from '../Edit';
import Show from '../Show';
import formatForMask from '~/utils/formatForMask';

const List = () => {
  let location = useLocation();
  let modal = location.state && location.state.modal;
  const dispatch = useDispatch();
  const { responsavel_tecnicos, meta } = useSelector(
    ({ ResponsavelTecnicoReducer }) => ResponsavelTecnicoReducer
  );

  useEffect(() => {
    dispatch(loadResponsavelTecnicos());
  }, [dispatch]);

  const breadcrumb = {
    label: 'Lista',
    to: '/tecnicos/responsavel_tecnico',
    active: false,
  };

  const handleRemove = async (event) => {
    const id = event.target.value;
    Swal.fire({
      title: 'Remover?',
      text: 'Deseja Remover esse item?!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.value) {
        try {
          await dispatch(removerResponsavelTecnico(id));
          await Swal.fire('Sucesso', 'Removido com sucesso!', 'success');
          dispatch(loadResponsavelTecnicos());
        } catch (error) {
          await Swal.fire('Erro', 'Erro ao Remover', 'error');
        }
      }
    });
  };
  return (
    <>
      {modal && (
        <Switch>
          <Route
            children={<Create />}
            path="/tecnicos/responsavel_tecnico/create"
          />
          <Route
            children={<Show />}
            exact
            path="/tecnicos/responsavel_tecnico/:responsavel_tecnico_id/detalhe"
          />
          <Route
            children={<Edit />}
            exact
            path="/tecnicos/responsavel_tecnico/:responsavel_tecnico_id/edit"
          />
        </Switch>
      )}
      <BasicContent breadcrumb={breadcrumb}>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Lista</h2>

            <div className="card-tools">
              <Link
                className="btn btn-success"
                to={{
                  pathname: '/tecnicos/responsavel_tecnico/create',
                  state: { modal: true },
                }}
              >
                <i className="fa fa-plus" /> Novo
              </Link>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-sm table-bordered table-hover table-striped">
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>CNPJ ou CPF</th>
                    <th>Email</th>
                    <th>Formação</th>
                    <th>Anotação Técnica </th>
                    <th className="text-right">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {responsavel_tecnicos.data &&
                    responsavel_tecnicos.data.map(
                      (
                        {
                          id,
                          responsavel_tecnico,
                          pode_elaboracao_e_estudo_projeto,
                          pode_elaboracao_orcamento,
                          pode_implantacao_estudo_e_projeto,
                          pode_execucao_estudo_e_projeto,
                          pode_fiscalizacao_estudo_e_projeto,
                          pode_monitoramento_estudo_e_projeto,
                        },
                        index
                      ) => (
                        <tr key={index}>
                          <td>{responsavel_tecnico.nome}</td>
                          <td>
                            {responsavel_tecnico.pessoa_tecnico_profissional
                              .cnpj ? formatForMask(responsavel_tecnico.pessoa_tecnico_profissional
                                .cnpj, '##.###.###/####-##') : ''} {responsavel_tecnico.pessoa_tecnico_profissional
                              .cpf ? formatForMask(responsavel_tecnico.pessoa_tecnico_profissional
                                .cpf, '###.###.###-##') : ''}
                          </td>
                          <td>{responsavel_tecnico.email}</td>
                          <td>
                            {
                              responsavel_tecnico.pessoa_tecnico_profissional
                                .formacao
                            }
                          </td>
                          <td>
                            <span
                              className={`badge ${
                                pode_elaboracao_e_estudo_projeto
                                  ? 'badge-success'
                                  : 'badge-secondary'
                              }`}
                            >
                              Elaboração de Estudo e Projeto
                            </span>
                            <br />
                            <span
                              className={`badge ${
                                pode_elaboracao_orcamento
                                  ? 'badge-success'
                                  : 'badge-secondary'
                              }`}
                            >
                              Elaboração de Orçamento
                            </span>
                            <br />
                            <span
                              className={`badge
                              ${
                        pode_implantacao_estudo_e_projeto
                          ? 'badge-success'
                          : 'badge-secondary'
                        }`}
                            >
                              Implantação de Estudo e Projeto
                            </span>
                            <br />
                            <span
                              className={`badge
                              ${
                        pode_execucao_estudo_e_projeto
                          ? 'badge-success'
                          : 'badge-secondary'
                        }`}
                            >
                              Execução de Estudo e Projeto
                            </span>
                            <br />
                            <span
                              className={`badge
                              ${
                        pode_fiscalizacao_estudo_e_projeto
                          ? 'badge-success'
                          : 'badge-secondary'
                        }`}
                            >
                              Fiscalização de Estudo e Projeto
                            </span>
                            <br />
                            <span
                              className={`badge
                              ${
                        pode_monitoramento_estudo_e_projeto
                          ? 'badge-success'
                          : 'badge-secondary'
                        }`}
                            >
                              Monitoramento de Estudo e Projeto
                            </span>
                          </td>
                          <td>
                            <div className="btn-group float-right">
                              <Link
                                className="btn btn-info"
                                to={{
                                  pathname: `/tecnicos/responsavel_tecnico/${id}/detalhe`,
                                  state: { modal: true },
                                }}
                              >
                                <FaEye /> Detalhes
                              </Link>
                              <Link
                                className="btn btn-default"
                                to={{
                                  pathname: `/tecnicos/responsavel_tecnico/${id}/edit`,
                                  state: { modal: true },
                                }}
                              >
                                <FaEdit /> Editar
                              </Link>
                              <button
                                className="btn btn-danger"
                                onClick={handleRemove}
                                value={id}
                              >
                                <FaTrash /> Remover
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
              {!responsavel_tecnicos.data && <Loading />}
            </div>
          </div>
          <div className="card-footer clearfix">
            <Paginate
              load={loadResponsavelTecnicos}
              meta={meta}
            />
          </div>
        </div>
      </BasicContent>
    </>
  );
};

export default List;
