import * as actions from './actionType';

export const loadValorReferencias = (params) => ({
  type: actions.LOAD_VALORES_REFERENCIAS,
  params
});
export const getValoresReferencias = valores_referencias => ({
  type: actions.VALOR_REFERENCIA_DATA,
  valores_referencias,
});

export const loadSelectValorReferencias = valor_referencias => ({
  type: actions.LOAD_SELECT_VALORES_REFERENCIAS,
  valor_referencias,
});

export const getSelectValorReferencias = valor_referencias => ({
  type: actions.SELECT_VALORES_REFERENCIAS,
  valor_referencias,
});

export const storeValorReferencia = valor_referencia => ({
  type: actions.STORE_VALOR_REFERENCIA,
  valor_referencia,
});

export const getValorReferencia = valor_referencia => ({
  type: actions.GET_VALOR_REFERENCIA,
  valor_referencia,
});

export const getCustomValorReferencia = tipo_licenca_id => ({
  type: actions.GET_CUSTOM_VALOR_REFERENCIA,
  tipo_licenca_id,
});

export const removerValorReferencia = (id) => ({
  type: actions.REMOVER_VALOR_REFERENCIA,
  id
});

export const showValorReferencia = id => ({
  type: actions.SHOW_VALOR_REFERENCIA,
  id,
});

export const updateValorReferencia = (valor_referencia, id) => ({
  type: actions.UPDATE_VALOR_REFERENCIA,
  valor_referencia,
  id
});

export const getErros = errors => ({
  type: actions.VALOR_REFERENCIA_ERROR,
  errors,
});

export const ativarValorReferencia = id => ({
  type: actions.ACTIVE_VALOR_REFERENCIA,
  id
})
