import React from 'react';

const TableQuestions = ({titulo, questoes}) => {
  return (
    <div className="table-responsive">
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th colSpan="2">{titulo}</th>
          </tr>
        </thead>
        <tbody>
          {questoes.map(({ val, q }) => (
            <tr>
              <th width="25%">{val}</th>
              <td>{q}</td>
            </tr>
          ))}
          <tr>
            <th>
              <div className="form-group">
                <input
                  className="form-control"
                  placeholder="Valor do Impacto do Profisional"
                  type="text"
                />
              </div>
            </th>
            <td>
              <small />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default TableQuestions;
