export const LOAD_PROJETO_PROCESSOS = 'LOAD_PROJETO_PROCESSOS';
export const SELECT_PROJETO_PROCESSOS = 'SELECT_PROJETO_PROCESSOS';
export const PROJETO_PROCESSO_ERROR = 'PROJETO_PROCESSO_ERROR';
export const PROJETO_PROCESSO_GET_ERRORS = 'PROJETO_PROCESSO_GET_ERRORS';
export const PROJETO_PROCESSOS_DATA = 'PROJETO_PROCESSOS_DATA';
export const STORE_PROJETO_PROCESSO = 'STORE_PROJETO_PROCESSO';
export const GET_PROJETO_PROCESSO = 'GET_PROJETO_PROCESSO';
export const REMOVER_PROJETO_PROCESSO = 'REMOVER_PROJETO_PROCESSO';
export const SHOW_PROJETO_PROCESSO = 'SHOW_PROJETO_PROCESSO';
export const UPDATE_PROJETO_PROCESSO = 'UPDATE_PROJETO_PROCESSO';
export const UPDATE_PROJETO_PROCESSO_PARECER = 'UPDATE_PROJETO_PROCESSO_PARECER';
