import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import 'suneditor/dist/css/suneditor.min.css';
import formatDataHora  from '~/utils/formatDataHora';
import { ModalAnexos } from '~/components';
import { NovaMensagem } from '../';
import { useDispatch, useSelector } from 'react-redux';
import { marcarComoLida } from '~/store/modules/mensagem/action'

const LerMensagem = ({processo,  mensagem }) => {
  const dispatch = useDispatch();
  const { user_auth } = useSelector(({ LoginReducer }) => LoginReducer);
  const [show, setShow] = useState(false);
  const [countResposta, setCountResposta] = useState(2);
  const [modalArquivo, setModalArquivo] = useState({
    title: '',
    path: '',
    show: false,
    handleHide: () => false,
  });
  const handleOpenModalAnexo = (title, path) => {
    setModalArquivo({
      title,
      path,
      show: true,
      handleHide,
    });
  };

  const handleHide = () => {
    setModalArquivo({
      title: '',
      path: '',
      show: false,
      handleHide: () => false,
    });
  }
  useEffect(() => {
    let total = 0;
    if (mensagem.respostas) {
      for (const {data_hora_leitura , user_remetente_id} of mensagem.respostas) {
        if (!data_hora_leitura && user_remetente_id !== user_auth.user.id) {
         total++
        }
      }
    }
    setCountResposta(total);

  }, [mensagem, user_auth]);

  useEffect(() => {
    if (show) {
      dispatch(marcarComoLida(mensagem.id));
    }
  }, [show, dispatch, mensagem.id])

  return (<>
    <button className="btn btn-primary" onClick={() => setShow(true)}>
      {countResposta ? <span className="badge badge-danger new-msg">
            <i className="fa fa-comment"/> {countResposta}
                            </span>
        : <><i className="fa fa-comment"/> </>}

      Ler mensagem
    </button>
    <Modal
      animation
      aria-labelledby="example-custom-modal-styling-title"
      dialogClassName="modal-xl"
      onHide={() => setShow(false)}
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title> <i className="fa fa-comments"></i> Mensagem</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card card-primary card-outline">

          <div className="card-body p-0">
            <div className="mailbox-read-info">
              <h5>{mensagem && mensagem.titulo}
                {mensagem && !mensagem.data_hora_leitura ? (<small>-  (Não lida)</small>) : (<small>  - Lida em {formatDataHora(mensagem.data_hora_leitura)}</small>)}
              </h5>
              <h6>de: {mensagem && mensagem.remetente && mensagem.remetente.nome}
                <span className="mailbox-read-time float-right"> Enviado em: {formatDataHora(mensagem.created_at)}</span></h6>
              <h6>Para: {mensagem && mensagem.receptor && mensagem.receptor.nome}
                </h6>
            </div>
            <div className="mailbox-read-message">
              <div className="m-3" dangerouslySetInnerHTML={{
                __html: mensagem.conteudo
              }}/>
            </div>
          </div>
          <div className="card-footer bg-white">
            <ul className="mailbox-attachments d-flex align-items-stretch clearfix">
              {mensagem && mensagem.anexos && mensagem.anexos[0] && mensagem.anexos.map((anexo, index) => (<li key={index}>
                <div className="mailbox-attachment-info">
                  <a href="# " className="mailbox-attachment-name"  onClick={() => handleOpenModalAnexo(anexo.nome, anexo.anexo)}>
                    <i
                    className="fas fa-paperclip"></i> {anexo.nome}</a>
                </div>
              </li>))}
            </ul>
          </div>
        </div>
        <hr/>
        { mensagem && mensagem.respostas.map((resposta, index)=>(
          <div className="card card-primary card-outline" key={index}>
            <div className="card-body p-0">
              <div className="mailbox-read-info">
                <h5>
                  {resposta && resposta.titulo}
                  {resposta && !resposta.data_hora_leitura ? (<small>  - (Não lida)</small>) : (<small>- Lida em {formatDataHora(resposta.data_hora_leitura)}</small>)}
                </h5>
                <h6>de: {resposta && resposta.remetente && resposta.remetente.nome}
                  <span className="mailbox-read-time float-right">Enviado em:  {formatDataHora(resposta.created_at)}</span></h6>

              </div>
              <div className="mailbox-read-message">
                <div className="m-3" dangerouslySetInnerHTML={{
                  __html: resposta.conteudo
                }}/>
              </div>
            </div>
            <div className="card-footer bg-white">
              <ul className="mailbox-attachments d-flex align-items-stretch clearfix">
                {resposta && resposta.anexos && resposta.anexos[0] && resposta.anexos.map((anexo, index) => (<li key={index}>
                  <div className="mailbox-attachment-info">
                    <a href="# " className="mailbox-attachment-name"  onClick={() => handleOpenModalAnexo(anexo.nome, anexo.anexo)}>
                      <i
                        className="fas fa-paperclip"></i> {anexo.nome}</a>
                  </div>
                </li>))}
              </ul>
            </div>
          </div>
        ))}



      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-default"
          onClick={() => setShow(false)}
          type="button"
        >
          <i className="fa fa-window-close"/> Fechar
        </button>
        <NovaMensagem processo={processo} mensagem_id={mensagem.id}/>
      </Modal.Footer>
    </Modal><ModalAnexos {...modalArquivo} />
  </>
);
};

export default LerMensagem;
