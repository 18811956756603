import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowCircleLeft } from 'react-icons/fa';
import {
    boxSelectCurso as BoxSelectCurso,
    boxActions as BoxActions,
} from './styles';
import { mutatePassword } from '../../store/modules/user/action';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

export default function TrocarSenha({history}) {

    const { status_response, errors } = useSelector(({ UserReducer }) => UserReducer);
    
    const dispatch = useDispatch();
    const [ infoUser, setInfoUser ] = useState({
        password:''
    });
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let key = urlParams.has('key') ?urlParams.get('key') : '';
    let id_user = urlParams.has('user') ?urlParams.get('user') : '';
    // console.log('key e id_user: ',key,' ,',id_user);
    useEffect(() => {
        // console.log('TrocarSenha errors: ',errors);
        if (errors.message) {
            // let server_errors = {};
            // const response_errors = errors.errors;
            // for (let name of Object.keys(response_errors)) {
            //   Object.assign(server_errors, { [name]: response_errors[name][0] });
            // }
            Swal.fire('Erro', 'Erro ao trocar a senha: '+errors.message, 'error').then(
                () => {}
            );
        }
    }, [errors]);

    useEffect(() => {
        // console.log('TrocarSenha status_response: ',status_response);
        if (status_response === 200) {
        Swal.fire('Sucesso', 'Senha alterada com sucesso!', 'success').then(
            () => {
                history.push('/login');
            }
        );
        }
    }, [status_response, history, dispatch]);
    
    const onHandleSubmit = async (event) => {
        event.preventDefault();
        let info = infoUser;
        info.key = key;
        info.id_user = id_user;
        await dispatch(mutatePassword(info));
    }

    const handleChange = event => {
        const { name, value } = event.target;
        setInfoUser({
            ...infoUser,
            [name]: value,
        });
    }

    return (
        <BoxSelectCurso className="d-flex d-flex-row box-base-green">
        <div className="card">
            <div className="card-body login-card-body">
                <p className="login-box-msg">TROCAR SENHA</p>
                <form onSubmit={onHandleSubmit}>
                    {/* <div className="input-group mb-3">
                        <input
                            aria-describedby="Email"
                            aria-label="Email"
                            className="form-control"
                            name="email"
                            onChange={handleChange}
                            placeholder="Email"
                            type="email"
                            value={infoUser.email}
                        />
                        <div className="input-group-append">
                            <div className="input-group-text">
                                <span className="fas fa-envelope" />
                            </div>
                        </div>
                    </div>
                    <div className="input-group mb-3">
                        <input
                            aria-describedby="CPF"
                            aria-label="CPF"
                            className="form-control"
                            name="cpf"
                            onChange={handleChange}
                            placeholder="CPF"
                            type="number"
                            value={infoUser.cpf}
                        />
                        <div className="input-group-append">
                            <div className="input-group-text">
                                <span className="fas fa-id-card" />
                            </div>
                        </div>
                    </div> */}
                    <div className="input-group mb-3">
                        <input
                            aria-describedby="Password"
                            aria-label="Password"
                            className="form-control"
                            name="password"
                            onChange={handleChange}
                            placeholder="Senha"
                            type="password"
                            value={infoUser.password}
                        />
                        <div className="input-group-append">
                            <div className="input-group-text">
                                <span className="fas fa-lock" />
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <button
                            className="btn btn-success btn-block"
                            type="submit"
                        >
                            TROCAR SENHA
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <BoxActions>
            <Link
            className="btn btn-musgo"
            to="/"
            >
            <FaArrowCircleLeft /> Voltar
            </Link>
        </BoxActions>
        </BoxSelectCurso>
    );
}
