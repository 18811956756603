import React from 'react';
const Modal = ({title, id, children }) => {
  return (
    <div
      className="modal fade"
      id={id}
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{title}</h4>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer justify-content-between">
            <button
              className="btn btn-default"
              data-dismiss="modal"
              type="button"
            >
              <i className="fa fa-window-close" /> Fechar
            </button>
            {/* <button
              className="btn btn-success"
              data-dismiss="modal"
              type="button"
            >
              <i className="fas fa-save" /> Salvar
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
