import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormEdit } from '../components';
import Swal from 'sweetalert2';
import schema from './schema'
import { showVeiculo, loadVeiculos, updateVeiculo } from '~/store/modules/veiculo/action';
import { Loading } from '~/components';

const Edit = ({entidade_id}) => {
  let history = useHistory();
  const [veiculo_edit, setVeiculoEdit] = useState(null);
  const [saved, setSaved] = useState(false);
  const { veiculo_id }  = useParams();
  const [errorsValidate, setErrorsValidate] = useState([]);
  const dispatch = useDispatch();
  const { status_response, errors, veiculo } = useSelector(({ VeiculoReducer }) => VeiculoReducer);

  useEffect(() => {
    dispatch(showVeiculo(veiculo_id));
  }, [dispatch, veiculo_id]);


  useEffect(() => {
    // console.log('errors Edit: ',errors);
    if (errors.status && errors.status === 422) {
      let server_errors = {};
      const response_errors = errors.data.errors;
      for (let name of Object.keys(response_errors)) {
        Object.assign(server_errors, { [name]: response_errors[name][0] });
      }
      setErrorsValidate(server_errors);
    }
    if(errors.update) {
      setSaved(errors.update);
    }
  }, [errors]);

  useEffect(() => {
    if (veiculo !== {}) {
      setVeiculoEdit({
        modelo: veiculo.modelo,
        placa: veiculo.placa,
        ano_fabricacao: veiculo.ano_fabricacao,
        ano_modelo: veiculo.ano_modelo,
        observacao: veiculo.observacao,
      });
    }
  }, [veiculo, status_response]);

  useEffect(() => {
    if (saved && status_response === 200) {
      Swal.fire('Sucesso', 'Registro editado com sucesso!', 'success').then(
        () => {
          history.goBack();
          dispatch(loadVeiculos({
            where:`entidade_id:${entidade_id}`,
          }));
        }
      );
    }
  }, [status_response, history, dispatch, saved, entidade_id]);


  const handleClose = () => {
    history.goBack();
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setErrorsValidate([]);
    if (!schema.isValidSync(veiculo_edit)) {
      let yup_errors = {};
      schema.validate(veiculo_edit, { abortEarly: false }).catch(err => {
        for (let error of err.inner) {
          const { path, message } = error;
          Object.assign(yup_errors, { [path]: message });
        }
        setErrorsValidate(yup_errors);
      });
    } else {
      const novo_veiculo = veiculo_edit;
      novo_veiculo.entidade_id = parseInt(entidade_id);
      // console.log('atividade_edit update: ',novo_veiculo);
      await dispatch(updateVeiculo(novo_veiculo, veiculo_id));
    }
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-xl"
      onHide={handleClose}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Veiculo
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Editar</h2>
          </div>
          <div className="card-body">
            {
              veiculo_edit?
                (
                  <FormEdit
                    errors={errorsValidate}
                    handleSubmit={handleSubmit}
                    schema={schema}
                    setVeiculo={setVeiculoEdit}
                    veiculo={veiculo_edit}
                  />
                ):
                (
                  <Loading />
                )
            }
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-default"
          onClick={handleClose}
        >
          <i className="fa fa-window-close" /> Cancelar
        </button>
        <button
          className="btn btn-success"
          form="form_veiculo"
          type="submint"
        >
          {' '}
          <i className="fa fa-save" /> Editar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Edit;
