import React from 'react';
import { Menu } from './components';

function SideBarNav({ pages }) {

  return (
    <nav className="mt-2">
      <ul
        className="nav nav-pills nav-sidebar flex-column"
        data-accordion="false"
        data-widget="treeview"
        role="menu"
      >
        {pages &&
          pages.map(({ menu }) => <Menu
            key={menu.menu_pai.id}
            menu={menu}
          />)}
      </ul>
    </nav>
  );
}

export default SideBarNav;
