import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Content } from '~/layouts/Dash/components';
import { Row, Col, Card, Table, Form, Button } from 'react-bootstrap';
import { Select } from '~/components/Form';
import { FiFilter } from 'react-icons/fi';
import { apiWithToken } from '~/services/api';
import Swal from 'sweetalert2';
import { BlobProvider } from '@react-pdf/renderer';
import { loadActivityAsOptions } from '~/services/Atividade/Atividade';
import formatCep from '~/utils/formatCep';
import formatPhone from '~/utils/formatPhone';
import RADEPDF from './PDF';

const breadcrumbs = [
    {
        label: 'Relatório',
        to: '/relatorios',
        active: false,
    },
    {
        label: 'Relatório Ambiental de Desenvolvimento Econômico',
        to: '/relatorios/relatorio-ambiental-desenvolvimento-economico',
        active: true,
    },
];

export default function RADE() {
    const token = localStorage.getItem('@token');
    const {
        user_auth: { user },
    } = useSelector(({ LoginReducer }) => LoginReducer);
    let api = apiWithToken(token);
    const [params, setParams] = useState({});
    const [period, setPeriod] = useState('');
    const [entityName, setEntityName] = useState('');
    const [organName, setOrganName] = useState('');
    const [logoSiblam, setLogoSiblam] = useState('');
    const [logoEntity, setLogoEntity] = useState('');
    const [data, setData] = useState([]);
    const [ativitys, setActivitys] = useState([]);
    const [logoCounty, setLogoCounty] = useState('');
    const [activity, setActivity] = useState('');
    const [show, setShow] = useState(false);
    const initialOption = {
        value: "select_all",
        label: "Selecionar Todos",
    };
    const options = [initialOption].concat(ativitys);

    const getActivityAsOptions = useCallback(
        async function () {
            const res = await loadActivityAsOptions({
                entity_id: user.entidade_id,
                value_field: 'id',
                label_field: 'nome',
            });

            setActivitys(res.data);
        },
        [user.entidade_id]
    );

    useEffect(() => {
        getActivityAsOptions();
    }, [getActivityAsOptions]);

    useEffect(() => {
        if (period) {
            setShow(true);
        }else {
            setShow(false);
        }
    }, [period, show]);

    function handleChange({ target: { name, value } }) {
        setParams({
            ...params,
            [name]: value,
        });
    }

    async function getRADE(event) {
        event.preventDefault();

        try {
            const res = await api.get(
                'relatorio-ambiental-desenvolvimento-economico',
                { params }
            );

            const {
                period,
                entity_name,
                organ_name,
                logo_siblam,
                logo_entity,
                data,
                logo_county,
                activity
            } = res.data;
            setPeriod(period);
            setEntityName(entity_name);
            setOrganName(organ_name);
            setLogoSiblam(logo_siblam);
            setLogoEntity(logo_entity);
            setLogoCounty(logo_county);
            setActivity(activity)
            setData(data);
        } catch (error) {
            const err = error.response.data.errors;

            if (Object.keys(err).includes('users_ids')) {
                await Swal.fire(
                    'Erro',
                    'O campo Atividade é obrigatório',
                    'error'
                );
            } else {
                await Swal.fire('Erro', 'Erro interno', 'error');
            }

            console.log(error.response);
        }
    }

    return (
        <Content
            breadcrumbs={breadcrumbs}
            title="Relatório Ambiental de Desenvolvimento Econômico"
        >
            <Row>
                <Col md="3">
                    <Card>
                        <Card.Header>
                            <Card.Title>Filtros</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={getRADE}>
                                <Form.Group>
                                    <Form.Label>Data Início</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="begin_date"
                                        onChange={handleChange}
                                        required
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Data Fim</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="end_date"
                                        onChange={handleChange}
                                        required
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Atividade</Form.Label>
                                    <Select
                                        name="activity_id"
                                        options={options}
                                        state={params}
                                        setState={setParams}
                                        required
                                    />
                                </Form.Group>
                                <Button
                                    block
                                    type="submit"
                                    variant="light"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <FiFilter style={{ marginRight: '5px' }} />
                                    Filtrar
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="9">
                    <Card>
                        <Card.Header>
                            <div
                                className="card-tools"
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    marginRight: '0px',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Card.Title>Relatório</Card.Title>
                                {(data.length > 0 || period) && (
                                    <div className="btn-group">
                                        <BlobProvider
                                            document={
                                                <RADEPDF
                                                    period={period}
                                                    entityName={entityName}
                                                    organName={organName}
                                                    logoSiblam={logoSiblam}
                                                    logoEntity={logoEntity}
                                                    logoCounty={logoCounty}
                                                    activity={activity}
                                                    data={data}
                                                />
                                            }
                                        >
                                            {({ url }) => (
                                                <a
                                                    href={url}
                                                    target="_blank"
                                                    className="btn btn-default"
                                                    rel="noopener noreferrer"
                                                >
                                                    <i className="fas fa-eye">
                                                        &nbsp;
                                                    </i>
                                                    Visualizar PDF
                                                </a>
                                            )}
                                        </BlobProvider>
                                    </div>
                                )}
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Table
                                responsive
                                bordered
                                hover
                                style={{ minWidth: '435px' }}
                            >
                                <thead>
                                    <tr>
                                        <th>N°</th>
                                        <th>CNAE / Atividade</th>
                                        <th>Processo</th>
                                        <th>Nome do Empreendimento</th>
                                        <th>Endereço</th>
                                        <th>Fone</th>
                                    </tr>
                                </thead>

                                {data.map(
                                    (
                                        {
                                            atividade,
                                            bairro,
                                            cep,
                                            cnae,
                                            logradouro,
                                            nome_empreendimento,
                                            numero_endereco,
                                            protocolo,
                                            fone,
                                        },
                                        index
                                    ) => (
                                        <tbody key={`tbody-${index}`}>
                                            <tr key={`row-${index}`}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    {cnae} | {atividade}
                                                </td>
                                                <td>{protocolo}</td>
                                                <td>{nome_empreendimento}</td>
                                                <td>{`${logradouro}, ${numero_endereco} - ${bairro}, ${formatCep(
                                                    cep || ''
                                                )}`}</td>
                                                <td>
                                                    {formatPhone(fone || '')}
                                                </td>
                                            </tr>
                                        </tbody>
                                    )
                                )}
                                {show && Object.keys(data).length === 0 && (
                                    <tbody>
                                        <tr>
                                            <td colSpan="6" style={{ textAlign: 'center' }}>
                                                Sem dados disponíveis para o período.
                                            </td>
                                        </tr>
                                    </tbody>
                                )}
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Content>
    );
}
