export const LOAD_CONDICIONATES = 'LOAD_CONDICIONATES';
export const SELECT_CONDICIONATES = 'SELECT_CONDICIONATES';
export const CONDICIONATE_ERROR = 'CONDICIONATE_ERROR';
export const CONDICIONATE_GET_ERRORS = 'CONDICIONATE_GET_ERRORS';
export const CONDICIONATES_DATA = 'CONDICIONATES_DATA';
export const STORE_CONDICIONATE = 'STORE_CONDICIONATE';
export const GET_CONDICIONATE = 'GET_CONDICIONATE';
export const REMOVER_CONDICIONATE = 'REMOVER_CONDICIONATE';
export const SHOW_CONDICIONATE = 'SHOW_CONDICIONATE';
export const UPDATE_CONDICIONATE = 'UPDATE_CONDICIONATE';
export const CHANGE_STATUS_CODE = 'CHANGE_STATUS_CODE';
