import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
// import { useSelector, useDispatch } from 'react-redux';
// import { loadCondicionantes } from '~/store/modules/condicionante/action';
import CustomAsyncSelect from '~/components/CustomAsyncSelect';
import {apiWithToken} from '~/services/api';
const token = localStorage.getItem('@token');
let api = apiWithToken(token);

const FormCad = ({ handleSubmit, schema, condicionante, setCondicionante, errors, entidade_id }) => {
  const [atividades_options, setAtividadesOptions] = useState([]);
  const [tipo_licenca_options, setTipoLicencaOptions] = useState([]);
  const [meio_analise_options, setMeioAnaliseOptions] = useState([]);
  const [selectAtividadeIsOpen, setSelectAtividadeIsOpen] = useState(false);
  const [selectTipoLicencaIsOpen, setSelectTipoLicencaIsOpen] = useState(false);
  const [selectMeioAnaliseIsOpen, setSelectMeioAnaliseIsOpen] = useState(false);


  useEffect(() => {
    const req_params = [
      {
        route: 'atividade/search',
        name: 'atividade_id',
        value: condicionante.atividade_id,
        setState: (obj) => setAtividadesOptions([obj])
      },
      {
        route: 'tipo_licenca/search',
        name: 'tipo_licenca_id',
        value: condicionante.tipo_licenca_id,
        setState: (obj) => setTipoLicencaOptions([obj]),
      },
      {
        route: 'meio_analise/search',
        name: 'meio_analise_id',
        value: condicionante.meio_analise_id,
        setState: (obj) => setMeioAnaliseOptions([obj]),
      },
    ];

    req_params.map(async (req_param) => {
      if(req_param.value){
        const res = await api.get(req_param.route, {
          params: {
            [req_param.name]: req_param.value,
          }
        })
        const data = res.data;
        req_param.setState({
          value: data[0].id,
          label: data[0].nome,
        });
      }
    });
  },[condicionante.atividade_id, condicionante.tipo_licenca_id, condicionante.meio_analise_id]);



  const loadOptions = async ({inputValue, hasId, id, name, route}) => {
    if(inputValue.length >= 3){
      const obj = {name: inputValue}
      if(hasId){
        obj[name] = id;
      }
      const response = await api.get(route, {
        params: {
          ...obj,
          entidade_id
        }
      })
      const options = [];
      response.data.forEach((option) => {
        options.push({
          value: option.id,
          label: option.nome,
        })
      })
      return options;
    }
  }

  const handleSelectChange = ({option, name}) => {
    switch (name) {
      case 'atividade_id':
        setCondicionante({
          ...condicionante,
          atividade_id: option.value,
          tipo_licenca_id: '',
          meio_analise_id: '',
        });

        setTipoLicencaOptions([]);
        break;
      case 'tipo_licenca_id':
        setCondicionante({
          ...condicionante,
          tipo_licenca_id: option.value,
          meio_analise_id: '',
        });
        setMeioAnaliseOptions([]);
        break;
      default:
        setCondicionante({
          ...condicionante,
          [name]: option.value
        });
        break;
    }

  };

  const handleChange = event => {
    const { name, value } = event.target;
    if(name === 'especifica'){
      if(value === 'true'){
        setCondicionante({
          ...condicionante,
          especifica: true,
        });
      }else{
        setCondicionante({
          especifica: false,
          nome: condicionante.nome,
        });
        setAtividadesOptions([]);
        setTipoLicencaOptions([]);
        setMeioAnaliseOptions([]);
      }
    }else{
      setCondicionante({
        ...condicionante,
        [name]: value,
      });
    }
  };

  const options = [
    {
      id:1,
      label:'Geral',
      value:false
    },
    {
      id:2,
      label:'Específica',
      value:true
    }
  ]

  return (
    <Form
      id="form_condicionante"
      onSubmit={handleSubmit}
      schema={schema}
    >
      <div className="form-group">
        <label htmlFor="especifica">Tipo de condicionante</label>
        <Form.Control
          as="select"
          id="especifica"
          isInvalid={!!errors.especifica}
          name="especifica"
          onChange={handleChange}
          value={condicionante.especifica}
        >
          {
            options.map(opt => (
              <option
                key={opt.id}
                value={opt.value}
              >
                {opt.label}
              </option>
            ))
          }
        </Form.Control>
        {/* <Form.Control.Feedback type="invalid"> */}
        {errors.especifica}
        {/* </Form.Control.Feedback> */}
      </div>

      {condicionante.especifica ? (
        <div className="form-group">
          <label htmlFor="atividades">Atividade</label>
          <CustomAsyncSelect
            defaultOptions={atividades_options}
            errorsMessage={errors.atividade_id}
            loadOptions={(inputValue) => loadOptions({inputValue, name: 'atividade_id', route: 'atividade/search'})}
            menuIsOpen={selectAtividadeIsOpen}
            onChange={(option) => handleSelectChange({option, name:'atividade_id'})}
            onInputChange={(value) => value.length >= 3 ? setSelectAtividadeIsOpen(true) : setSelectAtividadeIsOpen(false)}
            placeholder="Digite 3 ou mais caracteres para iniciar a busca..."
            value={atividades_options[0]}
          />
        </div>
      ) : ''}
      {condicionante.atividade_id ? (
        <div className="form-group">
          <label htmlFor="tipo_licenca">Tipo de licença</label>
          <CustomAsyncSelect
            defaultOptions={tipo_licenca_options}
            errorsMessage={errors.tipo_licenca_id}
            loadOptions={(inputValue) => loadOptions({inputValue, hasId: true, id: condicionante.atividade_id, name: 'atividade_id', route: 'tipo_licenca/search'})}
            menuIsOpen={selectTipoLicencaIsOpen}
            onChange={(option) => handleSelectChange({option, name:'tipo_licenca_id'})}
            onInputChange={(value) => value.length >= 3 ? setSelectTipoLicencaIsOpen(true) : setSelectTipoLicencaIsOpen(false)}
            placeholder="Digite 3 ou mais caracteres para iniciar a busca..."
            value={tipo_licenca_options[0]}
          />
        </div>
      ): ''}
      {condicionante.tipo_licenca_id ? (
        <div className="form-group">
          <label htmlFor="meio_analise">Meio analise</label>
          <CustomAsyncSelect
            defaultOptions={meio_analise_options}
            errorsMessage={errors.meio_analise_id}
            loadOptions={(inputValue) => loadOptions({inputValue, hasId: true, id: condicionante.tipo_licenca_id, name: 'tipo_licenca_id', route: 'meio_analise/search'})}
            menuIsOpen={selectMeioAnaliseIsOpen}
            onChange={(option) => handleSelectChange({option, name:'meio_analise_id'})}
            onInputChange={(value) => value.length >= 3 ? setSelectMeioAnaliseIsOpen(true) : setSelectMeioAnaliseIsOpen(false)}
            placeholder="Digite 3 ou mais caracteres para iniciar a busca..."
            value={meio_analise_options[0]}
          />
        </div>
      ): ''}

      <div className="form-group">
        <label htmlFor="nome"> Condicionante </label>
        <Form.Control
          as="textarea"
          id="nome"
          isInvalid={!!errors.nome}
          name="nome"
          onChange={handleChange}
          placeholder="Descrição"
          rows={8}
          type="text"
          value={condicionante.nome || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.nome}
        </Form.Control.Feedback>
      </div>
    </Form>
  );
};

export default FormCad;
