export const LOAD_VEICULOS = 'LOAD_VEICULOS';
export const LOAD_SELECT_VEICULOS = 'LOAD_SELECT_VEICULOS';
export const SELECT_VEICULOS = 'SELECT_VEICULOS';
export const VEICULO_ERROR = 'VEICULO_ERROR';
export const VEICULO_GET_ERRORS = 'VEICULO_GET_ERRORS';
export const VEICULOS_DATA = 'VEICULOS_DATA';
export const STORE_VEICULO = 'STORE_VEICULO';
export const GET_VEICULO = 'GET_VEICULO';
export const REMOVER_VEICULO = 'REMOVER_VEICULO';
export const SHOW_VEICULO = 'SHOW_VEICULO';
export const UPDATE_VEICULO = 'UPDATE_VEICULO';
