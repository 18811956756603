import React, { useState, useEffect } from 'react';
import { BoxCadPFisica } from './styles';
import { FormCad } from './components';
import Swal from 'sweetalert2';
import { initialData } from './components/FormCad/InitialData';
import schema from './components/FormCad/schemaValidations';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import removeMask from '../../utils/removeMask';
import { storePessoaJuridica } from '~/store/modules/pessoa_juridica/action';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export default function CadPessoaJuridica({ history }) {
    let query = useQuery();
    const {
        nome,
        cpf_cnpj,
        cep,
        estado,
        municipio,
        bairro,
        logradouro,
        numero,
    } = Object.fromEntries(query.entries());
    const [pessoaJuridica, setPessoaJuridica] = useState({
        ...initialData,
        nome,
        cnpj: cpf_cnpj,
        cep,
        estado,
        cidade: municipio,
        bairro,
        logradouro,
        numero_endereco: numero,
    });
    const [errorsValidate, setErrorsValidate] = useState([]);
    const dispatch = useDispatch();
    const { status_response, errors } = useSelector(
        ({ PessoaJuridicaReducer }) => PessoaJuridicaReducer
    );

    useEffect(() => {
        if (errors.status && errors.status === 422) {
            let server_errors = {};
            const response_errors = errors.data.errors;
            let errorMessages = '';
            for (let name of Object.keys(response_errors)) {
                Object.assign(server_errors, {
                    [name]: response_errors[name][0],
                });
                errorMessages += `${response_errors[name][0]}\n`;
            }
            setErrorsValidate(server_errors);
            Swal.fire('Error ao salvar os dados!', errorMessages, 'error');
        }
    }, [errors]);

    useEffect(() => {
        if (status_response === 201) {
            Swal.fire(
                'Sucesso',
                'Cadastro efetuado com sucesso!',
                'success'
            ).then((result) => {
                if (result.isConfirmed) {
                    history.push('/login');
                }
            });
        }
    }, [status_response, history, dispatch]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!schema.isValidSync(pessoaJuridica)) {
            let yup_errors = {};
            schema
                .validate(pessoaJuridica, { abortEarly: false })
                .catch((err) => {
                    for (let error of err.inner) {
                        const { path, message } = error;
                        Object.assign(yup_errors, { [path]: message });
                    }
                    setErrorsValidate(yup_errors);
                });
        } else {
            const form = new FormData();
            const pessoaJuridicaWithoutSpecialCharacters = pessoaJuridica;
            pessoaJuridicaWithoutSpecialCharacters.cpf_responsavel_legal =
                removeMask(
                    pessoaJuridicaWithoutSpecialCharacters.cpf_responsavel_legal
                );
            pessoaJuridicaWithoutSpecialCharacters.cnpj = removeMask(
                pessoaJuridicaWithoutSpecialCharacters.cnpj
            );
            pessoaJuridicaWithoutSpecialCharacters.numero_whatsapp = removeMask(
                pessoaJuridicaWithoutSpecialCharacters.numero_whatsapp
            );
            pessoaJuridicaWithoutSpecialCharacters.cep = removeMask(
                pessoaJuridicaWithoutSpecialCharacters.cep
            );
            pessoaJuridicaWithoutSpecialCharacters.telefone = removeMask(
                pessoaJuridicaWithoutSpecialCharacters.telefone
            );
            for (let key in pessoaJuridicaWithoutSpecialCharacters) {
                form.append(key, pessoaJuridicaWithoutSpecialCharacters[key]);
            }

            await dispatch(storePessoaJuridica(form));
        }
    };

    return (
        <BoxCadPFisica className="d-flex d-flex-row box-base-green">
            <h3> CADASTRO PESSOA JURÍDICA </h3>
            <FormCad
                errors={errorsValidate}
                onHandleSubmit={handleSubmit}
                pessoaJuridica={pessoaJuridica}
                setErrors={setErrorsValidate}
                setPessoaJuridica={setPessoaJuridica}
            />
        </BoxCadPFisica>
    );
}
