import styled from 'styled-components';

export const TitleSearchInput = styled.p`
  font-size: 15px;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const BoxSearch = styled.div`
  width: 100%;
  background-color: #eee;
  display: flex;
  justify-content:center;
`;

export const Search = styled.div`
  padding: 20px;
  width: 400px;
`;

export const BoxContent = styled.div`
  display: flex;
  justify-content: center;
  margin: 0;
`;

export const Img = styled.img`
  width: 500px;
  height: 480px;
`;

export const InfoProcess = styled.div`
  margin: 20px 0 -10px 0;
  height: auto;
  min-width: 60%;
`;

export const InputSearch = styled.input`
  border: 1px solid ${props => props.errorColor};
`;
