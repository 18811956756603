import * as actions from './actionType';

export const TecnicoProfissionalReducer = (
  state = {
    select_tecnico_profissionais: [],
    tecnico_profissionais: [],
    tecnico_profissional: {},
    errors: {},
  },
  action
) => {
  switch (action.type) {
    case actions.TECNICO_PROFISSIONAIS_DATA:
      return {
        ...state,
        tecnico_profissionais: action.tecnico_profissionais.data,
        links: action.tecnico_profissionais.data.links,
        meta: action.tecnico_profissionais.data.meta,
      };
    case actions.MINHAS_APIAS_DATA:
      return {
        ...state,
        processos: action.processos.data,
        links: action.processos.data.links,
        meta: action.processos.data.meta,
      };
    case actions.SELECT_TECNICO_PROFISSIONAIS:
      return {
        ...state,
        select_tecnico_profissionais: action.tecnico_profissionais.data,
      };
    case actions.GET_TECNICO_PROFISSIONAL:
      return {
        ...state,
        tecnico_profissional: action.tecnico_profissional.data.data,
        status_response: action.tecnico_profissional.status,
      };
    case actions.TECNICO_PROFISSIONAL_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
};
