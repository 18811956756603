import React, { useState } from 'react';
import { Content } from '~/layouts/Dash/components';
import {
  Row, Col, Card, Table,
  Form, Button
} from 'react-bootstrap';
import { FiFilter } from 'react-icons/fi';
import { apiWithToken } from '~/services/api';
import Swal from 'sweetalert2';
import { toBRL } from '~/utils/formatCurrency';
import { BlobProvider } from '@react-pdf/renderer';
import RAFAPDF from './PDF';

const breadcrumbs = [
  {
    label: 'Relatório',
    to: '/relatorios',
    active: false,
  }, {
    label: 'Relatório de Arrecadação Financeira Ambiental',
    to: '/relatorios/relatorio-arrecadacao-financeira-ambiental',
    active: true,
  }
];

export default function RAFA() {
  const token = localStorage.getItem('@token');
  let api = apiWithToken(token);
  const [params, setParams] = useState({});
  const [period, setPeriod] = useState('');
  const [entityName, setEntityName] = useState('');
  const [organName, setOrganName] = useState('');
  const [logoSiblam, setLogoSiblam] = useState('');
  const [logoEntity, setLogoEntity] = useState('');
  const [processes, setProcesses] = useState(null);
  const [logoCounty, setLogoCounty] = useState('');

  function handleChange({ target }) {
    const { name, value } = target;

    setParams({
      ...params,
      [name]: value
    })
  }

  async function getRAFA(event) {
    event.preventDefault();

    try {
      const res = await api.get(
        'relatorio-arrecadacao-financeira-ambiental',
        { params }
      );

      const {
        period, entity_name,
        organ_name, logo_siblam,
        logo_entity, processes,
        logo_county
      } = res.data;

      setPeriod(period);
      setEntityName(entity_name);
      setOrganName(organ_name);
      setLogoSiblam(logo_siblam);
      setLogoEntity(logo_entity);
      setProcesses(processes);
      setLogoCounty(logo_county);
    } catch (error) {
      await Swal.fire(
        'Erro',
        'Erro interno',
        'error'
      );
      console.log(error.response);
    }
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Relatório de Arrecadação Financeira Ambiental"
    >
      <Row>
        <Col md="3">
          <Card>
            <Card.Header>
              <Card.Title>Filtros</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={getRAFA}>
                <Form.Group>
                  <Form.Label>Data Início</Form.Label>
                  <Form.Control
                    type="date"
                    name="begin_date"
                    onChange={handleChange}
                    required
                  >
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Data Fim</Form.Label>
                  <Form.Control
                    type="date"
                    name="end_date"
                    onChange={handleChange}
                    required
                  >
                  </Form.Control>
                </Form.Group>
                <Button
                  block
                  type="submit"
                  variant="light"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <FiFilter style={{ marginRight: '5px'}}/>
                  Filtrar
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col md="9">
          <Card>
            <Card.Header>
              <div
                className="card-tools"
                style={{
                  width: '100%',
                  display: 'flex',
                  marginRight: '0px',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Card.Title>Relatório</Card.Title>
                {processes !== null && (
                  <div className="btn-group">
                    <BlobProvider document={
                      <RAFAPDF
                        period={period}
                        entityName={entityName}
                        organName={organName}
                        logoSiblam={logoSiblam}
                        logoEntity={logoEntity}
                        processes={processes}
                        LogoCounty={logoCounty}
                      />
                    }>
                      {({ url }) => (
                        <a
                          href={url}
                          target="_blank"
                          className="btn btn-default"
                          rel="noopener noreferrer"
                        >
                          <i className="fas fa-eye">&nbsp;</i>
                          Visualizar PDF
                        </a>
                      )}
                    </BlobProvider>
                  </div>
                )}
              </div>
            </Card.Header>
            <Card.Body>
              <Table responsive bordered hover style={{ minWidth: '435px' }}>
                <thead>
                  <tr>
                    <th>N°</th>
                    <th>Cód. Receita</th>
                    <th>Descrição</th>
                    <th>N° Processo</th>
                    <th>Valor</th>
                  </tr>
                </thead>
                <tbody>
                  {processes !== null && (processes.length === 0 ? (
                    <tr>
                      <td colSpan={5}>
                        Sem dados para o periodo
                      </td>
                    </tr>
                  ) : (
                    processes.map((process, index) => (
                      <tr key={`process-${index + 1}`}>
                        <td>{index + 1}</td>
                        <td>1121.04.1.1.00.02</td>
                        <td>Taxa de Licenciamento Ambiental</td>
                        <td>{process.protocolo}</td>
                        <td>R$ {toBRL(process.valor_total)}</td>
                      </tr>
                    ))
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Content>
  );
}
