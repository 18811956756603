import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlineFileDone } from 'react-icons/ai';
import { Modal } from 'react-bootstrap';
import { Loading, ModalAnexos } from '~/components';
import { loadListaDocumentos } from '~/store/modules/lista_documento/action';
import FileForm from './FileForm';

const CheckList = ({ setDocsTecIsOk }) => {
  const dispatch = useDispatch();
  let [show, setShow] = useState(false);
  const { user_auth } = useSelector(
    ({ LoginReducer }) => LoginReducer
  );
  const { processo } = useSelector(
    ({ ProcessoReducer }) => ProcessoReducer
  );
  const { lista_documentos } = useSelector(
    ({ ListaDocumentoReducer }) => ListaDocumentoReducer
  );
  const [unfilledDocs, setUnfilledDocs] = useState([]);
  const [modalArquivo, setModalArquivo] = useState({
    title: '',
    path: '',
    show: false,
    handleHide: () => false,
  });

  useEffect(() => {
    const tipoLicencaId = processo?.tipo_licenca_id;

    if (tipoLicencaId) {
      dispatch(
        loadListaDocumentos({
          raw_list: true,
          where:`tipo_licenca_id:${tipoLicencaId},doc_tecnica:true`,
          order_by: 'ordem:asc'
        })
      );
    }
  }, [processo.tipo_licenca_id, dispatch]);

  useEffect(() => {
    if (processo?.documentos && lista_documentos?.data) {
      const unfilledDocuments = [];

      lista_documentos.data.forEach(documento => {
        if (!processo?.documentos.find(doc => doc.nome === documento.nome)) {
          unfilledDocuments.push(documento);
        }
      });

      setUnfilledDocs(unfilledDocuments);
    }
  }, [processo.documentos, lista_documentos.data]);

  useEffect(() => {
    if (setDocsTecIsOk) {
      const verifyDocsTecIsOk = () => {
        if (user_auth.user.tipo_user_id === 7) {
          if (!unfilledDocs.length) {
            setDocsTecIsOk(true);
          } else {
            setDocsTecIsOk(false);
          }
        }
      }
      verifyDocsTecIsOk();
    }
  }, [unfilledDocs, setDocsTecIsOk, user_auth.user.tipo_user_id]);

  const handleOpenModalAnexo = (title, path) => {
    setModalArquivo({
      title,
      path,
      show: true,
      handleHide,
    });
  };

  const handleHide = () => {
    setModalArquivo({
      title: '',
      path: '',
      show: false,
      handleHide: () => false,
    });
  };

  return (
    <>
      {processo && processo.documentos ? (
        <>
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Lista de Documentos</h3>
            </div>
            <div className="card-body">
              <button
                className={`btn btn-block ${unfilledDocs.length ? 'btn-success' : 'btn-primary'}`}
                onClick={() => setShow(true)}
              >
                <AiOutlineFileDone /> Lista de Documentos
              </button>
            </div>
          </div>
          <Modal
            animation
            aria-labelledby="example-custom-modal-styling-title"
            dialogClassName="modal-xl"
            onHide={() => setShow(false)}
            show={show}
          >
            <Modal.Header closeButton>
              <Modal.Title>Lista de Documentos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>Documentação Administrativa</h5>
              <div className="table-responsive">
                <table className="table table-striped table-hover table-bordered">
                  <tbody>
                    {processo.documentos.map(({ nome, anexo }, index) =>
                      !lista_documentos.data.find(documento => documento.nome === nome) && (
                        <tr key={index}>
                          <td>{nome}</td>
                          <td style={{ width: '35%' }}>
                            <button
                              className="btn btn-default ml-2"
                              onClick={() => handleOpenModalAnexo(nome, anexo)}
                            >
                              <i className="fa fa-paperclip" /> Visualizar
                            </button>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>

              {lista_documentos.data.length > 0 && (
                <>
                  <h5>Documentação Técnica</h5>
                  <div className="table-responsive">
                    <table className="table table-striped table-hover table-bordered">
                      <tbody>
                        {lista_documentos.data.map(({ id, nome, obrigatorio }, index) => {
                          const documento = processo.documentos.find(documento => documento.nome === nome);
                          if (documento) {
                            return (
                              <tr key={index}>
                                <td>{nome}</td>
                                <td style={{ width: '35%' }}>
                                  <button
                                    className="btn btn-default ml-2"
                                    onClick={() => handleOpenModalAnexo(nome, documento.anexo)}
                                  >
                                    <i className="fa fa-paperclip" /> Visualizar
                                  </button>
                                </td>
                              </tr>
                            );
                          } else {
                            return (
                              <tr key={index}>
                                <td>{nome}</td>
                                <td style={{ width: '35%' }}>
                                  {user_auth.user.tipo_user_id === 1 || user_auth.user.tipo_user_id === 7 ? (
                                    <FileForm
                                      id={id}
                                      nome={nome}
                                      obrigatorio={obrigatorio}
                                      processo_id={processo.id}
                                    />
                                  ) : 'Documento Não Enviado'}
                                </td>
                              </tr>
                            );
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              )}

              <h5>ARTS</h5>
              <div className="table-responsive">
                <table className="table table-striped table-hover table-bordered">
                  <tbody>
                    {processo.elaboracao_estudo_projeto_anexo ? (
                      <tr>
                        <td>Elaboração de Estudo do Projeto</td>
                        <th>
                          <button
                            className="btn btn-default ml-2"
                            onClick={() => handleOpenModalAnexo(
                              'Elaboração de estudo do projeto',
                              processo.elaboracao_estudo_projeto_anexo
                            )}
                          >
                            <i className="fa fa-paperclip" /> Visualizar
                          </button>
                        </th>
                      </tr>
                    ) : null}
                    {processo.elaboracao_orcamento_anexo ? (
                      <tr>
                        <td>Elaboração de Orçamento</td>
                        <th>
                          <button
                            className="btn btn-default ml-2"
                            onClick={() => handleOpenModalAnexo('Elaboração de Orçamento', processo.elaboracao_orcamento_anexo)}
                          >
                            <i className="fa fa-paperclip" /> Visualizar
                          </button>
                        </th>
                      </tr>
                    ) : null}
                    {processo.execucao_estudo_e_projeto_anexo ? (
                      <tr>
                        <td>Execução do Estudo e Projeto Anexo</td>
                        <th>
                          <button
                            className="btn btn-default ml-2"
                            onClick={() => handleOpenModalAnexo('Execução do Estudo e Projeto Anexo', processo.execucao_estudo_e_projeto_anexo)}
                          >
                            <i className="fa fa-paperclip" /> Visualizar
                          </button>
                        </th>
                      </tr>
                    ) : null}
                    {processo.fiscalizacao_estudo_projeto_anexo ? (
                      <tr>
                        <td>Fiscalização de Estudo e Projeto Anexo</td>
                        <th>
                          <button
                            className="btn btn-default ml-2"
                            onClick={() => handleOpenModalAnexo('Fiscalização de Estudo e Projeto Anexo', processo.fiscalizacao_estudo_projeto_anexo)}
                          >
                            <i className="fa fa-paperclip" /> Visualizar
                          </button>
                        </th>
                      </tr>
                    ) : null}
                    {processo.implantacao_estudo_projeto_anexo ? (
                      <tr>
                        <td>Implantação do Estudo e Projeto Anexo</td>
                        <th>
                          <button
                            className="btn btn-default ml-2"
                            onClick={() => handleOpenModalAnexo('Implantação do Estudo e Projeto Anexo', processo.implantacao_estudo_projeto_anexo)}
                          >
                            <i className="fa fa-paperclip" /> Visualizar
                          </button>
                        </th>
                      </tr>
                    ) : null}
                    {processo.monitoramento_estudo_projeto_anexo ? (
                      <tr>
                        <td>Monitoramento do Estudo e Projeto Anexo</td>
                        <th>
                          <button
                            className="btn btn-default ml-2"
                            onClick={() => handleOpenModalAnexo('Monitoramento do Estudo e Projeto Anexo', processo.monitoramento_estudo_projeto_anexo)}
                          >
                            <i className="fa fa-paperclip" /> Visualizar
                          </button>
                        </th>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>

              <h5>Documentos Cadastrais</h5>
              <div className="table-responsive">
                <table className="table table-striped table-hover table-bordered">
                  <tbody>
                    {processo.pessoa.anexo_certidao_simplificada_jc ? (
                      <tr>
                        <td colSpan="10">Anexo Certidão Simplificada</td>
                        <th>
                          <button
                            className="btn btn-default ml-2"
                            onClick={() => handleOpenModalAnexo('Anexo Certidão Simplificada', processo.pessoa.anexo_certidao_simplificada_jc)}
                          >
                            <i className="fa fa-paperclip" /> Visualizar
                          </button>
                        </th>
                      </tr>
                    ) : null}
                    {processo.pessoa.anexo_cnpj ? (
                      <tr>
                        <td  colSpan="10">Anexo CNPJ</td>
                        <th>
                          <button
                            className="btn btn-default ml-2"
                            onClick={() => handleOpenModalAnexo('Anexo CNPJ', processo.pessoa.anexo_cnpj)}
                          >
                            <i className="fa fa-paperclip" /> Visualizar
                          </button>
                        </th>
                      </tr>
                    ) : null}
                    {processo.pessoa.anexo_cpf ? (
                      <tr>
                        <td colSpan="10">Anexo CPF</td>
                        <th>
                          <button
                            className="btn btn-default ml-2"
                            onClick={() => handleOpenModalAnexo('Anexo CPF', processo.pessoa.anexo_cpf)}
                          >
                            <i className="fa fa-paperclip" /> Visualizar
                          </button>
                        </th>
                      </tr>
                    ) : null}
                    {processo.pessoa.anexo_cpf_responsavel_legal ? (
                      <tr>
                        <td colSpan="10">Anexo CPF do Responsavel Legal</td>
                        <th>
                          <button
                            className="btn btn-default ml-2"
                            onClick={() => handleOpenModalAnexo('Anexo CPF do Responsavel Legal', processo.pessoa.anexo_cpf_responsavel_legal)}
                          >
                            <i className="fa fa-paperclip" /> Visualizar
                          </button>
                        </th>
                      </tr>
                    ) : null}
                    {processo.pessoa.anexo_rg_responsavel_legal ? (
                      <tr>
                        <td colSpan="10">Anexo do RG do Responsavel Legal</td>
                        <th>
                          <button
                            className="btn btn-default ml-2"
                            onClick={() => handleOpenModalAnexo('Anexo do RG do Responsavel Legal', processo.pessoa.anexo_rg_responsavel_legal)}
                          >
                            <i className="fa fa-paperclip" /> Visualizar
                          </button>
                        </th>
                      </tr>
                    ) : null}
                    {processo.pessoa.anexo_doutorado ? (
                      <tr>
                        <td colSpan="10">Anexo do Doutorado</td>
                        <th>
                          <button
                            className="btn btn-default ml-2"
                            onClick={() => handleOpenModalAnexo('Anexo do Doutorado', processo.pessoa.anexo_doutorado)}
                          >
                            <i className="fa fa-paperclip" /> Visualizar
                          </button>
                        </th>
                      </tr>
                    ) : null}
                    {processo.pessoa.anexo_endereco ? (
                      <tr>
                        <td colSpan="10">Anexo do Endereço</td>
                        <th>
                          <button
                            className="btn btn-default ml-2"
                            onClick={() => handleOpenModalAnexo('Anexo do Endereço', processo.pessoa.anexo_endereco)}
                          >
                            <i className="fa fa-paperclip" /> Visualizar
                          </button>
                        </th>
                      </tr>
                    ) : null}
                    {processo.pessoa.anexo_endereco_responsavel_legal ? (
                      <tr>
                        <td colSpan="10">Anexo do Endereço do Responsavel Legal</td>
                        <th>
                          <button
                            className="btn btn-default ml-2"
                            onClick={() => handleOpenModalAnexo('Anexo do Endereço do Responsavel Legal', processo.pessoa.anexo_endereco_responsavel_legal)}
                          >
                            <i className="fa fa-paperclip" /> Visualizar
                          </button>
                        </th>
                      </tr>
                    ) : null}
                    {processo.pessoa.anexo_especializacao ? (
                      <tr>
                        <td colSpan="10">Anexo da Especialização</td>
                        <th>
                          <button
                            className="btn btn-default ml-2"
                            onClick={() => handleOpenModalAnexo('Anexo da Especialização', processo.pessoa.anexo_especializacao)}
                          >
                            <i className="fa fa-paperclip" /> Visualizar
                          </button>
                        </th>
                      </tr>
                    ) : null}
                    {processo.pessoa.anexo_formacao ? (
                      <tr>
                        <td colSpan="10">Anexo da Formação</td>
                        <th>
                          <button
                            className="btn btn-default ml-2"
                            onClick={() => handleOpenModalAnexo('Anexo da Formação', processo.pessoa.anexo_formacao)}
                          >
                            <i className="fa fa-paperclip" /> Visualizar
                          </button>
                        </th>
                      </tr>
                    ) : null}
                    {processo.pessoa.anexo_formacao_2 ? (
                      <tr>
                        <td colSpan="10">Anexo da Formação 2</td>
                        <th>
                          <button
                            className="btn btn-default ml-2"
                            onClick={() => handleOpenModalAnexo('Anexo da Formação 2', processo.pessoa.anexo_formacao_2)}
                          >
                            <i className="fa fa-paperclip" /> Visualizar
                          </button>
                        </th>
                      </tr>
                    ) : null}
                    {processo.pessoa.anexo_mestrado ? (
                      <tr>
                        <td colSpan="10">Anexo do Mestrado</td>
                        <th>
                          <button
                            className="btn btn-default ml-2"
                            onClick={() => handleOpenModalAnexo('Anexo do Mestrado', processo.pessoa.anexo_mestrado)}
                          >
                            <i className="fa fa-paperclip" /> Visualizar
                          </button>
                        </th>
                      </tr>
                    ) : null}
                    {processo.pessoa.anexo_rg ? (
                      <tr>
                        <td colSpan="10">Anexo do RG</td>
                        <th>
                          <button
                            className="btn btn-default ml-2"
                            onClick={() => handleOpenModalAnexo('Anexo do RG', processo.pessoa.anexo_rg)}
                          >
                            <i className="fa fa-paperclip" /> Visualizar
                          </button>
                        </th>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-default"
                onClick={() => setShow(false)}
                type="button"
              >
                <i className="fa fa-window-close" /> Fechar
              </button>
            </Modal.Footer>
          </Modal>
          <ModalAnexos {...modalArquivo} />
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};
export default CheckList;
