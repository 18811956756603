import * as actions from './actionType';

export const loadRespostaQuestaos = (params) => ({
  type: actions.LOAD_RESPOSTA_QUESTAOS,
  params
});
export const getRespostaQuestaos = resposta_questaos => ({
  type: actions.RESPOSTA_QUESTAOS_DATA,
  resposta_questaos,
});

export const getSelectRespostaQuestao = resposta_questaos => ({
  type: actions.SELECT_RESPOSTA_QUESTAOS,
  resposta_questaos,
});

export const storeRespostaQuestao = resposta_questao => ({
  type: actions.STORE_RESPOSTA_QUESTAO,
  resposta_questao,

});
export const getRespostaQuestao = resposta_questao => ({
  type: actions.GET_RESPOSTA_QUESTAO,
  resposta_questao,
});

export const removerRespostaQuestao = (id) => ({
  type: actions.REMOVER_RESPOSTA_QUESTAO,
  id
});

export const showRespostaQuestao = (id, params) => ({
  type: actions.SHOW_RESPOSTA_QUESTAO,
  id,
  params
});

export const updateRespostaQuestao = (resposta_questao, id) => ({
  type: actions.UPDATE_RESPOSTA_QUESTAO,
  resposta_questao,
  id
});

export const getErros = errors => ({
  type: actions.RESPOSTA_QUESTAO_ERROR,
  errors,
});
