import { apiWithToken } from '../api';

const resouceUrl = '/ods/objetivos';
const token = localStorage.getItem('@token');
let api = apiWithToken(token);

export const load_objetivos_ods = async (params) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let page = urlParams.has('page') ? { page: urlParams.get('page') } : {};
    let search = urlParams.has('search')
        ? { search: urlParams.get('search') }
        : {};
    return await api.get(`${resouceUrl}`, {
        params: {
        ...params,
        ...page,
        ...search,
        },
    });
};
