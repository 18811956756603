import * as AtividadeViews from '~/views/Atividade';
import * as LegisAtivViews from '~/views/LegislacaoAtividade';
import * as IndAmbViews from '~/views/IndicadoresAmbientais';

const basePath = '/atividade';

export default [
  // { component: Views.AtividadeList, path: basePath, exact: true }
  // { component: Views.AtividadeList, path: `${basePath}/*`, exact: false },
  { component: AtividadeViews.AtividadeShow, path: `${basePath}/:atividade_id/detalhe`, exact: false },
  { component: AtividadeViews.AtividadeEdit, path: `${basePath}/:atividade_id/editar`, exact: false },
  { component: AtividadeViews.AtividadeShow, path: `${basePath}/:atividade_id/tipo_licenca/*`, exact: true },
  { component: AtividadeViews.AtividadeShow, path: `${basePath}/:atividade_id/legislacao/*`, exact: false },
  { component: LegisAtivViews.CreateLegislacao, path: `${basePath}/:atividade_id/legislacao/novo`, exact: true },
  { component: LegisAtivViews.EditLegislacao, path: `${basePath}/:atividade_id/legislacao/:legislacao_id/editar`, exact: true },
  { component: AtividadeViews.AtividadeShow, path: `${basePath}/:atividade_id/indicadoresAmbientais/*`, exact: false },
  { component: IndAmbViews.IndicadorAmbientalCreate, path: `${basePath}/:atividade_id/indicadoresAmbientais/novo`, exact: true },
  { component: IndAmbViews.IndicadorAmbientalEdit, path: `${basePath}/:atividade_id/indicadoresAmbientais/:indicador_ambiental_id/editar`, exact: true },
  { component: IndAmbViews.ImportIndicadorAmbiental, path: `${basePath}/:atividade_id/indicadoresAmbientais/import_data`, exact: true }
];
