import React from 'react';
import { Form } from 'react-bootstrap';

const FormCad = ({
  handleSubmit,
  schema,
  config_autorizacao,
  setConfigAutorizacao,
  tipo_users,
  errors,
}) => {
  const handleChange = (event) => {
    const { name, value, files } = event.target;
    setConfigAutorizacao({
      ...config_autorizacao,
      [name]: files ? files[0] : value,
    });
  };

  return (
    <Form id="form_config_autorizacao" onSubmit={handleSubmit} schema={schema}>
      <div className="form-group">
        <label htmlFor="nivel_autorizacao">Nivel de autorização</label>
        <Form.Control
          id="nivel_autorizacao"
          isInvalid={!!errors.nivel_autorizacao}
          name="nivel_autorizacao"
          onChange={handleChange}
          placeholder="Nível de autorização"
          type="number"
          value={config_autorizacao.nivel_autorizacao || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.nivel_autorizacao}
        </Form.Control.Feedback>
      </div>

      <div className="form-group">
        <label htmlFor="nomeclatura"> Nomeclatura </label>
        <Form.Control
          id="nomeclatura"
          isInvalid={!!errors.nomeclatura}
          name="nomeclatura"
          onChange={handleChange}
          placeholder="Ex: gerente, secretário."
          type="text"
          value={config_autorizacao.nomeclatura || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.nomeclatura}
        </Form.Control.Feedback>
      </div>

      <div className="form-group">
        <label htmlFor="obrigatoria">Obrigatória</label>
        <Form.Control
          as="select"
          id="obrigatoria"
          isInvalid={!!errors.obrigatoria}
          name="obrigatoria"
          onChange={handleChange}
          placeholder="Obrigatório?"
          value={String(Number(config_autorizacao.obrigatoria))}
        >
          <option value="1"> SIM</option>
          <option value="0">NÃO</option>
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {errors.obrigatoria}
        </Form.Control.Feedback>
      </div>

      <Form.Group>
        <Form.Label>Tipo de usuário</Form.Label>
        <Form.Control
          as="select"
          isInvalid={!!errors.tipo_user_id}
          name="tipo_user_id"
          onChange={handleChange}
          placeholder="Tipo de usuário"
          type="text"
          value={
            config_autorizacao.tipo_user_id
              ? config_autorizacao.tipo_user_id
              : 3
          }
        >
          {tipo_users?.data?.map((tipo) => (
            <option key={tipo.id} value={tipo.id}>
              {tipo.nome}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {errors.tipo_user_id}
        </Form.Control.Feedback>
      </Form.Group>
    </Form>
  );
};

export default FormCad;
