import * as actions from './actionType';

export const loadTipoLicencas = params => ({
  type: actions.LOAD_TIPO_LICENCAS,
  params
});
export const getTipoLicencas = tipo_licencas => ({
  type: actions.TIPO_LICENCAS_DATA,
  tipo_licencas,
});

export const getSelectTipoLicenca = tipo_licencas => ({
  type: actions.SELECT_TIPO_LICENCAS,
  tipo_licencas,
});

export const storeTipoLicenca = tipo_licenca => ({
  type: actions.STORE_TIPO_LICENCA,
  tipo_licenca,

});
export const getTipoLicenca = tipo_licenca => ({
  type: actions.GET_TIPO_LICENCA,
  tipo_licenca,
});

export const removerTipoLicenca = (id) => ({
  type: actions.REMOVER_TIPO_LICENCA,
  id
});

export const showTipoLicenca = (id, params) => ({
  type: actions.SHOW_TIPO_LICENCA,
  id,
  params
});

export const updateTipoLicenca = (tipo_licenca, id) => ({
  type: actions.UPDATE_TIPO_LICENCA,
  tipo_licenca,
  id
});

export const getErros = errors => ({
  type: actions.TIPO_LICENCA_ERROR,
  errors,
});

export const changeStatus = status => ({
  type: actions.CHANGE_STATUS_CODE,
  status,
});

export const ativarTipoLicenca = id => ({
  type: actions.ACTIVATE_TIPO_LICENCA,
  id,
})
