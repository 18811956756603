export const LOAD_INDICADOR_AMBIENTAL = 'LOAD_INDICADOR_AMBIENTAL';
export const INDICADOR_AMBIENTAL_ERROR = 'INDICADOR_AMBIENTAL_ERROR';
export const INDICADOR_AMBIENTAL_DATA = 'INDICADOR_AMBIENTAL_DATA';
export const STORE_INDICADOR_AMBIENTAL = 'STORE_INDICADOR_AMBIENTAL';
export const GET_INDICADOR_AMBIENTAL = 'GET_INDICADOR_AMBIENTAL';
export const SHOW_INDICADOR_AMBIENTAL = 'SHOW_INDICADOR_AMBIENTAL';
export const DESATIVAR_INDICADOR_AMBIENTAL = 'DESATIVAR_INDICADOR_AMBIENTAL';
export const CHANGE_STATUS_CODE = 'CHANGE_STATUS_CODE';
export const UPDATE_INDICADOR_AMBIENTAL = 'UPDATE_INDICADOR_AMBIENTAL';
export const ACTIVATE_INDICADOR_AMBIENTAL = 'ACTIVATE_INDICADOR_AMBIENTAL';
