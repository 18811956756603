import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { Content } from '~/layouts/Dash/components';
import { ModalEstudosAtividade, ModalDescritivoAtividade, ModalProjeto } from './components';

const Projeto = () => {
  const breadcrumbs = [
    {
      label: 'Distribuição',
      to: '/distribuicao',
      active: false
    },
    {
      label: 'Projeto',
      to: '/distribuicao/projeto',
      active: true
    }
  ];
  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Projeto do Processo 01-15-2019-13.845.598.0001.96-01"
    >
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">
            PROJETO GERAL DO EMPREENDIMENTO OU ATIVIDADE
          </h3>
          <div className="card-tools">
            <Link
              className="btn btn-default"
              to="/distribuicao"
            >
              <FaArrowAltCircleLeft /> Voltar
            </Link>
          </div>
        </div>
        <div className="card-body">
          <button
            className="btn btn-primary btn-block"
            data-target="#modal-projeto"
            data-toggle="modal"
          >
            PROJETO DO EMPREENDIMENTO OU ATIVIDADE
          </button>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <h3 className="card-title">
            MEMORIAL DESCRITIVO DA ATIVIDADE A SER EXECUTADA
          </h3>
        </div>
        <div className="card-body">
          <button
            className="btn btn-primary btn-block"
            data-target="#modal-descritivo-atividade"
            data-toggle="modal"
          >
            PREENCHA OS CAMPOS
          </button>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <h3 className="card-title">
            ELABORE OS ESTUDOS AMBIENTAIS REFERENTES À ATIVIDADE OU
            EMPREENDIMENTO
          </h3>
        </div>
        <div className="card-body">
          <button
            className="btn btn-primary btn-block"
            data-target="#modal-estudos-atividade"
            data-toggle="modal"
          >
            IDENTIFIQUE E PREENCHA OS ESTUDOS AMBIENTAIS SELECIONADOS
          </button>
        </div>
      </div>
      <ModalEstudosAtividade/>
      <ModalDescritivoAtividade/>
      <ModalProjeto/>
    </Content>
  );
};

export default Projeto;
