import React, { useEffect, useState, useCallback } from 'react';
import { useParams, Switch, Route, useHistory, useLocation, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { Loading } from '~/components';
import Swal from 'sweetalert2';
import { showTipoMeioAnalise } from '~/store/modules/tipo_meio_analise/action';
import { loadCondicionantes } from '~/store/modules/condicionante/action';
import { BasicContent } from '../components';
import { SubMenuList } from '../components/SubMenu';
import CreateCondicionante from '../../Condicionante/Create';
import CreateQuestaoAnalise from '../../QuestaoAnalise/Create';
import EditQuestaoAnalise from '../../QuestaoAnalise/Edit';
import { loadQuestaoAnalises,  removerQuestaoAnalise, ativarQuestaoAnalise } from '~/store/modules/questao_analise/action';
import ImportQuestaoAnalise from '../../QuestaoAnalise/ImportQuestaoAnalise';

const Show = () => {
  let history = useHistory();
  let location = useLocation();
  let modal = location.state && location.state.modal;
  const dispatch = useDispatch();
  const { tipo_meio_analise_id } = useParams();
  const [tipo_meio_analiseShow, setTipo_meio_analiseShow] = useState({});

  const { tipo_meio_analise } = useSelector(({ TipoMeioAnaliseReducer }) => TipoMeioAnaliseReducer);

  const { questao_analises } = useSelector(({ QuestaoAnaliseReducer }) => QuestaoAnaliseReducer);


  useEffect(() => {
    dispatch(showTipoMeioAnalise(tipo_meio_analise_id, {
      with: 'meio_analise.tipo_licenca,meio_analise.tipo_licenca.atividade,meio_analise.tipo_licenca.atividade.entidade',
      with_trashed: true
    }));

    dispatch(loadCondicionantes({
      where:`tipo_meio_analise_id:${tipo_meio_analise_id}`
    }));

    dispatch(loadQuestaoAnalises({
      where:`tipo_meio_analise_id:${tipo_meio_analise_id}`,
      with_trashed: true
    }));



  }, [dispatch, tipo_meio_analise_id]);

  useEffect(() => {
    if (tipo_meio_analise.id === parseInt(tipo_meio_analise_id)) {
      setTipo_meio_analiseShow(tipo_meio_analise);
    }
  }, [tipo_meio_analise, tipo_meio_analise_id]);

  const breadcrumb = [
    {
      label: 'Entidade',
      to: `/entidade/${tipo_meio_analiseShow?.meio_analise?.tipo_licenca?.atividade?.entidade_id}/detalhe`,
      active: true,
    },
    {
      label: 'Atividade',
      to: `/atividade/${tipo_meio_analiseShow?.meio_analise?.tipo_licenca?.atividade_id}/detalhe`,
      active: true,
    },
    {
      label: 'Tipo Licença',
      to: `/tipo_licenca/${tipo_meio_analiseShow?.meio_analise?.tipo_licenca_id}/detalhe`,
      active: true,
    },
    {
      label: 'Meio de analise',
      to: `/meio_analise/${tipo_meio_analiseShow?.meio_analise_id}/detalhe`,
      active: true,
    },
    {
      label: 'Impacto ambientais',
      to: `/tipo_meio_analise/${tipo_meio_analise_id}/detalhe`,
      active: false,
    },
    {
      label: 'Detalhes',
      to: `/tipo_meio_analise/${tipo_meio_analise_id}/detalhe`,
      active: true,
    }];

  const handleRemoveQuestaoAnalise = useCallback((id => {
    Swal.fire({
      title: 'Desativar?',
      text: 'Deseja desativar esse item?!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        try {
          await dispatch(removerQuestaoAnalise(id));
          await dispatch(loadQuestaoAnalises({
            where:`tipo_meio_analise_id:${tipo_meio_analise_id}`,
            with_trashed: true
          }));
          await Swal.fire('Sucesso',
            'Desativado com sucesso!',
            'success');
          await history.go();
        } catch (error) {
          await Swal.fire('Erro',
            'Erro ao Desativar',
            'error');
        }
      }
    })
  }),[dispatch, tipo_meio_analise_id, history]);
  
  const handleActivateQuestaoAnalise = useCallback((id => {
    Swal.fire({
      title: 'Ativar?',
      text: 'Deseja ativar esse item?!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        try {
          await dispatch(ativarQuestaoAnalise(id));
          await dispatch(loadQuestaoAnalises({
            where:`tipo_meio_analise_id:${tipo_meio_analise_id}`,
            with_trashed: true
          }));
          await Swal.fire('Sucesso',
            'Ativado com sucesso!',
            'success');
          await history.go();
        } catch (error) {
          await Swal.fire('Erro',
            'Erro ao ativar',
            'error');
        }
      }
    })
  }),[dispatch, tipo_meio_analise_id, history]);

  return (
    <>
      {modal && (
        <Switch>
          <Route
            children={<CreateCondicionante atividade_id={tipo_meio_analiseShow?.meio_analise?.tipo_licenca?.atividade_id} />}
            path={'/tipo_meio_analise/:tipo_meio_analise_id/condicionante/novo'}
          />
          <Route
            children={
              <CreateQuestaoAnalise
                atividade_id={tipo_meio_analiseShow?.meio_analise?.tipo_licenca?.atividade_id}
                tipo_meio_analise={tipo_meio_analiseShow?.id}
              />}
            path={'/tipo_meio_analise/:tipo_meio_analise_id/questao_analise/novo'}
          />
          <Route
            children={<ImportQuestaoAnalise />}
            path={'/tipo_meio_analise/:tipo_meio_analise_id/QuestaoAnalises/import_data'}
          />

          <Route
            children={
              <EditQuestaoAnalise
                atividade_id={tipo_meio_analiseShow?.meio_analise?.tipo_licenca?.atividade_id}
                tipo_meio_analise={tipo_meio_analiseShow?.id}
              />}
            path={'/tipo_meio_analise/:tipo_meio_analise_id/questao_analise/:questao_id/editar'}
          />
        </Switch>
      )}
      <BasicContent breadcrumb={breadcrumb}>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Detalhes</h2>
            <div className="card-tools">
              <Link
                className="btn btn-default"
                to={`/meio_analise/${tipo_meio_analiseShow?.meio_analise_id}/detalhe`}
              >
                <FaArrowAltCircleLeft /> Voltar
              </Link>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              {tipo_meio_analiseShow.id === parseInt(tipo_meio_analise_id) ? (
                <table className="table table-hover table-striped">
                  <tbody>
                    <tr>
                      <th>Entidade</th>
                      <td>
                        <Link to={`/entidade/${tipo_meio_analiseShow?.meio_analise?.tipo_licenca?.atividade?.entidade_id}/detalhe`}>
                          {tipo_meio_analiseShow?.meio_analise?.tipo_licenca?.atividade?.entidade?.nome}</Link>
                      </td>
                    </tr>
                    <tr>
                      <th>Atividade</th>
                      <td>
                        <Link to={`/atividade/${tipo_meio_analiseShow?.meio_analise?.tipo_licenca?.atividade_id}/detalhe`}>
                          {tipo_meio_analiseShow?.meio_analise?.tipo_licenca?.atividade?.nome}</Link>
                      </td>
                    </tr>
                    <tr>
                      <th>Tipo Licença</th>
                      <td>
                        <Link to={`/tipo_licenca/${tipo_meio_analiseShow?.meio_analise?.tipo_licenca_id}/detalhe`}>
                          {tipo_meio_analiseShow?.meio_analise?.tipo_licenca?.nome}</Link>
                      </td>
                    </tr>
                    <tr>
                      <th>Meio de analise</th>
                      <td>
                        <Link to={`/meio_analise/${tipo_meio_analiseShow?.meio_analise_id}/detalhe`}>
                          {tipo_meio_analiseShow?.meio_analise?.nome}</Link>
                      </td>
                    </tr>

                    <tr>
                      <th>id</th>
                      <td>{tipo_meio_analiseShow.id}</td>
                    </tr>
                    <tr>
                      <th>Nome</th>
                      <td>{tipo_meio_analiseShow.nome}</td>
                    </tr>
                    <tr>
                      <th>Descrição</th>
                      <td>{tipo_meio_analiseShow.descricao}</td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <Loading />
              )}
            </div>
          </div>
        </div>
        {/* {condicionantes.data ? (
          <>
            <SubMenuList
              action={loadCondicionantes}
              createPath={'/tipo_meio_analise/:tipo_meio_analise_id/condicionante/novo'}
              createUrl={`/tipo_meio_analise/${tipo_meio_analise_id}/condicionante/novo`}
              displayName={'tipo_meio_analise_id'}
              id={tipo_meio_analise_id}
              labelCol1={'Id'}
              labelCol2={'Nome'}
              labelCol3={'Tipo'}
              title={'Condicionantes'}
              valueCol1={'id'}
              valueCol2={'nome'}
              valueCol3={'especifica'}
              values={condicionantes}
            />
          </>
        ) : (
          <div className="card">
            <div className="card-header">
              <h2 className="card-title">Condicionantes</h2>
            </div>
            <div className="card-body">
              <Loading />
            </div>
          </div>
        )} */}



        {questao_analises.data ? (
          <>
            <SubMenuList
              action={loadQuestaoAnalises}
              createPath={'/tipo_meio_analise/:tipo_meio_analise_id/questao_analise/novo'}
              createUrl={`/tipo_meio_analise/${tipo_meio_analise_id}/questao_analise/novo`}
              detalhe
              displayName={'tipo_meio_analise_id'}
              edit_questao
              handleActivate={handleActivateQuestaoAnalise}
              handleRemove={handleRemoveQuestaoAnalise}
              id={tipo_meio_analise_id}
              labelCol1={'Id'}
              labelCol2={'Enunciado'}
              path_detalhe={'/questao_analise/*/detalhe'}
              importDataUrl={`/tipo_meio_analise/${tipo_meio_analise_id}/QuestaoAnalises/import_data`}
              title={'Questões para APIA e ATIA'}
              valueCol1={'id'}
              valueCol2={'nome'}
              values={questao_analises}
            />
          </>
        ) : (
          <div className="card">
            <div className="card-header">
              <h2 className="card-title">Questões para analise</h2>
            </div>
            <div className="card-body">
              <Loading />
            </div>
          </div>
        )}
      </BasicContent>
    </>
  );
};

export default Show;
