import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import formatForMask from '~/utils/formatForMask';
import mask from '~/utils/formatCpfCnpj';
import { loadEstados } from '~/store/modules/estado/action';
import { loadListForAdd } from '~/services/ResponsavelTecnico';
import { Loading } from '~/components';


const FormCad = ({
  handleSubmit,
  schema,
  responsavel_tecnico,
  setResponsavelTecnico,
  errors,
  edit
}) => {

  const dispatch = useDispatch();
  const { estados } = useSelector(({ EstadoReducer }) => EstadoReducer);
  const [userOk, setUserOk] = useState(false);
  const [onSelect, setOnSelect] = useState(true);
  const [ estadoSelect, setEstadoSelect ] = useState();
  const [loading, setLoading] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [cpfCnpjData, setCpfCnpjData] = useState('');
  const [options, setOptions] = useState();


  const handleSearch = async () => {
    let cpfCnpjFormated = '';

    if((/(.*?\d){3}/).test(cpfCnpjData)){
      cpfCnpjFormated = cpfCnpjData.replace(/[./-]/g, '');
    }

    try {
      setLoading(true);
      const res = await loadListForAdd({
        search_uf_endereco_id: estadoSelect,
        search_name: searchName,
        search_cpf_cnpj: cpfCnpjFormated,
      });

      const responsavel_tecnico = res.data.data;
      // console.log(responsavel_tecnico);
      if (responsavel_tecnico) {
        let label = '';
        let options = [];
        responsavel_tecnico.forEach(({ user, nome, cpf, cnpj, formacao, cidade, estado_endereco }) => {
          if(cpf) label = formatLabel(nome, cpf, formacao, 'CPF', '###.###.###-##');
          if(cnpj) label = formatLabel(nome, cnpj, formacao, 'CNPJ', '##.###.###/####-##');
          options.push({
            value: user.id,
            label: `${label} - ${cidade}/${estado_endereco.uf}`,
          });
        });
        setOptions(options);
      }
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }finally{
      setLoading(false);
    }
  }
  useEffect(() => {
    if(edit){
      setUserOk(true);
    }
  },[edit]);

  useEffect(() => {
    dispatch(loadEstados({
      raw_list:false
    }));
  },[dispatch]);

  const formatLabel = (nomeUser, docUser, formacao, docSigla, maskLabel) => {
    return `${nomeUser} - ${docSigla}: ${formatForMask(docUser, maskLabel)} - Formação: ${formacao}`;
  }

  const selectEstado = (event) =>{
    if (event.target.value) {
      setEstadoSelect(event.target.value);
      setOnSelect(false);
    }
  }

  const handleChange = (event) => {
    const { name, value} = event.target;
    setResponsavelTecnico({
      ...responsavel_tecnico,
      [name] : value,
    });
  };

  const handleChangeUser = (user) => {
    setResponsavelTecnico({
      ...responsavel_tecnico,
      responsavel_tecnico_id: user.value,
    });
    setUserOk(true);
  };

  return (
    <Form
      id="form_responsavel_tecnico"
      onSubmit={handleSubmit}
      schema={schema}
    >
      {edit && (
        <table className="table table-hover table-striped">
          <tbody>
            <tr>
              <th>id</th>
              <td>{responsavel_tecnico.id}</td>
            </tr>
            <tr>
              <th>Nome</th>
              <td>{responsavel_tecnico.responsavel_tecnico?.nome}</td>
            </tr>
            <tr>
              <th>CNPJ ou CPF</th>
              <td>
                {responsavel_tecnico.responsavel_tecnico
                  ?.pessoa_tecnico_profissional.cnpj ? formatForMask(responsavel_tecnico.responsavel_tecnico
                    ?.pessoa_tecnico_profissional.cnpj, '##.###.###/####-##'
                  ) : ''}
                {responsavel_tecnico.responsavel_tecnico
                  ?.pessoa_tecnico_profissional.cpf ? formatForMask(responsavel_tecnico.responsavel_tecnico
                    ?.pessoa_tecnico_profissional.cpf, '###.###.###-##') : ''}
              </td>
            </tr>

            <tr>
              <th>Email</th>
              <td>{responsavel_tecnico.responsavel_tecnico?.email}</td>
            </tr>
          </tbody>
        </table>
      )}
      {!edit && (
        <>
          <div className="row">
            <div className="form-group col-md-2">
              <label htmlFor="estado_id">Estado</label>
              <Form.Control
                as="select"
                id="estado_id"
                isInvalid={!!errors.estado_id}
                name="estado_id"
                onChange={selectEstado}
                type="number"
                value={estadoSelect}
              >
                <option value="">
                .::Selecione::.
                </option>
                {estados.data && estados.data.map(estado => (
                  <option
                    key={estado.id}
                    value={estado.id ?? ''}
                  >
                    {estado.nome}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.estado_id}
              </Form.Control.Feedback>
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="nome">CPF/CNPJ</label>
              <Form.Control
                id="cpf_cnpj"
                name="cpf_cnpj"
                onChange={(event) => setCpfCnpjData(mask(event.target.value))}
                type="text"
                value={cpfCnpjData}
              />
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="nome">Nome</label>
              <Form.Control
                id="nome"
                name="nome"
                onChange={(event) => setSearchName(event.target.value)}
                type="text"
                value={searchName}
              />
            </div>
            <div className="form-group col-md-3 d-flex align-items-end">
              {!!options && (
                <button
                  className="btn btn-primary mr-1"
                  onClick={() => {
                    setResponsavelTecnico(prevValue => ({
                      ...prevValue,
                      responsavel_tecnico_id: undefined
                    }));
                    setUserOk(false);
                    setOptions(undefined);
                    setSearchName('');
                    setCpfCnpjData('');
                    setEstadoSelect('');
                  }}
                  type="button"
                >
                  <i className="fa fa-times" /> Limpar
                </button>
              )}
              <button
                className="btn btn-success mr-1"
                disabled={onSelect && !cpfCnpjData.length && !searchName.length}
                onClick={handleSearch}
                type="button"
              >
                <i className="fa fa-search" /> Pesquisar
              </button>

            </div>
          </div>
          <hr/>
        </>
      )}

      {loading && (
        <Loading />
      )}

      {options?.length > 0 && !loading && !edit ? (
        <div>
          <div className="form-group">
            <label htmlFor="nome">Selecione o técnico consultor</label>
            <Select
              className={!!errors.responsavel_tecnico_id}
              menuIsOpen={!userOk}
              onChange={handleChangeUser}
              openMenuOnClick
              options={options}
            />
            <div className="invalid-feedback">
              {errors.responsavel_tecnico_id}
            </div>
          </div>
        </div>
      ): null}

      {options?.length === 0 && !loading && !edit && (
        <h4 style={{ textAlign: 'center' }}>Sem resultados!</h4>
      )}


      {userOk && (
        <table className="table table-hover table-striped">
          <thead>
            <tr>
              <th
                className="text-center"
                colSpan="2"
              >
                Tipo de Anotação Técnica
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Elaboração de Estudo e Projeto</th>
              <td>
                <select
                  className={`form-control ${
                    errors.pode_elaboracao_e_estudo_projeto && 'is-invalid'
                  }`}
                  name="pode_elaboracao_e_estudo_projeto"
                  onChange={handleChange}
                  value={responsavel_tecnico.pode_elaboracao_e_estudo_projeto}
                >
                  <option value="true">SIM</option>
                  <option value="false">NÃO</option>
                </select>
                <div className="invalid-feedback">
                  {errors.pode_elaboracao_e_estudo_projeto}
                </div>
              </td>
            </tr>

            <tr>
              <th>Elaboração de Orçamento</th>
              <td>
                <select
                  className={`form-control ${
                    errors.pode_elaboracao_orcamento && 'is-invalid'
                  }`}
                  name="pode_elaboracao_orcamento"
                  onChange={handleChange}
                  value={responsavel_tecnico.pode_elaboracao_orcamento}
                >
                  <option value="true">SIM</option>
                  <option value="false">NÃO</option>
                </select>
                <div className="invalid-feedback">
                  {errors.pode_elaboracao_orcamento}
                </div>
              </td>
            </tr>
            <tr>
              <th>Implantação de Estudo e Projeto</th>
              <td>
                <select
                  className={`form-control ${
                    errors.pode_implantacao_estudo_e_projeto && 'is-invalid'
                  }`}
                  name="pode_implantacao_estudo_e_projeto"
                  onChange={handleChange}
                  value={responsavel_tecnico.pode_implantacao_estudo_e_projeto}
                >
                  <option value="true">SIM</option>
                  <option value="false">NÃO</option>
                </select>
                <div className="invalid-feedback">
                  {errors.pode_implantacao_estudo_e_projeto}
                </div>
              </td>
            </tr>
            <tr>
              <th>Execução de Estudo e Projeto</th>
              <td>
                <select
                  className={`form-control ${
                    errors.pode_execucao_estudo_e_projeto && 'is-invalid'
                  }`}
                  name="pode_execucao_estudo_e_projeto"
                  onChange={handleChange}
                  value={responsavel_tecnico.pode_execucao_estudo_e_projeto}
                >
                  <option value="true">SIM</option>
                  <option value="false">NÃO</option>
                </select>
                <div className="invalid-feedback">
                  {errors.pode_execucao_estudo_e_projeto}
                </div>
              </td>
            </tr>
            <tr>
              <th>Fiscalização de Estudo e Projeto</th>
              <td>
                <select
                  className={`form-control ${
                    errors.pode_fiscalizacao_estudo_e_projeto && 'is-invalid'
                  }`}
                  name="pode_fiscalizacao_estudo_e_projeto"
                  onChange={handleChange}
                  value={responsavel_tecnico.pode_fiscalizacao_estudo_e_projeto}
                >
                  <option value="true">SIM</option>
                  <option value="false">NÃO</option>
                </select>
                <div className="invalid-feedback">
                  {errors.pode_fiscalizacao_estudo_e_projeto}
                </div>
              </td>
            </tr>
            <tr>
              <th>Monitoramento de Estudo e Projeto</th>
              <td>
                <select
                  className={`form-control ${
                    errors.pode_monitoramento_estudo_e_projeto && 'is-invalid'
                  }`}
                  name="pode_monitoramento_estudo_e_projeto"
                  onChange={handleChange}
                  value={responsavel_tecnico.pode_monitoramento_estudo_e_projeto}
                >
                  <option value="true">SIM</option>
                  <option value="false">NÃO</option>
                </select>
                <div className="invalid-feedback">
                  {errors.pode_monitoramento_estudo_e_projeto}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </Form>
  );
};

export default FormCad;
