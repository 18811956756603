import React from 'react';
import { Content } from '~/layouts/Dash/components';


const NotFound = () => {

  const breadcrumbs = [
    {
      label: 'Home',
      to: '/404',
      active: false,
    },
    {
      label: '404 Error Page',
      to: '/404',
      active: true,
    },
  ];
  return (
    <Content breadcrumbs={breadcrumbs} title="404 Error Page">
      <div className="error-page">
        <h2 className="headline text-warning"> 404</h2>

        <div className="error-content">
          <h3>
            <i className="fas fa-exclamation-triangle text-warning" /> Oops!
            Página não encontrada.
          </h3>

          <p>
            Não foi possível encontrar a página que você estava procurando. .
            Enquanto isso, você pode retornar ao painel ou tentar usar o
            formulário de pesquisa.
          </p>

          <form className="search-form">
            <div className="input-group">
              <input
                className="form-control"
                name="search"
                placeholder="Buscar"
                type="text"
              />

              <div className="input-group-append">
                <button className="btn btn-warning" name="submit" type="submit">
                  <i className="fas fa-search" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Content>
  );
}

export default NotFound;
