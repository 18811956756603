import React from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const FormCad = ({ handleSubmit, schema, valorReferencia, setValorReferenciaEdit, errors }) => {

  const { porte_empreendimentos } = useSelector(({ PorteEmpreendimentoReducer }) => PorteEmpreendimentoReducer);

  const formatMoney = (e) => {
    let tmp = parseInt(e.target.value.replace(/[\D]+/g, ''));
    if (tmp) {

      tmp = (String(tmp).replace(/([0-9]{2})$/g, ',$1'));
      if (tmp.length === 1) {

        tmp = `0,0${tmp}`
      }
      if (tmp.length === 3) {

        tmp = `0${tmp}`
      }

      if (tmp.length > 6) {
        tmp = String(tmp).replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
      }
    } else {
      tmp = '0,00'
    }


    setValorReferenciaEdit({
      ...valorReferencia,
      [e.target.name]:  tmp,
    })
  }
  const handleChange = event => {
    const { name, value, files } = event.target;
    setValorReferenciaEdit({
      ...valorReferencia,
      [name]: files ? files[0] : value,
    });
  };

  return (
    <Form
      id="form_valor_referencia"
      onSubmit={handleSubmit}
      schema={schema}
    >
       <div className="form-group">
        <label htmlFor="porte_empreendimento_id">Porte do Empreendimento</label>
        <Form.Control
          as="select"
          id="porte_empreendimento_id"
          isInvalid={!!errors.porte_empreendimento_id}
          name="porte_empreendimento_id"
          onChange={handleChange}
          type="number"
          value={valorReferencia?.porte_empreendimento_id || ''}
        >
          {porte_empreendimentos.data &&
            porte_empreendimentos.data.map(porte_empreendimento => (
              <option
                key={porte_empreendimento.id}
                value={porte_empreendimento.id}
              >
                {porte_empreendimento.nome}
              </option>
            ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {errors.porte_empreendimento}
        </Form.Control.Feedback>
      </div>

      <div className="form-group">
        <label className="label">Valor</label>
        <Form.Control
          isInvalid={!!errors.valor}
          maxLength={10}
          name="valor"
          onChange={formatMoney}
          required
          type="text"
          value={valorReferencia.valor || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.valor}
        </Form.Control.Feedback>
      </div>
    </Form>
  );
};

export default FormCad;
