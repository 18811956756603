import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Row, Col } from 'react-bootstrap';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { FiCheckCircle } from 'react-icons/fi';
import { BoxDeclaraRespo, BoxTermos } from '../../styles';
import schema from './schemaValidations';
import buscaCep from '~/services/cep';
import { useSelector } from 'react-redux';
import TermoUso from '../../../TermoUso/TermoUso';
import FileInput from '~/components/FileInput';
import formatCpfCnpj from '~/utils/formatCpfCnpj';
import formatCep from '~/utils/formatCep';
import formatPhone from '~/utils/formatPhone';

const FormCad = ({ onHandleSubmit, pessoa, setPessoa, errors }) => {
    const { uf_data } = useSelector(({ UfDataReducer }) => UfDataReducer);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setPessoa({
            ...pessoa,
            [name]: value,
        });
    };

    const handleBuscaCep = async (event) => {
        const { value } = event.target;
        const cep = value;
        try {
            const data = await buscaCep.get(`${cep}/json`);
            const endereco = data.data;
            const finded = {
                logradouro: endereco.logradouro,
                bairro: endereco.bairro,
                cidade: endereco.localidade,
            };
            Object.assign(pessoa, finded);
            setPessoa({
                ...pessoa,
            });
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <div className="card">
            <div className="card-body">
                <Form onSubmit={onHandleSubmit} schema={schema}>
                    <Form.Group controlId="nome">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.nome}
                            name="nome"
                            onChange={handleChange}
                            placeholder="Nome"
                            type="text"
                            value={pessoa.nome}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.nome}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={6}>
                            <Form.Label>CPF/CNPJ (Somente números)</Form.Label>
                            <Form.Control
                                isInvalid={!!errors.cpf_cnpj}
                                name="cpf_cnpj"
                                onChange={handleChange}
                                placeholder="CPF/CNPJ"
                                type="text"
                                maxLength="18"
                                minLength="14"
                                value={formatCpfCnpj(pessoa.cpf_cnpj ?? '')}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.cpf_cnpj}
                            </Form.Control.Feedback>
                        </Col>
                        <Col>
                            <Form.Label>Anexo</Form.Label>
                            <FileInput
                                dataError={errors.anexo_cpf_cnpj}
                                name="anexo_cpf_cnpj"
                                data={pessoa}
                                setData={setPessoa}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.anexo_cpf_cnpj}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={6}>
                            <Form.Label>RG (Somente números)</Form.Label>
                            <Form.Control
                                isInvalid={!!errors.rg}
                                name="rg"
                                onChange={handleChange}
                                placeholder="RG (Somente números)"
                                type="text"
                                value={pessoa.rg}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.rg}
                            </Form.Control.Feedback>
                        </Col>
                        <Col>
                            <Form.Label>Anexo</Form.Label>
                            <FileInput
                                dataError={errors.anexo_rg}
                                name="anexo_rg"
                                data={pessoa}
                                setData={setPessoa}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.anexo_rg}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>RG UF</Form.Label>
                        <Form.Control
                            as="select"
                            isInvalid={!!errors.uf_rg_id}
                            name="uf_rg_id"
                            onChange={handleChange}
                            placeholder="UF RG"
                            type="text"
                            value={pessoa.uf_rg_id}
                        >
                            {uf_data[0] &&
                                uf_data.map((uf) => (
                                    <option key={uf.id} value={uf.id}>
                                        {uf.uf}
                                    </option>
                                ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {errors.uf_rg_id}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Data Nascimento</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.data_nasc}
                            name="data_nasc"
                            onChange={handleChange}
                            placeholder="Data Nascimento"
                            type="date"
                            value={pessoa.data_nasc}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.data_nasc}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>CEP</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.cep}
                            name="cep"
                            onBlur={handleBuscaCep}
                            onChange={handleChange}
                            placeholder="CEP"
                            type="text"
                            maxLength="9"
                            value={formatCep(pessoa.cep ?? '')}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.cep}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={6}>
                            <Form.Label>Logradouro</Form.Label>
                            <Form.Control
                                isInvalid={!!errors.logradouro}
                                name="logradouro"
                                onChange={handleChange}
                                placeholder="Logradouro"
                                type="text"
                                value={pessoa.logradouro}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.logradouro}
                            </Form.Control.Feedback>
                        </Col>
                        <Col>
                            <Form.Label>Anexo</Form.Label>
                            <FileInput
                                dataError={errors.anexo_endereco}
                                name="anexo_endereco"
                                data={pessoa}
                                setData={setPessoa}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.anexo_endereco}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Número</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.numero_endereco}
                            name="numero_endereco"
                            onChange={handleChange}
                            placeholder="Número"
                            type="text"
                            value={pessoa.numero_endereco}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.numero_endereco}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Bairro</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.bairro}
                            name="bairro"
                            onChange={handleChange}
                            placeholder="Bairro"
                            type="text"
                            value={pessoa.bairro}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.bairro}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Cidade</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.cidade}
                            name="cidade"
                            onChange={handleChange}
                            placeholder="Cidade"
                            type="text"
                            value={pessoa.cidade}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.cidade}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>UF</Form.Label>
                        <Form.Control
                            as="select"
                            isInvalid={!!errors.uf_endereco_id}
                            name="uf_endereco_id"
                            onChange={handleChange}
                            placeholder="UF"
                            type="text"
                            value={pessoa.uf_endereco_id}
                        >
                            {uf_data[0] &&
                                uf_data.map((uf) => (
                                    <option key={uf.id} value={uf.id}>
                                        {uf.uf}
                                    </option>
                                ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {errors.uf_endereco_id}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Fone de contato</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.telefone}
                            name="telefone"
                            onChange={handleChange}
                            placeholder="Fone de contato"
                            type="text"
                            maxLength="15"
                            value={formatPhone(pessoa.telefone ?? '')}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.telefone}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Número de WhatsApp</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.numero_whatsapp}
                            name="numero_whatsapp"
                            onChange={handleChange}
                            placeholder="Número de WhatsAp"
                            type="text"
                            maxLength="15"
                            value={formatPhone(pessoa.numero_whatsapp ?? '')}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.numero_whatsapp}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Youtube</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.youtube}
                            name="youtube"
                            onChange={handleChange}
                            placeholder="Youtube"
                            type="text"
                            value={pessoa.youtube}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.youtube}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Facebook</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.facebook}
                            name="facebook"
                            onChange={handleChange}
                            placeholder="Facebook"
                            type="text"
                            value={pessoa.facebook}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.facebook}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Linkedin</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.linkdin}
                            name="linkdin"
                            onChange={handleChange}
                            placeholder="Linkedin"
                            type="text"
                            value={pessoa.linkdin}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.linkdin}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Twitter</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.twiter}
                            name="twiter"
                            onChange={handleChange}
                            placeholder="Twitter"
                            type="text"
                            value={pessoa.twiter}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.twiter}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={6}>
                            <Form.Label>Formação</Form.Label>
                            <Form.Control
                                isInvalid={!!errors.formacao}
                                name="formacao"
                                onChange={handleChange}
                                placeholder="Formação"
                                type="text"
                                value={pessoa.formacao}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.formacao}
                            </Form.Control.Feedback>
                        </Col>
                        <Col>
                            <Form.Label>Anexo</Form.Label>
                            <FileInput
                                dataError={errors.anexo_formacao}
                                name="anexo_formacao"
                                data={pessoa}
                                setData={setPessoa}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.anexo_formacao}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={6}>
                            <Form.Label>Formação 2 (caso tenha)</Form.Label>
                            <Form.Control
                                isInvalid={!!errors.formacao_2}
                                name="formacao_2"
                                onChange={handleChange}
                                placeholder="Formação 2 (caso tenha)"
                                type="text"
                                value={pessoa.formacao_2}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.formacao_2}
                            </Form.Control.Feedback>
                        </Col>
                        <Col>
                            <Form.Label>Anexo</Form.Label>
                            <FileInput
                                dataError={errors.anexo_formacao_2}
                                name="anexo_formacao_2"
                                data={pessoa}
                                setData={setPessoa}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.anexo_formacao_2}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={6}>
                            <Form.Label>Especialização</Form.Label>
                            <Form.Control
                                isInvalid={!!errors.especializacao}
                                name="especializacao"
                                onChange={handleChange}
                                placeholder="Especialização"
                                type="text"
                                value={pessoa.especializacao}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.especializacao}
                            </Form.Control.Feedback>
                        </Col>
                        <Col>
                            <Form.Label>Anexo</Form.Label>
                            <FileInput
                                dataError={errors.anexo_especializacao}
                                name="anexo_especializacao"
                                data={pessoa}
                                setData={setPessoa}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.anexo_especializacao}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={6}>
                            <Form.Label>Mestrado</Form.Label>
                            <Form.Control
                                isInvalid={!!errors.mestrado}
                                name="mestrado"
                                onChange={handleChange}
                                placeholder="Mestrado"
                                type="text"
                                value={pessoa.mestrado}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.mestrado}
                            </Form.Control.Feedback>
                        </Col>
                        <Col>
                            <Form.Label>Anexo</Form.Label>
                            <FileInput
                                dataError={errors.anexo_mestrado}
                                name="anexo_mestrado"
                                data={pessoa}
                                setData={setPessoa}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.anexo_mestrado}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={6}>
                            <Form.Label>Doutorado</Form.Label>
                            <Form.Control
                                isInvalid={!!errors.doutorado}
                                name="doutorado"
                                onChange={handleChange}
                                placeholder="Doutorado"
                                type="text"
                                value={pessoa.doutorado}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.doutorado}
                            </Form.Control.Feedback>
                        </Col>
                        <Col>
                            <Form.Label>Anexo</Form.Label>
                            <FileInput
                                dataError={errors.anexo_doutorado}
                                name="anexo_doutorado"
                                data={pessoa}
                                setData={setPessoa}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.anexo_doutorado}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <hr style={{ backgroundColor: '#FFF' }} />
                    <Form.Group>
                        <Form.Label>Dados de Acesso:</Form.Label>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.email}
                            name="email"
                            onChange={handleChange}
                            placeholder="E-mail"
                            type="email"
                            value={pessoa.email}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Confirmação de E-mail</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.confirmacao_email}
                            name="confirmacao_email"
                            onChange={handleChange}
                            placeholder="Confirmação de E-mail"
                            type="email"
                            value={pessoa.confirmacao_email}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.confirmacao_email}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Senha</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.password}
                            name="password"
                            onChange={handleChange}
                            placeholder="Senha"
                            type="password"
                            value={pessoa.password}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.password}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Confirmação Senha</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.confirmacao_senha}
                            name="confirmacao_senha"
                            onChange={handleChange}
                            placeholder="Confirmar Senha"
                            type="password"
                            value={pessoa.confirmacao_senha}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.confirmacao_senha}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            DECLARAÇÃO DE RESPONSABILIDADE AMBIENTAL
                        </Form.Label>
                        <BoxDeclaraRespo className="form-control">
                            Que todas as informações declaradas são de minha
                            responsabilidade como usuário(a). Estou ciente que a
                            omissão ou falsa declaração de informações, impedirá
                            a análise desta solicitação de licenciamento
                            ambiental, sem prejuízo a outras penalidades
                            previstas pela legislação aplicável.
                        </BoxDeclaraRespo>
                    </Form.Group>
                    <BoxTermos>
                        <Form.Check
                            isInvalid={!!errors.check_termos}
                            name="check_termos"
                            required
                        />
                        <TermoUso />
                        <Form.Control.Feedback type="invalid">
                            {errors.check_termos}
                        </Form.Control.Feedback>
                    </BoxTermos>
                    <div className="d-flex justify-content-between">
                        <Link
                            className="btn btn-default"
                            to="/selecione-cadastro"
                        >
                            <FaArrowCircleLeft /> Voltar
                        </Link>
                        <button className="btn btn-success" type="submit">
                            <FiCheckCircle /> Concordar e Cadastrar
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default FormCad;
