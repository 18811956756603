import React from 'react';
import { Loading } from '~/components';

export default function LoadPdf({ arquivo, description }) {
    return (
        <div>
            <div className="anexo-body">
                {arquivo ? (
                    <>
                        <object
                            aria-label="arquivo"
                            data={`data:${arquivo.mime_type};base64,${arquivo.file}`}
                            standby="Carregando..."
                            style={{ height: '90vh', width: '100%' }}
                            type={arquivo.mime_type}
                        />
                        {/* {description && <blockquote>{description}</blockquote>} */}
                    </>
                ) : (
                    <Loading />
                )}
            </div>
            <div
                className="d-flex justify-content-end mt-2"
                style={{ padding: '5px 10px' }}
            >
                {arquivo && (
                    <a
                        className="btn btn-success mr-1"
                        download={`${arquivo.name}.${arquivo.extension}`}
                        href={`data:${arquivo.mime_type};base64,${arquivo.file}`}
                        style={{ fontSize: '14px' }}
                    >
                        <i className="fa fa-download" /> Baixar
                    </a>
                )}
            </div>
        </div>
    );
}
