import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { Loading } from '~/components';
import { BasicContent } from '../components';
import { SubMenuList } from '../components/SubMenu';
import { showMenu } from '~/store/modules/menu/action';

const List = () => {
  const dispatch = useDispatch();
  const { menu_id } = useParams();
  const [menuShow, setMenuShow] = useState({});

  const { menu } = useSelector(({ MenuReducer }) => MenuReducer);

  useEffect(() => {
    dispatch(showMenu(menu_id));
  }, [dispatch, menu_id]);
  useEffect(() => {
    if (menu.id === parseInt(menu_id)) {
      setMenuShow(menu);
    }
  }, [menu, menu_id]);

  const breadcrumb = {
    label: 'Detalhes',
    to: `menu/${menu_id}/detalhe`,
    active: false,
  };

  return (
    <BasicContent breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-header">
          <h2 className="card-title">Detalhes</h2>
          <div className="card-tools">
            <Link className="btn btn-default" to="/menu">
              <FaArrowAltCircleLeft /> Voltar
            </Link>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            {menuShow.id === parseInt(menu_id) ? (
              <table className="table table-hover table-striped">
                <tbody>
                  <tr>
                    <th>id</th>
                    <td>{menu.id}</td>
                  </tr>
                  <tr>
                    <th>Nome</th>
                    <td>{menu.nome}</td>
                  </tr>
                  <tr>
                    <th>Icon</th>
                    <td>
                      <i className={`fa fa-${menu.icon}`} />
                    </td>
                  </tr>
                  <tr>
                    <th>Path name</th>
                    <td>{menu.path_name}</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </div>
      {menuShow.id === parseInt(menu_id) ? (
        <SubMenuList menu_id={menu_id} />
      ) : (
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Menus</h2>
          </div>
          <div className="card-body">
            <Loading />
          </div>
        </div>
      )}
    </BasicContent>
  );
};

export default List;
