import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import FileInputCsv from '../../../components/FileInputCsv';
import Swal from 'sweetalert2';
import { Loading, GerateLinkToCsv } from '~/components';
import { import_data } from '../../../services/ListaDocumento';
import { useDispatch } from 'react-redux';
import { loadListaDocumentos } from '~/store/modules/lista_documento/action';


const Create = () => {
  let history = useHistory();
  const { tipo_licenca_id } = useParams();
  const [arquivo, setArquivo] = useState({});
  const [dataError, setDataError] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();


  const headers = [
    { label: 'nome', key: 'nome' },
    { label: 'doc_tecnica', key: 'doc_tecnica' },
    { label: 'obrigatorio', key: 'obrigatorio' },
    { label: 'ordem', key: 'ordem' },
  ];

  const data = [
    {
      nome: 'Certidão exemplo',
      doc_tecnica: 'Sim',
      obrigatorio: 'Não',
      ordem: 1,
    },
  ];

  const handleImportData = async () => {
    try {
      setLoading(true);
      const { status } = await import_data(tipo_licenca_id, arquivo);

      setLoading(false);
      if (status === 200) {
        await  dispatch(loadListaDocumentos({
          where:`tipo_licenca_id:${tipo_licenca_id}`,
          with_trashed: true,
        }));
        await Swal.fire('Sucesso', 'Registro salvo com sucesso!', 'success');
        history.goBack();
      }

    } catch (error) {
      setDataError('Erro inesperado aconteceu, verifique seus dados.');
      if (error.response.status === 422) {
        let server_errors = [];
        server_errors = error.response.data;

        for (let [, value] of Object.entries(server_errors[0])) {
          Swal.fire('Algo deu errado!', `${value}`, 'error');
        }
      }
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setDataError(null);
  }, [arquivo]);


  const handleClose = () => {
    history.goBack();
  };


  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-md"
      onHide={handleClose}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Importar dados de lista de documentos</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        {loading ? (
          <Loading />
        ) : (
          <>
            <Alert variant="info">
              Os arquivos devem ser obrigatoriamente em <strong>CSV</strong> como exemplo você pode usar clicando
              {' '}
              <GerateLinkToCsv
                data_fields={data}
                data_headers={headers}
                import_table_name="lista_documentos"
              >
                aqui.
              </GerateLinkToCsv>
            </Alert>

            <FileInputCsv
              data={arquivo}
              dataError={dataError}
              name="lista_documentos_import"
              setData={setArquivo}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-default"
          disabled={loading}
          onClick={handleClose}
        >
          <i className="fa fa-window-close" /> Cancelar
        </button>
        <button
          className="btn btn-success"
          disabled={loading}
          onClick={handleImportData}
          type="button"
        >
          {' '}
          <i className="fa fa-save" /> Salvar
        </button>
      </Modal.Footer>
    </Modal >
  );
};

export default Create;
