import { apiWithToken } from '../api';

const resouceUrl = '/user';
const token = localStorage.getItem('@token');
let api = apiWithToken(token);

export const load = async params => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  let page = urlParams.has('page') ? {
    page: urlParams.get('page')
  } : {};
  let search = urlParams.has('search') ? {
    search: urlParams.get('search')
  } : {};

  return await api.get(`${resouceUrl}`,{
    params: {
      ...params,
      ...page,
      ...search
    }
  });
}

export const show = async (id, params) => {
  return await api.get(`${resouceUrl}/${id}`, {
    params: {
      ...params
    }
  });
}

export const store = async gerente => {
  return await api.post(resouceUrl, gerente);
}

export const update = async (id, gerente) => {
  return await api.post(`${resouceUrl}/${id}`, gerente);
}
