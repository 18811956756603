import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login_user_response } from '~/store/modules/login/action';
import { Card } from './components';
import removeMask from '../../utils/removeMask';
import { boxLogin as BoxLogin, contentLogin as ContentLogin } from './styles';
import { Alert } from '../../components';

export default function Login({ history }) {
  const dispatch = useDispatch();
  const { user_auth, user_error } = useSelector(
    ({ LoginReducer }) => LoginReducer
  );
  const [user, setUser] = useState({
    cpf_cnpj: '',
    password: '',
  });
  const handleLogin = (event) => {
    event.preventDefault();
    const userWithoutSpecialCharacters = user;
    userWithoutSpecialCharacters.cpf_cnpj = removeMask(
      userWithoutSpecialCharacters.cpf_cnpj
    );

    dispatch(login_user_response(userWithoutSpecialCharacters));
  };
  useEffect(() => {
    if (localStorage.getItem('@token')) {
      window.location.replace('/dashboard');
    }
  }, [user_auth, history]);
  return (
    <BoxLogin>
      <ContentLogin className="d-flex justify-content-around flex-row align-items-center mb-5">
        <Card onHandleSubmit={handleLogin} setUser={setUser} user={user} />
      </ContentLogin>
      <Alert message={user_error.message} />
    </BoxLogin>
  );
}
