import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { Loading } from '~/components';
import { BasicContent } from '../components';
import { MunicipioList } from '~/views/Municipio';
import { showEstado } from '~/store/modules/estado/action';

const List = () => {
  const dispatch = useDispatch();
  const { estado_id } = useParams();
  const [estadoShow, setEstadoShow] = useState({});

  const { estado } = useSelector(({ EstadoReducer }) => EstadoReducer);

  useEffect(() => {
    dispatch(showEstado(estado_id));
  }, [dispatch, estado_id]);
  useEffect(() => {
    if (estado.id === parseInt(estado_id)) {
      setEstadoShow(estado);
    }
  }, [estado, estado_id]);

  const breadcrumb = {
    label: 'Detalhes',
    to: `estado/${estado_id}/detalhe`,
    active: false,
  };

  return (
    <BasicContent breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-header">
          <h2 className="card-title">Detalhes</h2>
          <div className="card-tools">
            <Link
              className="btn btn-default"
              to="/estado"
            >
              <FaArrowAltCircleLeft /> Voltar
            </Link>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            {estadoShow.id === parseInt(estado_id) ? (
              <table className="table table-hover table-striped">
                <tbody>
                  <tr>
                    <th>id</th>
                    <td>{estado.id}</td>
                  </tr>
                  <tr>
                    <th>Nome</th>
                    <td>{estado.nome}</td>
                  </tr>
                  <tr>
                    <th>UF</th>
                    <td>{estado.uf}</td>
                  </tr>
                  <tr>
                    <th>Código IBGE</th>
                    <td>{estado.cod_ibge}</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </div>
      {estadoShow.id === parseInt(estado_id) ? (
        <MunicipioList
          estado_id={estado_id}
        />
      ) : (
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Municípios</h2>
          </div>
          <div className="card-body">
            <Loading />
          </div>
        </div>
      )}
    </BasicContent>
  );
};

export default List;
