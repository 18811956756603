import * as actions from './actionType';

export const AutorizacaoProcessoReducer = (
  state = {
    select_autorizacao_processos: [],
    autorizacao_processos: [],
    status_response: null,
    autorizacao_processo: {},
    errors: {},
  },
  action
) => {
  switch (action.type) {
    case actions.AUTORIZACAO_PROCESSOS_DATA:
      return {
        ...state,
        autorizacao_processos: action.autorizacao_processos.data,
        links: action.autorizacao_processos.data.links,
        meta: action.autorizacao_processos.data.meta,
      };
    case actions.SELECT_AUTORIZACAO_PROCESSOS:
      return {
        ...state,
        select_autorizacao_processos: action.autorizacao_processos.data,
      };
    case actions.GET_AUTORIZACAO_PROCESSO:
      return {
        ...state,
        autorizacao_processo: action.autorizacao_processo.data.data,
        status_response: action.autorizacao_processo.status,
      };
    case actions.AUTORIZACAO_PROCESSO_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    case actions.CHANGE_STATUS_CODE:
      return {
        ...state,
        status_response: action.status,
      };
    default:
      return state;
  }
};
