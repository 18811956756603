import * as actions from './actionType';

export const loadDocumentoProcessos = (params) => ({
  type: actions.LOAD_DOCUMENTO_PROCESSOS,
  params
});

export const getDocumentoProcessos = documento_processos => ({
  type: actions.DOCUMENTO_PROCESSOS_DATA,
  documento_processos
});

export const getSelectDocumentoProcesso = documento_processos => ({
  type: actions.SELECT_DOCUMENTO_PROCESSOS,
  documento_processos
});

export const storeDocumentoProcesso = documento_processo => ({
  type: actions.STORE_DOCUMENTO_PROCESSO,
  documento_processo
});

export const getDocumentoProcesso = documento_processo => ({
  type: actions.GET_DOCUMENTO_PROCESSO,
  documento_processo
});

export const removerDocumentoProcesso = (id) => ({
  type: actions.REMOVER_DOCUMENTO_PROCESSO,
  id
});

export const showDocumentoProcesso = id => ({
  type: actions.SHOW_DOCUMENTO_PROCESSO,
  id
});

export const updateDocumentoProcesso = (documento_processo, id) => ({
  type: actions.UPDATE_DOCUMENTO_PROCESSO,
  documento_processo,
  id
});

export const getErros = errors => ({
  type: actions.DOCUMENTO_PROCESSO_ERROR,
  errors
});

export const changeStatus = status => ({
  type: actions.CHANGE_STATUS_CODE,
  status,
});
