import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { Container, BoxTitle } from './styles';

const PreProcess = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const dataImpactos = [
    {
      ordem: 1,
      componente: 'Impacto na Flora',
      analise: '250',
      indice: '250',
      porcentagem: '16,23',
      classe: 'table-success',
    },
    {
      ordem: 2,
      componente: 'Impacto na Fauna',
      analise: '290',
      indice: '290',
      porcentagem: '18,83',
      classe: 'table-success',
    },
    {
      ordem: 3,
      componente: 'Impacto no Ar',
      analise: '110',
      indice: '110',
      porcentagem: '7,14',
      classe: 'table-warning',
    },
    {
      ordem: 4,
      componente: 'Impacto na Água',
      analise: '250',
      indice: '250',
      porcentagem: '16,23',
      classe: 'table-warning',
    },
    {
      ordem: 5,
      componente: 'Impacto na Paisagem',
      analise: '80',
      indice: '80',
      porcentagem: '5,19',
      classe: 'table-warning',
    },
    {
      ordem: 6,
      componente: 'Impacto no Solo Subsolo',
      analise: '300',
      indice: '300',
      porcentagem: '19,48',
      classe: 'table-warning',
    },
    {
      ordem: 7,
      componente: 'Impacto em Núcleo Populacional',
      analise: '70',
      indice: '70',
      porcentagem: '4,55',
      classe: 'table-danger',
    },
    {
      ordem: 8,
      componente: 'Impacto Social e Cultural',
      analise: '90',
      indice: '90',
      porcentagem: '5,84',
      classe: 'table-danger',
    },
    {
      ordem: 9,
      componente: 'Impacto Administrativo',
      analise: '100',
      indice: '100',
      porcentagem: '6,49',
      classe: 'table-danger',
    },
  ];
  return (
    <Container>
      <BoxTitle>
        <div>
          <h2>Processo</h2>
        </div>
        <div>
          <Link className="btn btn-light" to="/processos">
            <FaArrowAltCircleLeft /> Cancelar
          </Link>
        </div>
      </BoxTitle>
      <div className="card">
        <div className="card-header">
          PARABÊNS, VOCÊ CONCLUI 30 % DO SEU PROCESSO DE LICENCAIMENTO AMBIENTAL
          PARA:
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered table-striped">
              <tbody>
                <tr>
                  <th>Nome</th>
                  <td>Marcelo Ivan Pantoja Creão</td>
                  <th>Porte</th>
                  <td>{''}</td>
                </tr>
                <tr>
                  <th>CPF</th>
                  <td>395.324.532-53</td>
                  <th>Potencial Poluidor</th>
                  <td>Médio</td>
                </tr>
                <tr>
                  <th>Atividade</th>
                  <td>Piscicultura</td>
                  <th>Grau de Impacto Ambiental</th>
                  <td>9,935</td>
                </tr>
                <tr>
                  <th>Licença</th>
                  <td>Licença Ambiental Única</td>
                  <th>Classe</th>
                  <td>Insignificante</td>
                </tr>
                <tr>
                  <th className="text-center" colSpan="4">
                    DESEJA CONTINUAR O LICENCIAMENTO AMBIENTAL?
                  </th>
                </tr>
                <tr>
                  <td className="text-center" colSpan="4">
                    <div className="btn-group">
                      <Link
                        to="/processos/taxa-processo"
                        className="btn btn-success"
                      >
                        SIM
                      </Link>
                      <button className="btn btn-danger">NÃO</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="table-responsive">
            <table className="table table-bordered table-striped table-hover">
              <thead>
                <tr>
                  <th colSpan="5" className="text-center">
                    GRAU DE IMPACTO AMBIENTAL
                  </th>
                </tr>
                <tr>
                  <th>Ordem</th>
                  <th>COMPONENTES</th>
                  <th>Análise Empreendedor</th>
                  <th>Índice de Impacto Ambiental</th>
                  <th>%</th>
                </tr>
              </thead>
              <tbody>
                {dataImpactos.map(impacto => (
                  <tr className={impacto.classe}>
                    <th className="text-center">{impacto.ordem}</th>
                    <td>{impacto.componente}</td>
                    <td>{impacto.analise}</td>
                    <td>{impacto.indice}</td>
                    <td>{impacto.porcentagem}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="table-responsive">
            <table className="table table-bordered table-striped table-hover">
              <tbody className="text-center">
                <tr>
                  <th colSpan="3">
                    ESCALA DE ENQUADRAMENTO DO GRAU DE IMPACTO AMBIENTAL
                  </th>
                </tr>
                <tr>
                  <th className="table-info">PEQUENO</th>
                  <th className="table-success">MÉDIO</th>
                  <th className="table-danger">ALTO</th>
                </tr>
                <tr>
                  <td>0 - 3,32</td>
                  <td>3,33 - 6,66</td>
                  <td>6,67 - 10,00</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="table-responsive">
            <table className="table table-bordered">
              <tbody className="text-center">
                <tr className="table-success">
                  <th>GIAp = 9,935 ↓↑</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default PreProcess;
