export const LOAD_PESSOA_FISICAS = 'LOAD_PESSOA_FISICAS';
export const SELECT_PESSOA_FISICAS = 'SELECT_PESSOA_FISICAS';
export const PESSOA_FISICA_ERROR = 'PESSOA_FISICA_ERROR';
export const PESSOA_FISICA_GET_ERRORS = 'PESSOA_FISICA_GET_ERRORS';
export const PESSOA_FISICAS_DATA = 'PESSOA_FISICAS_DATA';
export const STORE_PESSOA_FISICA = 'STORE_PESSOA_FISICA';
export const GET_PESSOA_FISICA = 'GET_PESSOA_FISICA';
export const REMOVER_PESSOA_FISICA = 'REMOVER_PESSOA_FISICA';
export const SHOW_PESSOA_FISICA = 'SHOW_PESSOA_FISICA';
export const UPDATE_PESSOA_FISICA = 'UPDATE_PESSOA_FISICA';
