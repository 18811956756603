import React from 'react';
import { Content } from '~/layouts/Dash/components';

const BasicContent = ({ breadcrumb, children }) => {
  const breadcrumbs = [
    {
      label: 'Técnico Avaliador',
      to: '/tecnicos_avaliadores',
      active: false,
    },
    breadcrumb,
  ];

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Técnico Avaliador"
    >
      {children}
    </Content>
  );
}

export default BasicContent;
