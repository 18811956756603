import * as actions from './actionType';

export const AtividadeReducer = (state = {select_atividades: [], atividades: [], atividade: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.ATIVIDADES_DATA:
      return {
        ...state,
        atividades: action.atividades.data,
        links: action.atividades.data.links,
        meta: action.atividades.data.meta,
      };
    case actions.SELECT_ATIVIDADES:
      return {
        ...state,
        select_atividades: action.atividades.data
      };
    case actions.GET_ATIVIDADE:
      return {
        ...state,
        atividade: action.atividade.data.data,
        status_response: action.atividade.status,
      };
    case actions.ATIVIDADE_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
