import * as actions from './actionType';

export const loadQuestaoEstudoAmbientals = params => ({
  type: actions.LOAD_QUESTAO_ESTUDO_AMBIENTALS,
  params
});
export const getQuestaoEstudoAmbientals = questao_estudo_ambientals => ({
  type: actions.QUESTAO_ESTUDO_AMBIENTALS_DATA,
  questao_estudo_ambientals,
});

export const getSelectQuestaoEstudoAmbiental = questao_estudo_ambientals => ({
  type: actions.SELECT_QUESTAO_ESTUDO_AMBIENTALS,
  questao_estudo_ambientals,
});

export const storeQuestaoEstudoAmbiental = questao_estudo_ambiental => ({
  type: actions.STORE_QUESTAO_ESTUDO_AMBIENTAL,
  questao_estudo_ambiental,

});
export const getQuestaoEstudoAmbiental = questao_estudo_ambiental => ({
  type: actions.GET_QUESTAO_ESTUDO_AMBIENTAL,
  questao_estudo_ambiental,
});

export const removerQuestaoEstudoAmbiental = (id) => ({
  type: actions.REMOVER_QUESTAO_ESTUDO_AMBIENTAL,
  id
});

export const showQuestaoEstudoAmbiental = id => ({
  type: actions.SHOW_QUESTAO_ESTUDO_AMBIENTAL,
  id,
});

export const updateQuestaoEstudoAmbiental = (questao_estudo_ambiental, id) => ({
  type: actions.UPDATE_QUESTAO_ESTUDO_AMBIENTAL,
  questao_estudo_ambiental,
  id
});

export const getErros = errors => ({
  type: actions.QUESTAO_ESTUDO_AMBIENTAL_ERROR,
  errors,
});
