import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Switch, Route, useLocation, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { Loading } from '~/components';
import Swal from 'sweetalert2';
import { showAtividade } from '~/store/modules/atividade/action';
import { loadTipoLicencas, removerTipoLicenca, ativarTipoLicenca } from '~/store/modules/tipo_licenca/action';
import { loadIndicadoresAmbientais, removerIndicadorAmbiental, ativarIndicadorAmbiental } from '~/store/modules/indicadorAmbiental/action';
import { loadLegislacoes, removerLegislacao, activeLegislacao } from '~/store/modules/legislacao_atividade/action';
import { BasicContent } from '../components';
import { SubMenuList } from '../components/SubMenu';
import CreateInd from '../../IndicadoresAmbientais/Create';
import EditInd from '../../IndicadoresAmbientais/Edit';
import ImportInd from '../../IndicadoresAmbientais/ImportIndicadoresAmbientais';
import CreateTipo from '../../TipoLicenca/Create';
import EditTipo from '../../TipoLicenca/Edit';
import CreateDoc from '../../ListaDocumentos/Create';
import CreateLegislacao from '../../LegislacaoAtividade/Create';
import EditLegislacao from '../../LegislacaoAtividade/Edit';

import { useCallback } from 'react';

const Show = () => {
  let location = useLocation();
  let modal = location.state && location.state.modal;
  let history = useHistory();
  const dispatch = useDispatch();
  const { atividade_id } = useParams();


  const [atividadeShow, setAtividadeShow] = useState({});
  const [tipoLicencasCurrentPage, setTipoLicencasCurrentPage] = useState(1);

  const { tipo_licencas } = useSelector(
    ({ TipoLicencaReducer }) => TipoLicencaReducer
  );

  const { atividade } = useSelector(({ AtividadeReducer }) => AtividadeReducer);
  const { indicadores_ambientais } = useSelector(({ IndicadorAmbientalReducer }) => IndicadorAmbientalReducer);
  const { legislacoes } = useSelector(({ LegislacaoReducer }) => LegislacaoReducer);

  useEffect(() => {
    dispatch(showAtividade(atividade_id, {
      with: 'potencial_poluidor,entidade'
    }));

    dispatch(loadTipoLicencas({
      where: `atividade_id:${atividade_id}`,
      with_trashed: true,
    }));

    dispatch(loadLegislacoes({
      where: `atividade_id:${atividade_id}`,
      with_trashed: true,
    }));

    dispatch(loadIndicadoresAmbientais({
      where: `atividade_id:${atividade_id}`,
      with_trashed: true,
    }));
  }, [dispatch, atividade_id]);

  useEffect(() => {
    if (Object.keys(tipo_licencas).length) {
      setTipoLicencasCurrentPage(tipo_licencas.current_page);
    }
  }, [tipo_licencas]);

  useEffect(() => {
    if (atividade.id === parseInt(atividade_id)) {
      setAtividadeShow(atividade);
    }
  }, [atividade, atividade_id]);

  const back = () => {
    history.goBack();
  }

  const handleRemoveTipoLicenca = useCallback((id => {
    Swal.fire({
      title: 'Desativar?',
      text: 'Deseja desativar esse item?!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        try {
          await dispatch(removerTipoLicenca(id));
          await dispatch(loadTipoLicencas({
            where: `atividade_id:${atividade_id}`,
            with_trashed: true,
            page: tipoLicencasCurrentPage
          }));
          await Swal.fire('Sucesso',
            'Desativado com sucesso!',
            'success');
          await history.go();
        } catch (error) {
          await Swal.fire('Erro',
            'Erro ao Desativar',
            'error');
        }
      }
    })
  }), [dispatch, atividade_id, tipoLicencasCurrentPage, history]);

  const handleActivateTipoLicenca = useCallback((id) => {
    Swal.fire({
      title: 'Ativar?',
      text: 'Deseja Ativar esse item?!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        try {
          await dispatch(ativarTipoLicenca(id));
          await dispatch(loadTipoLicencas({
            where: `atividade_id:${atividade_id}`,
            with_trashed: true,
            page: tipoLicencasCurrentPage
          }));
          await Swal.fire('Sucesso',
            'Ativado com sucesso!',
            'success');
          await history.go();
        } catch (error) {
          await Swal.fire('Erro',
            'Erro ao ativar',
            'error');
        }
      }
    })
  }, [dispatch, atividade_id, tipoLicencasCurrentPage, history]);

  const handleRemoveIndicadorAmbiental = useCallback((id => {
    Swal.fire({
      title: 'Desativar?',
      text: 'Deseja desativar esse item?!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        try {
          await dispatch(removerIndicadorAmbiental(id));
          await dispatch(loadIndicadoresAmbientais({
            where: `atividade_id:${atividade_id}`,
            with_trashed: true,
          }));
          await Swal.fire('Sucesso',
            'Desativado com sucesso!',
            'success');
          await history.go();
        } catch (error) {
          await Swal.fire('Erro',
            'Erro ao Desativar',
            'error');
        }
      }
    })
  }), [dispatch, atividade_id, history]);

  const handleActivateIndicadorAmbiental = useCallback((id) => {
    Swal.fire({
      title: 'Ativar?',
      text: 'Deseja Ativar esse item?!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        try {
          await dispatch(ativarIndicadorAmbiental(id));
          await dispatch(loadIndicadoresAmbientais({
            where: `atividade_id:${atividade_id}`,
            with_trashed: true,
          }));
          await Swal.fire('Sucesso',
            'Ativado com sucesso!',
            'success');
          await history.go();
        } catch (error) {
          await Swal.fire('Erro',
            'Erro ao ativar',
            'error');
        }
      }
    })
  }, [dispatch, atividade_id, history]);

  const handleActivateLegislacao = useCallback((id) => {
    Swal.fire({
      title: 'Ativar?',
      text: 'Deseja Ativar esse item?!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        try {
          await dispatch(activeLegislacao(id));
          await dispatch(loadLegislacoes({
            where: `atividade_id:${atividade_id}`,
            with_trashed: true,
          }));
          await Swal.fire('Sucesso',
            'Ativado com sucesso!',
            'success');
          await history.go();
        } catch (error) {
          await Swal.fire('Erro',
            'Erro ao ativar',
            'error');
        }
      }
    })
  }, [dispatch, atividade_id, history]);

  const handleRemoveLegislacao = useCallback((id => {
    Swal.fire({
      title: 'Desativar?',
      text: 'Deseja desativar esse item?!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        try {
          await dispatch(removerLegislacao(id));
          await dispatch(loadLegislacoes({
            where: `atividade_id:${atividade_id}`,
            with_trashed: true,
          }));
          await Swal.fire('Sucesso',
            'Desativado com sucesso!',
            'success');
          await history.go();
        } catch (error) {
          await Swal.fire('Erro',
            'Erro ao Desativar',
            'error');
        }
      }
    })
  }), [dispatch, atividade_id, history]);

  const breadcrumb = [
    {
      label: 'Entidade',
      to: `/entidade/${atividadeShow?.entidade_id}/detalhe`,
      active: false,
    },
    {
      label: 'Atividade',
      to: `/atividade/${atividade_id}/detalhe`,
      active: false,
    },

    {
      label: 'Detalhes',
      to: `/atividade/${atividade_id}/detalhe`,
      active: false,
    }];

  return (
    <>
      {modal && (
        <Switch>
          <Route
            children={<CreateTipo />}
            path={'/atividade/:atividade_id/tipo_licenca/novo'}
          />
          <Route
            children={<EditTipo currentPage={tipoLicencasCurrentPage} />}
            path={'/atividade/:atividade_id/tipo_licenca/:tipo_licenca_id/editar'}
          />
          <Route
            children={<CreateInd />}
            path={'/atividade/:atividade_id/indicadoresAmbientais/novo'}
          />
          <Route
            children={<EditInd />}
            path={'/atividade/:atividade_id/indicadoresAmbientais/:indicador_ambiental_id/editar'}
          />
          <Route
            children={<ImportInd />}
            path={'/atividade/:atividade_id/indicadoresAmbientais/import_data'}
          />
          <Route
            children={<CreateDoc />}
            path={'/atividade/:atividade_id/lista_documentos/novo'}
          />
          <Route
            children={<CreateLegislacao />}
            path={'/atividade/:atividade_id/legislacao/novo'}
          />
          <Route
            children={<EditLegislacao />}
            path={'/atividade/:atividade_id/legislacao/:legislacao_id/editar'}
          />
        </Switch>
      )}
      <BasicContent breadcrumb={breadcrumb}>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Detalhes</h2>
            <div className="card-tools">
              <button
                className="btn btn-default"
                onClick={back}
              >
                <FaArrowAltCircleLeft /> Voltar
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              {atividadeShow.id === parseInt(atividade_id) ? (
                <table className="table table-hover table-striped">
                  <tbody>
                    <tr>
                      <th>Entidade</th>
                      <td><Link to={`/entidade/${atividadeShow?.entidade_id}/detalhe`}>{atividade?.entidade?.nome}</Link></td>
                    </tr>
                    <tr>
                      <th>id</th>
                      <td>{atividade.id}</td>
                    </tr>
                    <tr>
                      <th>Nome</th>
                      <td>{atividade.nome}</td>
                    </tr>
                    <tr>
                      <th>Código</th>
                      <td>{atividade.codigo}</td>
                    </tr>
                    <tr>
                      <th>Potencial poluidor</th>
                      <td>{atividade?.potencial_poluidor?.nome}</td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <Loading />
              )}
            </div>
          </div>
        </div>
        {atividadeShow.id === parseInt(atividade_id) ? (
          <>
            <SubMenuList
              action={loadTipoLicencas}
              cols={[
                { 'id': 'Cod' },
                { 'nome': 'Nome' },
                { 'sigla': 'Sigla' },
                { 'validade': 'Validade da Licença' },
              ]}
              createPath={'/atividade/:atividade_id/tipo_licenca/novo'}
              createUrl={`/atividade/${atividade_id}/tipo_licenca/novo`}
              detalhe
              displayName={'atividade_id'}
              edit_atividade
              // editUrl={`/atividade/${atividade_id}/tipo_licenca/editar`}
              handleActivate={handleActivateTipoLicenca}
              handleRemove={handleRemoveTipoLicenca}
              id={atividade_id}
              key_prefix="tipo_licencas"
              path_detalhe={'/tipo_licenca/*/detalhe'}
              title={'Tipos de Licença da Atividade'}
              values={tipo_licencas}
            />
          </>
        ) : (
          <div className="card">
            <div className="card-header">
              <h2 className="card-title">Tipos de licenças</h2>
            </div>
            <div className="card-body">
              <Loading />
            </div>
          </div>
        )}

        {indicadores_ambientais.data ? (
          <>
            <SubMenuList
              action={loadIndicadoresAmbientais}
              cols={[
                { 'codigo': 'Codigo' },
                { 'descricao': 'Descricao' },
                { 'indice': 'Índice' },
                { 'unidade': 'Unidade' },
              ]}
              createPath={'/atividade/:atividade_id/indicadoresAmbientais/novo'}
              createUrl={`/atividade/${atividade_id}/indicadoresAmbientais/novo`}
              detalhe={false}
              displayName={'atividade_id'}
              edit_indicador
              handleActivate={handleActivateIndicadorAmbiental}
              handleRemove={handleRemoveIndicadorAmbiental}
              id={atividade_id}
              importDataUrl={`/atividade/${atividade_id}/indicadoresAmbientais/import_data`}
              key_prefix="indicadores_ambientais"
              title={'Indicadores Ambientais da Atividade'}
              values={indicadores_ambientais}
            />
          </>
        ) : (
          <div className="card">
            <div className="card-header">
              <h2 className="card-title">Indicadores Ambientais da Atividade</h2>
            </div>
            <div className="card-body">
              <Loading />
            </div>
          </div>
        )}

        {legislacoes.data ? (
          <>
            <SubMenuList
              action={loadLegislacoes}
              cols={[
                { 'tipo': 'Tipo' },
                { 'legislacao': 'Legislação' }
              ]}
              createPath={'/atividade/:atividade_id/legislacao/novo'}
              createUrl={`/atividade/${atividade_id}/legislacao/novo`}
              detalhe={false}
              displayName={'atividade_id'}
              edit_legislacao
              handleActivate={handleActivateLegislacao}
              handleRemove={handleRemoveLegislacao}
              id={atividade_id}
              key_prefix="legislacoes"
              title={'Legislações da Atividade'}
              values={legislacoes}
            />
          </>
        ) : (
          <div className="card">
            <div className="card-header">
              <h2 className="card-title">Legislações da Atividade</h2>
            </div>
            <div className="card-body">
              <Loading />
            </div>
          </div>
        )}
      </BasicContent>
    </>
  );
};

export default Show;
