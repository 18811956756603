import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as MeioAnalise from '~/services/MeioAnalise';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadMeioAnalisesWorker({ params }) {
  try {
    const response = yield call(MeioAnalise.load, params);
    yield put(actions.getMeioAnalises(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}



function* loadMeioAnalisesWithRepostasWorker({ tipo_licenca_id, analise_id }) {

  const params = {
    where: `tipo_licenca_id:${tipo_licenca_id}`,
    raw_list: true,
    with: `tipo_meio_analises.questao_analises.alternativa_questao_analises,tipo_meio_analises.questao_analises.resposta_questao_analise;analise_processo_id:${analise_id}`
  };

  try {
    const response = yield call(MeioAnalise.load, params);
    yield put(actions.getMeioAnalisesWithRespostas(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}



function* loadMeioAnalisesWithRepostasApiaWorker({ tipo_licenca_id, analise_id }) {

  const params = {
    where: `tipo_licenca_id:${tipo_licenca_id}`,
    raw_list: true,
    with: `tipo_meio_analises.questao_analises.alternativa_questao_analises,tipo_meio_analises.questao_analises.resposta_questao_analise_previas;analise_processo_id:${analise_id}`
  };

  try {
    const response = yield call(MeioAnalise.load, params);
    yield put(actions.getMeioAnalisesWithRespostasApia(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}


function* showMeioAnaliseWorker({ id, params}) {
  try {
    const response = yield call(MeioAnalise.show, id, params);
    yield put(actions.getMeioAnalise(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updateMeioAnaliseWorker({ meio_analise, id}) {
  try {
    const response = yield call(MeioAnalise.update, id, meio_analise);
    yield put(actions.getMeioAnalise(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeMeioAnaliseWorker({meio_analise}) {
  try {
    const response = yield call(MeioAnalise.store, meio_analise);
    yield put(actions.changeStatus(response.status));
    yield put(actions.getErros({save:true}));
    yield put(getErros({save:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* removerMeioAnaliseWorker({id}) {
  try {
    yield call(MeioAnalise.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* ativarMeioAnaliseWorker({id}) {
  try {
    yield call(MeioAnalise.activate, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherMeioAnalise() {
  yield takeLatest(actionType.LOAD_MEIO_ANALISES, loadMeioAnalisesWorker);
  yield takeLatest(actionType.STORE_MEIO_ANALISE, storeMeioAnaliseWorker);
  yield takeLatest(actionType.SHOW_MEIO_ANALISE, showMeioAnaliseWorker);
  yield takeLatest(actionType.UPDATE_MEIO_ANALISE, updateMeioAnaliseWorker);
  yield takeLatest(actionType.REMOVER_MEIO_ANALISE, removerMeioAnaliseWorker);
  yield takeLatest(actionType.LOAD_MEIO_ANALISES_WITH_RESPOSTAS, loadMeioAnalisesWithRepostasWorker);
  yield takeLatest(actionType.LOAD_MEIO_ANALISES_WITH_RESPOSTAS_APIA, loadMeioAnalisesWithRepostasApiaWorker);
  yield takeLatest(actionType.ACTIVE_MEIO_ANALISE, ativarMeioAnaliseWorker);
}

export default function* saga() {
  yield all([watcherMeioAnalise()]);
}
