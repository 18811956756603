import React from 'react';
import { Link } from 'react-router-dom';
// import Swal from 'sweetalert2';
import { Paginate } from '~/components';
import { FaEye, FaEdit, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';
// import { loadTipoLicencas } from '~/store/modules/tipo_licenca/action';
// import Show from '../../../../Atividade/Show';
// import Edit from '../Edit';
import { Button } from 'react-bootstrap';


const List = ({
  action,
  displayName,
  id,
  // createPath,
  createUrl,
  importDataUrl,
  title,
  cols,
  key_prefix,
  values,
  detalhe,
  path_detalhe,
  handleRemove,
  handleActivate,
  edit_atividade,
  edit_indicador,
  edit_legislacao
}) => {
  let path_detalhe_ = ['', ''];
  if (detalhe) {
    path_detalhe_ = path_detalhe.split('*');
  }

  return (
    <div className="card">
      <div className="card-header">
        <h2 className="card-title">{title}</h2>
        <div className="card-tools">
          <Link
            className="btn btn-success mr-1"
            to={{
              pathname: createUrl,
              state: { modal: true }
            }}
          >
            <i className="fa fa-plus" /> Adicionar
          </Link>
          {importDataUrl && (
            <Link
              className="btn btn-primary"
              to={{
                pathname: importDataUrl,
                state: { modal: true }
              }}
            >
              <i className="fa fa-plus-circle" /> Importar dados
            </Link>
          )}
        </div>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-bordered table-hover table-striped table-sm">
            <thead>
              <tr>
                {cols && cols.map((obj) => (
                  <th key={`col-${key_prefix}-${Object.keys(obj)[0]}`}>
                    {Object.values(obj)[0]}
                  </th>
                ))}

                <th className="text-right">Ações</th>
              </tr>
            </thead>
            <tbody>
              {!!values.data &&
                values.data.map((value, index) => (
                  <tr key={index}>
                    {cols && cols.map((obj) => (
                      <td key={`${key_prefix}-${Object.keys(obj)[0]}`}>
                        {value[Object.keys(obj)[0]]}
                      </td>
                    ))}
                    <td className="text-right">
                      <div className="btn-group">
                        {
                          detalhe &&
                          (
                            <Link
                              className="btn btn-default"
                              to={{
                                pathname: path_detalhe_[0] + value.id + path_detalhe_[1]
                              }}
                            >
                              <FaEye /> Detalhes
                            </Link>
                          )
                        }
                        {
                          edit_atividade && !value.deleted_at && (
                            <Link
                              className="btn btn-info"
                              to={{
                                pathname: `/atividade/${id}/tipo_licenca/${value.id}/editar`,
                                state: { modal: true },
                              }}
                            >
                              <FaEdit /> Editar
                            </Link>
                          )
                        }
                        {
                          edit_indicador && !value.deleted_at && (
                            <Link
                              className="btn btn-info"
                              to={{
                                pathname: `/atividade/${id}/indicadoresAmbientais/${value.id}/editar`,
                                state: { modal: true },
                              }}
                            >
                              <FaEdit /> Editar
                            </Link>
                          )
                        }
                        {
                          edit_legislacao && !value.deleted_at && (
                            <Link
                              className="btn btn-info"
                              to={{
                                pathname: `/atividade/${id}/legislacao/${value.id}/editar`,
                                state: { modal: true },
                              }}
                            >
                              <FaEdit /> Editar
                            </Link>
                          )
                        }
                        {
                          !value.deleted_at &&
                          (
                            <Button
                              className="btn btn-danger"
                              onClick={() => handleRemove(value.id)}
                            >
                              <FaTimesCircle />&nbsp;
                              {
                                key_prefix === 'legislacoes' ?
                                'Remover da Licença' : 'Desativar'
                              }
                            </Button>
                          )
                        }
                        {
                          !!value.deleted_at &&
                          (
                            <Button
                              className="btn btn-success"
                              onClick={() => handleActivate(value.id)}
                            >
                              <FaPlusCircle />&nbsp;
                              {
                                key_prefix === 'legislacoes' ?
                                'Adicionar à Licença' : 'Ativar'
                              }
                            </Button>
                          )
                        }
                      </div>
                    </td>

                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="card-footer clearfix">
        <Paginate
          load={action}
          loadParans={{ where: `${displayName}:${id}` }}
          meta={values.meta || values}
        />
      </div>
    </div>
  );
};

export default List;
