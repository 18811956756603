import * as actions from './actionType';

export const loadRelatorioVistorias = params => ({
  type: actions.LOAD_RELATORIO_VISTORIAS,
  params
});
export const getRelatorioVistorias = relatorio_vistorias => ({
  type: actions.RELATORIO_VISTORIAS_DATA,
  relatorio_vistorias,
});

export const getSelectRelatorioVistoria = relatorio_vistorias => ({
  type: actions.SELECT_RELATORIO_VISTORIAS,
  relatorio_vistorias,
});

export const storeRelatorioVistoria = relatorio_vistoria => ({
  type: actions.STORE_RELATORIO_VISTORIA,
  relatorio_vistoria,

});
export const getRelatorioVistoria = relatorio_vistoria => ({
  type: actions.GET_RELATORIO_VISTORIA,
  relatorio_vistoria,
});

export const removerRelatorioVistoria = (id) => ({
  type: actions.REMOVER_RELATORIO_VISTORIA,
  id
});

export const showRelatorioVistoria = processo_id => ({
  type: actions.SHOW_RELATORIO_VISTORIA,
  processo_id,
});

export const updateRelatorioVistoria = (relatorio_vistoria, id) => ({
  type: actions.UPDATE_RELATORIO_VISTORIA,
  relatorio_vistoria,
  id
});



export const getErros = errors => ({
  type: actions.RELATORIO_VISTORIA_ERROR,
  errors,
});
