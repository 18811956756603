import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { loadTipoLicencas } from '~/store/modules/tipo_licenca/action';


const SelectAtividades = ({ atividade_id, setTipoLicenca }) => {
  const dispatch = useDispatch();

  const { tipo_licencas } = useSelector(
    ({ TipoLicencaReducer }) => TipoLicencaReducer
  );

  const [options, setOptions] = useState([
    { value: null, label: '.::Selecione::.' },
  ]);

  const [optionSelected, setOptionSelected] = useState([
    { value: null, label: '.::Selecione::.' },
  ]);

  useEffect(() => {
    dispatch(loadTipoLicencas({raw_list: true, where: `atividade_id:${atividade_id}` }));
  }, [atividade_id, dispatch]);

  useEffect(() => {
    let optionSet = [{ value: null, label: '.::Selecione::.' }];
    setOptionSelected({ value: null, label: '.::Selecione::.' });
    if (tipo_licencas.data) {
      tipo_licencas.data.forEach(({ id, nome, sigla}) => {
        optionSet.push({ value: id, label: `${sigla} - ${nome}` });
      });
    }
    setOptions(optionSet);
  }, [tipo_licencas]);

  const handleOnChange = (e) => {
    setOptionSelected(e);
    setTipoLicenca(e);
  };

  return (
    <>
      <Form.Group>
        <Form.Label>Tipo de licença ambiental</Form.Label>
        <Select
          onChange={handleOnChange}
          options={options}
          value={optionSelected}
        />
        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

export default SelectAtividades;
