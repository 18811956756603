export const LOAD_TECNICO_PROFISSIONAIS = 'LOAD_TECNICO_PROFISSIONAIS';
export const SELECT_TECNICO_PROFISSIONAIS = 'SELECT_TECNICO_PROFISSIONAIS';
export const TECNICO_PROFISSIONAL_ERROR = 'TECNICO_PROFISSIONAL_ERROR';
export const TECNICO_PROFISSIONAL_GET_ERRORS = 'TECNICO_PROFISSIONAL_GET_ERRORS';
export const TECNICO_PROFISSIONAIS_DATA = 'TECNICO_PROFISSIONAIS_DATA';
export const STORE_TECNICO_PROFISSIONAL = 'STORE_TECNICO_PROFISSIONAL';
export const GET_TECNICO_PROFISSIONAL = 'GET_TECNICO_PROFISSIONAL';
export const REMOVER_TECNICO_PROFISSIONAL = 'REMOVER_TECNICO_PROFISSIONAL';
export const SHOW_TECNICO_PROFISSIONAL = 'SHOW_TECNICO_PROFISSIONAL';
export const UPDATE_TECNICO_PROFISSIONAL = 'UPDATE_TECNICO_PROFISSIONAL';
export const LOAD_MINHAS_APIAS = 'LOAD_MINHAS_APIAS';
export const MINHAS_APIAS_DATA = 'MINHAS_APIAS_DATA';
