import React from 'react';

export default function Profile({ photo, name }) {

  const name_show = (name) => {
    let full_name = String(name).split(' ');
    if (full_name.length > 1) {
      let first_name = full_name[0];
      let last_name = full_name[full_name.length - 1];
      return `${first_name} ${last_name}`;
    }
    return name;
  }
  return (
    <div className="user-panel mt-3 pb-3 mb-3 d-flex">
      <div className="image">
        <img
          alt="User"
          className="img-circle elevation-2"
          src={photo}
        />
      </div>
      <div className="info">
        <a
          className="d-block"
          href="/perfil"
        >{name_show(name)}</a>
      </div>
    </div>
  );
}
