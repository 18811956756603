export const LOAD_AUTORIZACAO_PROCESSOS = 'LOAD_AUTORIZACAO_PROCESSOS';
export const SELECT_AUTORIZACAO_PROCESSOS = 'SELECT_AUTORIZACAO_PROCESSOS';
export const AUTORIZACAO_PROCESSO_ERROR = 'AUTORIZACAO_PROCESSO_ERROR';
export const AUTORIZACAO_PROCESSO_GET_ERRORS =
  'AUTORIZACAO_PROCESSO_GET_ERRORS';
export const AUTORIZACAO_PROCESSOS_DATA = 'AUTORIZACAO_PROCESSOS_DATA';
export const STORE_AUTORIZACAO_PROCESSO = 'STORE_AUTORIZACAO_PROCESSO';
export const GET_AUTORIZACAO_PROCESSO = 'GET_AUTORIZACAO_PROCESSO';
export const REMOVER_AUTORIZACAO_PROCESSO = 'REMOVER_AUTORIZACAO_PROCESSO';
export const SHOW_AUTORIZACAO_PROCESSO = 'SHOW_AUTORIZACAO_PROCESSO';
export const UPDATE_AUTORIZACAO_PROCESSO = 'UPDATE_AUTORIZACAO_PROCESSO';
export const LOAD_LIST_AUTORIZACAO_PROCESSOS =
  'LOAD_LIST_AUTORIZACAO_PROCESSOS';

export const CHANGE_STATUS_CODE = 'CHANGE_STATUS_CODE';
