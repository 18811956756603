import React from 'react';
import { Switch } from 'react-router-dom';
import { RoutePublic, RouteLayout } from './components';
import * as View from './views';
import { Simple, Dash } from './layouts';
import { useDispatch } from 'react-redux';
import { cadastro_pessoa_response } from '~/store/modules/uf/action';
import { login_auth } from '~/store/modules/login/action';
import Blank from './layouts/Blank/Blank';
// import { RelatorioPrint } from './views';
import EntidadeRoutes from './routes/Entidades';
import RelatorioRoutes from './routes/Relatorio';
import AtividadeRoutes from './routes/Atividade';
import ProcessoRoutes from './routes/Processo';
import RemessaRoutes from './routes/Remessa';

const Routes = () => {
    const dispatch = useDispatch();
    dispatch(cadastro_pessoa_response());
    if (localStorage.getItem('@user')) {
        dispatch(login_auth(JSON.parse(localStorage.getItem('@user'))));
    }
    return (
        <Switch>
            <RoutePublic
                component={View.Login}
                exact
                layout={Simple}
                path="/"
            />
            <RoutePublic
                component={View.Login}
                exact
                layout={Simple}
                path="/login"
            />
            <RoutePublic
                component={View.ConsultarProcesso}
                exact
                layout={Simple}
                path="/consultar-processo"
            />
            <RoutePublic
                component={View.CadastroSigau}
                exact
                layout={Simple}
                path="/cadastro-sigau-siblam"
            />
            <RoutePublic
                component={View.CadPessoaFisica}
                exact
                layout={Simple}
                path="/cadastro-pessoa-fisica"
            />
            <RoutePublic
                component={View.CadPessoaJuridica}
                exact
                layout={Simple}
                path="/cadastro-pessoa-juridica"
            />
            <RoutePublic
                component={View.CadTecnicoProfissional}
                exact
                layout={Simple}
                path="/cadastro-tec-prof"
            />
            <RoutePublic
                component={View.SelectCadastro}
                exact
                layout={Simple}
                path="/selecione-cadastro"
            />
            <RoutePublic
                component={View.RecuperarSenha}
                exact
                layout={Simple}
                path="/recuperar-senha"
            />
            <RoutePublic
                component={View.TrocarSenha}
                exact
                layout={Simple}
                path="/trocar-senha"
            />

            <RouteLayout
                component={View.Dashboard}
                exact
                layout={Dash}
                path="/dashboard"
            />
            <RouteLayout
                component={View.Profile}
                layout={Dash}
                path="/perfil"
            />
            <RouteLayout
                component={View.ProfileEdit}
                exact
                layout={Dash}
                path="/perfil/editar"
            />
            {/* ------- INICIO ROTAS PROCESSOS ------ */}

            {/* ------- INICIO ROTAS PROCESSOS ------ */}
            {ProcessoRoutes.map((route, index) => (
                <RouteLayout {...route} key={`processo-route-${index}`} />
            ))}
            {/* ------- FIM ROTAS PROCESSOS ------ */}

            {/* ------- INICIO ROTAS TECNICOS ------ */}
            <RouteLayout
                component={View.ResponsavelTecnicoList}
                exact
                layout={Dash}
                path="/tecnicos/responsavel_tecnico"
            />

            <RouteLayout
                component={View.ResponsavelTecnicoList}
                layout={Dash}
                path="/tecnicos/responsavel_tecnico/*"
            />
            {/* ------- FIM ROTAS TECNICOS ------ */}

            <RouteLayout
                component={View.Profile}
                exact
                layout={Dash}
                path="/profile"
            />
            <RouteLayout
                component={View.Configuration}
                exact
                layout={Dash}
                path="/configuration"
            />

            {/*------- ROTA DE AUTORIZACAO --------*/}
            <RoutePublic
                component={View.AutorizacaoList}
                exact
                layout={Dash}
                path="/autorizacao"
            />

            <RoutePublic
                component={View.AutorizacaoShow}
                exact
                layout={Dash}
                path="/autorizar-processo/:processo_id/detalhe"
            />
            {/*------- FIM DE AUTORIZACAO --------*/}

            {/* ------- FIM ROTAS DISTRIBUIÇÃO ------ */}
            <RouteLayout
                component={View.DistribuicaoList}
                exact
                layout={Dash}
                path="/distribuicao"
            />
            <RouteLayout
                component={View.DistribuicaoShow}
                exact
                layout={Dash}
                path="/distribuicao/:processo_id/detalhe"
            />
            <RouteLayout
                component={View.DistribuicaoProjeto}
                exact
                layout={Dash}
                path="/distribuicao/projeto"
            />

            {/* ------- FIM ROTAS DISTRIBUIÇÃO ------ */}

            {/* ------- INICIO ROTAS ANALISE PROCESSOS ------ */}
            <RouteLayout
                component={View.AnaliseProcessosList}
                exact
                layout={Dash}
                path="/analise-processo"
            />
            <RouteLayout
                component={View.AnaliseProcessosShow}
                exact
                layout={Dash}
                path="/analise-processo/:processo_id/detalhe"
            />

            <RouteLayout
                component={View.Relatorio}
                exact
                layout={Dash}
                path="/relatorio"
            />
            {/* <RouteLayout
        component={View.RelatorioPrint}
        exact
        layout={RelatorioPrint}
        path="/relatorio/:processo_id/imprimir/:relatorio_id"
      /> */}
            <RouteLayout
                component={View.CCLAPrint}
                exact
                layout={View.CCLAPrint}
                path="/cadeia_custodia/:processo_protocolo*/:processo_id/imprimir"
            />
            <RouteLayout
                component={View.RGLA}
                exact
                layout={Dash}
                path="/relatorios/relatorio-gestao-licenciamento-ambiental"
            />
            <RouteLayout
                component={View.RTLA}
                exact
                layout={Dash}
                path="/relatorios/relatorio-tecnico-licenciamento-ambiental"
            />
            <RouteLayout
                component={View.RAFA}
                exact
                layout={Dash}
                path="/relatorios/relatorio-arrecadacao-financeira-ambiental"
            />
            <RouteLayout
                component={View.RPLA}
                exact
                layout={Dash}
                path="/relatorios/relatorio-producao-licenciamento-ambiental"
            />
            <RouteLayout
                component={View.RADE}
                exact
                layout={Dash}
                path="/relatorios/relatorio-ambiental-desenvolvimento-economico"
            />

            {/* <RouteLayout
        component={View.ParecerProjetoPrint}
        exact
        layout={Blank}
        path="/parecer-projeto/:processo_id/imprimir"
      /> */}
            {/* <RouteLayout
        component={View.NotaTecAmbientalPrint}
        exact
        layout={Blank}
        path="/nota-tecnica/:processo_id/imprimir"
      /> */}

            {RelatorioRoutes.map((route, index) => (
                <RouteLayout
                    {...route}
                    key={`relatorio-route-${index}`}
                    layout={Dash}
                />
            ))}

            {/* Router of Entidade */}
            {EntidadeRoutes.map((route, index) => (
                <RouteLayout
                    {...route}
                    key={`entidade-route-${index}`}
                    layout={Dash}
                />
            ))}
            {/* Router of Entidade */}

            <RouteLayout
                component={View.ObservacaoShow}
                layout={Dash}
                path="/observacao/:observacao_id/detalhe"
            />

            {/* Route Motorista */}

            <RouteLayout
                component={View.MotoristaShow}
                layout={Dash}
                path="/motorista/:motorista_id/detalhe"
            />

            {/* Route Motorista */}

            {/* Route Veiculo */}
            <RouteLayout
                component={View.veiculoShow}
                layout={Dash}
                path="/veiculo/:veiculo_id/detalhe"
            />
            {/* Route Veiculo */}

            {/* Router of Estado */}
            <RouteLayout
                component={View.EstadoList}
                exact
                layout={Dash}
                path="/estado"
            />
            <RouteLayout
                component={View.EstadoShow}
                exact
                layout={Dash}
                path="/estado/:estado_id/detalhe"
            />
            <RouteLayout
                component={View.EstadoShow}
                exact
                layout={Dash}
                path="/estado/:estado_id/municipio/*"
            />
            <RouteLayout
                component={View.EstadoList}
                layout={Dash}
                path="/estado/*"
            />
            {/* Router of Estado */}
            {/* Router of Menu */}
            <RouteLayout
                component={View.MenuList}
                exact
                layout={Dash}
                path="/menu"
            />
            <RouteLayout
                component={View.MenuShow}
                exact
                layout={Dash}
                path="/menu/:menu_id/detalhe"
            />

            <RouteLayout
                component={View.MenuShow}
                exact
                layout={Dash}
                path="/menu/:menu_id/sub_menu/*"
            />
            <RouteLayout
                component={View.MenuList}
                layout={Dash}
                path="/menu/*"
            />
            {/* Router of Menu */}

            {/* Router of TipoUser */}
            <RouteLayout
                component={View.TipoUserList}
                exact
                layout={Dash}
                path="/tipo_user"
            />
            <RouteLayout
                component={View.TipoUserShow}
                exact
                layout={Dash}
                path="/tipo_user/:tipo_user_id/detalhe"
            />

            <RouteLayout
                component={View.TipoUserShow}
                exact
                layout={Dash}
                path="/tipo_user/:tipo_user_id/menu_tipo_user/*"
            />
            <RouteLayout
                component={View.TipoUserList}
                layout={Dash}
                path="/tipo_user/*"
            />
            {/* Router of TipoUser */}

            {/* Router of recurso */}
            <RouteLayout
                component={View.RecursoList}
                exact
                layout={Dash}
                path="/recurso"
            />

            <RouteLayout
                component={View.RecursoList}
                layout={Dash}
                path="/recurso/*"
            />
            {/* Router of recurso */}

            {/* Router of Potencial poluidor */}
            <RouteLayout
                component={View.PotencialPoluidorList}
                exact
                layout={Dash}
                path="/potencial_poluidor"
            />

            <RouteLayout
                component={View.PotencialPoluidorList}
                layout={Dash}
                path="/potencial_poluidor/*"
            />
            {/* Router of Potencial poluidor */}

            {/* Router of Potencial poluidor */}

            {AtividadeRoutes.map((route, index) => (
                <RouteLayout
                    {...route}
                    key={`atividade-route-${index}`}
                    layout={Dash}
                />
            ))}

            {RemessaRoutes.map((route, index) => (
                <RouteLayout
                    {...route}
                    key={`remessa-route-${index}`}
                    layout={Dash}
                />
            ))}

            <RouteLayout
                component={View.ListaDocumentosCreate}
                exact
                layout={Dash}
                path="/tipo_licenca/:tipo_licenca_id/lista_documentos/novo"
            />
            <RouteLayout
                component={View.ListaDocumentosEdit}
                exact
                layout={Dash}
                path="/tipo_licenca/:tipo_licenca_id/lista_documentos/:docId/edit"
            />
            {/* Router of Potencial poluidor */}

            {/** Router of Valor Referência */}

            <RouteLayout
                component={View.createValorReferencia}
                exact
                layout={Dash}
                path="/tipo_licenca/:tipo_licenca_id/:atividade_id/valor_referencia/novo"
            />

            <RouteLayout
                component={View.editValorReferencia}
                exact
                layout={Dash}
                path="/tipo_licenca/:tipo_licenca_id/valor_referencia/:valor_referencia_id/edit"
            />
            <RouteLayout
                component={View.ImportValorReferencia}
                exact
                layout={Dash}
                path="/tipo_licenca/:tipo_licenca_id/:atividade_id/ValorRefencia/import_data"
            />
            {/** end of router valorRef */}

            {/* Router of meio_analise */}

            {/* <RouteLayout
        component={View.MeioAnaliseShow}
        layout={Dash}
        path="/meio_analise"
      /> */}

            <RouteLayout
                component={View.MeioAnaliseShow}
                layout={Dash}
                path="/meio_analise/:meio_analise_id/detalhe"
            />

            <RouteLayout
                component={View.MeioAnaliseShow}
                exact
                layout={Dash}
                path="/meio_analise/:meio_analise_id/tipo_meio_analise/*"
            />

            <RouteLayout
                component={View.ImportMeioAnalise}
                exact
                layout={Dash}
                path="/tipo_licenca/:tipo_licenca_id/MeioAnalise/import_data"
            />

            {/* Router of meio_analise */}

            {/* Router of tipo_meio_analise */}

            {/* <RouteLayout
        component={View.MeioAnaliseShow}
        layout={Dash}
        path="/tipo_meio_analise"
      /> */}

            <RouteLayout
                component={View.TipoMeioAnaliseShow}
                layout={Dash}
                path="/tipo_meio_analise/:tipo_meio_analise_id/detalhe"
            />

            <RouteLayout
                component={View.TipoMeioAnaliseShow}
                exact
                layout={Dash}
                path="/tipo_meio_analise/:tipo_meio_analise_id/*"
            />
            {/* Router of tipo_meio_analise */}

            {/* Router of questao_analise */}

            <RouteLayout
                component={View.QuestaoAnaliseShow}
                exact
                layout={Dash}
                path="/questao_analise/:questao_analise_id/*"
            />

            <RouteLayout
                component={View.ImportQuestaoAnalise}
                exact
                layout={Dash}
                path="/tipo_meio_analise/:tipo_meio_analise_id/QuestaoAnalises/import_data"
            />
            <RouteLayout
                component={View.ModeloMeioAnalise}
                exact
                layout={Dash}
                path="/tipo_licenca/:tipo_licenca_id/meio_analise/modelo"
            />

            {/* Router of questao_analise */}

            {/* Router of condicionante */}

            <RouteLayout
                component={View.CondicionanteShow}
                layout={Dash}
                path="/condicionante"
            />

            <RouteLayout
                component={View.CondicionanteShow}
                layout={Dash}
                path="/condicionante/:condicionante_id/detalhe"
            />

            {/* <RouteLayout
        component={View.TipoMeioAnaliseShow}
        exact
        layout={Dash}
        path="/condicionante/:condicionante_id/condicionantes/*"
      /> */}
            {/* Router of condicionante */}

            {/* Router of lista_documentos */}
            {/* <RouteLayout
        component={View.ListaDocumentosCreate}
        exact
        layout={Dash}
        path="/lista_documentos"
      /> */}

            {/* <RouteLayout
        component={View.ListaDocumentosCreate}
        layout={Dash}
        path="/lista_documentos/*"
      /> */}

            <RouteLayout
                component={View.ImportListaDocumentos}
                exact
                layout={Dash}
                path="/tipo_licenca/:tipo_licenca_id/lista_documentos/import_data"
            />

            {/* Router of lista_documentos */}

            {/* Router of indicadoresAmbientais */}
            <RouteLayout
                component={View.IndicadorAmbientalCreate}
                exact
                layout={Dash}
                path="/indicadoresAmbientais"
            />

            <RouteLayout
                component={View.IndicadorAmbientalCreate}
                layout={Dash}
                path="/indicadoresAmbientais/*"
            />
            {/* Router of indicadoresAmbientais */}

            {/* Router of tipo_licenca */}
            <RouteLayout
                component={View.TipoLicencaList}
                exact
                layout={Dash}
                path="/tipo_licenca"
            />

            {/* <RouteLayout
        component={View.TipoLicencaList}
        layout={Dash}
        path="/tipo_licenca/*"
      /> */}

            <RouteLayout
                component={View.TipoLicencaShow}
                layout={Dash}
                path="/tipo_licenca/:tipo_licenca_id/detalhe"
            />

            <RouteLayout
                component={View.MeioAnaliseCreate}
                exact
                layout={Dash}
                path="/tipo_licenca/:tipo_licenca_id/meio_analise/novo"
            />

            <RouteLayout
                component={View.MeioAnaliseEdit}
                exact
                layout={Dash}
                path="/tipo_licenca/:tipo_licenca_id/meio_analise/:id_meio_analise/edit"
            />
            {/* Router of tipo_licenca */}

            {/* Router of pessoa_juridica */}
            <RouteLayout
                component={View.PessoaJuricaList}
                exact
                layout={Dash}
                path="/empreendimentos/pessoa_juridica"
            />

            <RouteLayout
                component={View.PessoaJuricaList}
                layout={Dash}
                path="/empreendimentos/pessoa_juridica/*"
            />

            <RouteLayout
                component={View.MeuEmpreendimentoList}
                exact
                layout={Dash}
                path="/empreendimentos/meus_empreendimentos"
            />

            <RouteLayout
                component={View.MeuEmpreendimentoList}
                layout={Dash}
                path="/empreendimentos/meus_empreendimentos/*"
            />
            {/* Router of pessoa_juridica */}

            {/* Router of pessoa_fisica */}
            <RouteLayout
                component={View.PessoaFisicaList}
                exact
                layout={Dash}
                path="/empreendimentos/pessoa_fisica"
            />

            <RouteLayout
                component={View.PessoaFisicaList}
                layout={Dash}
                path="/empreendimentos/pessoa_fisica/*"
            />
            {/* Router of pessoa_fisica */}

            {/* Router of tecnico_profissional */}
            <RouteLayout
                component={View.TecnicoProfissionalList}
                exact
                layout={Dash}
                path="/empreendimentos/tecnico_profissional"
            />

            <RouteLayout
                component={View.TecnicoProfissionalList}
                layout={Dash}
                path="/empreendimentos/tecnico_profissional/*"
            />
            {/* Router of tecnico_profissional */}

            {/* Router of tecnico_profissional */}
            <RouteLayout
                component={View.MinhasApiasList}
                exact
                layout={Dash}
                path="/analise-processo/minhas-analises"
            />

            <RouteLayout
                component={View.MinhasApiasShow}
                layout={Dash}
                path="/analise-processo/minhas-analises/:processo_id"
            />
            {/* Router of tecnico_profissional */}

            {/* Router of pagamentos pendentes */}

            <RouteLayout
                component={View.PagamentosPendentesList}
                layout={Dash}
                path="/confirmPay/pagamentos-pendentes"
            />

            {/* End Router of pagamentos pendentes */}

            {/* ------- INICIO ROTAS CADASTRAR USUARIOS ------ */}

            <RouteLayout
                component={View.CadUsuarioList}
                layout={Dash}
                path="/cad_users"
            />

            <RouteLayout
                component={View.GerenteList}
                layout={Dash}
                path="/gerentes"
            />

            <RouteLayout
                component={View.TecnicoAvaliadorList}
                layout={Dash}
                path="/tecnicos_avaliadores"
            />

            {/* ------- FIM ROTAS CADASTRAR USUARIOS ------ */}

            {/* ------- INICIO ROTAS VISTORIAS ------ */}
            <RouteLayout
                component={View.VistoriasList}
                layout={Dash}
                path="/vistorias"
            />
            {/* ------- FIM ROTAS VISTORIAS ------ */}

            {/* ------- INICIO ROTAS BOLETO ------ */}

            {/* <RouteLayout
        component={View.Boleto}
        layout={Blank}
        exact
        path="/boleto/:processo_id/:barcode"
      /> */}
            <RouteLayout
                component={View.GuiaArrecadacao}
                exact
                layout={Blank}
                path="/boleto/guia-arrecadacao/:processo_id"
            />

            {/* ------- FIM ROTAS BOLETO ------ */}

            <RouteLayout component={View.NotFound} layout={Dash} path="*" />
        </Switch>
    );
};

export default Routes;
