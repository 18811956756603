import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { Loading } from '~/components';
import { showPessoaJuridica } from '~/store/modules/pessoa_juridica/action';
import { ModalAnexos } from '~/components';

const List = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { pessoa_juridica_id } = useParams();
  const [pessoaJuridicaShow, setPessoaJuridicaShow] = useState({});

  const [modalArquivo, setModalArquivo] = useState({
    title: '',
    path: '',
    show: false,
    handleHide: () => false,
  });

  const { pessoa_juridica } = useSelector(
    ({ PessoaJuridicaReducer }) => PessoaJuridicaReducer
  );

  useEffect(() => {
    dispatch(showPessoaJuridica(pessoa_juridica_id));
  }, [dispatch, pessoa_juridica_id]);

  useEffect(() => {
    if (pessoa_juridica.id === parseInt(pessoa_juridica_id)) {
      setPessoaJuridicaShow(pessoa_juridica);
    }
  }, [pessoa_juridica, pessoa_juridica_id]);

  const handleClose = () => {
    history.push('/empreendimentos/pessoa_juridica');
  };

  const handleHide = () => {
    setModalArquivo({
      title: '',
      path: '',
      show: false,
      handleHide: () => false,
    });
  };

  const handleOpenModalAnexo = (title, path) => {
    console.log(title, path);
    setModalArquivo({
      title,
      path,
      show: true,
      handleHide,
    });
  };

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-xl"
        onHide={handleClose}
        show
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            PessoaJuridica
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header">
              <h2 className="card-title">Detalhes</h2>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                {pessoaJuridicaShow.id === parseInt(pessoa_juridica_id) ? (
                  <>
                    <table className="table table-hover table-striped table-sm">
                      <tbody>
                        <tr>
                          <th>id</th>
                          <td>{pessoa_juridica.id}</td>
                        </tr>
                        <tr>
                          <th>Razão Social</th>
                          <td>{pessoa_juridica.nome}</td>
                        </tr>

                        <tr>
                          <th>Nome Fantasia</th>
                          <td>{pessoa_juridica.nome_fantasia}</td>
                        </tr>
                        <tr>
                          <th>E-mail</th>
                          <td>{pessoa_juridica.email}</td>
                        </tr>
                        <tr>
                          <th>CNPJ</th>
                          <td>
                            {pessoa_juridica.cnpj}
                            <button
                              className="btn btn-default ml-2 float-right"
                              onClick={() =>
                                handleOpenModalAnexo(
                                  'Anexo CNPJ',
                                  pessoa_juridica.anexo_cnpj
                                )
                              }
                            >
                              <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <th>Certidão Simplificada da Junta Comercial</th>
                          <td>
                            {pessoa_juridica.certidao_simplificada_jc}
                            <button
                              className="btn btn-default ml-2 float-right"
                              onClick={() =>
                                handleOpenModalAnexo(
                                  'Certidão Simplificada da Junta Comercial',
                                  pessoa_juridica.anexo_certidao_simplificada_jc
                                )
                              }
                            >
                              <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <th>Porte Empreendimento</th>
                          <td>{pessoa_juridica.porte_empreendimento.nome}</td>
                        </tr>
                        <tr>
                          <th>CEP</th>
                          <td>{pessoa_juridica.cep}</td>
                        </tr>
                        <tr>
                          <th>Endereço</th>
                          <td>
                            {pessoa_juridica.logradouro},{' '}
                            {pessoa_juridica.bairro}, {pessoa_juridica.bairro},{' '}
                            {pessoa_juridica.Cidade} -
                            {pessoa_juridica.estado_endereco.uf}
                            <button
                              className="btn btn-default ml-2 float-right"
                              onClick={() =>
                                handleOpenModalAnexo(
                                  'Comprovante de endereço',
                                  pessoa_juridica.anexo_endereco
                                )
                              }
                            >
                              <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <th>Número de WhatsApp</th>
                          <td>{pessoa_juridica.numero_whatsapp}</td>
                        </tr>
                        <tr>
                          <th>Fone de contato</th>
                          <td>{pessoa_juridica.telefone}</td>
                        </tr>
                        <tr>
                          <th>Homepage da empresa</th>
                          <td>{pessoa_juridica.site}</td>
                        </tr>
                      </tbody>
                    </table>
                    <hr />
                    <h5>Dados Responsavel legal:</h5>
                    <table className="table table-hover table-striped table-sm">
                      <tbody>
                        <tr>
                          <th>Nome:</th>
                          <td>{pessoa_juridica.nome_responsavel_legal}</td>
                        </tr>
                        <tr>
                          <th>Função:</th>
                          <td>{pessoa_juridica.funcao_responsavel_legal}</td>
                        </tr>
                        <tr>
                          <th>CPF:</th>
                          <td>
                            {pessoa_juridica.cpf_responsavel_legal}
                            <button
                              className="btn btn-default ml-2 float-right"
                              onClick={() =>
                                handleOpenModalAnexo(
                                  'CPF Responsável Legal',
                                  pessoa_juridica.anexo_cpf_responsavel_legal
                                )
                              }
                            >
                              <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <th>RG:</th>
                          <td>
                            {pessoa_juridica.rg_responsavel_legal}
                            <button
                              className="btn btn-default ml-2 float-right"
                              onClick={() =>
                                handleOpenModalAnexo(
                                  'RG Responsável Legal',
                                  pessoa_juridica.anexo_rg_responsavel_legal
                                )
                              }
                            >
                              <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <th>Endereço:</th>
                          <td>
                            {pessoa_juridica.endereco_responsavel_legal}
                            <button
                              className="btn btn-default ml-2 float-right"
                              onClick={() =>
                                handleOpenModalAnexo(
                                  'Endereço Responsável Legal',
                                  pessoa_juridica.anexo_endereco_responsavel_legal
                                )
                              }
                            >
                              <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <th>Fone:</th>
                          <td>{pessoa_juridica.telefone_responsavel_legal}</td>
                        </tr>
                        <tr>
                          <th>E-mail:</th>
                          <td>{pessoa_juridica.email_responsavel_legal}</td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                ) : (
                  <Loading />
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Link
            className="btn btn-default"
            to="/empreendimentos/pessoa_juridica"
          >
            <i className="fa fa-window-close" /> Fechar
          </Link>
        </Modal.Footer>
      </Modal>
      <ModalAnexos {...modalArquivo} />
    </>
  );
};

export default List;
