import React from 'react';
import TableQuestions from '../TableQuestions';
import Impacto from '../Impacto';

const MeioBiotico = () => {
  const mountedQuestions = (valor, valor2, valor3, valor4, valor5) => [
    { val: '0,0 - 1,9', q: valor },
    { val: '2,0 - 3,9', q: valor2 },
    { val: '4,0 - 5,9', q: valor3 },
    { val: '6,0 - 7,9', q: valor4 },
    { val: '8,0 - 10,0', q:valor5 },
  ];
  const genericQuestions = [
    { val: '0,0 - 1,9', q: 'Sem evidências' },
    { val: '2,0 - 3,9', q: 'Suposta' },
    { val: '4,0 - 5,9', q: 'Comprovada sem influência' },
    { val: '6,0 - 7,9', q: 'Comprovada' },
    { val: '8,0 - 10,0', q: 'Comprovada com influência' },
  ];
  const genericQuestions2 = [
    { val: '0,0 - 1,9', q:'Sem influência'},
    { val: '2,0 - 3,9', q:'Suposta'},
    { val: '4,0 - 5,9', q:'Sem evidências'},
    { val: '6,0 - 7,9,0', q:'Fortes indícios'},
    { val: '8,0 - 10,0', q:'Comprovado'},
  ];
  const dataImpactoAr = [
    TableQuestions({
      titulo: 'O empreendimento causará a dispersão de poluentes?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará emissão de poluentes (CO, MP, SO2, Partículas inaláveis, fumaça, O3, voláteis, odores)?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará aumento dos níveis de ruído?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento tem proximidade de centros urbanos (baseada em censo demográfico)?',
      questoes: [
        { val: '0,0 - 1,9', q: ' Sem influência' },
        {
          val: '2,0 - 3,9',
          q:
            'Centro urbano (população maior ou igual 60.000 hab.) distante até 25 km',
        },
        {
          val: '4,0 - 5,9',
          q:
            'Comprovada sem influênciaCentro urbano (população maior ou igual 60.000 hab.) distante até 10 km.',
        },
        { val: '6,0 - 7,9', q: 'É limitrofe à centro urbano' },
        { val: '8,0 - 10,0', q: 'É dentro de centro urbano' },
      ],
    }),
    TableQuestions({
      titulo:
        'O empreendimento afeta as condições do ar de áreas protegidas (Unidades de Conservação: parques, reservas, estações ecológicas, etc.?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará comprometimento do aquífero, decorrente do dano ao ar?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará morte ou dano à fauna, decorrente do dano ao ar?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará morte ou dano à flora, decorrente do dano ao ar?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará dano ao patrimônio cultural, histórico, artístico, arqueológico e turístico, e /ou a monumentos naturais decorrentes do dano ao ar.',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará alteração da qualidade do ar?',
      questoes: [
        {
          val: '0,0 - 1,9',
          q: 'Sem influência. Estado de normalidade ambiental.',
        },
        {
          val: '2,0 - 3,9',
          q: 'Estado de atenção',
        },
        {
          val: '4,0 - 5,9',
          q: 'Estado de alerta',
        },
        { val: '6,0 - 7,9', q: 'Estado de emergência' },
        { val: '8,0 - 10,0', q: 'Alteração comprovada' },
      ],
    }),
    TableQuestions({
      titulo:
        'Qual a previsão de reequilíbrio (natural) após o encerramento do empreendimento ou atividade?',
      questoes: [
        {
          val: '0,0 - 1,9',
          q: 'Em até 01 ano',
        },
        {
          val: '2,0 - 3,9',
          q: 'Em até 03 anos',
        },
        {
          val: '4,0 - 5,9',
          q: 'Em até 05 anos',
        },
        { val: '6,0 - 7,9', q: 'Em até 08 anos' },
        { val: '8,0 - 10,0', q: 'Em até ou mais de 10 anos' },
      ],
    }),
  ];
  const dataImpactoAgua = [
    TableQuestions({
      titulo:
        'O empreendimento causará alteração da hidrodinâmica do ambiente?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará alteração da qualidade da água subterrânea? (referência resoluções CONAMA e Potabilidade)',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará alteração da qualidade da água superficial? (referência resoluções CONAMA)',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento utilizará significativa quantidade de água subterrânea?',
      questoes: [
        { val: '0,0 - 1,9', q: ' Sem influência' },
        {
          val: '2,0 - 3,9',
          q: 'Baixo volume de água',
        },
        {
          val: '4,0 - 5,9',
          q: 'Médio volume de água',
        },
        { val: '6,0 - 7,9', q: 'Alto volume de água' },
        { val: '8,0 - 10,0', q: 'Excessivo volume de água' },
      ],
    }),
    TableQuestions({
      titulo: 'O empreendimento causará alteração do balanço hídrico?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará alteração do fluxo de recarga da água subterrânea?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará alteração do nível do aquifero?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará alteração nos usos da água?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará aumento do assoreamento de cursos d\'água superficiais?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará desvios ou barramentos em cursos d\'água?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará alterações da disponibilidade hídrica?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará ecotoxicidade nos recursos hídricos superficiais?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará ecotoxicidade nos recursos hídricos subterrâneos?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará eutrofização e florações?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará geração de resíduos e efluentes que possam contaminar os recursos hídricos?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará toxicidade da emissão (baseada em literatura científica)?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará toxicidade da emissão (baseada em literatura científica)?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'Localização em relação a áreas protegidas (Unidades de Conservação: parques, reservas, estações ecológicas, etc. E áreas de preservação permanente definidas pela legislação). Na mesma bacia hidrográfica a juzante; Na mesma bacia hidrográfica a montante; Dentro',
      questoes: [
        { val: '0,0 - 1,9', q: 'Não se aplica' },
        {
          val: '2,0 - 3,9',
          q: 'Fora da Zona de Amortecimento',
        },
        {
          val: '4,0 - 5,9',
          q: 'Na Zona de Amortecimento, não contigua ao limite da UC',
        },
        {
          val: '6,0 - 7,9',
          q: 'Dentro da Zona de Amortecimento, no limite da UC',
        },
        { val: '8,0 - 10,0', q: 'Dentro da UC' },
      ],
    }),
    TableQuestions({
      titulo: 'Dano ao complexo solo-subsolo, decorrente do dano à água.?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'Morte ou dano à fauna, decorrente do dano à água?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'Morte ou dano à flora, decorrente do dano à água.?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'Dano ao patrimônio cultural, histórico, artístico, arquitetônico e turístico, e /ou a monumentos naturais decorrentes do dano à água?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'Alteração da classe de copo d\'água (baseado na resolução da CONAMA nº 20, de 18/06/86)?',
      questoes: [
        { val: '0,0 - 1,9', q: 'Altera em até 20 %.' },
        {
          val: '2,0 - 3,9',
          q: 'Altera em até 40 %.',
        },
        {
          val: '4,0 - 5,9',
          q: 'Altera em até 60 %.',
        },
        {
          val: '6,0 - 7,9',
          q: 'Altera em até 80 %.',
        },
        { val: '8,0 - 10,0', q: 'Altera em até 100 %.' },
      ],
    }),
    TableQuestions({
      titulo:
        'Alteração na vazão / volume d\'água. Não significativa; Significativa',
      questoes: [
        { val: '0,0 - 1,9', q: 'Altera em até 20 %.' },
        {
          val: '2,0 - 3,9',
          q: 'Altera em até 40 %.',
        },
        {
          val: '4,0 - 5,9',
          q: 'Altera em até 60 %.',
        },
        {
          val: '6,0 - 7,9',
          q: 'Altera em até 80 %.',
        },
        { val: '8,0 - 10,0', q: 'Altera em até 100 %.' },
      ],
    }),
    TableQuestions({
      titulo:
        'Qual a previsão de reequilíbrio (natural) após o encerramento do empreendimento ou atividade?',
      questoes: [
        { val: '0,0 - 1,9', q: 'Em até 01 ano' },
        {
          val: '2,0 - 3,9',
          q: 'Em até 03 anos',
        },
        {
          val: '4,0 - 5,9',
          q: 'Em até 05 anos',
        },
        {
          val: '6,0 - 7,9',
          q: 'Em até 08 anos',
        },
        { val: '8,0 - 10,0', q: 'Em até ou mais de 10 anos' },
      ],
    }),
  ];
  const dataImpactoPaisagem = [
    TableQuestions({
      titulo:
        'Localização em relação a áreas protegidas (Unidades de Conservação: parques, reservas, estações ecológicas, etc. E áreas de preservação permanente definidas pela legislação, bem como municípios decretados "estâncias", "balneários", "patrimônio cultural", etc.).',
      questoes: mountedQuestions(
        'Não se aplica',
        'Fora da Zona de Amortecimento',
        'Na Zona de Amortecimento, não contigua ao limite da UC',
        'Dentro da Zona de Amortecimento, no limite da UC',
        'Dentro da UC'
      ),
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará mudança de paisagem (fisionomia da paisagem)?',
      questoes: genericQuestions,
    }),
    TableQuestions({
      titulo: 'Proximidade de centros urbanos.',
      questoes: mountedQuestions(
        'Não se aplica',
        'Centro urbano (população maior ou igual 60.000 hab.) distante até 50 km;',
        'Centro urbano (população maior ou igual 60.000 hab.) distante até 25 km;',
        'Centro urbano (população maior ou igual 60.000 hab.) distante até 10 km;',
        'Dentro do centro urbano (população maior ou igual 60.000 hab).'
      ),
    }),
    TableQuestions({
      titulo:
        'Qual a previsão de reversão após o encerramento do empreendimento ou atividade?',
      questoes: mountedQuestions(
        'Não se aplica',
        'Em até 03 anos',
        'Em até 05 anos',
        'Em até 08 anos',
        'Em até ou mais de 10 anos'
      ),
    }),
    TableQuestions({
      titulo:
        'Comprometimento do aquífero. Sem influência; Não diretamente relacionado; Diretamente relacionado.',
      questoes: genericQuestions,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará morte ou dano à fauna.',
      questoes: genericQuestions,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará morte ou dano à flora.',
      questoes: genericQuestions,
    }),
    TableQuestions({
      titulo:
        'Dano ao patrimônio cultural, histórico, artístico, arqueológico e turístico, e /ou a monumentos naturais.',
      questoes: genericQuestions2,
    }),
  ];
  const dataImpactoSubSolo = [
    TableQuestions({
      titulo:
        'O empreendimento causará agravamento dos processos de desertificação?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará alteração negativa na estrutura do solo?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento ou atividade provoca o favorecimento à erosão?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará alteração negativa na fertilidade do solo (NPK, MO, pH, micronutrientes)?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará alteração do uso do solo?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará contaminação do solo por substâncias poluentes inorgânicas?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará contaminação do solo por substâncias poluentes orgânicas?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento irá restringir a regeneração das características do solo?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará geração de resíduos que possam contaminar o solo?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará geração de efluentes que possam contaminar o solo?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará geração de resíduos que possam contaminar o subsolo?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará geração de efluentes que possam contaminar o subsolo?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará erosão superficial?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará erosão de encostas?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará compactação do solo?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará o aumento da impermeabilização do solo?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O empreendimento causará diminuição da evapotranspiração do solo?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará ecotoxidade no solo?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará ecotoxidade no sub-solo?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreedimento causará toxicidade a partir de emissões?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'O emprrendimento compromete o aquífero, por decorrencia do dano ao complexo solo-subsolo?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'Localização em relação a áreas protegidas (Unidades de Conservação: parques, reservas, estações ecológicas, etc. E áreas de preservação permanente definidas pela legislação).',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'Assoreamento de copos d\'água. Sem influência; Pequena intensidade; Média intensidade; Grande intensidade.',
      questoes: mountedQuestions(
        'Sem influência',
        'Suposta',
        'Pequena intensidade',
        'Média intensidade',
        'Grande intensidade'
      ),
    }),
    TableQuestions({
      titulo:
        'Morte ou dano à fauna, decorrente do dano ao complexo solo-subsolo.',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'Dano ao patrimônio cultural, histórico, artístico, arquitetônico e turístico, e /ou a monumentos naturais decorrentes do dano à água. Sem influência; Suposto; Comprovado',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'Objetivando comercialização. Sem influência; Atividade principal ou secundária.',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'Alteração na capacidade de uso da terra. Não altera; Na mesma classe (subclasse); Em 1 classe; Em mais de 1 classe',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'Dano/modificação ao relevo (alterações de declividade, desmoronamentos, etc.). Sem risco; Pequeno risco; Grande risco; Ocorrido',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
        'Qual a previsão de reequilíbrio (natural) após o encerramento do empreendimento ou atividade?',
      questoes: mountedQuestions(
        'Em até 01 ano',
        'Em até 03 anos',
        'Em até 05 anos',
        'Em até 08 anos',
        'Em até ou mais de 10 anos'
      ),
    }),
  ];
  return (
    <div
      aria-labelledby="custom-tabs-two-meio-fisico-tab"
      className="tab-pane fade"
      id="custom-tabs-two-meio-fisico"
      role="tabpanel"
    >
      <div className="row">
        <div className="col-md-2">
          <div
            aria-orientation="vertical"
            className="nav flex-column nav-tabs h-100"
            id="vert-tabs-tab2"
            role="tablist"
          >
            <a
              aria-controls="v-pills-home"
              aria-selected="true"
              className="nav-link active"
              data-toggle="pill"
              href="#v-pills-ar"
              id="v-pills-home-tab1"
              role="tab"
            >
                      Ar
            </a>
            <a
              aria-controls="v-pills-profile"
              aria-selected="false"
              className="nav-link"
              data-toggle="pill"
              href="#v-pills-agua"
              id="v-pills-profile-agua"
              role="tab"
            >
                      Água
            </a>
            <a
              aria-controls="v-pills-profile"
              aria-selected="false"
              className="nav-link"
              data-toggle="pill"
              href="#v-pills-paisagem"
              id="v-pills-profile-agua"
              role="tab"
            >
                      Paisagem
            </a>
            <a
              aria-controls="v-pills-profile"
              aria-selected="false"
              className="nav-link"
              data-toggle="pill"
              href="#v-pills-solo"
              id="v-pills-profile-agua"
              role="tab"
            >
                      Solo Subsolo
            </a>
          </div>
        </div>
        {/* ------------MENU_FIM---------------- */}
        <div className="col-md-10">
          <div
            className="tab-content"
            id="v-pills-tabContent"
          >
            <div
              aria-labelledby="v-pills-home-tab"
              className="tab-pane fade show active"
              id="v-pills-ar"
              role="tabpanel"
            >
              {Impacto(
                'AR',
                dataImpactoAr.map(table => table),
                'Impactos na dinâmica atmosférica'
              )}
            </div>
            <div
              aria-labelledby="v-pills-profile-tab"
              className="tab-pane fade"
              id="v-pills-agua"
              role="tabpanel"
            >
              {Impacto(
                'ÁGUA',
                dataImpactoAgua.map(table => table),
                'Impactos na hidrodinâmica: alteração no fluxo e vazão'
              )}
            </div>
            <div
              aria-labelledby="v-pills-profile-tab"
              className="tab-pane fade"
              id="v-pills-paisagem"
              role="tabpanel"
            >
              {Impacto(
                'PAISAGEM',
                dataImpactoPaisagem.map(table => table),
                'Dano ao patrimônio cultural, histórico, artístico, arqueológico e turístico: legalmente constituído.'
              )}
            </div>
            <div
              aria-labelledby="v-pills-profile-tab"
              className="tab-pane fade"
              id="v-pills-solo"
              role="tabpanel"
            >
              {Impacto(
                'SOLO SUBSOLO',
                dataImpactoSubSolo.map(table => table),
                'Impacto na dinâmica do complexo solo-subsolo: movimentação de solo, corte ou aterro'
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeioBiotico;
