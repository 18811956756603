import * as actions from './actionType';

export const loadMunicipios = (estado_id, params) => ({
  type: actions.LOAD_MUNICIPIOS,
  estado_id,
  params
});
export const getMunicipios = municipios => ({
  type: actions.MUNICIPIOS_DATA,
  municipios,
});
export const storeMunicipio = municipio => ({
  type: actions.STORE_MUNICIPIO,
  municipio,

});
export const getMunicipio = municipio => ({
  type: actions.GET_MUNICIPIO,
  municipio,
});

export const removerMunicipio = (id) => ({
  type: actions.REMOVER_MUNICIPIO,
  id
});

export const showMunicipio = id => ({
  type: actions.SHOW_MUNICIPIO,
  id,
});

export const updateMunicipio = (municipio, id) => ({
  type: actions.UPDATE_MUNICIPIO,
  municipio,
  id
});

export const getErros = errors => ({
  type: actions.MUNICIPIO_ERROR,
  errors,
});
