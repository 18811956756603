import * as actions from './actionType';

export const loadPessoaFisicas = () => ({
  type: actions.LOAD_PESSOA_FISICAS
});
export const getPessoaFisicas = pessoa_fisicas => ({
  type: actions.PESSOA_FISICAS_DATA,
  pessoa_fisicas,
});

export const getSelectPessoaFisica = pessoa_fisicas => ({
  type: actions.SELECT_PESSOA_FISICAS,
  pessoa_fisicas,
});

export const storePessoaFisica = pessoa_fisica => ({
  type: actions.STORE_PESSOA_FISICA,
  pessoa_fisica,

});
export const getPessoaFisica = pessoa_fisica => ({
  type: actions.GET_PESSOA_FISICA,
  pessoa_fisica,
});

export const removerPessoaFisica = (id) => ({
  type: actions.REMOVER_PESSOA_FISICA,
  id
});

export const showPessoaFisica = id => ({
  type: actions.SHOW_PESSOA_FISICA,
  id,
});

export const updatePessoaFisica = (pessoa_fisica, id) => ({
  type: actions.UPDATE_PESSOA_FISICA,
  pessoa_fisica,
  id
});

export const getErros = errors => ({
  type: actions.PESSOA_FISICA_ERROR,
  errors,
});
