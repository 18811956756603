const maskCEP = (v) => {
    if (typeof v !== 'string' || v === '') {
      return '';
    }
    v = v.replace(/\D/g, '')
    v = v.replace(/(\d{5})(\d)/, '$1-$2')  
    return v
  }
  
  export default maskCEP;
  