import React, {  useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import SunEditor from 'suneditor-react';
import plugins from 'suneditor/src/plugins'
import 'suneditor/dist/css/suneditor.min.css';
import Swal from 'sweetalert2';
import { storeMensagem, responderMensagem } from '~/store/modules/mensagem/action'

const NovaMensagem = ({processo, mensagem_id}) => {
  const dispatch = useDispatch();
  let history  = useHistory();
  const [show, setShow] = useState(false);
  const [destinatarios, setDestinatarios] = useState([]);

  useEffect(() => {
    let para = [];
    if (processo && processo.id && !processo.user_avaliador) {
      para = [
        {
          id: processo.user_id,
          nome: processo.pessoa.nome
        },
        {
          id: processo.user_responsavel_tecnico_id,
          nome: processo.pessoa_responsavel_tecnico.responsavel_tecnico.pessoa_tecnico_profissional.nome
        }
      ]
    }
    if(processo && processo.id && processo.user_avaliador) {
      para = [
        {
          id: processo.user_id,
          nome: processo.pessoa.nome
        },
        {
          id: processo.user_responsavel_tecnico_id,
          nome: processo.pessoa_responsavel_tecnico.responsavel_tecnico.pessoa_tecnico_profissional.nome
        },
        {
          id: processo.user_avaliador.id,
          nome: processo.user_avaliador.nome
        }
      ]
    }
    setDestinatarios(para);
  }, [processo]);

  const btnForEditor = () => ([
    ['undo', 'redo'],
    ['font', 'fontSize', 'formatBlock'],
    ['paragraphStyle', 'blockquote'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    ['fontColor', 'hiliteColor', 'textStyle'],
    ['removeFormat'],
    '/', // Line break
    ['outdent', 'indent'],
    ['align', 'horizontalRule', 'list', 'lineHeight'],
    ['table', 'link', 'image'], // You must add the 'katex' library at options to use the 'math' plugin.
    ['fullScreen', 'showBlocks', 'codeView'],
    ['preview']
  ]);

  const handleSubmit = async  (event) => {
    event.preventDefault();
    let dataForm = new FormData(event.target);
    if (processo && processo.id) {
      try {
        let result = await Swal.fire({
          title: 'Enviar Mensagem?',
          text: 'Deseja enviar a mensagem?!',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não',
        });
        if(result.value){
          if (!mensagem_id) {
            await dispatch(storeMensagem(processo.id, dataForm));
            await Swal.fire('Sucesso', 'Mensagem enviada com sucesso!', 'success');
            await history.go();
          } else {
            await dispatch(responderMensagem(mensagem_id, dataForm));
            await Swal.fire('Sucesso', 'Mensagem enviada com sucesso!', 'success');
            await history.go();
          }
        }
      } catch (error) {
        await Swal.fire('Erro', 'Erro ao Enviar mensagem', 'error');
      }
    }

  };

  return (<>
    <button className="btn btn-primary" onClick={() => setShow(true)}> <i className="fa fa-plus-circle"></i> {mensagem_id ? (<> Responder mensagem</>): (<> Nova mensagem</>)} </button>
    <Modal
      animation
      aria-labelledby="example-custom-modal-styling-title"
      dialogClassName="modal-xl"
      onHide={() => setShow(false)}
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title> <i className="fa fa-comment-dots"></i> {mensagem_id ? (<> Responder mensagem</>): (<> Nova mensagem</>)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="form-send-mensage" onSubmit={handleSubmit}>
        <div className="card card-primary card-outline">
          <div className="card-header">
            <h3 className="card-title">{mensagem_id ? (<> Responder mensagem</>): (<> Nova mensagem</>)}</h3>
          </div>

          <div className="card-body">
            {!mensagem_id && (<div className="form-group">
              <label> Para: </label>
              <select className="form-control" placeholder="Para:" name="user_receptor_id" required="required">
                {destinatarios.map(({id, nome}, index) => (<option key={index} value={id}>{nome}</option>))}
              </select>
            </div>)}

            {!mensagem_id && (<div className="form-group">
              <label> Assunto: </label>
              <input className="form-control" placeholder="Assunto:" name="titulo" required="required" />
            </div>)}

            <div className="form-group">
              <label> Conteúdo: </label>
              <SunEditor
                lang="pt_br"
                name="conteudo"
                setOptions={{
                  plugins: [plugins],
                  height: 200,
                  buttonList: btnForEditor()
                }}
              />
            </div>
            <div className="form-group">
              <div className="btn btn-default">
                <i className="fas fa-paperclip"></i> Anexos:
                <input type="file" multiple name="anexos[]" />
              </div>
              <p className="help-block">Max. 5MB</p>
            </div>
          </div>
        {mensagem_id && (<input type="hidden" name="mensagem_id" value={mensagem_id}/>)}
          <div className="card-footer">
            <div className="float-right">
              <button type="submit" className="btn btn-primary"><i className="far fa-envelope"></i>
                {mensagem_id ? (<> Responder</>): (<> Enviar</>)}
              </button>
            </div>
          </div>

        </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-default"
          onClick={() => setShow(false)}
          type="button"
        >
          <i className="fa fa-window-close" /> Fechar
        </button>
      </Modal.Footer>
    </Modal>
  </>);
};

export default NovaMensagem;
