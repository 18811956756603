import React  from 'react';
import { Link } from 'react-router-dom';
import {Row, Col} from 'react-bootstrap';


const Generica = ({myInformation}) => {
  return ( <div className="container-fluid">
    <h1>Fluxo dos seus processos ambientais</h1>
    <Row>
      <Col md={2}>
        <div className="small-box bg-info">
          <div className="inner">
            <h3>{myInformation ? myInformation.etapa_1 : '0'}</h3>
            <h4>Etapa 1</h4>
            <p>
              {' '}
                  Cadastros
              <br />
              <br />
            </p>
          </div>
          <div className="icon">
            <i className="fa fa-plus" />
          </div>
          <Link
            className="small-box-footer"
            to="/processos/create/pass_1"
          >
                Abrir um processo <i className="fas fa-arrow-circle-right" />
          </Link>
        </div>
      </Col>
      <Col md={2}>
        <div className="small-box bg-info">
          <div className="inner">
            <h3>{myInformation ? myInformation.etapa_2 : '0' }</h3>
            <h4>Etapa 2</h4>
            <p> Análise Prévia de Impacto Ambiental</p>
          </div>
          <div className="icon">
            <i className="fa fa-edit" />
          </div>
          <Link
            className="small-box-footer"
            to="/processos"
          >
                Ver processos <i className="fas fa-arrow-circle-right" />
          </Link>
        </div>
      </Col>
      <Col md={2}>
        <div className="small-box bg-info">
          <div className="inner">
            <h3>{myInformation ? myInformation.etapa_3 : '0' }</h3>
            <h4>Etapa 3</h4>
            <p>
                  Estudo e Projetos <br />
              <br />
            </p>
          </div>
          <div className="icon">
            <i className="fa fa-book" />
          </div>
          <Link
            className="small-box-footer"
            to="/processos"
          >
                Ver processos <i className="fas fa-arrow-circle-right" />
          </Link>
        </div>
      </Col>
      <Col md={2}>
        <div className="small-box bg-info">
          <div className="inner">
            <h3>{myInformation ? myInformation.etapa_4 : '0' }</h3>
            <h4>Etapa 4</h4>
            <p>Avaliação Técnica de Impacto Ambiental</p>
          </div>
          <div className="icon">
            <i className="fa fa-clipboard-check" />
          </div>
          <Link
            className="small-box-footer"
            to="/processos"
          >
                Ver processos <i className="fas fa-arrow-circle-right" />
          </Link>
        </div>
      </Col>

      <Col md={2}>
        <div className="small-box bg-info">
          <div className="inner">
            <h3>{myInformation ? myInformation.etapa_5 : '0' }</h3>
            <h4>Etapa 5</h4>
            <p>
                  Vistoria Técnica Ambiental <br /> <br />{' '}
            </p>
          </div>
          <div className="icon">
            <i className="fa fa-calendar-check" />
          </div>
          <Link
            className="small-box-footer"
            to="/processos"
          >
                Ver processos <i className="fas fa-arrow-circle-right" />
          </Link>
        </div>
      </Col>

      <Col md={2}>
        <div className="small-box bg-info">
          <div className="inner">
            <h3>{myInformation ? myInformation.etapa_6 : '0' }</h3>
            <h4>Etapa 6</h4>
            <p>
                  Decisão
              <br /> <br />{' '}
            </p>
          </div>
          <div className="icon">
            <i className="fa fa-check-double" />
          </div>
          <Link
            className="small-box-footer"
            to="/processos"
          >
                Ver processos <i className="fas fa-arrow-circle-right" />
          </Link>
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">FLUXO DO PROCESSO AMBIENTAL</h3>
          </div>
          <div className="card-body">
            <ul>
              <li>
                <strong>Etapa 1</strong> Cadastro
              </li>
              <li>
                <strong>Etapa 2</strong> Análise Prévia de Impacto Ambiental
              </li>
              <li>
                <strong>Etapa 3</strong> Estudo e Projetos
              </li>
              <li>
                <strong>Etapa 4</strong> Avaliação Técnica de Impacto
                    Ambiental
              </li>
              <li>
                <strong>Etapa 5</strong> Vistoria Técnica Ambiental
              </li>
              <li>
                <strong>Etapa 6</strong> Decisão
              </li>
            </ul>
          </div>
        </div>

      </Col>
    </Row>
  </div>);
}

export default Generica;
