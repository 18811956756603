import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as Motorista from '~/services/Motorista';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadMotoristasWorker({params}) {
  try {
    const response = yield call(Motorista.load, params);
    yield put(actions.getMotoristas(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* loadSelectMotoristasWorker() {
  try {
    const response = yield call(Motorista.select);
    yield put(actions.getSelectMotoristas(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* showMotoristaWorker({id}) {
  try {
    const response = yield call(Motorista.show, id);
    yield put(actions.getMotorista(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updateMotoristaWorker({ motorista, id}) {
  try {
    const response = yield call(Motorista.update, id, motorista);
    yield put(actions.getMotorista(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeMotoristaWorker({motorista}) {
  try {
    const response = yield call(Motorista.store, motorista);
    yield put(actions.getMotorista(response));
    yield put(actions.getErros({save:true}));
    yield put(getErros({save:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* removerMotoristaWorker({id}) {
  try {
    yield call(Motorista.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherMotorista() {
  yield takeLatest(actionType.LOAD_MOTORISTAS, loadMotoristasWorker);
  yield takeLatest(actionType.LOAD_SELECT_MOTORISTAS, loadSelectMotoristasWorker);
  yield takeLatest(actionType.STORE_MOTORISTA, storeMotoristaWorker);
  yield takeLatest(actionType.SHOW_MOTORISTA, showMotoristaWorker);
  yield takeLatest(actionType.UPDATE_MOTORISTA, updateMotoristaWorker);
  yield takeLatest(actionType.REMOVER_MOTORISTA, removerMotoristaWorker);
}

export default function* saga() {
  yield all([watcherMotorista()]);
}
