import { all, put, call, takeLatest } from 'redux-saga/effects';
import * as FotoVistoria from '~/services/FotoVistoria';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadFotosVistoriaWorker({ params }) {
  try {
    const response = yield call(FotoVistoria.load, params);
    yield put(actions.getFotosVistoria(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeFotoVistoriaWorker({ foto_vistoria }) {
  try {
    const response = yield call(FotoVistoria.store, foto_vistoria);
    yield put(actions.getFotoVistoria(response));
    yield put(actions.changeStatus(response.status));
    yield put(actions.getErros({ save: true }));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* removeFotoVistoriaWorker({ id }) {
  try {
    const response = yield call(FotoVistoria.remove, id);
    yield put(actions.getFotoVistoria({ data: {} }));
    yield put(actions.changeStatus(response.status));
    yield put(actions.getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* watcherFotoVistoria() {
  yield takeLatest(actionType.LOAD_FOTOS_VISTORIA, loadFotosVistoriaWorker);
  yield takeLatest(actionType.STORE_FOTO_VISTORIA, storeFotoVistoriaWorker);
  yield takeLatest(actionType.REMOVE_FOTO_VISTORIA, removeFotoVistoriaWorker);
}

export default function* saga() {
  yield all([watcherFotoVistoria()]);
}
