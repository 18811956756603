import * as actions from './actionType';

export const ErrorsReducer = (state = {errors: {}}, action) => {
  switch (action.type) {
    case actions.GET_SERVER_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
