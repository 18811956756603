export const LOAD_ATIVIDADES = 'LOAD_ATIVIDADES';
export const SELECT_ATIVIDADES = 'SELECT_ATIVIDADES';
export const ATIVIDADE_ERROR = 'ATIVIDADE_ERROR';
export const ATIVIDADE_GET_ERRORS = 'ATIVIDADE_GET_ERRORS';
export const ATIVIDADES_DATA = 'ATIVIDADES_DATA';
export const STORE_ATIVIDADE = 'STORE_ATIVIDADE';
export const GET_ATIVIDADE = 'GET_ATIVIDADE';
export const REMOVER_ATIVIDADE = 'REMOVER_ATIVIDADE';
export const SHOW_ATIVIDADE = 'SHOW_ATIVIDADE';
export const UPDATE_ATIVIDADE = 'UPDATE_ATIVIDADE';
