import React from 'react';
import { Form } from 'react-bootstrap';

const FormCad = ({ handleSubmit, schema, observacao, setObservacao, errors }) => {
  const handleChange = event => {
    const { name, value, files } = event.target;
    setObservacao({
      ...observacao,
      [name]: files ? files[0] : value,
    });
  };

  return (
    <Form
      id="form_observacao"
      onSubmit={handleSubmit}
      schema={schema}
    >
      <div className="form-group">
        <label htmlFor="descricao"> Descrição </label>
        <Form.Control
          as="textarea"
          id="descricao"
          isInvalid={!!errors.descricao}
          name="descricao"
          onChange={handleChange}
          placeholder="Descrição"
          rows={8}
          type="text"
          value={observacao.descricao || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.descricao}
        </Form.Control.Feedback>
      </div>
    </Form>
  );
};

export default FormCad;
