import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { Loading } from '~/components';
// import { SubMenuList } from '../components/SubMenu';
import { showObservacao } from '~/store/modules/observacao/action';
// import Create from '../../Atividade/Create';
// import CreateMotorista from '../../Motorista/Create';
// import CreateVeiculo from '../../Veiculo/Create';

const Show = () => {
  let history = useHistory();
  // let location = useLocation();
  // let modal = location.state && location.state.modal;
  const dispatch = useDispatch();
  const { observacao_id } = useParams();
  const [observacaoShow, setObservacaoShow] = useState({});

  const { observacao } = useSelector(({ ObservacaoReducer }) => ObservacaoReducer);

  useEffect(() => {
    dispatch(showObservacao(observacao_id));
  }, [dispatch, observacao_id]);

  useEffect(() => {
    if (observacao.id === parseInt(observacao_id)) {
      setObservacaoShow(observacao);
    }
  }, [observacao, observacao_id]);

  const handleClose = () => {
    history.goBack();
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-xl"
      onHide={handleClose}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Observação
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {observacaoShow.id === parseInt(observacao_id) ? (
          <p>
            {observacao.descricao}
          </p>
        ):
          <Loading />
        }
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-default"
          onClick={handleClose}
        >
          <i className="fa fa-arrow-alt-circle-left" /> Voltar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Show;
