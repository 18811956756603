import * as actions from './actionType';

export const loadLegislacoes = (params) => ({
  type: actions.LOAD_LEGISLACOES,
  params
});
export const getLegislacoes = legislacoes => ({
  type: actions.LEGISLACOES_DATA,
  legislacoes,
});

export const loadSelectLegislacoes = legislacoes => ({
  type: actions.LOAD_SELECT_LEGISLACOES,
  legislacoes,
});

export const getSelectLegislacoes = legislacoes => ({
  type: actions.SELECT_LEGISLACOES,
  legislacoes,
});

export const storeLegislacao = legislacao => ({
  type: actions.STORE_LEGISLACAO,
  legislacao,
});
export const getLegislacao = legislacao => ({
  type: actions.GET_LEGISLACAO,
  legislacao,
});

export const removerLegislacao = (id) => ({
  type: actions.REMOVER_LEGISLACAO,
  id
});

export const activeLegislacao = id => ({
  type: actions.ACTIVE_LEGISLACAO,
  id,
});

export const showLegislacao = id => ({
  type: actions.SHOW_LEGISLACAO,
  id,
});

export const updateLegislacao = (legislacao, id) => ({
  type: actions.UPDATE_LEGISLACAO,
  legislacao,
  id
});

export const getErros = errors => ({
  type: actions.LEGISLACAO_ERROR,
  errors,
});
