import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GiTreeBranch } from 'react-icons/gi';
import { Modal, Form, Tabs, Tab } from 'react-bootstrap';

import Swal from 'sweetalert2';
import { updateCondicionantesProcesso } from '~/store/modules/processo/action';

const CondicionantesAmbientais = ({
    processo_id,
    cond_geral,
    cond_especifica,
    setCondicionantesIsOk,
    onlyView,
    has_apia_atia,
}) => {
    const dispatch = useDispatch();
    let [show, setShow] = useState(false);
    const [condicionantesIds, setCondicionantesIds] = useState([]);

    useEffect(() => {
        if (cond_especifica && cond_especifica.length) {
            let mark = [];
            cond_especifica.map((condicionante) => {
                if (condicionante.checked) {
                    mark.push(condicionante.id);
                }
                return null;
            });
            setCondicionantesIds(mark);
        }
    }, [cond_geral, cond_especifica]);

    const handleClose = async () => {
        await setShow(false);
    };

    const handleCheck = (event) => {
        let value = event.target.value;
        if (event.target.checked) {
            setCondicionantesIds([...condicionantesIds, parseInt(value)]);
        } else {
            setCondicionantesIds(
                condicionantesIds.filter(
                    (item) => parseInt(item) !== parseInt(value)
                )
            );
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (processo_id) {
            await dispatch(
                updateCondicionantesProcesso(processo_id, {
                    condicionante_id: condicionantesIds,
                })
            );
            await Swal.fire('Sucesso', 'Salvo com sucesso!', 'success');
        }
    };

    useEffect(() => {
        if (
            condicionantesIds.length > 0 ||
            (has_apia_atia === false && cond_especifica.length === 0)
        ) {
            setCondicionantesIsOk(true);
        }
    }, [
        condicionantesIds,
        setCondicionantesIsOk,
        cond_especifica,
        has_apia_atia,
    ]);

    return (
        <>
            <>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">
                            Condicionantes Ambientais
                        </h3>
                    </div>
                    <div className="card-body">
                        <button
                            //   className={`btn ${condicionantesIds.length ? 'btn-primary' : 'btn-success'} btn-block`}
                            className={`btn ${
                                has_apia_atia === false &&
                                cond_especifica.length === 0
                                    ? 'btn-primary'
                                    : condicionantesIds.length
                                    ? 'btn-primary'
                                    : 'btn-success'
                            } btn-block`}
                            onClick={() => setShow(true)}
                        >
                            <GiTreeBranch /> Condicionantes Ambientais
                        </button>
                    </div>
                </div>
                <Modal
                    animation
                    aria-labelledby="example-custom-modal-styling-title"
                    dialogClassName="modal-xl"
                    onHide={handleClose}
                    show={show}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            BASE GERAL DE CONDICIONANTES AMBIENTAIS
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="table-responsive">
                            <form
                                id="form-set-condicionante"
                                onSubmit={handleSubmit}
                            >
                                <Tabs
                                    defaultActiveKey="geral"
                                    id="noanim-tab-example"
                                    transition={false}
                                >
                                    <Tab eventKey="geral" title="Geral">
                                        <table className="table table-bordered table-hover table-striped">
                                            <thead>
                                                <tr>
                                                    <th> Condicionante </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cond_geral &&
                                                    cond_geral.length &&
                                                    cond_geral.map(
                                                        ({ id, nome }) => (
                                                            <tr
                                                                key={`${id}-condicionante-key`}
                                                            >
                                                                <td>{nome}</td>
                                                            </tr>
                                                        )
                                                    )}
                                            </tbody>
                                        </table>
                                    </Tab>
                                    <Tab
                                        eventKey="especifica"
                                        title="Especifica"
                                    >
                                        <table className="table table-bordered table-hover table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Meio</th>
                                                    <th> Condicionante </th>
                                                    <th> Ação </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cond_especifica &&
                                                cond_especifica.length ? (
                                                    cond_especifica.map(
                                                        ({
                                                            id,
                                                            nome,
                                                            meio_analise_nome,
                                                            checked,
                                                        }) => (
                                                            <tr
                                                                key={`${id}-condicionante-key`}
                                                            >
                                                                <td>
                                                                    {
                                                                        meio_analise_nome
                                                                    }
                                                                </td>
                                                                <td>{nome}</td>
                                                                <td>
                                                                    <Form.Check
                                                                        className="mb-3"
                                                                        defaultChecked={
                                                                            checked
                                                                        }
                                                                        disabled={
                                                                            onlyView
                                                                        }
                                                                        id={`condicionante_id-${id}`}
                                                                        label="Habilitar"
                                                                        name="condicionante_id[]"
                                                                        onClick={
                                                                            handleCheck
                                                                        }
                                                                        type="switch"
                                                                        value={
                                                                            id
                                                                        }
                                                                    />
                                                                </td>
                                                            </tr>
                                                        )
                                                    )
                                                ) : (
                                                    <tr />
                                                )}
                                            </tbody>
                                        </table>
                                    </Tab>
                                </Tabs>
                            </form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {!onlyView && (
                            <button
                                className="btn btn-success"
                                form="form-set-condicionante"
                                type="submit"
                            >
                                {' '}
                                <i className="fa fa-save" /> Salvar
                            </button>
                        )}
                        <button
                            className="btn btn-default"
                            onClick={handleClose}
                            type="button"
                        >
                            <i className="fa fa-window-close" /> Fechar
                        </button>
                    </Modal.Footer>
                </Modal>
            </>
        </>
    );
};
export default CondicionantesAmbientais;
