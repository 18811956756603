import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateVistoria } from '../../../store/modules/vistoria/action';
import Swal from 'sweetalert2';
import { FaEdit } from 'react-icons/fa';


const Edit = ({vistoria}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { errors } = useSelector(({ VistoriaReducer }) => VistoriaReducer);
    const [messageError, setMessageError] = useState(''); 
    let [show, setShow] = useState(false);

    const date = (date) => {
        if(date != null) {
            let data = date.split(' ')
            return data[0]
        }        
    }
    const hour = (date) => {      
      if(date != null) {          
            let data = date.split(' ')
            let hour = data[1].split(':')
            return hour[0]
            
        }
    }
    const minute = (date) => {
        if(date != null) {
            let data = date.split(' ')
            let minute = data[1].split(':')
            return minute[1]
        }
    }
    const second = (date) => {
        if(date != null) {
            let data = date.split(' ')
            let second = data[1].split(':')
            return second[2]
        }
    }
    
    let [dataForm, setDataForm] = useState({
      processo_id: vistoria.processo_id,
      veiculo_id: vistoria.veiculo_entidade_id,
      motorista_id: vistoria.motorista_entidade_id,
      data_saida: date(vistoria.data_saida),
      data_chegada: date(vistoria.data_chegada),
      hora_de_saida: hour(vistoria.data_saida),
      minutos_de_saida: minute(vistoria.data_saida),
      segundos_de_saida: second(vistoria.data_saida),
      hora_de_chegada: hour(vistoria.data_chegada),
      minutos_de_chegada: minute(vistoria.data_chegada),
      segundos_de_chegada: second(vistoria.data_chegada),
    });      

    useEffect(()=>{
      if(errors && errors.data){
        setMessageError(errors.data['data'].message);
      }  
  
    }, [errors])

    const handleChangeDataForm = (event) => {
      setDataForm({...dataForm, [event.target.name]: event.target.value });
    };
    const handleSubmit = async (event) => {
      event.preventDefault();
      await dispatch(updateVistoria({
        motorista_entidade_id: dataForm.motorista_id,
        veiculo_entidade_id: dataForm.veiculo_id,
        processo_id: dataForm.processo_id,
        data_saida: `${dataForm.data_saida} ${dataForm.hora_de_saida}:${dataForm.minutos_de_saida}:${dataForm.segundos_de_saida}`,
        data_chegada: `${dataForm.data_chegada} ${dataForm.hora_de_chegada}:${dataForm.minutos_de_chegada}:${dataForm.segundos_de_chegada}`
      }, vistoria.id, dataForm));
      await Swal.fire('Sucesso', 'Data alterada com sucesso!', 'success');
      await history.go();
    }
    const handleClose = () => {
      setShow(false);
      history.go();
    };
    return (
        <>
        <button
        className="btn btn-default"
        onClick={() => setShow(true)}       
        >
        <FaEdit /> Editar
        </button>
        <Modal
            animation
            aria-labelledby="example-custom-modal-styling-title"
            dialogClassName="modal-xl"
            onHide={handleClose}
            show={show}
          >
          <Modal.Header closeButton>
            <Modal.Title>EDITAR VISTORIA TÉCNICA AMBIENTAL E MONITORAMENTO</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <form
            id="form_agendar_vistoria"
            onSubmit={handleSubmit}
          >
            <div className="row">
              <div className="form-group col-6">
                <label className="label">Data saída</label>
                <input
                  className="form-control"
                  name="data_saida"
                  onChange={handleChangeDataForm}
                  required="required"
                  type="date"
                  value={dataForm.data_saida || ''}
                />
              </div>
              <div className="form-group col-2">
                <label className="label">Hora saída</label>

                <select
                  className="form-control"
                  id="select_hora_saida"
                  name="hora_de_saida"
                  onChange={handleChangeDataForm}
                  required="required"
                  value={dataForm.hora_de_saida || ''}
                >
                  {Array.from(Array(24)).map((_, i) => (<option
                    key={i}
                    value={i <10 ? `0${i}` : i}
                  >{i <10 ? `0${i}` : i }</option>))}
                </select>
              </div>
              <div className="form-group col-2">
                <label className="label">Minutos saída</label>
                <select
                  className="form-control"
                  id="select_minutos_saida"
                  name="minutos_de_saida"
                  onChange={handleChangeDataForm}
                  required="required"
                  value={dataForm.minutos_de_saida || ''}
                >
                  {Array.from(Array(60)).map((_, i) => (<option
                    key={i}
                    value={i <10 ? `0${i}` : i}
                  >{i <10 ? `0${i}` : i }</option>))}
                </select>
              </div>
              <div className="form-group col-2">
                <label className="label">Segundos saída</label>
                <select
                  className="form-control"
                  id="select_segundos_saida"
                  name="segundos_de_saida"
                  onChange={handleChangeDataForm}
                  required="required"
                  value={dataForm.segundos_de_saida || ''}
                >
                  {Array.from(Array(60)).map((_, i) => (<option
                    key={i}
                    value={i <10 ? `0${i}` : i}
                  >{i <10 ? `0${i}` : i }</option>))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-6">
                <label className="label">Data chegada</label>
                <input
                  className="form-control"
                  name="data_chegada"
                  onChange={handleChangeDataForm}
                  required="required"
                  type="date"
                  value={dataForm.data_chegada || ''}
                />
              </div>
              <div className="form-group col-2">
                <label className="label">Hora chegada</label>

                <select
                  className="form-control"
                  id="select_hora_chegada"
                  name="hora_de_chegada"
                  onChange={handleChangeDataForm}
                  required="required"
                  value={dataForm.hora_de_chegada || ''}
                >
                  {Array.from(Array(24)).map((_, i) => (<option
                    key={i}
                    value={i <10 ? `0${i}` : i}
                  >{i <10 ? `0${i}` : i }</option>))}
                </select>
              </div>
              <div className="form-group col-2">
                <label className="label">Minutos chegada</label>
                <select
                  className="form-control"
                  id="select_minutos_chegada"
                  name="minutos_de_chegada"
                  onChange={handleChangeDataForm}
                  required="required"
                  value={dataForm.minutos_de_chegada || ''}
                >
                  {Array.from(Array(60)).map((_, i) => (<option
                    key={i}
                    value={i <10 ? `0${i}` : i}
                  >{i <10 ? `0${i}` : i }</option>))}
                </select>
              </div>
              <div className="form-group col-2">
                <label className="label">Segundos chegada</label>
                <select
                  className="form-control"
                  id="select_segundos_chegada"
                  name="segundos_de_chegada"
                  onChange={handleChangeDataForm}
                  required="required"
                  value={dataForm.segundos_de_chegada || ''}
                >
                  {Array.from(Array(60)).map((_, i) => (<option
                    key={i}
                    value={i <10 ? `0${i}` : i}
                  >{i <10 ? `0${i}` : i }</option>))}
                </select>
              </div>
            </div>
            {messageError ? 
              <div
                className="alert alert-danger"
                role="alert"
              >
                {messageError}</div> : ''}
          </form>

        </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-success"
              form="form_agendar_vistoria"
              type="submit"
            >
              <i className="fa fa-save"/> Salvar
            </button>
            <button
              className="btn btn-default"
              onClick={handleClose}
              type="button"
            >
              <i className="fa fa-window-close" /> Fechar
            </button>
          </Modal.Footer>
          </Modal>
        </>
    )
};
export default Edit;