export const LOAD_CONFIG_AUTORIZACOES = 'LOAD_CONFIG_AUTORIZACOES';
export const SELECT_CONFIG_AUTORIZACOES = 'SELECT_CONFIG_AUTORIZACOES';
export const CONFIG_AUTORIZACAO_ERROR = 'CONFIG_AUTORIZACAO_ERROR';
export const CONFIG_AUTORIZACAO_GET_ERRORS = 'CONFIG_AUTORIZACAO_GET_ERRORS';
export const CONFIG_AUTORIZACOES_DATA = 'CONFIG_AUTORIZACOES_DATA';
export const STORE_CONFIG_AUTORIZACAO = 'STORE_CONFIG_AUTORIZACAO';
export const GET_CONFIG_AUTORIZACAO = 'GET_CONFIG_AUTORIZACAO';
export const REMOVER_CONFIG_AUTORIZACAO = 'REMOVER_CONFIG_AUTORIZACAO';
export const SHOW_CONFIG_AUTORIZACAO = 'SHOW_CONFIG_AUTORIZACAO';
export const UPDATE_CONFIG_AUTORIZACAO = 'UPDATE_CONFIG_AUTORIZACAO';
export const ACTIVATE_CONFIG_AUTORIZACAO = 'ACTIVATE_CONFIG_AUTORIZACAO';
export const LOAD_LIST_CONFIG_AUTORIZACOES = 'LOAD_LIST_CONFIG_AUTORIZACOES';

export const CHANGE_STATUS_CODE = 'CHANGE_STATUS_CODE';
