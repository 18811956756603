export const LOAD_LISTA_DOCUMENTOS = 'LOAD_LISTA_DOCUMENTOS';
export const SELECT_LISTA_DOCUMENTOS = 'SELECT_LISTA_DOCUMENTOS';
export const LISTA_DOCUMENTO_ERROR = 'LISTA_DOCUMENTO_ERROR';
export const LISTA_DOCUMENTO_GET_ERRORS = 'LISTA_DOCUMENTO_GET_ERRORS';
export const LISTA_DOCUMENTOS_DATA = 'LISTA_DOCUMENTOS_DATA';
export const STORE_LISTA_DOCUMENTO = 'STORE_LISTA_DOCUMENTO';
export const GET_LISTA_DOCUMENTO = 'GET_LISTA_DOCUMENTO';
export const REMOVER_LISTA_DOCUMENTO = 'REMOVER_LISTA_DOCUMENTO';
export const DELETE_LISTA_DOCUMENTO = 'DELETE_LISTA_DOCUMENTO';
export const SHOW_LISTA_DOCUMENTO = 'SHOW_LISTA_DOCUMENTO';
export const UPDATE_LISTA_DOCUMENTO = 'UPDATE_LISTA_DOCUMENTO';
export const CHANGE_STATUS_CODE = 'CHANGE_STATUS_CODE';
export const ACTIVE_LISTA_DOCUMENTO = 'ACTIVE_LISTA_DOCUMENTO';
