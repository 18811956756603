import * as Yup from 'yup';
import {
  format as formatCPF,
  validate as validateCPF,
} from 'gerador-validador-cpf';

// const validadarCNPJ = (cnpj) => {
//   cnpj = cnpj.replace(/[^\d]+/g,'');
//
//   if(cnpj === '') return false;
//
//   if (cnpj.length !== 14)
//     return false;
//
//   // Elimina CNPJs invalidos conhecidos
//   if (cnpj === '00000000000000' ||
//         cnpj === '11111111111111' ||
//         cnpj === '22222222222222' ||
//         cnpj === '33333333333333' ||
//         cnpj === '44444444444444' ||
//         cnpj === '55555555555555' ||
//         cnpj === '66666666666666' ||
//         cnpj === '77777777777777' ||
//         cnpj === '88888888888888' ||
//         cnpj === '99999999999999')
//     return false;
//
//   // Valida DVs
//   let tamanho = cnpj.length - 2
//   let numeros = cnpj.substring(0,tamanho);
//   let digitos = cnpj.substring(tamanho);
//   let soma = 0;
//   let pos = tamanho - 7;
//   for (let i = tamanho; i >= 1; i =i+1) {
//     soma += numeros.charAt(tamanho - i) * pos--;
//     if (pos < 2)
//       pos = 9;
//   }
//   let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
//   if (String(resultado) !== digitos.charAt(0))
//     return false;
//
//   tamanho = tamanho + 1;
//   numeros = cnpj.substring(0,tamanho);
//   soma = 0;
//   pos = tamanho - 7;
//   for (let i = tamanho; i >= 1; i--) {
//     soma += numeros.charAt(tamanho - i) * pos--;
//     if (pos < 2)
//       pos = 9;
//   }
//   resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
//   if (String(resultado) !== digitos.charAt(1))
//     return false;
//
//   return true;
// };

const testCPF = input => {
  const cpf = formatCPF(input);
  return validateCPF(cpf);
};

const schema = Yup.object().shape({
  nome: Yup.string().required('Valor requerido'),
  nome_fantasia: Yup.string().required('Valor requerido'),
  cnpj: Yup.string()
    .length(14, 'Tamanho incorreto')
    .required('Valor requerido'),
  certidao_simplificada_jc: Yup.string().required('Valor requerido'),
  logradouro: Yup.string().required('Valor requerido'),
  numero_endereco: Yup.string().required('Valor requerido'),
  cidade: Yup.string().required('Valor requerido'),
  bairro: Yup.string().required('Valor requerido'),
  cep: Yup.string().required('Valor requerido'),
  numero_whatsapp: Yup.string().required('Valor requerido'),
  telefone: Yup.string().required('Valor requerido'),
  site: Yup.string().url('Url inválida'),
  uf_endereco_id: Yup.number().required('Valor requerido'),
  porte_empreendimento_id: Yup.number().required('Valor requerido'),
  nome_responsavel_legal: Yup.string().required('Valor requerido'),
  funcao_responsavel_legal: Yup.string().required('Valor requerido'),
  cpf_responsavel_legal: Yup.string()
    .test('valid_cpf', 'CPF inválido', (val) => {
      if (
        val !== null &&
        val !== '' &&
        val !== undefined &&
        val.length === 11
      ) {
        return testCPF(val);
      }
      return true;
    })
    .length(11, 'Tamanho incorreto')
    .required('Valor requerido'),
  rg_responsavel_legal: Yup.string().required('Valor requerido'),
  endereco_responsavel_legal: Yup.string().required('Valor requerido'),
  email: Yup.string().email().required('Valor requerido'),

});

export default schema;
