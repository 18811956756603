import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa';
import Content from '~/layouts/Dash/components/Content';
import {
  MeioBiotico,
  MeioFisico,
  MeioSocioeconomico
} from './components';

const Apia = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const breadcrumbs = [
    {
      label: 'Processos',
      to: '/processos',
      active: false,
    }, {
      label: 'Lista',
      to: '/processos',
      active: true,
    }
  ];

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="AVALIAÇÃO PRÉVIA DE IMPACTO AMBIENTAL - APIA"
    >
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Preencha os campo abaixo a partir da estudos de campo e revisão
          bibliográfica.</h3>
          <div className="card-tools">
            <Link
              className="btn btn-light"
              to="/processos"
            >
              <FaArrowAltCircleLeft /> Cancelar
            </Link>
          </div>
        </div>
        <div className="card-body">
          <h3>
            Preenche os campos a partir de seus estudos bibliográficos e da
            visita técnica.
          </h3>
          <p>
            Impactos na dinâmica da comunidade, para maciços maiores que 10,0
            ha. Neste caso, analisar danos aos indivíduos e à comunidade.
          </p>
        </div>
      </div>
      <div className="card card-success card-outline card-outline-tabs">
        <div className="card-header p-0 pt-1 border-bottom-0">
          <ul
            className="nav nav-tabs"
            id="custom-tabs-two-tab"
            role="tablist"
          >
            <li className="nav-item">
              <a
                aria-controls="custom-tabs-two-meio-biotico"
                aria-selected="true"
                className="nav-link active"
                data-toggle="pill"
                href="#custom-tabs-two-meio-biotico"
                id="custom-tabs-two-meio-biotico-tab"
                role="tab"
              > Meio Biótico </a>
            </li>
            <li className="nav-item">
              <a
                aria-controls="custom-tabs-two-meio-fisico"
                aria-selected="false"
                className="nav-link"
                data-toggle="pill"
                href="#custom-tabs-two-meio-fisico"
                id="custom-tabs-two-meio-fisico-tab"
                role="tab"
              >Meio Físico</a>
            </li>
            <li className="nav-item">
              <a
                aria-controls="custom-tabs-two-meio-socioeconomico"
                aria-selected="false"
                className="nav-link"
                data-toggle="pill"
                href="#custom-tabs-two-meio-socioeconomico"
                id="custom-tabs-two-meio-socioeconomico-tab"
                role="tab"
              >Meio Sócioeconômico</a>
            </li>
          </ul>
        </div>

        <div className="card-body">
          <div
            className="tab-content"
            id="custom-tabs-two-tabContent"
          >
            <MeioBiotico />
            <MeioFisico />
            <MeioSocioeconomico />
          </div>
        </div>
        <div className="card-footer">
          <div className="form-group">
            <Link
              className="btn btn-light"
              to="/processos/check-list"
            >
              <FaArrowAltCircleLeft /> Voltar
            </Link>
            <Link
              className="btn btn-success"
              to="/processos/pre-processo"
            >
              <FaArrowAltCircleRight /> Finalizar Avaliação
            </Link>
          </div>
        </div>
      </div>
    </Content>
  );
};
export default Apia;
