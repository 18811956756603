import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as PessoaFisica from '~/services/PessoaFisica';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadPessoaFisicasWorker() {
  try {
    const response = yield call(PessoaFisica.load);
    yield put(actions.getPessoaFisicas(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* showPessoaFisicaWorker({id}) {
  try {
    const response = yield call(PessoaFisica.show, id);
    yield put(actions.getPessoaFisica(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updatePessoaFisicaWorker({ pessoa_fisica, id}) {
  try {
    const response = yield call(PessoaFisica.update, id, pessoa_fisica);
    yield put(actions.getPessoaFisica(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storePessoaFisicaWorker({pessoa_fisica}) {
  try {
    const response = yield call(PessoaFisica.store, pessoa_fisica);
    yield put(actions.getPessoaFisica(response));
    yield put(actions.getErros({save:true}));
    yield put(getErros({save:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* removerPessoaFisicaWorker({id}) {
  try {
    yield call(PessoaFisica.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherPessoaFisica() {
  yield takeLatest(actionType.LOAD_PESSOA_FISICAS, loadPessoaFisicasWorker);
  yield takeLatest(actionType.STORE_PESSOA_FISICA, storePessoaFisicaWorker);
  yield takeLatest(actionType.SHOW_PESSOA_FISICA, showPessoaFisicaWorker);
  yield takeLatest(actionType.UPDATE_PESSOA_FISICA, updatePessoaFisicaWorker);
  yield takeLatest(actionType.REMOVER_PESSOA_FISICA, removerPessoaFisicaWorker);
}

export default function* saga() {
  yield all([watcherPessoaFisica()]);
}
