import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {api} from '../../services/api';
import NotFoundImg from '../../assets/images/not_found.svg';
import { Loading } from '~/components';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}


export default function CadastroSigau() {
  const [loading, setLoading] = useState(true);
  let query = useQuery();
  let history = useHistory();

  useEffect(() => {
    async function userSearch(){
      let dataQuery = window.location.search;
      const { data } = await api.get(`verificar_usuario?cpf_cnpj=${query.get('cpf_cnpj')}`);

      if(data.length > 0){
        return history.push('/');
      }

      if(dataQuery && query.has('cpf_cnpj')){
        if(query.get('cpf_cnpj').length === 11){
          return history.push(`/cadastro-pessoa-fisica${dataQuery}`);
        }

        if(query.get('cpf_cnpj').length === 14){
          return history.push(`/cadastro-pessoa-juridica${dataQuery}`);
        }
      }

      setLoading(false);
    }

    userSearch();
  },[query, history]);

  return (
    <>
      {loading ? (
        <Loading />
      ): (
        <div
          className="error-content text-center"
          style={{ fontSize: '12px' }}
        >
          <h3 className="p-5">
            <i className="fas fa-exclamation-triangle text-warning" /> Oops!
            Algo deu errado.
          </h3>
          <img
            alt="Not Found"
            height="auto"
            src={NotFoundImg}
            width="40%"
          />
        </div>
      )}
    </>
  );
}
