import * as actions from './actionType';

export const PorteEmpreendimentoReducer = (state = {select_porte_empreendimentos: [], porte_empreendimentos: [], porte_empreendimento: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.PORTE_EMPREENDIEMENTOS_DATA:
      return {
        ...state,
        porte_empreendimentos: action.porte_empreendimentos.data,
        links: action.porte_empreendimentos.data.links,
        meta: action.porte_empreendimentos.data.meta,
      };
    case actions.SELECT_PORTE_EMPREENDIEMENTOS:
      return {
        ...state,
        select_porte_empreendimentos: action.porte_empreendimentos.data
      };
    case actions.GET_PORTE_EMPREENDIEMENTO:
      return {
        ...state,
        porte_empreendimento: action.porte_empreendimento.data.data,
        status_response: action.porte_empreendimento.status,
      };
    case actions.PORTE_EMPREENDIEMENTO_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
