import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

export default function CardWithModal({ title, finished, children }) {
    let [show, setShow] = useState(false);

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">{title}</h3>
                </div>
                <div className="card-body">
                    <button
                        onClick={() => setShow(true)}
                        className={`btn ${finished ? 'btn-primary' : 'btn-success'} btn-block`}
                    >
                        <i
                        aria-hidden="true"
                        className="fa fa-list-alt"
                        /> {title}
                    </button>
                </div>
            </div>

            <Modal
                animation
                enforceFocus={false}
                dialogClassName="modal-xl"
                onHide={() => setShow(false)}
                show={show}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {children}
                </Modal.Body>
            </Modal>
        </>
    );
}
