import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FaSearch } from 'react-icons/fa';

export default function SearchForm({
  load,
  initialParams,
  setInitialParams,
  searchInitialType = 'protocolo',
  searchInitialLabel = 'Protocolo',
  options,
}) {
  const dispatch = useDispatch();
  const [search, setSearch] = useState({
    value: '',
    type: searchInitialType,
    label: searchInitialLabel
  });

  const handleSelectChange = event => {
    const {name, value, options, selectedIndex} = event.target;

    setSearch({
      ...search,
      [name]: value,
      label: options[selectedIndex].text
    });
  }

  const handleChange = event => {
    const {name, value} = event.target;

    setSearch({
      ...search,
      [name]: value
    });
  }

  const research = useCallback((event) => {
    event.preventDefault();

    let searchValue = '';
    let payload = initialParams;

    if (search.type.includes('cpf') || search.type.includes('cnpj')) {
      searchValue = search.value.replace(/[./-]/g,'');
    } else if (search.type.includes('data_saida') || search.type.includes('data_chegada')) {
      const dataArray = search.value.split('/').reverse();
      if (dataArray[0].length > 4) {
        const [year, time] = dataArray[0].split(' ');
        searchValue = `${year}-${dataArray[1]}-${dataArray[2]} ${time}`
      } else {
        searchValue = dataArray.join('-');
      }
    } else {
      searchValue = search.value;
    }

    if (search.type.split(';').length > 1) {
      payload.where_has = `${search.type}:${searchValue}`
      delete payload.search;
    } else {
      payload.search = `${search.type},${searchValue}`
      delete payload.where_has;
    }

    dispatch(
      load(payload)
    );
    setInitialParams(payload);
  }, [dispatch, search.type, search.value, load, initialParams, setInitialParams]);

  return (
    <form
      style={{ width: '50%' }}
      className="float-right"
      onSubmit={research}
    >
      <div className="input-group" style={{ width: '100%' }}>
        <select
          name="type"
          className="form-control"
          style={{ width: '20%' }}
          onChange={handleSelectChange}
        >
          {options.map(({label, value}) => (
            <option key={label} value={value}>{label}</option>
          ))}
        </select>
        <input
          type="text"
          name="value"
          style={{ width: '60%' }}
          className="form-control"
          placeholder={`Pesquisar por ${search.label}`}
          onChange={handleChange}
        />
        <div className="input-group-append">
          <button
            className="btn btn-default btn-flat"
            style={{ borderRadius: '0px 5px 5px 0px' }}
          >
            <FaSearch/>
          </button>
        </div>
      </div>
    </form>
  );
}
