import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GoRepoForcePush } from 'react-icons/go';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import FormRelatorio from './components/Form';
import FormFotoVistoria from './components/PhotoForm';
//import { Loading } from '~/components';
import { ModalAnexos } from '~/components';
import { formatData } from '~/utils/formatDataHora';

const RelatorioVistoria = ({
  vistorias = [],
  setRelatorioIsOk,
  onlyView,
  returnReload = true,
}) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [reportsFilled, setReportsFilled] = useState(false);

  const [modalArquivo, setModalArquivo] = useState({
    title: '',
    path: '',
    show: false,
    handleHide: () => false,
  });
  // let [barometroSustentabilidade, setBarometroSustentabilidade] = useState();

  useEffect(() => {
    const countVistorias = vistorias.length;
    let countFilledReports = 0;

    vistorias.forEach(({ relatorio }) => {
      if (
        relatorio &&
        relatorio.objetivo !== '<p><br></p>' &&
        relatorio.objetivo !== null &&
        relatorio.descricao !== '<p><br></p>' &&
        relatorio.descricao !== null &&
        relatorio.relatorio !== '<p><br></p>' &&
        relatorio.relatorio !== null &&
        relatorio.nao_conformidade !== '<p><br></p>' &&
        relatorio.nao_conformidade !== null &&
        relatorio.irregularidade !== '<p><br></p>' &&
        relatorio.irregularidade !== null &&
        relatorio.base_legal !== '<p><br></p>' &&
        relatorio.base_legal !== null &&
        relatorio.parecer !== '<p><br></p>' &&
        relatorio.parecer !== null
      ) {
        countFilledReports++;
      }
    });

    if (countFilledReports === countVistorias) {
      setReportsFilled(true);
      setRelatorioIsOk(true);
    } else {
      setReportsFilled(false);
      setRelatorioIsOk(false);
    }
  }, [vistorias, setRelatorioIsOk]);

  const handleHide = () => {
    setModalArquivo({
      title: '',
      description: '',
      path: '',
      show: false,
      handleHide: () => false,
    });
  };

  const handleClose = async () => {
    await setShow(false);
    returnReload && (await history.go());
  };

  const handleOpenModalAnexo = ({ title, description, path }) => {
    setModalArquivo({
      title,
      description,
      path,
      show: true,
      handleHide,
    });
  };

  return (
    <>
        <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
                <h3 className="card-title w-100">
                    Relatório de Vistoria Técnica Ambiental
                </h3>
            </div>
            <div className="card-body">
                <button
                    className={`btn ${
                        reportsFilled ? 'btn-primary' : 'btn-success'
                    } btn-block`}
                    onClick={() => setShow(true)}
                >
                    <GoRepoForcePush /> Relatório de Vistoria Técnica Ambiental
                </button>
            </div>
        </div>

        <Modal
            animation
            aria-labelledby="example-custom-modal-styling-title"
            dialogClassName="modal-xl"
            onHide={handleClose}
            show={show}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Relatório(s) de Vistoria(s) Técnica Ambiental
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs id="noanim-tab-example" transition={false}>
                {vistorias.map((vistoria) => (
                    <Tab
                        eventKey={formatData(vistoria.data_saida)}
                        key={vistoria.id}
                        title={formatData(vistoria.data_saida)}
                    >
                    <FormRelatorio
                        handleClose={handleClose}
                        onlyView={onlyView}
                        vistoria={vistoria}
                    />
                    <br />
                    <FormFotoVistoria
                        handleOpenModalAnexo={handleOpenModalAnexo}
                        onlyView={onlyView}
                        vistoria={vistoria}
                    />
                    </Tab>
                ))}
                </Tabs>
            </Modal.Body>

            {/* <Modal.Footer>

            </Modal.Footer> */}
        </Modal>

        <ModalAnexos {...modalArquivo} />
    </>
  );
};
export default RelatorioVistoria;
