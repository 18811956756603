import * as actions from './actionType';

export const UserReducer = (
  state = {
    users: [],
    user: {},
    user_pessoa: {},
    user_analistas_entidades: [],
    user_analista_entidade: {},
    status_response:null,
    errors: {},
  },
  action
) => {
  switch (action.type) {
    case actions.GET_USERS:
      return {
        ...state,
        users: action.users.data,
        links: action.users.data.links,
        meta: action.users.data.meta,
        status_response:action.users.status
      };
    case actions.GET_USER:
      return {
        ...state,
        user: action.user.data,
        status_response:action.user.status
      };
    case actions.GET_USER_PESSOA:
      return {
        ...state,
        user_pessoa: action.user_pessoa.data,
        status_response: action.user_pessoa.status
      };
    case actions.USERS_ANALISTAS_ENTIDADE_DATA:
      return {
        ...state,
        user_analistas_entidades: action.user_analistas_entidades.data,
        links: action.user_analistas_entidades.data.links,
        meta: action.user_analistas_entidades.data.meta,
      };
    case actions.CHANGE_STATUS_CODE:
      return {
        ...state,
        status_response: action.status,
      };
    case actions.USER_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
};
