import * as actions from './actionType';

export const loadCondicionantes = (params) => ({
  type: actions.LOAD_CONDICIONATES,
  params
});
export const getCondicionantes = condicionantes => ({
  type: actions.CONDICIONATES_DATA,
  condicionantes,
});

export const storeCondicionante = condicionante => ({
  type: actions.STORE_CONDICIONATE,
  condicionante,

});
export const getCondicionante = condicionante => ({
  type: actions.GET_CONDICIONATE,
  condicionante,
});

export const removerCondicionante = (id) => ({
  type: actions.REMOVER_CONDICIONATE,
  id
});

export const showCondicionante = id => ({
  type: actions.SHOW_CONDICIONATE,
  id,
});

export const updateCondicionante = (condicionante, id) => ({
  type: actions.UPDATE_CONDICIONATE,
  condicionante,
  id
});

export const getErros = errors => ({
  type: actions.CONDICIONATE_ERROR,
  errors,
});

export const changeStatus = status => ({
  type: actions.CHANGE_STATUS_CODE,
  status,
});
