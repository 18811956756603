import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { loadQuestaoAnalises } from '~/store/modules/questao_analise/action';
import { storeRespostaQuestao } from '~/store/modules/resposta_questao/action';

export default function FormAll({
  analise_id,
  questions,
  questionsLoadParams
}) {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const [saved, setSaved] = useState(false);
  const [complete, setComplete] = useState(true);

  useEffect(() => {
    questions.forEach(question => {
      if (question.resposta_questao_analise_previas?.length === 0) {
        setComplete(false);
      }
    })
  }, [questions]);

  useEffect(() => {
    if (saved) {
      dispatch(
        loadQuestaoAnalises(
          questionsLoadParams
        )
      );

      setSaved(false);
    }
  }, [
    saved,
    dispatch,
    questionsLoadParams
  ]);

  function handleChange({ target }) {
    setValue(target.value);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    questions.map(async question => {
      if (question.resposta_questao_analise_previas?.length === 0) {
        await dispatch(
          storeRespostaQuestao({
            analise_processo_id: analise_id,
            questao_analise_id: question.id,
            valor: value
          })
        );
      }
    });

    setSaved(true);
  }

  if (process.env.REACT_APP_ENV === 'development' && !complete) {
    return (
      <table className="table table-sm table-striped table-bordered">
        <tbody>
          <tr>
            <th colSpan={3}>
              Responder Todas
            </th>
            <td>
              <Form
                autoComplete="off"
                onSubmit={handleSubmit}
                style={{ width: '100%' }}
              >
                <div className="form-group text-center">
                  <label className="label">Valor</label>
                  <Form.Control
                    min="0"
                    max="10"
                    step="0.1"
                    name="valor"
                    type="number"
                    className="text-center"
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group text-center">
                  <button className="btn btn-success">
                    Salvar
                  </button>
                </div>
              </Form>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  return null;
}
