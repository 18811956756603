import { all, fork } from 'redux-saga/effects';
import loginSaga from './login/sagas';
import cadastroFisicaSaga from './cpf/sagas';
import cadastroJuridicaSaga from './cnpj/sagas';
import ufDataSaga from './uf/sagas';
import Entidade from './entidade/sagas';
import Estado from './estado/sagas';
import Municipio from './municipio/sagas';
import Menu from './menu/sagas';
import TipoUser from './tipo_user/sagas';
import Recurso from './recurso/sagas';
import PotencialPoluidor from './potencial_poluidor/sagas';
import Atividade from './atividade/sagas';
import TipoLicenca from './tipo_licenca/sagas';
import PessoaJuridica from './pessoa_juridica/sagas';
import PessoaFisicaSaga from './pessoa_fisica/sagas';
import ArquivoSaga from './arquivo/sagas';
import PessoaJuridicaSaga from './tecnico_profissional/sagas';
import ProcessoSaga from './processo/sagas';
import ResponsavelTecnicoSaga from './responsavel_tecnico/sagas';
import ListaDocumentoSaga from './lista_documento/sagas';
import DocumentoProcessoSaga from './documento_processo/sagas';
import MeioAnaliseSaga from './meio_analise/sagas';
import TipoMeioAnaliseSaga from './tipo_meio_analise/sagas';
import AnaliseSaga from './analise/sagas';
import RespostaQuestaoSaga from './resposta_questao/sagas';
import RespostaQuestaoMemorialSaga from './resposta_questao_memorial/sagas';
import QuestaoAnaliseSaga from './questao_analise/sagas';
import PagamentosPendentesSaga from './pagamentos_pendentes/sagas';
import IndicadorAmbientalSaga from './indicadorAmbiental/sagas';
import ProjetoProcessoSaga from './projeto_processo/sagas';
import UserSaga from './user/sagas';
import CondicionanteSaga from './condicionante/sagas';
import MensagemSaga from './mensagem/sagas';
import NotaTecAmbientalSaga from './nota_tec_ambiental/sagas';
import VeiculoSaga from './veiculo/sagas';
import MotoristaSaga from './motorista/sagas';
import VistoriaSaga from './vistoria/sagas';
import RelatorioVistoriaSaga from './relatorio_vistoria/sagas';
import QuestaoEstudoAmbientalSaga from './questao_estudo_ambiental/sagas';
import AlternativaQuestaoAnaliseSaga from './alternativa_questao_analise/sagas';
import ObservacaoSaga from './observacao/sagas';
import IndicadorProcessoSaga from './indicadorProcesso/sagas';
import GerenteSaga from './gerente/sagas';
import TecnicoAvaliadorSaga from './tecnico_avaliador/sagas';
import FotoVistoriaSaga from './foto_vistoria/sagas';
import LegislacaoAtividadeSaga from './legislacao_atividade/sagas';
import ConfigAutorizacaoSaga from './config_autorizacao/sagas';
import AutorizacaoProcessoSaga from './autorizacao_processo/sagas';
import ValorReferenciaSaga from './valor_referencia/sagas';
import PorteEmpreendimento from './porte_empreendimento/sagas';

export default function* () {
  yield all([
    fork(ArquivoSaga),
    fork(loginSaga),
    fork(cadastroFisicaSaga),
    fork(cadastroJuridicaSaga),
    fork(ufDataSaga),
    fork(Entidade),
    fork(Estado),
    fork(Municipio),
    fork(Menu),
    fork(TipoUser),
    fork(Recurso),
    fork(PotencialPoluidor),
    fork(Atividade),
    fork(TipoLicenca),
    fork(PessoaJuridica),
    fork(PessoaFisicaSaga),
    fork(PessoaJuridicaSaga),
    fork(ProcessoSaga),
    fork(ResponsavelTecnicoSaga),
    fork(ListaDocumentoSaga),
    fork(DocumentoProcessoSaga),
    fork(MeioAnaliseSaga),
    fork(TipoMeioAnaliseSaga),
    fork(AnaliseSaga),
    fork(RespostaQuestaoSaga),
    fork(QuestaoAnaliseSaga),
    fork(PagamentosPendentesSaga),
    fork(UserSaga),
    fork(IndicadorAmbientalSaga),
    fork(ProjetoProcessoSaga),
    fork(RespostaQuestaoMemorialSaga),
    fork(CondicionanteSaga),
    fork(MensagemSaga),
    fork(NotaTecAmbientalSaga),
    fork(VeiculoSaga),
    fork(MotoristaSaga),
    fork(VistoriaSaga),
    fork(RelatorioVistoriaSaga),
    fork(QuestaoEstudoAmbientalSaga),
    fork(AlternativaQuestaoAnaliseSaga),
    fork(ObservacaoSaga),
    fork(IndicadorProcessoSaga),
    fork(GerenteSaga),
    fork(TecnicoAvaliadorSaga),
    fork(FotoVistoriaSaga),
    fork(LegislacaoAtividadeSaga),
    fork(ConfigAutorizacaoSaga),
    fork(AutorizacaoProcessoSaga),
    fork(ValorReferenciaSaga),
    fork(PorteEmpreendimento),
  ]);
}
