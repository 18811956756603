import React from 'react';
import Select from 'react-select';


const ModalEstudosAtividade = () => {

  const valuesOptions = [
    { value: 1, label: '1. AAEU (Avaliação Ambiental Estratégica Urbana)'},
    { value: 2, label: '2. AIA (Avaliação de Impacto Ambiental)'},
    { value: 3, label: '3. DIA (Declaração de Informação Ambiental)'},
    { value: 4, label: '4. DPA (Diagnóstico de Passivos Ambientais)'},
    { value: 6, label: '6. EASi (Estudo Ambiental de Sísmica)'},
    { value: 7, label: '7. EIVI (Estudo de Impacto de Vizinhança)'},
    { value: 8, label: '8. EPIA (Estudo Prévio de Impacto Ambiental)'},
    { value: 10, label: '10. EVE (Estudo de Viabilidade Econômica - Mineração)'},
    { value: 11, label: '11. LDV (Laudo de Cobertura Vegetal)'},
    { value: 12, label: '12. MDE (Memorial Descritivo do Empreendimento)'},
    { value: 13, label: '13. OUTORGA (Outorga de Recursos Hídricos - Mineração)'},
    { value: 14, label: '14. PACUERA (Plano Ambiental de Conservação e Uso do Entorno de Reservatório Artificial)'},
    { value: 16, label: '16. PAE (Plano de Aproveitamento Econômico - Mineração)'},
    { value: 17, label: '17. PBA (Plano Básico Ambiental)'},
    { value: 19, label: '19. PCAR (Plano de Controle Ambiental Rural)'},
    { value: 20, label: '20. PCM (Plano de Contingência em Mineração)'},
    { value: 21, label: '21. PCR (Prospecção e Cálculo de Reserva - Mineração)'},
    { value: 23, label: '23. PDR (Plano de Disposição de Rejeito - Mineração)'},
    { value: 24, label: '24. PDTM (Plano de Desmonte e Transporte de Minério)'},
    { value: 25, label: '25. PEM (Plano de Emergência em Mineração)'},
    { value: 26, label: '26. PGRCC (Plano de Gerenciamento de Resíduos da Construção Civil)'},
    { value: 27, label: '27. PGROLUC (Plano de Gerenciamento de Resíduos do Óleo Lubrificante Usado e Contaminado)'},
    { value: 28, label: '28. PGRS (Plano de Gerenciamento de Resíduos Sólidos)'},
    { value: 29, label: '29. PGRSS (Plano de Gerenciamento de Resíduos do Serviço de Saúde)'},
    { value: 30, label: '30. PL (Plano de Lavra ou de Exploração - Mineração)'},
    { value: 31, label: '31. PlaCarb (Plano de Carbonificação)'},
    { value: 32, label: '32. PMA (Plano de Monitoramento Ambiental)'},
    { value: 34, label: '34. PMM (Plano de Movimentação de Minério)'},
    { value: 36, label: '36. PROCOMV (Programa de Controle e Manutenção Veicular)'},
    { value: 37, label: '37. PTRF (Plano Técnico de Recomposição da Flora)'},
    { value: 38, label: '38. RADA (Relatório de Avaliação de Desempenho Ambiental)'},
    { value: 39, label: '39. RADAR (Relatório de Avaliação de Desempenho Ambiental Rural)'},
    { value: 40, label: '40. RAIAS (Relatório de Ausência de Impacto Ambiental Significativo)'},
    { value: 42, label: '42. RAS (Relatório Ambiental Simplificado)'},
    { value: 44, label: '44. RCAR (Relatório de Controle Ambiental Rural)'},
    { value: 45, label: '45. RCE (Relatório de Caracterização do Empreendimento)'},
    { value: 46, label: '46. RDPA (Relatório de Detalhamento de Programa Ambiental)'},
    { value: 47, label: '47. RIAS (Relatório de Impacto Ambiental de Sísmica)'},
    { value: 48, label: '48. RIMA (Relatório de Impacto Ambiental ou Relatório de Impacto do Meio Ambiente)'},
    { value: 49, label: '49. RIVI (Relatório de Impacto de Vizinhança)'},
    { value: 50, label: '50. RVA (Relatório de Viabilidade Ambiental)'},
    { value: 51, label: '51. PIF (ParecerProjeto de Inventário Florestal)'},
    { value: 52, label: '52. PAIPA (ParecerProjeto de Avaliação de Impacto ao Patrimônio Arqueológico)'},
    { value: 53, label: '53. RAIPA (Relatório de Avaliação de Impacto ao Patrimônio Arqueológico)'},
    { value: 54, label: '54. PEARIP (Plano de Encerramento de Aterro de Resíduos Industriais Perigosos)'},
  ];
  return (
    <div
      className="modal fade"
      id="modal-estudos-atividade"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title"> IDENTIFIQUE E PREENCHA OS ESTUDOS AMBIENTAIS SELECIONADOS</h4>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="">Estudos</label>
              <Select
                class="form-control"
                isMulti
                options={valuesOptions}
              />
            </div>

          </div>
          <div className="modal-footer justify-content-between">
            <button
              className="btn btn-default"
              data-dismiss="modal"
              type="button"
            >
              <i className="fa fa-window-close" /> Fechar
            </button>
            <button
              className="btn btn-success"
              data-dismiss="modal"
              type="button"
            >
              <i className="fas fa-save" /> Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalEstudosAtividade;
