export const LOAD_PESSOA_JURIDICAS = 'LOAD_PESSOA_JURIDICAS';
export const LOAD_MEUS_EMPREENDIMENTOS = 'LOAD_MEUS_EMPREENDIMENTOS';
export const SELECT_PESSOA_JURIDICAS = 'SELECT_PESSOA_JURIDICAS';
export const PESSOA_JURIDICA_ERROR = 'PESSOA_JURIDICA_ERROR';
export const PESSOA_JURIDICA_GET_ERRORS = 'PESSOA_JURIDICA_GET_ERRORS';
export const PESSOA_JURIDICAS_DATA = 'PESSOA_JURIDICAS_DATA';
export const STORE_PESSOA_JURIDICA = 'STORE_PESSOA_JURIDICA';
export const STORE_MEUS_EMPREENDIMENTOS = 'STORE_MEUS_EMPREENDIMENTOS';
export const GET_PESSOA_JURIDICA = 'GET_PESSOA_JURIDICA';
export const REMOVER_PESSOA_JURIDICA = 'REMOVER_PESSOA_JURIDICA';
export const SHOW_PESSOA_JURIDICA = 'SHOW_PESSOA_JURIDICA';
export const UPDATE_PESSOA_JURIDICA = 'UPDATE_PESSOA_JURIDICA';
