import * as actions from './actionType';

export const loadPorteEmpreendimentos = () => ({
  type: actions.LOAD_PORTE_EMPREENDIEMENTOS
});
export const getPorteEmpreendimentos = porte_empreendimentos => ({
  type: actions.PORTE_EMPREENDIEMENTOS_DATA,
  porte_empreendimentos,
});

export const getSelectPorteEmpreendimento = porte_empreendimentos => ({
  type: actions.SELECT_PORTE_EMPREENDIEMENTOS,
  porte_empreendimentos,
});

export const storePorteEmpreendimento = porte_empreendimento => ({
  type: actions.STORE_PPORTE_EMPREENDIEMENTO,
  porte_empreendimento,

});
export const getPorteEmpreendimento = porte_empreendimento => ({
  type: actions.GET_PORTE_EMPREENDIEMENTO,
  porte_empreendimento,
});

export const removerPorteEmpreendimento = (id) => ({
  type: actions.REMOVER_PORTE_EMPREENDIEMENTO,
  id
});

export const showPorteEmpreendimento = id => ({
  type: actions.SHOW_PORTE_EMPREENDIEMENTO,
  id,
});

export const updatePorteEmpreendimento = (porte_empreendimento, id) => ({
  type: actions.UPDATE_PORTE_EMPREENDIEMENTO,
  porte_empreendimento,
  id
});

export const getErros = errors => ({
  type: actions.PORTE_EMPREENDIEMENTO_ERROR,
  errors,
});
