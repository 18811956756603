import React from 'react';
const ModalProjeto = () => {
  return (
    <div
      className="modal fade"
      id="modal-projeto"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title"> PROJETO GERAL DO EMPREENDIMENTO OU ATIVIDADE </h4>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            Campos...

          </div>
          <div className="modal-footer justify-content-between">
            <button
              className="btn btn-default"
              data-dismiss="modal"
              type="button"
            >
              <i className="fa fa-window-close" /> Fechar
            </button>
            <button
              className="btn btn-success"
              data-dismiss="modal"
              type="button"
            >
              <i className="fas fa-save" /> Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalProjeto;
