import * as actions from './actionType';

export const ValorReferenciaReducer = (state = {select_valores_referencias: [], valores_referencias: [], valor_referencia: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.VALOR_REFERENCIA_DATA:
      return {
        ...state,
        valores_referencias: action.valores_referencias.data,
        links: action.valores_referencias.data.links,
        meta: action.valores_referencias.data.meta,
      };
    case actions.SELECT_VALORES_REFERENCIAS:
      return {
        ...state,
        select_valores_referencias: action.valores_referencias.data
      };
    case actions.GET_VALOR_REFERENCIA:
      return {
        ...state,
        valor_referencia: action.valor_referencia.data.data,
        status_response: action.valor_referencia.status,
      };
    case actions.VALOR_REFERENCIA_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
