import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as QuestaoAnalise from '~/services/QuestaoAnalise';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadQuestaoAnalisesWorker({ params }) {
  try {
    const response = yield call(QuestaoAnalise.load, params);
    yield put(actions.getQuestaoAnalises(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}


function* showQuestaoAnaliseWorker({id, params}) {
  try {
    const response = yield call(QuestaoAnalise.show, id, params);
    yield put(actions.getQuestaoAnalise(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updateQuestaoAnaliseWorker({ questao_analise, id}) {
  try {
    const response = yield call(QuestaoAnalise.update, id, questao_analise);
    yield put(actions.getQuestaoAnalise(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeQuestaoAnaliseWorker({questao_analise}) {
  try {
    const response = yield call(QuestaoAnalise.store, questao_analise);
    yield put(actions.getQuestaoAnalise(response));
    yield put(actions.getErros({save:true}));
    yield put(getErros({save:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* removerQuestaoAnaliseWorker({id}) {
  try {
    yield call(QuestaoAnalise.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* ativarQuestaoAnaliseWorker({id}) {
  try {
    yield call(QuestaoAnalise.activate, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherQuestaoAnalise() {
  yield takeLatest(actionType.LOAD_QUESTAO_ANALISES, loadQuestaoAnalisesWorker);
  yield takeLatest(actionType.STORE_QUESTAO_ANALISE, storeQuestaoAnaliseWorker);
  yield takeLatest(actionType.SHOW_QUESTAO_ANALISE, showQuestaoAnaliseWorker);
  yield takeLatest(actionType.UPDATE_QUESTAO_ANALISE, updateQuestaoAnaliseWorker);
  yield takeLatest(actionType.REMOVER_QUESTAO_ANALISE, removerQuestaoAnaliseWorker);
  yield takeLatest(actionType.ACTIVATE_QUESTAO_ANALISE, ativarQuestaoAnaliseWorker);
}

export default function* saga() {
  yield all([watcherQuestaoAnalise()]);
}
