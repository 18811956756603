import { CPF_DATA, CPF_ERROR } from './actionType';

export const CadastroFisicaReducer = (state = {}, action) => {
  switch (action.type) {
    case CPF_ERROR:
      return {
        ...state,
        cpf_error: action.error
      }
    case CPF_DATA:
      return {
        ...state,
        cpf_data: action.data
      }
    default:
      return state;
  }
}
