import React from 'react';
import { Content } from '~/layouts/Dash/components';


const BasicContent = ({breadcrumb, children}) => {

  const breadcrumbs = [
    {
      label: 'Pessoa Física',
      to: '/empreendimentos/pessoa_fisica',
      active: false,
    },
    breadcrumb,
  ];
  return (
    <Content breadcrumbs={breadcrumbs} title="Pessoa Física">
      {children}
    </Content>
  );
}

export default BasicContent;
