import * as actions from './actionType';

export const loadTecnicosAvaliadores = (params) => ({
  type: actions.LOAD_TECNICOS_AVALIADORES,
  params
});

export const getTecnicosAvaliadores = tecnicos_avaliadores => ({
  type: actions.TECNICOS_AVALIADORES_DATA,
  tecnicos_avaliadores,
});

export const showTecnicoAvaliador = (id, params) => ({
  type: actions.SHOW_TECNICO_AVALIADOR,
  id, params
});

export const getTecnicoAvaliador = tecnico_avaliador => ({
  type: actions.GET_TECNICO_AVALIADOR,
  tecnico_avaliador,
});

export const storeTecnicoAvaliador = tecnico_avaliador => ({
  type: actions.STORE_TECNICO_AVALIADOR,
  tecnico_avaliador
});

export const updateTecnicoAvaliador = (id, tecnico_avaliador) => ({
  type: actions.UPDATE_TECNICO_AVALIADOR,
  id, tecnico_avaliador
});

export const changeStatus = status => ({
  type: actions.CHANGE_STATUS_CODE,
  status,
});

export const getErros = errors => ({
  type: actions.TECNICO_AVALIADOR_ERROR,
  errors,
});
