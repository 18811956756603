import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowCircleLeft } from 'react-icons/fa';
import {
    boxSelectCurso as BoxSelectCurso,
    boxActions as BoxActions,
} from './styles';
import { changePassword } from '../../store/modules/user/action';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import formatCpfCnpj from '~/utils/formatCpfCnpj';
import removeMask from '~/utils/removeMask';

export default function RecuperarSenha({history}) {

    const { status_response, errors } = useSelector(({ UserReducer }) => UserReducer);
    
    const dispatch = useDispatch();
    const [ infoUser, setInfoUser ] = useState({
        email:'',
        numero_documento:'',
    });
    useEffect(() => {
        if (errors.message) {
            // let server_errors = {};
            // const response_errors = errors.errors;
            // for (let name of Object.keys(response_errors)) {
            //   Object.assign(server_errors, { [name]: response_errors[name][0] });
            // }
            Swal.fire('Erro', 'Erro ao Recuperar a Senha: '+errors.message, 'error').then(
                () => {}
            );
        }
    }, [errors]);

    useEffect(() => {
        if (status_response === 200) {
        Swal.fire('Sucesso', 'Email com link de recuperação enviado no seu email!', 'success').then(
            () => {
                history.push('/login');
            }
        );
        }
    }, [status_response, history, dispatch]);
    
    const onHandleSubmit = async (event) => {
        event.preventDefault();
        infoUser.numero_documento = removeMask(infoUser.numero_documento)
        await dispatch(changePassword(infoUser));
    }

    const handleChange = event => {
        const { name, value } = event.target;
        setInfoUser({
            ...infoUser,
            [name]: value,
        });
    }

    return (
        <BoxSelectCurso className="d-flex d-flex-row box-base-green">
        <div className="card">
            <div className="card-body login-card-body">
                <p className="login-box-msg">RECUPERAR SENHA</p>
                <form onSubmit={onHandleSubmit}>
                    <div className="input-group mb-3">
                        <input
                            aria-describedby="Email"
                            aria-label="Email"
                            className="form-control"
                            name="email"
                            onChange={handleChange}
                            placeholder="Email"
                            type="email"
                            required
                            value={infoUser.email}
                        />
                        <div className="input-group-append">
                            <div className="input-group-text">
                                <span className="fas fa-envelope" />
                            </div>
                        </div>
                    </div>
                    <div className="input-group mb-3">
                        <input
                            aria-describedby="CPF"
                            aria-label="CPF"
                            className="form-control"
                            name="numero_documento"
                            onChange={handleChange}
                            placeholder="CPF/CNPJ"
                            type="text"
                            required
                            maxLength="18"
                            minLength="14"
                            value={formatCpfCnpj(infoUser.numero_documento ?? '')}
                        />
                        <div className="input-group-append">
                            <div className="input-group-text">
                                <span className="fas fa-id-card" />
                            </div>
                        </div>
                    </div>
                    {/* <div className="input-group mb-3">
                        <input
                            aria-describedby="Password"
                            aria-label="Password"
                            className="form-control"
                            name="password"
                            onChange={handleChange}
                            placeholder="Senha"
                            type="password"
                            value={infoUser.password}
                        />
                        <div className="input-group-append">
                            <div className="input-group-text">
                                <span className="fas fa-lock" />
                            </div>
                        </div>
                    </div> */}
                    <div className="mb-3">
                        <button
                            className="btn btn-success btn-block"
                            type="submit"
                        >
                            RECUPERAR SENHA
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <BoxActions>
            <Link
            className="btn btn-musgo"
            to="/"
            >
            <FaArrowCircleLeft /> Voltar
            </Link>
        </BoxActions>
        </BoxSelectCurso>
    );
}
