import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { Loading } from '~/components';
import { showTecnicoProfissional } from '~/store/modules/tecnico_profissional/action';
import { ModalAnexos } from '~/components';

const List = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { tecnico_profissional_id } = useParams();
  const [TecnicoProfissionalShow, setTecnicoProfissionalShow] = useState({});

  const [modalArquivo, setModalArquivo] = useState({
    title: '',
    path: '',
    show: false,
    handleHide: () => false,
  });

  const { tecnico_profissional } = useSelector(
    ({ TecnicoProfissionalReducer }) => TecnicoProfissionalReducer
  );

  useEffect(() => {
    dispatch(showTecnicoProfissional(tecnico_profissional_id));
  }, [dispatch, tecnico_profissional_id]);

  useEffect(() => {
    if (tecnico_profissional.id === parseInt(tecnico_profissional_id)) {
      setTecnicoProfissionalShow(tecnico_profissional);
    }
  }, [tecnico_profissional, tecnico_profissional_id]);

  const handleClose = () => {
    history.push('/empreendimentos/tecnico_profissional');
  };

  const handleHide = () => {
    setModalArquivo({
      title: '',
      path: '',
      show: false,
      handleHide: () => false,
    });
  };

  const handleOpenModalAnexo = (title, path) => {
    console.log(title, path);
    setModalArquivo({
      title,
      path,
      show: true,
      handleHide,
    });
  };

  const formatData = (data) => {
    let part = data.split('-');
    return `${part[2]}/${part[1]}/${part[0]}`;
  }

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-xl"
        onHide={handleClose}
        show
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            TecnicoProfissional
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header">
              <h2 className="card-title">Detalhes</h2>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                {TecnicoProfissionalShow.id === parseInt(tecnico_profissional_id) ? (
                  <>
                    <table className="table table-hover table-striped table-sm">
                      <tbody>
                        <tr>
                          <th>id</th>
                          <td>{tecnico_profissional.id}</td>
                        </tr>
                        <tr>
                          <th>Nome</th>
                          <td>{tecnico_profissional.nome}</td>
                        </tr>
                        <tr>
                          <th>Data Nascimento</th>
                          <td>{formatData(tecnico_profissional.data_nasc)}</td>
                        </tr>
                        <tr>
                          <th>E-mail</th>
                          <td>{tecnico_profissional.email}</td>
                        </tr>
                        <tr>
                          <th>CNPJ</th>
                          <td>
                            {tecnico_profissional.cnpj}
                            <button
                              className="btn btn-default ml-2 float-right"
                              onClick={() =>
                                handleOpenModalAnexo(
                                  'Anexo CNPJ',
                                  tecnico_profissional.anexo_cnpj
                                )
                              }
                            >
                              <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <th>RG</th>
                          <td>
                            {tecnico_profissional.rg}
                            <button
                              className="btn btn-default ml-2 float-right"
                              onClick={() =>
                                handleOpenModalAnexo(
                                  'Anexo RG',
                                  tecnico_profissional.anexo_rg
                                )
                              }
                            >
                              <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                            </button>
                          </td>
                        </tr>

                        <tr>
                          <th>CEP</th>
                          <td>{tecnico_profissional.cep}</td>
                        </tr>
                        <tr>
                          <th>Endereço</th>
                          <td>
                            {tecnico_profissional.logradouro},{' '}
                            {tecnico_profissional.bairro}, {tecnico_profissional.bairro},{' '}
                            {tecnico_profissional.Cidade} -
                            {tecnico_profissional.estado_endereco.uf}
                            <button
                              className="btn btn-default ml-2 float-right"
                              onClick={() =>
                                handleOpenModalAnexo(
                                  'Comprovante de endereço',
                                  tecnico_profissional.anexo_endereco
                                )
                              }
                            >
                              <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <th>Número de WhatsApp</th>
                          <td>{tecnico_profissional.numero_whatsapp}</td>
                        </tr>
                        <tr>
                          <th>Fone de contato</th>
                          <td>{tecnico_profissional.telefone}</td>
                        </tr>
                        <tr>
                          <th>Youtube</th>
                          <td>{tecnico_profissional.youtube}</td>
                        </tr>
                        <tr>
                          <th>Facebook</th>
                          <td>{tecnico_profissional.facebook}</td>
                        </tr>
                        <tr>
                          <th>Linkdin</th>
                          <td>{tecnico_profissional.linkdin}</td>
                        </tr>
                        <tr>
                          <th>Twiter</th>
                          <td>{tecnico_profissional.twiter}</td>
                        </tr>
                        <tr>
                          <th>Formação</th>
                          <td>{tecnico_profissional.formacao}
                            <button
                              className="btn btn-default ml-2 float-right"
                              onClick={() =>
                                handleOpenModalAnexo(
                                  'Anexo formação',
                                  tecnico_profissional.anexo_formacao
                                )
                              }
                            >
                              <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                            </button>
                          </td>
                        </tr>
                        {tecnico_profissional.formacao_2 && <tr>
                          <th>Formação 2</th>
                          <td>{tecnico_profissional.formacao_2}
                            <button
                              className="btn btn-default ml-2 float-right"
                              onClick={() =>
                                handleOpenModalAnexo(
                                  'Anexo formação 2',
                                  tecnico_profissional.anexo_formacao_2
                                )
                              }
                            >
                              <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                            </button>
                          </td>
                        </tr>}

                        {tecnico_profissional.especializacao && <tr>
                          <th>Especialização</th>
                          <td>{tecnico_profissional.especializacao}
                            <button
                              className="btn btn-default ml-2 float-right"
                              onClick={() =>
                                handleOpenModalAnexo(
                                  'Anexo formação 2',
                                  tecnico_profissional.anexo_especializacao
                                )
                              }
                            >
                              <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                            </button>
                          </td>
                        </tr>}

                        {tecnico_profissional.mestrado && <tr>
                          <th>Mestrado</th>
                          <td>{tecnico_profissional.mestrado}
                            <button
                              className="btn btn-default ml-2 float-right"
                              onClick={() =>
                                handleOpenModalAnexo(
                                  'Anexo Mestrado',
                                  tecnico_profissional.anexo_mestrado
                                )
                              }
                            >
                              <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                            </button>
                          </td>
                        </tr>}

                        {tecnico_profissional.doutorado && <tr>
                          <th>Doutorado</th>
                          <td>{tecnico_profissional.doutorado}
                            <button
                              className="btn btn-default ml-2 float-right"
                              onClick={() =>
                                handleOpenModalAnexo(
                                  'Anexo Doutorado',
                                  tecnico_profissional.anexo_doutorado
                                )
                              }
                            >
                              <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                            </button>
                          </td>
                        </tr>}

                      </tbody>
                    </table>
                  </>
                ) : (
                  <Loading />
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Link
            className="btn btn-default"
            to="/empreendimentos/tecnico_profissional"
          >
            <i className="fa fa-window-close" /> Fechar
          </Link>
        </Modal.Footer>
      </Modal>
      <ModalAnexos {...modalArquivo} />
    </>
  );
};

export default List;
