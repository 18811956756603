import * as Yup from 'yup';

const schema = Yup.object().shape({
  // autor: Yup.string().required('Valor requerido'),
    codigo: Yup.string().required('Código obrigatório.'),
    descricao: Yup.string().required('Descrição obrigatório.'),
    indice: Yup.string().required('Índice obrigatório.'),
    valor: Yup.number().required('Valor obrigatório.'),
    unidade: Yup.string().required('Unidade obrigatório.')
});

export default schema;
