export const LOAD_RELATORIO_VISTORIAS = 'LOAD_RELATORIO_VISTORIAS';
export const SELECT_RELATORIO_VISTORIAS = 'SELECT_RELATORIO_VISTORIAS';
export const RELATORIO_VISTORIA_ERROR = 'RELATORIO_VISTORIA_ERROR';
export const RELATORIO_VISTORIA_GET_ERRORS = 'RELATORIO_VISTORIA_GET_ERRORS';
export const RELATORIO_VISTORIAS_DATA = 'RELATORIO_VISTORIAS_DATA';
export const STORE_RELATORIO_VISTORIA = 'STORE_RELATORIO_VISTORIA';
export const GET_RELATORIO_VISTORIA = 'GET_RELATORIO_VISTORIA';
export const REMOVER_RELATORIO_VISTORIA = 'REMOVER_RELATORIO_VISTORIA';
export const SHOW_RELATORIO_VISTORIA = 'SHOW_RELATORIO_VISTORIA';
export const UPDATE_RELATORIO_VISTORIA = 'UPDATE_RELATORIO_VISTORIA';
export const UPDATE_RELATORIO_VISTORIA_PARECER = 'UPDATE_RELATORIO_VISTORIA_PARECER';
