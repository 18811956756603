import React from 'react';

import { CSVLink } from 'react-csv';

const GerateLinkToCsv = ({ data_headers, data_fields, import_table_name, children }) => {
  return (
    <CSVLink
      className="alert-link"
      data={data_fields}
      filename={`${import_table_name}_import.csv`}
      headers={data_headers}
      target="_blank"
      separator={";"}
    >{children}</CSVLink>
  )
}

export default GerateLinkToCsv;
