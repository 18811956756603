import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import { FaArrowAltCircleLeft, FaCircleNotch } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { BasicContent } from '../components';
import { Loading } from '~/components';
import formatForMask from '~/utils/formatForMask';
import {
    MovimentacaoProcesso,
    CheckList,
    MensagensProcesso,
    Retificacao,
} from '~/views/Process/components';
import { ProgressBar } from '../List/styles';
import {
    AnaliseTecImpacto,
    TaxaAmbiental,
    Certidao,
    ExtratoPublicacaoAmbiental,
} from './components';
import { showProcesso, gerarCobranca } from '~/store/modules/processo/action';
import ModalLegislacoes from '~/components/ModalLegislacoes';

const Detalhe = () => {
    let { processo_id } = useParams();
    let history = useHistory();
    const dispatch = useDispatch();
    const { processo, status_code } = useSelector(
        ({ ProcessoReducer }) => ProcessoReducer
    );
    const [processoShow, setProcessoShow] = useState({});
    const [loading, setLoading] = useState(false);
    const [showLegisModal, setShowLegisModal] = useState(false);
    const [awaitingPayment, setAwaitingPayment] = useState(false);
    const newFlow = useRef(false);

    useEffect(() => {
        const newFlowDate = new Date(process.env.REACT_APP_NEW_FLOW_DATE);
        const dateProcess = new Date(processoShow.created_at);
        if (newFlowDate.getTime() < dateProcess.getTime()) {
            newFlow.current = true;
        } else {
            newFlow.current = false;
        }
    });

    useEffect(() => {
        if (status_code === 200 && awaitingPayment) {
            Swal.fire('Sucesso', 'Seu processo aguarda pagamento.').then(() => {
                history.go(`/processo/${processo_id}/detalhe`);
            });
        }
    }, [history, processo_id, status_code, awaitingPayment]);

    useEffect(() => {
        dispatch(
            showProcesso(processo_id, {
                with:
                    'analise_previa,' +
                    'pessoa,' +
                    'estado,' +
                    'documentos,' +
                    'municipio,' +
                    'pessoa_responsavel_tecnico,' +
                    'tipo_licenca.valor_referencias,' +
                    'status_processo,' +
                    'porte_empreendimento,' +
                    'atividade.potencial_poluidor,' +
                    'atividade.legislacao_atividade;deleted_at is null,' +
                    'user_avaliador.pessoa_tecnico_profissional,' +
                    'projeto,' +
                    'indicadores,' +
                    'entidade,' +
                    'tramites,' +
                    'grupo_atividades',
            })
        );
    }, [processo_id, dispatch]);

    useEffect(() => {
        if (processo && processo.id === parseInt(processo_id)) {
            setProcessoShow(processo);
        }
    }, [processo, processo_id]);

    const handleContinuar = async () => {
        let confirm = await Swal.fire({
            title: 'Continuar com o processo?',
            text: 'Será gerada sua taxa de pagamento.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não',
        });
        if (confirm.value) {
            setLoading(true);
            await dispatch(gerarCobranca(processo_id));
            setAwaitingPayment(true);
        }
    };

    const handleCancelar = async () => {
        try {
            let confirm = await Swal.fire({
                title: 'Cancelar com o processo?',
                text: 'Deseja cancelar com seu processo?!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim!',
                cancelButtonText: 'Não',
            });
            if (confirm.value) {
                //alert('ok');
            }
        } catch (err) {
            //console.log(err);
        }
    };

    return (
        <BasicContent
            breadcrumb={{
                label: 'Detalhes',
                to: '/processos',
                active: false,
            }}
            title="Detalhes Processo."
        >
            <ModalLegislacoes
                showLegisModal={showLegisModal}
                legislacoes={
                    Object.keys(processo).length
                        ? processo.atividade?.legislacao_atividade
                        : []
                }
                closeModal={() => setShowLegisModal(false)}
            />

            <div className="card">
                {processoShow &&
                processoShow.protocolo &&
                processoShow.pessoa_responsavel_tecnico ? (
                    <>
                        <div className="card-header">
                            <h3 className="card-title">
                                PROCESSO :{' '}
                                <strong>{processoShow.protocolo}</strong>
                            </h3>
                            <div className="card-tools">
                                <Link
                                    className="btn  btn-default"
                                    to="/processos"
                                >
                                    <FaArrowAltCircleLeft />
                                    &nbsp; Voltar
                                </Link>
                            </div>
                        </div>
                        <div className="card-body table-responsive">
                            <table className="table table-hover table-striped table-bordered table-sm">
                                <tbody>
                                    {processoShow.status_processo_id === 4 && (
                                        <tr>
                                            <th
                                                className="text-center"
                                                colSpan="2"
                                            >
                                                PARABÊNS, VOCÊ CONCLUIU 30% DO
                                                SEU PROCESSO DE LICENCIAMENTO
                                                AMBIENTAL PARA:
                                            </th>
                                        </tr>
                                    )}
                                    <tr>
                                        <th>Processo</th>
                                        <td>{processoShow.protocolo}</td>
                                    </tr>
                                    <tr>
                                        <th>Empreendimento</th>
                                        <td>
                                            {' '}
                                            {`${processoShow.pessoa?.nome} - ${
                                                processoShow.pessoa?.cnpj
                                                    ? `CNPJ: ${formatForMask(
                                                          processoShow.pessoa
                                                              .cnpj,
                                                          '##.###.###/####-##'
                                                      )}`
                                                    : ''
                                            } ${
                                                processoShow.pessoa?.cpf
                                                    ? `CPF: ${formatForMask(
                                                          processoShow.pessoa
                                                              .cpf,
                                                          '###.###.###-##'
                                                      )}`
                                                    : ''
                                            }`}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Porte Empreendimento</th>
                                        <td>
                                            {
                                                processoShow
                                                    .porte_empreendimento?.nome
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Local</th>
                                        <td>
                                            {' '}
                                            {
                                                processoShow.municipio?.nome
                                            } - {processoShow.estado?.uf}
                                        </td>
                                    </tr>
                                    {processoShow.entidade?.cnae_group &&
                                    processoShow.grupo_atividades ? (
                                        <>
                                            <tr>
                                                <th>
                                                    Atividade Principal
                                                    Licenciada
                                                </th>
                                                <td>
                                                    {' '}
                                                    {
                                                        processoShow.atividade
                                                            ?.nome
                                                    }
                                                </td>
                                            </tr>
                                            {processoShow.grupo_atividades.map(
                                                (grupo, index) => (
                                                    <tr key={index}>
                                                        <th>
                                                            Atividade(s)
                                                            Secundária(s)
                                                            Licenciada(s)
                                                        </th>
                                                        <td>{grupo.nome}</td>
                                                    </tr>
                                                )
                                            )}
                                        </>
                                    ) : (
                                        <tr>
                                            <th>Atividade</th>
                                            <td>
                                                {' '}
                                                {processoShow.atividade?.nome}
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <th>Endereço da atividades</th>
                                        <td>
                                            {processoShow.logradouro}
                                            &nbsp;-&nbsp;
                                            {processoShow.numero_endereco ??
                                                'Nº não informado'}
                                            &nbsp;-&nbsp;
                                            {processoShow.bairro}
                                            &nbsp;-&nbsp;
                                            {processoShow.cep}
                                            &nbsp;-&nbsp;
                                            {processoShow.complemento ??
                                                'Sem complemento.'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Tipo de licença</th>
                                        <td>
                                            {' '}
                                            {processoShow.tipo_licenca?.nome}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Técnico Consultor</th>
                                        <td>
                                            {
                                                processoShow
                                                    .pessoa_responsavel_tecnico
                                                    ?.responsavel_tecnico
                                                    ?.pessoa_tecnico_profissional
                                                    ?.nome
                                            }{' '}
                                            -{' '}
                                            {processoShow
                                                .pessoa_responsavel_tecnico
                                                ?.responsavel_tecnico
                                                ?.pessoa_tecnico_profissional
                                                ?.cnpj
                                                ? `CNPJ: ${formatForMask(
                                                      processoShow
                                                          .pessoa_responsavel_tecnico
                                                          .responsavel_tecnico
                                                          .pessoa_tecnico_profissional
                                                          .cnpj,
                                                      '##.###.###/####-##'
                                                  )}`
                                                : ''}
                                            {processoShow
                                                .pessoa_responsavel_tecnico
                                                ?.responsavel_tecnico
                                                ?.pessoa_tecnico_profissional
                                                ?.cpf
                                                ? `CPF: ${formatForMask(
                                                      processoShow
                                                          .pessoa_responsavel_tecnico
                                                          .responsavel_tecnico
                                                          .pessoa_tecnico_profissional
                                                          .cpf,
                                                      '###.###.###-##'
                                                  )}`
                                                : ''}
                                        </td>
                                    </tr>
                                    {!!processoShow.user_avaliador
                                        ?.pessoa_tecnico_profissional && (
                                        <tr>
                                            <th>Analista Responsável</th>
                                            <td>
                                                {`${processoShow.user_avaliador?.pessoa_tecnico_profissional?.nome} `}{' '}
                                                -
                                                {processoShow.user_avaliador
                                                    ?.pessoa_tecnico_profissional
                                                    ?.cnpj
                                                    ? ` CNPJ: ${formatForMask(
                                                          processoShow
                                                              .user_avaliador
                                                              .pessoa_tecnico_profissional
                                                              .cnpj,
                                                          '##.###.###/####-##'
                                                      )}`
                                                    : ''}
                                                {processoShow.user_avaliador
                                                    ?.pessoa_tecnico_profissional
                                                    ?.cpf
                                                    ? ` CPF: ${formatForMask(
                                                          processoShow
                                                              .user_avaliador
                                                              .pessoa_tecnico_profissional
                                                              .cpf,
                                                          '###.###.###-##'
                                                      )}`
                                                    : ''}
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <th>Endereço da Empresa</th>
                                        <td>
                                            {processoShow.pessoa?.logradouro},
                                            {` ${processoShow.pessoa?.bairro}`}{' '}
                                            -{processoShow.pessoa?.cep} -
                                            {processoShow.pessoa?.cidade}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Legislações</th>
                                        <td>
                                            <button
                                                type="button"
                                                className="btn btn-default"
                                                onClick={() =>
                                                    setShowLegisModal(true)
                                                }
                                            >
                                                Visualizar
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Status</th>
                                        <td>
                                            <span className="badge badge-success">
                                                {
                                                    processoShow.status_processo
                                                        ?.nome
                                                }
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Progresso</th>
                                        <td className="project_progress">
                                            <div className="progress progress-sm">
                                                <ProgressBar
                                                    ariaVolumemax="100"
                                                    ariaVolumemin="0"
                                                    ariaVolumenow={
                                                        (processoShow.passo *
                                                            100) /
                                                        20
                                                    }
                                                    className="progress-bar bg-green"
                                                    percents={
                                                        (processoShow.passo *
                                                            100) /
                                                        20
                                                    }
                                                    role="progressbar"
                                                />
                                            </div>
                                            <small>
                                                {(processoShow.passo * 100) /
                                                    20}
                                                % completo
                                            </small>
                                        </td>
                                    </tr>
                                    {newFlow.current ? (
                                        <>
                                            {processoShow &&
                                                processoShow.status_processo &&
                                                processoShow.status_processo
                                                    .id === 8 && (
                                                    <>
                                                        {processoShow &&
                                                            processoShow.projeto &&
                                                            ((processoShow
                                                                .tipo_licenca
                                                                .has_apia_atia &&
                                                                processoShow.indicadores) ||
                                                                !processoShow
                                                                    .tipo_licenca
                                                                    .has_apia_atia) && (
                                                                <>
                                                                    <tr>
                                                                        <th
                                                                            className="text-center"
                                                                            colSpan="2"
                                                                        >
                                                                            DESEJA
                                                                            CONTINUAR
                                                                            O
                                                                            LICENCIAMENTO
                                                                            AMBIENTAL?{' '}
                                                                            <br />
                                                                            GERAR
                                                                            TAXA
                                                                            PARA
                                                                            PAGAMENTO?
                                                                        </th>
                                                                    </tr>

                                                                    <tr>
                                                                        <th
                                                                            className="text-center"
                                                                            colSpan="2"
                                                                        >
                                                                            {loading ? (
                                                                                <FaCircleNotch className="spinner" />
                                                                            ) : (
                                                                                <div className="btn btn-group">
                                                                                    <button
                                                                                        className="btn btn-success"
                                                                                        onClick={
                                                                                            handleContinuar
                                                                                        }
                                                                                    >
                                                                                        Sim
                                                                                    </button>
                                                                                    <button
                                                                                        className="btn btn-danger"
                                                                                        onClick={
                                                                                            handleCancelar
                                                                                        }
                                                                                    >
                                                                                        Não
                                                                                    </button>
                                                                                </div>
                                                                            )}
                                                                        </th>
                                                                    </tr>
                                                                </>
                                                            )}
                                                    </>
                                                )}
                                        </>
                                    ) : (
                                        <>
                                            {processoShow.status_processo_id ===
                                                4 && (
                                                <>
                                                    <tr>
                                                        <th
                                                            className="text-center"
                                                            colSpan="2"
                                                        >
                                                            DESEJA CONTINUAR O
                                                            LICENCIAMENTO
                                                            AMBIENTAL?
                                                        </th>
                                                    </tr>

                                                    <tr>
                                                        <th
                                                            className="text-center"
                                                            colSpan="2"
                                                        >
                                                            {loading ? (
                                                                <FaCircleNotch className="spinner" />
                                                            ) : (
                                                                <div className="btn btn-group">
                                                                    <button
                                                                        className="btn btn-success"
                                                                        onClick={
                                                                            handleContinuar
                                                                        }
                                                                    >
                                                                        Sim
                                                                    </button>
                                                                    <button
                                                                        className="btn btn-danger"
                                                                        onClick={
                                                                            handleCancelar
                                                                        }
                                                                    >
                                                                        Não
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </th>
                                                    </tr>
                                                </>
                                            )}
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </>
                ) : (
                    <Loading />
                )}
            </div>
            <div className="row">
                {[7, 9, 10, 11].includes(processoShow.status_processo_id) && (
                    <Certidao processo={processoShow} />
                )}

                {processoShow.status_processo_id === 5 && (
                    <TaxaAmbiental processo={processoShow} />
                )}

                <div className="col-md-4">
                    <ExtratoPublicacaoAmbiental
                        processo_id={processo_id}
                        type="REQUERIMENTO"
                    />
                </div>

                <div className="col-md-4">
                    <MovimentacaoProcesso processo_id={processo_id} />
                </div>

                <div className="col-md-4">
                    <CheckList lista />
                </div>
                <div className="col-md-4">
                    <MensagensProcesso processo={processoShow} />
                </div>

                {processoShow.status_processo_id === 7 && (
                    <div className="col-md-4">
                        <ExtratoPublicacaoAmbiental
                            processo_id={processo_id}
                            type="RECEBIMENTO"
                        />
                    </div>
                )}

                {[7, 8, 9, 10, 11].includes(
                    processoShow.status_processo_id
                ) && (
                    <div className="col-md-4">
                        <Retificacao />
                    </div>
                )}
                {processoShow.status_processo_id >= 4 && (
                    <div className="col-md-4">
                        {Object.keys(processoShow).length ? (
                            <>
                                {!!processoShow.analise_previa?.id &&
                                    !!processoShow.tipo_licenca?.id && (
                                        <AnaliseTecImpacto
                                            analise_id={
                                                processoShow.analise_previa.id
                                            }
                                            tipo_licenca_id={
                                                processoShow.tipo_licenca.id
                                            }
                                        />
                                    )}
                            </>
                        ) : (
                            <Loading />
                        )}
                    </div>
                )}
            </div>
        </BasicContent>
    );
};

export default Detalhe;
