import { apiWithToken } from '../api';

const resouceUrl = '/resposta_questao_analise';
const token = localStorage.getItem('@token');
let api = apiWithToken(token);

export const load = async (params) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let page = urlParams.has('page') ? { page: urlParams.get('page') } : {};
  let search = urlParams.has('search')
    ? { search: urlParams.get('search') }
    : {};
  return await api.get(`${resouceUrl}`, {
    params: {
      ...params,
      ...page,
      ...search,
    },
  });
};

export const select = async () => {

  return await api.get(`${resouceUrl}`, {
    params: {
      fields:'id,nome',
      raw_list: true
    }
  });
};

export const show = async (id, params) => {
  return await api.get(`${resouceUrl}/${id}`, params);
}

export const update = async (id, meio_analise) => {
  return await api.put(`${resouceUrl}/${id}`, meio_analise);
}


export const store = async (meio_analise) => {
  let response = await api.post(resouceUrl , meio_analise);
  return response;
}

export const destroy = async (id) => {

  return await api.delete(`${resouceUrl}/${id}`);
}
