import * as Yup from 'yup';
import { validateCNPJ, validatePhone, validateCep } from 'validations-br';

const schema = Yup.object().shape({
  logo: Yup.string().required('Logo é obrigatório.'),
  logo_municipio: Yup.string().nullable().required('Logo do município é obrigatório.'),
  nome: Yup.string().required('Nome da entidade é obrigatório.'),
  estado_id: Yup.number().required('Escolha um Estado.'),
  municipio_id: Yup.number().required('Escolha um Município.'),
  nome_governador: Yup.string().required('Informe o Nome do Governador.'),
  nome_prefeito: Yup.string().required('Informe o Nome do Prefeito'),
  site_secretaria: Yup.string().url('Essa URL é inválida. Informe o protocolo HTTP!').nullable(),
  telefone_secretaria: Yup.string()
  .nullable()
  .test(
    "is-telefone_secretaria",
    "O telefone não é valido",
    (value) => {
      if (value !== null && value !== undefined && value !== '') {
        return validatePhone(value);
      }
      return true;
    }
  ),
  secretaria_faz_licenciamento: Yup.boolean().required('Campo obrigatório.'),
  // legislacao_federal: Yup.string().required('Valor requerido'),
  // legislacao_estadual: Yup.string().required('Valor requerido'),
  // legislacao_municipal: Yup.string().required('Valor requerido'),
  nome_gestor_ambiental: Yup.string().required('O Nome do gestor é obrigatório.'),
  funcao_gestor_ambiental: Yup.string().required('A função é obrigatório.'),
  nome_orgao_ambiental: Yup.string().required('O nome do órgão é obrigatório.'),
  // // instrucoes_normativas: Yup.string().required('Valor requerido'),
  secretaria_tem_conselho_meio_ambiente: Yup.boolean().required('Campo obrigatório.'),
  legislacao_conselho: Yup.string().required('A legislação é obrigatória.'),
  conselho_tem_preiodicidade_de_reunioes: Yup.boolean().required('Campo obrigatório.'),
  secretaria_possui_fundo_de_ambiente: Yup.boolean().required('Campo obrigatório.'),
  tem_pf_ou_pj_realizando_servicos_ambientais: Yup.boolean().required('Campo obrigatório.'),
  // legislacao_fundo_meio_ambiente: Yup.string().required('Valor requerido'),
  anexo_assinatura: Yup.string().nullable(),
  email: Yup.string().email('E-mail não é inválido.').required('E-mail é obrigatório.'),
  cnpj: Yup.string()
  .test(
    "is-cnpj",
    "O CNPJ não é valido.",
    (value) => {
      if(validateCNPJ(value)) {
        return true;
      }
      return false;
    }
  ).required('CNPJ é obrigatório.'),


  telefone_fixo: Yup.string()
  .nullable()
  .test(
    "is-telefone_fixo",
    "O telefone fixo não é valido",
    (value) => {
      if (value !== null && value !== undefined && value !== '') {
        return validatePhone(value);
      }
      return true;
    }
  ),
  telefone_movel: Yup.string()
  .nullable()
  .test(
    "is-telefone_movel",
    "Telefone não é valido.",
    (value) => {
      if (value !== null && value !== undefined && value !== '') {
        return validatePhone(value);
      }
      return true;
    }
  ).nullable(),
  cep: Yup.string()
  .test(
    "is-cep",
    "CEP não é valido",
    (value) => validateCep(value)
  ),
  logradouro: Yup.string().required('Logradouro é obrigatório.'),
  numero: Yup.string().required('Número é obrigatório.'),
  bairro: Yup.string().required('Bairro é obrigatório.'),
  payment_gateway: Yup.string(
    'Meio de Pagamento precisa ser uma sequencia de caracteres'
  ).required('O meio de pagamento é obrigatório.'),
  galax_hash: Yup.string('O Galax Pay Hash precisa ser uma sequencia numérica')
    .when('payment_gateway', {
    is: val => val === 'galax_pay',
    then: Yup.string()
      .required('O Galax Pay Hash é obrigatório.')  
  }),
  galax_id: Yup.string('O Galax Pay ID precisa ser uma sequencia numérica')
      .when('payment_gateway', {
        is: val => val === 'galax_pay',
        then: Yup.string()
          .required('O Galax Pay ID é obrigatório.')
  }),
});

export default schema;
