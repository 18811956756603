import * as Yup from 'yup';


const schema = Yup.object().shape({
    meio_abiotico: Yup.boolean(),
    meio_biotico: Yup.boolean(),
    meio_socioeconomico: Yup.boolean(),
    flora: Yup.boolean(),
    fauna: Yup.boolean(),
    ar: Yup.boolean(),
    agua: Yup.boolean(),
    paisagem: Yup.boolean(),
    solo_subsolo: Yup.boolean(),
    nucleo_populacional: Yup.boolean(),
    social_cultural: Yup.boolean(),
    administrativo: Yup.boolean(),
}).test({
    name:'selecione pelo menos uma opção de cada categoria.',
    test: function(value) {
    const { meio_abiotico, meio_biotico, meio_socioeconomico } = value;
    if (!meio_abiotico && !meio_biotico && !meio_socioeconomico) {
        return this.createError({
            path: 'selectMeio',
            message: 'Pelo menos um dos campos MEIO ABIÓTICO, MEIO BIÓTICO ou MEIO SÓCIOECONÔMICO deve ser selecionado.',
        });
    }
    return true;
},
}).test({
    name:'selecione pelo menos uma opção de cada categoria.',
    test: function(value) {
        const {flora, fauna, meio_abiotico} = value;
        if(meio_abiotico && (!flora &&  !fauna)) {
                return  this.createError({
                    path: 'meio_abiotico',
                    message: 'Pelo menos um dos campos flora ou fauna devem ser selecionado!',
                });
        }
        return true;
    }    
}).test({
    name:'selecione pelo menos uma opção de cada categoria.',
    test: function(value) {
        const {ar, agua, paisagem, solo_subsolo, meio_biotico} = value;
        if(meio_biotico && (!ar && !agua && !paisagem && !solo_subsolo)) {
            return  this.createError({
                path: 'meio_biotico',
                message: 'Pelo menos um dos campos ar, água, paisagem ou solo subsolo devem ser selecionado!',
            });
        }
        return true;
    }
}).test({
    name:'selecione pelo menos uma opção de cada categoria.',
    test: function(value) {
        const {nucleo_populacional, social_cultural, administrativo, meio_socioeconomico} = value;
        if(meio_socioeconomico && (!nucleo_populacional && !social_cultural && !administrativo)) {
            return  this.createError({
                path: 'meio_socioeconomico',
                message: 'Pelo menos um dos campos nucleo populacional, social cultural ou administrativo devem ser selecionado!',
            });
        }
        return true;
    }
});

export default schema;