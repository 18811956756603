import * as actions from './actionType';

export const GerenteReducer = (
  state = {
    gerentes: {},
    gerente: {},
    status_response: null,
    errors: {}
  },
  action
) => {
  switch (action.type) {
    case actions.GERENTES_DATA:
      return {
        ...state,
        gerentes: action.gerentes.data,
      };
    case actions.GET_GERENTE:
      return {
        ...state,
        gerente: action.gerente.data.data,
      };
    case actions.CHANGE_STATUS_CODE:
      return {
        ...state,
        status_response: action.status,
      };
    case actions.GERENTE_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
