import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import buscaCep from '~/services/cep';
import FileInput from '~/components/FileInput';
import mask from '~/utils/formatCpfCnpj'
import maskCEP from '~/utils/formatCep'
import formatPhone from '~/utils/formatPhone'

const FieldsPessoaFisica = ({ pessoa, setPessoa, errors }) => {
  const { uf_data } = useSelector(({ UfDataReducer }) => UfDataReducer);

  const handleChange = event => {
    const { name, value } = event.target;
    setPessoa({
      ...pessoa,
      [name]: value
    });
  };

  const handleBuscaCep = async (event) => {
    const { value } = event.target;
    const cep = value;
    try {
      const data = await buscaCep.get(`${cep}/json`);
      const endereco = data.data;
      const finded = {
        logradouro: endereco.logradouro,
        bairro: endereco.bairro,
        cidade: endereco.localidade
      }
      Object.assign(pessoa, finded);
      setPessoa({
        ...pessoa
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div style={{ border: '1px solid black', borderRadius: '3px',padding: '15px 20px', margin: '10px 0px' }}>
      <h5>Dados de Pessoa Física</h5>
      <Form.Group as={Row}>
        <Col sm={6}>
          <Form.Label>CPF</Form.Label>
          <Form.Control
            isInvalid={!!errors.cpf}
            maxLength="14"
            name="cpf"
            onChange={handleChange}
            placeholder="CPF"
            type="text"
            value={mask(pessoa.cpf ?? '') || ''}
          />
          <Form.Control.Feedback type="invalid">
            {errors.cpf}
          </Form.Control.Feedback>
        </Col>
        <Col>
          <Form.Label>Anexo</Form.Label>
          <FileInput
            dataError={errors.anexo_cpf}
            name="anexo_cpf"
            data={pessoa}
            setData={setPessoa}
          />
          <Form.Control.Feedback type="invalid">
            {errors.anexo_cpf}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col sm={6}>
          <Form.Label>RG</Form.Label>
          <Form.Control
            isInvalid={!!errors.rg}
            name="rg"
            onChange={handleChange}
            placeholder="RG"
            type="text"
            value={pessoa.rg || ''}
          />
          <Form.Control.Feedback type="invalid">
            {errors.rg}
          </Form.Control.Feedback>
        </Col>
        <Col>
          <Form.Label>Anexo</Form.Label>
          <FileInput
            dataError={errors.anexo_rg}
            name="anexo_rg"
            data={pessoa}
            setData={setPessoa}
          />
          <Form.Control.Feedback type="invalid">
            {errors.anexo_rg}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group>
        <Form.Label>RG UF</Form.Label>
        <Form.Control
          as="select"
          isInvalid={!!errors.uf_rg_id}
          name="uf_rg_id"
          onChange={handleChange}
          placeholder="UF RG"
          type="text"
          value={pessoa.uf_rg_id ? pessoa.uf_rg_id : 1}
        >
          {uf_data[0] && uf_data.map(
            uf => (
              <option
                key={uf.id}
                value={uf.id}
              >
                {uf.uf}
              </option>
            )
          )}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {errors.uf_rg_id}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>CEP</Form.Label>
        <Form.Control
          isInvalid={!!errors.cep}
          name="cep"
          maxLength="9"
          onBlur={handleBuscaCep}
          onChange={handleChange}
          placeholder="CEP"
          type="text"
          value={maskCEP(pessoa.cep ?? '') || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.cep}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Row}>
        <Col sm={6}>
          <Form.Label>Logradouro</Form.Label>
          <Form.Control
            isInvalid={!!errors.logradouro}
            name="logradouro"
            onChange={handleChange}
            placeholder="Logradouro"
            type="text"
            value={pessoa.logradouro || ''}
          />
          <Form.Control.Feedback type="invalid">
            {errors.logradouro}
          </Form.Control.Feedback>
        </Col>
        <Col>
          <Form.Label>Anexo</Form.Label>
          <FileInput
            dataError={errors.anexo_endereco}
            name="anexo_endereco"
            data={pessoa}
            setData={setPessoa}
          />
          <Form.Control.Feedback type="invalid">
            {errors.anexo_endereco}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group>
        <Form.Label>Número</Form.Label>
        <Form.Control
          isInvalid={!!errors.numero_endereco}
          name="numero_endereco"
          onChange={handleChange}
          placeholder="Número"
          type="text"
          value={pessoa.numero_endereco || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.numero_endereco}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Bairro</Form.Label>
        <Form.Control
          isInvalid={!!errors.bairro}
          name="bairro"
          onChange={handleChange}
          placeholder="Bairro"
          type="text"
          value={pessoa.bairro || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.bairro}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Cidade</Form.Label>
        <Form.Control
          isInvalid={!!errors.cidade}
          name="cidade"
          onChange={handleChange}
          placeholder="Cidade"
          type="text"
          value={pessoa.cidade || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.cidade}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>UF</Form.Label>
        <Form.Control
          as="select"
          isInvalid={!!errors.uf_endereco_id}
          name="uf_endereco_id"
          onChange={handleChange}
          placeholder="UF"
          type="text"
          value={pessoa.uf_endereco_id ? pessoa.uf_endereco_id : 1}
        >
          {uf_data[0] && uf_data.map(
            uf => (
              <option
                key={uf.id}
                value={uf.id}
              >
                {uf.uf}
              </option>
            )
          )}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {errors.uf_endereco_id}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Número de WhatsApp</Form.Label>
        <Form.Control
          isInvalid={!!errors.numero_whatsapp}
          name="numero_whatsapp"
          onChange={handleChange}
          placeholder="Número de WhatsAp"
          type="text"
          maxLength="15"
          value={formatPhone(pessoa.numero_whatsapp ?? '') || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.numero_whatsapp}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Fone de contato</Form.Label>
        <Form.Control
          isInvalid={!!errors.telefone}
          name="telefone"
          onChange={handleChange}
          placeholder="Fone de contato"
          type="text"
          maxLength="15"
          value={formatPhone(pessoa.telefone ?? '') || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.telefone}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
}

export default FieldsPessoaFisica;
