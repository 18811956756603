export const LOAD_POTENCIAL_POLUIDORES = 'LOAD_POTENCIAL_POLUIDORES';
export const SELECT_POTENCIAL_POLUIDORES = 'SELECT_POTENCIAL_POLUIDORES';
export const POTENCIAL_POLUIDOR_ERROR = 'POTENCIAL_POLUIDOR_ERROR';
export const POTENCIAL_POLUIDOR_GET_ERRORS = 'POTENCIAL_POLUIDOR_GET_ERRORS';
export const POTENCIAL_POLUIDORES_DATA = 'POTENCIAL_POLUIDORES_DATA';
export const STORE_POTENCIAL_POLUIDOR = 'STORE_POTENCIAL_POLUIDOR';
export const GET_POTENCIAL_POLUIDOR = 'GET_POTENCIAL_POLUIDOR';
export const REMOVER_POTENCIAL_POLUIDOR = 'REMOVER_POTENCIAL_POLUIDOR';
export const SHOW_POTENCIAL_POLUIDOR = 'SHOW_POTENCIAL_POLUIDOR';
export const UPDATE_POTENCIAL_POLUIDOR = 'UPDATE_POTENCIAL_POLUIDOR';
