import * as actions from './actionType';

export const MunicipioReducer = (state = {municipios: { data: [] }, municipio: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.MUNICIPIOS_DATA:
      return {
        ...state,
        municipios: action.municipios.data,
        links: action.municipios.data.links,
        meta: action.municipios.data.meta,
      };
    case actions.GET_MUNICIPIO:
      return {
        ...state,
        municipio: action.municipio.data.data,
        status_response: action.municipio.status,
      };
    case actions.MUNICIPIO_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
