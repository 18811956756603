import React, { useState, useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { loadEntidades } from '~/store/modules/entidade/action';
import { loadTipoUsers } from '~/store/modules/tipo_user/action';
import { filterUsers } from '~/store/modules/user/action';
import mask from '~/utils/formatCpfCnpj';
import removeMask from '~/utils/removeMask';
import { Loading } from '~/components';

const Filtro = ({showModal, setShowModal, setClearFilter}) => {
    const [entidadeSelect, setEntidadeSelect] = useState();
    const [cpfCnpjData, setCpfCnpjData] = useState('');
    const [typeUser, setTypeUser] = useState();
    const [email, setEmail] = useState('');
    const [searchName, setSearchName] = useState('');
    const [options, setOptions] = useState();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { entidades } = useSelector(({ EntidadeReducer }) => EntidadeReducer);
    const { tipo_users } = useSelector (({TipoUserReducer}) => TipoUserReducer);
    const { users } = useSelector (({UserReducer}) => UserReducer);
    
    const handleClose = () => {
        setShowModal(false);
    };

    useEffect(() => {
        dispatch(loadEntidades({raw_list:false}));
    },[dispatch]);

    useEffect(() => {
        dispatch(loadTipoUsers({raw_list:false}));
    }, [dispatch])

    const selectTypeUser = (event) => {
        if (event.target.value) {
            setTypeUser(event.target.value)
        }
    }

    const selectEntidade = (event) =>{
        if (event.target.value) {
            setEntidadeSelect(event.target.value);
        }
    }

    const handleSearch = async () => {
        let cpfCnpjFormated = removeMask(cpfCnpjData);
        try {
            setLoading(true);
            await dispatch(filterUsers({
                    search_entidade_id: entidadeSelect,
                    search_name: searchName,
                    search_cpf_cnpj: cpfCnpjFormated,
                    search_tipo_user_id: typeUser,
                    search_email: email
                }  
            ));
            
            setOptions(true)
        } catch (error) {
            console.log(error);
            setLoading(false);
        }finally{
            setLoading(false);
        }
    }

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="modal-xl"
            onHide={handleClose}
            show={showModal}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Filtros de usuários
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="row">
                <div className="form-group col-md-2">
                    <label htmlFor="estado_id">Entidade</label>
                    <Form.Control
                        as="select"
                        id="entidade_id"
                        name="entidade_id"
                        onChange={selectEntidade}
                        type="number"
                        value={entidadeSelect}
                    >
                        <option value="">
                        .::Selecione::.
                        </option>
                        {entidades.data && entidades.data.map(entidade => (
                        <option
                            key={entidade.id}
                            value={entidade.id ?? ''}
                        >
                            {entidade.nome}
                        </option>
                        ))}
                    </Form.Control>
                </div>
                <div className="form-group col-md-2">
                    <label htmlFor="tipo_user">Tipo de usuário</label>
                    <Form.Control
                        as="select"
                        id="tipo_user"
                        onChange={selectTypeUser}
                        type="number"
                        value={typeUser}
                    >
                        <option value="">
                            .::Selecione::.
                        </option>
                        {tipo_users.data && tipo_users.data.map(tipo_user => (
                            <option
                                key={tipo_user.id}
                                value={tipo_user.id}
                            >
                                {tipo_user.nome}
                            </option>
                        ))}
                    </Form.Control>
                </div>
                <div className="form-group col-md-2">
                    <label htmlFor="nome">CPF/CNPJ</label>
                    <Form.Control
                        id="cpf_cnpj"
                        name="cpf_cnpj"
                        onChange={(event) => setCpfCnpjData(mask(event.target.value))}
                        type="text"
                        maxLength="18"
                        value={cpfCnpjData}
                    />
                </div>
                <div className="form-group col-md-2">
                    <label htmlFor="nome">E-mail</label>
                    <Form.Control
                        id="email"
                        name="email"
                        onChange={(event) => setEmail(event.target.value)}
                        type="email"
                        value={email}
                    />
                </div>
                <div className="form-group col-md-2">
                    <label htmlFor="nome">Nome</label>
                    <Form.Control
                        id="nome"
                        name="nome"
                        onChange={(event) => setSearchName(event.target.value)}
                        type="text"
                        value={searchName}
                    />
                </div>
                <div className="form-group col-md-2 d-flex align-items-end">
                    {!!options && (
                        <button
                        className="btn btn-primary mr-1"
                        onClick={() => {
                            setOptions(undefined);
                            setSearchName('');
                            setCpfCnpjData('');
                            setEntidadeSelect('');
                            setTypeUser('');
                            setEmail('');
                            setClearFilter(undefined);
                        }}
                        type="button"
                        >
                        <i className="fa fa-times" /> Limpar
                        </button>
                    )}
                    <button
                        className="btn btn-success mr-1"
                        disabled={!entidadeSelect && !typeUser && !cpfCnpjData.length && !searchName.length && !email.length}
                        onClick={handleSearch}
                        type="button"
                    >
                        <i className="fa fa-search" /> Pesquisar
                    </button>

                </div>
            </ div>
            {loading && (
                <Loading />
            )}
            {options && !loading && users.data?.length === 0 && (
                <h4 style={{ textAlign: 'center' }}>Sem resultados!</h4>
            )}
            
            </Modal.Body>
        </Modal>
    );

}

export default Filtro;