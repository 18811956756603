import * as actions from './actionType';

export const VeiculoReducer = (state = {select_veiculos: [], veiculos: [], veiculo: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.VEICULOS_DATA:
      return {
        ...state,
        veiculos: action.veiculos.data,
        links: action.veiculos.data.links,
        meta: action.veiculos.data.meta,
      };
    case actions.SELECT_VEICULOS:
      return {
        ...state,
        select_veiculos: action.veiculos.data
      };
    case actions.GET_VEICULO:
      return {
        ...state,
        veiculo: action.veiculo.data.data,
        status_response: action.veiculo.status,
      };
    case actions.VEICULO_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
