import React from 'react';
import { Form } from 'react-bootstrap';
import { FormODS } from '../';
import { ListOds } from '../';

const FormCad = ({ handleSubmit, schema, indicadorAmbiental, setIndicadorAmbiental, errors }) => {
  const handleChange = event => {
    const { name, value, files } = event.target;
    setIndicadorAmbiental({
      ...indicadorAmbiental,
      [name]: files ? files[0] : value,
    });
  };
  const handleDeleteOds = (id) => {
    setIndicadorAmbiental({
        ...indicadorAmbiental,
        indicadores_ods: indicadorAmbiental.indicadores_ods.filter(item => item.id !== id)
    });
  }

  return (
    <Form
      id="form_indicadorAmbiental"
      onSubmit={handleSubmit}
      schema={schema}
    >
      {/* <div className="form-group">
        <label htmlFor="autor"> Autor </label>
        <Form.Control
          id="autor"
          isInvalid={!!errors.autor}
          name="autor"
          onChange={handleChange}
          placeholder="Autor"
          type="text"
          value={indicadorAmbiental.autor || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.indicadorAmbiental}
        </Form.Control.Feedback>
      </div> */}

      <div className="form-group">
        <label htmlFor="codigo"> Código </label>
        <Form.Control
          id="codigo"
          isInvalid={!!errors.codigo}
          name="codigo"
          onChange={handleChange}
          placeholder="Código"
          type="text"
          value={indicadorAmbiental.codigo || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.codigo}
        </Form.Control.Feedback>
      </div>

      <div className="form-group">
        <label htmlFor="descricao"> Descrição </label>
        <Form.Control
          id="descricao"
          isInvalid={!!errors.descricao}
          name="descricao"
          onChange={handleChange}
          placeholder="Código"
          type="text"
          value={indicadorAmbiental.descricao || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.descricao}
        </Form.Control.Feedback>
      </div>

      <div className="form-group">
        <label htmlFor="indice"> Índice </label>
        <Form.Control
          id="indice"
          isInvalid={!!errors.indice}
          name="indice"
          onChange={handleChange}
          placeholder="Código"
          type="text"
          value={indicadorAmbiental.indice || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.indice}
        </Form.Control.Feedback>
      </div>

      {/* <div className="form-group">
        <label htmlFor="valor"> Valor </label>
        <Form.Control
          id="valor"
          isInvalid={!!errors.valor}
          name="valor"
          onChange={handleChange}
          placeholder="Código"
          type="number"
          value={indicadorAmbiental.valor || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.valor}
        </Form.Control.Feedback>
      </div> */}

        <div className="form-group">
            <label htmlFor="unidade"> Unidade </label>
            <Form.Control
            id="unidade"
            isInvalid={!!errors.unidade}
            name="unidade"
            onChange={handleChange}
            placeholder="Código"
            type="text"
            value={indicadorAmbiental.unidade || ''}
            />
            <Form.Control.Feedback type="invalid">
            {errors.unidade}
            </Form.Control.Feedback>
        </div>
        {indicadorAmbiental.indicadores_ods?.length > 0 && (
            <ListOds
                indicadores_ods={indicadorAmbiental.indicadores_ods}
                handleDeleteOds={handleDeleteOds}
            />
        )}
        <div className="form-group d-flex justify-content-end">
            <FormODS
                indicadorAmbiental={indicadorAmbiental}
                setIndicadorAmbiental={setIndicadorAmbiental}
            />
        </div>
    </Form>
  );
};

export default FormCad;
