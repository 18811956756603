import * as actions from './actionType';

export const QuestaoEstudoAmbientalReducer = (state = {select_questao_estudo_ambientals: [], questao_estudo_ambientals: [], questao_estudo_ambiental: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.QUESTAO_ESTUDO_AMBIENTALS_DATA:
      return {
        ...state,
        questao_estudo_ambientals: action.questao_estudo_ambientals.data,
        links: action.questao_estudo_ambientals.data.links,
        meta: action.questao_estudo_ambientals.data.meta,
      };
    case actions.SELECT_QUESTAO_ESTUDO_AMBIENTALS:
      return {
        ...state,
        select_questao_estudo_ambientals: action.questao_estudo_ambientals.data
      };
    case actions.GET_QUESTAO_ESTUDO_AMBIENTAL:
      return {
        ...state,
        questao_estudo_ambiental: action.questao_estudo_ambiental.data.data,
        status_response: action.questao_estudo_ambiental.status,
      };
    case actions.QUESTAO_ESTUDO_AMBIENTAL_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
