import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import CustomAsyncSelect from '~/components/CustomAsyncSelect';
import {apiWithToken} from '~/services/api';
const token = localStorage.getItem('@token');
let api = apiWithToken(token);

const FormCad = ({ handleSubmit, schema, condicionante, setCondicionante, errors, entidade_id }) => {
  const [selectAtividadeIsOpen, setSelectAtividadeIsOpen] = useState(false);
  const [selectTipoLicencaIsOpen, setSelectTipoLicencaIsOpen] = useState(false);
  const [selectMeioAnaliseIsOpen, setSelectMeioAnaliseIsOpen] = useState(false);

  const options = [
    {
      id:1,
      label:'Geral',
      value:false
    },
    {
      id:2,
      label:'Específica',
      value:true
    }
  ]

  const loadOptions = async ({inputValue, hasId, id, name, route}) => {
    if(inputValue.length >= 3){
      const obj = {name: inputValue}
      if(hasId){
        obj[name] = id;
      }
      const response = await api.get(route, {
        params: {
          ...obj,
          entidade_id
        }
      })
      const options = [];
      response.data.forEach((option) => {
        options.push({
          value: option.id,
          label: option.nome,
        })
      })
      return options;
    }
  }

  const handleChange = event => {
    const { name, value } = event.target;
    if(name === 'especifica'){
      if(value === 'true'){
        setCondicionante({
          ...condicionante,
          especifica: true,
        });
      }else{
        setCondicionante({
          especifica: false,
          nome: condicionante.nome,
        });
        condicionante.atividade_id = null;
        condicionante.tipo_licenca_id = null;
        condicionante.meio_analise_id = null;
      }
    }else{
      setCondicionante({
        ...condicionante,
        [name]: value,
      });
    }
  };

  const handleSelectChange = ({option, name}) => {
    setCondicionante({
      ...condicionante,
      [name]: option.value
    });
  };

  return (
    <Form
      id="form_condicionante"
      onSubmit={handleSubmit}
      schema={schema}
    >

      <div className="form-group">
        <label htmlFor="especifica">Tipo de condicionante</label>
        <Form.Control
          as="select"
          id="especifica"
          isInvalid={!!errors.especifica}
          name="especifica"
          onChange={handleChange}
          value={condicionante.especifica}
        >
          {
            options.map(opt => (
              <option
                key={opt.id}
                value={opt.value}
              >
                {opt.label}
              </option>
            ))
          }
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {errors.especifica}
        </Form.Control.Feedback>
      </div>
      {condicionante.especifica ? (
        <div className="form-group">
          <label htmlFor="atividades">Atividade</label>
          <CustomAsyncSelect
            errorsMessage={errors.atividade_id}
            loadOptions={(inputValue) => loadOptions({inputValue, name: 'atividade_id', route: 'atividade/search'})}
            menuIsOpen={selectAtividadeIsOpen}
            onChange={(option) => handleSelectChange({option, name:'atividade_id'})}
            onInputChange={(value) => value.length >= 3 ? setSelectAtividadeIsOpen(true) : setSelectAtividadeIsOpen(false)}
            placeholder="Digite 3 ou mais caracteres para iniciar a busca..."
          />
        </div>
      ) : ''}
      {condicionante.atividade_id ? (
        <div className="form-group">
          <label htmlFor="tipo_licenca">Tipo de licença</label>
          <CustomAsyncSelect
            errorsMessage={errors.tipo_licenca_id}
            loadOptions={(inputValue) => loadOptions({inputValue, hasId: true, id: condicionante.atividade_id, name: 'atividade_id', route: 'tipo_licenca/search'})}
            menuIsOpen={selectTipoLicencaIsOpen}
            onChange={(option) => handleSelectChange({option, name:'tipo_licenca_id'})}
            onInputChange={(value) => value.length >= 3 ? setSelectTipoLicencaIsOpen(true) : setSelectTipoLicencaIsOpen(false)}
            placeholder="Digite 3 ou mais caracteres para iniciar a busca..."
          />
        </div>
      ): ''}
      {condicionante.tipo_licenca_id ? (
        <div className="form-group">
          <label htmlFor="meio_analise">Meio analise</label>
          <CustomAsyncSelect
            errorsMessage={errors.meio_analise_id}
            loadOptions={(inputValue) => loadOptions({inputValue, hasId: true, id: condicionante.tipo_licenca_id, name: 'tipo_licenca_id', route: 'meio_analise/search'})}
            menuIsOpen={selectMeioAnaliseIsOpen}
            onChange={(option) => handleSelectChange({option, name:'meio_analise_id'})}
            onInputChange={(value) => value.length >= 3 ? setSelectMeioAnaliseIsOpen(true) : setSelectMeioAnaliseIsOpen(false)}
            placeholder="Digite 3 ou mais caracteres para iniciar a busca..."
          />
        </div>
      ): ''}
      {/* {condicionante.especifica ? (
        <div className="form-group">
          <label htmlFor="especifica">Meio de analise</label>
          <Form.Control
            as="select"
            id="meio_analise"
            isInvalid={!!errors.meio_analise_id}
            name="meio_analise_id"
            onChange={handleChange}
            value={condicionante.meio_analise_id ? condicionante.meio_analise_id : condicionante.meio_analise_id = '1'}
          >
            {
              meio_analises.data && meio_analises.data.map(opt => (
                <option
                  key={opt.id}
                  value={opt.id}
                >
                  {opt.nome}
                </option>
              ))
            }
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.meio_analise_id}
          </Form.Control.Feedback>
        </div>
      ) : ''} */}

      <div className="form-group">
        <label htmlFor="nome"> Condicionante </label>
        <Form.Control
          as="textarea"
          id="nome"
          isInvalid={!!errors.nome}
          name="nome"
          onChange={handleChange}
          placeholder="Descrição"
          rows={8}
          type="text"
          value={condicionante.nome || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.nome}
        </Form.Control.Feedback>
      </div>
    </Form>
  );
};

export default FormCad;
