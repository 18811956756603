import * as actions from './actionType';

export const loadPessoaJuridicas = (params) => ({
  type: actions.LOAD_PESSOA_JURIDICAS,
  params
});


export const loadMeusEmpreendimentos = (params) => ({
  type: actions.LOAD_MEUS_EMPREENDIMENTOS,
  params
});

export const getPessoaJuridicas = pessoa_juridicas => ({
  type: actions.PESSOA_JURIDICAS_DATA,
  pessoa_juridicas,
});

export const getSelectPessoaJuridica = pessoa_juridicas => ({
  type: actions.SELECT_PESSOA_JURIDICAS,
  pessoa_juridicas,
});

export const storePessoaJuridica = pessoa_juridica => ({
  type: actions.STORE_PESSOA_JURIDICA,
  pessoa_juridica,

});

export const storeMeusEmpreendimentos = (pessoa_juridica) => ({
  type: actions.STORE_MEUS_EMPREENDIMENTOS,
  pessoa_juridica,
});
export const getPessoaJuridica = pessoa_juridica => ({
  type: actions.GET_PESSOA_JURIDICA,
  pessoa_juridica,
});

export const removerPessoaJuridica = (id) => ({
  type: actions.REMOVER_PESSOA_JURIDICA,
  id
});

export const showPessoaJuridica = id => ({
  type: actions.SHOW_PESSOA_JURIDICA,
  id,
});

export const updatePessoaJuridica = (pessoa_juridica, id) => ({
  type: actions.UPDATE_PESSOA_JURIDICA,
  pessoa_juridica,
  id
});

export const getErros = errors => ({
  type: actions.PESSOA_JURIDICA_ERROR,
  errors,
});
