import { combineReducers } from 'redux';
import { LoginReducer } from './login/reducer';
import { ErrorsReducer } from './errors/reducer';
import { CadastroFisicaReducer } from './cpf/reducer';
import { CadastroJuridicaReducer } from './cnpj/reducer';
import { UfDataReducer } from './uf/reducer';
import { EntidadeReducer } from './entidade/reducer';
import { EstadoReducer } from './estado/reducer';
import { MunicipioReducer } from './municipio/reducer';
import { MenuReducer } from './menu/reducer';
import { TipoUserReducer } from './tipo_user/reducer';
import { RecursoReducer } from './recurso/reducer';
import { PotencialPoluidorReducer } from './potencial_poluidor/reducer';
import { AtividadeReducer } from './atividade/reducer';
import { TipoLicencaReducer } from './tipo_licenca/reducer';
import { PessoaJuridicaReducer } from './pessoa_juridica/reducer';
import { ArquivoReducer } from './arquivo/reducer';
import { PessoaFisicaReducer } from './pessoa_fisica/reducer';
import { TecnicoProfissionalReducer } from './tecnico_profissional/reducer';
import { ProcessoReducer } from './processo/reducer';
import { ResponsavelTecnicoReducer } from './responsavel_tecnico/reducer';
import { ListaDocumentoReducer } from './lista_documento/reducer';
import { DocumentoProcessoReducer } from './documento_processo/reducer';
import { MeioAnaliseReducer } from './meio_analise/reducer';
import { AnaliseReducer } from './analise/reducer';
import { TipoMeioAnaliseReducer } from './tipo_meio_analise/reducer';
import { RespostaQuestaoReducer } from './resposta_questao/reducer';
import { RespostaQuestaoMemorialReducer } from './resposta_questao_memorial/reducer';
import { QuestaoAnaliseReducer } from './questao_analise/reducer';
import { PagamentosPendentesReducer } from './pagamentos_pendentes/reducer';
import { IndicadorAmbientalReducer } from './indicadorAmbiental/reducer';
import { UserReducer } from './user/reducer';
import { ProjetoProcessoReducer } from './projeto_processo/reducer';
import { CondicionanteReducer } from './condicionante/reducer';
import { MensagemReducer } from './mensagem/reducer';
import { NotaTecAmbientalReducer } from './nota_tec_ambiental/reducer';
import { VeiculoReducer } from './veiculo/reducer';
import { MotoristaReducer } from './motorista/reducer';
import { VistoriaReducer } from './vistoria/reducer';
import { RelatorioVistoriaReducer } from './relatorio_vistoria/reducer';
import { QuestaoEstudoAmbientalReducer } from './questao_estudo_ambiental/reducer';
import { AlternativaQuestaoAnaliseReducer } from './alternativa_questao_analise/reducer';
import { ObservacaoReducer } from './observacao/reducer';
import { IndicadorProcessoReducer } from './indicadorProcesso/reducer';
import { GerenteReducer } from './gerente/reducer';
import { TecnicoAvaliadorReducer } from './tecnico_avaliador/reducer';
import { FotoVistoriaReducer } from './foto_vistoria/reducer';
import { LegislacaoReducer } from './legislacao_atividade/reducer';
import { ConfigAutorizacaoReducer } from './config_autorizacao/reducer';
import { AutorizacaoProcessoReducer } from './autorizacao_processo/reducer';
import { ValorReferenciaReducer } from './valor_referencia/reducer';
import { PorteEmpreendimentoReducer } from './porte_empreendimento/reducer';

export default combineReducers({
  LoginReducer,
  ErrorsReducer,
  CadastroFisicaReducer,
  CadastroJuridicaReducer,
  UfDataReducer,
  EntidadeReducer,
  EstadoReducer,
  MunicipioReducer,
  MenuReducer,
  TipoUserReducer,
  RecursoReducer,
  PotencialPoluidorReducer,
  AtividadeReducer,
  TipoLicencaReducer,
  PessoaJuridicaReducer,
  ArquivoReducer,
  PessoaFisicaReducer,
  TecnicoProfissionalReducer,
  ProcessoReducer,
  ResponsavelTecnicoReducer,
  ListaDocumentoReducer,
  DocumentoProcessoReducer,
  MeioAnaliseReducer,
  TipoMeioAnaliseReducer,
  AnaliseReducer,
  RespostaQuestaoReducer,
  RespostaQuestaoMemorialReducer,
  QuestaoAnaliseReducer,
  PagamentosPendentesReducer,
  UserReducer,
  IndicadorAmbientalReducer,
  ProjetoProcessoReducer,
  CondicionanteReducer,
  MensagemReducer,
  NotaTecAmbientalReducer,
  VeiculoReducer,
  MotoristaReducer,
  VistoriaReducer,
  RelatorioVistoriaReducer,
  QuestaoEstudoAmbientalReducer,
  AlternativaQuestaoAnaliseReducer,
  ObservacaoReducer,
  IndicadorProcessoReducer,
  GerenteReducer,
  TecnicoAvaliadorReducer,
  FotoVistoriaReducer,
  LegislacaoReducer,
  ConfigAutorizacaoReducer,
  AutorizacaoProcessoReducer,
  ValorReferenciaReducer,
  PorteEmpreendimentoReducer,
});
