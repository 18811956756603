export const LOAD_RECURSOS = 'LOAD_RECURSOS';
export const LOAD_SELECT_RECURSOS = 'LOAD_SELECT_RECURSOS';
export const SELECT_RECURSOS = 'SELECT_RECURSOS';
export const RECURSO_ERROR = 'RECURSO_ERROR';
export const RECURSO_GET_ERRORS = 'RECURSO_GET_ERRORS';
export const RECURSOS_DATA = 'RECURSOS_DATA';
export const STORE_RECURSO = 'STORE_RECURSO';
export const GET_RECURSO = 'GET_RECURSO';
export const REMOVER_RECURSO = 'REMOVER_RECURSO';
export const SHOW_RECURSO = 'SHOW_RECURSO';
export const UPDATE_RECURSO = 'UPDATE_RECURSO';
