import React, { useState} from 'react';

import { Link } from 'react-router-dom';
import mask from '../../../../utils/formatCpfCnpj';

const Card = ({ onHandleSubmit, user, setUser }) => {
  const [passwordTyepe, setPasswordType] = useState('password');
    
  const handleClick = () => {
      const newType = passwordTyepe === 'password' ? 'text' : 'password';
    setPasswordType(newType)
    }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser({
      ...user,
      [name]: value,
    });
  };
  return (
    <div className="card">
      <div className="card-body login-card-body">
        <p className="login-box-msg">Faça o login para iniciar</p>

        <form onSubmit={onHandleSubmit}>
          <div className="input-group mb-3">
            <input
              aria-describedby="CPF ou CNPJ"
              aria-label="CPF_CNPJ"
              className="form-control"
              name="cpf_cnpj"
              onChange={handleChange}
              placeholder="CPF ou CNPJ"
              type="text"
              value={mask(user.cpf_cnpj)}
            />
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-user" />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input
              aria-describedby="Senha"
              aria-label="Senha"
              className="form-control"
              name="password"
              onChange={handleChange}
              placeholder="Senha"
              type={passwordTyepe}
              value={user.password}
            />
            <div className="input-group-append">
              <div className="input-group-text">
                <span onClick={handleClick} className= {passwordTyepe === 'password' ? 'fas fa-lock' : 'fas fa-unlock'} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-8">
              <div className="icheck-primary">
                <input id="remember" type="checkbox" />
                <label htmlFor="remember">&nbsp; Lembrar</label>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <button className="btn btn-success btn-block" type="submit">
              Entrar
            </button>
          </div>
        </form>

        <div className="social-auth-links text-center mb-3">
          <p>- OU -</p>
          <Link className="btn btn-block btn-primary" to="/selecione-cadastro">
            Cadastre-se
          </Link>
        </div>

        <p className="mb-1 text-center">
          <a href="/recuperar-senha">Esqueci minha senha</a>
        </p>
      </div>
    </div>
  );
};

export default Card;
