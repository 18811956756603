export const initialData = {
  tipo_user_id: 4,
  nome: '',
  cpf: '',
  rg: '',
  uf_rg_id: 1,
  cep: '',
  logradouro: '',
  numero_endereco: '',
  cidade: '',
  bairro: '',
  uf_endereco_id: 1,
  telefone: '',
  numero_whatsapp: '',
  email: '',
  anexo_cpf: '',
  anexo_rg: '',
  anexo_endereco: ''
};

export default initialData;
