import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const Paginate = ({meta, load, loadParans}) => {

  let last_page = meta && parseInt(meta.last_page);
  let current_page = meta && parseInt(meta.current_page);
  let from = meta && meta.from;
  let to = meta && meta.to;
  let total = meta && meta.total;
  const dispatch = useDispatch();
  const loadData = load;
  const history = useHistory();
  const handlePage = async event => {

    await history.push(event.target.value);
    await dispatch(loadData(loadParans));
  };

  const getNumPage = () => {

    let num = [];
    if (last_page >= 15 ) {
      //se o n pagina for igual ou maior que 5
      if (current_page >= 1 && current_page <= 6) {
        for (let i = 1; i <= 8; i++) {
          num.push(i);
        }
        num.push('...');
        num.push(last_page-1);
        num.push(last_page);
      } else if (current_page <= last_page-6) {
        let range_pg_start = current_page-3;
        let range_pg_end = current_page+3;
        num.push(1);
        num.push(2);
        num.push('...');
        for (
          range_pg_start;
          range_pg_start <= range_pg_end;
          range_pg_start++
        ) {
          num.push(range_pg_start);
        }
        num.push('...');
        num.push(last_page - 1);
        num.push(last_page);

      } else {
        num.push(1);
        num.push(2);
        num.push('...');
        let range_pg_start = last_page - 8;
        let range_pg_end = last_page;
        for (range_pg_start; range_pg_start <= range_pg_end; range_pg_start++) {
          num.push(range_pg_start);
        }
      }
    } else {
      // se o n pagina for menor ou igual a 5
      for (let i = 1; i <= last_page; i++) {
        num.push(i);
      }
    }
    return num;
  }



  return (
    <>
      {last_page > 1 && (
        <>
          Listando de {from} até {to} de {total} registros
          <ul className="pagination m-0 float-right">
            <li className={`page-item ${current_page <= 1 ? 'disabled' : ''}`}>
              <button
                className="page-link"
                onClick={handlePage}
                value={`?page=${current_page - 1}`}
              >
                {' '}
                &laquo;
              </button>
            </li>
            {getNumPage().map((pg, index) => {
              return (
                <li
                  className={`page-item ${
                    meta.current_page === pg ? 'active' : ''
                  }`}
                  key={index}
                >
                  <button
                    className="page-link"
                    onClick={handlePage}
                    value={`?page=${pg}`}
                  >
                    {pg}
                  </button>
                </li>
              );
            })}

            <li
              className={`page-item ${
                current_page >= last_page ? 'disabled' : ''
              }`}
            >
              <button
                className="page-link"
                onClick={handlePage}
                value={`?page=${current_page + 1}`}
              >
                &raquo;
              </button>
            </li>
          </ul>
        </>
      )}
    </>
  );
};

export default Paginate;
