import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as AlternativaQuestaoAnalise from '~/services/AlternativaQuestaoAnalise';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadAlternativaQuestaoAnalisesWorker({ params }) {
  try {
    const response = yield call(AlternativaQuestaoAnalise.load, params);
    yield put(actions.getAlternativaQuestaoAnalises(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}


function* showAlternativaQuestaoAnaliseWorker({id, params}) {
  try {
    const response = yield call(AlternativaQuestaoAnalise.show, id, params);
    yield put(actions.getAlternativaQuestaoAnalise(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updateAlternativaQuestaoAnaliseWorker({ alternativa_questao_analise, id}) {
  try {
    const response = yield call(AlternativaQuestaoAnalise.update, id, alternativa_questao_analise);
    yield put(actions.getAlternativaQuestaoAnalise(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeAlternativaQuestaoAnaliseWorker({alternativa_questao_analise}) {

  try {
    const response = yield call(AlternativaQuestaoAnalise.store, alternativa_questao_analise);
    yield put(actions.getAlternativaQuestaoAnalise(response));
    yield put(actions.getErros({save:true}));
    yield put(getErros({save:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* removerAlternativaQuestaoAnaliseWorker({id}) {
  try {
    yield call(AlternativaQuestaoAnalise.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* ativarAlternativaQuestaoAnaliseWorker({id}) {
  try {
    yield call(AlternativaQuestaoAnalise.activate, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherAlternativaQuestaoAnalise() {
  yield takeLatest(actionType.LOAD_ALTERNATIVA_QUESTAO_ANALISES, loadAlternativaQuestaoAnalisesWorker);
  yield takeLatest(actionType.STORE_ALTERNATIVA_QUESTAO_ANALISE, storeAlternativaQuestaoAnaliseWorker);
  yield takeLatest(actionType.SHOW_ALTERNATIVA_QUESTAO_ANALISE, showAlternativaQuestaoAnaliseWorker);
  yield takeLatest(actionType.UPDATE_ALTERNATIVA_QUESTAO_ANALISE, updateAlternativaQuestaoAnaliseWorker);
  yield takeLatest(actionType.REMOVER_ALTERNATIVA_QUESTAO_ANALISE, removerAlternativaQuestaoAnaliseWorker);
  yield takeLatest(actionType.ACTIVATE_ALTERNATIVA_QUESTAO_ANALISE, ativarAlternativaQuestaoAnaliseWorker);
}

export default function* saga() {
  yield all([watcherAlternativaQuestaoAnalise()]);
}
