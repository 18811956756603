import { apiWithToken } from '../api';

const resouceUrl = '/atividade';
const token = localStorage.getItem('@token');
let api = apiWithToken(token);

export const load = async (params) => {
  // console.log("serivce atividades load params: ",params.where);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let page = urlParams.has('page') ? { page: urlParams.get('page') } : {};
  let search = urlParams.has('search')
    ? { search: urlParams.get('search') }
    : {};
  return await api.get(`${resouceUrl}`, {
    params: {
      ...params,
      ...page,
      ...search,
    },
  });
};

export const select = async () => {

  return await api.get(`${resouceUrl}?fields=id,nome&raw_list`);
};

export const show = async (payload) => {
  return await api.get(`${resouceUrl}/${payload.id}`, {
    params: {
      ...payload.params
    }
  });
}

export const update = async (id, atividade) => {
  return await api.put(`${resouceUrl}/${id}`, atividade);
}


export const store = async (atividade) => {
  let response = await api.post(resouceUrl, atividade);
  return response;
}

export const destroy = async (id) => {
  return await api.delete(`${resouceUrl}/${id}`);
}

export const import_data = async (entidade_id, data_file_csv) => {
  let formData = new FormData();
  formData.append('arquivo', data_file_csv);
  formData.append('entidade_id', entidade_id);

  return await api.post(`${resouceUrl}/import_data`,
    formData,
    entidade_id,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
}
export const activity_group = async (entidade_id, id) => {
    return await api.get(`${resouceUrl}/group/${entidade_id}/${id}`);
};

export const loadActivityAsOptions = async (params) => {
    return await api.get(
      `${resouceUrl}/options`,
      { params }
    );
  }

