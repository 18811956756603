import { all, put, call, takeLatest } from 'redux-saga/effects';
import * as DocumentoProcesso from '~/services/DocumentoProcesso';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadDocumentoProcessosWorker({ params }) {
  try {
    const response = yield call(DocumentoProcesso.load, params);
    yield put(actions.getDocumentoProcessos(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* showDocumentoProcessoWorker({id}) {
  try {
    const response = yield call(DocumentoProcesso.show, id);
    yield put(actions.getDocumentoProcesso(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updateDocumentoProcessoWorker({ documento_processo, id}) {
  try {
    const response = yield call(DocumentoProcesso.update, id, documento_processo);
    yield put(actions.getDocumentoProcesso(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeDocumentoProcessoWorker({documento_processo}) {
  try {
    const response = yield call(DocumentoProcesso.store, documento_processo);
    yield put(actions.getDocumentoProcesso(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* removerDocumentoProcessoWorker({id}) {
  try {
    yield call(DocumentoProcesso.destroy, id);
    yield put(actions.getErros({deleted: true}));
    yield put(getErros({deleted: true}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros({ deleted: false, ...error.response }));
      yield put(getErros({ deleted: false, ...error.response }));
    } else if (error.request) {
      yield put(getErros({ deleted: false, data: error.message }));
    } else {
      yield put(getErros({ deleted: false, data: error.message }));
    }
  }
}

function* watcherDocumentoProcesso() {
  yield takeLatest(actionType.LOAD_DOCUMENTO_PROCESSOS, loadDocumentoProcessosWorker);
  yield takeLatest(actionType.STORE_DOCUMENTO_PROCESSO, storeDocumentoProcessoWorker);
  yield takeLatest(actionType.SHOW_DOCUMENTO_PROCESSO, showDocumentoProcessoWorker);
  yield takeLatest(actionType.UPDATE_DOCUMENTO_PROCESSO, updateDocumentoProcessoWorker);
  yield takeLatest(actionType.REMOVER_DOCUMENTO_PROCESSO, removerDocumentoProcessoWorker);
}

export default function* saga() {
  yield all([watcherDocumentoProcesso()]);
}
