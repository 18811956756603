import { CNPJ_DATA, CNPJ_RESPONSE, CNPJ_ERROR } from './actionType';

export const cadastro_pessoa_juridica_response = pessoaJuridica => {
  return {
    type: CNPJ_RESPONSE,
    pessoaJuridica
  }
}

export const cadastro_pessoa_juridica_error = error => {
  return {
    type: CNPJ_ERROR,
    error
  }
}

export const cadastro_pessoa_juridica_data = data => {
  return {
    type: CNPJ_DATA,
    data
  }
}
