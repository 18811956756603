import * as Yup from 'yup';
import { validateCPF } from 'validations-br';

const schema = Yup.object().shape({
  nome: Yup.string().required('Valor requerido'),
  cpf: Yup.string()
   .test(
    "is-cpf",
    "CPF não é valido",
    (value) => validateCPF(value)
  )
  .required('CPF obrigatório'),
});

export default schema;
