export const LOAD_RESPOSTA_QUESTAO_MEMORIALS = 'LOAD_RESPOSTA_QUESTAO_MEMORIALS';
export const SELECT_RESPOSTA_QUESTAO_MEMORIALS = 'SELECT_RESPOSTA_QUESTAO_MEMORIALS';
export const RESPOSTA_QUESTAO_MEMORIAL_ERROR = 'RESPOSTA_QUESTAO_MEMORIAL_ERROR';
export const RESPOSTA_QUESTAO_MEMORIAL_GET_ERRORS = 'RESPOSTA_QUESTAO_MEMORIAL_GET_ERRORS';
export const RESPOSTA_QUESTAO_MEMORIALS_DATA = 'RESPOSTA_QUESTAO_MEMORIALS_DATA';
export const STORE_RESPOSTA_QUESTAO_MEMORIAL = 'STORE_RESPOSTA_QUESTAO_MEMORIAL';
export const GET_RESPOSTA_QUESTAO_MEMORIAL = 'GET_RESPOSTA_QUESTAO_MEMORIAL';
export const REMOVER_RESPOSTA_QUESTAO_MEMORIAL = 'REMOVER_RESPOSTA_QUESTAO_MEMORIAL';
export const SHOW_RESPOSTA_QUESTAO_MEMORIAL = 'SHOW_RESPOSTA_QUESTAO_MEMORIAL';
export const UPDATE_RESPOSTA_QUESTAO_MEMORIAL = 'UPDATE_RESPOSTA_QUESTAO_MEMORIAL';
