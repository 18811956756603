import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { Loading } from '~/components';
import { ModalAnexos } from '~/components';
import { apiWithToken } from '~/services/api';
import formatForMask from '~/utils/formatForMask';

const token = localStorage.getItem('@token');
let api = apiWithToken(token);

const Show = () => {
  let history = useHistory();
  const { user_id } = useParams();
  const [pessoa, setPessoa] = useState({});

  const [modalArquivo, setModalArquivo] = useState({
    title: '',
    path: '',
    show: false,
    handleHide: () => false,
  });

  useEffect(() => {
    const getPessoa = async () => {
      const response = await api.get(`user/${user_id}`, {
        params: {
          with: 'pessoa.porte_empreendimento,pessoa.estado_endereco'
        }
      });

      setPessoa(response.data.data.pessoa);
    }

    getPessoa();
  }, [user_id]);

  const handleClose = () => {
    history.push('/empreendimentos/meus_empreendimentos');
  };

  const handleHide = () => {
    setModalArquivo({
      title: '',
      path: '',
      show: false,
      handleHide: () => false,
    });
  };

  const handleOpenModalAnexo = (title, path) => {
    setModalArquivo({
      title,
      path,
      show: true,
      handleHide,
    });
  };

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-xl"
        onHide={handleClose}
        show
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Meus Empreendimentos
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header">
              <h2 className="card-title">Detalhes</h2>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                {Object.keys(pessoa).length ? (
                  <>
                    <table className="table table-hover table-striped table-sm">
                      <tbody>
                        <tr>
                          <th>id</th>
                          <td>{pessoa.id}</td>
                        </tr>
                        <tr>
                          <th>Razão Social</th>
                          <td>{pessoa.nome}</td>
                        </tr>

                        <tr>
                          <th>Nome Fantasia</th>
                          <td>{pessoa.nome_fantasia}</td>
                        </tr>
                        <tr>
                          <th>E-mail</th>
                          <td>{pessoa.email}</td>
                        </tr>
                        <tr>
                          <th>CPF/CNPJ</th>
                          <td>
                            {pessoa.cnpj ? formatForMask(
                                pessoa.cnpj, '##.###.###/####-##'
                              ) : ''
                            }
                            {pessoa.cpf ? formatForMask(
                                pessoa.cpf,'###.###.###-##'
                              ) : ''
                            }

                            {pessoa.anexo_cnpj ? (
                              <button
                                className="btn btn-default ml-2 float-right"
                                onClick={() =>
                                  handleOpenModalAnexo(
                                    'Anexo CNPJ',
                                    pessoa.anexo_cnpj
                                  )
                                }
                              >
                                <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                              </button>
                            ) : ''}

                            {pessoa.anexo_cpf ? (
                              <button
                                className="btn btn-default ml-2 float-right"
                                onClick={() =>
                                  handleOpenModalAnexo(
                                    'Anexo CPF',
                                    pessoa.anexo_cpf
                                  )
                                }
                              >
                                <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                              </button>
                            ) : ''}
                          </td>
                        </tr>
                        <tr>
                          <th>Certidão Simplificada da Junta Comercial</th>
                          <td>
                            {pessoa.certidao_simplificada_jc}
                            {pessoa.anexo_certidao_simplificada_jc ? (
                              <button
                                className="btn btn-default ml-2 float-right"
                                onClick={() =>
                                  handleOpenModalAnexo(
                                    'Certidão Simplificada da Junta Comercial',
                                    pessoa.anexo_certidao_simplificada_jc
                                  )
                                }
                              >
                                <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                              </button>
                            ) : ''}
                          </td>
                        </tr>
                        <tr>
                          <th>Porte Empreendimento</th>
                          <td>{pessoa.porte_empreendimento?.nome}</td>
                        </tr>
                        <tr>
                          <th>CEP</th>
                          <td>{pessoa.cep}</td>
                        </tr>
                        <tr>
                          <th>Endereço</th>
                          <td>
                            {pessoa?.logradouro ?`${pessoa.logradouro}, ` : ''}
                            {pessoa?.bairro ? `${pessoa?.bairro}, ` : ''}
                            {pessoa?.cidade ? `${pessoa?.cidade} - ` : ''}
                            {pessoa?.estado_endereco?.uf ? `${pessoa?.estado_endereco?.uf}`: ''}

                            {pessoa.anexo_endereco ? (
                              <button
                                className="btn btn-default ml-2 float-right"
                                onClick={() =>
                                  handleOpenModalAnexo(
                                    'Comprovante de endereço',
                                    pessoa.anexo_endereco
                                  )
                                }
                              >
                                <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                              </button>
                            ) : ''}
                          </td>
                        </tr>
                        <tr>
                          <th>Número de WhatsApp</th>
                          <td>{pessoa.numero_whatsapp}</td>
                        </tr>
                        <tr>
                          <th>Fone de contato</th>
                          <td>{pessoa.telefone}</td>
                        </tr>
                        <tr>
                          <th>Homepage da empresa</th>
                          <td>{pessoa.site}</td>
                        </tr>
                      </tbody>
                    </table>
                    <hr />
                    <h5>Dados Responsavel legal:</h5>
                    <table className="table table-hover table-striped table-sm">
                      <tbody>
                        <tr>
                          <th>Nome:</th>
                          <td>{pessoa.nome_responsavel_legal}</td>
                        </tr>
                        <tr>
                          <th>Função:</th>
                          <td>{pessoa.funcao_responsavel_legal}</td>
                        </tr>
                        <tr>
                          <th>CPF:</th>
                          <td>
                            {pessoa.cpf_responsavel_legal}
                            {pessoa.anexo_cpf_responsavel_legal ? (
                              <button
                                className="btn btn-default ml-2 float-right"
                                onClick={() =>
                                  handleOpenModalAnexo(
                                    'CPF Responsável Legal',
                                    pessoa.anexo_cpf_responsavel_legal
                                  )
                                }
                              >
                                <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                              </button>
                            ) : ''}
                          </td>
                        </tr>
                        <tr>
                          <th>RG:</th>
                          <td>
                            {pessoa.rg_responsavel_legal}
                            {pessoa.anexo_rg_responsavel_legal ? (
                              <button
                                className="btn btn-default ml-2 float-right"
                                onClick={() =>
                                  handleOpenModalAnexo(
                                    'RG Responsável Legal',
                                    pessoa.anexo_rg_responsavel_legal
                                  )
                                }
                              >
                                <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                              </button>
                            ) : ''}
                          </td>
                        </tr>
                        <tr>
                          <th>Endereço:</th>
                          <td>
                            {pessoa.endereco_responsavel_legal}
                            {pessoa.anexo_endereco_responsavel_legal ? (
                              <button
                                className="btn btn-default ml-2 float-right"
                                onClick={() =>
                                  handleOpenModalAnexo(
                                    'Endereço Responsável Legal',
                                    pessoa.anexo_endereco_responsavel_legal
                                  )
                                }
                              >
                                <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                              </button>
                            ) : ''}
                          </td>
                        </tr>
                        <tr>
                          <th>Fone:</th>
                          <td>{pessoa.telefone_responsavel_legal}</td>
                        </tr>
                        <tr>
                          <th>E-mail:</th>
                          <td>{pessoa.email_responsavel_legal}</td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                ) : (
                  <Loading />
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Link
            className="btn btn-default"
            to="/empreendimentos/meus_empreendimentos"
          >
            <i className="fa fa-window-close" /> Fechar
          </Link>
        </Modal.Footer>
      </Modal>
      <ModalAnexos {...modalArquivo} />
    </>
  );
};

export default Show;
