import React from 'react';
import { Content } from '~/layouts/Dash/components';


const BasicContent = ({breadcrumb, children}) => {

  const breadcrumbs = [
    {
      label: 'Pessoa Jurídica',
      to: '/empreendimentos/pessoa_juridica',
      active: false,
    },
    breadcrumb,
  ];
  return (
    <Content breadcrumbs={breadcrumbs} title="Pessoa Jurídica">
      {children}
    </Content>
  );
}

export default BasicContent;
