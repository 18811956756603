import React from 'react';
import { Form } from 'react-bootstrap';

const FormCad = ({ handleSubmit, schema,  veiculo, setVeiculo, errors }) => {
  const handleChange = event => {
    const { name, value, files } = event.target;
    setVeiculo({
      ...veiculo,
      [name]: files ? files[0] : value,
    });
  };

  return (
    <Form
      id="form_veiculo"
      onSubmit={handleSubmit}
      schema={schema}
    >
      <div className="form-group">
        <label htmlFor="modelo"> Modelo </label>
        <Form.Control
          id="modelo"
          isInvalid={!!errors.modelo}
          name="modelo"
          onChange={handleChange}
          placeholder="modelo do Veiculo"
          type="text"
          value={veiculo.modelo || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.modelo}
        </Form.Control.Feedback>
      </div>

      <div className="form-group">
        <label htmlFor="placa"> Placa </label>
        <Form.Control
          id="placa"
          isInvalid={!!errors.placa}
          name="placa"
          onChange={handleChange}
          placeholder="Placa"
          type="text"
          value={veiculo.placa || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.placa}
        </Form.Control.Feedback>
      </div>

      <div className="form-group">
        <label htmlFor="ano_fabricacao"> Ano de fabricação </label>
        <Form.Control
          id="ano_fabricacao"
          isInvalid={!!errors.ano_fabricacao}
          name="ano_fabricacao"
          onChange={handleChange}
          placeholder="Ano de fabricação"
          type="number"
          value={veiculo.ano_fabricacao || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.ano_fabricacao}
        </Form.Control.Feedback>
      </div>

      <div className="form-group">
        <label htmlFor="ano_modelo"> Ano do modelo </label>
        <Form.Control
          id="ano_modelo"
          isInvalid={!!errors.ano_modelo}
          name="ano_modelo"
          onChange={handleChange}
          placeholder="Ano do modelo"
          type="number"
          value={veiculo.ano_modelo || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.ano_modelo}
        </Form.Control.Feedback>
      </div>

      <div className="form-group">
        <label htmlFor="observacao"> Observacao </label>
        <Form.Control
          id="observacao"
          isInvalid={!!errors.observacao}
          name="observacao"
          onChange={handleChange}
          placeholder="Código da veiculo"
          type="text"
          value={veiculo.observacao || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.observacao}
        </Form.Control.Feedback>
      </div>
    </Form>
  );
};

export default FormCad;
