import React from 'react';
import { Link } from 'react-router-dom';
// import Swal from 'sweetalert2';
import { Paginate } from '~/components';
import { FaEye, FaEdit, FaTimesCircle, FaPlusCircle } from 'react-icons/fa';
// import { loadTipoLicencas } from '~/store/modules/tipo_licenca/action';
// import Show from '../../../../Atividade/Show';
// import Edit from '../Edit';
import { Button } from 'react-bootstrap';


const List = ( {
  action,
  displayName,
  id,
  // createPath,
  createUrl,
  title,
  labelCol1,
  labelCol2,
  labelCol3,
  labelCol4,
  labelCol5,
  valueCol1,
  valueCol2,
  valueCol3,
  valueCol4,
  valueCol5,
  values,
  detalhe,
  delete_isOk,
  path_detalhe,
  edit_lista_doc,
  edit_valor_ref,
  handleRemove,
  perguntas,
  handleActivate,
  edit_meio_analises,
  handleDelete,
  importDataUrl
} ) => {
  // console.log("List values "+title+": ",values);
  let path_detalhe_ = ['',''];
  if(detalhe){
    path_detalhe_ = path_detalhe.split('*');
  }

  return (
    <div className="card">
      <div className="card-header">
        <h2 className="card-title">{title}</h2>
        <div className="card-tools">
          <Link
            className="btn btn-success"
            to={{
              pathname: createUrl,
              state: { modal: true }
            }}
          >
            <i className="fa fa-plus" /> Adicionar
          </Link>
          {importDataUrl && (
            <Link
              className="btn btn-primary"
              to={{
                pathname: importDataUrl,
                state: { modal: true }
              }}
            >
              <i className="fa fa-plus-circle" /> Importar dados
            </Link>
          )}
        </div>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-bordered table-hover table-striped table-sm">
            <thead>
              <tr>
                {labelCol1 && (<th>{labelCol1}</th>)}
                {labelCol2 && (<th>{labelCol2}</th>)}
                {labelCol3 && (<th>{labelCol3}</th>)}
                {labelCol4 && (<th>{labelCol4}</th>)}
                {labelCol5 && (<th>{labelCol5}</th>)}
                <th className="text-right">Ações</th>
              </tr>
            </thead>
            <tbody>
              {values.data &&
                  values.data.map((value, index) => (
                    <tr key={index}>
                      {
                        valueCol1 &&
                        (
                          <td>
                            {typeof value[valueCol1] === 'boolean' ? (
                              value[valueCol1] ? 'Sim' : 'Não'
                            ) : value[valueCol1]}
                          </td>
                        )
                      }

                      {
                        valueCol2 &&
                        (
                          <td>
                            {typeof value[valueCol2] === 'boolean' ? (
                              value[valueCol2] ? 'Sim' : 'Não'
                            ) : value[valueCol2].nome}
                          </td>
                        )
                      }

                      {
                        valueCol3 &&
                        (
                          <td>
                            {typeof value[valueCol3] === 'boolean' ? (
                              value[valueCol3] ? 'Sim' : 'Não'
                            ) : value[valueCol3].nome}
                          </td>
                        )
                      }

                      {
                        valueCol4 &&
                        (
                          <td>
                            {typeof value[valueCol4] === 'boolean' ? (
                              value[valueCol4] ? 'Sim' : 'Não'
                            ) : value[valueCol4]}
                          </td>
                        )
                      }
                      {
                        valueCol5 &&
                        (
                          <td>
                            {value[valueCol5]}
                          </td>
                        )
                      }

                      <td className="text-right">
                        <div className="btn-group">
                          {
                            detalhe && !!perguntas &&
                            (
                              <Link
                                className="btn btn-default"
                                to={{
                                  pathname: path_detalhe_[0] + value.id + path_detalhe_[1]
                                }}
                              >
                                <FaEye /> Detalhes
                              </Link>
                            )
                          }
                          {
                            edit_lista_doc && !value.deleted_at &&
                            (
                              <Link
                                className="btn btn-info"
                                to={{
                                  pathname: `/tipo_licenca/${id}/lista_documentos/${value.id}/edit`,
                                  state: { modal: true },
                                }}
                              >
                                <FaEdit /> Editar
                              </Link>
                            )
                          }
                          {
                            edit_meio_analises && !value.deleted_at &&
                            (
                              <Link
                                className="btn btn-info"
                                to={{
                                  pathname: `/tipo_licenca/${id}/meio_analise/${value.id}/edit`,
                                  state: { modal: true },
                                }}
                              >
                                <FaEdit /> Editar
                              </Link>
                            )
                          }
                          {
                            edit_valor_ref && !value.deleted_at &&
                            (
                              <Link
                                className="btn btn-info"
                                to={{
                                  pathname: `/tipo_licenca/${id}/valor_referencia/${value.id}/edit`,
                                  state: { modal: true },
                                }}
                              >
                                <FaEdit /> Editar
                              </Link>
                            )
                          }
                          {
                            !value.deleted_at &&
                            (
                              <Button
                                className="btn btn-danger"
                                onClick={() => handleRemove(value.id)}
                              >
                                <FaTimesCircle /> Desativar
                              </Button>
                            )
                          }
                          {
                            !!value.deleted_at &&
                            (
                              <Button
                                className="btn btn-success"
                                onClick={() => handleActivate(value.id)}
                              >
                                <FaPlusCircle />  Ativar
                              </Button>
                            )
                          }
                          {
                            !!value.deleted_at && delete_isOk &&
                            (
                              <Button
                                className="btn btn-danger"
                                onClick={() => handleDelete(value.id)}
                              >
                                <FaTimesCircle />  Apagar
                              </Button>
                            )
                          }
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="card-footer clearfix">
        <Paginate
          load={action}
          loadParans={{where:`${displayName}:${id}`}}
          meta={values.meta}
        />
      </div>
    </div>
  );
};

export default List;
