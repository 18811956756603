import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Content } from '~/layouts/Dash/components';
import {
    Row, Col, Card, Table,
    Form, Button
} from 'react-bootstrap';
import { FiFilter } from 'react-icons/fi';
import { apiWithToken } from '~/services/api';
import { BlobProvider } from '@react-pdf/renderer';
import Swal from 'sweetalert2';
import RCCAPDF from './PDF';
import { loadEstados } from '~/store/modules/estado/action';
import formatPhone from '~/utils/formatPhone';

const breadcrumbs = [
    {
        label: 'Relatório',
        to: '/relatorios',
        active: false,
    }, {
        label: 'Relatório de Cadastro de Consultores Ambiental',
        to: '/relatorios/relatorio-cadastro-consultores-ambientais',
        active: true,
    }
];

export default function RCCA() {
    const token = localStorage.getItem('@token');
    const dispatch = useDispatch();
    let api = apiWithToken(token);
    const [params, setParams] = useState({});
    // const [period, setPeriod] = useState('');
    const [entityName, setEntityName] = useState('');
    const [organName, setOrganName] = useState('');
    const [logoSiblam, setLogoSiblam] = useState('');
    const [logoEntity, setLogoEntity] = useState('');
    const [logoCounty, setLogoCounty] = useState('');
    const [consultants, setConsultants] = useState(null);
    const { estados } = useSelector(({ EstadoReducer }) => EstadoReducer);

    useEffect(() => {
        dispatch(loadEstados({ raw_list: true }));
    }, [dispatch]);

    function handleChange({ target }) {
        const { name, value } = target;

        setParams({
            ...params,
            [name]: value
        })
    }

    async function getRCCA(event) {
        event.preventDefault();

        try {
            const res = await api.get(
                'relatorio-cadastro-consultores-ambientais',
                { params }
            );

            const {
                entity_name,
                organ_name, logo_siblam,
                logo_entity, consultants,
                logo_county
            } = res.data;

            setEntityName(entity_name);
            setOrganName(organ_name);
            setLogoSiblam(logo_siblam);
            setLogoEntity(logo_entity);
            setConsultants(consultants);
            setLogoCounty(logo_county);
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erro ao gerar relatório!',
            });
        }
    }


    return (
        <Content
            breadcrumbs={breadcrumbs}
            title="Relatório de Cadastro de Consultores Ambientais"
        >
            <Row>
                <Col md="3">
                    <Card>
                        <Card.Header>
                            <Card.Title>Filtros</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={getRCCA}>
                                <Form.Group>
                                    <Form.Label>Estado</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="estado_id"
                                        onChange={handleChange}
                                        required
                                    >
                                        <option>Selecione</option>
                                        {estados.data?.map((estado) => (
                                            <option key={estado.id} value={estado.id ?? ''}>
                                                {estado.nome}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Button
                                    block
                                    type="submit"
                                    variant="light"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <FiFilter style={{ marginRight: '5px' }} />
                                    Filtrar
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="9">
                    <Card>
                        <Card.Header>
                            <div
                                className="card-tools"
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    marginRight: '0px',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Card.Title>Relatório</Card.Title>
                                {consultants !== null && (
                                    <div className="btn-group">
                                        <BlobProvider document={
                                            <RCCAPDF
                                                entityName={entityName}
                                                organName={organName}
                                                logoSiblam={logoSiblam}
                                                logoEntity={logoEntity}
                                                consultants={consultants}
                                                logoCounty={logoCounty}
                                            />
                                        }>
                                            {({ url }) => (
                                                <a
                                                    href={url}
                                                    target="_blank"
                                                    className="btn btn-default"
                                                    rel="noopener noreferrer"
                                                >
                                                    <i className="fas fa-eye">&nbsp;</i>
                                                    Visualizar PDF
                                                </a>
                                            )}
                                        </BlobProvider>
                                    </div>
                                )}
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Table responsive bordered hover style={{ minWidth: '435px' }}>
                                <thead>
                                    <tr>
                                        <th>N°</th>
                                        <th>Nome</th>
                                        <th>Formação</th>
                                        <th>Fone</th>
                                        <th>E-mail</th>
                                        <th>Rede Social</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {consultants !== null && (consultants.length === 0 ? (
                                        <tr>
                                            <td colSpan={5}>
                                                Nenhum consultor encontrado
                                            </td>
                                        </tr>
                                    ) : (
                                        consultants.map((consultant, index) => (
                                            <tr key={`consultant-${index + 1}`}>
                                                <td>{index + 1}</td>
                                                <td>{consultant.nome}</td>
                                                <td>
                                                    {
                                                        consultant.doutorado || consultant.mestrado || consultant.especializacao
                                                        || consultant.formacao2 || consultant.formacao || 'Não Informado'
                                                    }
                                                </td>
                                                <td>{formatPhone(consultant.telefone || '')}</td>
                                                <td>{consultant.email}</td>
                                                <td>
                                                    {
                                                        consultant.linkdin || consultant.facebook || consultant.twitter
                                                        || consultant.youtube || 'Não Informado'
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Content>
    );
}
