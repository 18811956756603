import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory, useParams } from 'react-router-dom';
import { Loading } from '~/components';
import {
  Form, Alert
} from 'react-bootstrap';
import { modelo_meio_analise } from '../../../services/MeioAnalise';
import Swal from 'sweetalert2';
import schema from './schemaValidations';

const Create = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const {tipo_licenca_id} = useParams();
    const [dataError, setDataError] = useState(false);
    const [errorsValidate, setErrorsValidate] = useState(false);
    const [meiosAnalise, setMeiosAnalise] = useState([
      {
        id: 'meio_biotico',
        label: 'MEIO BIÓTICO',
        checked: false,
        submenu: [
          {
            id: 'flora',
            label: 'Flora',
            description: 'Impactos na dinâmica da comunidade, para maciços maiores que 10,0 ha. Neste caso, analisar danos aos indivíduos e à comunidade.',
            checked: false
          },
          {
            id: 'fauna',
            label: 'Fauna',
            description: 'Impactos na dinâmica da comunidade, para mais de um indivíduo. Neste caso, analisar danos aos indivíduos e à comunidade.',
            checked: false
          }
        ]
      },
      {
        id: 'meio_abiotico',
        label: 'MEIO ABIÓTICO',
        checked: false,
        submenu: [
          {
            id: 'ar',
            label: 'Ar',
            description: 'Impactos na dinâmica atmosférica.',
            checked: false
          },
          {
            id: 'agua',
            label: 'Água',
            description: 'Impactos na hidrodinâmica: alteração no fluxo e vazão.',
            checked: false
          },
          {
            id: 'paisagem',
            label: 'Paisagem',
            description: 'Dano ao patrimônio cultural, histórico, artístico, arqueológico e turístico: legalmente constituído.',
            checked: false
          },
          {
            id: 'solo_subsolo',
            label: 'Solo-subsolo',
            description: 'Impacto na dinâmica do complexo solo-subsolo: movimentação de solo, corte ou aterro.',
            checked: false
          }
        ]
      },
      {
        id: 'meio_socioeconomico',
        label: 'MEIO SÓCIOECONÔMICO',
        checked: false,
        submenu: [
          {
            id: 'nucleo_populacional',
            label: 'Núcleo populacional',
            description: 'Danos à demanda de serviços, áreas urbanas e comercialização da população local.',
            checked: false
          },
          {
            id: 'social_cultural',
            label: 'Social e cultural',
            description: 'Danos aos elementos culturais, patrimônio material e qualidade de vida da população local.',
            checked: false
          },
          {
            id: 'administrativo',
            label: 'Administrativo',
            description: 'Infringência aos aspectos administrativos e legais com relação à legislação ambiental.',
            checked: false
          }
        ]
      }
    ]);
    // useEffect(() => {
    //   const updatedErrorsValidate = { ...errorsValidate };

    //   if(meiosAnalise[0].checked || meiosAnalise[1].checked || meiosAnalise[2].checked) {
    //     updatedErrorsValidate.selectMeio = false;
    //   }
    //   if(meiosAnalise[0].checked && (!meiosAnalise[0].submenu[0].checked || !meiosAnalise[0].submenu[1].checked)) {
    //     updatedErrorsValidate.meio_abiotico = false;
    //   }
    //   if(meiosAnalise[1].checked && (!meiosAnalise[1].submenu[0].checked || !meiosAnalise[1].submenu[1].checked || !meiosAnalise[1].submenu[2].checked || !meiosAnalise[1].submenu[3].checked)) {
    //     updatedErrorsValidate.meio_biotico = false;
    //   }
    //   if(meiosAnalise[2].checked && (!meiosAnalise[2].submenu[0].checked || !meiosAnalise[2].submenu[1].checked || !meiosAnalise[2].submenu[2].checked)) {
    //     updatedErrorsValidate.meio_socioeconomico = false;
    //   }
    //   if(
    //     !updatedErrorsValidate.selectMeio
    //     || !updatedErrorsValidate.meio_abiotico
    //     || !updatedErrorsValidate.meio_biotico
    //     || !updatedErrorsValidate.meio_socioeconomico
    //     ) {
    //     setErrorsValidate(updatedErrorsValidate);
    //   }
    // }, [ meiosAnalise]);

    const handleMainCheckboxClick = (index) => {
      const updatedMeiosAnalise = [...meiosAnalise];
      // const meio = updatedMeiosAnalise[index];
      updatedMeiosAnalise[index].checked = !updatedMeiosAnalise[index].checked;

      updatedMeiosAnalise[index].submenu.forEach((submenuItem) => {
        submenuItem.checked = false;
      });

      setMeiosAnalise(updatedMeiosAnalise);
    };

    const handleCheckboxChange = (meioIndex, checkboxIndex) => {
      const updatedMeiosAnalise = [...meiosAnalise];
      updatedMeiosAnalise[meioIndex].submenu[checkboxIndex].checked = !updatedMeiosAnalise[meioIndex].submenu[checkboxIndex].checked;
      setMeiosAnalise(updatedMeiosAnalise);
    };

    const handleClose = () => {
        history.goBack();
      };

    const handleSave = async () => {

      const formData = new FormData();

      meiosAnalise.forEach((meio) => {
        if (meio.checked) {
          formData.append(`${meio.id}`, `${meio.label}`);

          meio.submenu.forEach((submenuItem) => {
            if (submenuItem.checked) {
              formData.append(`${meio.id}_${submenuItem.id}`, `${submenuItem.label}`);
              formData.append(`${meio.id}_${submenuItem.id}_description`, `${submenuItem.description}`);
            }
          });
        }
      });
      formData.append('tipo_licenca_id', tipo_licenca_id)

      const data = Object.fromEntries(formData);

      const dataValidate = {
        //meio abiotico e impactos
        meio_abiotico: meiosAnalise[0].checked,
        flora: meiosAnalise[0].submenu[0].checked,
        fauna: meiosAnalise[0].submenu[1].checked,
        //meio biotico e impactos
        meio_biotico: meiosAnalise[1].checked,
        ar: meiosAnalise[1].submenu[0].checked,
        agua: meiosAnalise[1].submenu[1].checked,
        paisagem: meiosAnalise[1].submenu[2].checked,
        solo_subsolo: meiosAnalise[1].submenu[3].checked,
        //meio socioeconomico e impactos
        meio_socioeconomico: meiosAnalise[2].checked,
        nucleo_populacional: meiosAnalise[2].submenu[0].checked,
        social_cultural:  meiosAnalise[2].submenu[1].checked,
        administrativo: meiosAnalise[2].submenu[2].checked,
      };
        try {
          if (!schema.isValidSync(dataValidate)) {
            let yup_errors = {};
            schema
              .validate(data, { abortEarly: false })
              .catch((err) => {
                for (let error of err.inner) {
                  const { path, message } = error;
                  Object.assign(yup_errors, { [path]: message });
                }
                setErrorsValidate(yup_errors);
              })
          }else{
            setLoading(true);
            const { status } = await modelo_meio_analise(tipo_licenca_id, JSON.stringify(data));
            setLoading(false);

            if (status === 200) {
              await Swal.fire('Sucesso', 'Registro salvo com sucesso!', 'success');
              history.goBack();
            }
          }
        } catch (error) {
          setDataError('Erro inesperado aconteceu, verifique seus dados.');
          if (error.response && error.response.status === 422) {
            let server_errors = [];
            server_errors = error.response.data.errors;
            for (let [value] of Object.entries(server_errors)) {
              Swal.fire('Algo deu errado!', `Esse ${value} já está sendo utlizado!`, 'error');
            }
          }
        }
        finally {
          setLoading(false);
        }
      };

    return (
        <Modal
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-lg"
        onHide={handleClose}
        show
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Opções de meios de análise e questões para APIA e ATIA.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Form.Group> */}
          {loading ? (
          <Loading />
        ) : (
          <>
            <Modal.Title id="contained-modal-title-vcenter">Selecione os meios de análise</Modal.Title>
            {meiosAnalise.map((meio, meioIndex) => (
              <div key={meioIndex}>
                <Form.Check
                  type="checkbox"
                  id={`mainCheckbox-${meioIndex}`}
                  label={meio.label}
                  name={meio.id}
                  onChange={() => handleMainCheckboxClick(meioIndex)}
                  checked={meio.checked}
                  isInvalid={Boolean(errorsValidate.selectMeio)}
                />


                {meio.checked && (
                  <div style={{ marginLeft: '20px' }}>
                  <Modal.Title id="contained-modal-title-vcenter">Habilite os impactos ambientais</Modal.Title>
                    {meio.submenu.map((submenuItem, checkboxIndex) => (
                      <Form.Check
                        key={checkboxIndex}
                        type="switch"
                        name={submenuItem.id}
                        id={`checkbox-${meioIndex}-${checkboxIndex}`}
                        label={submenuItem.label}
                        checked={submenuItem.checked}
                        onChange={() => handleCheckboxChange(meioIndex, checkboxIndex)}
                        isInvalid={
                          (Boolean(errorsValidate.meio_abiotico) && !submenuItem.checked && (submenuItem.id === 'fauna' || submenuItem.id === 'flora'))
                          || (Boolean(errorsValidate.meio_biotico) && !submenuItem.checked && (submenuItem.id === 'ar' || submenuItem.id === 'agua' || submenuItem.id === 'paisagem' || submenuItem.id === 'solo_subsolo' ))
                          || (Boolean(errorsValidate.meio_socioeconomico) && !submenuItem.checked && (submenuItem.id === 'nucleo_populacional' || submenuItem.id === 'social_cultural' || submenuItem.id === 'administrativo'))
                        }
                      />
                      )
                    )}
                  </div>
                )}
              </div>
            ))}

          </>
        )}
        {(errorsValidate.selectMeio || errorsValidate.meio_abiotico || errorsValidate.meio_biotico || errorsValidate.meio_socioeconomico) || dataError ? (
            <Alert variant="danger">
              {dataError} {Object.keys(errorsValidate).map((key) => (
                <p key={key}>{errorsValidate[key]}</p>
              ))}
            </Alert>
        ) : null}
          {/* </Form.Group> */}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-default"
            disabled={loading}
            onClick={handleClose}
          >
            <i className="fa fa-window-close" /> Cancelar
          </button>
          <button
            className="btn btn-success"
            disabled={loading}
            onClick={handleSave}
            type="button"
          >
            {' '}
            <i className="fa fa-save" /> Salvar
        </button>
        </Modal.Footer>
      </Modal >
    )
};
export default Create;
