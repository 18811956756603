import * as actions from './actionType';

export const TipoUserReducer = (state = {tipo_users: [], tipo_user: {}, menus_tipo_user: [], recursos_tipo_user: [], errors: {}}, action) => {
  switch (action.type) {
    case actions.TIPO_USERS_DATA:
      return {
        ...state,
        tipo_users: action.tipo_users.data,
        links: action.tipo_users.data.links,
        meta: action.tipo_users.data.meta,
      };
    case actions.GET_TIPO_USER:
      return {
        ...state,
        tipo_user: action.tipo_user.data.data,
        status_response: action.tipo_user.status,
      };

    case actions.GET_MENUS_TIPO_USER:
      return {
        ...state,
        menus_tipo_user: action.menus.data.data,
        status_menu_response: action.menus.status,
      };
    case actions.GET_RECURSOS_TIPO_USER:
      return {
        ...state,
        recursos_tipo_user: action.recursos.data.data,
        status_recursos_response: action.recursos.status,
      };
    case actions.TIPO_USER_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
