import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
import { Loading } from '~/components';
import { showPotencialPoluidor } from '~/store/modules/potencial_poluidor/action';

const List = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { potencial_poluidor_id } = useParams();
  const [potencialPoluidorShow, setPotencialPoluidorShow] = useState({});

  const { potencial_poluidor } = useSelector(({ PotencialPoluidorReducer }) => PotencialPoluidorReducer);

  useEffect(() => {
    dispatch(showPotencialPoluidor(potencial_poluidor_id));
  }, [dispatch, potencial_poluidor_id]);
  useEffect(() => {
    if (potencial_poluidor.id === parseInt(potencial_poluidor_id)) {
      setPotencialPoluidorShow(potencial_poluidor);
    }
  }, [potencial_poluidor, potencial_poluidor_id]);




  const handleClose = () => {
    history.push('/potencial_poluidor');
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-xl"
      onHide={handleClose}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">PotencialPoluidor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Detalhes</h2>
            <div className="card-tools">
              <Link
                className="btn btn-default"
                to={{
                  pathname: `/potencial_poluidor/${potencial_poluidor.id}/edit`,
                  state: { modal: true },
                }}
              >
                <FaEdit /> Editar
              </Link>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              {potencialPoluidorShow.id === parseInt(potencial_poluidor_id) ? (
                <table className="table table-hover table-striped">
                  <tbody>
                    <tr>
                      <th>id</th>
                      <td>{potencial_poluidor.id}</td>
                    </tr>
                    <tr>
                      <th>Nome</th>
                      <td>{potencial_poluidor.nome}</td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <Loading />
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Link className="btn btn-default" to="/potencial_poluidor">
          <i className="fa fa-window-close" /> Fechar
        </Link>
      </Modal.Footer>
    </Modal>
  );
};

export default List;
