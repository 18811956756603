import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as Analise from '~/services/Analise';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadAnalisesWorker({ params }) {
  try {
    const response = yield call(Analise.load, params);
    yield put(actions.getAnalises(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}


function* showAnaliseWorker({id, params}) {
  try {
    const response = yield call(Analise.show, id, params);
    yield put(actions.getAnalise(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updateAnaliseWorker({ analise, id}) {
  try {
    const response = yield call(Analise.update, id, analise);
    yield put(actions.getAnalise(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* finalizarAnaliseWorker({id}) {
  try {
    const response = yield call(Analise.finalizar, id);
    yield put(actions.getAnalise(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeAnaliseWorker({analise}) {
  try {
    const response = yield call(Analise.store, analise);
    yield put(actions.getAnalise(response));
    yield put(actions.getErros({save:true}));
    yield put(getErros({save:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* removerAnaliseWorker({id}) {
  try {
    yield call(Analise.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherAnalise() {
  yield takeLatest(actionType.LOAD_ANALISES, loadAnalisesWorker);
  yield takeLatest(actionType.STORE_ANALISE, storeAnaliseWorker);
  yield takeLatest(actionType.SHOW_ANALISE, showAnaliseWorker);
  yield takeLatest(actionType.UPDATE_ANALISE, updateAnaliseWorker);
  yield takeLatest(actionType.REMOVER_ANALISE, removerAnaliseWorker);
  yield takeLatest(actionType.FINALIZAR_ANALISE, finalizarAnaliseWorker);
}

export default function* saga() {
  yield all([watcherAnalise()]);
}
