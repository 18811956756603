import React from 'react';
import { Switch, Route, Link, useLocation } from 'react-router-dom';
import { Content } from '~/layouts/Dash/components';
import { useSelector } from 'react-redux';
import { FaRegEdit } from 'react-icons/fa';
import Edit from './Edit';

const Profile = () => {
  let location = useLocation();
  let modal = location.state && location.state.modal;
  const { user_auth } = useSelector(({ LoginReducer }) => LoginReducer);

  const breadcrumbs = [
    {
      label: 'Perfil',
      to: '/profile',
      active: false
    },
    {
      label: 'Perfil',
      to: '/profile',
      active: true
    }
  ];

  return (
    <>
      {modal && (
        <Switch>
          <Route children={<Edit />} exact path="/perfil/editar" />
        </Switch>
      )}
      <Content breadcrumbs={breadcrumbs} title="Perfil">
        <div className="card card-success card-outline">
          <div className="card-body box-profile">
            <div className="text-center">
              <img
                alt="User profile "
                className="profile-user-img img-fluid img-circle"
                src="https://abad.com.br/wp-content/uploads/2019/11/user-icon.svg"
              />
            </div>
            <h3 className="profile-username text-center">
              {user_auth.user ? user_auth.user.nome : null}
            </h3>

            <p className="text-muted text-center">
              {user_auth.user ? user_auth.user.cpf : null}
            </p>

            <ul className="list-group list-group-unbordered mb-3">
              <li className="list-group-item">
                <b>Nome</b>
                <span className="float-right">
                  {user_auth.user ? user_auth.user.nome : null}
                </span>
              </li>
              <li className="list-group-item">
                <b>Email</b>
                <a
                  className="float-right"
                  href="/#"
                >
                  {user_auth.user ? user_auth.user.email : null}
                </a>
              </li>
              <li className="list-group-item">
                <b>Entidade</b>
                <span className="float-right">
                  {user_auth.user.entidade ? user_auth.user.entidade.nome : null}
                </span>
              </li>
            </ul>


            <Link
              className="btn btn-primary btn-block"
              to={{
                pathname: '/perfil/editar',
                state: { modal: true }
              }}
            >
              <FaRegEdit /> Editar
            </Link>
          </div>
        </div>
      </Content>
    </>
  );
}
export default Profile;
