import React from 'react';
import { Form } from 'react-bootstrap';
// import { useSelector } from 'react-redux';

const FormCad = ({ handleSubmit, schema, documento, setDocumento, errors }) => {
  const handleChange = event => {
    const { name, value, files } = event.target;
    if(name === 'ordem' && value === ''){
      setDocumento({
        ...documento,
        [name]: files ? files[0] : undefined,
      });
    }else{
    setDocumento({
      ...documento,
      [name]: files ? files[0] : value,
    });
  };
  }
  const options = [
    {
      id:1,
      label:'Não',
      value:false
    },
    {
      id:2,
      label:'Sim',
      value:true
    }
  ]

  return (
    <Form
      id="form_documento"
      onSubmit={handleSubmit}
      schema={schema}
    >
      <div className="form-group">
        <label htmlFor="nome"> Nome </label>
        <Form.Control
          id="nome"
          isInvalid={!!errors.nome}
          name="nome"
          onChange={handleChange}
          placeholder="Nome da documento"
          type="text"
          value={documento.nome || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.nome}
        </Form.Control.Feedback>
      </div>

      <div className="form-group">
        <label htmlFor="doc_tecnica">O documento será técnico?</label>
        <Form.Control
          as="select"
          id="doc_tecnica"
          isInvalid={!!errors.doc_tecnica}
          name="doc_tecnica"
          onChange={handleChange}
          value={documento.doc_tecnica}
        >
          {
            options.map(opt => (
              <option
                key={opt.id}
                value={opt.value}
              >
                {opt.label}
              </option>
            ))
          }
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {errors.doc_tecnica}
        </Form.Control.Feedback>
      </div>

      <div className="form-group">
        <label htmlFor="obrigatorio">O documento será obrigatório?</label>
        <Form.Control
          as="select"
          id="obrigatorio"
          isInvalid={!!errors.obrigatorio}
          name="obrigatorio"
          onChange={handleChange}
          value={documento.obrigatorio}
        >
          {
            options.map(opt => (
              <option
                key={opt.id}
                value={opt.value}
              >
                {opt.label}
              </option>
            ))
          }
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {errors.obrigatorio}
        </Form.Control.Feedback>
      </div>
      <div className="form-group">
        <label htmlFor="ordem">Nº da ordem:</label>
        <Form.Control 
          id="ordem"
          isInvalid={!!errors.ordem}
          name="ordem"
          onChange={handleChange}
          placeholder="Nº da ordem do documento"
          type="number"
          value={documento.ordem || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.ordem}
        </Form.Control.Feedback>
      </div>
    </Form>
  );
};

export default FormCad;
