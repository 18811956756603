import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Switch, Route, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { Loading } from '~/components';
import { showMeioAnalise } from '~/store/modules/meio_analise/action';
import { loadTipoMeioAnalises } from '~/store/modules/tipo_meio_analise/action';
// import { loadIndicadoresAmbientais } from '~/store/modules/indicadorAmbiental/action';
// import { loadListaDocumentos } from '~/store/modules/lista_documento/action';
import { BasicContent } from '../components';
import { SubMenuList } from '../components/SubMenu';
// import CreateInd from '../../IndicadoresAmbientais/Create';
import CreateTipoMeio from '../../tipo_meio_analise/Create';
// import CreateDoc from '../../ListaDocumentos/Create';

const List = () => {
  let location = useLocation();
  let modal = location.state && location.state.modal;
  let history = useHistory();
  const dispatch = useDispatch();
  const { meio_analise_id } = useParams();
  const [meio_analiseShow, setMeio_analiseShow] = useState({});

  const { meio_analise } = useSelector(({ MeioAnaliseReducer }) => MeioAnaliseReducer);
  const { tipo_meio_analises } = useSelector(({ TipoMeioAnaliseReducer }) => TipoMeioAnaliseReducer);
  // const { lista_documentos } = useSelector(({ ListaDocumentoReducer }) => ListaDocumentoReducer);

  // console.log("List meio_analise_id : ",meio_analise_id);
  useEffect(() => {
    dispatch(showMeioAnalise(meio_analise_id));

    dispatch(loadTipoMeioAnalises({
      where: `meio_analise_id:${meio_analise_id}`
    }));
  }, [dispatch, meio_analise_id]);

  useEffect(() => {
    if (meio_analise.id === parseInt(meio_analise_id)) {
      setMeio_analiseShow(meio_analise);
    }
  }, [meio_analise, meio_analise_id]);

  const back = () => {
    history.goBack();
  }

  const breadcrumb = [
    {
      label: 'Entidade',
      to: '/entidade',
      active: false,
    },
    {
      label: 'Detalhes',
      to: `/meio_analise/${meio_analise_id}/detalhe`,
      active: true,
    }];

  return (
    <>
      {modal && (
        <Switch>
          <Route
            children={<CreateTipoMeio />}
            path={'/meio_analise/:meio_analise_id/tipo_meio_analise/novo'}
          />
        </Switch>
      )}
      <BasicContent breadcrumb={breadcrumb}>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Detalhes</h2>
            <div className="card-tools">
              <button
                className="btn btn-default"
                onClick={back}
              >
                <FaArrowAltCircleLeft /> Voltar
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              {meio_analiseShow.id === parseInt(meio_analise_id) ? (
                <table className="table table-hover table-striped">
                  <tbody>
                    <tr>
                      <th>id</th>
                      <td>{meio_analise.id}</td>
                    </tr>
                    <tr>
                      <th>Nome</th>
                      <td>{meio_analise.nome}</td>
                    </tr>
                    <tr>
                      <th>Descrição</th>
                      <td>{meio_analise.descricao}</td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <Loading />
              )}
            </div>
          </div>
        </div>
        {tipo_meio_analises.data ? (
          <>
            <SubMenuList
              action={loadTipoMeioAnalises}
              createPath={'/meio_analise/:meio_analise_id/tipo_meio_analise/novo'}
              createUrl={`/meio_analise/${meio_analise_id}/tipo_meio_analise/novo`}
              detalhe
              displayName={'meio_analise_id'}
              id={meio_analise_id}
              labelCol1={'Nome'}
              labelCol2={'Descricao'}
              path_detalhe={'/tipo_meio_analise/*/detalhe'}
              title={'Impactos ambientais'}
              valueCol1={'nome'}
              valueCol2={'descricao'}
              values={tipo_meio_analises}
            />
          </>
        ) : (
          <div className="card">
            <div className="card-header">
              <h2 className="card-title">Impactos ambientais</h2>
            </div>
            <div className="card-body">
              <Loading />
            </div>
          </div>
        )}
      </BasicContent>
    </>
  );
};

export default List;
