import { all, put, call, takeLatest } from 'redux-saga/effects';
import * as ConfigAutorizacao from '~/services/ConfigAutorizacao';
import * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadConfigAutorizacoesWorker({ params }) {
  try {
    const response = yield call(ConfigAutorizacao.load, params);
    yield put(actions.getConfigAutorizacoes(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* loadListConfigAutorizacoesWorker({ params }) {
  try {
    const response = yield call(
      ConfigAutorizacao.get_list_autorization,
      params
    );
    yield put(actions.getConfigAutorizacoes(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* showConfigAutorizacaoWorker({ id, params }) {
  try {
    const response = yield call(ConfigAutorizacao.show, id, params);
    yield put(actions.getConfigAutorizacao(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* updateConfigAutorizacaoWorker({ config_autorizacao, id }) {
  try {
    const response = yield call(
      ConfigAutorizacao.update,
      id,
      config_autorizacao
    );
    yield put(actions.getConfigAutorizacao(response));
    yield put(actions.getErros({ update: true }));
    yield put(getErros({ update: true }));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* storeConfigAutorizacaoWorker({ config_autorizacao }) {
  try {
    const response = yield call(ConfigAutorizacao.store, config_autorizacao);
    // yield put(actions.getConfigAutorizacao(response));
    yield put(actions.changeStatus(response.status));
    yield put(actions.getErros({ save: true }));
    yield put(getErros({ save: true }));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* removerConfigAutorizacaoWorker({ id }) {
  try {
    yield call(ConfigAutorizacao.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* ativarConfigAutorizacaoWorker({ id }) {
  try {
    yield call(ConfigAutorizacao.activate, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* watcherConfigAutorizacao() {
  yield takeLatest(
    actionType.LOAD_CONFIG_AUTORIZACOES,
    loadConfigAutorizacoesWorker
  );
  yield takeLatest(
    actionType.STORE_CONFIG_AUTORIZACAO,
    storeConfigAutorizacaoWorker
  );
  yield takeLatest(
    actionType.SHOW_CONFIG_AUTORIZACAO,
    showConfigAutorizacaoWorker
  );
  yield takeLatest(
    actionType.UPDATE_CONFIG_AUTORIZACAO,
    updateConfigAutorizacaoWorker
  );
  yield takeLatest(
    actionType.REMOVER_CONFIG_AUTORIZACAO,
    removerConfigAutorizacaoWorker
  );
  yield takeLatest(
    actionType.ACTIVATE_CONFIG_AUTORIZACAO,
    ativarConfigAutorizacaoWorker
  );
  yield takeLatest(
    actionType.LOAD_LIST_CONFIG_AUTORIZACOES,
    loadListConfigAutorizacoesWorker
  );
}

export default function* saga() {
  yield all([watcherConfigAutorizacao()]);
}
