import { UF_DATA, UF_ERROR } from './actionType';
const initialState = {
  uf_error: {},
  uf_data: {}
}
export const UfDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case UF_DATA:
      return {
        ...state,
        uf_data: action.data
      }
    case UF_ERROR:
      return {
        ...state,
        uf_error: action.error
      }
    default:
      return state;
  }
}
