import React from 'react';

const Impacto = (meio, data, desc) => {
  return (
    <>
      <table className="table table-bordered table-striped">
        <tbody>
          <tr>
            <th>IMPACTO NA {meio}</th>
            <th>{desc}</th>
          </tr>
        </tbody>
      </table>
      {data}
      <table className="table table-bordered table-striped">
        <tbody>
          <tr>
            <th>Impactos no(a) {meio}</th>
            <th>250</th>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Impacto;
