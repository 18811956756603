import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import { FaEdit, FaCheck, FaWindowClose } from 'react-icons/fa';
import { Modal } from 'react-bootstrap';
import { Loading } from '~/components';
import { showResponsavelTecnico } from '~/store/modules/responsavel_tecnico/action';
import formatForMask from '~/utils/formatForMask';

const List = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { responsavel_tecnico_id } = useParams();
  const [responsavel_tecnicoShow, setResponsavelTecnicoShow] = useState({});

  const { responsavel_tecnico } = useSelector(
    ({ ResponsavelTecnicoReducer }) => ResponsavelTecnicoReducer
  );

  useEffect(() => {
    dispatch(showResponsavelTecnico(responsavel_tecnico_id));
  }, [dispatch, responsavel_tecnico_id]);

  useEffect(() => {
    if (responsavel_tecnico.id === parseInt(responsavel_tecnico_id)) {
      setResponsavelTecnicoShow(responsavel_tecnico);
    }
  }, [responsavel_tecnico, responsavel_tecnico_id]);

  const handleClose = () => {
    history.push('/tecnicos/responsavel_tecnico');
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-xl"
      onHide={handleClose}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Consultor Técnico
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Detalhes</h2>
            <div className="card-tools">
              <Link
                className="btn btn-default"
                to={{
                  pathname: `/tecnicos/responsavel_tecnico/${responsavel_tecnico.id}/edit`,
                  state: { modal: true },
                }}
              >
                <FaEdit /> Editar
              </Link>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              {responsavel_tecnicoShow.id ===
              parseInt(responsavel_tecnico_id) ? (
                  <>
                    <table className="table table-hover table-striped">
                      <tbody>
                        <tr>
                          <th>id</th>
                          <td>{responsavel_tecnico?.id}</td>
                        </tr>
                        <tr>
                          <th>Nome</th>
                          <td>{responsavel_tecnico.responsavel_tecnico?.nome}</td>
                        </tr>
                        <tr>
                          <th>CNPJ ou CPF</th>
                          <td>
                            { responsavel_tecnico.responsavel_tecnico
                              ?.pessoa_tecnico_profissional.cnpj ? formatForMask( responsavel_tecnico.responsavel_tecnico
                                ?.pessoa_tecnico_profissional.cnpj, '##.###.###/####-##') : ''}
                            {responsavel_tecnico.responsavel_tecnico
                              ?.pessoa_tecnico_profissional.cpf ? formatForMask(responsavel_tecnico.responsavel_tecnico
                                ?.pessoa_tecnico_profissional.cpf, '###.###.###-##') : ''}
                          </td>
                        </tr>

                        <tr>
                          <th>Email</th>
                          <td>{responsavel_tecnico.responsavel_tecnico?.email}</td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="table table-hover table-striped">
                      <thead>
                        <tr>
                          <th
                            className="text-center"
                            colSpan="2"
                          >
                          Tipo de Anotação Técnica
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>Elaboração de Estudo e Projeto</th>
                          <td>
                            {responsavel_tecnico?.pode_elaboracao_e_estudo_projeto ? (
                              <FaCheck
                                color="#28a745"
                                title="SIM"
                              />
                            ) : (
                              <FaWindowClose
                                color="#ff0000"
                                title="Não"
                              />
                            )}
                          </td>
                        </tr>

                        <tr>
                          <th>Elaboração de Orçamento</th>
                          <td>
                            {responsavel_tecnico?.pode_elaboracao_orcamento ? (
                              <FaCheck
                                color="#28a745"
                                title="SIM"
                              />
                            ) : (
                              <FaWindowClose
                                color="#ff0000"
                                title="Não"
                              />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th>Implantação de Estudo e Projeto</th>
                          <td>
                            {responsavel_tecnico?.pode_implantacao_estudo_e_projeto ? (
                              <FaCheck
                                color="#28a745"
                                title="SIM"
                              />
                            ) : (
                              <FaWindowClose
                                color="#ff0000"
                                title="Não"
                              />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th>Execução de Estudo e Projeto</th>
                          <td>
                            {responsavel_tecnico?.pode_execucao_estudo_e_projeto ? (
                              <FaCheck
                                color="#28a745"
                                title="SIM"
                              />
                            ) : (
                              <FaWindowClose
                                color="#ff0000"
                                title="Não"
                              />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th>Fiscalização de Estudo e Projeto</th>
                          <td>
                            {responsavel_tecnico?.pode_fiscalizacao_estudo_e_projeto ? (
                              <FaCheck
                                color="#28a745"
                                title="SIM"
                              />
                            ) : (
                              <FaWindowClose
                                color="#ff0000"
                                title="Não"
                              />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th>Monitoramento de Estudo e Projeto</th>
                          <td>
                            {responsavel_tecnico?.pode_monitoramento_estudo_e_projeto ? (
                              <FaCheck
                                color="#28a745"
                                title="SIM"
                              />
                            ) : (
                              <FaWindowClose
                                color="#ff0000"
                                title="Não"
                              />
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                ) : (
                  <Loading />
                )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Link
          className="btn btn-default"
          to="/tecnicos/responsavel_tecnico"
        >
          <i className="fa fa-window-close" /> Fechar
        </Link>
      </Modal.Footer>
    </Modal>
  );
};

export default List;
