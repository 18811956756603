import { apiWithToken } from '../api';

const token = localStorage.getItem('@token');
let api = apiWithToken(token);

const resouceUrl = '/municipio';

export const load = async (estado_id, params) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let page = urlParams.has('page')
    ? { page: urlParams.get('page') }
    : {};
  let search = urlParams.has('search')
    ? { search: urlParams.get('search') }
    : {};
  return await api.get(`${resouceUrl}`, {
    params: {
      ...params,
      ...page,
      ...search,
      where: `estado_id:${estado_id}`
    }
  })
}

export const show = async (id) => {
  return await api.get(`${resouceUrl}/${id}`);
}

export const update = async (id, estado) => {
  return await api.put(`${resouceUrl}/${id}`, estado);
}


export const store = async (estado) => {
  let response = await api.post(resouceUrl , estado);
  return response;
}

export const destroy = async (id) => {
  return await api.delete(`${resouceUrl}/${id}`);
}
