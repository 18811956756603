export const initialData = {
  nome: '',
  nome_fantasia: '',
  cnpj: '',
  anexo_cnpj: '',
  certidao_simplificada_jc: 1,
  anexo_certidao_simplificada_jc: '',
  cep: '',
  logradouro: '',
  numero_endereco: '',
  anexo_endereco: '',
  bairro: '',
  cidade: '',
  uf_endereco_id: 1,
  porte_empreendimento_id: 2,
  email: '',
  site: '',
  telefone: '',
  numero_whatsapp: '',
  nome_responsavel_legal: '',
  funcao_responsavel_legal: '',
  cpf_responsavel_legal: '',
  anexo_cpf_responsavel_legal: '',
  rg_responsavel_legal: '',
  anexo_rg_responsavel_legal: '',
  endereco_responsavel_legal: '',
  anexo_endereco_responsavel_legal: '',
  telefone_responsavel_legal: '',
  email_responsavel_legal: '',
  password:'',
  confirmacao_senha: '',
  tipo_pessoa_id: 2,
  check_termos: true,
  confirmacao_email_responsavel_legal: '',
  confirmacao_email: '',
}
