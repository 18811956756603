import React, { useState } from 'react';
import { Loading } from '~/components';
import {api} from '../../services/api';
import formatForMask from '~/utils/formatForMask';
import {BoxSearch, Search, TitleSearchInput, Img, BoxContent, InfoProcess, InputSearch} from './styles';
import ImageNoProcess from '../../assets/images/sem_dados_processo.svg';

export default function ConsultarProcesso() {
  const [protocoloProcesso, setProtocoloProcesso] = useState('');
  const [processoSituacao, setProcessoSituacao] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleInput = (e) => {
    setProtocoloProcesso(e.target.value);
    setError(false);
  }

  const handleSearchProcess = async (event) => {
    event.preventDefault();
    setLoading(true);
    const response = await api.get(`/consultar_processo/${protocoloProcesso}`);
    if(Object.keys(response.data).length !== 0){
      setProcessoSituacao(response.data);
    }else{
      setError(true);
      setProcessoSituacao({});
    }
    setLoading(false);
  }

  const handleRemoveSearch = () => {
    document.querySelector('input[name="search"]').value = '';
    setProcessoSituacao({});
  }

  return (
    <>
      <BoxSearch>
        <Search>
          <TitleSearchInput>Informe aqui o protocolo do processo:</TitleSearchInput>
          <form
            className="search-form"
            onSubmit={handleSearchProcess}
          >
            <div className="input-group">
              <InputSearch
                className="form-control"
                errorColor={error ? 'red' : ''}
                name="search"
                onChange={handleInput}
                placeholder="00-0000-0000-00000000000-000"
                type="text"
              />
              <div className="input-group-append mr-1">
                <button
                  className="btn btn-success"
                  name="submit"
                  type="submit"
                >
                  <i className="fas fa-search mr-1" />
                  Buscar
                </button>
              </div>
              {Object.keys(processoSituacao).length > 0 && (
                <button
                  className="btn btn-info"
                  name="submit"
                  onClick={() => handleRemoveSearch()}
                  type="button"
                >
                  <i className="fas fa-eraser mr-1" />
                  Limpar
                </button>
              )}
            </div>
          </form>
          {error ? (
            <div
              className="alert alert-danger mt-2"
              role="alert"
            >
            Protocolo do Processo Inválido!
            </div>
          ) : ''}
        </Search>
      </BoxSearch>
      <BoxContent>
        {!Object.keys(processoSituacao).length ? <Img src={ImageNoProcess} /> : (
          <>
            {loading ? <Loading/> : (
              <InfoProcess>
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">
                  PROCESSO : <strong>{processoSituacao.protocolo}</strong>
                    </h3>
                  </div>
                  <div className="card-body table-responsive">
                    <table className="table table-hover table-striped table-bordered table-sm">
                      <tbody>
                        <tr>
                          <th>Empreendimento</th>
                          <td>
                            {' '}
                            {`${processoSituacao.pessoa?.nome} - ${
                              processoSituacao.pessoa?.cnpj
                                ? formatForMask(
                                  processoSituacao.pessoa?.cnpj,
                                  '##.###.###/####-##'
                                )
                                : ''
                            } ${
                              processoSituacao.pessoa?.cpf
                                ? formatForMask(
                                  processoSituacao.pessoa?.cpf,
                                  '###.###.###-##'
                                )
                                : ''
                            }`}
                          </td>
                        </tr>
                        <tr>
                          <th>Porte Empreendimento</th>
                          <td>
                            {processoSituacao.porte_empreendimento?.nome}
                          </td>
                        </tr>
                        <tr>
                          <th>Local</th>
                          <td>
                            {' '}
                            {processoSituacao.municipio?.nome} - {processoSituacao.estado?.uf}
                          </td>
                        </tr>
                        <tr>
                          <th>Atividade</th>
                          <td>
                            {processoSituacao.atividade ? processoSituacao.atividade?.nome : "NÃO SELECIONADO."}
                          </td>
                        </tr>
                        <tr>
                          <th>Endereço da atividade</th>
                          <td>
                            {processoSituacao.logradouro} -{' '}
                            {processoSituacao.bairro} -{' '}
                            {processoSituacao.numero_endereco ? processoSituacao.numero_endereco : 'Nº não informado'} - {' '}
                            {processoSituacao.cep} - {' '}
                            {processoSituacao.complemento ? processoSituacao.complemento : 'Sem complemento.'}
                          </td>
                        </tr>
                        <tr>
                          <th>Tipo de licença</th>
                          <td>{processoSituacao.tipo_licenca ? processoSituacao.tipo_licenca?.nome : "NÃO SELECIONADO."}</td>
                        </tr>
                        <tr>
                          <th>Técnico Consultor</th>
                          <td>
                            {processoSituacao.pessoa_responsavel_tecnico ? processoSituacao.pessoa_responsavel_tecnico?.responsavel_tecnico?.nome : "NÃO SELECIONADO."}
                          </td>
                        </tr>
                        <tr>
                          <th>Endereço da Empresa</th>
                          <td>
                            {processoSituacao.pessoa?.logradouro},
                            {processoSituacao.pessoa?.bairro} -
                            {processoSituacao.pessoa?.cep} -
                            {processoSituacao.pessoa?.cidade}
                          </td>
                        </tr>
                        <tr>
                          <th>Status</th>
                          <td>
                            <span className="badge badge-success">
                              {processoSituacao.status_processo?.nome}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </InfoProcess>
            )}
          </>
        )}
      </BoxContent>
    </>
  );
}
