import { apiWithToken } from '../api';

const resouceUrl = '/estado';
const token = localStorage.getItem('@token');
let api = apiWithToken(token);

export const load = async (params) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  // let page = (urlParams.has('page')) ? `&page=${urlParams.get('page')}`:'';
  // let search = (urlParams.has('search')) ? `&search=${urlParams.get('search')}`:'';
  let page = urlParams.has('page')
    ? { page: urlParams.get('page') }
    : {};
  let search = urlParams.has('search')
    ? { search: urlParams.get('search') }
    : {};
  return await api.get(`${resouceUrl}`,{
    params: {
      ...params,
      ...page,
      ...search
    }
  });
}

export const show = async (id) => {
  return await api.get(`${resouceUrl}/${id}`);
}

export const update = async (id, estado) => {
  return await api.put(`${resouceUrl}/${id}`, estado);
}


export const store = async (estado) => {
  let response = await api.post(resouceUrl , estado);
  return response;
}

export const destroy = async (id) => {
  return await api.delete(`${resouceUrl}/${id}`);
}
