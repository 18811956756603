export const LOAD_TIPO_LICENCAS = 'LOAD_TIPO_LICENCAS';
export const SELECT_TIPO_LICENCAS = 'SELECT_TIPO_LICENCAS';
export const TIPO_LICENCA_ERROR = 'TIPO_LICENCA_ERROR';
export const TIPO_LICENCA_GET_ERRORS = 'TIPO_LICENCA_GET_ERRORS';
export const TIPO_LICENCAS_DATA = 'TIPO_LICENCAS_DATA';
export const STORE_TIPO_LICENCA = 'STORE_TIPO_LICENCA';
export const GET_TIPO_LICENCA = 'GET_TIPO_LICENCA';
export const REMOVER_TIPO_LICENCA = 'REMOVER_TIPO_LICENCA';
export const SHOW_TIPO_LICENCA = 'SHOW_TIPO_LICENCA';
export const UPDATE_TIPO_LICENCA = 'UPDATE_TIPO_LICENCA';
export const ACTIVATE_TIPO_LICENCA = 'ACTIVATE_TIPO_LICENCA';

export const CHANGE_STATUS_CODE = 'CHANGE_STATUS_CODE';
