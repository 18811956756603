import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import styles from '../../../../../Process/components/ProjetoGeralEmpreendimento/styles';
import { Header, Footer } from '~/components/PDF';
import InfoTable from '~/components/PDF/InfoTable';
import extractTextFromHTML from '~/utils/extractTextFromHTML';

export default function RelatorioVistoriaTecnicaPDF({
    process,
    vistoria,
    images,
}) {
    return (
        <Document title="Relatório de Vistoria Técnica Ambiental">
            <Page size="A4" style={styles.page} wrap>
                <Header
                    logoEntity={process?.entidade?.logo_base64}
                    entityName={process?.entidade?.nome}
                    organName={process?.entidade?.nome_orgao_ambiental}
                    LogoCounty={process?.entidade?.logo_municipio_base64}
                    styles={styles}
                />
                <View style={{ marginTop: '-10px' }}>
                    <Text style={[styles.title, { textAlign: 'center' }]}>
                        Relatório de Vistoria Técnica Ambiental
                    </Text>
                </View>
                <View style={styles.section}>
                    <InfoTable process={process} />
                </View>
                <View style={{ marginTop: '-10px' }}>
                    <Text style={[styles.title, { marginTop: '20px' }]}>
                        1. Objetivo
                    </Text>
                    <Text style={[styles.subtitle, { marginTop: '15px' }]}>
                        {extractTextFromHTML(vistoria.objetivo)}
                    </Text>
                    <Text style={[styles.title, { marginTop: '20px' }]}>
                        2. Descrição da Atividade ou Empreendimento
                    </Text>
                    <Text style={[styles.subtitle, { marginTop: '15px' }]}>
                        {extractTextFromHTML(vistoria.descricao)}
                    </Text>
                    <Text style={[styles.title, { marginTop: '20px' }]}>
                        3. Relatório da vistoria técnica ambiental
                    </Text>
                    <Text style={[styles.subtitle, { marginTop: '15px' }]}>
                        {extractTextFromHTML(vistoria.relatorio)}
                    </Text>
                    <Text style={[styles.title, { marginTop: '20px' }]}>
                        4. Não-conformidade encontrada
                    </Text>
                    <Text style={[styles.subtitle, { marginTop: '15px' }]}>
                        {extractTextFromHTML(vistoria.nao_conformidade)}
                    </Text>
                    <Text style={[styles.title, { marginTop: '20px' }]}>
                        5. Irregularidades encontradas
                    </Text>
                    <Text style={[styles.subtitle, { marginTop: '15px' }]}>
                        {extractTextFromHTML(vistoria.irregularidade)}
                    </Text>
                    <Text style={[styles.title, { marginTop: '20px' }]}>
                        6. Base legal aplicada
                    </Text>
                    <Text style={[styles.subtitle, { marginTop: '15px' }]}>
                        {extractTextFromHTML(vistoria.base_legal)}
                    </Text>
                    <Text style={[styles.title, { marginTop: '20px' }]}>
                        7. Parecer
                    </Text>
                    <Text style={[styles.subtitle, { marginTop: '15px' }]}>
                        {extractTextFromHTML(vistoria.parecer)}
                    </Text>
                    {images.length > 0 && (
                        <View style={{ marginBottom: '20px' }}>
                            <Text
                                style={[
                                    styles.title,
                                    { marginTop: '20px', marginBottom: '20px' },
                                ]}
                            >
                                8. Seção de fotos da vistoria
                            </Text>
                            {images.map((img) => (
                                <View
                                    key={img.id}
                                    style={{ marginBottom: '20px' }}
                                >
                                    <Text
                                        style={[
                                            styles.title,
                                            {
                                                textTransform: 'uppercase',
                                                fontSize: '11px',
                                                marginTop: '10px',
                                            },
                                        ]}
                                    >
                                        {img.nome}
                                    </Text>
                                    <View
                                        style={{
                                            alignItems: 'center',
                                            marginTop: '10px',
                                        }}
                                    >
                                        <Image
                                            src={img.foto_base64}
                                            style={{
                                                width: '250px',
                                                height: '200px',
                                                maxHeight: '700px',
                                            }}
                                        />
                                    </View>
                                    <Text
                                        style={[
                                            styles.subtitle,
                                            { marginTop: '10px' },
                                        ]}
                                    >
                                        {img.descricao
                                            ? img.descricao
                                            : 'Sem descrição.'}
                                    </Text>
                                </View>
                            ))}
                        </View>
                    )}
                </View>
                <Footer
                    logoSiblam={process?.entidade?.src_siblam_logo_base64}
                    styles={styles}
                />
            </Page>
        </Document>
    );
}
