import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';

export default function LegalAspect({
  entity, errorsForm, handleChange
}) {
  return (
    <fieldset>
      <legend>ASPECTO LEGAL</legend>

      <Row>
        {/* A Secretaria de Meio Ambiente Usa Groupo de CNAE? */}
        <Col md="12">
          <div className="form-group">
            <label htmlFor="cnae_group">
              A Secretaria de Meio Ambiente Usa Grupo de Atividades?
            </label>
            <div className="form-check">
              <Form.Check
                checked={
                  `${entity.cnae_group}` ===
                  'true'
                    ? true
                    : false
                }
                isInvalid={!!errorsForm.cnae_group}
                label={'Sim'}
                name="cnae_group"
                onChange={handleChange}
                type="radio"
                value="true"
              />
            </div>
            <div className="form-check">
              <Form.Check
                checked={
                  `${entity.cnae_group}` ===
                  'false'
                    ? true
                    : false
                }
                isInvalid={!!errorsForm.cnae_group}
                label={'Não'}
                name="cnae_group"
                onChange={handleChange}
                type="radio"
                value="false"
              />
            </div>
          </div>
        </Col>
        {/* Escolha do cálculo que usará para o grupo de atividades */}
        <Col md="12">
            <div className="form-group">
                <label htmlFor="cnae_group">
                    Escolha a Fórmula do Cálculo Para o Grupo de Atividades
                </label>
                <div className="form-check">
                    <Form.Check
                        checked={
                        `${entity.formula_calc_grupo}` ===
                        'media'
                            ? true
                            : false
                        }
                        isInvalid={!!errorsForm.formula_calc_grupo}
                        label={'Média'}
                        name="formula_calc_grupo"
                        onChange={handleChange}
                        type="radio"
                        value="media"
                    />
                </div>
                <div className="form-check">
                    <Form.Check
                        checked={
                        `${entity.formula_calc_grupo}` ===
                        'highest_value'
                            ? true
                            : false
                        }
                        isInvalid={!!errorsForm.formula_calc_grupo}
                        label={'Maior Valor'}
                        name="formula_calc_grupo"
                        onChange={handleChange}
                        type="radio"
                        value="highest_value"
                    />
                </div>
            </div>
        </Col>
        {/* A Secretaria de Meio Ambiente faz licenciamento ambiental? */}
        <Col md="12">
          <div className="form-group">
            <label htmlFor="secretaria_faz_licenciamento">
              A Secretaria de Meio Ambiente faz licenciamento
              ambiental?
            </label>
            <div className="form-check">
              <Form.Check
                checked={
                  `${entity.secretaria_faz_licenciamento}` ===
                  'true'
                    ? true
                    : false
                }
                isInvalid={!!errorsForm.secretaria_faz_licenciamento}
                label={'Sim'}
                name="secretaria_faz_licenciamento"
                onChange={handleChange}
                type="radio"
                value="true"
              />
            </div>
            <div className="form-check">
              <Form.Check
                checked={
                  `${entity.secretaria_faz_licenciamento}` ===
                  'false'
                    ? true
                    : false
                }
                isInvalid={!!errorsForm.secretaria_faz_licenciamento}
                label={'Não'}
                name="secretaria_faz_licenciamento"
                onChange={handleChange}
                type="radio"
                value="false"
              />
            </div>
          </div>
        </Col>

        <Col md="12">
          {/* A Secretaria de Meio Ambiente tem Conselho de Meio Ambiente criado? */}
          <div className="form-group">
            <label>
              A Secretaria de Meio Ambiente tem Conselho de Meio
              Ambiente criado?
            </label>
            <div className="form-check">
              <Form.Check
                checked={
                  `${entity.secretaria_tem_conselho_meio_ambiente}` ===
                  'true'
                    ? true
                    : false
                }
                isInvalid={
                  !!errorsForm.secretaria_tem_conselho_meio_ambiente
                }
                label={'Sim'}
                name="secretaria_tem_conselho_meio_ambiente"
                onChange={handleChange}
                type="radio"
                value="true"
              />
            </div>
            <div className="form-check">
              <Form.Check
                checked={
                  `${entity.secretaria_tem_conselho_meio_ambiente}` ===
                  'false'
                    ? true
                    : false
                }
                isInvalid={
                  !!errorsForm.secretaria_tem_conselho_meio_ambiente
                }
                label={'Não'}
                name="secretaria_tem_conselho_meio_ambiente"
                onChange={handleChange}
                type="radio"
                value="false"
              />
            </div>
          </div>
        </Col>

        {/* Qual a legislação que institui o Conselho de Meio Ambiente? */}
        <Col md="12">
          <div className="form-group">
            <label htmlFor="legislacao_conselho">
              Qual a legislação que institui o Conselho de Meio
              Ambiente?
            </label>
            <Form.Control
              id="legislacao_conselho"
              isInvalid={!!errorsForm.legislacao_conselho}
              name="legislacao_conselho"
              onChange={handleChange}
              placeholder="Qual a legislação que institui o Conselho de Meio Ambiente?"
              type="text"
              value={entity.legislacao_conselho ?? ''}
            />
            <Form.Control.Feedback type="invalid">
              {errorsForm.legislacao_conselho}
            </Form.Control.Feedback>
          </div>
        </Col>

        {/* O Conselho de Meio Ambiente tem alguma periodicidade de reuniões? */}
        <Col md="12">
          <div className="form-group">
            <label>
              O Conselho de Meio Ambiente tem alguma periodicidade de
              reuniões?
            </label>
            <div className="form-check">
              <Form.Check
                checked={
                  `${entity.conselho_tem_preiodicidade_de_reunioes}` ===
                  'true'
                    ? true
                    : false
                }
                isInvalid={
                  !!errorsForm.conselho_tem_preiodicidade_de_reunioes
                }
                label={'Sim'}
                name="conselho_tem_preiodicidade_de_reunioes"
                onChange={handleChange}
                type="radio"
                value="true"
              />
            </div>
            <div className="form-check">
              <Form.Check
                checked={
                  `${entity.conselho_tem_preiodicidade_de_reunioes}` ===
                  'false'
                    ? true
                    : false
                }
                isInvalid={
                  !!errorsForm.conselho_tem_preiodicidade_de_reunioes
                }
                label={'Não'}
                name="conselho_tem_preiodicidade_de_reunioes"
                onChange={handleChange}
                type="radio"
                value="false"
              />
            </div>
          </div>
        </Col>

        {/* A Secretaria de Meio Ambiente possui Fundo de Ambiente criado? */}
        <Col md="12">
          <div className="form-group">
            <label>
              A Secretaria de Meio Ambiente possui Fundo de Ambiente
              criado?
            </label>
            <div className="form-check">
              <Form.Check
                checked={
                  `${entity.secretaria_possui_fundo_de_ambiente}` ===
                  'true'
                    ? true
                    : false
                }
                isInvalid={
                  !!errorsForm.secretaria_possui_fundo_de_ambiente
                }
                label={'Sim'}
                name="secretaria_possui_fundo_de_ambiente"
                onChange={handleChange}
                type="radio"
                value="true"
              />
            </div>
            <div className="form-check">
              <Form.Check
                checked={
                  `${entity.secretaria_possui_fundo_de_ambiente}` ===
                  'false'
                    ? true
                    : false
                }
                isInvalid={
                  !!errorsForm.secretaria_possui_fundo_de_ambiente
                }
                label={'Não'}
                name="secretaria_possui_fundo_de_ambiente"
                onChange={handleChange}
                type="radio"
                value="false"
              />
            </div>
          </div>
        </Col>

        {/* A Secretaria possui PF ou PJ realizando serviços ambientais? */}
        <Col md="12">
          <div className="form-group">
            <label>
              A Secretaria possui PF ou PJ realizando serviços
              ambientais?
            </label>
            <div className="form-check">
              <Form.Check
                checked={
                  `${entity.tem_pf_ou_pj_realizando_servicos_ambientais}` ===
                  'true'
                    ? true
                    : false
                }
                isInvalid={
                  !!errorsForm.tem_pf_ou_pj_realizando_servicos_ambientais
                }
                label={'Sim'}
                name="tem_pf_ou_pj_realizando_servicos_ambientais"
                onChange={handleChange}
                type="radio"
                value="true"
              />
            </div>
            <div className="form-check">
              <Form.Check
                checked={
                  `${entity.tem_pf_ou_pj_realizando_servicos_ambientais}` ===
                  'false'
                    ? true
                    : false
                }
                isInvalid={
                  !!errorsForm.tem_pf_ou_pj_realizando_servicos_ambientais
                }
                label={'Não'}
                name="tem_pf_ou_pj_realizando_servicos_ambientais"
                onChange={handleChange}
                type="radio"
                value="false"
              />
            </div>
          </div>
        </Col>

        {/* Qual a legislação que institui o Fundo de Meio Ambiente? */}
        {/* <Col md="12">
          <div className="form-group">
            <label htmlFor="legislacao_fundo_meio_ambiente">
              Qual a legislação que institui o Fundo de Meio Ambiente?
            </label>
            <Form.Control
              isInvalid={!!errorsForm.legislacao_fundo_meio_ambiente}
              onChange={handleChange}
              value={entity.legislacao_fundo_meio_ambiente ?? ''}
              name="legislacao_fundo_meio_ambiente"
              placeholder="Qual a legislação que institui o Fundo de Meio Ambiente?"
              type="text"
            />
            <Form.Control.Feedback type="invalid">
              {errorsForm.legislacao_fundo_meio_ambiente}
            </Form.Control.Feedback>
          </div>
        </Col> */}
      </Row>
    </fieldset>
  );
}
