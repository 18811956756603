export default (barcode = '') => {
  const codes = [];

  for (let offset = 0; offset <= barcode.length - 12; offset += 12) {
    const part = barcode.slice(offset, offset + 12);

    codes.push(part.slice(0, 11) + ' ' + part.charAt(11));
  }

  return codes.join(' ');
}
