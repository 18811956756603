import * as Yup from 'yup';

const schema = Yup.object().shape({
  modelo: Yup.string().required('Valor requerido'),
  placa: Yup.string().required('Valor requerido'),
  ano_fabricacao: Yup.number().required('Valor requerido'),
  ano_modelo: Yup.number().required('Valor requerido'),
});

export default schema;
