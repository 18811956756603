import React from 'react';
import { Content } from '~/layouts/Dash/components';

const BasicContent = ({ breadcrumb, children }) => {
  const breadcrumbs = [
    {
      label: 'Gerentes',
      to: '/gerentes',
      active: false,
    },
    breadcrumb,
  ];

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Gerentes"
    >
      {children}
    </Content>
  );
}

export default BasicContent;
