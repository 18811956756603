import React from 'react';
import { Row, Col, Tabs, Tab, Form } from 'react-bootstrap';
import FileInput from '~/components/FileInput';
import styles from './styles';
import maskCEP from '~/utils/formatCep'
import formatPhone from '~/utils/formatPhone'
import formatCpfCnpj from '~/utils/formatCpfCnpj'

export default function FieldsPJ({
  fields, setFields, ufData,
  handleBuscaCep, handleChange
}) {
  return (
    <Tabs defaultActiveKey="dados_empresa">
      <Tab title="Dados da Empresa" eventKey="dados_empresa" style={styles.tab}>
        <Row>
          <Form.Group as={Col} md="6">
            <Form.Label>Razão Social</Form.Label>
            <Form.Control
              // isInvalid={!!errors.nome}
              name="nome"
              // onChange={handleChange}
              placeholder="Razão Social"
              type="text"
              value={fields.nome || ''}
              readOnly
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.nome}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Nome Fantasia</Form.Label>
            <Form.Control
              // isInvalid={!!errors.nome_fantasia}
              name="nome_fantasia"
              // onChange={handleChange}
              placeholder="Nome Fantasia"
              type="text"
              value={fields.nome_fantasia || ''}
              readOnly
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.nome_fantasia}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>CNPJ (Somente números)</Form.Label>
            <Form.Control
              // isInvalid={!!errors.cnpj}
              maxLength="18"
              name="cnpj"
              // onChange={handleChange}
              placeholder="CNPJ"
              type="text"
              value={formatCpfCnpj(fields.cnpj ?? '') || ''}
              readOnly
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.cnpj}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Anexo</Form.Label>
            <FileInput
              data={fields}
              // dataError={errors.anexo_cnpj}
              id="anexo_cnpj"
              name="anexo_cnpj"
              // setData={setFields}
              disabled
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.anexo_cnpj}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>Porte Empreendimento</Form.Label>
            <Form.Control
              as="select"
              // isInvalid={!!errors.porte_empreendimento_id}
              name="porte_empreendimento_id"
              // onChange={handleChange}
              value={fields.porte_empreendimento_id || ''}
              disabled
            >
              <option value="2">MEI</option>
              <option value="3">MICRO EMPRESA</option>
              <option value="4">EMPRESA PEQUENO PORTE</option>
              <option value="5">EMPRESA MÉDIO PORTE</option>
              <option value="6">EMPRESA GRANDE PORTE</option>
            </Form.Control>
            {/* <Form.Control.Feedback type="invalid">
              {errors.porte_empreendimento_id}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>Certidão Simplificada da Junta Comercial</Form.Label>
            <Form.Control
              as="select"
              // isInvalid={!!errors.certidao_simplificada_jc}
              name="certidao_simplificada_jc"
              // onChange={handleChange}
              placeholder="Certidão Simplificada da Junta Comercial"
              value={fields.certidao_simplificada_jc || ''}
              disabled
            >
              <option value={1}> SIM </option>
              <option value={0}> NÃO </option>
            </Form.Control>
            {/* <Form.Control.Feedback type="invalid">
              {errors.certidao_simplificada_jc}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>Anexo</Form.Label>
            <FileInput
              data={fields}
              // dataError={errors.anexo_certidao_simplificada_jc}
              id="anexo_certidao_simplificada_jc"
              name="anexo_certidao_simplificada_jc"
              // setData={setFields}
              disabled
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.anexo_certidao_simplificada_jc}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Fone de contato</Form.Label>
            <Form.Control
              // isInvalid={!!errors.telefone}
              name="telefone"
              onChange={handleChange}
              placeholder="Fone de contato"
              type="text"
              maxLength="15"
              value={formatPhone(fields.telefone ?? '') || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.telefone}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Número de WhatsApp</Form.Label>
            <Form.Control
              // isInvalid={!!errors.numero_whatsapp}
              name="numero_whatsapp"
              onChange={handleChange}
              placeholder="Número de WhatsAp"
              type="text"
              maxLength="15"
              value={formatPhone(fields.numero_whatsapp ?? '') || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.numero_whatsapp}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              // isInvalid={!!errors.email}
              name="email"
              onChange={handleChange}
              placeholder="E-mail"
              type="email"
              value={fields.email || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Site da empresa</Form.Label>
            <Form.Control
              // isInvalid={!!errors.site}
              name="site"
              onChange={handleChange}
              placeholder="Site da empresa"
              type="text"
              value={fields.site || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.site}
            </Form.Control.Feedback> */}
          </Form.Group>
        </Row>
      </Tab>
      <Tab title="Responsável Legal" eventKey="responsavel_legal" style={styles.tab}>
        <Row>
          <Form.Group as={Col} md="6">
            <Form.Label>Nome do Responsável Legal</Form.Label>
            <Form.Control
              // isInvalid={!!errors.nome_responsavel_legal}
              name="nome_responsavel_legal"
              // onChange={handleChange}
              placeholder="Nome do Responsável Legal"
              type="text"
              value={fields.nome_responsavel_legal || ''}
              readOnly
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.nome_responsavel_legal}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Função do Responsável Legal</Form.Label>
            <Form.Control
              // isInvalid={!!errors.funcao_responsavel_legal}
              name="funcao_responsavel_legal"
              // onChange={handleChange}
              placeholder="Função"
              type="text"
              value={fields.funcao_responsavel_legal || ''}
              readOnly
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.funcao_responsavel_legal}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>CPF Responsável Legal</Form.Label>
            <Form.Control
              // isInvalid={!!errors.cpf_responsavel_legal}
              name="cpf_responsavel_legal"
              // onChange={handleChange}
              placeholder="CPF Responsável Legal"
              type="text"
              value={formatCpfCnpj(fields.cpf_responsavel_legal ?? '') || ''}
              readOnly
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.cpf_responsavel_legal}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Anexo</Form.Label>
            <FileInput
              data={fields}
              // dataError={errors.anexo_cpf_responsavel_legal}
              id="anexo_cpf_responsavel_legal"
              name="anexo_cpf_responsavel_legal"
              // setData={setFields}
              disabled
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.anexo_cpf_responsavel_legal}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>RG Responsável Legal</Form.Label>
            <Form.Control
              // isInvalid={!!errors.rg_responsavel_legal}
              name="rg_responsavel_legal"
              // onChange={handleChange}
              placeholder="RG Responsável Legal"
              type="text"
              value={fields.rg_responsavel_legal || ''}
              readOnly
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.rg_responsavel_legal}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Anexo</Form.Label>
            <FileInput
              data={fields}
              // dataError={errors.anexo_rg_responsavel_legal}
              id="teste2"
              name="anexo_rg_responsavel_legal"
              // setData={setFields}
              disabled
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.anexo_rg_responsavel_legal}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Endereço Responsável Legal</Form.Label>
            <Form.Control
              // isInvalid={!!errors.endereco_responsavel_legal}
              name="endereco_responsavel_legal"
              onChange={handleChange}
              placeholder="Endereço Responsável Legal"
              type="text"
              value={fields.endereco_responsavel_legal || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.endereco_responsavel_legal}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Anexo</Form.Label>
            <FileInput
              data={fields}
              // dataError={errors.anexo_endereco_responsavel_legal}
              name="anexo_endereco_responsavel_legal"
              setData={setFields}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.anexo_endereco_responsavel_legal}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Fone de Contato do Responsável Legal</Form.Label>
            <Form.Control
              // isInvalid={!!errors.telefone_responsavel_legal}
              name="telefone_responsavel_legal"
              onChange={handleChange}
              placeholder="Fone de Contato do Responsável Legal"
              type="text"
              maxLength="15"
              value={formatPhone(fields.telefone_responsavel_legal ?? '') || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.telefone_responsavel_legal}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>E-mail do Responsável Legal</Form.Label>
            <Form.Control
              // isInvalid={!!errors.telefone_responsavel_legal}
              name="email_responsavel_legal"
              onChange={handleChange}
              placeholder="E-mail do Responsável Legal"
              type="text"
              value={fields.email_responsavel_legal || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.email_responsavel_legal}
            </Form.Control.Feedback> */}
          </Form.Group>
        </Row>
      </Tab>
      <Tab title="Endereço" eventKey="endereco" style={styles.tab}>
        <Row>
          <Form.Group as={Col} md="2">
            <Form.Label>CEP</Form.Label>
            <Form.Control
              // isInvalid={!!errors.cep}
              name="cep"
              onBlur={handleBuscaCep}
              onChange={handleChange}
              placeholder="CEP"
              type="text"
              maxLength="9"
              value={maskCEP(fields.cep ?? '') || ''} 
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.cep}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>Logradouro</Form.Label>
            <Form.Control
              // isInvalid={!!errors.logradouro}
              name="logradouro"
              onChange={handleChange}
              placeholder="Logradouro"
              type="text"
              value={fields.logradouro || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.logradouro}
            </Form.Control.Feedback> */}
          </Form.Group>

          <Form.Group as={Col} md="2">
            <Form.Label>Número</Form.Label>
            <Form.Control
              // isInvalid={!!errors.numero_endereco}
              name="numero_endereco"
              onChange={handleChange}
              placeholder="Número"
              type="text"
              value={fields.numero_endereco || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.numero_endereco}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>Bairro</Form.Label>
            <Form.Control
              // isInvalid={!!errors.bairro}
              name="bairro"
              onChange={handleChange}
              placeholder="Bairro"
              type="text"
              value={fields.bairro || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.bairro}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>Cidade</Form.Label>
            <Form.Control
              // isInvalid={!!errors.cidade}
              name="cidade"
              onChange={handleChange}
              placeholder="Cidade"
              type="text"
              value={fields.cidade || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.cidade}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>Estado</Form.Label>
            <Form.Control
              as="select"
              // isInvalid={!!errors.uf_endereco_id}
              name="uf_endereco_id"
              onChange={handleChange}
              value={fields.uf_endereco_id || 1}
            >
              {ufData[0] && ufData.map(
                uf => (
                  <option key={uf.id} value={uf.id}>
                    {uf.uf}
                  </option>
                )
              )}
            </Form.Control>
            {/* <Form.Control.Feedback type="invalid">
              {errors.uf_endereco_id}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>Anexo</Form.Label>
            <FileInput
              data={fields}
              // dataError={errors.anexo_endereco}
              id="anexo_endereco"
              name="anexo_endereco"
              setData={setFields}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.anexo_endereco}
            </Form.Control.Feedback> */}
          </Form.Group>
        </Row>
      </Tab>
    </Tabs>
  );
}
