import React from 'react';
import { View, Text, Image } from '@react-pdf/renderer';
import customStyle from './styles';

export default function Header({
  logoEntity, entityName,
  organName, styles,
  LogoCounty
}) {
  return (
    <View style={customStyle.header} fixed>
      {!!logoEntity && (
        <Image src={logoEntity} style={{ width: '70px' }}/>
      )}
      <View style={{ alignItems: 'center',  flex: 1, textAlign: 'center'}}>
        <Text style={styles.title}>
          {entityName}
        </Text>
        <Text style={styles.subtitle}>
          {organName}
        </Text>
      </View>
      {!!LogoCounty && (
        <Image src={LogoCounty} style={{ width: '70px' }}/>
      )}
    </View>
  );
}
