import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { FaPrint } from 'react-icons/fa';
import { Loading } from '~/components';
import { useDispatch, useSelector } from 'react-redux';

import formatForMask from '~/utils/formatForMask';
import { getBoleto } from '~/store/modules/processo/action';

const TaxaAmbiental = ({ processo }) => {
  let [show, setShow] = useState(false);

  const dispatch = useDispatch();
  const { url_boleto } = useSelector(({ ProcessoReducer }) => ProcessoReducer);
  useEffect(() => {
    dispatch(
      getBoleto(processo)
    );
  }, [processo, dispatch]);

//   let valorX = parseFloat(processo.valor_porte) + parseFloat(processo.valor_potencial_poluidor);
//   if(processo.tipo_licenca.valor_referencias?.length > 0){
//     processo.tipo_licenca.valor_referencias.forEach(function(valor_referencia, index){
//       if(valor_referencia.potencial_poluidor_id === processo.atividade.potencial_poluidor_id){
//         if(valor_referencia.porte_empreendimento_id === processo.porte_empreendimento_id){
//           valorX = valor_referencia.valor
//         }
//       }
//     });
//   }

  return (
    <>
      {processo.pessoa ? (
        <>
          <div className="col-md-4">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">TAXA AMBIENTAL</h3>
              </div>
              <div className="card-body">
                <button
                  className="btn btn-success btn-block"
                  onClick={() => setShow(true)}
                >
                  <i
                    aria-hidden="true"
                    className="fa fa-barcode"
                  /> TAXA
                  AMBIENTAL
                </button>
              </div>
            </div>
          </div>

          <Modal
            animation
            aria-labelledby="example-custom-modal-styling-title"
            dialogClassName="modal-xl"
            onHide={() => setShow(false)}
            show={show}
          >
            <Modal.Header closeButton>
              <Modal.Title>TAXA AMBIENTAL</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="card">
                <div className="card-header">
                  Dados da tipologia do empreendimento / atividade
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover table-striped table-bordered table-sm">
                      <tbody>
                        <tr>
                          <th>Processo</th>
                          <td>{processo.protocolo}</td>
                        </tr>
                        <tr>
                          <th>Empreendimento</th>
                          <td>
                            {' '}
                            {`${processo.pessoa.nome} - ${
                              processo.pessoa.cnpj
                                ? formatForMask(
                                  processo.pessoa.cnpj,
                                  '##.###.###/####-##'
                                )
                                : ''
                            } ${
                              processo.pessoa.cpf
                                ? formatForMask(
                                  processo.pessoa.cpf,
                                  '###.###.###-##'
                                )
                                : ''
                            }`}
                          </td>
                        </tr>
                        <tr>
                          <th>Porte Empreendimento</th>
                          <td>
                            {processo.porte_empreendimento?.nome}
                          </td>
                        </tr>
                        <tr>
                          <th>Local</th>
                          <td>
                            {' '}
                            {processo.municipio?.nome} - {processo.estado?.uf}
                          </td>
                        </tr>

                        {processo.entidade?.cnae_group && processo.grupo_atividades ? (
                            <>
                                <tr>
                                    <th>Atividade Principal Licenciada</th>
                                    <td>
                                        {' '}
                                        {processo.atividade?.nome}
                                    </td>
                                </tr>
                                {processo.grupo_atividades.map((grupo, index) => (
                                    <tr key={index}>
                                        <th>Atividade(s) Secundária(s) Licenciada(s)</th>
                                        <td>{grupo.nome}</td>
                                    </tr>
                                ))}
                            </>
                        ) : (
                            <tr>
                                <th>Atividade</th>
                                <td>
                                    {' '}
                                    {processo.atividade?.nome}
                                </td>
                            </tr>
                        )}
                        <tr>
                            <th>Tipo de licença</th>
                            <td> {processo.tipo_licenca?.nome}</td>
                        </tr>
                        <tr>
                          <th>Técnico Consultor</th>
                          <td>
                            {processo.pessoa_responsavel_tecnico?.responsavel_tecnico?.pessoa_tecnico_profissional?.nome}
                            {processo.pessoa_responsavel_tecnico?.responsavel_tecnico?.pessoa_tecnico_profissional?.cnpj ?
                              formatForMask(
                                processo.pessoa_responsavel_tecnico
                                .responsavel_tecnico.pessoa_tecnico_profissional
                                .cnpj, '##.###.###/####-##'
                              ) : ''
                            }

                            {processo.pessoa_responsavel_tecnico?.responsavel_tecnico?.pessoa_tecnico_profissional?.cpf ?
                              formatForMask(
                                processo.pessoa_responsavel_tecnico
                                .responsavel_tecnico.pessoa_tecnico_profissional
                                .cpf, '###.###.###-##'
                              ) : ''
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped table-hover">
                      <thead>
                        <tr>
                          <th
                            className="text-center"
                            colSpan="12"
                          >
                            CÁLCULO DA TAXA DE LICENCIAMENTO
                          </th>
                        </tr>
                        <tr>
                          <th>X</th>
                          <th>A</th>
                          <th>B</th>
                          <th>C</th>
                          <th>D</th>
                          <th>D1</th>
                          <th>D2</th>
                          <th>D3</th>
                          <th>E</th>
                          <th>F</th>
                          <th>G</th>
                          <th>P</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {
                            processo.valor_x
                              // processo.tipo_licenca.valor_referencia ? processo.tipo_licenca.valor_referencia.valor : parseFloat(processo.valor_porte) + parseFloat(processo.valor_potencial_poluidor)
                              // !!processo.valor_porte &&
                              // !!processo.valor_potencial_poluidor &&
                              // (
                              //   parseFloat(processo.valor_porte) +
                              //   parseFloat(processo.valor_potencial_poluidor)
                              // ).toFixed(2)
                            }
                          </td>
                          <td>{processo.qtd_tec_para_analise}</td>
                          <td>{processo.qtd_hora_tec_necessaria}</td>
                          <td>{!!processo.valor_hora_tec && parseFloat(processo.valor_hora_tec).toFixed(2)}</td>
                          <td>{!!processo.valor_diaria && parseFloat(processo.valor_diaria).toFixed(2)}</td>
                          <td>{!!processo.qtd_diarias && parseFloat(processo.qtd_diarias).toFixed(2)}</td>
                          <td>{!!processo.valor_combustivel_vistoria && parseFloat(processo.valor_combustivel_vistoria).toFixed(2)}</td>
                          <td>{!!processo.valor_combustivel_vistoria && parseFloat(parseFloat(processo.valor_combustivel_vistoria)/2).toFixed(2)}</td>
                          <td>{processo.qtd_viagem_vistoria}</td>
                          <td>{!!processo.taxa_requerimento && parseFloat(processo.taxa_requerimento).toFixed(2)}</td>
                          <td>{!!processo.taxa_recebimento && parseFloat(processo.taxa_recebimento).toFixed(2)}</td>
                          <td>{!!processo.valor_total && parseFloat(processo.valor_total).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>X</td>
                          <td>
                            {
                            //   valorX
                            processo.valor_x
                              // processo.tipo_licenca.valor_referencia ? processo.tipo_licenca.valor_referencia.valor : parseFloat(processo.valor_porte) + parseFloat(processo.valor_potencial_poluidor)
                              // !!processo.valor_porte &&
                              // !!processo.valor_potencial_poluidor &&
                              // (
                              //   parseFloat(processo.valor_porte) +
                              //   parseFloat(processo.valor_potencial_poluidor)
                              // ).toFixed(2)
                            }
                          </td>
                          <td colSpan="2">Potencial Poluidor =</td>
                          <td>
                            <strong>
                              {processo.atividade?.potencial_poluidor?.nome}
                            </strong>
                          </td>
                          <td>Porte = </td>
                          <td colSpan="6">
                            <strong>
                              {processo.porte_empreendimento?.nome}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td>A</td>
                          <td>{processo.qtd_tec_para_analise}</td>
                          <td colSpan="12">
                            Quantidade de Técnicos envolvidos na análise
                          </td>
                        </tr>
                        <tr>
                          <td>B</td>
                          <td>{processo.qtd_hora_tec_necessaria}</td>
                          <td colSpan="12">
                            Quantidade de Horas/Técnica necessárias para análise
                          </td>
                        </tr>
                        <tr>
                          <td>C</td>
                          <td>{!!processo.valor_hora_tec && parseFloat(processo.valor_hora_tec).toFixed(2)}</td>
                          <td colSpan="12">
                            Valor em UFIR da Hora/Técnico para análise fixado em
                            50 UFIR
                          </td>
                        </tr>
                        <tr>
                          <td>D</td>
                          <td>{!!processo.valor_diaria && parseFloat(processo.valor_diaria).toFixed(2)}</td>
                          <td colSpan="12">Valor da diária</td>
                        </tr>
                        <tr>
                          <td>D1</td>
                          <td>{!!processo.qtd_diarias && parseFloat(processo.qtd_diarias).toFixed(2)}</td>
                          <td colSpan="12">Total de Diárias</td>
                        </tr>
                        <tr>
                          <td>D2</td>
                          <td>{!!processo.valor_combustivel_vistoria && parseFloat(processo.valor_combustivel_vistoria).toFixed(2)}</td>
                          <td colSpan="12">
                            Valor do combustível usado na vistoria
                          </td>
                        </tr>
                        <tr>
                          <td>D3</td>
                          <td>{!!processo.valor_combustivel_vistoria && parseFloat(parseFloat(processo.valor_combustivel_vistoria)/2).toFixed(2)}</td>
                          <td colSpan="12">Manutenção do Veículo (50% de D2)</td>
                        </tr>
                        <tr>
                          <td>E</td>
                          <td>{processo.qtd_viagem_vistoria}</td>
                          <td colSpan="12">
                            Quantidade de Viagens necessárias para a vistoria
                          </td>
                        </tr>
                        <tr>
                          <td>F</td>
                          <td>{!!processo.taxa_requerimento && parseFloat(processo.taxa_requerimento).toFixed(2)}</td>
                          <td colSpan="12">Taxa de Requerimento de Publicação</td>
                        </tr>
                        <tr>
                          <td>G</td>
                          <td>{!!processo.taxa_recebimento && parseFloat(processo.taxa_recebimento).toFixed(2)}</td>
                          <td colSpan="12">Taxa de Recebimento de Publicação</td>
                        </tr>
                        <tr>
                          <td>P</td>
                          <td>{!!processo.valor_total && parseFloat(processo.valor_total).toFixed(2)}</td>
                          <td colSpan="12">Resultado do cálculo</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>Valor da Taxa</th>
                          <td>R$ </td>
                          <td colSpan="12">{!!processo.valor_total && parseFloat(processo.valor_total).toFixed(2)}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                {
                  url_boleto != null ? (
                    url_boleto.includes('http') ? (
                      <div className="card-footer text-center">
                        <a
                          className="btn btn-lg btn-info"
                          href={url_boleto}
                          target="new"
                        >
                          <FaPrint /> VISUALIZAR BOLETO
                        </a>
                        <hr />
                        {/*<Link*/}
                        {/*  className="btn  btn-primary"*/}
                        {/*  to="/processos/certidao-processo"*/}
                        {/*>*/}
                        {/*  Ver Certidão*/}
                        {/*</Link>*/}
                      </div>
                    ) : (
                      <div className='card-footer text-center'>
                        <a
                          className="btn btn-lg btn-info text-white"
                          rel="noopener noreferrer"
                          href={`/boleto/guia-arrecadacao/${processo.id}`}
                          target="_blank"
                        >
                          <FaPrint /> VISUALIZAR BOLETO
                        </a>
                        <hr />
                        {/* <h5 className="text-center"> */}
                          {/* Linha Digitável */}
                        {/* </h5> */}
                        {/* <h4 className="text-center"> */}
                          {/* {formatBarcode(url_boleto)} */}
                        {/* </h4> */}
                      </div>
                    )
                  ) : (
                    <div className="card-footer text-center">
                      <FaPrint /> CARREGANDO BOLETO...
                      <hr />
                    </div>
                  )
                }
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-default"
                onClick={() => setShow(false)}
                type="button"
              >
                <i className="fa fa-window-close" /> Fechar
              </button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};
export default TaxaAmbiental;
