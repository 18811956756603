import styled from 'styled-components';

export const TableCustom = styled.table`
  border: 1px solid #ddd;
`;

export const TdCustom = styled.td`
  border: 1px solid #ddd;
  font-size: 22px;
`;

export const ThCustom = styled.th`
  border: 1px solid #ddd;
  font-size: 22px;
`;
