import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import {
  removerIndicadorProcesso,
  storeIndicadorProcesso
} from '~/store/modules/indicadorProcesso/action';

const FormResposta = ({ indicador_id, indicador_processo_id, processo_id, valor }) => {
  const dispatch = useDispatch();

  const [dataForm, setDataForm] = useState({
    valor: undefined,
    indicador_id,
    processo_id
  });
  const [errorsValidate, setErrorsValidate] = useState([]);

  const schema = Yup.object().shape({
    valor: Yup.number().min(0).required('Valor requerido'),
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!schema.isValidSync(dataForm)) {
      let yup_errors = {};
      schema.validate(dataForm, { abortEarly: false }).catch((err) => {
        for (let error of err.inner) {
          const { path, message } = error;
          Object.assign(yup_errors, { [path]: message });
        }
        setErrorsValidate(yup_errors);
      });
    } else {
      await dispatch(storeIndicadorProcesso(dataForm));
      // console.log('valores', dataForm);
    }
  };

  const handleIndicadorProcesso = (id) => {
    Swal.fire({
      title: 'Atenção',
      text: 'Deseja remover esse item?',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then(async result => {
      if (result.value) {
        try{
          await dispatch(removerIndicadorProcesso(id, processo_id));
          await Swal.fire('Sucesso',
            'Removido com sucesso!',
            'success');
        } catch(error) {
          console.log(error);
        }
      }
    });
  }

  return (
    <>
      {!valor ? (
        <Form onSubmit={handleSubmit}>
          <div className="form-group text-center">
            <label className="label">Valor</label>
            <Form.Control
              className="text-center"
              isInvalid={!!errorsValidate.valor}
              // max="10"
              min="0"
              name="valor"
              onChange={(e) => setDataForm({ ...dataForm, valor: e.target.value })}
              required
              step="0.1"
              type="number"
            />
            <Form.Control.Feedback type="invalid">
              {errorsValidate.valor}
            </Form.Control.Feedback>
          </div>
          <div className="form-group text-center">
            <button className="btn btn-success">Salvar</button>
          </div>
        </Form>
      ) : (
        <div className="form-group d-flex justify-content-around align-items-center">
          <span>
            {valor}
          </span>
          <button
            className="btn btn-danger"
            onClick={() => handleIndicadorProcesso(indicador_processo_id)}
            type="button"
          ><FaTrash /> Remover</button>
        </div>
      )}
    </>
  );
};

export default FormResposta;
