import React from 'react';

const Footer = () => {
  const data =  new Date();
  return (
    <footer className="main-footer">
      <div className="float-right d-none d-sm-block">
        <b>Version</b> 1.0.0-beta
      </div>
      <strong>
        Copyright &copy; { data.getFullYear() } <a href="http://tuxtu.com.br/">TUXTU</a>.
      </strong>
      All rights reserved.
    </footer>
  );
};

export default Footer;
