import React from 'react';

import { Link } from 'react-router-dom';

import HeaderStyle from './styles';
import imgLogo from '../../assets/images/logo.svg';

const Header = () => {
  return (
    <HeaderStyle>
      <div className="row">
        <div className="d-flex p-2 justify-content-around flex-row align-items-center">
          <Link to="/">
            <img
              alt="Logo"
              className="img-fluid"
              src={imgLogo}
              width="250px"
            />
          </Link>
        </div>
      </div>
    </HeaderStyle>
  )
}

export default Header;
