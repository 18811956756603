import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { loadAtividades } from '~/store/modules/atividade/action';


const SelectAtividades = ({ entidade_id, setAtividade }) => {
  const dispatch = useDispatch();

  const { atividades } = useSelector(
    ({ AtividadeReducer }) => AtividadeReducer
  );

  const [options, setOptions] = useState([
    { value: null, label: '.::Selecione::.' },
  ]);

  const [optionSelected, setOptionSelected] = useState([
    { value: null, label: '.::Selecione::.' },
  ]);

  useEffect(() => {
    dispatch(
      loadAtividades({
        raw_list: true,
        where: `entidade_id:${entidade_id}`,
      })
    );
  }, [entidade_id, dispatch]);

  useEffect(() => {
    let optionSet = [{ value: null, label: '.::Selecione::.' }];
    setOptionSelected({ value: null, label: '.::Selecione::.' });
    if (atividades.data) {
      atividades.data.forEach(({ id, nome, codigo }) => {
        optionSet.push({ value: id, label: `${codigo} - ${nome}` });
      });
    }
    setOptions(optionSet);
  }, [atividades]);

  const handleOnChange = (e) => {
    setOptionSelected(e);
    setAtividade(e);
  };

  return (
    <>
      <Form.Group>
        <Form.Label>Atividade</Form.Label>

        <Select
          onChange={handleOnChange}
          options={options}
          value={optionSelected}
        />
        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

export default SelectAtividades;
