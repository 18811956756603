import { apiWithToken } from '../api';

const resouceUrl = '/foto_vistoria';
const token = localStorage.getItem('@token');
let api = apiWithToken(token);

export const load = async relatorio_vistoria_id => {
  return await api.get(`${resouceUrl}/${relatorio_vistoria_id}`);
}

export const store = async foto_vistoria => {
  return await api.post(resouceUrl, foto_vistoria);
}

export const remove = async (id) => {
  return await api.delete(`${resouceUrl}/${id}`);
}
