import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { Loading } from '~/components';
import plugins from 'suneditor/src/plugins';
import Swal from 'sweetalert2';
import {
  storeProjetoProcesso,
  updateProjetoProcesso,
} from '~/store/modules/projeto_processo/action';
import { FaPrint } from 'react-icons/fa';
import { BlobProvider } from '@react-pdf/renderer';
import ParecerProjetoPDF from './PDF';

const ProjetoGeralEmpreendimento = ({
  setProjetoIsOk,
  processo_id,
  projeto,
}) => {
  const dispatch = useDispatch();
  let [show, setShow] = useState(false);
  const history = useHistory();
  let [introducao, setIntroducao] = useState();
  let [desenvolvimento, setDesenvolvimento] = useState();
  let [conclusao, setConclusao] = useState();
  let [projetoData, setProjetoData] = useState();
  let [projetoOk, setProjetoOk] = useState(false);
  const { processo } = useSelector(({ ProcessoReducer }) => ProcessoReducer);

  useEffect(() => {
    if (projeto && projeto.id) {
      setIntroducao(projeto.introducao);
      setDesenvolvimento(projeto.desenvolvimento);
      setConclusao(projeto.conclusao);
      setProjetoData(projeto);
    }
  }, [dispatch, projeto]);

  useEffect(() => {
    if (
      projetoData &&
      projetoData.introducao != null &&
      projetoData.desenvolvimento != null &&
      projetoData.conclusao != null
    ) {
      if (setProjetoIsOk) {
        setProjetoIsOk(true);
      }
      setProjetoOk(true);
    }
  }, [projetoData, setProjetoIsOk]);

  const handleOnChangeIntroducao = (content) => {
    setIntroducao(content);
  };

  const handleOnChangeDesenvolvimento = (content) => {
    setDesenvolvimento(content);
  };

  const handleOnChangeConclusao = (content) => {
    setConclusao(content);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!projetoData) {
      await dispatch(
        storeProjetoProcesso({
          introducao,
          desenvolvimento,
          conclusao,
          processo_id,
        })
      );
      await Swal.fire('Sucesso', 'Projeto salvo com sucesso!', 'success');
      await history.go();
    } else {
      await dispatch(
        updateProjetoProcesso(
          {
            introducao,
            desenvolvimento,
            conclusao,
          },
          projetoData.id
        )
      );
      await Swal.fire('Sucesso', 'Projeto salvo com sucesso!', 'success');
    }
  };

  const handleClose = async () => {
    await setShow(false);
    await history.go();
  };

  return (
    <>
      {processo_id ? (
        <>
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h3 className="card-title w-100">
                Projeto geral do empreendimento ou atividade
              </h3>
              {(projetoOk || (projeto && !projeto.editavel)) && (
                // <a
                //   className="btn btn-info d-flex flex-row align-items-center"
                //   href={`/parecer-projeto/${processo_id}/imprimir`}
                //   rel="noopener noreferrer"
                //   target="_blank"
                // >
                //   <FaPrint style={{ marginRight: 5 }} /> Imprimir
                // </a>

                <BlobProvider document={
                    <ParecerProjetoPDF
                        process={processo}
                    />
                }>
                    {({ url }) => (
                        <a
                            href={url}
                            target="_blank"
                            className="btn btn-info d-flex flex-row align-items-center"
                            rel="noopener noreferrer"
                        >
                            <FaPrint style={{ marginRight: 5 }} /> Imprimir
                        </a>
                    )}
                </BlobProvider>                                    
              )}
            </div>
            <div className="card-body">
              <button
                className={`btn ${
                  projetoOk || (projeto && !projeto.editavel)
                    ? 'btn-primary'
                    : 'btn-success'
                } btn-block`}
                onClick={() => setShow(true)}
              >
                <i aria-hidden="true" className="fa fa-list-alt" /> Projeto
                geral do empreendimento ou atividade
              </button>
            </div>
          </div>
          <Modal
            animation
            aria-labelledby="example-custom-modal-styling-title"
            dialogClassName="modal-xl"
            onHide={handleClose}
            show={show}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Projeto geral do empreendimento ou atividade
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {(projeto && projeto.editavel) || !projeto ? (
                <form id="form-save-projeto" onSubmit={handleSubmit}>
                  <div>
                    <label>Introdução</label>
                    {!projeto || (projeto && !projeto.introducao_aceita) ? (
                      <SunEditor
                        lang="pt_br"
                        name="introducao"
                        onChange={handleOnChangeIntroducao}
                        setContents={introducao}
                        setOptions={{
                          plugins: [plugins],
                          height: 200,
                          buttonList: [
                            ['undo', 'redo'],
                            ['font', 'fontSize', 'formatBlock'],
                            ['paragraphStyle', 'blockquote'],
                            [
                              'bold',
                              'underline',
                              'italic',
                              'strike',
                              'subscript',
                              'superscript',
                            ],
                            ['fontColor', 'hiliteColor', 'textStyle'],
                            ['removeFormat'],
                            '/', // Line break
                            ['outdent', 'indent'],
                            ['align', 'horizontalRule', 'list', 'lineHeight'],
                            ['table', 'link'], // You must add the 'katex' library at options to use the 'math' plugin.
                            ['fullScreen', 'showBlocks', 'codeView'],
                            ['preview'],
                          ],
                        }}
                      />
                    ) : (
                      <div
                        className="m-3"
                        dangerouslySetInnerHTML={{
                          __html: introducao,
                        }}
                      />
                    )}
                  </div>
                  <hr />
                  {projeto && projeto.introducao_parecer && (
                    <div>
                      <label>Parecer Introdução</label>
                      <div
                        className="m-3"
                        dangerouslySetInnerHTML={{
                          __html: projeto.introducao_parecer,
                        }}
                      />
                      <hr />
                    </div>
                  )}
                  <div>
                    <label>Desenvolvimento</label>
                    {!projeto ||
                    (projeto && !projeto.desenvolvimento_aceita) ? (
                      <SunEditor
                        lang="pt_br"
                        name="desenvolvimento"
                        onChange={handleOnChangeDesenvolvimento}
                        setContents={desenvolvimento}
                        setOptions={{
                          plugins: [plugins],
                          height: 400,
                          buttonList: [
                            ['undo', 'redo'],
                            ['font', 'fontSize', 'formatBlock'],
                            ['paragraphStyle', 'blockquote'],
                            [
                              'bold',
                              'underline',
                              'italic',
                              'strike',
                              'subscript',
                              'superscript',
                            ],
                            ['fontColor', 'hiliteColor', 'textStyle'],
                            ['removeFormat'],
                            '/', // Line break
                            ['outdent', 'indent'],
                            ['align', 'horizontalRule', 'list', 'lineHeight'],
                            ['table', 'link'], // You must add the 'katex' library at options to use the 'math' plugin.
                            ['fullScreen', 'showBlocks', 'codeView'],
                            ['preview'],
                          ],
                        }}
                      />
                    ) : (
                      <div
                        className="m-3"
                        dangerouslySetInnerHTML={{
                          __html: desenvolvimento,
                        }}
                      />
                    )}
                  </div>
                  <hr />
                  {projeto && projeto.desenvolvimento_parecer && (
                    <div>
                      <label>Parecer Desenvolvimento</label>
                      <div
                        className="m-3"
                        dangerouslySetInnerHTML={{
                          __html: projeto.desenvolvimento_parecer,
                        }}
                      />
                      <hr />
                    </div>
                  )}

                  <div>
                    <label>Conclusão</label>
                    {!projeto || (projeto && !projeto.conclusao_aceita) ? (
                      <SunEditor
                        lang="pt_br"
                        name="conclusao"
                        onChange={handleOnChangeConclusao}
                        setContents={conclusao}
                        setOptions={{
                          plugins: [plugins],
                          height: 400,
                          buttonList: [
                            ['undo', 'redo'],
                            ['font', 'fontSize', 'formatBlock'],
                            ['paragraphStyle', 'blockquote'],
                            [
                              'bold',
                              'underline',
                              'italic',
                              'strike',
                              'subscript',
                              'superscript',
                            ],
                            ['fontColor', 'hiliteColor', 'textStyle'],
                            ['removeFormat'],
                            '/', // Line break
                            ['outdent', 'indent'],
                            ['align', 'horizontalRule', 'list', 'lineHeight'],
                            ['table', 'link'], // You must add the 'katex' library at options to use the 'math' plugin.
                            ['fullScreen', 'showBlocks', 'codeView'],
                            ['preview'],
                          ],
                        }}
                      />
                    ) : (
                      <div
                        className="m-3"
                        dangerouslySetInnerHTML={{
                          __html: conclusao,
                        }}
                      />
                    )}
                  </div>
                  {projeto && projeto.conclusao_parecer && (
                    <div>
                      <label>Parecer Conclusão</label>
                      <div
                        className="m-3"
                        dangerouslySetInnerHTML={{
                          __html: projeto.conclusao_parecer,
                        }}
                      />
                      <hr />
                    </div>
                  )}
                </form>
              ) : (
                <>
                  <div className="row">
                    <div className="col-md-8">
                      <label>Introdução</label>
                      <div
                        className="m-3"
                        dangerouslySetInnerHTML={{
                          __html: introducao,
                        }}
                      />
                    </div>
                    {projeto && projeto.introducao_parecer && (
                      <div className="col-md-4">
                        <label>Parecer Introdução</label>
                        <div
                          className="m-3"
                          dangerouslySetInnerHTML={{
                            __html: projeto.introducao_parecer,
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-8">
                      <label>Desenvolvimento</label>
                      <div
                        className="m-3"
                        dangerouslySetInnerHTML={{
                          __html: desenvolvimento,
                        }}
                      />
                    </div>
                    {projeto && projeto.desenvolvimento_parecer && (
                      <div className="col-md-4">
                        <label>Parecer Desenvolvimento</label>
                        <div
                          className="m-3"
                          dangerouslySetInnerHTML={{
                            __html: projeto.desenvolvimento_parecer,
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-8">
                      <label>Conclusão</label>
                      <div
                        className="m-3"
                        dangerouslySetInnerHTML={{
                          __html: conclusao,
                        }}
                      />
                    </div>
                    {projeto && projeto.conclusao_parecer && (
                      <div className="col-md-4">
                        <label>Parecer Conclusão</label>
                        <div
                          className="m-3"
                          dangerouslySetInnerHTML={{
                            __html: projeto.conclusao_parecer,
                          }}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              {(projeto && projeto.editavel) || !projeto ? (
                <button
                  className="btn btn-success float-right"
                  form="form-save-projeto"
                  type="submit"
                >
                  <i className="fa fa-save" /> Salvar
                </button>
              ) : (
                ''
              )}
              <button
                className="btn btn-default"
                onClick={handleClose}
                type="button"
              >
                <i className="fa fa-window-close" /> Fechar
              </button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default ProjetoGeralEmpreendimento;
