import React, { useEffect } from 'react';
import { Link, Switch, Route, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaEye, FaEdit } from 'react-icons/fa';
import { BasicContent } from '../components';
import { Loading, Paginate } from '~/components';
import formatForMask from '~/utils/formatForMask';
import { loadTecnicosAvaliadores } from '~/store/modules/tecnico_avaliador/action';
import Create from '../Create';
import Edit from '../Edit';
import Show from '../Show';

const List = () => {
  let location = useLocation();
  const dispatch = useDispatch();
  let modal = location.state && location.state.modal;
  const breadcrumb = {
    label: 'Lista',
    to: '/tecnicos_avaliadores',
    active: false,
  };
  const { user_auth } = useSelector(
    ({ LoginReducer }) => LoginReducer
  );
  const { tecnicos_avaliadores, tecnico_avaliador } = useSelector(
    ({ TecnicoAvaliadorReducer }) => TecnicoAvaliadorReducer
  );

  useEffect(() => {
    dispatch(loadTecnicosAvaliadores({
      with: 'pessoa',
      where: `tipo_user_id:5,entidade_id:${user_auth.user.entidade_id}`
    }));
  }, [dispatch, user_auth.user.entidade_id, tecnico_avaliador]);

  return (
    <>
      {modal && (
        <Switch>
          <Route
            children={<Create />}
            path="/tecnicos_avaliadores/create"
          />
          <Route
            children={<Edit />}
            exact
            path="/tecnicos_avaliadores/:tecnico_avaliador_id/edit"
          />
          <Route
            children={<Show />}
            exact
            path="/tecnicos_avaliadores/:tecnico_avaliador_id/detalhe"
          />
        </Switch>
      )}
      <BasicContent breadcrumb={breadcrumb}>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Lista</h2>
            <div className="card-tools">
              <Link
                className="btn btn-success"
                to={{
                  pathname: '/tecnicos_avaliadores/create',
                  state: { modal: true },
                }}
              >
                <i className="fa fa-plus" /> Novo
              </Link>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-sm table-bordered table-hover table-striped">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Nome</th>
                    <th>CNPJ/CPF</th>
                    <th>Email</th>
                    <th className="text-right">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {tecnicos_avaliadores.data && tecnicos_avaliadores.data.map((tecnico_avaliador, index) => (
                    <tr key={index}>
                      <td>{tecnico_avaliador.id}</td>
                      <td>{tecnico_avaliador.nome}</td>
                      <td>
                        {tecnico_avaliador.pessoa && tecnico_avaliador.pessoa.cnpj ?
                          formatForMask(
                            tecnico_avaliador.pessoa.cnpj,
                            '##.###.###/####-##'
                          ) : ''
                        }
                        {tecnico_avaliador.pessoa && tecnico_avaliador.pessoa.cpf ?
                          formatForMask(
                            tecnico_avaliador.pessoa.cpf,
                            '###.###.###-##'
                          ) : ''
                        }
                      </td>
                      <td>{tecnico_avaliador.email}</td>
                      <td>
                        <div className="btn-group float-right">
                          <Link
                            className="btn btn-default"
                            to={{
                              pathname: `/tecnicos_avaliadores/${tecnico_avaliador.id}/edit`,
                              state: { modal: true },
                            }}
                          >
                            <FaEdit /> Editar
                          </Link>
                          <Link
                            className="btn btn-info"
                            to={{
                              pathname: `/tecnicos_avaliadores/${tecnico_avaliador.id}/detalhe`,
                              state: { modal: true },
                            }}
                          >
                            <FaEye /> Detalhes
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {!tecnicos_avaliadores && <Loading />}
            </div>
          </div>
          <div className="card-footer clearfix">
            <Paginate
              load={() => loadTecnicosAvaliadores({
                with: 'pessoa',
                where: `tipo_user_id:5,entidade_id:${user_auth.user.entidade_id}`
              })}
              meta={tecnicos_avaliadores.meta}
            />
          </div>
        </div>
      </BasicContent>
    </>
  );
};

export default List;
