export const LOAD_RESPONSAVEL_TECNICOS = 'LOAD_RESPONSAVEL_TECNICOS';
export const LOAD_LIST_FOR_ADD = 'LOAD_LIST_FOR_ADD';
export const GET_LIST_FOR_ADD = 'GET_LIST_FOR_ADD';
export const SELECT_RESPONSAVEL_TECNICOS = 'SELECT_RESPONSAVEL_TECNICOS';
export const RESPONSAVEL_TECNICO_ERROR = 'RESPONSAVEL_TECNICO_ERROR';
export const RESPONSAVEL_TECNICO_GET_ERRORS = 'RESPONSAVEL_TECNICO_GET_ERRORS';
export const RESPONSAVEL_TECNICOS_DATA = 'RESPONSAVEL_TECNICOS_DATA';
export const STORE_RESPONSAVEL_TECNICO = 'STORE_RESPONSAVEL_TECNICO';
export const GET_RESPONSAVEL_TECNICO = 'GET_RESPONSAVEL_TECNICO';
export const REMOVER_RESPONSAVEL_TECNICO = 'REMOVER_RESPONSAVEL_TECNICO';
export const SHOW_RESPONSAVEL_TECNICO = 'SHOW_RESPONSAVEL_TECNICO';
export const UPDATE_RESPONSAVEL_TECNICO = 'UPDATE_RESPONSAVEL_TECNICO';
