import React from 'react'
import { Modal } from 'react-bootstrap';
import './styles.css';


export default function ModalDetalheLegislacao({
    showLegisDetalheModal = false,
    legislacao = [],
    closeModal = () => {}
  }) {
    return(
        <Modal
            show={showLegisDetalheModal}
            dialogClassName="modal-80w"
            onHide={closeModal}
            scrollable
        >
            <Modal.Header closeButton>
            <Modal.Title>
                {legislacao.tipo} {legislacao.legislacao}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>  
                <blockquote style={{ borderLeft: '0.7rem solid #28a745' }}>
                    <div
                    className="m-3"
                    dangerouslySetInnerHTML={{
                        __html: legislacao.descricao
                    }}
                    />
                </blockquote>
            </Modal.Body>
        </Modal>
    )
  }