import React from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import formatForMask from '~/utils/formatForMask';
import NotaTecAmbiental from '../../views/Process/components/NotaTecAmbiental';
import ParecerProjeto from '../../views/AnaliseProcessos/Show/components/ParecerProjeto';
import './styles.css';
import { RelatorioVistoria } from '../../views/AnaliseProcessos/Show/components';

export default function ModalLicencaRef({
  showLicencasModal = false,
  licencas = [],
  closeModal = () => {},
}) {
  return (
    <Modal
      dialogClassName="modal-80w"
      onHide={closeModal}
      scrollable
      show={showLicencasModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Processos de referência</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs id="noanim-tab-example" transition={false}>
          {licencas.length > 0 &&
            licencas?.map((licenca) => (
              <Tab
                eventKey={licenca.protocolo}
                key={licenca.protocolo}
                title={licenca.protocolo}
              >
                <div className="content-info-ref">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">
                        PROCESSO : <strong>{licenca.protocolo}</strong>
                      </h3>
                    </div>
                    <div className="card-body table-responsive">
                      <table className="table table-hover table-striped table-bordered table-sm">
                        <tbody>
                          <tr>
                            <th>Empreendimento</th>
                            <td>
                              {' '}
                              {`${licenca.nome_pessoa} - ${
                                licenca.cnpj
                                  ? formatForMask(
                                      licenca.cnpj,
                                      '##.###.###/####-##'
                                    )
                                  : ''
                              } ${
                                licenca.cpf
                                  ? formatForMask(licenca.cpf, '###.###.###-##')
                                  : ''
                              }`}
                            </td>
                          </tr>
                          <tr>
                            <th>Porte Empreendimento</th>
                            <td>{licenca.nome_empreendimento}</td>
                          </tr>
                          <tr>
                            <th>Local</th>
                            <td>
                              {' '}
                              {licenca.nome_municipio} - {licenca.uf}
                            </td>
                          </tr>
                          <tr>
                            <th>Atividade</th>
                            <td>{licenca.nome_atividade}</td>
                          </tr>
                          <tr>
                            <th>Endereço da atividade</th>
                            <td>
                              {licenca.logradouro} - {licenca.bairro} -{' '}
                              {licenca.numero_endereco
                                ? licenca.numero_endereco
                                : 'Nº não informado'}{' '}
                              - {licenca.cep} -{' '}
                              {licenca.complemento
                                ? licenca.complemento
                                : 'Sem complemento.'}
                            </td>
                          </tr>
                          <tr>
                            <th>Tipo de licença</th>
                            <td>{licenca.nome_tipo_licenca}</td>
                          </tr>
                          <tr>
                            <th>Técnico Responsável</th>
                            <td>{licenca.user_name}</td>
                          </tr>
                          <tr>
                            <th>Endereço da Empresa</th>
                            <td>
                              {licenca.logradouro_pessoa},
                              {licenca.bairro_pessoa} -{licenca.cep_pessoa} -
                              {licenca.cidade_pessoa}
                            </td>
                          </tr>
                          <tr>
                            <th>Status</th>
                            <td>
                              <span className="badge badge-success">
                                {licenca.nome_status_processo}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <NotaTecAmbiental
                        onlyView={true}
                        processo_id={licenca?.id}
                        setNotaTecIsOk={() => {}}
                        isRef
                      />
                    </div>
                    {(licenca?.status_processo_id >= 10 ||
                      licenca?.status_processo_id === 7) && (
                        <div className="col-md-4">
                          <ParecerProjeto
                            processo_id={licenca.id}
                            projeto={licenca?.projeto}
                            setParecerIsOk={() => {}}
                            isRef
                          />
                        </div>
                      )}
                    {licenca?.vistorias && (
                      <div className="col-md-4">
                        <RelatorioVistoria
                          onlyView
                          setRelatorioIsOk={() => {}}
                          vistorias={licenca.vistorias}
                          returnReload={false}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Tab>
            ))}
        </Tabs>
      </Modal.Body>
    </Modal>
  );
}
