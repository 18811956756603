import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Row, Col } from 'react-bootstrap';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { FiCheckCircle } from 'react-icons/fi';
import { BoxDeclaraRespo, BoxTermos } from '../../styles';
import schema from './schemaValidations';
import buscaCep from '~/services/cep';
import { useSelector } from 'react-redux';
import TermoUso from '../../../TermoUso/TermoUso';
import FileInput from '~/components/FileInput';
import formatCpfCnpj from '~/utils/formatCpfCnpj';
import formatCep from '~/utils/formatCep';
import formatPhone from '~/utils/formatPhone';

const FormCad = ({
    onHandleSubmit,
    pessoaJuridica,
    setPessoaJuridica,
    errors,
}) => {
    const { uf_data } = useSelector(({ UfDataReducer }) => UfDataReducer);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setPessoaJuridica({
            ...pessoaJuridica,
            [name]: value,
        });
    };

    const handleBuscaCep = async (event) => {
        const { value } = event.target;
        const cep = value;
        try {
            const data = await buscaCep.get(`${cep}/json`);
            const endereco = data.data;
            const finded = {
                logradouro: endereco.logradouro,
                bairro: endereco.bairro,
                cidade: endereco.localidade,
            };
            Object.assign(pessoaJuridica, finded);
            setPessoaJuridica({
                ...pessoaJuridica,
            });
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <div className="card">
            <div className="card-body">
                <Form onSubmit={onHandleSubmit} schema={schema}>
                    <Form.Group controlId="nome">
                        <Form.Label>Razão Social</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.nome}
                            name="nome"
                            onChange={handleChange}
                            placeholder="Razão Social"
                            type="text"
                            value={pessoaJuridica.nome}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.nome}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="nome_fantasia">
                        <Form.Label>Nome Fantasia</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.nome_fantasia}
                            name="nome_fantasia"
                            onChange={handleChange}
                            placeholder="Nome Fantasia"
                            type="text"
                            value={pessoaJuridica.nome_fantasia}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.nome_fantasia}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Row} controlId="cnpj_select_input">
                        <Col sm={6}>
                            <Form.Label>CNPJ </Form.Label>
                            <Form.Control
                                isInvalid={!!errors.cnpj}
                                maxLength="18"
                                minLength="17"
                                name="cnpj"
                                onChange={handleChange}
                                placeholder="CNPJ"
                                type="text"
                                value={formatCpfCnpj(pessoaJuridica.cnpj ?? '')}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.cnpj}
                            </Form.Control.Feedback>
                        </Col>
                        <Col>
                            <Form.Label>Anexo</Form.Label>
                            <FileInput
                                data={pessoaJuridica}
                                dataError={errors.anexo_cnpj}
                                id="anexo_cnpj"
                                name="anexo_cnpj"
                                setData={setPessoaJuridica}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.anexo_cnpj}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={6}>
                            <Form.Label>
                                Certidão Simplificada da Junta Comercial
                            </Form.Label>
                            <Form.Control
                                as="select"
                                isInvalid={!!errors.certidao_simplificada_jc}
                                name="certidao_simplificada_jc"
                                onChange={handleChange}
                                placeholder="Certidão Simplificada da Junta Comercial"
                                value={pessoaJuridica.certidao_simplificada_jc}
                            >
                                <option value={1}> SIM </option>
                                <option value={0}> NÃO </option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {errors.certidao_simplificada_jc}
                            </Form.Control.Feedback>
                        </Col>
                        <Col>
                            <Form.Label>Anexo</Form.Label>
                            <FileInput
                                data={pessoaJuridica}
                                dataError={
                                    errors.anexo_certidao_simplificada_jc
                                }
                                id="anexo_certidao_simplificada_jc"
                                name="anexo_certidao_simplificada_jc"
                                setData={setPessoaJuridica}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.anexo_certidao_simplificada_jc}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Porte Empreendimento</Form.Label>
                        <Form.Control
                            as="select"
                            isInvalid={!!errors.porte_empreendimento_id}
                            name="porte_empreendimento_id"
                            onChange={handleChange}
                            placeholder="UF"
                            value={pessoaJuridica.porte_empreendimento_id}
                        >
                            <option value="2">MEI</option>
                            <option value="3">MICRO EMPRESA</option>
                            <option value="4">EMPRESA PEQUENO PORTE</option>
                            <option value="5">EMPRESA MÉDIO PORTE</option>
                            <option value="6">EMPRESA GRANDE PORTE</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {errors.porte_empreendimento_id}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>CEP</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.cep}
                            name="cep"
                            onBlur={handleBuscaCep}
                            onChange={handleChange}
                            placeholder="CEP"
                            type="text"
                            maxLength="9"
                            value={formatCep(pessoaJuridica.cep ?? '')}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.cep}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={6}>
                            <Form.Label>Logradouro</Form.Label>
                            <Form.Control
                                isInvalid={!!errors.logradouro}
                                name="logradouro"
                                onChange={handleChange}
                                placeholder="Logradouro"
                                type="text"
                                value={pessoaJuridica.logradouro}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.logradouro}
                            </Form.Control.Feedback>
                        </Col>
                        <Col>
                            <Form.Label>Anexo</Form.Label>
                            <FileInput
                                data={pessoaJuridica}
                                dataError={errors.anexo_endereco}
                                id="anexo_endereco"
                                name="anexo_endereco"
                                setData={setPessoaJuridica}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.anexo_endereco}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Número</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.numero_endereco}
                            name="numero_endereco"
                            onChange={handleChange}
                            placeholder="Número"
                            type="text"
                            value={pessoaJuridica.numero_endereco}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.numero_endereco}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Bairro</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.bairro}
                            name="bairro"
                            onChange={handleChange}
                            placeholder="Bairro"
                            type="text"
                            value={pessoaJuridica.bairro}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.bairro}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Cidade</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.cidade}
                            name="cidade"
                            onChange={handleChange}
                            placeholder="Cidade"
                            type="text"
                            value={pessoaJuridica.cidade}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.cidade}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>UF</Form.Label>
                        <Form.Control
                            as="select"
                            isInvalid={!!errors.uf_endereco_id}
                            name="uf_endereco_id"
                            onChange={handleChange}
                            placeholder="UF"
                            value={pessoaJuridica.uf_endereco_id}
                        >
                            {uf_data[0] &&
                                uf_data.map((uf) => (
                                    <option key={uf.id} value={uf.id}>
                                        {uf.uf}
                                    </option>
                                ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {errors.uf_endereco_id}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Número de WhatsApp</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.numero_whatsapp}
                            name="numero_whatsapp"
                            onChange={handleChange}
                            placeholder="Número de WhatsAp"
                            type="text"
                            maxLength="15"
                            value={formatPhone(
                                pessoaJuridica.numero_whatsapp ?? ''
                            )}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.numero_whatsapp}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Fone de contato</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.telefone}
                            name="telefone"
                            onChange={handleChange}
                            placeholder="Fone de contato"
                            type="text"
                            maxLength="15"
                            value={formatPhone(pessoaJuridica.telefone ?? '')}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.telefone}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Homepage da empresa</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.site}
                            name="site"
                            onChange={handleChange}
                            placeholder="Homepage da empresa"
                            type="text"
                            value={pessoaJuridica.site}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.site}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <hr style={{ backgroundColor: '#FFF' }} />
                    <Form.Group>
                        <Form.Label>Dados Responsavel legal:</Form.Label>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Nome do Responsável Legal</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.nome_responsavel_legal}
                            name="nome_responsavel_legal"
                            onChange={handleChange}
                            placeholder="Nome do Responsável Lega"
                            type="text"
                            value={pessoaJuridica.nome_responsavel_legal}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.nome_responsavel_legal}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Função</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.funcao_responsavel_legal}
                            name="funcao_responsavel_legal"
                            onChange={handleChange}
                            placeholder="Função"
                            type="text"
                            value={pessoaJuridica.funcao_responsavel_legal}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.funcao_responsavel_legal}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={6}>
                            <Form.Label>CPF Responsável Legal</Form.Label>
                            <Form.Control
                                isInvalid={!!errors.cpf_responsavel_legal}
                                name="cpf_responsavel_legal"
                                onChange={handleChange}
                                placeholder="CPF Responsável Legal"
                                type="text"
                                maxLength="14"
                                value={formatCpfCnpj(
                                    pessoaJuridica.cpf_responsavel_legal ?? ''
                                )}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.cpf_responsavel_legal}
                            </Form.Control.Feedback>
                        </Col>
                        <Col>
                            <Form.Label>Anexo</Form.Label>
                            <FileInput
                                data={pessoaJuridica}
                                dataError={errors.anexo_cpf_responsavel_legal}
                                id="anexo_cpf_responsavel_legal"
                                name="anexo_cpf_responsavel_legal"
                                setData={setPessoaJuridica}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.anexo_cpf_responsavel_legal}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={6}>
                            <Form.Label>RG Responsável Legal</Form.Label>
                            <Form.Control
                                isInvalid={!!errors.rg_responsavel_legal}
                                name="rg_responsavel_legal"
                                onChange={handleChange}
                                placeholder="RG Responsável Legal"
                                type="text"
                                value={pessoaJuridica.rg_responsavel_legal}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.rg_responsavel_legal}
                            </Form.Control.Feedback>
                        </Col>
                        <Col>
                            <Form.Label>Anexo</Form.Label>
                            <FileInput
                                data={pessoaJuridica}
                                dataError={errors.anexo_rg_responsavel_legal}
                                id="teste2"
                                name="anexo_rg_responsavel_legal"
                                setData={setPessoaJuridica}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.anexo_rg_responsavel_legal}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={6}>
                            <Form.Label>Endereço Responsável Legal</Form.Label>
                            <Form.Control
                                isInvalid={!!errors.endereco_responsavel_legal}
                                name="endereco_responsavel_legal"
                                onChange={handleChange}
                                placeholder="Endereço Responsável Legal"
                                type="text"
                                value={
                                    pessoaJuridica.endereco_responsavel_legal
                                }
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.endereco_responsavel_legal}
                            </Form.Control.Feedback>
                        </Col>
                        <Col>
                            <Form.Label>Anexo</Form.Label>
                            <FileInput
                                data={pessoaJuridica}
                                dataError={
                                    errors.anexo_endereco_responsavel_legal
                                }
                                name="anexo_endereco_responsavel_legal"
                                setData={setPessoaJuridica}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.anexo_endereco_responsavel_legal}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Fone de contato do Representante Legal
                        </Form.Label>
                        <Form.Control
                            isInvalid={!!errors.telefone_responsavel_legal}
                            name="telefone_responsavel_legal"
                            onChange={handleChange}
                            placeholder="Fone de contato do Representante Legal"
                            type="text"
                            maxLength="15"
                            value={formatPhone(
                                pessoaJuridica.telefone_responsavel_legal ?? ''
                            )}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.telefone_responsavel_legal}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>E-mail do Representante Legal</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.email_responsavel_legal}
                            name="email_responsavel_legal"
                            onChange={handleChange}
                            placeholder="E-mail do Representante Legal"
                            type="text"
                            value={pessoaJuridica.email_responsavel_legal}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.email_responsavel_legal}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Confirmação E-mail do Representante Legal
                        </Form.Label>
                        <Form.Control
                            isInvalid={
                                !!errors.confirmacao_email_responsavel_legal
                            }
                            name="confirmacao_email_responsavel_legal"
                            onChange={handleChange}
                            placeholder="Confirmação E-mail do Representante Legal"
                            type="text"
                            value={
                                pessoaJuridica.confirmacao_email_responsavel_legal
                            }
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.confirmacao_email_responsavel_legal}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <hr style={{ backgroundColor: '#FFF' }} />
                    <Form.Group>
                        <Form.Label>Dados de Acesso:</Form.Label>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.email}
                            name="email"
                            onChange={handleChange}
                            placeholder="E-mail"
                            type="email"
                            value={pessoaJuridica.email}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Confirmação de E-mail</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.confirmacao_email}
                            name="confirmacao_email"
                            onChange={handleChange}
                            placeholder="Confirmação de E-mail"
                            type="email"
                            value={pessoaJuridica.confirmacao_email}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.confirmacao_email}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Senha</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.password}
                            name="password"
                            onChange={handleChange}
                            placeholder="Senha"
                            type="password"
                            value={pessoaJuridica.password}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.senha}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Confirmação Senha</Form.Label>
                        <Form.Control
                            isInvalid={!!errors.confirmacao_senha}
                            name="confirmacao_senha"
                            onChange={handleChange}
                            placeholder="Confirmar Senha"
                            type="password"
                            value={pessoaJuridica.confirmacao_senha}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.confirmacao_senha}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            DECLARAÇÃO DE RESPONSABILIDADE AMBIENTAL
                        </Form.Label>
                        <BoxDeclaraRespo className="form-control">
                            Que todas as informações declaradas são de minha
                            responsabilidade como usuário(a). Estou ciente que a
                            omissão ou falsa declaração de informações, impedirá
                            a análise desta solicitação de licenciamento
                            ambiental, sem prejuízo a outras penalidades
                            previstas pela legislação aplicável.
                        </BoxDeclaraRespo>
                    </Form.Group>
                    <BoxTermos>
                        <Form.Check
                            isInvalid={!!errors.check_termos}
                            name="check_termos"
                            required
                        />
                        <TermoUso />
                    </BoxTermos>
                    <div className="d-flex justify-content-between">
                        <Link
                            className="btn btn-default"
                            to="/selecione-cadastro"
                        >
                            <FaArrowCircleLeft /> Voltar
                        </Link>
                        <button className="btn btn-success" type="submit">
                            <FiCheckCircle /> Concordar e Cadastrar
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default FormCad;
