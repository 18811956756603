import React from 'react';
import TableQuestions from '../TableQuestions';
import Impacto from '../Impacto';

const MeioSocioeconomico = () => {
  const mountedQuestions = (valor, valor2, valor3, valor4, valor5) => [
    { val: '0,0 - 1,9', q: valor },
    { val: '2,0 - 3,9', q: valor2 },
    { val: '4,0 - 5,9', q: valor3 },
    { val: '6,0 - 7,9', q: valor4 },
    { val: '8,0 - 10,0', q: valor5 },
  ];
  const genericQuestions2 = [
    { val: '0,0 - 1,9', q: 'Sem influência' },
    { val: '2,0 - 3,9', q: 'Suposta' },
    { val: '4,0 - 5,9', q: 'Sem evidências' },
    { val: '6,0 - 7,9,0', q: 'Fortes indícios' },
    { val: '8,0 - 10,0', q: 'Comprovado' },
  ];

  const dataImpactoNucleoPopulacional = [
    TableQuestions({
      titulo:
          'O empreendimento causará alteração negativa das condições de abastecimento e comercialização da população local?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
          'O empreendimento causará alterações na rede de polarização regional?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
          'O empreendimento causará aumento da expectativa de emprego na população local e/ou de municípios próximos?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
          'O empreendimento causará aumento da demanda de serviços e equipamentos sociais?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
          'O empreendimento causará desapropriação de imóveis urbanos ou rurais?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará inundação de áreas urbanas?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento causará aparecimento de vetores de doenças?',
      questoes: genericQuestions2,
    }),
  ];

  const dataImpactoSocialCultural = [
    TableQuestions({
      titulo:
          'O empreendimento causará alteração da qualidade de vida da população  local?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
          'O empreendimento causará alteração das relações sociais da população?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
          'O empreendimento causará alteração dos elementos culturais da população?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
          'O empreendimento afetará patrimônios históricos e/ou arqueológicos?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
          'O empreendimento afetará patrimônios históricos e/ou arqueológicos?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
          'O empreendimento afetará redes de transmissão/distribuição de energia elétrica?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento afetará gasodutos, oleodutos ou afins?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo: 'O empreendimento afetará redes de telecomunicações?',
      questoes: genericQuestions2,
    }),
    TableQuestions({
      titulo:
          'O empreendimento afetará redes de distribuição de água ou de coleta de esgoto?',
      questoes: genericQuestions2,
    }),
  ];

  const dataImpactoAdministrativo = [
    TableQuestions({
      titulo:
          'O empreendedor possui a Licença Ambiental adequada de acordo com a atual fase do empreendimento.',
      questoes: mountedQuestions(
        'NA',
        'Possui e está válida',
        'Possui e está inválida',
        'Possui licença, porém é inadequada e está válida',
        'Possui licença, porém é inadequada e está inválida'
      ),
    }),
    TableQuestions({
      titulo:
          'O empreendedor cumpriu as condicionantes das Licenças Anteriores.',
      questoes: mountedQuestions(
        'Não se aplica por ser o primeiro processo',
        'Cumpriu 100% das condicionantes',
        'Cumpriu 50% das condicionantes',
        'Cumpriu 25 % das condicionantes',
        'Não cumpriu nenhuma condicionante'
      ),
    }),
    TableQuestions({
      titulo:
          'O empreendedor possui Auto de Infração Ambiental na esfera federal?',
      questoes: mountedQuestions(
        'Não possui auto de infração ambiental.',
        'Possui auto de infração com infração leve.',
        'Possui auto de infração com infração média.',
        'Possui auto de infração com infração grave.',
        'Possui auto de infração com infração gravíssima.'
      ),
    }),
    TableQuestions({
      titulo:
          'O empreendedor possui Auto de Infração Ambiental na esfera estadual?',
      questoes: mountedQuestions(
        'Não possui auto de infração ambiental.',
        'Possui auto de infração com infração leve.',
        'Possui auto de infração com infração média.',
        'Possui auto de infração com infração grave.',
        'Possui auto de infração com infração gravíssima.'
      ),
    }),
    TableQuestions({
      titulo:
          'O empreendedor possui algum Termo de Ajuste de Conduta Ambiental ou Termo Circunstanciado com objeto ambiental, e em caso positivo atendeu ao termo?',
      questoes: mountedQuestions(
        'Não possui TAC, TC ou não atende ao empreendimento.',
        'Poussui e atendeu 100%.',
        'Poussui e atendeu 50%.',
        'Poussui e atendeu 25%.',
        'Possui e não atendeu ao instrumento jurídico.'
      ),
    }),
    TableQuestions({
      titulo:
          'O empreendedor atende todas as normas técnicas e ambientais em vigor no tocante à sua atividade ou empreendimento.',
      questoes: mountedQuestions(
        'Sim, todos',
        'Quase todos',
        'Metade',
        'Quase nenhum',
        'Não'
      ),
    }),
    TableQuestions({
      titulo:
          'O empreendimento possui os estudos, planos e demais instrumentos necessários ao desenvolvimento de sua atividade.',
      questoes: mountedQuestions(
        'Sim, todos',
        'Quase todos',
        'Metade',
        'Quase nenhum',
        'Não'
      ),
    }),
    TableQuestions({
      titulo:
          'O empreendimento possui CAR/PRA (Cadastro Ambiental Rural e Plano de Recureção Ambiental)?',
      questoes: mountedQuestions(
        'Não se aplica.',
        'Possui CAR e está homologado. Possui PRA e está aprovado.',
        'Possui CAR e está homologado. Possui PRA e não está aprovado.',
        'Possui apenas o CAR e não está homologado.',
        'Não possui o CAR'
      ),
    }),
    TableQuestions({
      titulo: 'O empreendimento possui SIGEF?',
      questoes: mountedQuestions(
        'Não se aplica.',
        'Possui SIGEF e está homologado.',
        'Possui SIGEF e não está homologado.',
        'Possui apenas o Georeferenciamento da área.',
        'Não possui apenas o Georeferenciamento da área.'
      ),
    }),
  ];

  return (
    <div
      aria-labelledby="custom-tabs-two-meio-socioeconomico-tab"
      className="tab-pane fade"
      id="custom-tabs-two-meio-socioeconomico"
      role="tabpanel"
    >
      <div className="row">
        <div className="col-md-2">
          <div
            aria-orientation="vertical"
            className="nav flex-column nav-tabs h-100"
            id="v-pills-tab"
            role="tablist"
          >
            <a
              aria-controls="v-pills-home"
              aria-selected="true"
              className="nav-link active"
              data-toggle="pill"
              href="#v-pills-nucleo-populacional"
              id="v-pills-home-tab1"
              role="tab"
            >
              Núcleo Populacional
            </a>
            <a
              aria-controls="v-pills-profile"
              aria-selected="false"
              className="nav-link"
              data-toggle="pill"
              href="#v-pills-social-cultural"
              id="v-pills-profile-agua"
              role="tab"
            >
              Social e Cultural
            </a>
            <a
              aria-controls="v-pills-profile"
              aria-selected="false"
              className="nav-link"
              data-toggle="pill"
              href="#v-pills-aministrativo"
              id="v-pills-profile-agua"
              role="tab"
            >
              Administrativo
            </a>
          </div>
        </div>
        <div className="col-10">
          <div className="tab-content" id="v-pills-tabContent">
            <div
              aria-labelledby="v-pills-home-tab"
              className="tab-pane fade show active"
              id="v-pills-nucleo-populacional"
              role="tabpanel"
            >
              {Impacto(
                'NÚCLEO POPULACIONAL',
                dataImpactoNucleoPopulacional.map(table => table),
                ''
              )}
            </div>
            <div
              aria-labelledby="v-pills-profile-tab"
              className="tab-pane fade"
              id="v-pills-social-cultural"
              role="tabpanel"
            >
              {Impacto(
                'SOCIAL E CULTURAL',
                dataImpactoSocialCultural.map(table => table),
                ''
              )}
            </div>
            <div
              aria-labelledby="v-pills-profile-tab"
              className="tab-pane fade"
              id="v-pills-aministrativo"
              role="tabpanel"
            >
              {Impacto(
                'ADMINISTRATIVO',
                dataImpactoAdministrativo.map(table => table),
                'Infringência aos aspectos administrativos e legais com relação à legislação ambiental.'
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeioSocioeconomico;
