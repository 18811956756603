import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FaRegEdit } from 'react-icons/fa';
import { Modal } from 'react-bootstrap';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { Loading } from '~/components';
import plugins from 'suneditor/src/plugins';
import {
  showNotaTecAmbiental,
  storeNotaTecAmbiental,
  updateNotaTecAmbiental,
} from '~/store/modules/nota_tec_ambiental/action';
import Swal from 'sweetalert2';
import { FaPrint } from 'react-icons/fa';
import { BlobProvider } from '@react-pdf/renderer';
import NotaTecAmbientalPDF from './PDF';

const NotaTecAmbiental = ({
  processo_id,
  setNotaTecIsOk,
  onlyView,
  isRef = false,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  let [show, setShow] = useState(false);
  let [analise, setAnalise] = useState(false);
  let [notaTecAmbiental, setNotaTecAmbiental] = useState();

  const { nota_tec_ambiental } = useSelector(
    ({ NotaTecAmbientalReducer }) => NotaTecAmbientalReducer
  );
  const { processo } = useSelector(({ ProcessoReducer }) => ProcessoReducer);

  const buttonList = [
    ['undo', 'redo'],
    ['font', 'fontSize', 'formatBlock'],
    ['paragraphStyle', 'blockquote'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    ['fontColor', 'hiliteColor', 'textStyle'],
    ['removeFormat'],
    '/', // Line break
    ['outdent', 'indent'],
    ['align', 'horizontalRule', 'list', 'lineHeight'],
    ['table', 'link', 'image'], // You must add the 'katex' library at options to use the 'math' plugin.
    ['fullScreen', 'showBlocks', 'codeView'],
    ['preview'],
  ];

  const verificationData = nota_tec_ambiental[0]?.analise;

  useEffect(() => {
    if (processo_id) {
      dispatch(showNotaTecAmbiental(processo_id));
    }
  }, [processo_id, dispatch]);

  useEffect(() => {
    if (nota_tec_ambiental && nota_tec_ambiental[0]) {
      setNotaTecAmbiental(nota_tec_ambiental[0]);
      setAnalise(nota_tec_ambiental[0].analise);
    }
  }, [nota_tec_ambiental]);

  const handleClose = async () => {
    await setShow(false);
    await history.go();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!notaTecAmbiental) {
      await dispatch(
        storeNotaTecAmbiental({
          processo_id,
          analise,
        })
      );
      await Swal.fire('Sucesso', 'Nota técnica salva com sucesso!', 'success');
      await history.go();
    } else {
      await dispatch(
        updateNotaTecAmbiental(
          {
            analise,
          },
          notaTecAmbiental.id
        )
      );
      await Swal.fire('Sucesso', 'Nota técnica salva com sucesso!', 'success');
    }
  };

  useEffect(() => {
    if (notaTecAmbiental) {
      setNotaTecIsOk(true);
    }
  }, [notaTecAmbiental, setNotaTecIsOk]);

  return (
    <>
      {nota_tec_ambiental ? (
        <>
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h3 className="card-title w-100">Nota Técnica Ambiental</h3>
              {verificationData && (
                // <a
                //   className="btn btn-info d-flex flex-row align-items-center"
                //   href={`/nota-tecnica/${processo_id}/imprimir`}
                //   rel="noopener noreferrer"
                //   target="_blank"
                // >
                //   <FaPrint style={{ marginRight: 5 }} /> Imprimir
                // </a>
                <BlobProvider document={
                      <NotaTecAmbientalPDF
                          process={processo}
                          nota_tec_ambiental={nota_tec_ambiental[0]}
                      />
                  }>
                      {({ url }) => (
                          <a
                              href={url}
                              target="_blank"
                              className="btn btn-info d-flex flex-row align-items-center"
                              rel="noopener noreferrer"
                          >
                              <FaPrint style={{ marginRight: 5 }} /> Imprimir
                          </a>
                      )}
                  </BlobProvider>
              )}
            </div>
            <div className="card-body">
              <button
                className={`btn ${
                  notaTecAmbiental ? 'btn-primary' : 'btn-success'
                } btn-block`}
                disabled={isRef}
                onClick={() => setShow(true)}
              >
                <FaRegEdit /> Nota Técnica Ambiental
              </button>
            </div>
          </div>
          <Modal
            animation
            aria-labelledby="example-custom-modal-styling-title"
            dialogClassName="modal-xl"
            onHide={handleClose}
            show={show}
          >
            <Modal.Header closeButton>
              <Modal.Title>Nota Técnica Ambiental</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form id="form-nota-tec" onSubmit={handleSubmit}>
                <label>ANÁLISE AMBIENTAL</label>
                <SunEditor
                  disable={onlyView}
                  lang="pt_br"
                  name="analise"
                  onChange={setAnalise}
                  setContents={analise}
                  setOptions={{
                    plugins: [plugins],
                    height: 400,
                    buttonList,
                  }}
                />
              </form>
            </Modal.Body>
            <Modal.Footer>
              {!onlyView && (
                <button
                  className="btn btn-success"
                  form="form-nota-tec"
                  type="submit"
                >
                  {' '}
                  <i className="fa fa-save" /> Salvar
                </button>
              )}
              <button
                className="btn btn-default"
                onClick={handleClose}
                type="button"
              >
                <i className="fa fa-window-close" /> Fechar
              </button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};
export default NotaTecAmbiental;
