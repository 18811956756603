import * as actions from './actionType';

export const loadEntidades = (params) => ({
  type: actions.LOAD_ENTIDADE,
  params: params
});

export const getEntidades = entidades => ({
  type: actions.ENTIDADE_DATA,
  entidades,
});

export const storeEntidade = entidade => ({
  type: actions.STORE_ENTIDADE,
  entidade,
});

export const getEntidade = entidade => ({
  type: actions.GET_ENTIDADE,
  entidade,
});

export const updateEntidade = (id, entidade) => ({
  type: actions.UPDATE_ENTIDADE,
  id, entidade
});

export const ativarDesativarEntidade = id => ({
  type: actions.ATIVAR_DESATIVAR_ENTIDADE,
  id
});

export const getErros = errors => ({
  type: actions.ENTIDADE_ERROR,
  errors,
});

export const showEntidade = (id, params) => ({
  type: actions.SHOW_ENTIDADE,
  payload:{
    id:id,
    params:params
  }
});

export const changeStatus = status => ({
  type: actions.CHANGE_STATUS_CODE,
  status,
});

export const registerFinancialAccount = (id, password) => ({
  type: actions.REGISTER_FINANCIAL_ACCOUNT,
  id, password
});

export const changeFinancialPassword = (id, password, new_password, new_password_confirmation) => ({
  type: actions.CHANGE_FINANCIAL_PASSWORD,
  id, password, new_password, new_password_confirmation
});

// export const makeFinancialAccount = id => ({
//   type: actions.MAKE_FINANCIAL_ACCOUNT,
//   id
// });
