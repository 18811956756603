import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SunEditor from 'suneditor-react';
import plugins from 'suneditor/src/plugins';
import 'suneditor/dist/css/suneditor.min.css';
import Swal from 'sweetalert2';
import { FaPrint } from 'react-icons/fa';
import {
    storeRelatorioVistoria,
    updateRelatorioVistoria,
} from '~/store/modules/relatorio_vistoria/action';
import { BlobProvider } from '@react-pdf/renderer';
import RelatorioVistoriaTecnicaPDF from './PDF';
import { load } from '~/services/FotoVistoria';

const buttonList = [
    ['undo', 'redo'],
    ['font', 'fontSize', 'formatBlock'],
    ['paragraphStyle', 'blockquote'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    ['fontColor', 'hiliteColor', 'textStyle'],
    ['removeFormat'],
    '/', // Line break
    ['outdent', 'indent'],
    ['align', 'horizontalRule', 'list', 'lineHeight'],
    ['table', 'link'], // You must add the 'katex' library at options to use the 'math' plugin.
    ['fullScreen', 'showBlocks', 'codeView'],
    ['preview'],
];

export default function Form({ vistoria, handleClose, onlyView }) {
    const dispatch = useDispatch();
    const [parecer, setParecer] = useState('');
    const [objetivo, setObjetivo] = useState('');
    const [baseLegal, setBaseLegal] = useState('');
    const [descricao, setDescricao] = useState('');
    const [relatorio, setRelatorio] = useState('');
    const [irregularidade, setIrregularidade] = useState('');
    const [naoConformidade, setNaoConformidade] = useState('');
    const [images, setImages] = useState([]);
    // const [barometroSustentabilidade, setBarometroSustentabilidade] = useState('');
    const { processo } = useSelector(({ ProcessoReducer }) => ProcessoReducer);

    useEffect(() => {
        if (vistoria.relatorio && vistoria.relatorio.id) {
            setObjetivo(vistoria.relatorio.objetivo || '');
            setDescricao(vistoria.relatorio.descricao || '');
            setRelatorio(vistoria.relatorio.relatorio || '');
            setNaoConformidade(vistoria.relatorio.nao_conformidade || '');
            setIrregularidade(vistoria.relatorio.irregularidade || '');
            setBaseLegal(vistoria.relatorio.base_legal || '');
            setParecer(vistoria.relatorio.parecer || '');
            // setBarometroSustentabilidade(vistoria.relatorio.barometro_sustentabilidade);
        }
    }, [vistoria.relatorio]);

    useEffect(() => {
        if (vistoria.relatorio) {
            load(vistoria.relatorio?.id)
                .then((response) => {
                    setImages(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [vistoria.relatorio]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        let dataForm = {
            processo_id: vistoria.processo_id,
            vistoria_id: vistoria.id,
            objetivo,
            descricao,
            relatorio,
            nao_conformidade: naoConformidade,
            irregularidade,
            base_legal: baseLegal,
            // barometro_sustentabilidade: barometroSustentabilidade,
            parecer,
        };
        if (vistoria.relatorio && vistoria.relatorio.id) {
            await dispatch(
                updateRelatorioVistoria(dataForm, vistoria.relatorio.id)
            );
            await Swal.fire(
                'Sucesso',
                'Relatório salvo com sucesso!',
                'success'
            );
        } else {
            await dispatch(storeRelatorioVistoria(dataForm));
            await Swal.fire(
                'Sucesso',
                'Relatório salvo com sucesso!',
                'success'
            );
            // await handleClose();
        }
    };

    return (
        <div className="table-responsive">
            {vistoria.relatorio && vistoria.relatorio.id && (
                <div className="d-flex justify-content-end pt-2 pb-2">
                    {/* <a
            className="btn btn-info d-flex flex-row align-items-center text-white"
            href={`/relatorio/${vistoria.processo_id}/imprimir/${vistoria.relatorio.id}`}
            rel="noopener noreferrer"
            target="_blank"
            >
            <FaPrint style={{ marginRight: 5 }} /> Imprimir
          </a> */}
                    <BlobProvider
                        document={
                            <RelatorioVistoriaTecnicaPDF
                                process={processo}
                                vistoria={vistoria.relatorio}
                                images={images}
                            />
                        }
                    >
                        {({ url }) => (
                            <a
                                href={url}
                                target="_blank"
                                className="btn btn-info d-flex flex-row align-items-center"
                                rel="noopener noreferrer"
                            >
                                <FaPrint style={{ marginRight: 5 }} /> Imprimir
                            </a>
                        )}
                    </BlobProvider>
                </div>
            )}
            <form onSubmit={handleSubmit}>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>OBJETIVO DA ANÁLISE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <SunEditor
                                    disable={onlyView}
                                    lang="pt_br"
                                    name="objetivo"
                                    onChange={(val) => setObjetivo(val)}
                                    setContents={objetivo}
                                    setOptions={{
                                        plugins: [plugins],
                                        height: 400,
                                        buttonList,
                                    }}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>DESCRIÇÃO DA ATIVIDADE OU EMPREENDIMENTO</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <SunEditor
                                    disable={onlyView}
                                    lang="pt_br"
                                    name="descricao"
                                    onChange={(val) => setDescricao(val)}
                                    setContents={descricao}
                                    setOptions={{
                                        plugins: [plugins],
                                        height: 400,
                                        buttonList,
                                    }}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>RELATÓRIO DA VISTÓRIA TÉCNICA AMBIENTAL</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <SunEditor
                                    disable={onlyView}
                                    lang="pt_br"
                                    name="relatorio"
                                    onChange={(val) => setRelatorio(val)}
                                    setContents={relatorio}
                                    setOptions={{
                                        plugins: [plugins],
                                        height: 400,
                                        buttonList,
                                    }}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>NÃO-CONFORMIDADE ENCONTRADA</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <SunEditor
                                    disable={onlyView}
                                    lang="pt_br"
                                    name="nao_conformidade"
                                    onChange={(val) => setNaoConformidade(val)}
                                    setContents={naoConformidade}
                                    setOptions={{
                                        plugins: [plugins],
                                        height: 400,
                                        buttonList,
                                    }}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>IRREGULARIDADES ENCONTRADAS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <SunEditor
                                    disable={onlyView}
                                    lang="pt_br"
                                    name="irregularidade"
                                    onChange={(val) => setIrregularidade(val)}
                                    setContents={irregularidade}
                                    setOptions={{
                                        plugins: [plugins],
                                        height: 400,
                                        buttonList,
                                    }}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>BASE LEGAL APLICADA</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <SunEditor
                                    disable={onlyView}
                                    lang="pt_br"
                                    name="base_legal"
                                    onChange={(val) => setBaseLegal(val)}
                                    setContents={baseLegal}
                                    setOptions={{
                                        plugins: [plugins],
                                        height: 400,
                                        buttonList,
                                    }}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                {/* <table className="table table-bordered">
            <thead>
            <tr>
                <th className="table-danger">BARÔMETRO DA SUSTENTABILIDADE</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>
                <SunEditor
                    lang="pt_br"
                    name="barometro_sustentabilidade"
                    setContents={barometroSustentabilidade}
                    onChange={(val) => setBarometroSustentabilidade(val)}
                    setOptions={{
                    plugins: [plugins],
                    height: 400,
                    buttonList
                    }} />
                </td>
            </tr>
            </tbody>
            </table> */}
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>PARECER</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <SunEditor
                                    disable={onlyView}
                                    lang="pt_br"
                                    name="parecer"
                                    onChange={(val) => setParecer(val)}
                                    setContents={parecer}
                                    setOptions={{
                                        plugins: [plugins],
                                        height: 400,
                                        buttonList,
                                    }}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="btn-group float-right">
                    {!onlyView && (
                        <button className="btn btn-success">
                            {' '}
                            <i className="fa fa-save" /> Salvar
                        </button>
                    )}
                    <button
                        className="btn btn-default"
                        onClick={handleClose}
                        type="button"
                    >
                        <i className="fa fa-window-close" /> Fechar
                    </button>
                </div>
            </form>
        </div>
    );
}
