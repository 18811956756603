import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaPlus, FaList } from 'react-icons/fa';
import { ProgressBar } from './styles';
import { Loading, Paginate } from '~/components';
import { BasicContent } from '../components';
import { loadProcessos } from '~/store/modules/processo/action';

export default function List() {
  const dispatch = useDispatch();
  const { processos, meta } = useSelector(
    ({ ProcessoReducer }) => ProcessoReducer
  );

  useEffect(() => {
    dispatch(
      loadProcessos({
        with: 'status_processo',
        order_by: 'created_at:desc'
      })
    );
  }, [dispatch]);

  const breadcrumb = {
    label: 'Lista',
    to: '/processos',
    active: false,
  };

  const formatDataHora = (data_hora) => {
    let data_hora_raw = data_hora.split(' ');
    let data_format = data_hora_raw[0].split('-');
    let hora = data_hora_raw[1];
    let data = `${data_format[2]}/${data_format[1]}/${data_format[0]}`;
    return `${data} ${hora}`;
  };

  return (
    <>
      <BasicContent breadcrumb={breadcrumb}>
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">
              <FaList /> PROCESSOS
            </h3>
            <div className="card-tools">
              <Link
                className="btn  btn-success"
                to="/processos/create/pass_1"
              >
                <FaPlus /> Novo Processo
              </Link>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-bordered table-sm table-striped">
                <thead>
                  <tr>
                    <th> N. processo </th>
                    <th>Data Abertura</th>
                    <th>Progresso</th>
                    <th> Status </th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {processos.data &&
                    processos.data.map(
                      (
                        { id, protocolo, passo, created_at, status_processo },
                        index
                      ) => {
                        return (
                          <tr key={index}>
                            <td>{protocolo}</td>
                            <td>{formatDataHora(created_at)}</td>
                            <td className="project_progress">
                              <div className="progress progress-sm">
                                <ProgressBar
                                  ariaVolumemax="100"
                                  ariaVolumemin="0"
                                  ariaVolumenow={(passo*100)/20}
                                  className="progress-bar bg-green"
                                  percents={(passo*100)/20}
                                  role="progressbar"
                                />
                              </div>
                              <small>{(passo*100)/20}% completo</small>
                            </td>
                            <td>
                              <span className="badge badge-success">
                                {status_processo.nome}
                              </span>
                            </td>
                            <td>
                              <div className="btn-group">
                                {passo < 5 && (
                                  <Link
                                    className="btn btn-sm btn-info"
                                    to={`/processos/create/pass_${passo}/${id}`}
                                  >
                                    Continuar Processo
                                  </Link>
                                )}
                                {(status_processo.id > 1) && (
                                  <Link
                                    className="btn btn-info"
                                    to={`/processos/${id}/detalhe`}
                                  >
                                    Detalhes
                                  </Link>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    )}
                </tbody>
              </table>
              {!processos.data && <Loading />}
            </div>
          </div>
          <div className="card-footer">
            <Paginate
              load={() => loadProcessos({
                with: 'status_processo',
                order_by: 'created_at:desc'
              })}
              meta={meta}
            />
          </div>
        </div>
      </BasicContent>
    </>
  );
}
