import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import FileInput from '~/components/FileInput';
import formatPhone from '~/utils/formatPhone';

export default function EnvironmentalManagement({
  entity, setEntity, errorsForm, handleChange
}) {
  return (
    <fieldset>
      <legend>GESTÃO AMBIENTAL</legend>
      <Row>
        {/* Governador */}
        <Col md="6">
          <div className="form-group">
            <label htmlFor="nome_governador">Governador</label>
            <Form.Control
              id="nome_governador"
              isInvalid={!!errorsForm.nome_governador}
              name="nome_governador"
              onChange={handleChange}
              placeholder="Qual o nome do governador?"
              type="text"
              value={entity.nome_governador ?? ''}
            />
            <Form.Control.Feedback type="invalid">
              {errorsForm.nome_governador}
            </Form.Control.Feedback>
          </div>
        </Col>

        {/* Prefeito */}
        <Col md="6">
          <div className="form-group">
            <label htmlFor="nome_prefeito">Prefeito</label>
            <Form.Control
              id="nome_prefeito"
              isInvalid={!!errorsForm.nome_prefeito}
              name="nome_prefeito"
              onChange={handleChange}
              placeholder="Qual o nome do prefeito?"
              type="text"
              value={entity.nome_prefeito ?? ''}
            />
            <Form.Control.Feedback type="invalid">
              {errorsForm.nome_prefeito}
            </Form.Control.Feedback>
          </div>
        </Col>

        {/* Nome do órgão ambiental */}
        <Col md="4">
          <div className="form-group">
            <label htmlFor="nome_orgao_ambiental">
              Órgão Ambiental
            </label>
            <Form.Control
              id="nome_orgao_ambiental"
              isInvalid={!!errorsForm.nome_orgao_ambiental}
              name="nome_orgao_ambiental"
              onChange={handleChange}
              placeholder="Qual o nome do órgão ambiental?"
              type="text"
              value={entity.nome_orgao_ambiental ?? ''}
            />
            <Form.Control.Feedback type="invalid">
              {errorsForm.nome_orgao_ambiental}
            </Form.Control.Feedback>
          </div>
        </Col>

        {/* Nome do gestor ambiental */}
        <Col md="4">
          <div className="form-group">
            <label htmlFor="nome_gestor_ambiental">
              Gestor Ambiental
            </label>
            <Form.Control
              id="nome_gestor_ambiental"
              isInvalid={!!errorsForm.nome_gestor_ambiental}
              name="nome_gestor_ambiental"
              onChange={handleChange}
              placeholder="Qual o nome do gestor ambiental?"
              type="text"
              value={entity.nome_gestor_ambiental ?? ''}
            />
            <Form.Control.Feedback type="invalid">
              {errorsForm.nome_gestor_ambiental}
            </Form.Control.Feedback>
          </div>
        </Col>

        {/* Função do gestor ambiental */}
        <Col md="4">
          <div className="form-group">
            <label htmlFor="funcao_gestor_ambiental">
              Função do Gestor
            </label>
            <Form.Control
              id="funcao_gestor_ambiental"
              isInvalid={!!errorsForm.funcao_gestor_ambiental}
              name="funcao_gestor_ambiental"
              onChange={handleChange}
              placeholder="Qual a função do gestor?"
              type="text"
              value={entity.funcao_gestor_ambiental ?? ''}
            />
            <Form.Control.Feedback type="invalid">
              {errorsForm.funcao_gestor_ambiental}
            </Form.Control.Feedback>
          </div>
        </Col>

        {/* Site da secretaria */}
        <Col>
          <div className="form-group">
            <label htmlFor="site_secretaria">
              Site da Secretaria
            </label>
            <Form.Control
              id="site_secretaria"
              isInvalid={!!errorsForm.site_secretaria}
              name="site_secretaria"
              onChange={handleChange}
              placeholder="Site secretaria"
              type="text"
              value={entity.site_secretaria ?? ''}
            />
            <Form.Control.Feedback type="invalid">
              {errorsForm.site_secretaria}
            </Form.Control.Feedback>
          </div>
        </Col>

        {/* Telefone da secretaria */}
        <Col>
          <div className="form-group">
            <label htmlFor="telefone_secretaria">
              Telefone da Secretaria
            </label>
            <Form.Control
              id="telefone_secretaria"
              isInvalid={!!errorsForm.telefone_secretaria}
              name="telefone_secretaria"
              onChange={handleChange}
              placeholder="Telefone"
              type="text"
              maxLength="15"
              value={formatPhone(entity.telefone_secretaria ?? '')}
            />
            <Form.Control.Feedback type="invalid">
              {errorsForm.telefone_secretaria}
            </Form.Control.Feedback>
          </div>
        </Col>

        {/* Assinatura do secretário */}
        <Col>
          <div className="form-group">
            <label htmlFor="assinatura_secretario">
              Assinatura do Secretário
              {!!entity.assinatura_secretario &&
                ' (Já Cadastrada)'}
            </label>
            <FileInput
              data={entity}
              isInvalid={errorsForm.anexo_assinatura}
              dataError={errorsForm.anexo_assinatura}
              name="assinatura_secretario"
              id="assinatura_secretario"
              setData={setEntity}
            />
            <small style={{ fontWeight: 600 }}>
              <span style={{ color: '#F00' }}>*</span>
              Imagem deve ser PNG e sem fundo
            </small>
          </div>
        </Col>
      </Row>
    </fieldset>
  );
}
