import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Content } from '~/layouts/Dash/components';
import {
  Row, Col, Card, Table,
  Form, Button
} from 'react-bootstrap';
// import Select from 'react-select';
import { Select } from '~/components/Form';
import { FiFilter } from 'react-icons/fi';
import { apiWithToken } from '~/services/api';
import Swal from 'sweetalert2';
import { BlobProvider } from '@react-pdf/renderer';
import { loadUsersAsOptions } from '~/services/User/User';
import formatDataHora from '~/utils/formatDataHora';
import RPLAPDF from './PDF';

const breadcrumbs = [
  {
    label: 'Relatório',
    to: '/relatorios',
    active: false,
  }, {
    label: 'Relatório de Produção de Licenciamento Ambiental',
    to: '/relatorios/relatorio-producao-licenciamento-ambiental',
    active: true,
  }
];

export default function RPLA() {
  const token = localStorage.getItem('@token');
  const { user_auth: { user } } = useSelector(({ LoginReducer }) => LoginReducer);
  let api = apiWithToken(token);
  const [params, setParams] = useState({});
  const [period, setPeriod] = useState('');
  const [entityName, setEntityName] = useState('');
  const [organName, setOrganName] = useState('');
  const [logoSiblam, setLogoSiblam] = useState('');
  const [logoEntity, setLogoEntity] = useState('');
  const [data, setData] = useState([]);
  const [evaluatorsTechnicians, setEvaluatorsTechnicians] = useState([]);
  const [logoCounty, setLogoCounty] = useState('');

  const getUsersAsOptions = useCallback(
    async function () {
      const res = await loadUsersAsOptions({
        users_types: '5',
        rows_limit: 20,
        entity_id: user.entidade_id,
        value_field: 'id',
        label_field: 'nome'
      });

      setEvaluatorsTechnicians(res.data);
    }, [
      user.entidade_id
    ]
  )


  useEffect(() => {
    getUsersAsOptions();
  }, [getUsersAsOptions]);

  function handleChange({ target: { name, value } }) {
    setParams({
      ...params,
      [name]: value
    })
  }

  async function getRPLA(event) {
    event.preventDefault();

    try {
      const res = await api.get(
        'relatorio-producao-licenciamento-ambiental',
        { params }
      );

      const {
        period, entity_name,
        organ_name, logo_siblam,
        logo_entity, data, logo_county
      } = res.data;
      // console.log(data);

      setPeriod(period);
      setEntityName(entity_name);
      setOrganName(organ_name);
      setLogoSiblam(logo_siblam);
      setLogoEntity(logo_entity);
      setLogoCounty(logo_county);
      setData(data);
    } catch (error) {
      const err = error.response.data.errors;

      if (Object.keys(err).includes('users_ids')) {
        await Swal.fire(
          'Erro',
          'O campo Analista(s) é obrigatório',
          'error'
        );
      } else {
        await Swal.fire(
          'Erro',
          'Erro interno',
          'error'
        );
      }

      console.log(error.response);
    }
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Relatório de Produção de Licenciamento Ambiental"
    >
      <Row>
        <Col md="3">
          <Card>
            <Card.Header>
              <Card.Title>Filtros</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={getRPLA}>
                <Form.Group>
                  <Form.Label>Data Início</Form.Label>
                  <Form.Control
                    type="date"
                    name="begin_date"
                    onChange={handleChange}
                    required
                  >
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Data Fim</Form.Label>
                  <Form.Control
                    type="date"
                    name="end_date"
                    onChange={handleChange}
                    required
                  >
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Analista(s)</Form.Label>
                  <Select
                    name="users_ids"
                    options={evaluatorsTechnicians}
                    state={params}
                    setState={setParams}
                    required
                    isMulti
                  />
                </Form.Group>
                <Button
                  block
                  type="submit"
                  variant="light"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <FiFilter style={{ marginRight: '5px'}}/>
                  Filtrar
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col md="9">
          <Card>
            <Card.Header>
              <div
                className="card-tools"
                style={{
                  width: '100%',
                  display: 'flex',
                  marginRight: '0px',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Card.Title>Relatório</Card.Title>
                {data.length > 0 && (
                  <div className="btn-group">
                    <BlobProvider document={
                      <RPLAPDF
                        period={period}
                        entityName={entityName}
                        organName={organName}
                        logoSiblam={logoSiblam}
                        logoEntity={logoEntity}
                        logoCounty={logoCounty}
                        data={data}
                      />
                    }>
                      {({ url }) => (
                        <a
                          href={url}
                          target="_blank"
                          className="btn btn-default"
                          rel="noopener noreferrer"
                        >
                          <i className="fas fa-eye">&nbsp;</i>
                          Visualizar PDF
                        </a>
                      )}
                    </BlobProvider>
                  </div>
                )}
              </div>
            </Card.Header>
            <Card.Body>
              {!data.length && (
                <Table responsive bordered hover style={{ minWidth: '435px' }}>
                  <thead>
                    <tr>
                      <th>N°</th>
                      <th>N° Processo</th>
                      <th>Data Recebimento</th>
                      <th>Data Finalização</th>
                      <th>Dias de Análise</th>
                    </tr>
                  </thead>
                </Table>
              )}

              {data.map(({ evaluator, processes_analysis }, index) => (processes_analysis.length > 0 && (
                <>
                  {index > 0 && <hr/>}
                  <h5>{evaluator.nome}</h5>
                  <Table responsive bordered hover style={{ minWidth: '435px' }}>
                    <thead>
                      <tr>
                        <th>N°</th>
                        <th>N° Processo</th>
                        <th>Data Recebimento</th>
                        <th>Data Finalização</th>
                        <th>Dias de Análise</th>
                      </tr>
                    </thead>
                    <tbody>
                      {processes_analysis.map(({
                        protocolo,
                        data_hora_inicio,
                        data_hora_conclusao,
                        dias_analise
                      }, index) => (
                        <tr key={`analysis-process-${index + 1}`}>
                          <td>{index + 1}</td>
                          <td>{protocolo}</td>
                          <td>{formatDataHora(data_hora_inicio)}</td>
                          <td>{formatDataHora(data_hora_conclusao)}</td>
                          <td>{dias_analise}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )) || (processes_analysis.length === 0 && (
                <>
                  {index > 0 && <hr/>}
                  <h5>{evaluator.nome}</h5>
                  <Table responsive bordered hover style={{ minWidth: '435px' }}>
                    <thead>
                      <tr>
                        <th>N°</th>
                        <th>N° Processo</th>
                        <th>Data Recebimento</th>
                        <th>Data Finalização</th>
                        <th>Dias de Análise</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan={5}>
                          Sem Dados Para O Período
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </>
              )))}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Content>
  );
}
