import { apiWithToken } from '../api';

const token = localStorage.getItem('@token');
let api = apiWithToken(token);

const resouceUrl = '/entidade';

export const list_entidade = async (params) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let page = urlParams.has('page') ? { page: urlParams.get('page') } : {};
  let search = urlParams.has('search')
    ? { search: urlParams.get('search') }
    : {};
  return await api.get(`${resouceUrl}`, {
    params: {
      ...params,
      ...page,
      ...search,
    },
  });
};

export const show_entidade = async ({ id, params }) => {
  return await api.get(`${resouceUrl}/${id}`, {
    params: {
      ...params,
    },
  });
};

export const update = async (id, entidade) => {
  return await api.post(`${resouceUrl}/${id}`, entidade);
};

export const store_entidade = async (entidade) => {
  return await api.post(`${resouceUrl}`, entidade);
};

export const ativar_desativar_entidade = async (id) => {
  return await api.put(`${resouceUrl}/${id}/ativar_desativar_entidade`);
};

export const register_financial_account = async (id, password) => {
  return await api.post(
    `${resouceUrl}/${id}/registrar_conta_financeira`,
    password
  );
};

export const change_financial_password = async (
  id,
  password,
  new_password,
  new_password_confirmation
) => {
  return await api.post(
    `${resouceUrl}/${id}/mudar_senha_conta_financeira`,
    password,
    new_password,
    new_password_confirmation
  );
};

// export const make_financial_account = async (id) => {
//   return await api.put(`${resouceUrl}/${id}/criar_conta_financeira`);
// }
