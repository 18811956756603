import * as Yup from 'yup';
import { validateCPF, validatePhone } from 'validations-br';

const schema = Yup.object().shape({
  cpf: Yup.string()
    .test(
      "is-cpf",
      "CPF não é valido",
      (value) => validateCPF(value)
    )
    .required('CPF obrigatório'),
  anexo_cpf: Yup.string().required('Anexo do CPF obrigatório'),
  rg: Yup.string().required('RG obrigatório'),
  anexo_rg: Yup.string().required('Anexo do RG obrigatório'),
  uf_rg_id: Yup.number().required('UF obrigatório'),
  logradouro: Yup.string().required('Logradouro obrigatório'),
  anexo_endereco: Yup.string().required('Anexo do endereço obrigatório'),
  bairro: Yup.string().required('Bairro obrigatório'),
  cep: Yup.string().required('CEP obrigatório'),
  cidade: Yup.string().required('Cidade obrigatório'),
  numero_endereco: Yup.string().required("Nº obrigatório"),
  numero_whatsapp: Yup.string().nullable()
  .test(
    "is-numero_whatsapp",
    "Whatsapp não é valido",
    function(value) {
      if(!!value) {
        return validatePhone(value)
      }
      return true;
    }
    )
  ,
  telefone: Yup.string()
  .test(
    "is-telefone",
    "Fone de contato não é valido",
    (value) => validatePhone(value)
  )
  .required('Fone de contato obrigatório'),
});

export default schema;
