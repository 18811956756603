import * as actions from './actionType';

export const loadTipoUsers = (params) => ({
  type: actions.LOAD_TIPO_USERS,
  params
});

export const listCustomTipoUsers = () => ({
  type: actions.LIST_CUSTOM_TIPO_USER,
});

export const loadMenus = (id) => ({
  type: actions.LOAD_MENUS_TIPO_USER,
  id,
});

export const getMenus = (menus) => ({
  type: actions.GET_MENUS_TIPO_USER,
  menus,
});

export const addMenu = (id, menu_id) => ({
  type: actions.ADD_MENUS_TIPO_USER,
  id,
  menu_id,
});

export const loadRecursos = (id) => ({
  type: actions.LOAD_RECURSOS_TIPO_USER,
  id,
});

export const addRecurso = (id, recurso_id) => ({
  type: actions.ADD_RECURSOS_TIPO_USER,
  id,
  recurso_id,
});

export const getRecursos = (recursos) => ({
  type: actions.GET_RECURSOS_TIPO_USER,
  recursos,
});

export const getTipoUsers = (tipo_users) => ({
  type: actions.TIPO_USERS_DATA,
  tipo_users,
});
export const storeTipoUser = (tipo_user) => ({
  type: actions.STORE_TIPO_USER,
  tipo_user,
});

export const getTipoUser = (tipo_user) => ({
  type: actions.GET_TIPO_USER,
  tipo_user,
});

export const removerTipoUser = (id) => ({
  type: actions.REMOVER_TIPO_USER,
  id,
});

export const showTipoUser = (id) => ({
  type: actions.SHOW_TIPO_USER,
  id,
});

export const updateTipoUser = (tipo_user, id) => ({
  type: actions.UPDATE_TIPO_USER,
  tipo_user,
  id,
});

export const getErros = (errors) => ({
  type: actions.TIPO_USER_ERROR,
  errors,
});
