import React, { Fragment } from 'react';
import { View, Text, Image } from '@react-pdf/renderer';

export default function Footer({
  logoSiblam, styles
}) {
  return (
    <Fragment>
        <Text
        fixed
        style={{
            fontFamily: 'Inter',
            fontSize: '10px',
            alignSelf: 'flex-start',
            bottom: '30px',
            position: 'absolute',
            left: '50px'
        }}
        render={() => (
            'Emitido em: ' + new Date().toLocaleString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            })
        )}
      />
      <View style={styles.footer} fixed>
        {!!logoSiblam && (
          <Image src={logoSiblam} style={{ width: '120px' }}/>
        )}
      </View>
      <Text
        fixed
        style={styles.pagination}
        render={({ pageNumber, totalPages }) => (
          `Pág. ${pageNumber} de ${totalPages}`
        )}
      />
    </Fragment>
  );
}
