import * as actions from './actionType';

export const TipoMeioAnaliseReducer = (state = {select_tipo_meio_analises: [], tipo_meio_analises: {}, tipo_meio_analise: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.TIPO_MEIO_ANALISES_DATA:
      return {
        ...state,
        tipo_meio_analises: action.tipo_meio_analises.data,
        links: action.tipo_meio_analises.data.links,
        meta: action.tipo_meio_analises.data.meta,
      };
    case actions.SELECT_TIPO_MEIO_ANALISES:
      return {
        ...state,
        select_tipo_meio_analises: action.tipo_meio_analises.data
      };
    case actions.GET_TIPO_MEIO_ANALISE:
      return {
        ...state,
        tipo_meio_analise: action.tipo_meio_analise.data.data,
        status_response: action.tipo_meio_analise.status,
      };
    case actions.TIPO_MEIO_ANALISE_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    case actions.CHANGE_STATUS_CODE:
      return {
        ...state,
        status_response: action.status,
      };
    default:
      return state;
  }
}
