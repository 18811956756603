import * as actions from './actionType';

export const loadIndicadoresProcessos = (processo_id) => ({
  type: actions.LOAD_INDICADORES_PROCESSO,
  processo_id,
});
export const getIndicadoresProcessos = indicadores_processos => ({
  type: actions.INDICADORES_PROCESSO_DATA,
  indicadores_processos,
});
export const storeIndicadorProcesso = indicador_processo => ({
  type: actions.STORE_INDICADOR_PROCESSO,
  indicador_processo,
});
export const getIndicadorProcesso = indicador_processo => ({
  type: actions.GET_INDICADOR_PROCESSO,
  indicador_processo,
});

export const getErros = errors => ({
  type: actions.INDICADOR_PROCESSO_ERROR,
  errors,
});


export const removerIndicadorProcesso = (id) => ({
  type: actions.REMOVER_INDICADOR_PROCESSO,
  id
});


export const changeStatus = status => ({
  type: actions.CHANGE_STATUS_CODE,
  status,
});
