import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FaArrowAltCircleLeft,
  FaArrowAltCircleRight,
  FaCircleNotch,
} from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import { Form, Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import intialDataEndereco from './initialData';
import schema from './schemaValidations';
import {
  SelectEstado,
  SelectMunicipio,
  SelectEmpreendimento,
} from '../../Forms';
import buscaCep from '~/services/cep';
import {
  storeProcesso,
  changeStatus,
  listaProcessos,
} from '~/store/modules/processo/action';
import SelectMultiple from '../../../../../../components/SelectMultiple';
import { useCallback } from 'react';
import maskCEP from '~/utils/formatCep'

export default function Passo1() {
  let history = useHistory();
  const dispatch = useDispatch();
  const [empreendimento, setEmpreendimento] = useState();
  const [estado, setEstado] = useState();
  const [endereco, setEndereco] = useState(intialDataEndereco);
  const [processosReferencia, setProcessosReferencia] = useState([]);
  const [errors, setErrors] = useState({});
  const [municipio, setMunicipio] = useState();
  const [loading, setLoading] = useState(false);
  const { processo, status_code, lista_processos } = useSelector(
    ({ ProcessoReducer }) => ProcessoReducer
  );
  const [isReference, setIsReference] = useState(false);
  const [listaOpcoes, setListaOpcoes] = useState();
  const [errorInReference, setErrorInReferences] = useState(null);

  const [dataForm, setDataForm] = useState({
    passo: parseInt(2),
    pessoa_id: null,
    estado_id: null,
    municipio_id: null,
    cep: '',
    logradouro: '',
    bairro: '',
    numero_endereco: '',
    complemento: '',
    isReference: false,
    references: [],
  });

  useEffect(() => {
    if (status_code === 201) {
      Swal.fire('Sucesso', 'Processo iniciado com sucesso!', 'success').then(
        () => {
          dispatch(changeStatus(null));
          history.push(`/processos/create/pass_2/${processo.id}`);
        }
      );
    }
  }, [dispatch, history, processo.id, status_code]);

  const handleChange = (event) => {
    const { name, value, files } = event.target;
    setEndereco({
      ...endereco,
      [name]: files ? files[0] : value,
    });
  };

  useEffect(() => {
    if (endereco) {
      setErrors({});
      setDataForm((dataForm) => ({
        ...dataForm,
        cep: endereco.cep,
        logradouro: endereco.logradouro,
        bairro: endereco.bairro,
        numero_endereco: endereco.numero_endereco,
        complemento: endereco.complemento,
      }));
    }
  }, [endereco]);

  useEffect(() => {
    if (empreendimento) {
      setDataForm((dataForm) => ({
        ...dataForm,
        pessoa_id: empreendimento.value,
      }));
    }
  }, [empreendimento]);

  useEffect(() => {
    if (estado) {
      setDataForm((dataForm) => ({
        ...dataForm,
        estado_id: estado.value,
        municipio_id: null,
      }));
    }
  }, [estado]);

  useEffect(() => {
    if (municipio && municipio.value) {
      setDataForm((dataForm) => ({
        ...dataForm,
        municipio_id: municipio.value,
      }));
    }
  }, [municipio]);

  useEffect(() => {
    if (isReference && processosReferencia) {
      let dataRef = [];

      processosReferencia.map((processo) => dataRef.push(processo.value));

      setDataForm((dataForm) => ({
        ...dataForm,
        isReference,
        references: dataRef,
      }));
    }
  }, [isReference, processosReferencia]);

  const handleBuscaCep = async (event) => {
    const { value } = event.target;
    const cep = value;
    try {
      const data = await buscaCep.get(`${cep}/json`);
      const endereco_response = data.data;
      const finded = {
        logradouro: endereco_response.logradouro,
        bairro: endereco_response.bairro,
        cidade: endereco_response.localidade,
      };
      Object.assign(endereco, finded);
      setEndereco({
        ...endereco,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleCountCaracteres = () => {
    let maxlength = document.getElementById('complemento').maxLength;
    let countCaractere = endereco.complemento.length;

    return maxlength - countCaractere;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (isReference && !dataForm.references.length > 0) {
      // console.log(dataForm.references);
      setErrorInReferences('Selecione um ou mais processos como referência');
      setLoading(false);
      return;
    }
    if (!schema.isValidSync(endereco)) {
      let yup_errors = {};
      schema.validate(endereco, { abortEarly: false }).catch((err) => {
        for (let error of err.inner) {
          const { path, message } = error;
          Object.assign(yup_errors, { [path]: message });
        }
        setErrors(yup_errors);
      });
      setLoading(false);
    }else{
      // if (!dataForm.cep || !dataForm.bairro || !dataForm.logradouro) {
      //   throw new Error();
      // }
      await dispatch(storeProcesso(dataForm));
    }

  };

  const handleGetProcessForUser = useCallback(async () => {
    try {
      await dispatch(listaProcessos());
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    handleGetProcessForUser();
  }, [handleGetProcessForUser]);

  useEffect(() => {
    if (lista_processos) {
      const lista = lista_processos?.map((processo) => ({
        value: processo.id,
        label: `${processo?.cpf_cnpj}/${processo?.protocolo}`,
      }));
      setListaOpcoes(lista);
    }
  }, [lista_processos]);

  const handleSelectIsReference = (value) => {
    setProcessosReferencia(value);

    if (errorInReference !== null) {
      setErrorInReferences(null);
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Passo 1 - Escolha o Estado e Município</h3>
        <div className="card-tools">
          <Link className="btn btn-light" to="/processos">
            <FaArrowAltCircleLeft /> Cancelar
          </Link>
        </div>
      </div>
      <div className="card-body">
        <form id="form_start_processo" onSubmit={handleSubmit}>
          <SelectEmpreendimento setEmpreendimento={setEmpreendimento} />
          <div style={{ margin: '10px 0px' }}>
            <Form.Check
              label="Incluir processo como referência? (Digite abaixo o número de processo já licenciado no SIBLAM, que terá vínculo com este novo processo)."
              onChange={() => setIsReference(!isReference)}
              type="checkbox"
              value={isReference}
            />

            {isReference && (
              <div style={{ marginTop: 10 }}>
                <SelectMultiple
                  isLoading={false}
                  name="hasReferencia"
                  onChange={handleSelectIsReference}
                  options={listaOpcoes}
                  value={processosReferencia}
                />
              </div>
            )}
            {errorInReference !== null && (
              <p className="text-danger">{errorInReference}</p>
            )}
          </div>
          <SelectEstado setEstado={setEstado} />
          {estado && (
            <>
              <SelectMunicipio
                estado_id={estado.value}
                setMunicipio={setMunicipio}
              />

              <Form.Label>Endereço da atividade</Form.Label>
              <div style={{ border: '1px solid black', borderRadius: '3px',padding: '15px 20px', margin: '10px 0px' }}>
                <Form.Group as={Row}>
                  <Col>
                    <Form.Label>CEP</Form.Label>
                    <Form.Control
                      isInvalid={!!errors.cep}
                      name="cep"
                      onBlur={handleBuscaCep}
                      onChange={handleChange}
                      placeholder="CEP"
                      type="text"
                      maxLength="9"
                      value={maskCEP(endereco.cep ?? '') || ''}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.cep}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm={8}>
                    <Form.Label>Bairro</Form.Label>
                    <Form.Control
                      isInvalid={!!errors.bairro}
                      name="bairro"
                      onChange={handleChange}
                      placeholder="Bairro"
                      type="text"
                      value={endereco.bairro || ''}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.bairro}
                    </Form.Control.Feedback>
                  </Col>
                  <Col sm={4}>
                    <Form.Label>Número</Form.Label>
                    <Form.Control
                      isInvalid={!!errors.numero_endereco}
                      name="numero_endereco"
                      onChange={handleChange}
                      placeholder="Número"
                      type="text"
                      value={endereco.numero_endereco || ''}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.numero_endereco}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Logradouro</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.logradouro}
                    name="logradouro"
                    onChange={handleChange}
                    placeholder="Logradouro"
                    type="text"
                    value={endereco.logradouro || ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.logradouro}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Complemento</Form.Label>
                  <Form.Control
                    id="complemento"
                    isInvalid={!!errors.complemento}
                    maxLength={50}
                    name="complemento"
                    onChange={handleChange}
                    placeholder="complemento"
                    type="text"
                    value={endereco.complemento || ''}
                  />
                  {endereco.complemento && (
                    <p className="text-secondary mt-1">
                      Quantidade de caracteres restantes:{' '}
                      <strong>
                        {handleCountCaracteres(endereco.complemento.length)}
                      </strong>
                    </p>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.complemento}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              {dataForm.pessoa_id &&
                dataForm.estado_id &&
                dataForm.municipio_id && (
                  <div className="form-group">
                    <Link
                      className="btn btn-light"
                      to="/processos/create/pass_1"
                    >
                      <FaArrowAltCircleLeft /> Voltar
                    </Link>
                    <button
                      className="btn btn-success"
                      disabled={loading}
                      form="form_start_processo"
                    >
                      {loading ? (
                        <FaCircleNotch className="spinner" />
                      ) : (
                        <FaArrowAltCircleRight />
                      )}{' '}
                      Escolher e avançar
                    </button>
                  </div>
                )}
            </>
          )}
        </form>
      </div>
    </div>
  );
}
