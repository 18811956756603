import { apiWithToken } from '../api';

const token = localStorage.getItem('@token');
let api = apiWithToken(token);

const resouceUrl = '/pessoa_juridica';

export const load = async (params) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let page = urlParams.has('page')
    ? { page: urlParams.get('page') }
    : {};
  let search = urlParams.has('search')
    ? { search: urlParams.get('search') }
    : {};
  return await api.get(`${resouceUrl}`, {
    params: {
      ...params,
      ...page,
      ...search
    }
  })
}

export const meusEmpreendimentos = async (params) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let page = urlParams.has('page') ? { page: urlParams.get('page') } : {};
  let search = urlParams.has('search')
    ? { search: urlParams.get('search') }
    : {};
  return await api.get('meus_empreendimentos', {
    params: {
      ...params,
      ...page,
      ...search,
    },
  });
};


export const show = async (id) => {
  return await api.get(`${resouceUrl}/${id}`);
}

export const update = async (id, pessoa_juridica) => {
  return await api.put(`${resouceUrl}/${id}`, pessoa_juridica);
}


export const store = async (pessoa_juridica) => {
  let response = await api.post(resouceUrl , pessoa_juridica);
  return response;
}

export const store_meus_empreendimentos = async (pessoa_juridica) => {

  const form = new FormData();
  for (let key in pessoa_juridica) {
    form.append(key, pessoa_juridica[key]);
  }
  let response = await api.post('meus_empreendimentos', form);
  return response;
};

export const destroy = async (id) => {
  return await api.delete(`${resouceUrl}/${id}`);
}
