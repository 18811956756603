import React from 'react';

const Content = () => {
  return (
    <>
      <div className="table-responsive">
        <table className="table table-bordered table-striped">
          <tbody>
            <tr>
              <th>Processo n.</th>
              <td>{''}</td>
              <th>{''}</th>
              <td>{''}</td>
            </tr>
            <tr>
              <th>CNPJ / CPF</th>
              <td>{''}</td>
              <th>Tipo de Licença</th>
              <td>Licença de Operação</td>
            </tr>
            <tr>
              <th>CNPJ / CPF</th>
              <td>{''}</td>
              <th>Tipo de Licença</th>
              <td>Licença de Operação</td>
            </tr>
            <tr>
              <th>Empreendedor</th>
              <td>{''}</td>
              <th>Atividade</th>
              <td>Pecuária</td>
            </tr>
            <tr>
              <th>Razão Social</th>
              <td>{''}</td>
              <th>Porte</th>
              <td>Pequeno</td>
            </tr>
            <tr>
              <th>Endereço</th>
              <td>{''}</td>
              <th>Potencial Poluidor</th>
              <td>Médio</td>
            </tr>
            <tr>
              <th>Estado</th>
              <td>{''}</td>
              <th>Grau de I.A.</th>
              <td>1,86 ↑↓</td>
            </tr>
            <tr>
              <th>Município</th>
              <td>{''}</td>
              <th>Classe </th>
              <td>Insignificante</td>
            </tr>
            <tr>
              <th>Resp. Técnico</th>
              <td>{''}</td>
              <th>{''} </th>
              <td>{''}</td>
            </tr>
            <tr>
              <th>Insc. Estadual</th>
              <td>{''}</td>
              <th>Data de entrada </th>
              <td>{''}</td>
            </tr>
            <tr>
              <th>Insc. Municipal</th>
              <td>{''}</td>
              <th>Hora de entrada </th>
              <td>{''}</td>
            </tr>
            <tr>
              <th>Geolocalização</th>
              <td>{''}</td>
              <th>{''}</th>
              <td>{''}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered table-striped table-hover">
          <tbody>
            <tr>
              <th className="text-center table-success">
                Previsão de resultado do pedido de licenciamento
              </th>
            </tr>
            <tr>
              <th className="text-center table-dark">
                Em até 10 dias úteis a partir da conclusão da Etapa 3 do
                licenciamento ambiental.
              </th>
            </tr>
            <tr>
              <th className="table-success">
                O documento CPA, tem validade de certificação de protocolo de
                processo ambiental.
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Content;
