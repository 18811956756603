import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProcessoCCLA } from '../../store/modules/processo/action';
import { useParams } from 'react-router-dom';
import LogoSIBLAM from '~/assets/images/logo.svg';
import LoadPdf from '~/components/LoadPdf';

function CCLAPrint() {
    let { processo_protocolo, processo_id } = useParams();

    const dispatch = useDispatch();
    const { lista_processos_ccla } = useSelector(
        ({ ProcessoReducer }) => ProcessoReducer
    );

    useEffect(() => {
        if (processo_protocolo && processo_id) {
            // Ajustado para && ao invés de vírgula
            dispatch(getProcessoCCLA(processo_protocolo, processo_id));
        }
    }, [processo_protocolo, processo_id, dispatch]);

    return (
        <>
            {lista_processos_ccla ? (
                <LoadPdf
                    arquivo={lista_processos_ccla}
                    description="CCLA - CADEIA DE CUSTÓDIA DO LICENCIAMENTO AMBIENTAL"
                />
            ) : (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100vh',
                    }}
                >
                    <div
                        className="error-content text-center"
                        style={{ fontSize: '12px' }}
                    >
                        <h3 className="p-5">
                            <i
                                className="fas fa-hourglass-half"
                                style={{
                                    fontSize: '2em',
                                    animation: 'rotate 2s linear infinite',
                                }}
                            />{' '}
                            Aguarde enquanto estamos reunindo as informações da
                            CCLA.
                        </h3>
                        <div>
                            <i
                                className="fas fa-spinner fa-spin"
                                style={{ fontSize: '2em', color: '#007bff' }}
                            />
                        </div>
                        <img
                            alt="Loading"
                            height="auto"
                            src={LogoSIBLAM}
                            width="40%"
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default CCLAPrint;
