import React from 'react';
import Select from 'react-select';

export default function CustomSelect({
  name, options, state, setState, ...rest
}) {
  function handleChange(selectedOptions) {
    let val = '';

    if (selectedOptions) {
      if (rest.isMulti) {
        selectedOptions.forEach(({ value }) => {
          if (value) {
            val += val.length > 0 ? `,${value}` : value;
          }
        });
      } else {
        val = selectedOptions.value;
      }
    }

    setState(previousState => {
      if (typeof previousState === 'object') {
        if (Array.isArray(previousState)) {
          return [
            ...previousState, val
          ];
        } else {
          return {
            ...previousState,
            [name]: val
          };
        }
      } else {
        return val;
      }
    });
  }

  return (
    <Select
      name={name}
      options={options}
      placeholder="Selecione"
      onChange={handleChange}
      {...rest}
    />
  );
}
