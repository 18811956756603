import * as actions from './actionType';

export const loadPotencialPoluidores = () => ({
  type: actions.LOAD_POTENCIAL_POLUIDORES
});
export const getPotencialPoluidores = potencial_poluidores => ({
  type: actions.POTENCIAL_POLUIDORES_DATA,
  potencial_poluidores,
});

export const getSelectPotencialPoluidor = potencial_poluidores => ({
  type: actions.SELECT_POTENCIAL_POLUIDORES,
  potencial_poluidores,
});

export const storePotencialPoluidor = potencial_poluidor => ({
  type: actions.STORE_POTENCIAL_POLUIDOR,
  potencial_poluidor,

});
export const getPotencialPoluidor = potencial_poluidor => ({
  type: actions.GET_POTENCIAL_POLUIDOR,
  potencial_poluidor,
});

export const removerPotencialPoluidor = (id) => ({
  type: actions.REMOVER_POTENCIAL_POLUIDOR,
  id
});

export const showPotencialPoluidor = id => ({
  type: actions.SHOW_POTENCIAL_POLUIDOR,
  id,
});

export const updatePotencialPoluidor = (potencial_poluidor, id) => ({
  type: actions.UPDATE_POTENCIAL_POLUIDOR,
  potencial_poluidor,
  id
});

export const getErros = errors => ({
  type: actions.POTENCIAL_POLUIDOR_ERROR,
  errors,
});
