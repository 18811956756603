import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Row, Col } from 'react-bootstrap';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { FiCheckCircle } from 'react-icons/fi';
import buscaCep from '~/services/cep';
import FileInput from '~/components/FileInput';

const FormGerente = ({
  errors,
  schema,
  gerente,
  setGerente,
  handleSubmit
}) => {
  const { uf_data } = useSelector(({ UfDataReducer }) => UfDataReducer);

  const handleBuscaCep = async (event) => {
    const { value } = event.target;
    const cep = value;
    try {
      const data = await buscaCep.get(`${cep}/json`);
      const endereco = data.data;
      const finded = {
        logradouro: endereco.logradouro,
        bairro: endereco.bairro,
        cidade: endereco.localidade
      }
      setGerente({
        ...gerente,
        ...finded
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = event => {
    const { name, value } = event.target;

    setGerente({
      ...gerente,
      [name]: value
    });
  };

  return (
    <div className="card">
      <div className="card-body">
        <Form
          onSubmit={handleSubmit}
          schema={schema}
        >
          <Row>
            <Col>
              <Form.Group controlId="validation1">
                <Form.Label>Nome completo</Form.Label>
                <Form.Control
                  isInvalid={!!errors.nome}
                  name="nome"
                  onChange={handleChange}
                  placeholder="Nome completo"
                  type="text"
                  value={gerente.nome || ''}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.nome}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  isInvalid={!!errors.email}
                  name="email"
                  onChange={handleChange}
                  placeholder="E-mail"
                  type="email"
                  value={gerente.email || ''}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Senha</Form.Label>
                <Form.Control
                  isInvalid={!!errors.password}
                  name="password"
                  onChange={handleChange}
                  placeholder="Senha"
                  type="password"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Confirmação Senha</Form.Label>
                <Form.Control
                  isInvalid={!!errors.confirmacao_senha}
                  name="confirmacao_senha"
                  onChange={handleChange}
                  placeholder="Confirmar Senha"
                  type="password"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.confirmacao_senha}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <div style={{ border: '1px solid black', borderRadius: '3px',padding: '15px 20px', margin: '10px 0px' }}>
            <h5>Dados de Pessoa Física</h5>
            <Form.Group as={Row}>
              <Col sm={6}>
                <Form.Label>CPF</Form.Label>
                <Form.Control
                  isInvalid={!!errors.cpf}
                  maxLength="11"
                  name="cpf"
                  onChange={handleChange}
                  placeholder="CPF"
                  type="text"
                  value={gerente.cpf || ''}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.cpf}
                </Form.Control.Feedback>
              </Col>
              <Col>
                <Form.Label>Anexo</Form.Label>
                <FileInput
                  dataError={errors.anexo_cpf}
                  name="anexo_cpf"
                  data={gerente}
                  setData={setGerente}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.anexo_cpf}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>RG UF</Form.Label>
                  <Form.Control
                    as="select"
                    isInvalid={!!errors.uf_rg_id}
                    name="uf_rg_id"
                    onChange={handleChange}
                    placeholder="UF RG"
                    type="text"
                    value={gerente.uf_rg_id}
                  >
                    {uf_data[0] && uf_data.map(
                      uf => (
                        <option
                          key={uf.id}
                          value={uf.id}
                        >
                          {uf.uf}
                        </option>
                      )
                    )}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.uf_rg_id}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>RG</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.rg}
                    name="rg"
                    onChange={handleChange}
                    placeholder="RG"
                    type="text"
                    value={gerente.rg || ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.rg}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Anexo</Form.Label>
                  <FileInput
                    dataError={errors.anexo_rg}
                    name="anexo_rg"
                    data={gerente}
                    setData={setGerente}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.anexo_rg}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>CEP</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.cep}
                    name="cep"
                    onBlur={handleBuscaCep}
                    onChange={handleChange}
                    placeholder="CEP"
                    type="text"
                    value={gerente.cep || ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.cep}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Logradouro</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.logradouro}
                    name="logradouro"
                    onChange={handleChange}
                    placeholder="Logradouro"
                    type="text"
                    value={gerente.logradouro || ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.logradouro}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Anexo</Form.Label>
                  <FileInput
                    dataError={errors.anexo_endereco}
                    name="anexo_endereco"
                    data={gerente}
                    setData={setGerente}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.anexo_endereco}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Número</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.numero_endereco}
                    name="numero_endereco"
                    onChange={handleChange}
                    placeholder="Número"
                    type="text"
                    value={gerente.numero_endereco || ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.numero_endereco}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Bairro</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.bairro}
                    name="bairro"
                    onChange={handleChange}
                    placeholder="Bairro"
                    type="text"
                    value={gerente.bairro || ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.bairro}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Cidade</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.cidade}
                    name="cidade"
                    onChange={handleChange}
                    placeholder="Cidade"
                    type="text"
                    value={gerente.cidade || ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.cidade}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>UF</Form.Label>
                  <Form.Control
                    as="select"
                    isInvalid={!!errors.uf_endereco_id}
                    name="uf_endereco_id"
                    onChange={handleChange}
                    placeholder="UF"
                    type="text"
                    value={gerente.uf_endereco_id}
                  >
                    {uf_data[0] && uf_data.map(
                      uf => (
                        <option
                          key={uf.id}
                          value={uf.id}
                        >
                          {uf.uf}
                        </option>
                      )
                    )}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.uf_endereco_id}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Número de WhatsApp</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.numero_whatsapp}
                    name="numero_whatsapp"
                    onChange={handleChange}
                    placeholder="Número de WhatsAp"
                    type="text"
                    value={gerente.numero_whatsapp || ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.numero_whatsapp}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Fone de contato</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.telefone}
                    name="telefone"
                    onChange={handleChange}
                    placeholder="Fone de contato"
                    type="text"
                    value={gerente.telefone || ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.telefone}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </div>

          <div className="d-flex justify-content-between">
            <Link
              className="btn btn-default"
              to="/gerentes"
            >
              <FaArrowCircleLeft /> Voltar
            </Link>
            <button
              className="btn btn-success"
              type="submit"
            >
              <FiCheckCircle /> Finalizar
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default FormGerente;
