import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormCad } from '../components';
import Swal from 'sweetalert2';
import initialData from './initialData';
import schema from './schema'
import { updateTipoMeioAnalise, showTipoMeioAnalise } from '~/store/modules/tipo_meio_analise/action';

const Edit = ({ currentPage }) => {
  let history = useHistory();
  const [tipoMeioAnalise, setTipoMeioAnalise] = useState(initialData);
  const { meio_analise_id, tipo_meio_analise_id } = useParams();
  const [errorsValidate, setErrorsValidate] = useState([]);
  const dispatch = useDispatch();
  const { tipo_meio_analise, errors } = useSelector(({ TipoMeioAnaliseReducer }) => TipoMeioAnaliseReducer);

  useEffect(() => {
    dispatch(showTipoMeioAnalise(tipo_meio_analise_id));
  }, [dispatch, tipo_meio_analise_id]);

  useEffect(() => {
    if (Object.keys(tipo_meio_analise).length) {
      setTipoMeioAnalise(tipo_meio_analise);
    }
  }, [tipo_meio_analise]);

  useEffect(() => {
    if (errors.status && errors.status === 422) {
      let server_errors = {};
      const response_errors = errors.data.errors;
      for (let name of Object.keys(response_errors)) {
        Object.assign(server_errors, { [name]: response_errors[name][0] });
      }
      setErrorsValidate(server_errors);
    }
  }, [errors]);


  const handleClose = () => {
    history.goBack();
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (!schema.isValidSync(tipoMeioAnalise)) {
      let yup_errors = {};
      schema.validate(tipoMeioAnalise, { abortEarly: false }).catch(err => {
        for (let error of err.inner) {
          const { path, message } = error;
          Object.assign(yup_errors, { [path]: message });
        }
        setErrorsValidate(yup_errors);
      });
    } else {
      const tipoMeioAnalise_ = tipoMeioAnalise;
      tipoMeioAnalise_.meio_analise_id = parseInt(meio_analise_id);
      await dispatch(updateTipoMeioAnalise(tipoMeioAnalise_, tipoMeioAnalise_.id));
      Swal.fire('Sucesso', 'Registro salvo com sucesso!', 'success').then(
        () => {
          history.goBack();
        }
      );
    }
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-xl"
      onHide={handleClose}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         Impacto
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Editar</h2>
          </div>
          <div className="card-body">
            <FormCad
              errors={errorsValidate}
              handleSubmit={handleSubmit}
              schema={schema}
              setTipoMeioAnalise={setTipoMeioAnalise}
              tipoMeioAnalise={tipoMeioAnalise}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-default"
          onClick={handleClose}
        >
          <i className="fa fa-window-close" /> Cancelar
        </button>
        <button
          className="btn btn-success"
          form="form_tipoMeioAnalise"
          type="submint"
        >
          {' '}
          <i className="fa fa-save" /> Salvar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Edit;

