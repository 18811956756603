import { apiWithToken } from '../api';

const token = localStorage.getItem('@token');
let api = apiWithToken(token);

const resouceUrl = '/pessoa_fisica';

export const load = async (params) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let page = urlParams.has('page')
    ? { page: urlParams.get('page') }
    : {};
  let search = urlParams.has('search')
    ? { search: urlParams.get('search') }
    : {};
  return await api.get(`${resouceUrl}`, {
    params: {
      ...params,
      ...page,
      ...search
    }
  })
}

export const show = async (id) => {
  return await api.get(`${resouceUrl}/${id}`);
}

export const update = async (id, pessoa_fisica) => {
  return await api.put(`${resouceUrl}/${id}`, pessoa_fisica);
}


export const store = async (pessoa_fisica) => {
  let response = await api.post(resouceUrl , pessoa_fisica);
  return response;
}

export const destroy = async (id) => {
  return await api.delete(`${resouceUrl}/${id}`);
}
