import React from 'react';
import { BasicContent } from '../components';
import { Link } from 'react-router-dom';
import schema from './schema';
import EntityForm from '../Form';

const Create = () => {
  return (
    <BasicContent
      breadcrumb={{
        label: 'Novo',
        to: '/entidade/create',
        active: false,
      }}
    >
      <div className="card">
        <div className="card-header">
          <h2 className="card-title">Novo</h2>
          <div className="card-tools">
            <Link
              className="btn btn-default"
              to="/entidade"
            >
              <i className="fa fa-arrow-circle-left" /> Voltar
            </Link>
          </div>
        </div>
        <div className="card-body">
          <EntityForm
            action="store"
            validation={schema}
          />
        </div>
        <div className="card-footer">
          <Link
            className="btn btn-default"
            to="/entidade"
          >
            <i className="fa fa-arrow-circle-left" /> Voltar
          </Link>
          <button
            type="submit"
            form="form_entidade"
            className="btn btn-success float-right"
          >
            {' '}
            <i className="fa fa-save" /> Salvar
          </button>
        </div>
      </div>
    </BasicContent>
  );
};

export default Create;
