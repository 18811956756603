export const LOAD_MENU = 'LOAD_MENU';
export const MENU_ERROR = 'MENU_ERROR';
export const MENU_GET_ERRORS = 'MENU_GET_ERRORS';
export const MENU_DATA = 'MENU_DATA';
export const STORE_MENU = 'STORE_MENU';
export const GET_MENU = 'GET_MENU';
export const REMOVER_MENU = 'REMOVER_MENU';
export const SHOW_MENU = 'SHOW_MENU';
export const UPDATE_MENU = 'UPDATE_MENU';

export const LOAD_SUBMENU = 'LOAD_SUBMENU';
export const SUBMENU_DATA = 'SUBMENU_DATA';

export const LOAD_MENU_TREE = 'LOAD_MENU_TREE';
export const MENU_TREE_DATA = 'MENU_TREE_DATA';
