export const LOAD_MEIO_ANALISES = 'LOAD_MEIO_ANALISES';
export const SELECT_MEIO_ANALISES = 'SELECT_MEIO_ANALISES';
export const MEIO_ANALISE_ERROR = 'MEIO_ANALISE_ERROR';
export const MEIO_ANALISE_GET_ERRORS = 'MEIO_ANALISE_GET_ERRORS';
export const MEIO_ANALISES_DATA = 'MEIO_ANALISES_DATA';
export const STORE_MEIO_ANALISE = 'STORE_MEIO_ANALISE';
export const GET_MEIO_ANALISE = 'GET_MEIO_ANALISE';
export const REMOVER_MEIO_ANALISE = 'REMOVER_MEIO_ANALISE';
export const SHOW_MEIO_ANALISE = 'SHOW_MEIO_ANALISE';
export const UPDATE_MEIO_ANALISE = 'UPDATE_MEIO_ANALISE';
export const CHANGE_STATUS_CODE = 'CHANGE_STATUS_CODE';
export const LOAD_MEIO_ANALISES_WITH_RESPOSTAS_APIA = 'LOAD_MEIO_ANALISES_WITH_RESPOSTAS_APIA';
export const MEIO_ANALISES_WITH_RESPOSTAS_APIA_DATA = 'MEIO_ANALISES_WITH_RESPOSTAS_APIA_DATA';
export const LOAD_MEIO_ANALISES_WITH_RESPOSTAS = 'LOAD_MEIO_ANALISES_WITH_RESPOSTAS';
export const MEIO_ANALISES_WITH_RESPOSTAS_DATA = 'MEIO_ANALISES_WITH_RESPOSTAS_DATA';
export const ACTIVE_MEIO_ANALISE = 'ACTIVE_MEIO_ANALISE';
