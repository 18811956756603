import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as PessoaJuridica from '~/services/PessoaJuridica';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadPessoaJuridicasWorker({ params }) {
  try {
    const response = yield call(PessoaJuridica.load, params);
    yield put(actions.getPessoaJuridicas(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}
function* loadMeusEmpreendimentosWorker({params}) {
  try {
    const response = yield call(PessoaJuridica.meusEmpreendimentos, params);
    yield put(actions.getPessoaJuridicas(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}
function* showPessoaJuridicaWorker({id}) {
  try {
    const response = yield call(PessoaJuridica.show, id);
    yield put(actions.getPessoaJuridica(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updatePessoaJuridicaWorker({ pessoa_juridica, id}) {
  try {
    const response = yield call(PessoaJuridica.update, id, pessoa_juridica);
    yield put(actions.getPessoaJuridica(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storePessoaJuridicaWorker({pessoa_juridica}) {
  try {
    const response = yield call(PessoaJuridica.store, pessoa_juridica);
    yield put(actions.getPessoaJuridica(response));
    yield put(actions.getErros({save:true}));
    yield put(getErros({save:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeMeusEmpreendimentosWorker({ pessoa_juridica }) {
  try {
    const response = yield call(PessoaJuridica.store_meus_empreendimentos, pessoa_juridica);
    yield put(actions.getPessoaJuridica(response));
    yield put(actions.getErros({ save: true }));
    yield put(getErros({ save: true }));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* removerPessoaJuridicaWorker({id}) {
  try {
    yield call(PessoaJuridica.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherPessoaJuridica() {
  yield takeLatest(actionType.LOAD_PESSOA_JURIDICAS, loadPessoaJuridicasWorker);
  yield takeLatest(actionType.LOAD_MEUS_EMPREENDIMENTOS, loadMeusEmpreendimentosWorker);
  yield takeLatest(actionType.STORE_PESSOA_JURIDICA, storePessoaJuridicaWorker);
  yield takeLatest(actionType.STORE_MEUS_EMPREENDIMENTOS, storeMeusEmpreendimentosWorker);
  yield takeLatest(actionType.SHOW_PESSOA_JURIDICA, showPessoaJuridicaWorker);
  yield takeLatest(actionType.UPDATE_PESSOA_JURIDICA, updatePessoaJuridicaWorker);
  yield takeLatest(actionType.REMOVER_PESSOA_JURIDICA, removerPessoaJuridicaWorker);
}

export default function* saga() {
  yield all([watcherPessoaJuridica()]);
}
