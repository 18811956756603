import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import { loadMeusEmpreendimentos } from '~/store/modules/pessoa_juridica/action';

import formatForMask from '~/utils/formatForMask';

const SelectEmpreendimento = ({ setEmpreendimento }) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([
    { value: null, label: '.::Selecione::.' },
  ]);

  const { pessoa_juridicas } = useSelector(
    ({ PessoaJuridicaReducer }) => PessoaJuridicaReducer
  );

  useEffect(() => {
    dispatch(loadMeusEmpreendimentos({ raw_list: 'raw_list' }));
  }, [dispatch]);

  const [optionSelected, setOptionSelected] = useState([
    { value: null, label: '.::Selecione::.' },
  ]);

  useEffect(() => {
    let optionSet = [{ value: null, label: '.::Selecione::.' }];
    setOptionSelected({ value: null, label: '.::Selecione::.' });

    if (pessoa_juridicas && pessoa_juridicas.data) {
      pessoa_juridicas.data.forEach(({ id, nome, cpf, cnpj }) => {
        let labelCpf = cpf
          ? `CPF: ${formatForMask(cpf, '###.###.###-##')}`
          : '';
        let labelCnpj = cnpj
          ? `CNPJ: ${formatForMask(cnpj, '##.###.###/####-##')}`
          : '';
        optionSet.push({
          value: id,
          label: `${nome} - ${labelCpf} ${labelCnpj}`,
        });
      });
    }
    setOptions(optionSet);
  }, [pessoa_juridicas]);

  const handleOnChange = (e) => {
    setOptionSelected(e);
    setEmpreendimento(e);
  }

  return (
    <Form.Group>
      <Form.Label>Selecione o Empreendimento</Form.Label>
      <Select
        onChange={handleOnChange}
        options={options}
        value={optionSelected}
      />
      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
    </Form.Group>
  );
};
export default SelectEmpreendimento;
