import * as actions from './actionType';

export const QuestaoAnaliseReducer = (state = {select_questao_analises: [], questao_analises: [], questao_analise: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.QUESTAO_ANALISES_DATA:
      return {
        ...state,
        questao_analises: action.questao_analises.data,
        links: action.questao_analises.data.links,
        meta: action.questao_analises.data.meta,
      };
    case actions.SELECT_QUESTAO_ANALISES:
      return {
        ...state,
        select_questao_analises: action.questao_analises.data
      };
    case actions.GET_QUESTAO_ANALISE:
      return {
        ...state,
        questao_analise: action.questao_analise.data.data,
        status_response: action.questao_analise.status,
      };
    case actions.QUESTAO_ANALISE_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    case actions.CHANGE_STATUS_CODE:
      return {
        ...state,
        status_response: action.status,
      };
    default:
      return state;
  }
}
