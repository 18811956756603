import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as ListaDocumento from '~/services/ListaDocumento';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadListaDocumentosWorker({ params }) {
  try {
    const response = yield call(ListaDocumento.load, params);
    yield put(actions.getListaDocumentos(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}


function* showListaDocumentoWorker({id}) {
  try {
    const response = yield call(ListaDocumento.show, id);
    yield put(actions.getListaDocumento(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updateListaDocumentoWorker({ lista_documento, id}) {
  try {
    const response = yield call(ListaDocumento.update, id, lista_documento);
    yield put(actions.getListaDocumento(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeListaDocumentoWorker({lista_documento}) {
  try {
    const response = yield call(ListaDocumento.store, lista_documento);
    yield put(actions.changeStatus(response.status));
    // yield put(actions.getListaDocumento(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* removerListaDocumentoWorker({id}) {
  try {
    yield call(ListaDocumento.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* ativarListaDocumentoWorker({id}) {
  try {
    yield call(ListaDocumento.activate, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* deleteListaDocumentoWorker({id}) {
  try {
    yield call(ListaDocumento.forceDelete, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}


function* watcherListaDocumento() {
  yield takeLatest(actionType.LOAD_LISTA_DOCUMENTOS, loadListaDocumentosWorker);
  yield takeLatest(actionType.STORE_LISTA_DOCUMENTO, storeListaDocumentoWorker);
  yield takeLatest(actionType.SHOW_LISTA_DOCUMENTO, showListaDocumentoWorker);
  yield takeLatest(actionType.UPDATE_LISTA_DOCUMENTO, updateListaDocumentoWorker);
  yield takeLatest(actionType.REMOVER_LISTA_DOCUMENTO, removerListaDocumentoWorker);
  yield takeLatest(actionType.ACTIVE_LISTA_DOCUMENTO, ativarListaDocumentoWorker);
  yield takeLatest(actionType.DELETE_LISTA_DOCUMENTO, deleteListaDocumentoWorker);
}

export default function* saga() {
  yield all([watcherListaDocumento()]);
}
