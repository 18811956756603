import { all, put, takeEvery } from 'redux-saga/effects';
import { api } from '~/services/api';
import { UF_RESPONSE } from './actionType';
import { cadastro_pessoa_data } from './action';

function* ufWorker() {
  try {
    const ufData = yield api.get('/estado?fields=id,uf,nome&raw_list');
    yield put(cadastro_pessoa_data(ufData.data.data));
  }
  catch (err) {
    console.log(err.response);
    // yield put(cadastro_pessoa_error(err.response))
  }
}

function* watcherUfData() {
  yield takeEvery(UF_RESPONSE, ufWorker);
}

export default function* saga() {
  yield all([watcherUfData()]);
}
