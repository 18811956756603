import React, { useEffect, useState } from 'react';
import { useParams,  Switch, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { Loading } from '~/components';
import { BasicContent } from '../components';
// import { SubMenuList } from '../components/SubMenu';
import { showVeiculo } from '~/store/modules/veiculo/action';
// import Create from '../../Atividade/Create';
// import CreateMotorista from '../../Motorista/Create';
// import CreateVeiculo from '../../Veiculo/Create';

const Show = () => {
  let history = useHistory();
  let location = useLocation();
  let modal = location.state && location.state.modal;
  const dispatch = useDispatch();
  const { veiculo_id } = useParams();
  const [veiculoShow, setVeiculoShow] = useState({});

  const { veiculo } = useSelector(({ VeiculoReducer }) => VeiculoReducer);

  useEffect(() => {
    dispatch(showVeiculo(veiculo_id,{
      with:'estado,municipio'
    }));
  }, [dispatch, veiculo_id]);

  useEffect(() => {
    if (veiculo.id === parseInt(veiculo_id)) {
      setVeiculoShow(veiculo);
    }
  }, [veiculo, veiculo_id]);

  const breadcrumb = {
    label: 'Detalhes',
    to: `veiculo/${veiculo_id}/detalhe`,
    active: false,
  };

  const handleClose = () => {
    history.goBack();
  };

  return (
    <>
      {modal && (
        <Switch />
      )}
      <BasicContent breadcrumb={breadcrumb}>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Detalhes</h2>
            <div className="card-tools">
              <button
                className="btn btn-default"
                onClick={handleClose}
              >
                <FaArrowAltCircleLeft /> Voltar
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              {veiculoShow.id === parseInt(veiculo_id) ? (
                <table className="table table-hover table-striped">
                  <tbody>
                    <tr>
                      <th>Modelo</th>
                      <td>{veiculo.modelo}</td>
                    </tr>
                    <tr>
                      <th>Placa</th>
                      <td>{veiculo.placa}</td>
                    </tr>
                    <tr>
                      <th>Ano de fabricação</th>
                      <td>{veiculo.ano_fabricacao?veiculo.ano_fabricacao:'Não foi informado'}</td>
                    </tr>
                    <tr>
                      <th>Ano do modelo</th>
                      <td>{veiculo.ano_modelo}</td>
                    </tr>
                    <tr>
                      <th>Observação</th>
                      <td>{veiculo.observacao ? veiculo.observacao : 'Sem observações.'}</td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <Loading />
              )}
            </div>
          </div>
        </div>
      </BasicContent>
    </>
  );
};

export default Show;
