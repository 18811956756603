import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { Loading } from '~/components';
import { showPessoaFisica } from '~/store/modules/pessoa_fisica/action';
import { ModalAnexos } from '~/components';

const List = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { pessoa_fisica_id } = useParams();
  const [pessoaFisicaShow, setPessoaFisicaShow] = useState({});

  const [modalArquivo, setModalArquivo] = useState({
    title: '',
    path: '',
    show: false,
    handleHide: () => false,
  });

  const { pessoa_fisica } = useSelector(
    ({ PessoaFisicaReducer }) => PessoaFisicaReducer
  );

  useEffect(() => {
    dispatch(showPessoaFisica(pessoa_fisica_id));
  }, [dispatch, pessoa_fisica_id]);

  useEffect(() => {
    if (pessoa_fisica.id === parseInt(pessoa_fisica_id)) {
      setPessoaFisicaShow(pessoa_fisica);
    }
  }, [pessoa_fisica, pessoa_fisica_id]);

  const handleClose = () => {
    history.push('/empreendimentos/pessoa_fisica');
  };

  const handleHide = () => {
    setModalArquivo({
      title: '',
      path: '',
      show: false,
      handleHide: () => false,
    });
  };

  const handleOpenModalAnexo = (title, path) => {
    console.log(title, path);
    setModalArquivo({
      title,
      path,
      show: true,
      handleHide,
    });
  };

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-xl"
        onHide={handleClose}
        show
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            PessoaFisica
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header">
              <h2 className="card-title">Detalhes</h2>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                {pessoaFisicaShow.id === parseInt(pessoa_fisica_id) ? (
                  <>
                    <table className="table table-hover table-striped table-sm">
                      <tbody>
                        <tr>
                          <th>id</th>
                          <td>{pessoa_fisica.id}</td>
                        </tr>
                        <tr>
                          <th>Nome completo</th>
                          <td>{pessoa_fisica.nome}</td>
                        </tr>
                        <tr>
                          <th>E-mail</th>
                          <td>{pessoa_fisica.email}</td>
                        </tr>
                        <tr>
                          <th>CPF</th>
                          <td>
                            {pessoa_fisica.cpf}
                            <button
                              className="btn btn-default ml-2 float-right"
                              onClick={() =>
                                handleOpenModalAnexo(
                                  'Anexo CPF',
                                  pessoa_fisica.anexo_cpf
                                )
                              }
                            >
                              <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                            </button>
                          </td>
                        </tr>

                        <tr>
                          <th>RG</th>
                          <td>
                            {pessoa_fisica.rg}
                            <button
                              className="btn btn-default ml-2 float-right"
                              onClick={() =>
                                handleOpenModalAnexo(
                                  'Anexo RG',
                                  pessoa_fisica.anexo_rg
                                )
                              }
                            >
                              <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                            </button>
                          </td>
                        </tr>

                        <tr>
                          <th>CEP</th>
                          <td>{pessoa_fisica.cep}</td>
                        </tr>
                        <tr>
                          <th>Endereço</th>
                          <td>
                            {pessoa_fisica.logradouro}, {pessoa_fisica.bairro},{' '}
                            {pessoa_fisica.bairro}, {pessoa_fisica.Cidade} -
                            {pessoa_fisica.estado_endereco.uf}
                            <button
                              className="btn btn-default ml-2 float-right"
                              onClick={() =>
                                handleOpenModalAnexo(
                                  'Comprovante de endereço',
                                  pessoa_fisica.anexo_endereco
                                )
                              }
                            >
                              <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <th>Número de WhatsApp</th>
                          <td>{pessoa_fisica.numero_whatsapp}</td>
                        </tr>
                        <tr>
                          <th>Fone de contato</th>
                          <td>{pessoa_fisica.telefone}</td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                ) : (
                  <Loading />
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Link className="btn btn-default" to="/empreendimentos/pessoa_fisica">
            <i className="fa fa-window-close" /> Fechar
          </Link>
        </Modal.Footer>
      </Modal>
      <ModalAnexos {...modalArquivo} />
    </>
  );
};

export default List;
