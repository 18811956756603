export const initialData = {
  nome: '',
  sigla: '',
  qtd_tec_para_analise: 1,
  qtd_hora_tec_necessaria: 1,
  valor_hora_tec: '0,00',
  qtd_diarias: 0,
  valor_diaria: '0,00',
  valor_combustivel_vistoria: '0,00',
  qtd_viagem_vistoria: 0,
  has_apia_atia: 1,
  validade: '',
  taxa_recebimento: '0,00',
  taxa_requerimento: '0,00',
  expiration_unit: 'years',
  expiration_quantity: 1,
  isencao_taxa_ambiental: false
}
export default initialData;
