import React, { useEffect, useState, useCallback } from 'react';
import { useParams, Switch, Route, useLocation, Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { Loading } from '~/components';
import { showTipoLicenca } from '~/store/modules/tipo_licenca/action';
import { loadMeioAnalises, removerMeioAnalise, activeMeioAnalise } from '~/store/modules/meio_analise/action';
import Swal from 'sweetalert2';
// import { loadIndicadoresAmbientais } from '~/store/modules/indicadorAmbiental/action';
import { loadListaDocumentos, removerListaDocumento, activeListaDocumento, deleteListaDocumento } from '~/store/modules/lista_documento/action';
import { loadValorReferencias, removerValorReferencia, ativarValorReferencia } from '~/store/modules/valor_referencia/action';
import { BasicContent } from '../components';
import { SubMenuList } from '../components/SubMenu';
import { SubMenuValorReferencia } from '../components/SubMenuValorReferencia';
// import CreateInd from '../../IndicadoresAmbientais/Create';
import CreateMeio from '../../MeioAnalise/Create';
import EditMeio from '../../MeioAnalise/Edit';
import CreateValorReferencia from '../../ValorReferencia/Create';
import EditValorReferencia from '../../ValorReferencia/Edit';
import ImportMeioAnalise from '../../MeioAnalise/ImportMeioAnalise';
import ImportListaDocumentos from '../../ListaDocumentos/ImportListaDocumentos';
import ModeloMeioAnalise from '../../MeioAnalise/ModeloMeioAnalise';

const List = () => {
  let location = useLocation();
  let modal = location.state && location.state.modal;
  const dispatch = useDispatch();
  let history = useHistory();
  const { tipo_licenca_id } = useParams();
  const [tipo_licenca_show, setTipo_licenca_show] = useState({});

  const { tipo_licenca } = useSelector(({ TipoLicencaReducer }) => TipoLicencaReducer);
  const { meio_analises } = useSelector(({ MeioAnaliseReducer }) => MeioAnaliseReducer);
  const { lista_documentos } = useSelector(({ ListaDocumentoReducer }) => ListaDocumentoReducer);
  const { valores_referencias } = useSelector(({ ValorReferenciaReducer }) => ValorReferenciaReducer);

  useEffect(() => {
    dispatch(showTipoLicenca(tipo_licenca_id, {
      with_trashed: true
    }));
    dispatch(loadMeioAnalises({
      where:`tipo_licenca_id:${tipo_licenca_id}`,
      with_trashed: true,
    }));
    dispatch(loadListaDocumentos({
      where:`tipo_licenca_id:${tipo_licenca_id}`,
      with_trashed: true,
      order_by: 'ordem:asc'
    }));
    dispatch(loadValorReferencias({
      where:`tipo_licenca_id:${tipo_licenca_id}`,
      with: 'potencial_poluidor,porte_empreendimento',
      with_trashed: true,
    }));
  }, [dispatch, tipo_licenca_id]);

  useEffect(() => {
    if (tipo_licenca.id === parseInt(tipo_licenca_id)) {
      setTipo_licenca_show(tipo_licenca);
    }
  }, [tipo_licenca, tipo_licenca_id]);

  const handleRemoveListaDoc = useCallback((id => {
    Swal.fire({
      title: 'Desativar?',
      text: 'Deseja desativar esse item?!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        try {
          await dispatch(removerListaDocumento(id));
          await dispatch(loadListaDocumentos({
            where:`tipo_licenca_id:${tipo_licenca_id}`,
            with_trashed: true,
          }));
          await Swal.fire('Sucesso',
            'Desativado com sucesso!',
            'success');
          await history.go();
        } catch (error) {
          await Swal.fire('Erro',
            'Erro ao Desativar',
            'error');
        }
      }
    })
  }),[dispatch, tipo_licenca_id, history]);

  const handleDeleteListaDoc = useCallback((id => {
    Swal.fire({
      title: 'Apagar?',
      text: 'Deseja apagar definitivamente esse item?!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        try {
          await dispatch(deleteListaDocumento(id));
          await dispatch(loadListaDocumentos({
            where:`tipo_licenca_id:${tipo_licenca_id}`,
            with_trashed: true,
            order_by: 'ordem:asc'
          }));
          await Swal.fire('Sucesso',
            'Apagado com sucesso!',
            'success');
          await history.go();
        } catch (error) {
          await Swal.fire('Erro',
            'Erro ao ativar',
            'error');
        }
      }
    })
  }),[dispatch, tipo_licenca_id, history]);

  const handleActivateListaDoc = useCallback((id => {
    Swal.fire({
      title: 'Ativar?',
      text: 'Deseja ativar esse item?!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        try {
          await dispatch(activeListaDocumento(id));
          await dispatch(loadListaDocumentos({
            where:`tipo_licenca_id:${tipo_licenca_id}`,
            with_trashed: true,
          }));
          await Swal.fire('Sucesso',
            'Ativado com sucesso!',
            'success');
          await history.go();
        } catch (error) {
          await Swal.fire('Erro',
            'Erro ao ativar',
            'error');
        }
      }
    })
  }),[dispatch, tipo_licenca_id, history]);

  const handleRemoveMeioAnalise = useCallback((id => {
    Swal.fire({
      title: 'Desativar?',
      text: 'Deseja desativar esse item?!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        try {
          await dispatch(removerMeioAnalise(id));
          await dispatch(loadMeioAnalises({
            where:`tipo_licenca_id:${tipo_licenca_id}`,
            with_trashed: true,
          }));
          await Swal.fire('Sucesso',
            'Desativado com sucesso!',
            'success');
          await history.go();
        } catch (error) {
          await Swal.fire('Erro',
            'Erro ao Desativar',
            'error');
        }
      }
    })
  }),[dispatch, tipo_licenca_id, history]);

  const handleActivateMeioAnalise = useCallback((id => {
    Swal.fire({
      title: 'Ativar?',
      text: 'Deseja ativar esse item?!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        try {
          await dispatch(activeMeioAnalise(id));
          await dispatch(loadMeioAnalises({
            where:`tipo_licenca_id:${tipo_licenca_id}`,
            with_trashed: true,
          }));
          await Swal.fire('Sucesso',
            'Ativado com sucesso!',
            'success');
          await history.go();
        } catch (error) {
          await Swal.fire('Erro',
            'Erro ao ativar',
            'error');
        }
      }
    })
  }),[dispatch, tipo_licenca_id, history]);

  const handleRemoveValorReferencia = useCallback((id => {
    Swal.fire({
      title: 'Desativar?',
      text: 'Deseja desativar esse item?!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        try {
          await dispatch(removerValorReferencia(id));
          await dispatch(loadValorReferencias({
            where:`tipo_licenca_id:${tipo_licenca_id}`,
            with_trashed: true,
          }));
          await Swal.fire('Sucesso',
            'Desativado com sucesso!',
            'success');
          await history.go();
        } catch (error) {
          await Swal.fire('Erro',
            'Erro ao Desativar',
            'error');
        }
      }
    })
  }),[dispatch, tipo_licenca_id, history]);

  const handleActivateValorReferencia = useCallback((id => {
    Swal.fire({
      title: 'Ativar?',
      text: 'Deseja ativar esse item?!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        try {
          await dispatch(ativarValorReferencia(id));
          await dispatch(loadValorReferencias({
            where:`tipo_licenca_id:${tipo_licenca_id}`,
            with_trashed: true,
          }));
          await Swal.fire('Sucesso',
            'Ativado com sucesso!',
            'success');
          await history.go();
        } catch (error) {
          await Swal.fire('Erro',
            'Erro ao ativar',
            'error');
        }
      }
    })
  }),[dispatch, tipo_licenca_id, history]);

  const breadcrumb = [
    {
      label: 'Entidade',
      to: `/entidade/${tipo_licenca_show?.atividade?.entidade_id}/detalhe`,
      active: false,
    },
    {
      label: 'Atividade',
      to: `/atividade/${tipo_licenca_show?.atividade_id}/detalhe`,
      active: false,
    },
    {
      label: 'Tipo Licença',
      to: `/tipo_licenca/${tipo_licenca_id}/detalhe`,
      active: false,
    },
    {
      label: 'Detalhes',
      to: `/tipo_licenca/${tipo_licenca_id}/detalhe`,
      active: true,
    }];

  // console.log(valores_referencias?.data);

  return (
    <>
      {modal && (
        <Switch>
          <Route
            children={<CreateMeio />}
            path={'/tipo_licenca/:tipo_licenca_id/meio_analise/novo'}
          />
          <Route
            children={<EditMeio />}
            path={'/tipo_licenca/:tipo_licenca_id/meio_analise/:id_meio_analise/edit'}
          />
           <Route
            children={<ImportMeioAnalise />}
            path={'/tipo_licenca/:tipo_licenca_id/meio_analise/import_data'}
          />
          <Route
             children={<ImportListaDocumentos />}
            path={'/tipo_licenca/:tipo_licenca_id/lista_documentos/import_data'} 
          />
          <Route
            children={<ModeloMeioAnalise />}
            path={'/tipo_licenca/:tipo_licenca_id/meio_analise/modelo'}
          />
          <Route
            children={<CreateValorReferencia />}
            path={'/tipo_licenca/:tipo_licenca_id/:atividade_id/valor_referencia/novo'}
          />
          <Route
            children={<EditValorReferencia />}
            path={'/tipo_licenca/:tipo_licenca_id/valor_referencia/:valor_referencia_id/edit'}
          />
        </Switch>
      )}
      <BasicContent breadcrumb={breadcrumb}>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Detalhes</h2>
            <div className="card-tools">
              <Link
                className="btn btn-default"
                to={`/atividade/${tipo_licenca_show?.atividade_id}/detalhe`}
              >
                <FaArrowAltCircleLeft /> Voltar
              </Link>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              {tipo_licenca_show.id === parseInt(tipo_licenca_id) ? (
                <table className="table table-hover table-striped">
                  <tbody>
                    <tr>
                      <th>Entidade</th>
                      <td><Link to={`/entidade/${tipo_licenca_show?.atividade?.entidade_id}/detalhe`}>{tipo_licenca_show?.atividade?.entidade?.nome}</Link></td>
                    </tr>
                    <tr>
                      <th>Atividade</th>
                      <td><Link to={`/atividade/${tipo_licenca_show?.atividade_id}/detalhe`}>{tipo_licenca_show?.atividade?.nome}</Link></td>
                    </tr>
                    <tr>

                      <th>id</th>
                      <td>{tipo_licenca_show?.id}</td>
                    </tr>
                    <tr>
                      <th>Nome</th>
                      <td>{tipo_licenca_show?.nome}</td>
                    </tr>
                    <tr>
                      <th>Sigla</th>
                      <td>{tipo_licenca_show?.sigla}</td>
                    </tr>
                    <tr>
                      <th>Validade</th>
                      <td>{tipo_licenca_show?.validade}</td>
                    </tr>
                    <tr>
                      <th>Taxa de Requerimento de Publicação (R$)</th>
                      <td>R$ {tipo_licenca_show?.taxa_requerimento}</td>
                    </tr>
                    <tr>
                      <th>Taxa de Recebimento de Publicação (R$)</th>
                      <td>R$ {tipo_licenca_show?.taxa_recebimento}</td>
                    </tr>
                    <tr>
                      <th>Qtd. de técnicos para analise</th>
                      <td>{tipo_licenca_show?.qtd_tec_para_analise}</td>
                    </tr>
                    <tr>
                      <th>Qtd. de horas necessárias</th>
                      <td>{tipo_licenca_show?.qtd_hora_tec_necessaria}</td>
                    </tr>
                    <tr>
                      <th>Valor hora técnica (R$)</th>
                      <td>R$ {tipo_licenca_show?.valor_hora_tec}</td>
                    </tr>
                    <tr>
                      <th>Qtd. de diárias</th>
                      <td>{tipo_licenca_show?.qtd_diarias}</td>
                    </tr>
                    <tr>
                      <th>Valor de diária (R$)</th>
                      <td>R$ {tipo_licenca_show?.valor_diaria}</td>
                    </tr>
                    <tr>
                      <th>Valor combustível vistoria (R$)</th>
                      <td>R$ {tipo_licenca_show?.valor_combustivel_vistoria}</td>
                    </tr>
                    <tr>
                      <th>Qtd. viagem vistoria</th>
                      <td>{tipo_licenca_show?.qtd_viagem_vistoria}</td>
                    </tr>
                    <tr>
                      <th>Tem APIA e ATIA?</th>
                      <td>{tipo_licenca_show.has_apia_atia ? 'SIM' : 'NÃO'}</td>
                    </tr>

                  </tbody>
                </table>
              ) : (
                <Loading />
              )}
            </div>
          </div>
        </div>

        {lista_documentos.data ? (
          <>
            <SubMenuList
              action={loadListaDocumentos}
              createPath={'/tipo_licenca/:tipo_licenca_id/lista_documentos/novo'}
              createUrl={`/tipo_licenca/${tipo_licenca_id}/lista_documentos/novo`}
              delete_isOk
              detalhe={false}
              displayName={'tipo_licenca_id'}
              edit_lista_doc
              handleActivate={handleActivateListaDoc}
              handleDelete={handleDeleteListaDoc}
              handleRemove={handleRemoveListaDoc}
              id={tipo_licenca_id}
              labelCol1={'Ordem'}
              labelCol2={'Nome'}
              labelCol3={'Obrigatório'}
              labelCol4={'Técnico'}
              title={'Lista de documentos do Tipo de Licença'}
              importDataUrl={`/tipo_licenca/${tipo_licenca_id}/lista_documentos/import_data`}
              valueCol1={'ordem'}
              valueCol2={'nome'}
              valueCol3={'obrigatorio'}
              valueCol4={'doc_tecnica'}
              values={lista_documentos}
            />
          </>
        ) : (
          <div className="card">
            <div className="card-header">
              <h2 className="card-title">Lista de documentos da Atividade</h2>
            </div>
            <div className="card-body">
              <Loading />
            </div>
          </div>
        )}

        {meio_analises.data ? (
          <>
            <SubMenuList
              action={loadMeioAnalises}
              createPath={'/tipo_licenca/:tipo_licenca_id/meio_analise/novo'}
              createUrl={`/tipo_licenca/${tipo_licenca_id}/meio_analise/novo`}
              detalhe
              displayName={'tipo_licenca_id'}
              edit_meio_analises
              handleActivate={handleActivateMeioAnalise}
              handleRemove={handleRemoveMeioAnalise}
              id={tipo_licenca_id}
              labelCol1={'Id'}
              labelCol2={'Nome'}
              path_detalhe={'/meio_analise/*/detalhe'}
              perguntas={tipo_licenca_show.has_apia_atia}
              template={`/tipo_licenca/${tipo_licenca_id}/meio_analise/modelo`}
              importDataUrl={`/tipo_licenca/${tipo_licenca_id}/MeioAnalise/import_data`}
              title={'Meios de analise'}
              valueCol1={'id'}
              valueCol2={'nome'}
              values={meio_analises}
            />
          </>
        ) : (
          <div className="card">
            <div className="card-header">
              <h2 className="card-title">Meios analise</h2>
            </div>
            <div className="card-body">
              <Loading />
            </div>
          </div>
        )}

        {valores_referencias ? (
          <>
            <SubMenuValorReferencia
              action={loadValorReferencias}
              createPath={'/tipo_licenca/:tipo_licenca_id/:atividade_id/valor_referencia/novo'}
              createUrl={`/tipo_licenca/${tipo_licenca_id}/${tipo_licenca_show.atividade_id}/valor_referencia/novo`}
              displayName={'tipo_licenca_id'}
              edit_valor_ref
              handleActivate={handleActivateValorReferencia}
              handleRemove={handleRemoveValorReferencia}
              id={tipo_licenca_id}
              importDataUrl={`/tipo_licenca/${tipo_licenca_id}/${tipo_licenca_show.atividade_id}/ValorRefencia/import_data`}
              labelCol1={'Id'}
              labelCol2={'Porte do Empreendimento'}
              labelCol3={'Potencial Poluidor'}
              labelCol4={'Valor'}
              title={'Valores de Referência'}
              valueCol1={'id'}
              valueCol2={'porte_empreendimento'}
              valueCol3={'potencial_poluidor'}
              valueCol4={'valor'}
              values={valores_referencias}
            />
          </>
        ) : (
          <div className="card">
            <div className="card-header">
              <h2 className="card-title">Valores de Referência</h2>
            </div>
            <div className="card-body">
              <Loading />
            </div>
          </div>
        )}
      </BasicContent>
    </>
  );
};

export default List;
