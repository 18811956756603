import * as actions from './actionType';

export const ResponsavelTecnicoReducer = (state = {select_responsavel_tecnicos: [], responsavel_tecnicos: [], responsavel_tecnico: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.RESPONSAVEL_TECNICOS_DATA:
      return {
        ...state,
        responsavel_tecnicos: action.responsavel_tecnicos.data,
        links: action.responsavel_tecnicos.data.links,
        meta: action.responsavel_tecnicos.data.meta,
      };
    case actions.SELECT_RESPONSAVEL_TECNICOS:
      return {
        ...state,
        select_responsavel_tecnicos: action.responsavel_tecnicos.data,
      };
    case actions.GET_RESPONSAVEL_TECNICO:
      return {
        ...state,
        responsavel_tecnico: action.responsavel_tecnico.data.data,
        status_response: action.responsavel_tecnico.status,
      };
    case actions.GET_LIST_FOR_ADD:
      return {
        ...state,
        responsavel_tecnico_add: action.tecnicos.data.data,
        status_response: action.tecnicos.status,
      };
    case actions.RESPONSAVEL_TECNICO_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
