import React, {useEffect} from 'react';
import { Link, Switch, Route, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { Paginate } from '~/components';
import { loadSubMenus, removerMenu } from '~/store/modules/menu/action';
import Create from '../Create';
import Edit from '../Edit';


const List = ( {menu_id} ) => {
  let location = useLocation();
  let modal = location.state && location.state.modal;
  const dispatch = useDispatch();
  const { menus, meta } = useSelector(
    ({ MenuReducer }) => MenuReducer
  );

  useEffect(() => {
    dispatch(loadSubMenus(menu_id))
  }, [dispatch, menu_id]);


  const handleRemove = async (event) => {
    const id = event.target.value;
    Swal.fire({
      title: 'Remover?',
      text: 'Deseja Remover esse item?!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        try {
          await dispatch(removerMenu(id));
          await Swal.fire('Sucesso',
            'Removido com sucesso!',
            'success');
          dispatch(loadSubMenus(menu_id))
        } catch (error) {
          await Swal.fire('Erro',
            'Erro ao Remover',
            'error');
        }
      }
    })
  };
  return (
    <>
      {modal && (
        <Switch>
          <Route
            children={<Edit />}
            exact
            path="/menu/:menu_id/sub_menu/:sub_menu_id/edit"
          />
          <Route
            children={<Create />}
            exact
            path="/menu/:menu_id/sub_menu/novo"
          />
        </Switch>
      )}
      <div className="card">
        <div className="card-header">
          <h2 className="card-title">Sub Menus</h2>
          <div className="card-tools">
            <Link
              className="btn btn-success"
              to={{
                pathname: `/menu/${menu_id}/sub_menu/novo`,
                state: { modal: true },
              }}
            >
              <i className="fa fa-plus" /> Adicionar
            </Link>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered table-hover table-striped table-sm">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Nome</th>
                  <th>Icon</th>
                  <th>Path name</th>
                  <th className="text-right">Ações</th>
                </tr>
              </thead>
              <tbody>
                {menus.data &&
                  menus.data.map((menu, index) => (
                    <tr key={index}>
                      <td>{menu.id}</td>
                      <td>{menu.nome}</td>
                      <td className="text-center">
                        <i className={`fa fa-${menu.icon}`} />
                      </td>
                      <td>{menu.path_name}</td>
                      <td className="text-right">
                        <div className="btn-group">
                          <Link
                            className="btn btn-default"
                            to={{
                              pathname: `/menu/${menu_id}/sub_menu/${menu.id}/edit`,
                              state: { modal: true },
                            }}
                          >
                            <FaEdit /> Editar
                          </Link>
                          <button
                            className="btn btn-danger"
                            onClick={handleRemove}
                            value={menu.id}
                          >
                            <FaTrash /> Remover
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="card-footer clearfix">
          <Paginate load={loadSubMenus} loadParans={menu_id} meta={meta} />
        </div>
      </div>
    </>
  );
};

export default List;
