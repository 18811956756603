import { apiWithToken } from '../api';

const token = localStorage.getItem('@token');
let api = apiWithToken(token);

const resouceUrl = '/tecnico_profissional';

export const load = async (params) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let page = urlParams.has('page')
    ? { page: urlParams.get('page') }
    : {};
  let search = urlParams.has('search')
    ? { search: urlParams.get('search') }
    : {};
  return await api.get(`${resouceUrl}`, {
    params: {
      ...params,
      ...page,
      ...search
    }
  })
}


export const load_minhas_apias = async (params) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let page = urlParams.has('page') ? { page: urlParams.get('page') } : {};
  let search = urlParams.has('search')
    ? { search: urlParams.get('search') }
    : {};
  return await api.get(`${resouceUrl}/minhas_apias`, {
    params: {
      ...params,
      ...page,
      ...search,
    },
  });
};



export const show = async (id) => {
  return await api.get(`${resouceUrl}/${id}`);
}

export const update = async (id, tecnico_profissional) => {
  return await api.put(`${resouceUrl}/${id}`, tecnico_profissional);
}


export const store = async (tecnico_profissional) => {
  let response = await api.post(resouceUrl , tecnico_profissional);
  return response;
}

export const destroy = async (id) => {
  return await api.delete(`${resouceUrl}/${id}`);
}
