import React from 'react';
import { Link } from 'react-router-dom';
const Breadcrumb = ({ breadcrumbs }) => {
  return (
    <ol className="breadcrumb float-sm-right">
      {breadcrumbs.map((link, index) => (
        <li
          className={`${'breadcrumb-item'} ${link.active && 'active'}`}
          key={index}
        >
          <Link to={link.to}>{link.label}</Link>
        </li>
      ))}
    </ol>
  );
};
export default Breadcrumb;
