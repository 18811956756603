import React, { useState } from 'react';
import { Modal, Alert } from 'react-bootstrap';

export default function TermoUso() {
  const [show, setShow] = useState(false);

  return (
    <>
      <p>
       Eu concordo com os
        <Alert.Link
          className="primary"
          onClick={() => setShow(true)}
        > termos de uso.</Alert.Link>
      </p>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShow(!show)}
        show={show}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Termos de Uso - SIBLAM</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <strong>1. APRESENTAÇÃO</strong>
            <p className="mt-2">
              1.1 SIBLAM – Sistema Brasileiro de Licenciamento Ambiental, desenvolvido
              pela TUXTU Conectividade Ambiental e acessado pelo órgão gestor do meio
              ambiente urbano deste município. Todos os direitos reservados.
            </p>
            <p>
              1.2 O presente Acordo estabelece os termos obrigacionais que irão reger a
              relação jurídica entre o sistema SIBLAM e seus Usuários (individualmente
              referidos como Usuário).
            </p>
            <p>
              1.3 O presente Acordo de Utilização é um contrato, ainda que firmado por meio
              eletrônico, e possui eficácia e validade jurídicas, em conformidade com a
              legislação civil em vigor (artigos 422 e 425 do Código Civil, Lei nº 10.406/2002).
            </p>
            <p>
              1.4 O objetivo deste contrato é assegurar a efetivação de uma política de
              transparência, privacidade e segurança para ambas as partes envolvidas, que
              permita a melhor utilização do sistema por seus Usuários.
            </p>
            <p>
              1.5 Ao acessar SIBLAM e cadastrar-se, é preciso que você leia e concorde
              com os termos do acordo de utilização, o qual poderá ser atualizado, visando
              adequação à legislação vigente e aos novos serviços.
            </p>
            <p>
              1.6 Ao utilizar os serviços do SIBLAM, é preciso que você conheça
              determinados deveres, abaixo detalhados, podendo ensejar eventuais medidas
              judiciais, em caso de prejuízo em face da entidade contratante e/ou de
              terceiros.
            </p>
            <p>
              1.7 O Usuário poderá ter acesso ao presente Acordo de Utilização, a qualquer
              tempo, através da página https://www.siblam.com.br
              1.8 Após a leitura atenta do teor deste contrato, e se for de seu interesse,
              indique – na caixa de aceitação – a sua concordância com o mesmo. Em caso
              de concordância com os termos do acordo de utilização dos serviços, marque a
              opção correspondente.
            </p>
            <p>
              1.9 Uma vez concordado, os deveres e as normas aqui transcritos considerar-
              se-ão lidos e deverão ser obrigatoriamente obedecidos. A partir do momento
              em que os serviços prestados neste sistema forem utilizados, a sua aceitação a
              este Termo estará sendo ratificada, indicando que o Usuário leu e concorda
              com o que está inserido em suas cláusulas.
            </p>
            <hr />
            <strong>2. DO SERVIÇO UTILIZADO</strong>
            <p className="mt-2">
              2.1 O SIBLAM oferece serviços de emissão de licença ambiental via internet. A
              oferta desta modalidade não está sujeita a qualquer tipo de autorização prévia
              pelo simples fato de ser fornecida por meio eletrônico.
            </p>
            <p>
              2.2 O órgão ambiental contratante se responsabiliza pela execução de
              eventuais serviços online que oferece aos seus Usuários, tendo
              responsabilidade pelos conteúdos exibidos, mensagens enviadas e recebidas.
            </p>
            <p>
              2.3 Os serviços contidos no SIBLAM somente serão oferecidos quando o
              sistema estiver no ar e disponível para acesso. Caso, temporariamente, o
              acesso ao sistema esteja interrompido para manutenção ou por outra razão, a
              prestação do serviço automaticamente será interrompida.
            </p>
            <p>
              2.4 Caso novos serviços sejam incorporados ao sistema SIBLAM, estes serão
              incluídos no presente Termo de Uso, incidindo, automaticamente, as regras já
              em vigor.
            </p>
            <p>
              2.5 O Usuário tem conhecimento e concorda que o serviço é fornecido pelo
              sistema SIBLAM mediante acesso a World Wide Web através de dispositivos
              próprios para tanto, se responsabilizando, o Usuário, pelo equipamento técnico
              necessário como computador, softwares, modem etc, bem como pelo provedor
              de acesso à Internet.
            </p>
            <p>
              2.6 O Usuário, ao se cadastrar no SIBLAM, receberá, automaticamente em seu
              e-mail registrado neste sistema, a confirmação da sua inscrição.
            </p>
            <p>
              2.7 Caso o Usuário verifique algum dado errado, poderá o mesmo acessar a
              área Meus Dados, no sistema, e retificá-los a qualquer momento.
            </p>
            <p>
              2.8 As imagens do SIBLAM, sejam do modelo ou do banco de imagens
              somente poderão ser reproduzidas mediante autorização prévia de seu
              administrador, não podendo ser copiadas, baixadas, compartilhadas nem
              transferidas para nenhum outro computador, sistema ou hospedagem.
            </p>
            <hr />
            <strong>3. DO CADASTRO</strong>
            <p className="mt-2">
              3.1 O Usuário do SIBLAM se compromete a fornecer dados pessoais
              verdadeiros, precisos, atuais e completos durante o procedimento de registro,
              cadastramento de seus dados e de residência, bem como a manter atualizadas
              as informações prestadas quando do cadastro.
            </p>
            <p>
              3.2 Referidos dados se prestam a auxiliar o SIBLAM a manter um registro
              detalhado da conta de seus Usuários, auxiliando-os na execução dos serviços
              prestados ao usuário.
            </p>
            <p>
              3.3 O endereço de correio eletrônico informado no ato do cadastro será
              utilizado somente para os fins autorizados pelos Usuários, assim como para
              responder às dúvidas ou enviar informações e serviços sobre o sistema aos
              Usuários.
            </p>
            <p>
              3.4 O fornecimento de falsa informação, ou a utilização de informações de
              terceiros, constitui crime qualificado no Código Penal Brasileiro. Caso decorra
              algum dano ou prejuízo a terceiros ou ao município/órgão contratante, em
              função da atitude ilegal de algum de seus Usuários, serão imputadas ao
              declarante as devidas sanções penais e cíveis.
            </p>
            <p>
              3.5 Caso haja suspeita ou fortes indícios da má utilização dos serviços do
              sistema por algum Usuário, o SIBLAM poderá excluir o usuário mediante
              notificação ou aviso prévio a ser enviado por e-mail, bem como recusar o novo
              cadastro do Usuário que tenha violado alguma das cláusulas inseridas neste
              Acordo.
            </p>
            <p>
              3.6 Ao início do processo de cadastramento, o Usuário deverá escolher uma
              senha e uma identificação pessoal, sendo, ele próprio, unicamente responsável
              pela senha que escolher e por mantê-la em sigilo.
            </p>
            <p>
              3.7 O Usuário compromete-se a notificar o sistema SIBLAM sobre qualquer uso
              não autorizado de sua senha ou conta, bem como qualquer quebra de
              segurança que possa vir a ocorrer.
            </p>
            <hr />
            <strong>4. DA POLÍTICA DE PRIVACIDADE E SEGURANÇA</strong>
            <p className="mt-2">
              4.1 O SIBLAM possui uma política de privacidade e segurança que visa
              proteger os seus Usuários contra o uso indevido de suas informações pessoais
              por terceiros.
            </p>
            <p>
              4.2 O SIBLAM não venderá ou compartilhará suas informações pessoais com
              terceiros. Somente mediante sua expressa autorização ou através de
              autorização judicial é que essas informações poderão ser divulgadas à
              autoridade solicitante. Caso o Usuário disponibilize suas informações
              constantes no sistema SIBLAM a terceiros, será de sua inteira
              responsabilidade o uso que esses venham a fazer das mesmas.
            </p>
            <p>
              4.3 Para garantir a sua segurança, é recomendável que você altere sua senha
              de uso pessoal para acessar seu sistema ou sua conta de suporte,
              periodicamente, e que não a repasse a terceiros.
            </p>
            <p>
              4.4 As senhas são encriptadas em nosso banco de dados, em caso de
              esquecimento, o SIBLAM enviará a um link para troca de senha para o
              endereço eletrônico informado em seu cadastro, assim que solicitado pelo link
              “Esqueci minha senha” na página de login.
            </p>
            <p>
              4.5 Ao acessar este sistema, poderão ser coletadas informações através de
              cookies e outros meios, como seu IP, Browser, idioma, etc., para melhorar a
              execução do sistema.
            </p>
            <p>
              4.6 Em caso de alterações na política de segurança do SIBLAM, o Usuário
              será avisado, seja através de seu e-mail ou por meio de anúncios de destaque
              em nossa página.
            </p>
            <hr />
            <strong>5. DA POLÍTICA DE SEGURANÇA</strong>
            <p className="mt-2">
              5.1 O SIBLAM utiliza a adequada tecnologia disponível no mercado para
              garantir a segurança e a proteção de seus dados contra o acesso de pessoas
              não autorizadas ou o uso inapropriado.
            </p>
            <p>
              5.2 Sua senha pessoal é confidencial e criptografada, o que garante seu sigilo.
              Somente mediante sua senha pessoal, você estará apto a alterar seus dados
              pessoais. Aconselhamos que você utilize uma sequência de algarismos ou de
              letras que não lhe identifique diretamente.
            </p>
            <p>
              5.3 O SIBLAM não se responsabiliza, e não poderá ser responsabilizado, pelo
              uso que terceiros façam das informações de acesso que o Usuário tenha
              fornecido a eles, tais como senha, login ou outra informação semelhante.
            </p>
            <hr />
            <strong>6. DOS COOKIES</strong>
            <p className="mt-2">
              6.1 O Usuário, a partir do presente termo, autoriza, expressamente, que o
              SIBLAM utilize recursos, tais como, mas não limitados, a “cookies”, a fim de
              oferecer um serviço melhor e otimizado.
            </p>
            <p>
              6.2 Cookie representa uma pequena quantidade de dados, usualmente
              incluindo um identificador anônimo único, enviado a você por uma página da
              web e armazenado no disco do seu computador.
            </p>
            <p>
              6.3 O Usuário pode configurar o seu navegador para aceitar todos os cookies,
              rejeitá-los ou ser notificado quando um cookie é enviado.
            </p>
            <p>
              6.4 Como cada navegador faz isso de uma maneira diferente, cheque a
              ferramenta de ajuda constante de seu navegador para saber como modificar as
              suas preferências sobre cookies.
            </p>
            <p>
              6.5 ATENÇÃO: Se o Usuário optar por rejeitar todos os cookies em seu
              navegador, não conseguirá utilizar todos os recursos e serviços oferecidos pelo
              SIBLAM.
            </p>
            <hr />
            <strong>7. DAS OBRIGAÇÕES DO USUÁRIO</strong>
            <p className="mt-2">
              7.1 O Usuário se responsabiliza pela titularidade das informações que
              transmite ao sistema SIBLAM.
            </p>
            <p>
              7.2 O Usuário deve fornecer dados corretos, exatos, precisos e atuais. Cada
              Usuário é pessoalmente responsável pelos dados que fornece.
            </p>
            <p>
              7.3 O SIBLAM não se responsabiliza pela veracidade das informações
              prestadas por seus Usuários.
            </p>
            <p>
              7.4 O fornecimento de falsas informações configura crime de falsidade
              ideológica, tipificado no art. 299 do Código Penal.
            </p>
            <p>
              7.5 O usuário se obriga a cumprir e respeitar todos os termos do Digital
              Millennium Copyright Act, do Marco Civil da Internet, e da Lei Geral de
              Proteção de Dados.
            </p>
            <p>
              7.6 O Usuário não poderá solicitar a qualquer outro Usuário ou terceiro que aja
              em seu nome, a menos que seja mediante procuração.
            </p>
            <p>
              7.7 O Usuário não poderá violar, intencionalmente ou não, qualquer norma
              legal presente no sistema legal brasileiro e internacional, tampouco alegar
              desconhecimento das disposições normativas vigentes.
            </p>
            <p>
              7.8 O Usuário não poderá intervir no sistema de transmissão do SIBLAM nem
              mesmo copiar, reproduzir, duplicar ou fazer uso do conteúdo do sistema para
              qualquer finalidade, sendo responsabilizado pelas práticas ilegais que fizer,
              nesse intento.
            </p>
            <hr />
            <strong>8. DO FORO DE ELEIÇÃO</strong>
            <p className="mt-2">
              8.1 Em conformidade com a legislação em vigor, o foro para dirimir eventuais
              controvérsias que decorram da prestação de serviços será o Município de
              Macapá/AP.
            </p>
            <hr />
            <strong>9. DAS DISPOSIÇÕES FINAIS</strong>
            <p className="mt-2">
              9.1 Este Acordo será regido pelas leis do Brasil e atende ao sistema legal
              brasileiro, aos princípios gerais de direito e as normas internacionais de tráfego
              digital.
            </p>
            <p>
              9.2 Caso o Usuário não concorde com determinada cláusula deste Termo, e
              obtenha judicialmente a nulidade da mesma, os demais dispositivos não serão
              prejudicados e permanecerão em vigor.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-default"
            onClick={() => setShow(!show)}
          >
            <i className="fa fa-window-close" /> Fechar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
