import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormCad } from '../components';
import Swal from 'sweetalert2';
import schema from './schema';
import { updateUser } from '~/store/modules/user/action';
import { Loading } from '~/components';
import { loadTipoUsers } from '~/store/modules/tipo_user/action';
import { loadEntidades } from '~/store/modules/entidade/action';
import { loadUser, loadUserPessoa } from '~/store/modules/user/action';
import initialData from './initialData';
import { initialData as pfInitialData } from '../../CadPessoaFisica/components/FormCad/InitialData';
import { initialData as pjInitialData } from '../../CadPessoaJuridica/components/FormCad/InitialData';
import { initialData as tpInitialData } from '../../CadTecnicoProfissional/components/FormCad/InitialData';
import shemaPessoaFisisca from '../components/FormCad/FieldsPessoaFisica/schemaValidations';
import schemaPessoaJuridica from '../components/FormCad/FieldsPessoaJuridica/schemaValidations';
import schemaTecnicoProfissional from '../components/FormCad/FieldsTecnicoProfissional/schemaValidations';
import removeMask from '~/utils/removeMask';

const Edit = () => {
    let history = useHistory();
    const pessoaFields = Object.assign(
        tpInitialData, pjInitialData, pfInitialData
    );
    const dispatch = useDispatch();
    const { id_user } = useParams();
    const [update, setUpdate] = useState(false);
    const [errorsForm, setErrors] = useState({});
    const [userEdit, setUserEdit] = useState(initialData);
    const [pessoa, setPessoa] = useState(pessoaFields);
    const { user } = useSelector(({ UserReducer }) => UserReducer);
    const { user_pessoa } = useSelector(({ UserReducer }) => UserReducer);
    const { errors } = useSelector(({ UserReducer }) => UserReducer);
    const { entidades } = useSelector(({ EntidadeReducer }) => EntidadeReducer);
    const { tipo_users } = useSelector(({ TipoUserReducer }) => TipoUserReducer);

    useEffect(() => {
        dispatch(loadUser(id_user));
        dispatch(loadUserPessoa(id_user));
        dispatch(loadTipoUsers());
        dispatch(loadEntidades({ raw_list: true }));
    }, [dispatch, id_user]);

    useEffect(() => {
        if (user.id && user.id === parseInt(id_user)) {
            setUserEdit(user);
        }
    }, [user, id_user]);

    useEffect(() => {
        setPessoa({
            ...user_pessoa,
            cpf_cnpj_tecnico: user_pessoa.cpf ?? user_pessoa.cnpj
        });
    }, [user_pessoa, id_user]);

    useEffect(() => {
        if (errors.status && errors.status === 422) {
            let server_errors = {};
            const response_errors = errors.data.errors;
            for (let name of Object.keys(response_errors)) {
                Object.assign(server_errors, { [name]: response_errors[name][0] });
            }
            setErrors(server_errors);
        }
        if (errors.update) {
            setUpdate(errors.update);
        }
    }, [errors]);

    useEffect(() => {
        if (update) {
            Swal.fire('Sucesso', 'Registro editado com sucesso!', 'success').then(
                () => {
                    history.push('/cad_users');
                    // dispatch(loadResponsavelTecnicos());
                }
            );
        }
    }, [dispatch, update, history]);

    const handleClose = () => {
        history.push('/cad_users');
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const schemaValidatePessoaFisica = schema.concat(shemaPessoaFisisca);
        const schemaValidatePessoaJuridica = schema.concat(schemaPessoaJuridica);
        const schemaValidateTecnicoProfissional = schema.concat(schemaTecnicoProfissional);

        const fields = Object.assign(pessoa, userEdit);
        fields.tipo_user_id = parseInt(fields.tipo_user_id)
        let yup_errors = {};
        if (
            (fields.tipo_user_id === 1 ||
                fields.tipo_user_id === 2 ||
                fields.tipo_user_id === 3 ||
                fields.tipo_user_id === 4 ||
                fields.tipo_user_id === 8) &&
            !schemaValidatePessoaFisica.isValidSync(fields)
        ) {
            schemaValidatePessoaFisica.validate(fields, { abortEarly: false }).catch(err => {
                for (let error of err.inner) {
                    const { path, message } = error;
                    Object.assign(yup_errors, { [path]: message });
                }
                setErrors(yup_errors);
            });
        } else if (
            fields.tipo_user_id === 6 && !schemaValidatePessoaJuridica.isValidSync(fields)
        ) {
            schemaValidatePessoaJuridica.validate(fields, { abortEarly: false }).catch(err => {
                for (let error of err.inner) {
                    const { path, message } = error;
                    Object.assign(yup_errors, { [path]: message });
                }
                setErrors(yup_errors);
            });
        } else if (
            (
                fields.tipo_user_id === 5 ||
                fields.tipo_user_id === 7
            ) && !schemaValidateTecnicoProfissional.isValidSync(fields)

        ) {
            schemaValidateTecnicoProfissional.validate(fields, { abortEarly: false }).catch(err => {
                for (let error of err.inner) {
                    const { path, message } = error;
                    Object.assign(yup_errors, { [path]: message });
                }
                setErrors(yup_errors);
            });
        } else {
            switch (fields.tipo_user_id) {
                case 1: case 2: case 3: case 4: case 8:
                    fields.cpf_cnpj = removeMask(fields.cpf ?? '')
                    break;
                case 6:
                    fields.cpf_cnpj = removeMask(fields.cnpj ?? '')
                    break;
                case 7: case 5:
                    fields.cpf_cnpj = removeMask(fields.cpf_cnpj_tecnico ?? '')
                    break;
                default:
                    break;
            }

            fields.cep = removeMask(fields.cep ?? '');
            fields.cpf_responsavel_legal = removeMask(fields.cpf_responsavel_legal ?? '');
            fields.numero_whatsapp = removeMask(fields.numero_whatsapp ?? '');
            fields.cpf = removeMask(fields.cpf ?? '')
            fields.cnpj = removeMask(fields.cnpj ?? '')
            fields.telefone = removeMask(fields.telefone ?? '')
            fields.telefone_responsavel_legal = removeMask(fields.telefone_responsavel_legal ?? '')

            const form = new FormData();

            form.append('_method', 'PUT');

            for (let key in fields) {
                if (fields[key]) {
                    form.append(key, fields[key]);
                }
            }

            dispatch(updateUser(id_user, form));
        }
    }

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="modal-xl"
            onHide={handleClose}
            show
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Editar usuário
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    (tipo_users.data && entidades.data) && (
                        <FormCad
                            onHandleSubmit={handleSubmit}
                            entidades={entidades.data}
                            tipo_users={tipo_users.data}
                            errors={errorsForm}
                            setErrors={setErrors}
                            usuario={userEdit}
                            setUsuario={setUserEdit}
                            pessoa={pessoa}
                            setPessoa={setPessoa}
                        />
                    )
                }

                {(!tipo_users.data || !entidades.data || !pessoa) && <Loading />}
            </Modal.Body>
        </Modal>
    );
};

export default Edit;
