import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Loading } from '~/components';
import { FaChartLine } from 'react-icons/fa';
import { FormResposta } from './components';

const IndicadoresAmbientais = ({
    processo_id,
    indicadores_ambientais,
    actions,
}) => {
    let [show, setShow] = useState(false);
    const [btnClass, setBtnClass] = useState('success');

    useEffect(() => {
        let ind_amb = [];

        if (indicadores_ambientais && indicadores_ambientais.length) {
            indicadores_ambientais.forEach((ind) => {
                if (ind.valor) {
                    ind_amb.push(ind);
                }
            });

            if (ind_amb.length === indicadores_ambientais.length) {
                setBtnClass('primary');
            } else {
                setBtnClass('success');
            }
        }
    }, [indicadores_ambientais]);

    return (
        <>
            {indicadores_ambientais && indicadores_ambientais.length >= 0 ? (
                <>
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                Indicadores Ambientais
                            </h3>
                        </div>
                        <div className="card-body">
                            <button
                                className={`btn btn-${btnClass} btn-block`}
                                onClick={() => setShow(true)}
                            >
                                <FaChartLine /> Indicadores Ambientais
                            </button>
                        </div>
                    </div>
                    <Modal
                        animation
                        aria-labelledby="example-custom-modal-styling-title"
                        dialogClassName="modal-xl"
                        onHide={() => setShow(false)}
                        show={show}
                    >
                        <Modal.Header closeButton>
                            <div>
                                <Modal.Title>
                                    Indicadores Ambientais
                                </Modal.Title>
                                <a
                                    href="https://cia.tuxtu.com.br"
                                    target="_blank"
                                >
                                    Para calcular os Indicadores Ambientais
                                    acesso a Calculadora de
                                    Indicadores Ambientais.
                                </a>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <table className="table table-sm table-striped table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>Descrição</th>
                                        <th>Índice</th>

                                        {actions ? (
                                            <th>Ação</th>
                                        ) : (
                                            <th>Valor</th>
                                        )}
                                        <th>Unidade</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {indicadores_ambientais.map(
                                        (
                                            {
                                                id,
                                                indice,
                                                descricao,
                                                unidade,
                                                valor,
                                                indicador_processo_id,
                                            },
                                            index
                                        ) => (
                                            <tr key={index}>
                                                <td>{descricao}</td>
                                                <td>{indice}</td>

                                                {actions ? (
                                                    <td
                                                        style={{
                                                            width: '200px',
                                                        }}
                                                    >
                                                        <FormResposta
                                                            indicador_id={id}
                                                            indicador_processo_id={
                                                                indicador_processo_id
                                                            }
                                                            processo_id={parseInt(
                                                                processo_id
                                                            )}
                                                            valor={valor}
                                                        />
                                                    </td>
                                                ) : (
                                                    <td>{valor}</td>
                                                )}
                                                <td>{unidade}</td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                className="btn btn-default"
                                onClick={() => setShow(false)}
                                type="button"
                            >
                                <i className="fa fa-window-close" /> Fechar
                            </button>
                        </Modal.Footer>
                    </Modal>
                </>
            ) : (
                <Loading />
            )}
        </>
    );
};

export default IndicadoresAmbientais;
