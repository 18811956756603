import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import {Row, Col} from 'react-bootstrap';
import { Loading } from '~/components';
import { BasicContent, MenuTree, Recursos } from '../components';
import {
  showTipoUser,
  loadMenus,
  loadRecursos,
} from '~/store/modules/tipo_user/action';


const List = () => {
  const dispatch = useDispatch();
  const { tipo_user_id } = useParams();
  const [tipoTipoUserShow, setTipoUserShow] = useState({});


  const { tipo_user } = useSelector(({ TipoUserReducer }) => TipoUserReducer);

  useEffect(() => {
    const showTipoUserEffect = async tipo_user_id => {
      await dispatch(showTipoUser(tipo_user_id));
      await dispatch(loadMenus(tipo_user_id));
      await dispatch(loadRecursos(tipo_user_id));
    };

    showTipoUserEffect(tipo_user_id);

  }, [dispatch, tipo_user_id]);
  useEffect(() => {
    if (tipo_user.id === parseInt(tipo_user_id)) {
      setTipoUserShow(tipo_user);
    }
  }, [tipo_user, tipo_user_id]);

  const breadcrumb = {
    label: 'Detalhes',
    to: `tipo_user/${tipo_user_id}/detalhe`,
    active: false,
  };

  return (
    <BasicContent breadcrumb={breadcrumb}>
      <Row>
        <Col>
          <div className="card">
            <div className="card-header">
              <h2 className="card-title">Detalhes</h2>
              <div className="card-tools">
                <Link className="btn btn-default" to="/tipo_user">
                  <FaArrowAltCircleLeft /> Voltar
                </Link>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                {tipoTipoUserShow.id === parseInt(tipo_user_id) ? (
                  <table className="table table-hover table-striped">
                    <tbody>
                      <tr>
                        <th>id</th>
                        <td>{tipo_user.id}</td>
                      </tr>
                      <tr>
                        <th>Nome</th>
                        <td>{tipo_user.nome}</td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <Loading />
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {tipoTipoUserShow.id === parseInt(tipo_user_id) ? (
            <MenuTree/>
          ) : (
            <div className="card">
              <div className="card-header">
                <h2 className="card-title">Menus</h2>
              </div>
              <div className="card-body">
                <Loading />
              </div>
            </div>
          )}
        </Col>
        <Col>
          {tipoTipoUserShow.id === parseInt(tipo_user_id) ? (
            <Recursos/>
          ) : (
            <div className="card">
              <div className="card-header">
                <h2 className="card-title">Pemissões</h2>
              </div>
              <div className="card-body">
                <Loading />
              </div>
            </div>
          )}
        </Col>
      </Row>
    </BasicContent>
  );
};

export default List;
