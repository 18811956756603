import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { FiEdit3  } from 'react-icons/fi';

const AnaliseImpactoAmbiental = () => {
  const [show, setShow] = useState(false);
  const [medias, setMedias] = useState({ resumo: [] });

  const { meio_analises_with_respostas, meio_analises_with_respostas_apia } = useSelector(
    ({ MeioAnaliseReducer }) => MeioAnaliseReducer
  );

  const calcPorcentagem = (giap, resumo) => {
    resumo.forEach((item) => {
      item.porcentagem = ((item.total * 100) / giap).toFixed(2);
    })
  }

  const calcGiapIndice = (resumo = []) => {
    if(!resumo){
      return 0;
    }
    let giap = 0;
    let total_questoes = 0;
    resumo.forEach((item)=> {
      giap += item.total;
      total_questoes += item.total_questoes;
    });
    return {giap, indice: giap / total_questoes };
  }

  const gerarResumo = (meioAnalises = []) => {
    if(!meioAnalises){
      return [];
    }
    const resumo = [];
    meioAnalises.forEach((meioAnalise) => {
      meioAnalise.tipo_meio_analises.forEach((tipoMeio) => {
        let itemResumo = {
          nome: tipoMeio.nome,
          total: 0,
          porcentagem: 0,
          total_questoes: 0,
        };
        tipoMeio.questao_analises.forEach((questao_analise) => {
          if(questao_analise.resposta_questao_analise){
            itemResumo.total_questoes++;
            questao_analise.resposta_questao_analise.forEach((resposta) => {
              itemResumo.total += parseFloat(resposta.valor);
            })
          }else{
            itemResumo.total_questoes++;
            questao_analise.resposta_questao_analise_previas.forEach((resposta_previa) => {
              itemResumo.total += parseFloat(resposta_previa.valor);
            })
          }
        })
        resumo.push(itemResumo);
      })
    })
    return resumo;
  }

  useEffect(() => {
    if(meio_analises_with_respostas.data && meio_analises_with_respostas_apia.data){
      const resumo = [];
      const resumoATIA = gerarResumo(meio_analises_with_respostas.data);
      const resumoAPIA = gerarResumo(meio_analises_with_respostas_apia.data);

      resumoAPIA.forEach((item) => {
        const subItem = resumoATIA.find((obj) => obj.nome === item.nome);
        resumo.push({
          ...item,
          total: (item.total + subItem.total) / 2,
        })
      });

      const {giap, indice} = calcGiapIndice(resumo);
      calcPorcentagem(giap, resumo);
      setMedias({
        giap: giap.toFixed(2),
        indice: indice.toFixed(2),
        resumo
      });
    }
  },[meio_analises_with_respostas, meio_analises_with_respostas_apia])

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Análise de Impacto Ambiental</h3>
        </div>
        <div className="card-body">
          <button
            className="btn btn-primary btn-block"
            onClick={() => setShow(true)}
          >
            <FiEdit3 /> Análise de Impacto Ambiental
          </button>
        </div>
      </div>
      <Modal
        animation
        aria-labelledby="example-custom-modal-styling-title"
        centered
        dialogClassName="modal-xl"
        onHide={() => setShow(!show)}
        show={show}
      >
        <Modal.Header closeButton>
          <Modal.Title>Análise de Impacto Ambiental</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th
                        className="text-center"
                        colSpan="5"
                      >
                     GRAU DE IMPACTO AMBIENTAL
                      </th>
                    </tr>
                    <tr>
                      <th>Ordem</th>
                      <th>COMPONENTES</th>
                      <th>Análise Final</th>
                      <th>Índice de Impacto Ambiental</th>
                      <th>%</th>
                    </tr>
                  </thead>
                  <tbody>
                    {medias.resumo.map((media, index) => (
                      <tr key={`key-${index}`}>
                        <th className="text-center">{++index}</th>
                        <td>Impacto {media.nome}</td>
                        <td className="text-center">{media.total.toFixed(2)}</td>
                        <td className="text-center">{media.total.toFixed(2)}</td>
                        <td className="text-right">{media.porcentagem}</td>
                      </tr>
                    ))}
                    <tr>
                      <th/>
                      <th>GIA do Empreendimento</th>
                      <th className="text-center">{medias && medias.giap}</th>
                      <th className="text-center">{medias && medias.indice}</th>
                      <th className="text-right">100</th>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="table-responsive">
                <table className="table table-bordered table-striped table-hover">
                  <tbody className="text-center">
                    <tr>
                      <th colSpan="3">
                     ESCALA DE ENQUADRAMENTO DO GRAU DE IMPACTO AMBIENTAL
                      </th>
                    </tr>
                    <tr>
                      <th className="table-info">PEQUENO</th>
                      <th className="table-success">MÉDIO</th>
                      <th className="table-danger">ALTO</th>
                    </tr>
                    <tr>
                      <td>0 - 3,32</td>
                      <td>3,33 - 6,66</td>
                      <td>6,67 - 10,00</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="table-responsive">
                <table className="table table-bordered">
                  <tbody className="text-center">
                    <tr
                      className={`
                     ${medias && medias.indice <= 3.32 && 'table-info'}
                     ${medias && medias.indice >= 3.33 &&  medias.indice <= 6.66 && 'table-success'}
                     ${medias && medias.indice >= 6.67 && 'table-danger'}
                   `}
                    >
                      <th>GIAp = {medias && medias.indice} ↓↑</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AnaliseImpactoAmbiental;
