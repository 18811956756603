import React, { useEffect, useState, useMemo } from 'react';
import { Content } from '~/layouts/Dash/components';
import { Button } from 'react-bootstrap';
import formatForMask from '~/utils/formatForMask';
import { Link, Switch, Route, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaEdit, FaTrash, FaReply } from 'react-icons/fa';
// import { BasicContent } from '../components';
import { Loading, Paginate } from '~/components';
import { loadUsers, removeUser, restoreUser } from '~/store/modules/user/action';
import Create from '../Create';
import Edit from '../Edit';
import Filtro from '../Filtro';
import Swal from 'sweetalert2';

export default function CadUsuario() {
    let location = useLocation();
    let modal = location.state && location.state.modal;
    const [showModal, setShowModal] = useState(false);
    const [clearFilter, setClearFilter] = useState(false)
    const { users } = useSelector(({ UserReducer }) => UserReducer);
    const dispatch = useDispatch();
    const loadUsersParams = useMemo(() => ({
        with: 'pessoa,tipo_usuario'
    }), []);

    useEffect(() => {
        dispatch(loadUsers(loadUsersParams));
        setClearFilter(false)
    }, [dispatch, modal, clearFilter, loadUsersParams]);

    const handleModal = () => {
        setShowModal(true)
    }

    const handleRemove = (user) => {
        Swal.fire({
            title: 'Você tem certeza que deseja remover este usuário?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, remova!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(removeUser(user, loadUsersParams));
            }
        }).then(() => {
            Swal.fire(
                'Removido!',
                'Seu registro foi removido.',
                'success'
            )
        }).catch((error) => {
            Swal.fire(
                'Erro!',
                'Ocorreu um erro ao apagar o registro.',
                'error'
            )
        });
    }

    const handleRestore = (user) => {
        Swal.fire({
            title: 'Você tem certeza que deseja restaurar este usuário?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, restaure!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(restoreUser(user, loadUsersParams));
            }
        }).then(() => {
            Swal.fire(
                'Restaurado!',
                'Seu registro foi restaurado.',
                'success'
            )
        }).catch((error) => {
            Swal.fire(
                'Erro!',
                'Ocorreu um erro ao restaurar o registro.',
                'error'
            )
        });
    }

    const breadcrumbs = [
        {
            label: 'Cadastro de usuários',
            to: '/cad_users',
            active: false,
        },
        {
            label: 'Lista',
            to: '/cad_users',
            active: true,
        },
    ];

    return (
        <>
            {modal && (
                <Switch>
                    <Route
                        children={<Create />}
                        path="/cad_users/create"
                    />
                    {/* <Route
                        children={<Show />}
                        exact
                        path="/tecnicos/responsavel_tecnico/:responsavel_tecnico_id/detalhe"
                    /> */}
                    <Route
                        children={<Edit />}
                        exact
                        path="/cad_users/:id_user/edit"
                    />
                </Switch>
            )}
            <Content
                breadcrumbs={breadcrumbs}
                title="CADASTRO DE USUÁRIOS"
            >
                <div className="card">
                    <div className="card-header">
                        <h2 className="card-title">Lista</h2>

                        <div className="card-tools">
                            <Link
                                className="btn btn-success"
                                to={{
                                    pathname: '/cad_users/create',
                                    state: { modal: true },
                                }}
                            >
                                <i className="fa fa-plus" /> Novo
                            </Link>
                            <Button
                                className="btn btn-primary m-1"
                                onClick={handleModal}
                            >
                                <i className="fa fa-filter" /> Filtros
                            </Button>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-sm table-bordered table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Nome</th>
                                        <th>Tipo de usuário</th>
                                        <th>CNPJ/CPF</th>
                                        <th>Email</th>
                                        <th className="text-right">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.data &&
                                        users.data.map((user, index) => (
                                            <tr key={index}>
                                                <td>{user.id}</td>
                                                <td>{user.nome}</td>
                                                <td>{user.tipo_usuario?.nome}</td>
                                                <td>
                                                    {user.pessoa?.cnpj ? formatForMask(
                                                        user.pessoa?.cnpj,
                                                        '##.###.###/####-##'
                                                    ) : ''}
                                                    {user.pessoa?.cpf ? formatForMask(
                                                        user.pessoa?.cpf,
                                                        '###.###.###-##'
                                                    ) : ''}
                                                </td>
                                                <td>{user.email}</td>
                                                <td>
                                                    <div className="btn-group float-right">
                                                        {/* <Link
                                                            className="btn btn-info"
                                                            to={{
                                                                pathname: `/empreendimentos/tecnico_profissional/${user.id}/detalhe`,
                                                                state: { modal: true },
                                                            }}
                                                        >
                                                            <FaEye /> Detalhes
                                                        </Link> */}
                                                        <Link
                                                            className="btn btn-default"
                                                            to={{
                                                                pathname: `/cad_users/${user.id}/edit`,
                                                                state: { modal: true },
                                                            }}
                                                        >
                                                            <FaEdit /> Editar
                                                        </Link>
                                                        {user.deleted_at ? (
                                                            <button
                                                                type="button"
                                                                className="btn btn-success"
                                                                onClick={() => handleRestore(user)}
                                                            >
                                                                <FaReply /> Restaurar
                                                            </button>
                                                        ) : (
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger"
                                                                onClick={() => handleRemove(user)}
                                                            >
                                                                <FaTrash /> Remover
                                                            </button>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                            {!users && <Loading />}
                        </div>
                    </div>
                    <div className="card-footer clearfix">
                        <Paginate
                            load={() => loadUsers({
                                with: 'pessoa,tipo_usuario'
                            })}
                            meta={users}
                        />
                    </div>
                </div>
            </Content>
            <Filtro
                showModal={showModal}
                setShowModal={setShowModal}
                setClearFilter={setClearFilter}
            />
        </>
    );
}
