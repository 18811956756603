export const LOAD_ENTIDADE = 'LOAD_ENTIDADE';
export const ENTIDADE_ERROR = 'ENTIDADE_ERROR';
export const ENTIDADE_DATA = 'ENTIDADE_DATA';
export const STORE_ENTIDADE = 'STORE_ENTIDADE';
export const GET_ENTIDADE = 'GET_ENTIDADE';
export const UPDATE_ENTIDADE = 'UPDATE_ENTIDADE';
export const SHOW_ENTIDADE = 'SHOW_ENTIDADE';
export const ATIVAR_DESATIVAR_ENTIDADE = 'ATIVAR_DESATIVAR_ENTIDADE';
export const CHANGE_STATUS_CODE = 'CHANGE_STATUS_CODE';
export const REGISTER_FINANCIAL_ACCOUNT = 'REGISTER_FINANCIAL_ACCOUNT';
export const CHANGE_FINANCIAL_PASSWORD = 'CHANGE_FINANCIAL_PASSWORD';
// export const MAKE_FINANCIAL_ACCOUNT = 'MAKE_FINANCIAL_ACCOUNT';
