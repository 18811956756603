import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormEdit } from '../components';
import Swal from 'sweetalert2';
import schema from './schema'
import { showLegislacao, loadLegislacoes, updateLegislacao } from '~/store/modules/legislacao_atividade/action';
import { Loading } from '~/components';
import initialData from './initialData';

const Edit = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { legislacao_id, atividade_id }  = useParams();
  const { status_response, errors, legislacao } = useSelector(({ LegislacaoReducer }) => LegislacaoReducer);
  const [saved, setSaved] = useState(false);
  const [legislacaoEdit, setLegislacaoEdit] = useState(initialData);
  const [errorsValidate, setErrorsValidate] = useState([]);

  useEffect(() => {
    dispatch(showLegislacao(legislacao_id));
  }, [dispatch, legislacao_id]);

  useEffect(() => {
    if (errors.status && errors.status === 422) {
      let server_errors = {};
      const response_errors = errors.data.errors;
      for (let name of Object.keys(response_errors)) {
        Object.assign(server_errors, { [name]: response_errors[name][0] });
      }
      setErrorsValidate(server_errors);
    }
    if(errors.update) {
      setSaved(errors.update);
    }
  }, [errors]);

  useEffect(() => {
    if (Object.keys(legislacao).length > 0) {
      setLegislacaoEdit({
        tipo: legislacao.tipo,
        legislacao: legislacao.legislacao,
        descricao: legislacao.descricao
      });
    }
  }, [legislacao, status_response]);

  useEffect(() => {
    if (saved && status_response === 200) {
      Swal.fire('Sucesso', 'Registro editado com sucesso!', 'success').then(
        () => {
          history.goBack();
          dispatch(loadLegislacoes({
            where:`atividade_id:${atividade_id}`,
          }));
        }
      );
    }
  }, [status_response, history, dispatch, saved, atividade_id]);


  const handleClose = () => {
    history.goBack();
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setErrorsValidate([]);
    if (!schema.isValidSync(legislacaoEdit)) {
      let yup_errors = {};
      schema.validate(legislacaoEdit, { abortEarly: false }).catch(err => {
        for (let error of err.inner) {
          const { path, message } = error;
          Object.assign(yup_errors, { [path]: message });
        }
        setErrorsValidate(yup_errors);
      });
    } else {
      const nova_legislacao = legislacaoEdit;
      nova_legislacao.atividade_id = parseInt(atividade_id);
      await dispatch(updateLegislacao(nova_legislacao, legislacao_id));
    }
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-xl"
      onHide={handleClose}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Legislações da Atividade
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Editar</h2>
          </div>
          <div className="card-body">
            {
              legislacaoEdit ?
                (
                  <FormEdit
                    errors={errorsValidate}
                    handleSubmit={handleSubmit}
                    legislacao={legislacaoEdit}
                    schema={schema}
                    setLegislacao={setLegislacaoEdit}
                  />
                ):
                (
                  <Loading />
                )
            }
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-default"
          onClick={handleClose}
        >
          <i className="fa fa-window-close" /> Cancelar
        </button>
        <button
          className="btn btn-success"
          form="form_legislacao"
          type="submint"
        >
          {' '}
          <i className="fa fa-save" /> Editar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Edit;
