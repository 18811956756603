import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory, useParams} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormCad } from '../components';
import Swal from 'sweetalert2';
import initialData from './initialData';
import schema from './schema';
import { storeAlternativaQuestaoAnalise, loadAlternativaQuestaoAnalises, changeStatus} from '~/store/modules/alternativa_questao_analise/action';
const Create = () => {
  let history = useHistory();

  const {questao_analise_id} = useParams();

  const [ alternativaQuestaoAnalise, setAlternativaQuestaoAnalise] = useState(initialData);

  const [errorsValidate, setErrorsValidate] = useState([]);
  const dispatch = useDispatch();
  const { status_response, errors } = useSelector(({ AlternativaQuestaoAnaliseReducer }) => AlternativaQuestaoAnaliseReducer);


  useEffect(() => {
    if (errors.status && errors.status === 422) {
      let server_errors = {};
      const response_errors = errors.data.errors;
      for (let name of Object.keys(response_errors)) {
        Object.assign(server_errors, { [name]: response_errors[name][0] });
      }
      setErrorsValidate(server_errors);
    }
  }, [errors]);


  useEffect(() => {
    if (status_response === 201) {
      Swal.fire('Sucesso', 'Registro salvo com sucesso!', 'success').then(
        () => {
          history.goBack();
          dispatch(loadAlternativaQuestaoAnalises({
            where:`questao_analise_id:${questao_analise_id}`
          }));
          dispatch(changeStatus(null));
        }
      );
    }
  }, [status_response, history, dispatch, questao_analise_id]);


  const handleClose = () => {
    history.goBack();
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (!schema.isValidSync( alternativaQuestaoAnalise)) {
      let yup_errors = {};
      schema.validate( alternativaQuestaoAnalise, { abortEarly: false }).catch(err => {
        for (let error of err.inner) {
          const { path, message } = error;
          Object.assign(yup_errors, { [path]: message });
        }
        setErrorsValidate(yup_errors);
      });
    } else {

      let alternativaQuestaoAnalise_ =  alternativaQuestaoAnalise;
      alternativaQuestaoAnalise_.questao_analise_id = parseInt(questao_analise_id);

      await dispatch(storeAlternativaQuestaoAnalise(alternativaQuestaoAnalise_));
    }
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-xl"
      onHide={handleClose}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         Nova alternativa
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Novo</h2>
          </div>
          <div className="card-body">
            <FormCad
              alternativaQuestaoAnalise={alternativaQuestaoAnalise}
              errors={errorsValidate}
              handleSubmit={handleSubmit}
              schema={schema}
              setAlternativaQuestaoAnalise={setAlternativaQuestaoAnalise}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-default"
          onClick={handleClose}
        >
          <i className="fa fa-window-close" /> Cancelar
        </button>
        <button
          className="btn btn-success"
          form="form_alternativa_questao_analise"
          type="submint"
        >
          {' '}
          <i className="fa fa-save" /> Salvar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Create;

