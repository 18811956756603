import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as NotaTecAmbiental from '~/services/NotaTecAmbiental';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadNotaTecAmbientalsWorker({params}) {
  try {
    const response = yield call(NotaTecAmbiental.load, params);
    yield put(actions.getNotaTecAmbientals(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}


function* showNotaTecAmbientalWorker({processo_id}) {
  try {
    const response = yield call(NotaTecAmbiental.show, processo_id);
    yield put(actions.getNotaTecAmbiental(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updateNotaTecAmbientalWorker({ nota_tec_ambiental, id}) {
  try {
    const response = yield call(NotaTecAmbiental.update, id, nota_tec_ambiental);
    yield put(actions.getNotaTecAmbiental(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}


function* storeNotaTecAmbientalWorker({nota_tec_ambiental}) {
  try {
    const response = yield call(NotaTecAmbiental.store, nota_tec_ambiental);
    yield put(actions.getNotaTecAmbiental(response));
    yield put(actions.getErros({save:true}));
    yield put(getErros({save:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}


function* watcherNotaTecAmbiental() {
  yield takeLatest(actionType.LOAD_NOTA_TEC_AMBIENTALS, loadNotaTecAmbientalsWorker);
  yield takeLatest(actionType.STORE_NOTA_TEC_AMBIENTAL, storeNotaTecAmbientalWorker);
  yield takeLatest(actionType.SHOW_NOTA_TEC_AMBIENTAL, showNotaTecAmbientalWorker);
  yield takeLatest(actionType.UPDATE_NOTA_TEC_AMBIENTAL, updateNotaTecAmbientalWorker);
}

export default function* saga() {
  yield all([watcherNotaTecAmbiental()]);
}
