import * as actions from './actionType';

export const DocumentoProcessoReducer = (state = {select_documento_processos: [], documento_processos: [], documento_processo: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.DOCUMENTO_PROCESSOS_DATA:
      return {
        ...state,
        documento_processos: action.documento_processos.data,
        links: action.documento_processos.data.links,
        meta: action.documento_processos.data.meta,
      };
    case actions.SELECT_DOCUMENTO_PROCESSOS:
      return {
        ...state,
        select_documento_processos: action.documento_processos.data
      };
    case actions.GET_DOCUMENTO_PROCESSO:
      return {
        ...state,
        documento_processo: action.documento_processo.data.data,
        status_response: action.documento_processo.status
      };
    case actions.DOCUMENTO_PROCESSO_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    case actions.CHANGE_STATUS_CODE:
      return {
        ...state,
        status_response: action.status,
      };
    default:
      return state;
  }
}
