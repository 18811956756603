import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useParams} from 'react-router-dom';
import { formatData } from '~/utils/formatDataHora';
import { formatForMonthYear } from '~/utils/formatForMonthYear'
import formatCpfCnpj from '~/utils/formatCpfCnpj';
import { toBRL } from '~/utils/formatCurrency';
import Barcode from '~/components/Barcode';
import formatBarcode from '~/utils/formatBarcode';
import { showProcesso } from '~/store/modules/processo/action';
import NavBarFixed from '~/components/NavBarFixed';
import QRCodeCanvas from '~/components/QRCode';

const GuiaArrecadacao = () => {

    const dispatch = useDispatch();
    const { processo } = useSelector(({ ProcessoReducer }) => ProcessoReducer);
    const { processo_id} = useParams();

    useEffect(() => {
        dispatch(
          showProcesso(processo_id, {
            with:
              'analise_previa,'
              + 'pessoa.estado_endereco,'
              + 'estado,'
              + 'municipio,'
              + 'guia_arrecadacao,'
              + 'entidade,'
              + 'tipo_licenca,'
              + 'atividade'
          })
        );
      }, [processo_id, dispatch]);

    // const handlePrint = () => {

    //     let css = '@page { size: 215mm 139mm; }, ', //A4 landscape //@page { size: A4; margin-left: 5mm, margin-right: 5mm }, @media print { html, body { width: 210mm; height: 297mm;
    //     head = document.head || document.getElementsByTagName('head')[0],
    //     style = document.createElement('style');

    //     style.type = 'text/css';
    //     style.media = 'print';

    //     if (style.styleSheet){
    //     style.styleSheet.cssText = css;
    //     } else {
    //     style.appendChild(document.createTextNode(css));
    //     }

    //     head.appendChild(style);
    //     window.print();
    // };
  return (
    <>
    <div className='d-flex justify-content-center'>
        <NavBarFixed />
    </div>
    <div className="container mt-5">
        <div className="container" style={{height: '145mm'}}>
            <div style={{height: '100mm', border: 'green'}}>
                <div className=' d-flex justify-content-center align-items-lg-center'>
                    <img src={process.env.PUBLIC_URL + '/asset/img/logoSIBLAM.svg'} alt="SIBLAM"></img>
                </div>
                <div className="row border border-dark">
                    <div className="col font-weight-bold small">
                    DADOS AMBIENTAIS
                    </div>
                </div>
                <div className="row  border-left border-right border-bottom border-dark ">
                    <div className="col text-md-right small">
                    Tipo do Serviço Ambiental
                    </div>
                    <div className="col border-left border-dark small">
                    Taxa de Licenciamento Ambiental <br />
                    </div>
                </div>
                <div className='row  border-left border-right border-bottom border-dark'>
                    <div className='col text-md-right small'>
                        Processo Ambiental
                    </div>
                    <div className='col border-left border-dark small'>
                        {processo.protocolo}
                    </div>
                </div>
                <div className='row  border-left border-right border-bottom border-dark'>
                    <div className='col text-md-right small'>
                        Objeto
                    </div>
                    <div className='col border-left border-dark small'>
                        Licenciamento Ambiental
                    </div>
                </div>
                <div className='row  border-left border-right border-bottom border-dark'>
                    <div className='col text-md-right small'>
                        Tipo
                    </div>
                    <div className='col border-left border-dark small'>
                        {processo.tipo_licenca?.nome}
                    </div>
                </div>
                <div className='row  border-left border-right border-bottom border-dark'>
                    <div className='col text-md-right small'>
                        Atividade
                    </div>
                    <div className='col border-left border-dark small'>
                        {processo.atividade?.nome}
                    </div>
                </div>
                <div className='row  border-left border-right border-bottom border-dark'>
                    <div className='col d-flex justify-content-between'>
                        <div className='small'>Período de apuração</div>
                        <div className='small'>{formatForMonthYear(processo.guia_arrecadacao?.created_at)}</div>
                    </div>
                    <div className='col d-flex justify-content-between border-left border-dark'>
                        <div className='small'>Data do documento</div>
                        <div className='small'>{formatData(processo.guia_arrecadacao?.created_at ?? '')}</div>
                    </div>
                </div>
                <div className="row border-bottom border-left border-right border-dark">
                    <div className="col font-weight-bold small">
                    DADOS DO PAGADOR
                    </div>
                </div>
                <div className='row border-left border-right border-bottom border-dark'>
                    <div className='col text-md-right small'>Nome</div>
                    <div className='col border-left border-dark small'>{processo.pessoa?.nome}</div>
                </div>
                <div className='row border-left border-right border-bottom border-dark'>
                    <div className='col text-md-right small'>CPF/CNPJ</div>
                    <div className='col border-left border-dark small'>{formatCpfCnpj(processo.pessoa?.cpf ?? '') || formatCpfCnpj( processo.pessoa?.cnpj ?? '')}</div>
                </div>
                <div className="row border-bottom border-left border-right border-dark">
                    <div className="col font-weight-bold small">
                    DADOS DO ENTE PÚBLICO
                    </div>
                </div>
                <div className='row border-left border-right border-bottom border-dark'>
                    <div className='col text-md-right small'>Instituição beneficiária</div>
                    <div className='col border-left border-dark'>
                        <div className='small'>
                            {processo.entidade?.nome}
                        </div>
                        <div className='small'>
                            {processo.entidade?.nome_orgao_ambiental}
                        </div>
                    </div>
                </div>
                <div className='row border-left border-right border-bottom border-dark'>
                    <div className='col text-md-right small'>CNPJ</div>
                    <div className='col border-left border-dark small'>{formatCpfCnpj(processo.entidade?.cnpj ?? '')}</div>
                </div>

            </div>
            <div className='row border-bottom border-dark' style={{height: '10mm'}}>
                <p>PAGAR PREFERENCIALMENTE NO BANCO DO BRASIL.</p>
            </div>
            <div className='row border-bottom border-dark' style={{height: '10mm'}}>
                <div className='col d-flex justify-content-between align-items-center border-left border-dark'>
                    <div className=''>
                        Data de vencimento
                    </div>
                    <div className=''>
                        {formatData(processo.guia_arrecadacao?.data_expiracao ?? '')}
                    </div>
                </div>
                <div className='col d-flex justify-content-between align-items-center border-left border-right border-dark'>
                    <div className=''>
                        Valor
                    </div>
                    <div className=''>
                       R$: {toBRL(processo.valor_total)}
                    </div>
                </div>
            </div>
            <div className='row border-bottom border-dark' style={{height: '20mm', marginBottom: '5mm'}}>
                <div className='col  text-center'>AUTENTICAÇÃO MECÂNICA</div>
            </div>

        </div>
        <div className="container">
            <div style={{height: '73mm'}}>
                <div className='row'>
                    <div className='d-flex col-2 align-items-center justify-content-center'>
                        <img style={{marginTop: '6mm', marginLeft: '6mm', height: '100px', width: '100px' }} src={processo.entidade?.logo_base64} alt="SIBLAM"></img>
                    </div>
                    <div className='col-8'>
                        <div className='d-flex mb-0 justify-content-end' style={{marginTop: '6mm', marginRight: '2mm'}}>
                            <div className='border ml-1 pl-1 pr-1 border-dark font-weight-bold' style={{fontSize: '1.2em'}}>{formatBarcode(processo.guia_arrecadacao?.linha_digitavel).slice(0,13)}</div>
                            <div className='border ml-1 pl-1 pr-1 border-dark font-weight-bold' style={{fontSize: '1.2em'}}>{formatBarcode(processo.guia_arrecadacao?.linha_digitavel).slice(14,27)}</div>
                            <div className='border ml-1 pl-1 pr-1 border-dark font-weight-bold' style={{fontSize: '1.2em'}}>{formatBarcode(processo.guia_arrecadacao?.linha_digitavel).slice(28,41)}</div>
                            <div className='border ml-1 pl-1 pr-1 border-dark font-weight-bold' style={{fontSize: '1.2em'}}>{formatBarcode(processo.guia_arrecadacao?.linha_digitavel).slice(42,55)}</div>
                        </div>
                        <div className='d-flex align-align-items-end justify-content-end'>
                            <Barcode
                                    value={
                                        processo.guia_arrecadacao?.codigo_barra}
                                        options={{
                                            format: 'ITF',
                                            background: '#fff',
                                            fontOptions: "bold",
                                            height: 50,
                                            width: 1.36,
                                            displayValue: false,
                                            marginLeft: 4,
                                            marginRight: 0,
                                            marginTop: 0,
                                            marginBottom: 0,
                                            // textPosition: 'top'
                                    }}
                            />
                        </div>
                    </div>
                    <div className='col-2 d-flex flex-column align-items-center'>
                        <div className='font-weight-bold'>PAGAR COM PIX</div>
                        <div>
                            <QRCodeCanvas value={processo.guia_arrecadacao?.qr_code} />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <p>SR. CAIXA, NÃO RECEBER APÓS O VENCIMENTO.</p>
                    </div>
                </div>
                <div className='row border-bottom border-top border-dark' style={{height: '10mm'}}>
                    <div className='col d-flex justify-content-between border-left border-dark'>
                        <div className=''>
                            Data de vencimento
                        </div>
                        <div className=''>
                            {formatData(processo.guia_arrecadacao?.data_expiracao ?? '')}
                        </div>
                    </div>
                    <div className='col d-flex justify-content-between align-items-cente border-left border-right border-dark'>
                        <div className=''>
                            Valor
                        </div>
                        <div className=''>
                        R$: {toBRL(processo.valor_total)}
                        </div>
                    </div>
                </div>
                <div className='row border-bottom border-dark' style={{height: '20mm'}}>
                    <div className='col  text-center'>AUTENTICAÇÃO MECÂNICA</div>
                </div>
            </div>

        </div>

    </div>
    </>
  )
}

export default GuiaArrecadacao
