import * as actions from './actionType';

export const VistoriaReducer = (
  state = {
    select_vistorias: [],
    vistorias: { data: [] },
    vistoria: {},
    errors: {}
  },
  action
) => {
  switch (action.type) {
    case actions.VISTORIAS_DATA:
      return {
        ...state,
        vistorias: action.vistorias.data,
        links: action.vistorias.data.links,
        meta: action.vistorias.data.meta,
      };
    case actions.SELECT_VISTORIAS:
      return {
        ...state,
        select_vistorias: action.vistorias.data
      };
    case actions.GET_VISTORIA:
      return {
        ...state,
        vistoria: action.vistoria.data,
        status_response: action.vistoria.status,
      };
    case actions.VISTORIA_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
