import * as actions from './actionType';

export const CondicionanteReducer = (state = {select_condicionantes: [], condicionantes: [], status_response:null, condicionante: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.CONDICIONATES_DATA:
      return {
        ...state,
        condicionantes: action.condicionantes.data,
        links: action.condicionantes.data.links,
        meta: action.condicionantes.data.meta,
      };
    case actions.SELECT_CONDICIONATES:
      return {
        ...state,
        select_condicionantes: action.condicionantes.data
      };
    case actions.GET_CONDICIONATE:
      return {
        ...state,
        condicionante: action.condicionante.data.data,
        status_response: action.condicionante.status,
      };
    case actions.CONDICIONATE_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    case actions.CHANGE_STATUS_CODE:
      return {
        ...state,
        status_response: action.status,
      };
    default:
      return state;
  }
}
