export const initialData = {
  responsavel_tecnico_id: undefined,
  pode_elaboracao_e_estudo_projeto: false,
  pode_elaboracao_orcamento: false,
  pode_implantacao_estudo_e_projeto: false,
  pode_execucao_estudo_e_projeto: false,
  pode_fiscalizacao_estudo_e_projeto: false,
  pode_monitoramento_estudo_e_projeto: false,
};
export default initialData;
