import { all, put, call, takeLatest } from 'redux-saga/effects';
import * as User from '~/services/User';
import * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadlistaAnalistasEntidadesWorker({ params }) {
    try {
        const response = yield call(User.load_lista_analista_entidade, params);
        yield put(actions.getAnalistasEntidades(response));
        yield put(actions.getErros({}));
        yield put(getErros({}));
    } catch (error) {
        if (error.response) {
            yield put(actions.getErros(error.response));
            yield put(getErros(error.response));
        } else if (error.request) {
            yield put(getErros({ data: error.message }));
        } else {
            yield put(getErros({ data: error.message }));
        }
    }
}

function* loadUsersWorker({ params }) {
    try {
        const response = yield call(User.load_users, params);

        // console.log('users loadUsersWorker: ',response);
        yield put(actions.getUsers(response));
        yield put(actions.getErros({}));
        yield put(getErros({}));
    } catch (error) {
        if (error.response) {
            yield put(actions.getErros(error.response));
            yield put(getErros(error.response));
        } else if (error.request) {
            yield put(getErros({ data: error.message }));
        } else {
            yield put(getErros({ data: error.message }));
        }
    }
}

function* showUserWorker({ id_user }) {
    try {
        const response = yield call(User.load_user, id_user);

        // console.log('users showUserWorker: ',response);
        yield put(actions.getUser(response.data));
        yield put(actions.getErros({}));
        yield put(getErros({}));
    } catch (error) {
        if (error.response) {
            yield put(actions.getErros(error.response));
            yield put(getErros(error.response));
        } else if (error.request) {
            yield put(getErros({ data: error.message }));
        } else {
            yield put(getErros({ data: error.message }));
        }
    }
}

function* showUserPessoaWorker({ id_user }) {
    try {
        const response = yield call(User.load_user_pessoa, id_user);

        // console.log('users showUserPessoaWorker: ', response);
        yield put(actions.getUserPessoa(response.data));
        yield put(actions.getErros({}));
        yield put(getErros({}));
    } catch (error) {
        if (error.response) {
            yield put(actions.getErros(error.response));
            yield put(getErros(error.response));
        } else if (error.request) {
            yield put(getErros({ data: error.message }));
        } else {
            yield put(getErros({ data: error.message }));
        }
    }
}

function* storeUsersWorker({ usuario }) {
    try {
        const response = yield call(User.store_users, usuario);

        // console.log('users storeUsersWorker: ',response);
        yield put(actions.getErros({ save: true }));
        yield put(actions.changeStatus(response.status));
        yield put(getErros({ save: true }));
        yield put(actions.loadUsers());
    } catch (error) {
        if (error.response) {
            yield put(actions.getErros(error.response));
            yield put(getErros(error.response));
        } else if (error.request) {
            yield put(getErros({ data: error.message }));
        } else {
            yield put(getErros({ data: error.message }));
        }
    }
}

function* updateUsersWorker({ payload }) {
    try {
        const response = yield call(User.update_users, payload);

        // console.log('users updateUsersWorker: ',response);
        yield put(actions.changeStatus(response.status));
        yield put(actions.getErros({ update: true }));
        yield put(getErros({ update: true }));
        yield put(actions.loadUsers());
    } catch (error) {
        if (error.response) {
            yield put(actions.getErros(error.response));
            yield put(getErros(error.response));
        } else if (error.request) {
            yield put(getErros({ data: error.message }));
        } else {
            yield put(getErros({ data: error.message }));
        }
    }
}

function* changePasswordWorker({ user }) {
    try {
        const response = yield call(User.change_password_users, user);

        // console.log('users changePasswordWorker: ',response);
        yield put(actions.changeStatus(response.status));
        yield put(actions.getErros({}));
        yield put(getErros({}));
    } catch (error) {
        if (error.response) {
            // console.log('error response changePasswordWorker: ',error.response);
            yield put(actions.getErros(error.response.data));
            yield put(getErros(error.response.data));
            yield put(actions.changeStatus(error.response.status));
        } else if (error.request) {
            // console.log('error request changePasswordWorker: ',error.request);
            yield put(getErros({ data: error.message }));
        } else {
            // console.log('error data changePasswordWorker: ',error.message);
            yield put(getErros({ data: error.message }));
        }
    }
}

function* mutatePasswordWorker({ user }) {
    try {
        const response = yield call(User.mutate_password_users, user);

        // console.log('users mutatePasswordWorker: ',response);
        yield put(actions.changeStatus(response.status));
        yield put(actions.getErros({}));
        yield put(getErros({}));
    } catch (error) {
        if (error.response) {
            // console.log('error response mutatePasswordWorker: ',error.response);
            yield put(actions.getErros(error.response.data));
            yield put(getErros(error.response.data));
            yield put(actions.changeStatus(error.response.status));
        } else if (error.request) {
            // console.log('error request mutatePasswordWorker: ',error.request);
            yield put(getErros({ data: error.message }));
        } else {
            // console.log('error data mutatePasswordWorker: ',error.message);
            yield put(getErros({ data: error.message }));
        }
    }
}

function* filterUsersWorker({ params }) {
    try {
        const response = yield call(User.filterUsers, params);
        // console.log('filterUsers: ', response);
        yield put(actions.getUsers(response));
        yield put(actions.getErros({}));
        yield put(getErros({}));
    } catch (error) {
        if (error.response) {
            yield put(actions.getErros(error.response));
            yield put(getErros(error.response));
        } else if (error.request) {
            yield put(getErros({ data: error.message }));
        } else {
            yield put(getErros({ data: error.message }));
        }
    }
}

function* removeUserWorker({ user, params }) {
    try {
        yield call(User.removeUser, user);
        const response = yield call(User.load_users, params);
        yield put(actions.getUsers(response));
        yield put(actions.getErros({}));
        yield put(getErros({}));
    } catch (error) {
        if (error.response) {
            yield put(actions.getErros(error.response));
            yield put(getErros(error.response));
        } else if (error.request) {
            yield put(getErros({ data: error.message }));
        } else {
            yield put(getErros({ data: error.message }));
        }
    }
}

function* restoreUserWorker({ user, params }) {
    try {
        yield call(User.restoreUser, user);
        const response = yield call(User.load_users, params);
        yield put(actions.getUsers(response));
        yield put(actions.getErros({}));
        yield put(getErros({}));
    } catch (error) {
        if (error.response) {
            yield put(actions.getErros(error.response));
            yield put(getErros(error.response));
        } else if (error.request) {
            yield put(getErros({ data: error.message }));
        } else {
            yield put(getErros({ data: error.message }));
        }
    }
}

function* watcherTecnicoProfissional() {
    yield takeLatest(actionType.LOAD_USERS_ANALISTA_ENTIDADE, loadlistaAnalistasEntidadesWorker);
    yield takeLatest(actionType.LOAD_USERS, loadUsersWorker);
    yield takeLatest(actionType.STORE_USER, storeUsersWorker);
    yield takeLatest(actionType.UPDATE_USER, updateUsersWorker);
    yield takeLatest(actionType.LOAD_USER, showUserWorker);
    yield takeLatest(actionType.LOAD_USER_PESSOA, showUserPessoaWorker);
    yield takeLatest(actionType.CHANGE_PASSWORD, changePasswordWorker);
    yield takeLatest(actionType.MUTATE_PASSWORD, mutatePasswordWorker);
    yield takeLatest(actionType.FILTER_USERS, filterUsersWorker)
    yield takeLatest(actionType.REMOVE_USER, removeUserWorker);
    yield takeLatest(actionType.RESTORE_USER, restoreUserWorker);
}

export default function* saga() {
    yield all([watcherTecnicoProfissional()]);
}
