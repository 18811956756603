import * as actions from './actionType';

export const PessoaFisicaReducer = (state = {select_pessoa_fisicas: [], pessoa_fisicas: [], pessoa_fisica: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.PESSOA_FISICAS_DATA:
      return {
        ...state,
        pessoa_fisicas: action.pessoa_fisicas.data,
        links: action.pessoa_fisicas.data.links,
        meta: action.pessoa_fisicas.data.meta,
      };
    case actions.SELECT_PESSOA_FISICAS:
      return {
        ...state,
        select_pessoa_fisicas: action.pessoa_fisicas.data
      };
    case actions.GET_PESSOA_FISICA:
      return {
        ...state,
        pessoa_fisica: action.pessoa_fisica.data.data,
        status_response: action.pessoa_fisica.status,
      };
    case actions.PESSOA_FISICA_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
