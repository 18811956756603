import * as actions from './actionType';

export const MeioAnaliseReducer = (state = {select_meio_analises: [], meio_analises: [],
  meio_analises_with_respostas: [],
  meio_analises_with_respostas_apia: [],
  status_response:null, meio_analise: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.MEIO_ANALISES_DATA:
      return {
        ...state,
        meio_analises: action.meio_analises.data,
        links: action.meio_analises.data.links,
        meta: action.meio_analises.data.meta,
      };


    case actions.MEIO_ANALISES_WITH_RESPOSTAS_DATA:
      return {
        ...state,
        meio_analises_with_respostas: action.meio_analises_with_respostas.data
      };

    case actions.MEIO_ANALISES_WITH_RESPOSTAS_APIA_DATA:
      return {
        ...state,
        meio_analises_with_respostas_apia: action.meio_analises_with_respostas_apia.data
      };

    case actions.SELECT_MEIO_ANALISES:
      return {
        ...state,
        select_meio_analises: action.meio_analises.data
      };
    case actions.GET_MEIO_ANALISE:
      return {
        ...state,
        meio_analise: action.meio_analise.data.data,
        status_response: action.meio_analise.status,
      };
    case actions.MEIO_ANALISE_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    case actions.CHANGE_STATUS_CODE:
      return {
        ...state,
        status_response: action.status,
      };
    default:
      return state;
  }
}
