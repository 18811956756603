export const initialData = {
  // autor: '',
  codigo: '',
  descricao:'',
  indice:'',
  valor:0,
  unidade:'',
  indicadores_ods: [],
}
export default initialData;
