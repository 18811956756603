import { CPF_DATA, CPF_RESPONSE, CPF_ERROR } from './actionType';

export const cadastro_pessoa_response = pessoa => {
  return {
    type: CPF_RESPONSE,
    pessoa
  }
}

export const cadastro_pessoa_error = error => {
  return {
    type: CPF_ERROR,
    error
  }
}

export const cadastro_pessoa_data = data => {
  return {
    type: CPF_DATA,
    data
  }
}
