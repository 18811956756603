import { all, put, call, takeLatest } from 'redux-saga/effects';
import * as AutorizacaoProcesso from '~/services/AutorizacaoProcesso';
import * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadAutorizacaoProcessosWorker({ params }) {
  try {
    const response = yield call(AutorizacaoProcesso.load, params);
    yield put(actions.getAutorizacaoProcessos(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* showAutorizacaoProcessoWorker({ id, params }) {
  try {
    const response = yield call(AutorizacaoProcesso.show, id, params);
    yield put(actions.getAutorizacaoProcesso(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* updateAutorizacaoProcessoWorker({ autorizacao_processo, id }) {
  try {
    const response = yield call(
      AutorizacaoProcesso.update,
      id,
      autorizacao_processo
    );
    yield put(actions.getAutorizacaoProcesso(response));
    yield put(actions.getErros({ update: true }));
    yield put(getErros({ update: true }));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* storeAutorizacaoProcessoWorker({ autorizacao_processo }) {
  try {
    const response = yield call(
      AutorizacaoProcesso.store,
      autorizacao_processo
    );
    yield put(actions.getAutorizacaoProcesso(response.data));
    yield put(actions.changeStatus(response.status));
    yield put(actions.getErros({ save: true }));
    yield put(getErros({ save: true }));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* removerAutorizacaoProcessoWorker({ id }) {
  try {
    const response = yield call(AutorizacaoProcesso.destroy, id);
    yield put(actions.changeStatus(response.status))
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* watcherAutorizacaoProcesso() {
  yield takeLatest(
    actionType.LOAD_AUTORIZACAO_PROCESSOS,
    loadAutorizacaoProcessosWorker
  );
  yield takeLatest(
    actionType.STORE_AUTORIZACAO_PROCESSO,
    storeAutorizacaoProcessoWorker
  );
  yield takeLatest(
    actionType.SHOW_AUTORIZACAO_PROCESSO,
    showAutorizacaoProcessoWorker
  );
  yield takeLatest(
    actionType.UPDATE_AUTORIZACAO_PROCESSO,
    updateAutorizacaoProcessoWorker
  );
  yield takeLatest(
    actionType.REMOVER_AUTORIZACAO_PROCESSO,
    removerAutorizacaoProcessoWorker
  );
}

export default function* saga() {
  yield all([watcherAutorizacaoProcesso()]);
}
