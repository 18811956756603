import * as actions from './actionType';

export const FotoVistoriaReducer = (
  state = {
    fotos_vistoria: [],
    foto_vistoria: {},
    status_response: null,
    errors: {}
  },
  action
) => {
  switch (action.type) {
    case actions.GET_FOTOS_VISTORIA:
      return {
        ...state,
        fotos_vistoria: action.fotos_vistoria.data,
      };
    case actions.GET_FOTO_VISTORIA:
      return {
        ...state,
        foto_vistoria: action.foto_vistoria.data,
      };
    case actions.CHANGE_STATUS_CODE:
      return {
        ...state,
        status_response: action.status,
      };
    case actions.FOTO_VISTORIA_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
