import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { PasswordInput } from '~/components/CustomForm/components';

export default function FinancialCredentials({
  entity, errorsForm, handleChange
}) {
  switch (entity.payment_gateway) {
    case 'bb':
      const accountTypes = [
        { label: "Conta Corrente", value: "CACC" },
        { label: "Conta Poupança", value: "SVGS" }
      ];

      return (
        <fieldset>
          <legend>CREDENCIAIS BANCO DO BRASIL</legend>
          <Row>
            <Col md="3">
              <div className="form-group">
                <label htmlFor="numero_convenio_bb">Número de Convênio</label>
                <Form.Control
                  id="numero_convenio_bb"
                  isInvalid={!!errorsForm.numero_convenio_bb}
                  name="numero_convenio_bb"
                  onChange={handleChange}
                  type="text"
                  value={entity.numero_convenio_bb ?? ''}
                />
                <Form.Control.Feedback type="invalid">
                  {errorsForm.numero_convenio_bb}
                </Form.Control.Feedback>
              </div>
            </Col>
            <Col md="2">
              <div className="form-group">
                <label htmlFor="numero_carteira_bb">Número da Carteira</label>
                <Form.Control
                  id="numero_carteira_bb"
                  isInvalid={!!errorsForm.numero_carteira_bb}
                  name="numero_carteira_bb"
                  onChange={handleChange}
                  type="text"
                  value={entity.numero_carteira_bb ?? ''}
                />
                <Form.Control.Feedback type="invalid">
                  {errorsForm.numero_carteira_bb}
                </Form.Control.Feedback>
              </div>
            </Col>
            <Col md="2">
              <div className="form-group">
                <label htmlFor="numero_variacao_carteira_bb">Número de Variação da Carteira</label>
                <Form.Control
                  id="numero_variacao_carteira_bb"
                  isInvalid={!!errorsForm.numero_variacao_carteira_bb}
                  name="numero_variacao_carteira_bb"
                  onChange={handleChange}
                  type="text"
                  value={entity.numero_variacao_carteira_bb ?? ''}
                />
                <Form.Control.Feedback type="invalid">
                  {errorsForm.numero_variacao_carteira_bb}
                </Form.Control.Feedback>
              </div>
            </Col>
            <Col md="2">
              <div className="form-group">
                <label htmlFor="numero_segmento_bb">Número do Segmento</label>
                <Form.Control
                  id="numero_segmento_bb"
                  isInvalid={!!errorsForm.numero_segmento_bb}
                  name="numero_segmento_bb"
                  onChange={handleChange}
                  type="text"
                  value={entity.numero_segmento_bb ?? ''}
                />
                <Form.Control.Feedback type="invalid">
                  {errorsForm.numero_segmento_bb}
                </Form.Control.Feedback>
              </div>
            </Col>
            <Col md="3">
              <div className="form-group">
                <label htmlFor="numero_identificacao_bb">Número de Identificação</label>
                <Form.Control
                  id="numero_identificacao_bb"
                  isInvalid={!!errorsForm.numero_identificacao_bb}
                  name="numero_identificacao_bb"
                  onChange={handleChange}
                  type="text"
                  value={entity.numero_identificacao_bb ?? ''}
                />
                <Form.Control.Feedback type="invalid">
                  {errorsForm.numero_identificacao_bb}
                </Form.Control.Feedback>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="form-group">
                <label htmlFor="chave_pix_bb">Chave Pix</label>
                <Form.Control
                  id="chave_pix_bb"
                  isInvalid={!!errorsForm.chave_pix_bb}
                  name="chave_pix_bb"
                  onChange={handleChange}
                  type="text"
                  value={entity.chave_pix_bb ?? ''}
                />
                <Form.Control.Feedback type="invalid">
                  {errorsForm.chave_pix_bb}
                </Form.Control.Feedback>
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label htmlFor="agencia_bb">Número da Agência (Sem Dígito Verificador)</label>
                <Form.Control
                  id="agencia_bb"
                  isInvalid={!!errorsForm.agencia_bb}
                  name="agencia_bb"
                  onChange={handleChange}
                  type="text"
                  value={entity.agencia_bb ?? ''}
                />
                <Form.Control.Feedback type="invalid">
                  {errorsForm.agencia_bb}
                </Form.Control.Feedback>
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label htmlFor="conta_bb">Número da Conta (Sem Dígito Verificador)</label>
                <Form.Control
                  id="conta_bb"
                  isInvalid={!!errorsForm.conta_bb}
                  name="conta_bb"
                  onChange={handleChange}
                  type="text"
                  value={entity.conta_bb ?? ''}
                />
                <Form.Control.Feedback type="invalid">
                  {errorsForm.conta_bb}
                </Form.Control.Feedback>
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label htmlFor="tipo_conta_bb">Tipo da Conta</label>
                <Form.Control
                  as="select"
                  id="tipo_conta_bb"
                  isInvalid={!!errorsForm.tipo_conta_bb}
                  name="tipo_conta_bb"
                  onChange={handleChange}
                  value={entity.tipo_conta_bb ?? ''}
                >
                  <option>Selecione</option>
                  {accountTypes.map((accountType, index) => (
                    <option key={`account-type-${index}`} value={accountType.value}>
                      {accountType.label}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errorsForm.tipo_conta_bb}
                </Form.Control.Feedback>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form-group">
                <label htmlFor="gw_dev_app_key_bb">Chave de Desenvolvedor</label>
                <PasswordInput
                  id="gw_dev_app_key_bb"
                  isInvalid={!!errorsForm.gw_dev_app_key_bb}
                  name="gw_dev_app_key_bb"
                  placeholder="developer_application_key"
                  onChange={handleChange}
                  value={entity.gw_dev_app_key_bb ?? ''}
                  autoComplete="off"
                />
                <Form.Control.Feedback type="invalid">
                  {errorsForm.gw_dev_app_key_bb}
                </Form.Control.Feedback>
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label htmlFor="basic_auth_key_bb">Chave de Autenticação Básica</label>
                <PasswordInput
                  id="basic_auth_key_bb"
                  isInvalid={!!errorsForm.basic_auth_key_bb}
                  name="basic_auth_key_bb"
                  placeholder="basic_auth_key"
                  onChange={handleChange}
                  value={entity.basic_auth_key_bb ?? ''}
                  autoComplete="off"
                />
                <Form.Control.Feedback type="invalid">
                  {errorsForm.basic_auth_key_bb}
                </Form.Control.Feedback>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form-group">
                <label htmlFor="bb_sia_username">BB Sia - Nome de Usuário</label>
                <Form.Control
                  id="bb_sia_username"
                  isInvalid={!!errorsForm.bb_sia_username}
                  name="bb_sia_username"
                  onChange={handleChange}
                  type="text"
                  value={entity.bb_sia_username ?? ''}
                />
                <Form.Control.Feedback type="invalid">
                  {errorsForm.bb_sia_username}
                </Form.Control.Feedback>
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label htmlFor="bb_sia_password">BB Sia - Senha</label>
                <PasswordInput
                  id="bb_sia_password"
                  isInvalid={!!errorsForm.bb_sia_password}
                  name="bb_sia_password"
                  placeholder=""
                  onChange={handleChange}
                  value={entity.bb_sia_password ?? ''}
                  autoComplete="off"
                />
                <Form.Control.Feedback type="invalid">
                  {errorsForm.bb_sia_password}
                </Form.Control.Feedback>
              </div>
            </Col>
          </Row>
        </fieldset>
      );
    case 'galax_pay':
      return (
        <fieldset>
          <legend>CREDENCIAIS GALAX PAY</legend>
          {!!entity.galax_key_ok && (
            <small>*Credenciais já cadastradas</small>
          )}
          <Row>
            <Col md="6">
              <div className="form-group">
                <label htmlFor="galax_id">Galax Pay ID</label>
                <Form.Control
                  id="galax_id"
                  isInvalid={!!errorsForm.galax_id}
                  name="galax_id"
                  onChange={handleChange}
                  type="text"
                  value={entity.galax_id ?? ''}
                />
                <Form.Control.Feedback type="invalid">
                  {errorsForm.galax_id}
                </Form.Control.Feedback>
              </div>
            </Col>
            <Col md="6">
              <div className="form-group">
                <label htmlFor="galax_hash">Galax Pay HASH</label>
                <Form.Control
                  id="galax_hash"
                  isInvalid={!!errorsForm.galax_hash}
                  name="galax_hash"
                  onChange={handleChange}
                  type="text"
                  value={entity.galax_hash ?? ''}
                />
                <Form.Control.Feedback type="invalid">
                  {errorsForm.galax_hash}
                </Form.Control.Feedback>
              </div>
            </Col>
          </Row>
        </fieldset>
      );
    default:
      return null;
  }
}
