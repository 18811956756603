import * as actions from './actionType';

export const loadArquivo = (path, params) => ({
  type: actions.LOAD_ARQUIVO,
  path,
  params,
});

export const getArquivo = arquivo => ({
  type: actions.GET_ARQUIVO,
  arquivo,
});

export const getErros = errors => ({
  type: actions.ARQUIVO_ERROR,
  errors,
});
