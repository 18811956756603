import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Row, Col } from 'react-bootstrap';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { FiCheckCircle } from 'react-icons/fi';
import buscaCep from '~/services/cep';
import FileInput from '~/components/FileInput';

const FormTecnicoAvaliador = ({
  errors,
  schema,
  handleSubmit,
  tecnicoAvaliador,
  setTecnicoAvaliador
}) => {
  const { uf_data } = useSelector(({ UfDataReducer }) => UfDataReducer);

  const handleBuscaCep = async (event) => {
    const { value } = event.target;
    const cep = value;
    try {
      const data = await buscaCep.get(`${cep}/json`);
      const endereco = data.data;
      const finded = {
        logradouro: endereco.logradouro,
        bairro: endereco.bairro,
        cidade: endereco.localidade
      }
      setTecnicoAvaliador({
        ...tecnicoAvaliador,
        ...finded
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = event => {
    const { name, value } = event.target;

    setTecnicoAvaliador({
      ...tecnicoAvaliador,
      [name]: value
    });
  };

  return (
    <div className="card">
      <div className="card-body">
        <Form
          onSubmit={handleSubmit}
          schema={schema}
        >
          <Row>
            <Col>
              <Form.Group controlId="validation1">
                <Form.Label>Nome completo</Form.Label>
                <Form.Control
                  isInvalid={!!errors.nome}
                  name="nome"
                  onChange={handleChange}
                  placeholder="Nome completo"
                  type="text"
                  value={tecnicoAvaliador.nome || ''}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.nome}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  isInvalid={!!errors.email}
                  name="email"
                  onChange={handleChange}
                  placeholder="E-mail"
                  type="email"
                  value={tecnicoAvaliador.email || ''}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Senha</Form.Label>
                <Form.Control
                  isInvalid={!!errors.password}
                  name="password"
                  onChange={handleChange}
                  placeholder="Senha"
                  type="password"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Confirmação Senha</Form.Label>
                <Form.Control
                  isInvalid={!!errors.confirmacao_senha}
                  name="confirmacao_senha"
                  onChange={handleChange}
                  placeholder="Confirmar Senha"
                  type="password"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.confirmacao_senha}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <div style={{ border: '1px solid black', borderRadius: '3px',padding: '15px 20px', margin: '10px 0px' }}>
            <h5>Dados do Técnico Avaliador</h5>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Data Nascimento</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.data_nasc}
                    name="data_nasc"
                    onChange={handleChange}
                    placeholder="Data Nascimento"
                    type="date"
                    value={tecnicoAvaliador.data_nasc || ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.data_nasc}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>CPF/CNPJ (Somente números)</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.cpf_cnpj}
                    maxLength="14"
                    name="cpf_cnpj"
                    onChange={handleChange}
                    placeholder="CPF/CNPJ"
                    type="number"
                    value={tecnicoAvaliador.cpf_cnpj || ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.cpf_cnpj}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Anexo</Form.Label>
                  <FileInput
                    dataError={errors.anexo_cpf_cnpj}
                    name="anexo_cpf_cnpj"
                    data={tecnicoAvaliador}
                    setData={setTecnicoAvaliador}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.anexo_cpf_cnpj}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>RG UF</Form.Label>
                  <Form.Control
                    as="select"
                    isInvalid={!!errors.uf_rg_id}
                    name="uf_rg_id"
                    onChange={handleChange}
                    placeholder="UF RG"
                    type="text"
                    value={tecnicoAvaliador.uf_rg_id}
                  >
                    {uf_data[0] && uf_data.map(uf =>
                      <option
                        key={uf.id}
                        value={uf.id}
                      >
                        {uf.uf}
                      </option>)}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.uf_rg_id}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>RG (Somente números)</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.rg}
                    name="rg"
                    onChange={handleChange}
                    placeholder="RG (Somente números)"
                    type="text"
                    value={tecnicoAvaliador.rg || ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.rg}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Anexo</Form.Label>
                  <FileInput
                    dataError={errors.anexo_rg}
                    name="anexo_rg"
                    data={tecnicoAvaliador}
                    setData={setTecnicoAvaliador}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.anexo_rg}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>CEP</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.cep}
                    name="cep"
                    onBlur={handleBuscaCep}
                    onChange={handleChange}
                    placeholder="CEP"
                    type="text"
                    value={tecnicoAvaliador.cep || ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.cep}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Logradouro</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.logradouro}
                    name="logradouro"
                    onChange={handleChange}
                    placeholder="Logradouro"
                    type="text"
                    value={tecnicoAvaliador.logradouro || ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.logradouro}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Anexo</Form.Label>
                  <FileInput
                    dataError={errors.anexo_endereco}
                    name="anexo_endereco"
                    data={tecnicoAvaliador}
                    setData={setTecnicoAvaliador}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.anexo_endereco}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Número</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.numero_endereco}
                    name="numero_endereco"
                    onChange={handleChange}
                    placeholder="Número"
                    type="text"
                    value={tecnicoAvaliador.numero_endereco || ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.numero_endereco}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Bairro</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.bairro}
                    name="bairro"
                    onChange={handleChange}
                    placeholder="Bairro"
                    type="text"
                    value={tecnicoAvaliador.bairro || ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.bairro}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Cidade</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.cidade}
                    name="cidade"
                    onChange={handleChange}
                    placeholder="Cidade"
                    type="text"
                    value={tecnicoAvaliador.cidade || ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.cidade}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>UF</Form.Label>
                  <Form.Control
                    as="select"
                    isInvalid={!!errors.uf_endereco_id}
                    name="uf_endereco_id"
                    onChange={handleChange}
                    placeholder="UF"
                    type="text"
                    value={tecnicoAvaliador.uf_endereco_id}
                  >
                    {uf_data[0] && uf_data.map(uf =>
                      <option
                        key={uf.id}
                        value={uf.id}
                      >
                        {uf.uf}
                      </option>)}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.uf_endereco_id}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Fone de contato</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.telefone}
                    name="telefone"
                    onChange={handleChange}
                    placeholder="Fone de contato"
                    type="text"
                    value={tecnicoAvaliador.telefone || ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.telefone}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Número de WhatsApp</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.numero_whatsapp}
                    name="numero_whatsapp"
                    onChange={handleChange}
                    placeholder="Número de WhatsAp"
                    type="text"
                    value={tecnicoAvaliador.numero_whatsapp || ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.numero_whatsapp}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Youtube</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.youtube}
                    name="youtube"
                    onChange={handleChange}
                    placeholder="Youtube"
                    type="text"
                    value={tecnicoAvaliador.youtube || ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.youtube}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Facebook</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.facebook}
                    name="facebook"
                    onChange={handleChange}
                    placeholder="Facebook"
                    type="text"
                    value={tecnicoAvaliador.facebook || ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.facebook}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Linkdin</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.linkdin}
                    name="linkdin"
                    onChange={handleChange}
                    placeholder="Linkdin"
                    type="text"
                    value={tecnicoAvaliador.linkdin || ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.linkdin}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Twiter</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.twiter}
                    name="twiter"
                    onChange={handleChange}
                    placeholder="Twiter"
                    type="text"
                    value={tecnicoAvaliador.twiter || ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.twiter}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group as={Row}>
              <Col sm={6}>
                <Form.Label>Formação</Form.Label>
                <Form.Control
                  isInvalid={!!errors.formacao}
                  name="formacao"
                  onChange={handleChange}
                  placeholder="Formação"
                  type="text"
                  value={tecnicoAvaliador.formacao || ''}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.formacao}
                </Form.Control.Feedback>
              </Col>
              <Col>
                <Form.Label>Anexo</Form.Label>
                <FileInput
                  dataError={errors.anexo_formacao}
                  name="anexo_formacao"
                  data={tecnicoAvaliador}
                  setData={setTecnicoAvaliador}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.anexo_formacao}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Col sm={6}>
                <Form.Label>Formação 2 (caso tenha)</Form.Label>
                <Form.Control
                  isInvalid={!!errors.formacao_2}
                  name="formacao_2"
                  onChange={handleChange}
                  placeholder="Formação 2 (caso tenha)"
                  type="text"
                  value={tecnicoAvaliador.formacao_2 || ''}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.formacao_2}
                </Form.Control.Feedback>
              </Col>
              <Col>
                <Form.Label>Anexo</Form.Label>
                <FileInput
                  dataError={errors.anexo_formacao_2}
                  name="anexo_formacao_2"
                  data={tecnicoAvaliador}
                  setData={setTecnicoAvaliador}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.anexo_formacao_2}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col sm={6}>
                <Form.Label>Especialização</Form.Label>
                <Form.Control
                  isInvalid={!!errors.especializacao}
                  name="especializacao"
                  onChange={handleChange}
                  placeholder="Especialização"
                  type="text"
                  value={tecnicoAvaliador.especializacao || ''}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.especializacao}
                </Form.Control.Feedback>
              </Col>
              <Col>
                <Form.Label>Anexo</Form.Label>
                <FileInput
                  dataError={errors.anexo_especializacao}
                  name="anexo_especializacao"
                  data={tecnicoAvaliador}
                  setData={setTecnicoAvaliador}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.anexo_especializacao}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col sm={6}>
                <Form.Label>Mestrado</Form.Label>
                <Form.Control
                  isInvalid={!!errors.mestrado}
                  name="mestrado"
                  onChange={handleChange}
                  placeholder="Mestrado"
                  type="text"
                  value={tecnicoAvaliador.mestrado || ''}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.mestrado}
                </Form.Control.Feedback>
              </Col>
              <Col>
                <Form.Label>Anexo</Form.Label>
                <FileInput
                  dataError={errors.anexo_mestrado}
                  name="anexo_mestrado"
                  data={tecnicoAvaliador}
                  setData={setTecnicoAvaliador}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.anexo_mestrado}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col sm={6}>
                <Form.Label>Doutorado</Form.Label>
                <Form.Control
                  isInvalid={!!errors.doutorado}
                  name="doutorado"
                  onChange={handleChange}
                  placeholder="Doutorado"
                  type="text"
                  value={tecnicoAvaliador.doutorado || ''}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.doutorado}
                </Form.Control.Feedback>
              </Col>
              <Col>
                <Form.Label>Anexo</Form.Label>
                <FileInput
                  dataError={errors.anexo_doutorado}
                  name="anexo_doutorado"
                  data={tecnicoAvaliador}
                  setData={setTecnicoAvaliador}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.anexo_doutorado}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </div>

          <div className="d-flex justify-content-between">
            <Link
              className="btn btn-default"
              to="/tecnicos_avaliadores"
            >
              <FaArrowCircleLeft /> Voltar
            </Link>
            <button
              className="btn btn-success"
              type="submit"
            >
              <FiCheckCircle /> Finalizar
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default FormTecnicoAvaliador;
