export default [
  { label: 'Iniciou o cadastro', value: 'cadadastro'},
  { label: 'Enviado para APIA', value: 'enviado_para_apia'},
  { label: 'Iniciou APIA', value: 'iniciou_apia'},
  { label: 'Finalizou APIA', value: 'finalizou_apia'},
  { label: 'Enviado para elaboração do projeto', value: 'detalhamento_empreendimento'},
  { label: 'Enviado para distribuição', value: 'distribuicao'},
  { label: 'Enviado para o(a) analista', value: 'enviado_para_analista'},
  { label: 'Enviado para a autorização', value: 'enviado_para_autorizacao'},
  { label: 'Processo finalizado', value: 'finalizado'}
];
