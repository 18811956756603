import { all, put, takeEvery } from 'redux-saga/effects';
import { api, apiWithToken } from '~/services/api';
import { LOGIN_RESPONSE, UPDATE_AUTH_USER } from './actionType';
import { login_auth, login_user_error, changeStatusCode } from './action';

const token = localStorage.getItem('@token');
let api_with_token = apiWithToken(token);

function* loginWorker({ user }) {
  try {
    const userData = yield api.post('/auth/login', user);
    yield localStorage.setItem('@token', userData.data.access_token);
    yield localStorage.setItem('@user', JSON.stringify(userData.data));
    yield localStorage.setItem('@menus', JSON.stringify(userData.data.data_menu));

    yield put(login_auth(userData.data));
  }
  catch (err) {
    console.log(err.response.data);
    yield put(login_user_error(err.response.data))
  }
}

function* updateAuthUserWorker({ authInfo, user }) {
  try {
    const response = yield api_with_token.post('/profile/update', user);
    yield put(changeStatusCode(response.status));
    const { pessoa, ...rest } = response.data.data;
    Object.keys(rest).forEach(key => {
      authInfo.user[key] = key.includes('_id') ? parseInt(rest[key]) : rest[key];
    });
    authInfo.user.pessoas = [ pessoa ];
    yield localStorage.setItem('@user', JSON.stringify(authInfo));
    yield put(login_auth(authInfo));
  } catch (error) {
    console.log(error.response.data);
    yield put(changeStatusCode(error.response.status));
    yield put(login_user_error(error.response.data));
  }
}

function* watcherLogin() {
  yield takeEvery(LOGIN_RESPONSE, loginWorker);
  yield takeEvery(UPDATE_AUTH_USER, updateAuthUserWorker);
}

export default function* saga() {
  yield all([watcherLogin()]);
}
