import React from 'react';
import { Form } from 'react-bootstrap';

const FormCad = ({
  handleSubmit,
  schema,
  alternativaQuestaoAnalise,
  setAlternativaQuestaoAnalise,
  errors }) => {
  const handleChange = event => {
    const { name, value, files } = event.target;
    setAlternativaQuestaoAnalise({
      ...alternativaQuestaoAnalise,
      [name]: files ? files[0] : value,
    });
  };

  return (
    <Form
      id="form_alternativa_questao_analise"
      onSubmit={handleSubmit}
      schema={schema}
    >
      <div className="form-group">
        <label htmlFor="nome"> Nome </label>
        <Form.Control
          id="nome"
          isInvalid={!!errors.nome}
          name="nome"
          onChange={handleChange}
          placeholder="Nome"
          type="text"
          value={alternativaQuestaoAnalise.nome || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.nome}
        </Form.Control.Feedback>
      </div>

      <div className="form-group">
        <label htmlFor="min_valor"> Valor Mínimo </label>
        <Form.Control
          id="min_valor"
          isInvalid={!!errors.min_valor}
          max={parseFloat(alternativaQuestaoAnalise.max_valor)-0.1}
          min="0"
          name="min_valor"
          onChange={handleChange}
          placeholder="Valor Mínimo"
          required
          step="0.1"
          type="number"

          value={alternativaQuestaoAnalise.min_valor || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.min_valor}
        </Form.Control.Feedback>
      </div>

      <div className="form-group">
        <label htmlFor="max_valor"> Valor Máximo </label>
        <Form.Control
          id="max_valor"
          isInvalid={!!errors.max_valor}
          max="10"
          min={parseFloat(alternativaQuestaoAnalise.min_valor)+0.1}
          name="max_valor"
          onChange={handleChange}
          placeholder="Valor Máximo"
          required
          step="0.1"
          type="number"
          value={alternativaQuestaoAnalise.max_valor || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.max_valor}
        </Form.Control.Feedback>
      </div>

    </Form>
  );
};

export default FormCad;
