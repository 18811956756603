import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import {
  storeDocumentoProcesso,
} from '~/store/modules/documento_processo/action';
import {
  showProcesso
} from '~/store/modules/processo/action';

export default function FileForm({ id, nome, obrigatorio, processo_id }) {
  const dispatch = useDispatch();
  const [dataForm, setDataForm] = useState({ processo_id, nome, anexo: null });

  const handleChange = event => {
    const { files } = event.target;

    setDataForm({
      ...dataForm,
      anexo: files[0]
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    Swal.fire({
      title: 'Adicionar esse arquivo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.value) {
        try {
          let form = new FormData();

          for (let key in dataForm) {
            form.append(key, dataForm[key]);
          }

          await dispatch(storeDocumentoProcesso(form));
          await Swal.fire(
            'Sucesso',
            'Arquivo adicionado com sucesso!',
            'success'
          );
          await dispatch(showProcesso(
            processo_id, {
              with: 'analise_processo,analise_previa,pessoa,documentos,estado,municipio,atividade,pessoa_responsavel_tecnico,tipo_licenca,status_processo,porte_empreendimento,projeto'
            }
          ));
        } catch (error) {
          await Swal.fire(
            'Error',
            'Error ao adicionar essse anexo',
            'error'
          );
        }
      }
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="input-group">
        <div className="custom-file">
          <input
            className="custom-file-input"
            name={`anexo_${id}`}
            onChange={handleChange}
            required={obrigatorio}
            type="file"
          />
          <label
            data-browse="Buscar"
            className="custom-file-label"
            htmlFor={`inputGroupFile${id}`}
          >
            {dataForm[`anexo`] ? dataForm[`anexo`].name : 'Escolha o arquivo'}
          </label>
        </div>
        <div className="input-group-append">
          <button className="btn btn-success">
            Enviar
          </button>
        </div>
      </div>
    </form>
  );
}
