import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as RelatorioVistoria from '~/services/RelatorioVistoria';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadRelatorioVistoriasWorker({params}) {
  try {
    const response = yield call(RelatorioVistoria.load, params);
    yield put(actions.getRelatorioVistorias(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* showRelatorioVistoriaWorker({processo_id}) {
  try {
    const response = yield call(RelatorioVistoria.show, processo_id);
    yield put(actions.getRelatorioVistoria(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updateRelatorioVistoriaWorker({ relatorio_vistoria, id}) {
  try {
    const response = yield call(RelatorioVistoria.update, id, relatorio_vistoria);
    yield put(actions.getRelatorioVistoria(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeRelatorioVistoriaWorker({relatorio_vistoria}) {
  try {
    const response = yield call(RelatorioVistoria.store, relatorio_vistoria);
    yield put(actions.getRelatorioVistoria(response));
    yield put(actions.getErros({save:true}));
    yield put(getErros({save:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* removerRelatorioVistoriaWorker({id}) {
  try {
    yield call(RelatorioVistoria.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherRelatorioVistoria() {
  yield takeLatest(actionType.LOAD_RELATORIO_VISTORIAS, loadRelatorioVistoriasWorker);
  yield takeLatest(actionType.STORE_RELATORIO_VISTORIA, storeRelatorioVistoriaWorker);
  yield takeLatest(actionType.SHOW_RELATORIO_VISTORIA, showRelatorioVistoriaWorker);
  yield takeLatest(actionType.UPDATE_RELATORIO_VISTORIA, updateRelatorioVistoriaWorker);
  yield takeLatest(actionType.REMOVER_RELATORIO_VISTORIA, removerRelatorioVistoriaWorker);
}

export default function* saga() {
  yield all([watcherRelatorioVistoria()]);
}
