import React from 'react';
import { FiPrinter, FiXCircle } from 'react-icons/fi';
import { Container, ButtonClose, ButtonPrint } from './styles';

function NavBar() {
  const handlePrint = () => {
    window.print();
  };

  const handleClosePage = () => {
    window.close();
  };

  return (
    <Container className="nav-bar-fixed">
      <ButtonPrint onClick={handlePrint}>
        <FiPrinter color="#fff" size={30} />
      </ButtonPrint>
      <ButtonClose onClick={handleClosePage}>
        <FiXCircle color="#fff" size={30} />
      </ButtonClose>
    </Container>
  );
}

export default NavBar;
