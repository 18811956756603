export const LOAD_QUESTAO_ESTUDO_AMBIENTALS = 'LOAD_QUESTAO_ESTUDO_AMBIENTALS';
export const SELECT_QUESTAO_ESTUDO_AMBIENTALS = 'SELECT_QUESTAO_ESTUDO_AMBIENTALS';
export const QUESTAO_ESTUDO_AMBIENTAL_ERROR = 'QUESTAO_ESTUDO_AMBIENTAL_ERROR';
export const QUESTAO_ESTUDO_AMBIENTAL_GET_ERRORS = 'QUESTAO_ESTUDO_AMBIENTAL_GET_ERRORS';
export const QUESTAO_ESTUDO_AMBIENTALS_DATA = 'QUESTAO_ESTUDO_AMBIENTALS_DATA';
export const STORE_QUESTAO_ESTUDO_AMBIENTAL = 'STORE_QUESTAO_ESTUDO_AMBIENTAL';
export const GET_QUESTAO_ESTUDO_AMBIENTAL = 'GET_QUESTAO_ESTUDO_AMBIENTAL';
export const REMOVER_QUESTAO_ESTUDO_AMBIENTAL = 'REMOVER_QUESTAO_ESTUDO_AMBIENTAL';
export const SHOW_QUESTAO_ESTUDO_AMBIENTAL = 'SHOW_QUESTAO_ESTUDO_AMBIENTAL';
export const UPDATE_QUESTAO_ESTUDO_AMBIENTAL = 'UPDATE_QUESTAO_ESTUDO_AMBIENTAL';
export const UPDATE_QUESTAO_ESTUDO_AMBIENTAL_PARECER = 'UPDATE_QUESTAO_ESTUDO_AMBIENTAL_PARECER';
