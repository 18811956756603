import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { Loading } from '~/components';
import { showGerente } from '~/store/modules/gerente/action';
import { ModalAnexos } from '~/components';

const Show = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { gerente_id } = useParams();

  const [modalArquivo, setModalArquivo] = useState({
    title: '',
    path: '',
    show: false,
    handleHide: () => false,
  });

  const { gerente } = useSelector(
    ({ GerenteReducer }) => GerenteReducer
  );

  useEffect(() => {
    dispatch(
      showGerente(
        gerente_id, {
          with: 'pessoa.estado_endereco'
        }
      )
    );
  }, [dispatch, gerente_id]);

  const handleClose = () => {
    history.push('/gerentes');
  };

  const handleHide = () => {
    setModalArquivo({
      title: '',
      path: '',
      show: false,
      handleHide: () => false,
    });
  };

  const handleOpenModalAnexo = (title, path) => {
    setModalArquivo({
      title,
      path,
      show: true,
      handleHide,
    });
  };

  const formatData = (data) => {
    let part = data.split('-');
    return `${part[2]}/${part[1]}/${part[0]}`;
  }

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-xl"
        onHide={handleClose}
        show
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Gerente
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-header">
              <h2 className="card-title">Detalhes</h2>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                {gerente.id === parseInt(gerente_id) ? (
                  <table className="table table-hover table-striped table-sm">
                    <tbody>
                      <tr>
                        <th>id</th>
                        <td>{gerente.id}</td>
                      </tr>
                      <tr>
                        <th>Nome</th>
                        <td>{gerente.nome}</td>
                      </tr>
                      <tr>
                        <th>E-mail</th>
                        <td>{gerente.email}</td>
                      </tr>
                      <tr>
                        <th>Data Nascimento</th>
                        <td>
                          {gerente.pessoa?.data_nasc ? formatData(gerente.pessoa?.data_nasc) : ''}
                        </td>
                      </tr>
                      <tr>
                        <th>CPF/CNPJ</th>
                        <td>
                          {gerente.pessoa?.cnpj ? (
                            <>
                              {gerente.pessoa?.cnpj}
                              {gerente.pessoa?.anexo_cnpj ? (
                                <button
                                  className="btn btn-default ml-2 float-right"
                                  onClick={() =>
                                    handleOpenModalAnexo(
                                      'Anexo CNPJ',
                                      gerente.pessoa?.anexo_cnpj
                                    )
                                  }
                                >
                                  <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                                </button>
                              ) : ''}
                            </>
                          ) : ''}
                          {gerente.pessoa?.cpf ? (
                            <>
                              {gerente.pessoa?.cpf}
                              {gerente.pessoa?.anexo_cpf ? (
                                <button
                                  className="btn btn-default ml-2 float-right"
                                  onClick={() =>
                                    handleOpenModalAnexo(
                                      'Anexo CPF',
                                      gerente.pessoa?.anexo_cpf
                                    )
                                  }
                                >
                                  <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                                </button>
                              ) : ''}
                            </>
                          ) : ''}
                        </td>
                      </tr>
                      <tr>
                        <th>RG</th>
                        <td>
                          {gerente.pessoa?.rg}
                          {gerente.pessoa?.anexo_rg ? (
                            <button
                              className="btn btn-default ml-2 float-right"
                              onClick={() =>
                                handleOpenModalAnexo(
                                  'Anexo RG',
                                  gerente.pessoa?.anexo_rg
                                )
                              }
                            >
                              <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                            </button>
                          ) : ''}
                        </td>
                      </tr>

                      <tr>
                        <th>CEP</th>
                        <td>{gerente.pessoa?.cep}</td>
                      </tr>
                      <tr>
                        <th>Endereço</th>
                        <td>
                          {gerente.pessoa?.logradouro ?`${gerente.pessoa.logradouro}, ` : ''}
                          {gerente.pessoa?.bairro ? `${gerente.pessoa?.bairro}, ` : ''}
                          {gerente.pessoa?.cidade ? `${gerente.pessoa?.cidade} - ` : ''}
                          {gerente.pessoa?.estado_endereco?.uf ? `${gerente.pessoa?.estado_endereco?.uf}`: ''}
                          {gerente.pessoa?.anexo_endereco ? (
                            <button
                              className="btn btn-default ml-2 float-right"
                              onClick={() =>
                                handleOpenModalAnexo(
                                  'Comprovante de endereço',
                                  gerente.pessoa?.anexo_endereco
                                )
                              }
                            >
                              <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                            </button>
                          ) : ''}
                        </td>
                      </tr>
                      <tr>
                        <th>Número de WhatsApp</th>
                        <td>{gerente.pessoa?.numero_whatsapp}</td>
                      </tr>
                      <tr>
                        <th>Fone de contato</th>
                        <td>{gerente.pessoa?.telefone}</td>
                      </tr>
                      <tr>
                        <th>Youtube</th>
                        <td>{gerente.pessoa?.youtube}</td>
                      </tr>
                      <tr>
                        <th>Facebook</th>
                        <td>{gerente.pessoa?.facebook}</td>
                      </tr>
                      <tr>
                        <th>Linkdin</th>
                        <td>{gerente.pessoa?.linkdin}</td>
                      </tr>
                      <tr>
                        <th>Twiter</th>
                        <td>{gerente.pessoa?.twiter}</td>
                      </tr>
                      <tr>
                        <th>Formação</th>
                        <td>
                          {gerente.pessoa?.formacao}
                          {gerente.pessoa?.anexo_formacao ? (
                            <button
                              className="btn btn-default ml-2 float-right"
                              onClick={() =>
                                handleOpenModalAnexo(
                                  'Anexo formação',
                                  gerente.pessoa?.anexo_formacao
                                )
                              }
                            >
                              <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                            </button>
                          ) : ''}
                        </td>
                      </tr>
                      {gerente.pessoa?.formacao_2 &&
                        <tr>
                          <th>Formação 2</th>
                          <td>
                            {gerente.pessoa?.formacao_2}
                            {gerente.pessoa?.anexo_formacao_2 ? (
                              <button
                                className="btn btn-default ml-2 float-right"
                                onClick={() =>
                                  handleOpenModalAnexo(
                                    'Anexo formação 2',
                                    gerente.pessoa?.anexo_formacao_2
                                  )
                                }
                              >
                                <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                              </button>
                            ) : ''}
                          </td>
                        </tr>
                      }

                      <tr>
                        <th>Especialização</th>
                        <td>
                          {gerente.pessoa?.especializacao}
                          {gerente.pessoa?.anexo_especializacao ? (
                            <button
                              className="btn btn-default ml-2 float-right"
                              onClick={() =>
                                handleOpenModalAnexo(
                                  'Anexo formação 2',
                                  gerente.pessoa?.anexo_especializacao
                                )
                              }
                            >
                              <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                            </button>
                          ) : ''}
                        </td>
                      </tr>

                      <tr>
                        <th>Mestrado</th>
                        <td>
                          {gerente.pessoa?.mestrado}
                          {gerente.pessoa?.anexo_mestrado ? (
                            <button
                              className="btn btn-default ml-2 float-right"
                              onClick={() =>
                                handleOpenModalAnexo(
                                  'Anexo Mestrado',
                                  gerente.pessoa?.anexo_mestrado
                                )
                              }
                            >
                              <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                            </button>
                          ) : ''}
                        </td>
                      </tr>

                      <tr>
                        <th>Doutorado</th>
                        <td>
                          {gerente.pessoa?.doutorado}
                          {gerente.pessoa?.anexo_doutorado ? (
                            <button
                              className="btn btn-default ml-2 float-right"
                              onClick={() =>
                                handleOpenModalAnexo(
                                  'Anexo Doutorado',
                                  gerente.pessoa?.anexo_doutorado
                                )
                              }
                            >
                              <i className="fa fa-paperclip" /> Visualizar Anexo{' '}
                            </button>
                          ) : ''}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <Loading />
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Link
            className="btn btn-default"
            to="/gerentes"
          >
            <i className="fa fa-window-close" /> Fechar
          </Link>
        </Modal.Footer>
      </Modal>
      <ModalAnexos {...modalArquivo} />
    </>
  );
};

export default Show;
