import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

const ModalResumo = ({ show, handleHide, meio_analises, analise_previa }) => {
  const [impactos, setImpactos] = useState();

  useEffect(() => {
    let dataImpactos = [];
    let giap = 0;
    let indice = 0;
    let total_questao = 0;
    for (let { tipo_meio_analises } of meio_analises) {
      dataImpactos = [...dataImpactos, ...tipo_meio_analises];
    }
    let resumo = [];
    for (let impacto of dataImpactos) {
      let total = 0;
      if (analise_previa) {
        for (let {resposta_questao_analise_previas} of impacto.questao_analises) {
          total_questao++;
          if (resposta_questao_analise_previas[0]) {
            total += parseFloat(resposta_questao_analise_previas[0].valor);
          }
        }
      } else {
        for (let {resposta_questao_analise} of impacto.questao_analises) {
          total_questao++;
          if (resposta_questao_analise[0]) {
            total += parseFloat(resposta_questao_analise[0].valor);
          }
        }
      }
      giap += total;
      resumo = [...resumo, { nome: impacto.nome, total }];
    }
    indice = giap / total_questao;

    for (let data_resumo of resumo) {
      let porcentagem = (data_resumo.total * 100) / giap;
      data_resumo['porcentagem'] = porcentagem.toFixed(2);
    }

    setImpactos({
      giap,
      total_questao,
      indice,
      resumo,
    });
  }, [meio_analises, analise_previa]);



  return (
    <Modal
      animation
      aria-labelledby="example-custom-modal-styling-title"
      centered
      dialogClassName="modal-xl"
      onHide={handleHide}
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title>Análise {analise_previa ? 'Prévia' : 'Técnica'}  de Impacto Ambiental</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-bordered table-striped table-hover">
                <thead>
                  <tr>
                    <th
                      className="text-center"
                      colSpan="5"
                    >
                      GRAU DE IMPACTO AMBIENTAL
                    </th>
                  </tr>
                  <tr>
                    <th>Ordem</th>
                    <th>COMPONENTES</th>
                    <th>Análise Técnica</th>
                    <th>Índice de Impacto Ambiental</th>
                    <th>%</th>
                  </tr>
                </thead>
                <tbody>
                  {impactos &&  impactos.resumo.map((impacto, index) => (
                    <tr key={index}>
                      <th className="text-center">{++index}</th>
                      <td>Impacto {impacto.nome}</td>
                      <td className="text-center">{impacto.total.toFixed(2)}</td>
                      <td className="text-center">{impacto.total.toFixed(2)}</td>
                      <td className="text-right">{impacto.porcentagem}</td>
                    </tr>
                  ))}
                  <tr>
                    <th/>
                    <th>GIA do Empreendimento</th>
                    <th className="text-center">{impactos && impactos.giap.toFixed(2)}</th>
                    <th className="text-center">{impactos && impactos.indice.toFixed(2)}</th>
                    <th className="text-right">100</th>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="table-responsive">
              <table className="table table-bordered table-striped table-hover">
                <tbody className="text-center">
                  <tr>
                    <th colSpan="3">
                      ESCALA DE ENQUADRAMENTO DO GRAU DE IMPACTO AMBIENTAL
                    </th>
                  </tr>
                  <tr>
                    <th className="table-info">PEQUENO</th>
                    <th className="table-success">MÉDIO</th>
                    <th className="table-danger">ALTO</th>
                  </tr>
                  <tr>
                    <td>0 - 3,32</td>
                    <td>3,33 - 6,66</td>
                    <td>6,67 - 10,00</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="table-responsive">
              <table className="table table-bordered">
                <tbody className="text-center">
                  <tr
                    className={`
                      ${impactos && impactos.indice <= 3.32 && 'table-info'}
                      ${impactos && impactos.indice >= 3.33 &&  impactos.indice <= 6.66 && 'table-success'}
                      ${impactos && impactos.indice >= 6.67 && 'table-danger'}
                    `}
                  >
                    <th>GIAp = {impactos && impactos.indice.toFixed(2)} ↓↑</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalResumo;
