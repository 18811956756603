import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormCad } from '../components';
import Swal from 'sweetalert2';
import initialData from './initialData';
import schema from './schema'
import { storeVeiculo, loadVeiculos } from '~/store/modules/veiculo/action';

const Create = ({entidade_id}) => {
  let history = useHistory();
  const [veiculo, setVeiculo] = useState(initialData);
  const [saved, setSaved] = useState(false);
  const [errorsValidate, setErrorsValidate] = useState([]);
  const dispatch = useDispatch();
  const { status_response, errors } = useSelector(({ VeiculoReducer }) => VeiculoReducer);

  useEffect(() => {
  }, [dispatch]);

  useEffect(() => {
    // console.log('errors create veiculo: ',errors);
    if (errors.status && errors.status === 422) {
      let server_errors = {};
      const response_errors = errors.data.errors;
      for (let name of Object.keys(response_errors)) {
        Object.assign(server_errors, { [name]: response_errors[name][0] });
      }
      setErrorsValidate(server_errors);
    }
    if(errors.save) {
      setSaved(errors.save);
    }
  }, [errors]);

  useEffect(() => {
    // console.log('Sucesso create veiculo: ',status_response);
    if (saved && status_response === 201) {
      Swal.fire('Sucesso', 'Registro salvo com sucesso!', 'success').then(
        () => {
          history.goBack();
          dispatch(loadVeiculos({
            where:`entidade_id:${entidade_id}`
          }));
        }
      );
    }
  }, [status_response, history, dispatch, saved, entidade_id]);

  const handleClose = () => {
    history.goBack();
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setErrorsValidate([]);
    // console.log('veiculo store: ',veiculo);
    if (!schema.isValidSync(veiculo)) {
      let yup_errors = {};
      schema.validate(veiculo, { abortEarly: false }).catch(err => {
        for (let error of err.inner) {
          const { path, message } = error;
          Object.assign(yup_errors, { [path]: message });
        }
        setErrorsValidate(yup_errors);
      });
    } else {
      const vei = veiculo;
      vei.potencial_poluidor_id = parseInt(vei.potencial_poluidor_id);
      vei.entidade_id = parseInt(entidade_id);
      // console.log('veiculo store: ',vei);
      await dispatch(storeVeiculo(vei));
    }
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-xl"
      onHide={handleClose}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Veiculo
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Novo</h2>
          </div>
          <div className="card-body">
            <FormCad
              errors={errorsValidate}
              handleSubmit={handleSubmit}
              schema={schema}
              setVeiculo={setVeiculo}
              veiculo={veiculo}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-default"
          onClick={handleClose}
        >
          <i className="fa fa-window-close" /> Cancelar
        </button>
        <button
          className="btn btn-success"
          form="form_veiculo"
          type="submint"
        >
          {' '}
          <i className="fa fa-save" /> Salvar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Create;
