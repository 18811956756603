import { apiWithToken } from '../api';

const token = localStorage.getItem('@token');
let api = apiWithToken(token);

const resouceUrl = '/tipo_user';

export const load = async (params) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let page = urlParams.has('page') ? `&page=${urlParams.get('page')}` : '';
  let search = urlParams.has('search')
    ? `&search=${urlParams.get('search')}`
    : '';
    return await api.get(`${resouceUrl}`,{
      params: {
        ...params,
        ...page,
        ...search
      }
    });
};

export const show = async (id) => {
  return await api.get(`${resouceUrl}/${id}`);
};

export const update = async (id, tipo_user) => {
  return await api.put(`${resouceUrl}/${id}`, tipo_user);
};

export const addMenu = async (id, menu_id) => {
  return await api.post(`${resouceUrl}/add_menu/${id}`, menu_id);
};

export const loadMenusSelect = async (id) => {
  return await api.get(`${resouceUrl}/${id}/lista_menus_selecionados`);
};

export const addRecurso = async (id, recurso_id) => {
  return await api.post(`${resouceUrl}/${id}/add_recurso`, recurso_id);
};

export const loadRecursosSelect = async (id) => {
  return await api.get(`${resouceUrl}/${id}/lista_recursos_selecionados`);
};

export const loadListCustom = async () => {
  return await api.get(`${resouceUrl}/tipos_especificos/listar`);
};

export const store = async (tipo_user) => {
  let response = await api.post(resouceUrl, tipo_user);
  return response;
};

export const destroy = async (id) => {
  return await api.delete(`${resouceUrl}/${id}`);
};
