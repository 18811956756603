import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as ProjetoProcesso from '~/services/ProjetoProcesso';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadProjetoProcessosWorker({params}) {
  try {
    const response = yield call(ProjetoProcesso.load, params);
    yield put(actions.getProjetoProcessos(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}


function* showProjetoProcessoWorker({id}) {
  try {
    const response = yield call(ProjetoProcesso.show, id);
    yield put(actions.getProjetoProcesso(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updateProjetoProcessoWorker({ projeto_processo, id}) {
  try {
    const response = yield call(ProjetoProcesso.update, id, projeto_processo);
    yield put(actions.getProjetoProcesso(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}


function* updateProjetoProcessoParecerWorker({ id, projeto_processo}) {
  try {
    const response = yield call(ProjetoProcesso.update_parecer, id, projeto_processo);
    yield put(actions.getProjetoProcesso(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeProjetoProcessoWorker({projeto_processo}) {
  try {
    const response = yield call(ProjetoProcesso.store, projeto_processo);
    yield put(actions.getProjetoProcesso(response));
    yield put(actions.getErros({save:true}));
    yield put(getErros({save:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* removerProjetoProcessoWorker({id}) {
  try {
    yield call(ProjetoProcesso.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherProjetoProcesso() {
  yield takeLatest(actionType.LOAD_PROJETO_PROCESSOS, loadProjetoProcessosWorker);
  yield takeLatest(actionType.STORE_PROJETO_PROCESSO, storeProjetoProcessoWorker);
  yield takeLatest(actionType.SHOW_PROJETO_PROCESSO, showProjetoProcessoWorker);
  yield takeLatest(actionType.UPDATE_PROJETO_PROCESSO, updateProjetoProcessoWorker);
  yield takeLatest(actionType.REMOVER_PROJETO_PROCESSO, removerProjetoProcessoWorker);
  yield takeLatest(actionType.UPDATE_PROJETO_PROCESSO_PARECER, updateProjetoProcessoParecerWorker);
}

export default function* saga() {
  yield all([watcherProjetoProcesso()]);
}
