import * as actions from './actionType';

export const loadRecursos = () => ({
  type: actions.LOAD_RECURSOS
});
export const getRecursos = recursos => ({
  type: actions.RECURSOS_DATA,
  recursos,
});

export const loadSelectRecursos = recursos => ({
  type: actions.LOAD_SELECT_RECURSOS,
  recursos,
});

export const getSelectRecursos = recursos => ({
  type: actions.SELECT_RECURSOS,
  recursos,
});

export const storeRecurso = recurso => ({
  type: actions.STORE_RECURSO,
  recurso,

});
export const getRecurso = recurso => ({
  type: actions.GET_RECURSO,
  recurso,
});

export const removerRecurso = (id) => ({
  type: actions.REMOVER_RECURSO,
  id
});

export const showRecurso = id => ({
  type: actions.SHOW_RECURSO,
  id,
});

export const updateRecurso = (recurso, id) => ({
  type: actions.UPDATE_RECURSO,
  recurso,
  id
});

export const getErros = errors => ({
  type: actions.RECURSO_ERROR,
  errors,
});
