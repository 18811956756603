import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { store } from '~/services/Retificacao';

export default function Form ({ handlePageChange, getRetificacoes }) {
    const [fieldsValues, setFieldsValues] = useState([{ onde_se_le: '', leia: '' }]);
    const { processo: { id: processo_id } } = useSelector(({ ProcessoReducer }) => ProcessoReducer);

    useEffect(() => { return () => handlePageChange('LIST'); }, [ handlePageChange ]);

    function addFields() {
        let newfields = { onde_se_le: '', leia: '' };
        setFieldsValues([...fieldsValues, newfields]);
    }

    function removeFields(index) {
        if (fieldsValues.length > 1) {
            let data = [...fieldsValues];
            data.splice(index, 1);
            setFieldsValues(data);
        }
    }

    function handleChange(event, index) {
        let data = [...fieldsValues];
        data[index][event.target.name] = event.target.value;
        setFieldsValues(data);
    }

    async function handleFormSubmit(event) {
        event.preventDefault();

        try {
            const { message } = await store(processo_id, fieldsValues);

            Swal.fire(
                'Sucesso',
                message,
                'success'
            ).then(async () => {
                await getRetificacoes();
                handlePageChange('LIST');
            });
        } catch (error) {
            console.log(error.response);

            Swal.fire(
                'Erro',
                error.response.data.message,
                'error'
            )
        }
    }

    return (
        <form className="row" onSubmit={handleFormSubmit}>
            {fieldsValues.map((fieldValue, index) => (
                <Fragment key={`retificacao-form-fields-${index}`}>
                    <div className="form-group col-md-5">
                        <label>Onde Se Lê</label>
                        <input
                            name="onde_se_le"
                            className="form-control"
                            value={fieldValue.onde_se_le}
                            onChange={event => handleChange(event, index)}
                            required
                        />
                    </div>
                    <div className="form-group col-md-5">
                        <label>Leia</label>
                        <input
                            name="leia"
                            className="form-control"
                            value={fieldValue.leia}
                            onChange={event => handleChange(event, index)}
                            required
                        />
                    </div>
                    {index > 0 && (
                        <div className="form-group col-md-2 d-flex align-items-end">
                            <button type="button" className="btn btn-danger btn-block" onClick={() => removeFields(index)}>
                                <i className="fas fa-minus">&nbsp;</i>
                                Remover
                            </button>
                        </div>
                    )}
                </Fragment>
            ))}
            <div className="btn-group col-md-12">
                <button type="button" className="btn btn-default" onClick={() => handlePageChange('LIST')}>
                    <i className="fas fa-reply">&nbsp;</i>
                    Voltar
                </button>
                <button type="button" className="btn btn-primary" onClick={addFields}>
                    <i className="fas fa-plus">&nbsp;</i>
                    Adicionar
                </button>
                <button className="btn btn-success">
                    <i className="fas fa-save">&nbsp;</i>
                    Salvar
                </button>
            </div>
        </form>
    );
}
