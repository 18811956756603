import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight, FaCircleNotch } from 'react-icons/fa';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { Loading } from '~/components';
import {
  showProcesso,
  addPessoaResponsavelTecnico,
  changeStatus
} from '~/store/modules/processo/action';
import formatForMask from '~/utils/formatForMask';
import { SelectTecnico, TipoAnotacao } from '../../Forms';

export default function Passo3() {
  let { processo_id } = useParams();
  let history = useHistory();
  const dispatch = useDispatch();
  const [processoShow, setProcessoShow] = useState();
  const [userAnotacaoTecnica, setUserAnotacaoTecnica] = useState();
  const [anotacaoTecnica, setAnotacaoTecnica] = useState({});
  const [loading, setLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const { processo, status_code } = useSelector(({ ProcessoReducer }) => ProcessoReducer);

  const [dataForm, setDataForm] = useState({
    passo: 4,
    pessoa_responsavel_tecnico_id: null

  });

  useEffect(() => {
    if (dataForm.pessoa_responsavel_tecnico_id) {
        setDisabledButton(false);
    }
    if (dataForm.pessoa_responsavel_tecnico_id === null) {
        setDisabledButton(true);
    }
  }, [dataForm.pessoa_responsavel_tecnico_id])

  useEffect(() => {
    if (status_code === 200) {
      Swal.fire(
        'Sucesso',
        'Técnico selecionado com sucesso!',
        'success'
      ).then(() => {
        dispatch(changeStatus(null));
        history.push(
          `/processos/create/pass_${processo.passo}/${processo.id}`
        );
      });
    }
  }, [dispatch, history, processo.id, processo.passo, status_code]);

  useEffect(() => {
    dispatch(showProcesso(processo_id,{
      with: 'pessoa,estado,municipio,atividade,tipo_licenca'
    }));
  }, [processo_id, dispatch]);


  useEffect(() => {
    if (processo.id === parseInt(processo_id)) {
      setProcessoShow(processo);
    }
  }, [processo, processo_id]);



  useEffect(() => {
    if (userAnotacaoTecnica) {
      setDataForm((dataForm) => ({
        ...dataForm,
        pessoa_responsavel_tecnico_id: userAnotacaoTecnica.value,
      }));
    }
  }, [userAnotacaoTecnica]);

  useEffect(() => {
    if (anotacaoTecnica) {
      setDataForm((dataForm) => ({
        ...dataForm,
        ...anotacaoTecnica,
      }));
    }
  }, [anotacaoTecnica]);


  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (dataForm.pessoa_responsavel_tecnico_id) {
      await dispatch(
        addPessoaResponsavelTecnico(
          processo_id, dataForm
        )
      );
    }
  }
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">
          Processo (
          {processoShow && processoShow.protocolo && processoShow.protocolo}) -
          Passo 3 - Selecione o Técnico para avaliar
        </h3>
        <div className="card-tools">
          <Link
            className="btn btn-light"
            to="/processos"
          >
            <FaArrowAltCircleLeft /> Cancelar
          </Link>
        </div>
      </div>
      <div className="card-body">
        {processoShow &&
        processoShow.pessoa &&
        processoShow.atividade &&
        processoShow.id === parseInt(processo_id) ? (
            <form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>Empreendimento</Form.Label>
                <Form.Control
                  id="pessoa"
                  name="pessoa"
                  placeholder="Selecione o Empreendimento"
                  readOnly
                  type="text"
                  value={`${processoShow.pessoa.nome} - ${
                    processoShow.pessoa.cnpj
                      ? `CNPJ: ${formatForMask(
                        processoShow.pessoa.cnpj,
                        '##.###.###/####-##'
                      )}` : ''
                  } ${
                    processoShow.pessoa.cpf
                      ? `CPF: ${formatForMask(
                        processoShow.pessoa.cpf,
                        '###.###.###-##'
                      )}` : ''
                  }`}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Estado</Form.Label>
                <Form.Control
                  id="estado"
                  name="estado"
                  placeholder="Selecione o Estado"
                  readOnly
                  type="text"
                  value={processoShow.estado.nome}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Município</Form.Label>
                <Form.Control
                  id="municipio"
                  name="municipio"
                  placeholder="Selecione o Município"
                  readOnly
                  type="text"
                  value={processoShow.municipio.nome}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Atividade</Form.Label>
                <Form.Control
                  id="atividade"
                  name="atividade"
                  placeholder="Selecione o Atividade"
                  readOnly
                  type="text"
                  value={processoShow.atividade.nome}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Tipo de licença ambiental</Form.Label>
                <Form.Control
                  id="tipo_licenca"
                  name="tipo_licenca"
                  placeholder="Selecione o Tipo de licença ambiental"
                  readOnly
                  type="text"
                  value={processoShow.tipo_licenca.nome}
                />
              </Form.Group>

              <SelectTecnico
                required
                setUserAnotacaoTecnica={setUserAnotacaoTecnica}
              />

              {dataForm.pessoa_responsavel_tecnico_id && (
                <TipoAnotacao
                  pessoa_responsavel_tecnico_id={
                    dataForm.pessoa_responsavel_tecnico_id
                  }
                  setAnotacaoTecnica={setAnotacaoTecnica}
                />
              )}

              <div className="form-group">
                <Link
                  className="btn btn-light"
                  to="/processos"
                >
                  <FaArrowAltCircleLeft /> Cancelar
                </Link>
                <button
                  className="btn btn-success"
                  type="submit"
                  disabled={disabledButton}
                >
                  {loading ?
                    <FaCircleNotch className="spinner"/> : <FaArrowAltCircleRight />
                  } Anexar documentação
                </button>
              </div>
            </form>
          ) : (
            <Loading />
          )}
      </div>
    </div>
  );
}
