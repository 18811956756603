import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal, Form } from 'react-bootstrap';
import { FieldsPF, FieldsPJ, FieldsPT } from './FormFields';
import Swal from 'sweetalert2';
import buscaCep from '~/services/cep';
import initialData from './initialData';
import { updateAuthUser, changeStatusCode } from '~/store/modules/login/action';
import { useEffect } from 'react';
import removeMask from '~/utils/removeMask';

const pj_ids = [6];
const pt_ids = [5, 7];
const pf_ids = [1, 2, 3, 4, 8];

export default function Edit() {
  let history = useHistory();
  let dispatch = useDispatch();
  let FormFields = null;
  const { uf_data } = useSelector(({ UfDataReducer }) => UfDataReducer);
  const { user_auth, status_code } = useSelector(({LoginReducer}) => LoginReducer);
  const { user } =  user_auth;
  const { pessoas } = user;
  const [fields, setFields] = useState({
    ...initialData, ...user, ...pessoas[0]
  });

  if (pf_ids.includes(user.tipo_user_id))
    FormFields = FieldsPF;

  if (pj_ids.includes(user.tipo_user_id))
    FormFields = FieldsPJ;

  if (pt_ids.includes(user.tipo_user_id))
    FormFields = FieldsPT;

    useEffect(() => {
      if (status_code === 200) {
        Swal.fire(
          'Sucesso',
          'Atualização concluída',
          'success'
        ).then(() => {
          dispatch(changeStatusCode(0));
        });
      }

      if (status_code !== 200 && status_code !== 0) {
        Swal.fire(
          'Erro',
          'Erro na atualização',
          'error'
        ).then(() => {
          dispatch(changeStatusCode(0));
        });
      }
    }, [dispatch, status_code]);

  const handleBuscaCep = async ({ target: { value } }) => {
    const cep = value;
    try {
      const res = await buscaCep.get(`${cep}/json`);
      const endereco = res.data;
      const finded = {
        logradouro: endereco.logradouro,
        bairro: endereco.bairro,
        cidade: endereco.localidade
      }

      setFields({
        ...fields,
        ...finded
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose = () => {
    history.goBack();
  };

  const handleChange = ({
    target: { name, value }
  }) => {
    setFields({
      ...fields,
      [name]: value
    });
  }

  const handleSubmit = () => {
    Swal.fire({
      icon: 'question',
      title: 'Deseja salvar suas altereções?',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then(async result => {
      if (result.isConfirmed) {
        fields.telefone = removeMask(fields.telefone ?? '')
        fields.numero_whatsapp = removeMask(fields.numero_whatsapp ?? '')
        fields.cep = removeMask(fields.cep ?? '')
        fields.cpf = removeMask(fields.cpf ?? '')
        fields.cpf_cnpj = removeMask(fields.cpf_cnpj ?? '')
        fields.telefone_responsavel_legal = removeMask(fields.telefone_responsavel_legal ?? '')
        fields.cpf_responsavel_legal = removeMask(fields.cpf_responsavel_legal ?? '')
        const form = new FormData();
        form.append('_method', 'PUT');
        Object.keys(fields).forEach(key => {
          if (fields[key]) {
            form.append(key, fields[key]);
          }
        });

        await dispatch(updateAuthUser(user_auth, form));
      }
    });
  }

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-xl"
      onHide={handleClose}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Editar Perfil
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <FormFields
            ufData={uf_data} handleBuscaCep={handleBuscaCep}
            fields={fields} setFields={setFields} handleChange={handleChange}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ width: '100%' }}>
          <button
            onClick={handleClose}
            className="btn btn-default"
          >
            <i className="fa fa-window-close" /> Fechar
          </button>
          <button
            type="button"
            onClick={handleSubmit}
            className="btn btn-success float-right"
          >
            <i className="fa fa-save" /> Salvar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
