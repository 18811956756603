export const LOAD_MOTORISTAS = 'LOAD_MOTORISTAS';
export const LOAD_SELECT_MOTORISTAS = 'LOAD_SELECT_MOTORISTAS';
export const SELECT_MOTORISTAS = 'SELECT_MOTORISTAS';
export const MOTORISTA_ERROR = 'MOTORISTA_ERROR';
export const MOTORISTA_GET_ERRORS = 'MOTORISTA_GET_ERRORS';
export const MOTORISTAS_DATA = 'MOTORISTAS_DATA';
export const STORE_MOTORISTA = 'STORE_MOTORISTA';
export const GET_MOTORISTA = 'GET_MOTORISTA';
export const REMOVER_MOTORISTA = 'REMOVER_MOTORISTA';
export const SHOW_MOTORISTA = 'SHOW_MOTORISTA';
export const UPDATE_MOTORISTA = 'UPDATE_MOTORISTA';
