import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FiEdit3 } from 'react-icons/fi';
import { Modal } from 'react-bootstrap';
import { showAnalise } from '~/store/modules/analise/action';
import { loadMeioAnalises } from '~/store/modules/meio_analise/action';

import { ModalAnalise, ModalResumo } from './components/';

const AnaliseTecImpacto = ({tipo_licenca_id, analise_id }) => {
  const dispatch = useDispatch();
  let [show, setShow] = useState(false);
  let [showResumo, setShowResumo] = useState(false);
  let [modalAnalise, setModalAnalise] = useState({
    tipo_meio_analise: undefined,
    show: false,
    analise_id,
    handleHide: () => false,
  });
  let [meioAnalises, setMeioAnalises] = useState();

  const { meio_analises } = useSelector(
    ({ MeioAnaliseReducer }) => MeioAnaliseReducer
  );

  const { analise } = useSelector(({ AnaliseReducer }) => AnaliseReducer);

  useEffect(() => {
    if (tipo_licenca_id && analise_id) {
      dispatch(
        loadMeioAnalises({
          where: `tipo_licenca_id:${tipo_licenca_id}`,
          raw_list: true,
          with: `tipo_meio_analises.questao_analises.alternativa_questao_analises,tipo_meio_analises.questao_analises.resposta_questao_analise_previas;analise_processo_id:${analise_id}`,
        })
      );
      dispatch(showAnalise(analise_id));
    }
  }, [tipo_licenca_id, analise_id, dispatch]);


  useEffect(() => {
    let meio_analises_format = [];
    if (meio_analises.data) {
      for (let meio of meio_analises.data) {
        let tipo_meio = [];
        let isOk = true;
        for (let tipo of meio.tipo_meio_analises) {
          let checked = true;
          for (let {
            resposta_questao_analise_previas,
          } of tipo.questao_analises) {
            if (!resposta_questao_analise_previas[0]) {
              checked = false;
              isOk = false;
              break;
            }
          }
          tipo_meio.push({
            id: tipo.id,
            nome: tipo.nome,
            checked,
          });
        }
        meio_analises_format.push({
          id: meio.id,
          nome: meio.nome,
          checked: isOk,
          tipo_meio_analises: tipo_meio,
        });
      }
      setMeioAnalises(meio_analises_format);
    }
  }, [meio_analises]);

  useEffect(() => {
    if (meioAnalises && meioAnalises[0]) {
      for (let { checked } of meioAnalises) {
        if (!checked) {
          break;
        }
      }
    }
  }, [meioAnalises, analise]);

  const handleOpenAnalise = (tipo_meio_analise) => {
    setModalAnalise({
      tipo_meio_analise,
      show: true,
      analise_id,
      handleHide,
    });
  };

  const handleHide = () => {
    setModalAnalise({
      tipo_meio_analise: undefined,
      show: false,
      analise_id,
      handleHide: () => false,
    });
    dispatch(
      loadMeioAnalises({
        where: `tipo_licenca_id:${tipo_licenca_id}`,
        raw_list: true,
        with: `tipo_meio_analises.questao_analises.alternativa_questao_analises,tipo_meio_analises.questao_analises.resposta_questao_analise_previas;analise_processo_id:${analise_id}`,
      })
    );
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Análise Prévia de Impacto Ambiental</h3>
        </div>
        <div className="card-body">
          <button
            className="btn btn-primary btn-block"
            onClick={() => setShow(true)}
          >
            <FiEdit3 />
            Análise Prévia de Impacto Ambiental
          </button>
        </div>
      </div>
      <Modal
        animation
        aria-labelledby="example-custom-modal-styling-title"
        dialogClassName="modal-xl"
        onHide={() => setShow(false)}
        show={show}
      >
        <Modal.Header closeButton>
          <Modal.Title>Análise Prévia de Impacto Ambiental</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Campos preenchidos a partir da vistoria previa e das informações
            apresentadas pelo empreendedor
          </p>

          <div className="card-body">
            <div className="row">
              {meioAnalises &&
                meioAnalises.map(
                  ({ id, nome, tipo_meio_analises, checked }) => (
                    <div
                      className="col-4 card"
                      key={id}
                    >
                      <div className="card-header">
                        <strong>{nome}</strong>

                        {checked ? (
                          <span className="float-right badge badge-success">
                            <i className="fa fa-check" />
                          </span>
                        ) : (
                          <span className="float-right badge badge-danger">
                            <i className="fa fa-window-close" />
                          </span>
                        )}
                      </div>
                      <div className="card-body">
                        {tipo_meio_analises.map((tipo_meio_analise) => (
                          <span key={tipo_meio_analise.id}>
                            {tipo_meio_analise.checked ? (
                              <>
                                <button
                                  className="btn btn-info btn-block m-2"
                                  onClick={() =>
                                    handleOpenAnalise(tipo_meio_analise)
                                  }
                                >
                                  {tipo_meio_analise.nome}
                                  <span className="float-right badge badge-success">
                                    <i className="fa fa-check" />
                                  </span>
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="btn btn-success btn-block m-2"
                                  onClick={() =>
                                    handleOpenAnalise(tipo_meio_analise)
                                  }
                                >
                                  {tipo_meio_analise.nome}
                                  <span className="float-right badge badge-danger">
                                    <i className="fa fa-window-close" />
                                  </span>
                                </button>
                              </>
                            )}
                          </span>
                        ))}
                      </div>
                    </div>
                  )
                )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-group">
            {analise.concluida && (
              <>
                <button
                  className="btn btn-success"
                  onClick={() => setShowResumo(true)}
                >
                  Resumo do Geral de Impacto Ambiental{' '}
                </button>
              </>
            )}
            <button
              className="btn btn-default"
              onClick={() => setShow(false)}
              type="button"
            >
              <i className="fa fa-window-close" /> Fechar
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <ModalAnalise {...modalAnalise} />
      {meio_analises.data && (
        <ModalResumo
          handleHide={() => setShowResumo(false)}
          meio_analises={meio_analises.data}
          show={showResumo}
        />
      )}
    </>
  );
};
export default AnaliseTecImpacto;
