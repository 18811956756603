import React, { useState, useEffect, useRef } from 'react';
import {
  // FaStar,
  // FaComments,
  // FaBell,
  // FaFile,
  // FaUsers,
  // FaEnvelope,
  FaBars
} from 'react-icons/fa'

import { FormSearch } from '~/components';
import {MensagensIcon} from './components';


export default function Header({ onHandleLogout }) {
  const [show, setShow] = useState(false);
  const showRef = useRef(show);

  const handleShow = () => {
    showRef.current = !show;
    setShow(show => !show);
  };

  useEffect(() => {
    window.addEventListener('click', event => {
      const element = document.querySelector('#user-logout');

      if (event.target !== element && showRef.current) {
        showRef.current = false;
        setShow(false);
      }
    });

    return () => window.removeEventListener(
      'click', () => {
        showRef.current = false;
        setShow(false);
      }
    );
  }, []);

  return (
    <nav className="main-header navbar navbar-expand navbar-dark navbar-success">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="/#">
            <FaBars />
          </a>
        </li>
        {/* <li className="nav-item d-none d-sm-inline-block">
          <a
            className="nav-link"
            href="/#"
          >Home</a>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <a
            className="nav-link"
            href="/#"
          >Contact</a>
        </li> */}
      </ul>
      <FormSearch />

      <ul className="navbar-nav ml-auto">
      <MensagensIcon/>
        {/* <li className="nav-item dropdown">
          <a className="nav-link" data-toggle="dropdown" href="/#">
            <FaBell />
            <span className="badge badge-warning navbar-badge">15</span>
          </a>
          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <span className="dropdown-item dropdown-header">
              15 Notifications
            </span>
            <div className="dropdown-divider" />
            <a className="dropdown-item" href="/#">
              <FaEnvelope className="mr-2" /> 4 new messages
              <span className="float-right text-muted text-sm">3 mins</span>
            </a>
            <div className="dropdown-divider" />
            <a className="dropdown-item" href="/#">
              <FaUsers className="mr-2" /> 8 friend requests
              <span className="float-right text-muted text-sm">12 hours</span>
            </a>
            <div className="dropdown-divider" />
            <a className="dropdown-item" href="/#">
              <FaFile className="mr-2" /> 3 new reports
              <span className="float-right text-muted text-sm">2 days</span>
            </a>
            <div className="dropdown-divider" />
            <a className="dropdown-item dropdown-footer" href="/#">
              See All Notifications
            </a>
          </div>
        </li> */}
        <li className={`nav-item dropdown ${show && 'show'}`}>
          <button
            id="user-logout"
            className="nav-link"
            onClick={handleShow}
            style={{
              border: 'none',
              outline: 'none',
              cursor: 'pointer',
              background: 'none'
            }}
          >
            <i className="fas fa-user"></i>
          </button>
          <div className={`dropdown-menu dropdown-menu-lg dropdown-menu-right ${show && 'show'}`}>
            <a className="dropdown-item" href="/#" onClick={onHandleLogout}>
              <ion-icon name="log-in" /> Sair
            </a>
            <div className="dropdown-divider" />
          </div>
        </li>
      </ul>
    </nav>
  );
}
