import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as Condicionante from '~/services/Condicionante';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadCondicionantesWorker({ params }) {
  try {
    const response = yield call(Condicionante.load, params);
    // console.log('loadCondicionantesWorker response: ',response.data);
    yield put(actions.getCondicionantes(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}


function* showCondicionanteWorker({id}) {
  try {
    const response = yield call(Condicionante.show, id);
    // console.log('showcondicionante', response.data);
    yield put(actions.getCondicionante(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updateCondicionanteWorker({ condicionante, id}) {
  try {
    const response = yield call(Condicionante.update, id, condicionante);
    yield put(actions.getCondicionante(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeCondicionanteWorker({condicionante}) {
  try {
    const response = yield call(Condicionante.store, condicionante);
    yield put(actions.changeStatus(response.status));
    yield put(actions.getErros({save:true}));
    yield put(getErros({save:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* removerCondicionanteWorker({id}) {
  try {
    yield call(Condicionante.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherCondicionante() {
  yield takeLatest(actionType.LOAD_CONDICIONATES, loadCondicionantesWorker);
  yield takeLatest(actionType.STORE_CONDICIONATE, storeCondicionanteWorker);
  yield takeLatest(actionType.SHOW_CONDICIONATE, showCondicionanteWorker);
  yield takeLatest(actionType.UPDATE_CONDICIONATE, updateCondicionanteWorker);
  yield takeLatest(actionType.REMOVER_CONDICIONATE, removerCondicionanteWorker);
}

export default function* saga() {
  yield all([watcherCondicionante()]);
}
