import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormCad } from '../components';
import Swal from 'sweetalert2';
import initialData from './initialData';
import schema from './schema'
import { storeCondicionante, loadCondicionantes, changeStatus } from '~/store/modules/condicionante/action';

const Create = ({entidade_id}) => {
  let history = useHistory();
  const [condicionante, setCondicionante] = useState(initialData);
  const { meio_analise_id } = useParams();
  const [errorsValidate, setErrorsValidate] = useState([]);
  const dispatch = useDispatch();
  const { status_response, errors } = useSelector(({ CondicionanteReducer }) => CondicionanteReducer);

  // console.log('status_response condicionante store: ',status_response);
  // console.log('atividade_id condicionante store: ',tipo_meio_analise_id);
  useEffect(() => {
    if (errors.status && errors.status === 422) {
      let server_errors = {};
      const response_errors = errors.data.errors;
      for (let name of Object.keys(response_errors)) {
        Object.assign(server_errors, { [name]: response_errors[name][0] });
      }
      setErrorsValidate(server_errors);
    }
  }, [errors]);

  useEffect(() => {
    if (status_response === 201) {
      Swal.fire('Sucesso', 'Registro salvo com sucesso!', 'success').then(
        () => {
          history.goBack();
          dispatch(loadCondicionantes({
            where: `entidade_id:${entidade_id}`
          }));
          dispatch(changeStatus(null));
        }
      );
    }
  }, [status_response, history, dispatch, meio_analise_id, entidade_id]);


  const handleClose = () => {
    history.goBack();
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (!schema.isValidSync(condicionante)) {
      let yup_errors = {};
      schema.validate(condicionante, { abortEarly: false }).catch(err => {
        for (let error of err.inner) {
          const { path, message } = error;
          Object.assign(yup_errors, { [path]: message });
        }
        setErrorsValidate(yup_errors);
      });
    } else {
      const isEspecifica = condicionante.especifica === 'true' || condicionante.especifica === true ? true : false;
      const condicionante_create = condicionante;
      condicionante_create.especifica = isEspecifica;
      condicionante_create.meio_analise_id = isEspecifica ? parseInt(condicionante_create.meio_analise_id) : null;
      condicionante_create.entidade_id = parseInt(entidade_id);
      // console.log('condicionante_ store: ',condicionante_);
      await dispatch(storeCondicionante(condicionante_create));
    }
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-xl"
      onHide={handleClose}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Condicionante
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Novo</h2>
          </div>
          <div className="card-body">
            <FormCad
              condicionante={condicionante}
              entidade_id={entidade_id}
              errors={errorsValidate}
              handleSubmit={handleSubmit}
              schema={schema}
              setCondicionante={setCondicionante}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-default"
          onClick={handleClose}
        >
          <i className="fa fa-window-close" /> Cancelar
        </button>
        <button
          className="btn btn-success"
          form="form_condicionante"
          type="submint"
        >
          {' '}
          <i className="fa fa-save" /> Salvar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Create;

