import styled from 'styled-components';

export const boxLogin = styled.div`
padding-top: 30px`;

export const contentLogin = styled.div``;

export const Box = styled.div`
  display: block;
  @media (max-width: 1200px ) {
   display: none;
  }
`;
