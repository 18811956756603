export const LOAD_ANALISES = 'LOAD_ANALISES';
export const SELECT_ANALISES = 'SELECT_ANALISES';
export const ANALISE_ERROR = 'ANALISE_ERROR';
export const ANALISE_GET_ERRORS = 'ANALISE_GET_ERRORS';
export const ANALISES_DATA = 'ANALISES_DATA';
export const STORE_ANALISE = 'STORE_ANALISE';
export const GET_ANALISE = 'GET_ANALISE';
export const REMOVER_ANALISE = 'REMOVER_ANALISE';
export const SHOW_ANALISE = 'SHOW_ANALISE';
export const UPDATE_ANALISE = 'UPDATE_ANALISE';
export const FINALIZAR_ANALISE = 'FINALIZAR_ANALISE';
