export const LOAD_DOCUMENTO_PROCESSOS = 'LOAD_DOCUMENTO_PROCESSOS';
export const SELECT_DOCUMENTO_PROCESSOS = 'SELECT_DOCUMENTO_PROCESSOS';
export const DOCUMENTO_PROCESSO_ERROR = 'DOCUMENTO_PROCESSO_ERROR';
export const DOCUMENTO_PROCESSO_GET_ERRORS = 'DOCUMENTO_PROCESSO_GET_ERRORS';
export const DOCUMENTO_PROCESSOS_DATA = 'DOCUMENTO_PROCESSOS_DATA';
export const STORE_DOCUMENTO_PROCESSO = 'STORE_DOCUMENTO_PROCESSO';
export const GET_DOCUMENTO_PROCESSO = 'GET_DOCUMENTO_PROCESSO';
export const REMOVER_DOCUMENTO_PROCESSO = 'REMOVER_DOCUMENTO_PROCESSO';
export const SHOW_DOCUMENTO_PROCESSO = 'SHOW_DOCUMENTO_PROCESSO';
export const UPDATE_DOCUMENTO_PROCESSO = 'UPDATE_DOCUMENTO_PROCESSO';
export const CHANGE_STATUS_CODE = 'CHANGE_STATUS_CODE';
