import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { loadMunicipios } from '~/store/modules/municipio/action';
const SelectMunicipio = ({ estado_id, setMunicipio }) => {
  const dispatch = useDispatch();

  const { municipios } = useSelector(
    ({ MunicipioReducer }) => MunicipioReducer
  );

  const [options, setOptions] = useState([
    { value: null, label: '.::Selecione::.' },
  ]);

  const [optionSelected, setOptionSelected] = useState([
    { value: null, label: '.::Selecione::.' },
  ]);

  useEffect(() => {
    dispatch(loadMunicipios(estado_id, { raw_list: true }));
  }, [estado_id, dispatch]);

  useEffect(() => {
    let optionSet = [{ value: null, label: '.::Selecione::.' }];
    setOptionSelected({ value: null, label: '.::Selecione::.' });
    if (municipios.data) {
      municipios.data.forEach(({ id, nome }) => {
        optionSet.push({ value: id, label: nome });
      });
    }
    setOptions(optionSet);
  }, [municipios]);


  const handleOnChange = (e) => {
    setOptionSelected(e);
    setMunicipio(e);
  };
  return (
    <Form.Group>
      <Form.Label>Selecione o Município</Form.Label>
      <Select
        onChange={handleOnChange}
        options={options}
        value={optionSelected}
      />
      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
    </Form.Group>
  );
};

export default SelectMunicipio;
