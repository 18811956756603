import * as actions from './actionType';

export const EstadoReducer = (state = {estados: [], estado: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.ESTADOS_DATA:
      return {
        ...state,
        estados: action.estados.data,
        links: action.estados.data.links,
        meta: action.estados.data.meta,
      };
    case actions.GET_ESTADO:
      return {
        ...state,
        estado: action.estado.data.data,
        status_response: action.estado.status,
      };
    case actions.ESTADO_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
