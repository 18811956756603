import React from 'react';
import { Content } from '~/layouts/Dash/components';


const BasicContent = ({breadcrumb, children}) => {

  const breadcrumbs = [
    {
      label: 'Analise de Processo',
      to: '/analise-processo/minhas-analises',
      active: false,
    },
    breadcrumb,
  ];
  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Minhas analises"
    >
      {children}
    </Content>
  );
}

export default BasicContent;
