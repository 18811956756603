import {
  LOGIN_RESPONSE, LOGIN_ERROR,
  LOGIN_AUTH, UPDATE_AUTH_USER,
  CHANGE_STATUS_CODE
} from './actionType';

export const login_user_response = user => {
  return {
    type: LOGIN_RESPONSE,
    user
  }
}
export const login_user_error = error => {
  return {
    type: LOGIN_ERROR,
    error
  }
}
export const login_auth = auth => {
  return {
    type: LOGIN_AUTH,
    auth
  }
}

export const updateAuthUser = (authInfo, user) => ({
  type: UPDATE_AUTH_USER,
  authInfo, user
});

export const changeStatusCode = status_code => ({
  type: CHANGE_STATUS_CODE,
  status_code
});
