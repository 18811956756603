import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as ResponsavelTecnico from '~/services/ResponsavelTecnico';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadResponsavelTecnicosWorker({params}) {
  try {
    const response = yield call(ResponsavelTecnico.load, params);
    yield put(actions.getResponsavelTecnicos(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}


function* loadListForAddWorker({ params }) {
  try {
    const response = yield call(ResponsavelTecnico.loadListForAdd, params);
    yield put(actions.getListForAdd(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* showResponsavelTecnicoWorker({id}) {
  try {
    const response = yield call(ResponsavelTecnico.show, id);
    yield put(actions.getResponsavelTecnico(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updateResponsavelTecnicoWorker({ responsavel_tecnico, id}) {
  try {
    const response = yield call(ResponsavelTecnico.update, id, responsavel_tecnico);
    yield put(actions.getResponsavelTecnico(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeResponsavelTecnicoWorker({responsavel_tecnico}) {
  try {
    const response = yield call(ResponsavelTecnico.store, responsavel_tecnico);
    yield put(actions.getResponsavelTecnico(response));
    yield put(actions.getErros({save:true}));
    yield put(getErros({save:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* removerResponsavelTecnicoWorker({id}) {
  try {
    yield call(ResponsavelTecnico.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherResponsavelTecnico() {
  yield takeLatest(actionType.LOAD_RESPONSAVEL_TECNICOS, loadResponsavelTecnicosWorker);
  yield takeLatest(actionType.LOAD_LIST_FOR_ADD, loadListForAddWorker);
  yield takeLatest(actionType.STORE_RESPONSAVEL_TECNICO, storeResponsavelTecnicoWorker);
  yield takeLatest(actionType.SHOW_RESPONSAVEL_TECNICO, showResponsavelTecnicoWorker);
  yield takeLatest(actionType.UPDATE_RESPONSAVEL_TECNICO, updateResponsavelTecnicoWorker);
  yield takeLatest(actionType.REMOVER_RESPONSAVEL_TECNICO, removerResponsavelTecnicoWorker);
}

export default function* saga() {
  yield all([watcherResponsavelTecnico()]);
}
