import { apiWithToken } from '../api';

const token = localStorage.getItem('@token');
let api = apiWithToken(token);

const resouceUrl = '/indicador_processo';

export const list_indicadores_processo = async (processo_id) => {
  return await api.get(`${resouceUrl}/${processo_id}`);
}

export const store_indicador_processo = async (indicador_processo) => {
  return await api.post(`${resouceUrl}`, indicador_processo)
}

export const destroy_indicador_processo = async (id) => {
  return await api.delete(`${resouceUrl}/${id}`);
}
