import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { loadAtividades } from '~/store/modules/atividade/action';
import { activity_group } from "~/services/Atividade";

const SelectAtividades = ({ entidade_id, setAtividade, setActivityGroups, setIsReference, isReference }) => {
    const dispatch = useDispatch();

    const { atividades } = useSelector(
        ({ AtividadeReducer }) => AtividadeReducer
    );

    const [activityGroup, setActivityGroup] = useState([]);

    const [loadGroup, setLoadGroup] = useState(false);

    const [options, setOptions] = useState([
        { value: null, label: '.::Selecione::.' },
    ]);

    const [optionSelected, setOptionSelected] = useState([
        { value: null, label: '.::Selecione::.' },
    ]);
    // select activity group
    const [optionsGroup, setOptionsGroup] = useState([]);
    const [optionsGroupSelected, setOptionsGroupSelected] = useState([]);
    useEffect(() => {
        if( activityGroup ) {
            let optionGroupSet = [];
            setOptionsGroupSelected([]);
            setActivityGroups([]);
            if (activityGroup.data) {
            activityGroup.data.forEach(({ id, nome, codigo }) => {
                optionGroupSet.push({ value: id, label: `${codigo} - ${nome}` });
            });
            }
            setOptionsGroup(optionGroupSet);
        }
    }, [activityGroup, setActivityGroups]);
    // end select activity group
    useEffect(() => {
        dispatch(
        loadAtividades({
            raw_list: true,
            where: `entidade_id:${entidade_id}`,
        })
        );
    }, [entidade_id, dispatch]);

    useEffect(() => {
        let optionSet = [{ value: null, label: '.::Selecione::.' }];
        setOptionSelected({ value: null, label: '.::Selecione::.' });
        if (atividades.data) {
        atividades.data.forEach(({ id, nome, codigo }) => {
            optionSet.push({ value: id, label: `${codigo} - ${nome}` });
        });
        }
        setOptions(optionSet);
    }, [atividades]);

    const handleOnChange = async (e) => {
        // if (isReference) setIsReference(!isReference)
        // setOptionsGroup([])
        // setOptionsGroupSelected(null);

        setOptionSelected(e);
        const { status, data} = await activity_group(entidade_id, e.value);
        if (status === 200) {
            setActivityGroup(data)
            setLoadGroup(true);
            setAtividade(e);
        }
    };
    const handleOnGruopChange = async (e) => {
        setOptionsGroupSelected(e)
        const values = e.map(option => option.value);
        setActivityGroups(values)
    }

    return (
        <>
            <Form.Group>
                <Form.Label>Selecione a Atividade que Deseja Licenciar (Atividade Principal). </Form.Label>

                <Select
                    onChange={handleOnChange}
                    options={options}
                    value={optionSelected}
                />
                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
            </Form.Group>
            {loadGroup && (
                <div className="mt-3 mb-3">
                    <Form.Check
                        label="Deseja incluir outras atividades do mesmo grupo?"
                        onChange={() => setIsReference(!isReference)}
                        type="checkbox"
                        value={isReference}
                    />
                    {isReference && (
                        <>
                            <Form.Label>Selecione outras atividades.</Form.Label>
                            <Select
                                isMulti
                                onChange={handleOnGruopChange}
                                options={optionsGroup}
                                value={optionsGroupSelected}
                            />
                        </>
                    )}
                </div>
            )}
        </>
    );
};
export default SelectAtividades;
