import { apiWithToken } from '../api';

const token = localStorage.getItem('@token');
let api = apiWithToken(token);

export const loadArquivo = async (path, params) => {

  return await api.get(`${path}`, {
    params: {
      ...params
    },
  });
}
