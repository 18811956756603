import React, {useEffect} from 'react';
import { Link, Switch, Route, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { Paginate } from '~/components';
import { loadMunicipios, removerMunicipio } from '~/store/modules/municipio/action';
import Create from '../Create';
import Edit from '../Edit';


const List = ( {estado_id} ) => {
  let location = useLocation();
  let modal = location.state && location.state.modal;
  const dispatch = useDispatch();
  const { municipios, meta } = useSelector(
    ({ MunicipioReducer }) => MunicipioReducer
  );

  useEffect(() => {
    dispatch(loadMunicipios(estado_id))
  }, [dispatch, estado_id]);


  const handleRemove = async (event) => {
    const id = event.target.value;
    Swal.fire({
      title: 'Remover?',
      text: 'Deseja Remover esse item?!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        try {
          await dispatch(removerMunicipio(id));
          await Swal.fire('Sucesso',
            'Removido com sucesso!',
            'success');
          dispatch(loadMunicipios(estado_id))
        } catch (error) {
          await Swal.fire('Erro',
            'Erro ao Remover',
            'error');
        }
      }
    })
  };
  return (
    <>
      {modal && (
        <Switch>
          <Route
            children={<Edit/>}
            exact
            path="/estado/:estado_id/municipio/:municipio_id/edit"
          />
          <Route
            children={<Create />}
            exact
            path="/estado/:estado_id/municipio/novo"
          />
        </Switch>
      )}
      <div className="card">
        <div className="card-header">
          <h2 className="card-title">Municípios</h2>
          <div className="card-tools">
            <Link
              className="btn btn-success"
              to={{
                pathname: `/estado/${estado_id}/municipio/novo`,
                state: { modal: true },
              }}
            >
              <i className="fa fa-plus" /> Adicionar
            </Link>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered table-hover table-striped table-sm">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>Código IBGE</th>
                  <th className="text-right">Ações</th>
                </tr>
              </thead>
              <tbody>
                {municipios.data &&
                  municipios.data.map((municipio, index) => (
                    <tr key={index}>
                      <td>{municipio.id}</td>
                      <td>{municipio.nome}</td>
                      <td>{municipio.cod_ibge}</td>
                      <td className="text-right">
                        <div className="btn-group">
                          <Link
                            className="btn btn-default"
                            to={{
                              pathname: `/estado/${estado_id}/municipio/${municipio.id}/edit`,
                              state: { modal: true },
                            }}
                          >
                            <FaEdit /> Editar
                          </Link>
                          <button
                            className="btn btn-danger"
                            onClick={handleRemove}
                            value={municipio.id}
                          >
                            <FaTrash /> Remover
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="card-footer clearfix">
          <Paginate load={loadMunicipios} loadParans={estado_id} meta={meta} />
        </div>
      </div>
    </>
  );
};

export default List;
