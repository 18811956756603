import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as Atividade from '~/services/Atividade';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadAtividadesWorker({ params }) {
  try {
    const response = yield call(Atividade.load, params);
    // console.log('loadAtividadesWorker response: ',response);
    yield put(actions.getAtividades(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}


function* showAtividadeWorker({payload}) {
  try {
    const response = yield call(Atividade.show, payload);
    yield put(actions.getAtividade(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updateAtividadeWorker({ atividade, id}) {
  try {
    const response = yield call(Atividade.update, id, atividade);
    yield put(actions.getAtividade(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeAtividadeWorker({atividade}) {
  try {
    const response = yield call(Atividade.store, atividade);
    yield put(actions.getAtividade(response));
    yield put(actions.getErros({save:true}));
    yield put(getErros({save:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* removerAtividadeWorker({id}) {
  try {
    yield call(Atividade.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherAtividade() {
  yield takeLatest(actionType.LOAD_ATIVIDADES, loadAtividadesWorker);
  yield takeLatest(actionType.STORE_ATIVIDADE, storeAtividadeWorker);
  yield takeLatest(actionType.SHOW_ATIVIDADE, showAtividadeWorker);
  yield takeLatest(actionType.UPDATE_ATIVIDADE, updateAtividadeWorker);
  yield takeLatest(actionType.REMOVER_ATIVIDADE, removerAtividadeWorker);
}

export default function* saga() {
  yield all([watcherAtividade()]);
}
