import styled from 'styled-components';

export const boxSelectCurso = styled.div`
  flex: 1;
  flex-direction: column;
  max-width: 900px;
  margin: auto;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  height: 70vh;
  min-height: 450px;
  padding: 20px;
  > h3 {
    margin-top: 20px;
  }
`;

export const boxActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 200px;
  .btn-musgo {
    background: #1c2d18;
    color: #ffffff;
  }
  .btn-musgo:hover {
    color: #d7d7d7d7;
  }
`;
