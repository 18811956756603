import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa';

import { Container, BoxTitle } from './styles';
import { Content } from './components';

const Certidao = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Container>
      <BoxTitle>
        <div>
          <h2>CERTIDÃO DE PROCESSO AMBIENTAL - CPA</h2>
        </div>
        <div>
          <Link
            className="btn btn-light"
            to="/processos"
          >
            <FaArrowAltCircleLeft /> Cancelar
          </Link>
        </div>
      </BoxTitle>
      <div className="card">
        <div className="card-body">
          <Content />
        </div>
        <div className="card-footer">
          <Link
            className="btn btn-success"
            to="/distribuicao/projeto"
          >
            <FaArrowAltCircleRight /> Avançar
          </Link>
        </div>
      </div>
    </Container>
  );
};
export default Certidao;
