import { apiWithToken } from '../api';

const resouceUrl = '/recurso';
const token = localStorage.getItem('@token');
let api = apiWithToken(token);

export const load = async () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let page = (urlParams.has('page')) ? `&page=${urlParams.get('page')}`:'';
  let search = (urlParams.has('search')) ? `&search=${urlParams.get('search')}`:'';
  return await api.get(`${resouceUrl}?${page}${search}`);
}

export const select = async () => {

  return await api.get(`${resouceUrl}?fields=id,nome&raw_list`);
};

export const show = async (id) => {
  return await api.get(`${resouceUrl}/${id}`);
}

export const update = async (id, recurso) => {
  return await api.put(`${resouceUrl}/${id}`, recurso);
}


export const store = async (recurso) => {
  let response = await api.post(resouceUrl , recurso);
  return response;
}

export const destroy = async (id) => {
  return await api.delete(`${resouceUrl}/${id}`);
}
