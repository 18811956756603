import * as actions from './actionType';

export const loadMotoristas = (params) => ({
  type: actions.LOAD_MOTORISTAS,
  params
});
export const getMotoristas = motoristas => ({
  type: actions.MOTORISTAS_DATA,
  motoristas,
});

export const loadSelectMotoristas = motoristas => ({
  type: actions.LOAD_SELECT_MOTORISTAS,
  motoristas,
});

export const getSelectMotoristas = motoristas => ({
  type: actions.SELECT_MOTORISTAS,
  motoristas,
});

export const storeMotorista = motorista => ({
  type: actions.STORE_MOTORISTA,
  motorista,

});
export const getMotorista = motorista => ({
  type: actions.GET_MOTORISTA,
  motorista,
});

export const removerMotorista = (id) => ({
  type: actions.REMOVER_MOTORISTA,
  id
});

export const showMotorista = id => ({
  type: actions.SHOW_MOTORISTA,
  id,
});

export const updateMotorista = (motorista, id) => ({
  type: actions.UPDATE_MOTORISTA,
  motorista,
  id
});

export const getErros = errors => ({
  type: actions.MOTORISTA_ERROR,
  errors,
});
