import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
// import Swal from 'sweetalert2';
import { BasicContent, BtnEnviarAnalista } from '../components';
import { Loading } from '~/components';
import formatForMask from '~/utils/formatForMask';
import { MovimentacaoProcesso, CheckList } from '~/views/Process/components';
import { ProgressBar } from '../../Process/List/styles';
import { AnaliseTecImpacto, TaxaAmbiental, Certidao } from './components';

import { showProcesso } from '~/store/modules/processo/action';

const Show = () => {
    let { processo_id } = useParams();
    // let history = useHistory();
    const dispatch = useDispatch();
    const { processo } = useSelector(({ ProcessoReducer }) => ProcessoReducer);

    const [processoShow, setProcessoShow] = useState();

    useEffect(() => {
        dispatch(
            showProcesso(processo_id, {
                with:
                    'analise_previa,' +
                    'pessoa,' +
                    'estado,' +
                    'documentos,' +
                    'municipio,' +
                    'pessoa_responsavel_tecnico,' +
                    'tipo_licenca,' +
                    'status_processo,' +
                    'porte_empreendimento,' +
                    'atividade.potencial_poluidor,' +
                    'user_avaliador.pessoa_tecnico_profissional,' +
                    'entidade,' +
                    'tramites,' +
                    'grupo_atividades',
            })
        );
    }, [processo_id, dispatch]);

    useEffect(() => {
        if (processo && processo.id === parseInt(processo_id)) {
            setProcessoShow(processo);
        }
    }, [processo, processo_id]);

    return (
        <BasicContent
            breadcrumb={{
                label: 'Detalhes',
                to: '/distribuicao',
                active: false,
            }}
            title="Detalhes Processo."
        >
            <div className="card">
                {processoShow &&
                processoShow.protocolo &&
                processoShow.pessoa_responsavel_tecnico ? (
                    <>
                        <div className="card-header">
                            <h3 className="card-title">
                                PROCESSO :{' '}
                                <strong>{processoShow.protocolo}</strong>
                            </h3>
                            <div className="card-tools">
                                <Link
                                    className="btn  btn-default"
                                    to="/distribuicao"
                                >
                                    <FaArrowAltCircleLeft />
                                    Voltar
                                </Link>
                            </div>
                        </div>
                        <div className="card-body table-responsive">
                            <table className="table table-hover table-striped table-bordered table-sm">
                                <tbody>
                                    <tr>
                                        <th>Processo</th>
                                        <td>{processoShow.protocolo}</td>
                                    </tr>
                                    <tr>
                                        <th>Empreendimento</th>
                                        <td>
                                            {' '}
                                            {`${processoShow.pessoa?.nome} - ${
                                                processoShow.pessoa?.cnpj
                                                    ? formatForMask(
                                                          processoShow.pessoa
                                                              ?.cnpj,
                                                          '##.###.###/####-##'
                                                      )
                                                    : ''
                                            } ${
                                                processoShow.pessoa?.cpf
                                                    ? formatForMask(
                                                          processoShow.pessoa
                                                              ?.cpf,
                                                          '###.###.###-##'
                                                      )
                                                    : ''
                                            }`}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Porte Empreendimento</th>
                                        <td>
                                            {processoShow.porte_empreendimento &&
                                                processoShow
                                                    .porte_empreendimento?.nome}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Local</th>
                                        <td>
                                            {' '}
                                            {
                                                processoShow.municipio?.nome
                                            } - {processoShow.estado?.uf}
                                        </td>
                                    </tr>
                                    {processoShow.entidade?.cnae_group &&
                                    processoShow.grupo_atividades ? (
                                        <>
                                            <tr>
                                                <th>
                                                    Atividade Principal
                                                    Licenciada
                                                </th>
                                                <td>
                                                    {' '}
                                                    {
                                                        processoShow.atividade
                                                            ?.nome
                                                    }
                                                </td>
                                            </tr>
                                            {processoShow.grupo_atividades.map(
                                                (grupo, index) => (
                                                    <tr key={index}>
                                                        <th>
                                                            Atividade(s)
                                                            Secundária(s)
                                                            Licenciada(s)
                                                        </th>
                                                        <td>{grupo.nome}</td>
                                                    </tr>
                                                )
                                            )}
                                        </>
                                    ) : (
                                        <tr>
                                            <th>Atividade</th>
                                            <td>
                                                {' '}
                                                {processoShow.atividade?.nome}
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <th>Tipo de licença</th>
                                        <td>
                                            {' '}
                                            {processoShow.tipo_licenca?.nome}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Técnico Consultor</th>
                                        <td>
                                            {processoShow.pessoa_responsavel_tecnico &&
                                                processoShow
                                                    .pessoa_responsavel_tecnico
                                                    ?.responsavel_tecnico &&
                                                processoShow
                                                    .pessoa_responsavel_tecnico
                                                    ?.responsavel_tecnico
                                                    ?.pessoa_tecnico_profissional &&
                                                processoShow
                                                    .pessoa_responsavel_tecnico
                                                    ?.responsavel_tecnico
                                                    ?.pessoa_tecnico_profissional
                                                    ?.nome}{' '}
                                            -{' '}
                                            {processoShow.pessoa_responsavel_tecnico &&
                                            processoShow
                                                .pessoa_responsavel_tecnico
                                                ?.responsavel_tecnico &&
                                            processoShow
                                                .pessoa_responsavel_tecnico
                                                ?.responsavel_tecnico
                                                ?.pessoa_tecnico_profissional &&
                                            processoShow
                                                .pessoa_responsavel_tecnico
                                                ?.responsavel_tecnico
                                                ?.pessoa_tecnico_profissional
                                                ?.cnpj
                                                ? formatForMask(
                                                      processoShow
                                                          .pessoa_responsavel_tecnico
                                                          ?.responsavel_tecnico
                                                          ?.pessoa_tecnico_profissional
                                                          ?.cnpj,
                                                      '##.###.###/####-##'
                                                  )
                                                : '' -
                                                  processoShow
                                                      .pessoa_responsavel_tecnico
                                                      ?.responsavel_tecnico
                                                      ?.pessoa_tecnico_profissional
                                                      ?.cpf
                                                ? formatForMask(
                                                      processoShow
                                                          .pessoa_responsavel_tecnico
                                                          ?.responsavel_tecnico
                                                          ?.pessoa_tecnico_profissional
                                                          ?.cpf,
                                                      '###.###.###-##'
                                                  )
                                                : ''}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Analista Responsável</th>
                                        <td>
                                            {processoShow.user_avaliador
                                                ?.pessoa_tecnico_profissional ? (
                                                <>
                                                    {`${processoShow.user_avaliador?.pessoa_tecnico_profissional?.nome} `}{' '}
                                                    -
                                                    {processoShow.user_avaliador
                                                        ?.pessoa_tecnico_profissional
                                                        ?.cnpj
                                                        ? ` CNPJ: ${formatForMask(
                                                              processoShow
                                                                  .user_avaliador
                                                                  ?.pessoa_tecnico_profissional
                                                                  ?.cnpj,
                                                              '##.###.###/####-##'
                                                          )}`
                                                        : ''}
                                                    {processoShow.user_avaliador
                                                        ?.pessoa_tecnico_profissional
                                                        ?.cpf
                                                        ? ` CPF: ${formatForMask(
                                                              processoShow
                                                                  .user_avaliador
                                                                  ?.pessoa_tecnico_profissional
                                                                  ?.cpf,
                                                              '###.###.###-##'
                                                          )}`
                                                        : ''}
                                                </>
                                            ) : (
                                                <BtnEnviarAnalista
                                                    processo_id={
                                                        processoShow.id
                                                    }
                                                />
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Status</th>
                                        <td>
                                            <span className="badge badge-success">
                                                {
                                                    processoShow.status_processo
                                                        ?.nome
                                                }
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Progresso</th>
                                        <td className="project_progress">
                                            <div className="progress progress-sm">
                                                <ProgressBar
                                                    ariaVolumemax="100"
                                                    ariaVolumemin="0"
                                                    ariaVolumenow={
                                                        (processoShow.passo *
                                                            100) /
                                                        20
                                                    }
                                                    className="progress-bar bg-green"
                                                    percents={
                                                        (processoShow.passo *
                                                            100) /
                                                        20
                                                    }
                                                    role="progressbar"
                                                />
                                            </div>
                                            <small>
                                                {(processoShow.passo * 100) /
                                                    20}
                                                % completo
                                            </small>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                ) : (
                    <Loading />
                )}
            </div>
            <div className="row">
                <Certidao processo={processoShow} />

                {processoShow && processoShow.status_processo_id === 5 && (
                    <TaxaAmbiental processo={processoShow} />
                )}
                <div className="col-md-4">
                    {processoShow ? (
                        <>
                            {processoShow.analise_previa && (
                                <>
                                    {processoShow.tipo_licenca && (
                                        <AnaliseTecImpacto
                                            analise_id={
                                                processoShow.analise_previa.id
                                            }
                                            tipo_licenca_id={
                                                processoShow.tipo_licenca.id
                                            }
                                        />
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <Loading />
                    )}
                </div>

                <div className="col-md-4">
                    <MovimentacaoProcesso processo_id={processo_id} />
                </div>

                <div className="col-md-4">
                    <CheckList lista processo_id={processo_id} />
                </div>
            </div>
        </BasicContent>
    );
};

export default Show;
