import React from 'react';
import Swal from 'sweetalert2';
import imageCompression from 'browser-image-compression';
import { Form } from 'react-bootstrap';

export default function FileInput ({
  id, dataError = false, name,
  accept = '.jpeg, .jpg, .png, .pdf',
  data, setData,
  isRequired, ...rest
}) {
  const handleFile = async event => {
    const { name, files } = event.target;
    if (files.length) {
      let file = files[0];
      const fileType = file['type'];
      const imageTypes = ['image/jpeg', 'image/jpg', 'image/png'];

      if (imageTypes.includes(fileType)) {
        try {
          file = await imageCompression(
            file, {
              maxSizeMB: 5,
              maxWidthOrHeight: 1920
            }
          );
        } catch (error) {
          console.log(error);
        }
      } else {
        let fileSize = (file.size / 1024).toFixed();

        if(fileSize > 5000){
          let getInput = document.querySelector(`input[name=${name}]`);

          Swal.fire(
            'Erro',
            'Seu anexo ultrapassa o limite de 5MB, tente outro.',
            'error'
          );

          getInput.value = null;
        }
      }

      setData({
        ...data,
        [name]: file
      });
    } else {
      setData({
        ...data,
        [name]: null
      });
    }
  }

  return (
    <div className="custom-file">
      <Form.File
        custom
        id="formcheck-api-custom"
      >
        <Form.File.Input
          accept={accept}
          id={id}
          isInvalid={!!dataError}
          name={name}
          onChange={handleFile}
          required={isRequired}
          {...rest}
        />
        <Form.File.Label data-browse="Buscar">
          {!!data[name] && typeof data[name] === 'object' && data[name].name}
          {((!!data[name] && typeof data[name] === 'string') || !data[name]) && 'Selecione o arquivo'}
        </Form.File.Label>
        <Form.Control.Feedback type="invalid">{dataError}</Form.Control.Feedback>
      </Form.File>
    </div>
  );
}
