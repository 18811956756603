import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as Menu from '~/services/Menu';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadMenuWorker() {
  try {
    const response = yield call(Menu.load);
    yield put(actions.getMenus(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* loadSubMenuWorker({ menu_id }) {
  try {
    const response = yield call(Menu.loadSubMenu, menu_id);
    yield put(actions.getMenus(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* loadMenuTreeWorker() {
  try {
    const response = yield call(Menu.loadMenuTree);
    yield put(actions.getMenuTree(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* showMenuWorker({id}) {
  try {
    const response = yield call(Menu.show, id);
    yield put(actions.getMenu(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updateMenuWorker({ menu, id}) {
  try {
    const response = yield call(Menu.update, id, menu);
    yield put(actions.getMenu(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeMenuWorker({menu}) {
  try {
    const response = yield call(Menu.store,menu);
    console.log(response);
    yield put(actions.getMenu(response));
    yield put(actions.getErros({save:true}));
    yield put(getErros({save:true}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* removerMenuWorker({id}) {
  try {
    const response = yield call(Menu.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));
    console.log(response);
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherMenu() {
  yield takeLatest(actionType.LOAD_MENU, loadMenuWorker);
  yield takeLatest(actionType.LOAD_SUBMENU, loadSubMenuWorker);
  yield takeLatest(actionType.STORE_MENU, storeMenuWorker);
  yield takeLatest(actionType.SHOW_MENU, showMenuWorker);
  yield takeLatest(actionType.UPDATE_MENU, updateMenuWorker);
  yield takeLatest(actionType.REMOVER_MENU, removerMenuWorker);
  yield takeLatest(actionType.LOAD_MENU_TREE, loadMenuTreeWorker);
}

export default function* saga() {
  yield all([watcherMenu()]);
}
