import { all, put, takeEvery, call } from 'redux-saga/effects';
import  * as actionType from './actionType';
import * as actions from './action';
import * as Entidade from '~/services/Entidade';
import { getErros } from '~/store/modules/errors/action';

function* loadEntidadeWorker({params}) {
  try {
    const response = yield call(Entidade.list_entidade, params);
    yield put(actions.getEntidades(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response.data));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeEntidadeWorker({entidade}) {
  try {
    const response = yield call(Entidade.store_entidade, entidade);
    yield put(actions.changeStatus(response.status));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response.data));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updateEntidadeWorker({ id, entidade }) {
  try {
    const response = yield call(Entidade.update, id, entidade);
    yield put(actions.getEntidade(response));
    yield put(actions.changeStatus(response.status));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* ativarDesativarEntidadeWorker({ id }) {
  try {
    yield call(Entidade.ativar_desativar_entidade, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* showEntidadeWorker({payload}) {
  try {
    const response = yield call(Entidade.show_entidade, payload);
    yield put(actions.getEntidade(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* registerFinancialAccountWorker({ id, password }) {
  try {
    const response = yield call(
      Entidade.register_financial_account,
      id, password
    );
    yield put(actions.getEntidade(response));
    yield put(actions.changeStatus(response.status));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* changeFinancialPasswordWorker({ id, password, new_password, new_password_confirmation }) {
  try {
    const response = yield call(
      Entidade.change_financial_password,
      id, password, new_password, new_password_confirmation
    );
    yield put(actions.getEntidade(response));
    yield put(actions.changeStatus(response.status));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

// function* makeFinancialAccountWorker({ id }) {
//   try {
//     yield call(Entidade.make_financial_account, id);
//     yield put(actions.getErros({}));
//     yield put(getErros({}));
//   } catch (error) {
//     if (error.response) {
//       yield put(actions.getErros(error.response));
//       yield put(getErros(error.response));
//     } else if (error.request) {
//       yield put(getErros({data: error.message}));
//     } else {
//       yield put(getErros({data: error.message}));
//     }
//   }
// }

function* watcherEntidade() {
  yield takeEvery(actionType.LOAD_ENTIDADE, loadEntidadeWorker);
  yield takeEvery(actionType.STORE_ENTIDADE, storeEntidadeWorker);
  yield takeEvery(actionType.ATIVAR_DESATIVAR_ENTIDADE, ativarDesativarEntidadeWorker);
  yield takeEvery(actionType.SHOW_ENTIDADE, showEntidadeWorker);
  yield takeEvery(actionType.UPDATE_ENTIDADE, updateEntidadeWorker);
  yield takeEvery(actionType.REGISTER_FINANCIAL_ACCOUNT, registerFinancialAccountWorker);
  yield takeEvery(actionType.CHANGE_FINANCIAL_PASSWORD, changeFinancialPasswordWorker);
  // yield takeEvery(actionType.MAKE_FINANCIAL_ACCOUNT, makeFinancialAccountWorker);
}


export default function* saga() {
  yield all([watcherEntidade()]);
}
