/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { FaList, FaClipboard } from 'react-icons/fa';
import { BasicContent } from '../components';
import { Loading, Paginate } from '~/components';
import formatDataHora from '~/utils/formatDataHora';
import SearchForm from '~/components/SearchForm';
import { loadAutorizacao } from '~/store/modules/processo/action';

export default function List() {
  const breadcrumb = {
    label: 'Analise Processo',
    to: '/analise-processo',
    active: true,
  };

  const dispatch = useDispatch();
  const { processos, meta } = useSelector(
    ({ ProcessoReducer }) => ProcessoReducer
  );
  const { user_auth } = useSelector(({ LoginReducer }) => LoginReducer);
  const [params, setParams] = useState({
    where: `status_processo_id:11,entidade_id:${user_auth.user.entidade_id}`,
    with: 'tipo_licenca,atividade,tramites;tipo:enviado_para_analista,status_processo',
    order_by: 'created_at:desc'
  });

  useEffect(() => {
    if (user_auth.user?.entidade_id) {
      dispatch(
        loadAutorizacao({
          with: 'tipo_licenca,atividade,tramites;tipo:enviado_para_autorizacao,status_processo',
          order_by: 'created_at:desc'
        })
      );
    }
  }, [dispatch, user_auth.user, params]);

  return (
    <BasicContent
      breadcrumb={breadcrumb}
      title="AMBIENTE VIRTUAL DO ANALISTA - AVA"
    >
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">
            <FaList />&nbsp;Processos Distribuídos para o Analista
          </h3>
          <SearchForm
            load={loadAutorizacao}
            options={[
              {value: 'protocolo', label: 'Protocolo'},
              {value: 'pessoa;cpf', label: 'CPF'},
              {value: 'pessoa;cnpj', label: 'CNPJ'}
            ]}
            relationships="tipo_licenca,atividade,tramites;tipo:enviado_para_autorizacao,status_processo"
          />
        </div>
        <div className="card-body table-responsive">
          <Table
            bordered
            hover
            size="sm"
            striped
          >
            <thead>
              <tr>
                <th> Número Processo </th>
                <th> Data Entrada </th>
                <th> Data Distribuição </th>
                <th> Atividade/Licença </th>
                <th> Status </th>
                <th> Ações </th>
              </tr>
            </thead>
            <tbody>
              {processos.data &&
                processos.data.map(
                  (
                    {
                      id,
                      protocolo,
                      tramites,
                      atividade,
                      tipo_licenca,
                      created_at,
                      status_processo,
                    },
                    index
                  ) => {
                    return (
                      <tr key={index}>
                        <td>{protocolo}</td>
                        <td>{formatDataHora(created_at)}</td>
                        <td>
                          {tramites &&
                            tramites[0] &&
                            formatDataHora(tramites[0].created_at)}
                        </td>
                        <td>
                          {atividade && atividade.nome} /{' '}
                          {tipo_licenca && tipo_licenca.nome}
                        </td>
                        <td>
                          <span className="badge badge-success">
                            {status_processo.nome}
                          </span>
                        </td>
                        <td>
                          <div className="btn-group">
                            {' '}

                            <Link
                              className="btn btn-info"
                              to={`/autorizar-processo/${id}/detalhe`}
                            >
                              <FaClipboard /> Analisar
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                )}
            </tbody>
          </Table>
          {!processos.data && <Loading />}
        </div>
        <div className="card-footer">
          <Paginate
            load={() => loadAutorizacao({
              with:'tipo_licenca,atividade,tramites;tipo:enviado_para_autorizacao,status_processo',
              order_by: 'created_at:desc'
            })}
            meta={meta}
          />
        </div>
      </div>
    </BasicContent>
  );
}
