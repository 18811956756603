import React, { useEffect, useState } from 'react';
import { FaFileContract } from 'react-icons/fa';
import { FiSend } from 'react-icons/fi';
import { FaCheckCircle } from 'react-icons/fa';
import { Modal } from '..';
import { Loading } from '~/components';
import Swal from 'sweetalert2';
import { ModalAnexos } from '~/components';
import { useSelector, useDispatch } from 'react-redux';
import { loadListConfigAutorizacoes } from '~/store/modules/config_autorizacao/action';
import { finalizarProcesso, enviarLicenca } from '~/store/modules/processo/action';
import {
  storeAutorizacaoProcesso,
  changeStatus,
  removerAutorizacaoProcesso,
} from '~/store/modules/autorizacao_processo/action';
import { useHistory } from 'react-router-dom';

const Autorizacao = ({ processo_id, entidade_id, status_processo_id }) => {
  const history = useHistory();
  const { user_auth } = useSelector(({ LoginReducer }) => LoginReducer);
  const { config_autorizacoes } = useSelector(
    ({ ConfigAutorizacaoReducer }) => ConfigAutorizacaoReducer
  );
  const { status_response } = useSelector(
    ({ AutorizacaoProcessoReducer }) => AutorizacaoProcessoReducer
  );
  const  { status_code } = useSelector(
    ({ ProcessoReducer }) => ProcessoReducer
  );
  const [loading, setLoading] = useState(false);
  const [usersAutorizacao, setUsersAutorizacao] = useState([]);
  const dispatch = useDispatch();
  const [modalArquivo, setModalArquivo] = useState({
    title: '',
    path: '',
    show: false,
    handleHide: () => false,
  });

  useEffect(() => {
    if (config_autorizacoes?.data?.length > 0) {
      setUsersAutorizacao(config_autorizacoes.data);
    }
  }, [config_autorizacoes]);

  useEffect(() => {
    if (status_response === 201) {
      dispatch(
        loadListConfigAutorizacoes({
          entidade_id,
          processo_id,
        })
      );
      dispatch(changeStatus(null));
    }
  }, [status_response, dispatch, entidade_id, processo_id]);

  const handleSendLicenca = async () => {
    Swal.fire({
      title: 'Enviar?',
      text: 'Deseja enviar a licença por e-mail?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.value) {
        try {
          setLoading(true);
          await dispatch(enviarLicenca(processo_id));
          await Swal.fire('Sucesso', 'E-mail enviado com sucesso!', 'success');
        } catch (error) {
          await Swal.fire('Erro', 'Erro ao enviar e-mail', 'error');
        } finally {
        setLoading(false);
      }
    }
  });
};

  const handleFinalizar = async (processo_id) => {
    Swal.fire({
      title: 'Deseja finalizar?',
      text: 'Ao finalizar sua licença não será mais alterada.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.value) {
        try {
          setLoading(true);
          await dispatch(finalizarProcesso(processo_id));
          const { value, isConfirmed } = await Swal.fire('Sucesso', 'Finalizado com sucesso', 'success');
          if (status_code === 200 && isConfirmed && value === true) {
            history.go(0)
          }
        } catch (error) {
          await Swal.fire('Erro', 'Erro ao finalizar', 'error');
        } finally {
          setLoading(false);
      }
    }
  });
};

  const handleAutorization = async (users) => {
    Swal.fire({
      title: 'Deseja autorizar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.value) {
        try {
          const autorizacao_data = {
            id_processo: parseInt(processo_id),
            config_autorizacao_id: users.id,
            user_id: user_auth?.user?.id,
          };

          await dispatch(storeAutorizacaoProcesso(autorizacao_data));
          await Swal.fire(
            'Sucesso',
            'Autorização concedida com sucesso!',
            'success'
          );
        } catch (error) {
          Swal.fire('Error', 'Error ao conceder a autorização ', 'error');
        }
      }
    });
  };

  const handleRemoveAutorization = async (id_autorization) => {
    Swal.fire({
      title: 'Remover autorização?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.value) {
        try {
          await dispatch(removerAutorizacaoProcesso(id_autorization));
          await Swal.fire(
            'Sucesso',
            'Autorização removida com sucesso!',
            'success'
          );
          await dispatch(
            loadListConfigAutorizacoes({
              entidade_id,
              processo_id,
            })
          );
        } catch (error) {
          Swal.fire('Error', 'Error ao remover a autorização ', 'error');
        }
      }
    });
  };

  const handleOpenModalAnexo = (title, path) => {
    setModalArquivo({
      title,
      path,
      show: true,
      handleHide,
    });
  };

  const handleHide = () => {
    setModalArquivo({
      title: '',
      path: '',
      show: false,
      handleHide: () => false,
    });
  };

  useEffect(() => {
    if (entidade_id) {
      dispatch(
        loadListConfigAutorizacoes({
          entidade_id,
          processo_id,
        })
      );
    }
  }, [entidade_id, dispatch, processo_id]);



  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Autorização</h3>
        </div>
        <div className="card-body">
          <button
            className={`btn ${parseInt(status_processo_id) === 7 ? 'btn-primary' : 'btn-success'} btn-block`}
            data-target="#modal-autorizacao"
            data-toggle="modal"
          >
            <FaFileContract /> Autorização
          </button>
        </div>
      </div>
      <Modal id="modal-autorizacao" title="Autorização">
        <table className="table">
          <thead>
            <tr>
              <th colSpan="2">Responsável</th>
              <th>Nível</th>
              <th colSpan="3">Status</th>
            </tr>
          </thead>
          <tbody>
            {usersAutorizacao?.map((users) => (
              <tr key={`${users.id}-user`}>
                <td colSpan="2">{users.nomeclatura}</td>
                <td>{`Nível ${users.nivel_autorizacao}`}</td>
                <td colSpan="3">
                  <div className="btn-group">
                    {users.pode_autorizar && parseInt(status_processo_id) !== 7 ? (
                      <>
                        {users.autorizou ? (
                          <button
                            className="btn btn-danger"
                            onClick={() =>
                              handleRemoveAutorization(users.autorizou.id)
                            }
                          >
                            Remover Autorização
                          </button>
                        ) : (
                          <button
                            className="btn btn-success"
                            onClick={() => handleAutorization(users)}
                          >
                            Autorizar
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        {users.autorizou ? (
                          <p>Autorizou <FaCheckCircle  color="#28a745" style={{ marginLeft: 5 }} /></p>
                        ): 'Aguardando autorização'}
                      </>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="text-center">
          <br />
          <br />
          <button
            className="btn btn-default ml-2"
            onClick={() =>
              handleOpenModalAnexo(
                'Pdf',
                `${process.env.REACT_APP_API_URL}/processo/licenca/${processo_id}/gerar_licenca`
              )
            }
          >
            <i className="fa fa-paperclip" /> Visualizar
          </button>
          {loading ? (
            <Loading />
          ) : (
            <>
            {status_processo_id === 7 && (
              <button
                className="btn btn-primary ml-2"
                onClick={() => handleSendLicenca()}
              >
                <FiSend /> Enviar Licença
              </button>
            )}
            </>
          )}
          {usersAutorizacao && usersAutorizacao.length && usersAutorizacao[0].pode_finalizar && (
            <button
              className="btn btn-success ml-2"
              onClick={() => handleFinalizar(processo_id)}
            >
              <i className="fa fa-check" /> Finalizar processo
            </button>
          )}
        </div>
      </Modal>
      <ModalAnexos {...modalArquivo} />
    </>
  );
};
export default Autorizacao;
