import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Loading } from '~/components';
import { FormCad } from '../components';
import initialData from './initialData';
import schema from './schema'
import { updateResponsavelTecnico, showResponsavelTecnico, loadResponsavelTecnicos } from '~/store/modules/responsavel_tecnico/action';



const Edit = () => {
  let history = useHistory();
  const [responsavelTecnicoEdit, setResponsavelTecnicoEdit] = useState(initialData);
  const [errorsValidate, setErrorsValidate] = useState([]);
  const [updated, setUpdated] = useState(false);
  const dispatch = useDispatch();
  const { responsavel_tecnico_id } = useParams();
  const { responsavel_tecnico, errors } = useSelector(({ ResponsavelTecnicoReducer }) => ResponsavelTecnicoReducer);

  useEffect(() => {
    dispatch(showResponsavelTecnico(responsavel_tecnico_id));
  }, [dispatch, responsavel_tecnico_id]);

  useEffect(() => {
    if (responsavel_tecnico.id === parseInt(responsavel_tecnico_id)) {

      setResponsavelTecnicoEdit(responsavel_tecnico);
    }
  }, [responsavel_tecnico, responsavel_tecnico_id]);

  useEffect(() => {
    if (errors.status && errors.status === 422) {
      let server_errors = {};
      const response_errors = errors.data.errors;
      for (let name of Object.keys(response_errors)) {
        Object.assign(server_errors, { [name]: response_errors[name][0] });
      }
      setErrorsValidate(server_errors);
    }
    if(errors.update) {
      setUpdated(true);
    }
  }, [errors]);

  useEffect(() => {
    if (errorsValidate.at_least_one_true) {
        Swal.fire('Error', errorsValidate.at_least_one_true, 'error');
    }
  }, [errorsValidate])

  useEffect(() => {
    if (updated) {
      Swal.fire('Sucesso', 'Registro atualizada com sucesso!', 'success').then(
        () => {
          history.goBack();
          dispatch(loadResponsavelTecnicos());
        }
      );
    }
  }, [updated, history, dispatch]);

  const handleClose = () => {
    history.goBack('/tecnicos/responsavel_tecnico');
  };


  const handleSubmit = async event => {
    event.preventDefault();
    if (!schema.isValidSync(responsavelTecnicoEdit)) {
      let yup_errors = {};
      schema.validate(responsavelTecnicoEdit, { abortEarly: false }).catch(err => {
        for (let error of err.inner) {
          const { path, message } = error;
          Object.assign(yup_errors, { [path]: message });
        }
        setErrorsValidate(yup_errors);
      });
    } else {
      let {
        pode_elaboracao_e_estudo_projeto,
        pode_elaboracao_orcamento,
        pode_execucao_estudo_e_projeto,
        pode_fiscalizacao_estudo_e_projeto,
        pode_implantacao_estudo_e_projeto,
        pode_monitoramento_estudo_e_projeto
      } = responsavelTecnicoEdit;


      await dispatch(
        updateResponsavelTecnico(
          {
            pode_elaboracao_e_estudo_projeto,
            pode_elaboracao_orcamento,
            pode_execucao_estudo_e_projeto,
            pode_fiscalizacao_estudo_e_projeto,
            pode_implantacao_estudo_e_projeto,
            pode_monitoramento_estudo_e_projeto,
          },
          responsavel_tecnico_id
        )
      );
    }

  };
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-xl"
      onHide={handleClose}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          ResponsavelTecnico
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Editar</h2>
          </div>
          <div className="card-body">
            {responsavelTecnicoEdit.id === parseInt(responsavel_tecnico_id) ? (
              <FormCad
                edit
                errors={errorsValidate}
                handleSubmit={handleSubmit}
                responsavel_tecnico={responsavelTecnicoEdit}
                schema={schema}
                setResponsavelTecnico={setResponsavelTecnicoEdit}
              />
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Link className="btn btn-default" to="/tecnicos/responsavel_tecnico">
          <i className="fa fa-window-close" /> Cancelar
        </Link>
        <button
          className="btn btn-success"
          form="form_responsavel_tecnico"
          type="submint"
        >
          <i className="fa fa-save" /> Salvar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Edit;
