import React from 'react';
import { FaFileDownload } from 'react-icons/fa';

const CheckList = ({lista}) => {
  let listDocs = [
    {
      num: 1,
      doc: 'Publicação do PEDIDO DE LICENÇA no DIARIO OFICIAL DO ESTADO',
    },
    {
      num: 2,
      doc:
        'Publicação do PEDIDO DE LICENÇA no JORNAL LOCAL DE GRANDE CIRCULAÇÃO',
    },
    {
      num: 3,
      doc: 'Registro Geral do Empreendedor',
    },
    {
      num: 4,
      doc: 'Cadastro de Pessoa Física do Empreendedor',
    },
    {
      num: 5,
      doc: 'Comprovante de Endereço do Empreendedor',
    },
    {
      num: 6,
      doc: 'Procuração Particular (quando for necessário)',
    },
    {
      num: 7,
      doc: 'Registro Geral do Procurador (quando for necessário)',
    },
    {
      num: 8,
      doc: 'Cadastro de Pessoa Física do Procurador (quando for necessário)',
    },
    {
      num: 9,
      doc: 'Comprovante de Endereço do Procurador',
    },
    {
      num: 10,
      doc: 'Cartão do Cadastro Nacional de Pessoa Jurídica - CNPJ',
    },
    {
      num: 11,
      doc: 'Certidão de Anuência da Prefeitura – Uso e Ocupação do Solo',
    },
    {
      num: 12,
      doc:
        'Certificado de Regularidade da SEICOM (Lei Est. 1.613 de 30/12/11) (para empresas de mineração)',
    },
    {
      num: 13,
      doc: 'Certidão Simplificada da Junta Comercial do Estado',
    },
    {
      num: 14,
      doc:
        'Documento de Responsabilidade Técnica (DRT) do respectivo conselho de classe.',
    },
    {
      num: 15,
      doc:
        'Comprovante de Domínio ou de Posse do Terreno, ou Contrato de Aluguel do Imóvel.',
    },
    {
      num: 16,
      doc:
        'Registro de Licença do DNPM em vigor, no caso de solicitação de L.O em processo mineral.',
    },
    {
      num: 17,
      doc:
        'Anuência do ICMBio (Inst. Chico Mendes de Conserv. da Biodiver.) – Qdo houver relação c/ UC Federal',
    },
    {
      num: 18,
      doc:
        'Anuência da SEMA (Secretaria de Estado de Meio Ambiente) – Qdo houver relação c/ UC Estadual',
    },
    {
      num: 19,
      doc:
        'Anuência da Secretaria Municipal de Meio Ambiente – Qdo houver relação c/ UC Municipal',
    },
    {
      num: 20,
      doc: 'Declaração de Outorga de Uso da Água (Documento IMAP)',
    },
    {
      num: 21,
      doc: 'Certidão da ANP (Agência Nacional de Petróleo)',
    },
    {
      num: 22,
      doc:
        'Declaração do IPHAN (Instituto do Patrimônio Histórico e Artístico Nacional)',
    },
    {
      num: 23,
      doc:
        'Alvará da Vigilância Sanitária Municipal ou Estadual ou Federal / Alvará de Vistoria do Bombeiro',
    },
  ];
  let files = (<></>);
  if (!lista) {
    files = (
      <div className="form-group">
        <input
          className="form-control"
          type="file"
        />
      </div>
    );
  } else {
    files = (
      <button className="btn btn-default" href="/#">
        <FaFileDownload /> Baixar
      </button>
    );
  }
  return (
    <div className="table-responsive">
      <table className="table table-striped table-hover table-bordered">
        <tbody>
          {listDocs.map(({ num, doc }) => (
            <tr key={num}>
              <th>{num}</th>
              <th>{doc}</th>
              <td>{files}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CheckList;
