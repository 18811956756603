import { apiWithToken } from '../api';

const token = localStorage.getItem('@token');
let api = apiWithToken(token);

const resouceUrl = '/indicador_ambiental';

export const list_indicador_ambiental = async (params) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let page = urlParams.has('page')
    ? { page: urlParams.get('page') }
    : {};
  let search = urlParams.has('search')
    ? { search: urlParams.get('search') }
    : {};
  return await api.get(`${resouceUrl}`, {
    params: {
      ...params,
      ...page,
      ...search
    }
  });
}

export const show_indicador_ambiental = async (payload) => {
  return await api.get(`${resouceUrl}/${payload.id}`, {
    params: {
      ...payload.params
    }
  });
}

export const store_indicador_ambiental = async (indicador_ambiental) => {
  return await api.post(`${resouceUrl}`, indicador_ambiental)
}

export const update_indicador_ambiental = async (id, indicador_ambiental) => {
  return await api.put(`${resouceUrl}/${id}`, indicador_ambiental);
}

export const destroy_indicador_ambiental = async (id) => {
  return await api.delete(`${resouceUrl}/${id}`);
}

export const activate_indicador_ambiental = async (id) => {
  return await api.post(`${resouceUrl}/${id}/activate`);
}

export const import_data = async (atividade_id, data_file_csv) => {
  let formData = new FormData();
  formData.append('arquivo', data_file_csv);
  formData.append('atividade_id', atividade_id);

  return await api.post(`${resouceUrl}/import_data`,
    formData,
    atividade_id
    , {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
}

