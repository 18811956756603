import * as actions from './actionType';

export const RelatorioVistoriaReducer = (state = {select_relatorio_vistorias: [], relatorio_vistorias: [], relatorio_vistoria: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.RELATORIO_VISTORIAS_DATA:
      return {
        ...state,
        relatorio_vistorias: action.relatorio_vistorias.data,
        links: action.relatorio_vistorias.data.links,
        meta: action.relatorio_vistorias.data.meta,
      };
    case actions.SELECT_RELATORIO_VISTORIAS:
      return {
        ...state,
        select_relatorio_vistorias: action.relatorio_vistorias.data
      };
    case actions.GET_RELATORIO_VISTORIA:
      return {
        ...state,
        relatorio_vistoria: action.relatorio_vistoria.data.data,
        status_response: action.relatorio_vistoria.status,
      };
    case actions.RELATORIO_VISTORIA_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
