import * as actions from './actionType';

export const PessoaJuridicaReducer = (state = {select_pessoa_juridicas: [], pessoa_juridicas: [], pessoa_juridica: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.PESSOA_JURIDICAS_DATA:
      return {
        ...state,
        pessoa_juridicas: action.pessoa_juridicas.data,
        links: action.pessoa_juridicas.data.links,
        meta: action.pessoa_juridicas.data.meta,
      };
    case actions.SELECT_PESSOA_JURIDICAS:
      return {
        ...state,
        select_pessoa_juridicas: action.pessoa_juridicas.data
      };
    case actions.GET_PESSOA_JURIDICA:
      return {
        ...state,
        pessoa_juridica: action.pessoa_juridica.data.data,
        status_response: action.pessoa_juridica.status,
      };
    case actions.PESSOA_JURIDICA_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
