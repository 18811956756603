import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as TecnicoAvaliador from '~/services/TecnicoAvaliador';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadTecnicosAvaliadoresWorker({ params }) {
  try {
    const response = yield call(TecnicoAvaliador.load, params);
    yield put(actions.getTecnicosAvaliadores(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* showTecnicoAvaliadorWorker({ id, params }) {
  try {
    const response = yield call(TecnicoAvaliador.show, id, params);
    yield put(actions.getTecnicoAvaliador(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* storeTecnicoAvaliadorWorker({ tecnico_avaliador }) {
  try {
    const response = yield call(TecnicoAvaliador.store, tecnico_avaliador);
    yield put(actions.getTecnicoAvaliador(response));
    yield put(actions.changeStatus(response.status));
    yield put(actions.getErros({ save: true }));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* updateTecnicoAvaliadorWorker({ id, tecnico_avaliador }) {
  try {
    const response = yield call(TecnicoAvaliador.update, id, tecnico_avaliador);
    yield put(actions.getTecnicoAvaliador(response));
    yield put(actions.changeStatus(response.status));
    yield put(actions.getErros({ update: true }));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherTecnicoAvaliador() {
  yield takeLatest(actionType.LOAD_TECNICOS_AVALIADORES, loadTecnicosAvaliadoresWorker);
  yield takeLatest(actionType.SHOW_TECNICO_AVALIADOR, showTecnicoAvaliadorWorker);
  yield takeLatest(actionType.STORE_TECNICO_AVALIADOR, storeTecnicoAvaliadorWorker);
  yield takeLatest(actionType.UPDATE_TECNICO_AVALIADOR, updateTecnicoAvaliadorWorker);
}

export default function* saga() {
  yield all([watcherTecnicoAvaliador()]);
}
