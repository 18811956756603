import { StyleSheet, Font } from '@react-pdf/renderer';
import InterRegular from '~/assets/fonts/Inter/Inter-Regular.ttf';
import InterSemiBold from '~/assets/fonts/Inter/Inter-SemiBold.ttf';
import InterBold from '~/assets/fonts/Inter/Inter-Bold.ttf';

Font.register({
  family: 'Inter',
  fonts: [
    { src: InterRegular },
    { src: InterSemiBold, fontStyle: 'semibold' },
    { src: InterBold, fontStyle: 'bold' },
  ]
});

export default StyleSheet.create({
  page: {
    padding: '30px 50px 70px 50px',
    flexDirection: 'column'
  },
  section: {
    margin: '15px 0px'
  },
  title: {
    fontSize: '13px',
    fontFamily: 'Inter',
    fontStyle: 'bold'
  },
  subtitle: {
    fontSize: '13px',
    fontFamily: 'Inter'
  },
  paragraph: {
    fontSize: '14px',
    lineHeight: '1.5',
    fontFamily: 'Inter',
    marginLeft: '22px',
    marginTop: '10px'
  },
  horizontalLine: {
    borderBottom: '1px solid black',
    marginVertical: '10px',
    marginBottom: '20px',
    marginTop: '20px',
  },
  label: {
    fontSize: '11px',
    fontFamily: 'Inter',
    fontStyle: 'semibold'
  },
  table: {
    border: '1px solid black',
    borderRight: 'none',
    borderBottom: 'none',
    margin: '5px 0px'
  },
  tr: {
    flexDirection: 'row'
  },
  semibold: {
    fontFamily: 'Inter',
    fontStyle: 'semibold'
  },
  footer: {
    bottom: '20px',
    alignSelf: 'center',
    position: 'absolute'
  },
  pagination: {
    fontFamily: 'Inter',
    fontSize: '11px',
    alignSelf: 'flex-end',
    bottom: '30px',
    position: 'absolute',
    right: '50px'
  }
});
