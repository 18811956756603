import * as actions from './actionType';

export const loadMeioAnalises = (params) => ({
  type: actions.LOAD_MEIO_ANALISES,
  params
});

export const loadMeioAnalisesWithRespostas = (tipo_licenca_id, analise_id) => ({
  type: actions.LOAD_MEIO_ANALISES_WITH_RESPOSTAS,
  tipo_licenca_id, analise_id
});

export const loadMeioAnalisesWithRespostasApia = (tipo_licenca_id, analise_id) => ({
  type: actions.LOAD_MEIO_ANALISES_WITH_RESPOSTAS_APIA,
  tipo_licenca_id, analise_id
});


export const getMeioAnalisesWithRespostas = meio_analises_with_respostas => ({
  type: actions.MEIO_ANALISES_WITH_RESPOSTAS_DATA,
  meio_analises_with_respostas
});


export const getMeioAnalisesWithRespostasApia = meio_analises_with_respostas_apia => ({
  type: actions.MEIO_ANALISES_WITH_RESPOSTAS_APIA_DATA,
  meio_analises_with_respostas_apia
});


export const getMeioAnalises = meio_analises => ({
  type: actions.MEIO_ANALISES_DATA,
  meio_analises,
});

export const getSelectMeioAnalise = meio_analises => ({
  type: actions.SELECT_MEIO_ANALISES,
  meio_analises,
});

export const storeMeioAnalise = meio_analise => ({
  type: actions.STORE_MEIO_ANALISE,
  meio_analise,

});
export const getMeioAnalise = meio_analise => ({
  type: actions.GET_MEIO_ANALISE,
  meio_analise,
});

export const removerMeioAnalise = (id) => ({
  type: actions.REMOVER_MEIO_ANALISE,
  id
});

export const showMeioAnalise = (id, params) => ({
  type: actions.SHOW_MEIO_ANALISE,
  id,
  params
});

export const updateMeioAnalise = (meio_analise, id) => ({
  type: actions.UPDATE_MEIO_ANALISE,
  meio_analise,
  id
});

export const getErros = errors => ({
  type: actions.MEIO_ANALISE_ERROR,
  errors,
});

export const changeStatus = status => ({
  type: actions.CHANGE_STATUS_CODE,
  status,
});

export const activeMeioAnalise = id => ({
  type: actions.ACTIVE_MEIO_ANALISE,
  id,
});
