import React, { useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';

import { Form } from 'react-bootstrap';

const SelectEstado = ({ setEstado }) => {
  const { uf_data } = useSelector(({ UfDataReducer }) => UfDataReducer);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    let optionSet = [];
    if (uf_data[0]) {
      uf_data.forEach(({ id, nome, uf }) => {
        optionSet.push({ value: id, label: `${nome} - ${uf}` });
      });
    }
    setOptions(optionSet);
  }, [uf_data]);

  const handleChoiceEstado = (estado) => {
    setEstado(estado);
  };

  return (
    <Form.Group>
      <Form.Label>Selecione o Estado</Form.Label>
      <Select
        onChange={handleChoiceEstado}
        options={options}
      />
      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
    </Form.Group>
  );
};
export default SelectEstado;
