import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { load } from '~/services/Retificacao';
import CardWithModal from '../CardWithModal';
import List from './components/List';
import Form from './components/Form';
import Modificacao from './components/Modificacao';
import { RetificacaoContext } from './context';

export default function Retificacao() {
    const { processo_id } = useParams();
    const [retificacoes, setRetificacoes] = useState([]);
    const [retificacoesFinished, setRetificacoesFinished] = useState(false);
    const [currentPage, setCurrentPage] = useState('LIST');
    const [selectedRetificacaoIndex, setSelectedRetificacaoIndex] = useState(0);
    const { user_auth: { user } } = useSelector(({ LoginReducer }) => LoginReducer);

    useEffect(() => {
        retificacoes.forEach(retificacao => {
            const modificacoes = retificacao.modificacoes;

            if (modificacoes.filter(modificacao => modificacao.status === 'ANALISE').length === 0) {
                const qtdModificacoes = modificacoes.length;
                const qtdModificacoesAprovadas = modificacoes.filter(modificacao => modificacao.status === 'APROVADA').length
                const qtdModificacoesReprovadas = modificacoes.filter(modificacao => modificacao.status === 'REPROVADA').length

                if (qtdModificacoes === qtdModificacoesAprovadas) {
                    retificacao.status = 'APROVADA';
                } else if (qtdModificacoes === qtdModificacoesReprovadas) {
                    retificacao.status = 'REPROVADA';
                } else {
                    retificacao.status = 'APROVADA_PARCIALMENTE';
                }
            }
        });

        setRetificacoesFinished(retificacoes.filter(retificacao => retificacao.status === 'ANALISE').length === 0);
    }, [ retificacoes ]);

    const getRetificacoes = useCallback(async () => {
        try {
            const res = await load(processo_id);
            setRetificacoes(res.data);
        } catch (error) {
            console.log(error);

            Swal.fire(
                'Erro',
                'Não foi possível carregar as retificações',
                'error'
            );
        }
    }, [ processo_id ]);

    useEffect(() => { getRetificacoes(); }, [ getRetificacoes ]);

    const handlePageChange = useCallback(pageName => {
        setCurrentPage(pageName);
    }, []);

    return (
        <RetificacaoContext.Provider value={[retificacoes, setRetificacoes]}>
            <CardWithModal title="Retificações" finished={retificacoesFinished}>
                {currentPage === 'FORM' && <Form handlePageChange={handlePageChange} getRetificacoes={getRetificacoes}/>}
                {currentPage === 'LIST' && <List authUser={user} handlePageChange={handlePageChange} setSelectedRetificacaoIndex={setSelectedRetificacaoIndex}/>}
                {currentPage === 'MODIFICACOES' && <Modificacao authUser={user} handlePageChange={handlePageChange} retificacaoIndex={selectedRetificacaoIndex}/>}
            </CardWithModal>
        </RetificacaoContext.Provider>
    );
}
