import styled from 'styled-components';

export const Container = styled.div`
  background-color: #333;
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 1000;
`;

export const ButtonClose = styled.button`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  background-color: #333;
  height: 30px;
`;

export const ButtonPrint = styled.button`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  background-color: #333;
  height: 30px;
  margin-right: 10px;
`;
