import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Loading } from '~/components';
import { FormCad } from '../components';
import initialData from './initialData';
import schema from './schema'
import { updatePotencialPoluidor, showPotencialPoluidor, loadPotencialPoluidores } from '~/store/modules/potencial_poluidor/action';



const Edit = () => {
  let history = useHistory();
  const [potencialPoluidorEdit, setPotencialPoluidorEdit] = useState(initialData);
  const [errorsValidate, setErrorsValidate] = useState([]);
  const [updated, setUpdated] = useState(false);
  const dispatch = useDispatch();
  const { potencial_poluidor_id } = useParams();
  const { potencial_poluidor, errors } = useSelector(({ PotencialPoluidorReducer }) => PotencialPoluidorReducer);

  useEffect(() => {
    dispatch(showPotencialPoluidor(potencial_poluidor_id));
  }, [dispatch, potencial_poluidor_id]);

  useEffect(() => {
    if (potencial_poluidor.id === parseInt(potencial_poluidor_id)) {
      setPotencialPoluidorEdit(potencial_poluidor);
    }
  }, [potencial_poluidor, potencial_poluidor_id]);

  useEffect(() => {
    if (errors.status && errors.status === 422) {
      let server_errors = {};
      const response_errors = errors.data.errors;
      for (let name of Object.keys(response_errors)) {
        Object.assign(server_errors, { [name]: response_errors[name][0] });
      }
      setErrorsValidate(server_errors);
    }
    if(errors.update) {
      setUpdated(true);
    }
  }, [errors]);

  useEffect(() => {
    if (updated) {
      Swal.fire('Sucesso', 'Registro atualizada com sucesso!', 'success').then(
        () => {
          history.goBack();
          dispatch(loadPotencialPoluidores());
        }
      );
    }
  }, [updated, history, dispatch]);

  const handleClose = () => {
    history.goBack('/potencial_poluidor');
  };


  const handleSubmit = async event => {
    event.preventDefault();
    if (!schema.isValidSync(potencialPoluidorEdit)) {
      let yup_errors = {};
      schema.validate(potencialPoluidorEdit, { abortEarly: false }).catch(err => {
        for (let error of err.inner) {
          const { path, message } = error;
          Object.assign(yup_errors, { [path]: message });
        }
        setErrorsValidate(yup_errors);
      });
    } else {
      await dispatch(updatePotencialPoluidor(potencialPoluidorEdit, potencial_poluidor_id));
    }

  };
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-xl"
      onHide={handleClose}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">PotencialPoluidor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Editar</h2>
          </div>
          <div className="card-body">
            {potencial_poluidor.id === parseInt(potencial_poluidor_id) ? (
              <FormCad
                errors={errorsValidate}
                potencialPoluidor={potencialPoluidorEdit}
                handleSubmit={handleSubmit}
                schema={schema}
                setPotencialPoluidor={setPotencialPoluidorEdit}
              />
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Link className="btn btn-default" to="/potencial_poluidor">
          <i className="fa fa-window-close" /> Cancelar
        </Link>
        <button className="btn btn-success" form="form_potencial_poluidor" type="submint">
          <i className="fa fa-save" /> Salvar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Edit;
