import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import FileInputCsv from '../../../components/FileInputCsv';
import Swal from 'sweetalert2';
import { Loading, GerateLinkToCsv } from '~/components';
import { import_data } from '../../../services/IndicadorAmbiental';
import { useDispatch } from 'react-redux';
import { loadIndicadoresAmbientais } from '~/store/modules/indicadorAmbiental/action';



const Create = () => {
  let history = useHistory();
  const { atividade_id } = useParams();
  const [arquivo, setArquivo] = useState({});
  const [dataError, setDataError] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();


  const headers = [
    { label: 'codigo', key: 'codigo' },
    { label: 'descricao', key: 'descricao' },
    { label: 'indice', key: 'indice' },
    { label: 'unidade', key: 'unidade' },
  ];

  const data = [
    {
      codigo: 'Código de exemplo',
      descricao: 'Descrição de exemplo',
      indice: 'Indice de exemlo',
      unidade: 'Unidade de exemplo',
    },
  ];

  const handleImportData = async () => {
    try {
      setLoading(true);
      const { status } = await import_data(atividade_id, arquivo);

      setLoading(false);
      if (status === 200) {
        await dispatch(
          dispatch(loadIndicadoresAmbientais({
            where: `atividade_id:${atividade_id}`,
            with_trashed: true,
          }))
        );
        await Swal.fire('Sucesso', 'Registro salvo com sucesso!', 'success');
        history.goBack();
      }

    } catch (error) {
      setDataError('Erro inesperado aconteceu, verifique seus dados.');
      if (error.response.status === 422) {
        let server_errors = [];
        server_errors = error.response.data;

        for (let [, value] of Object.entries(server_errors[0])) {
          Swal.fire('Algo deu errado!', `${value}`, 'error');
        }
      }
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setDataError(null);
  }, [arquivo]);


  const handleClose = () => {
    history.goBack();
  };


  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-md"
      onHide={handleClose}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Importar dados de Indicadores ambientais</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        {loading ? (
          <Loading />
        ) : (
          <>
            <Alert variant="info">
              Os arquivos devem ser obrigatoriamente em <strong>CSV</strong> como exemplo você pode usar clicando
              {' '}
              <GerateLinkToCsv
                data_fields={data}
                data_headers={headers}
                import_table_name="indicadores_ambientais"
              >
                aqui.
              </GerateLinkToCsv>
            </Alert>

            <FileInputCsv
              data={arquivo}
              dataError={dataError}
              name="indicadores_ambientais_import"
              setData={setArquivo}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-default"
          disabled={loading}
          onClick={handleClose}
        >
          <i className="fa fa-window-close" /> Cancelar
        </button>
        <button
          className="btn btn-success"
          disabled={loading}
          onClick={handleImportData}
          type="button"
        >
          {' '}
          <i className="fa fa-save" /> Salvar
        </button>
      </Modal.Footer>
    </Modal >
  );
};

export default Create;
