import React from 'react';
import {
  Page, Text, View, Document
} from '@react-pdf/renderer';
import styles from './styles';
import { Header, Footer } from '~/components/PDF';
import extractTextFromHTML from '~/utils/extractTextFromHTML';
import InfoTable from '~/components/PDF/InfoTable'


export default function ParecerProjetoPDF({
    process
  }) {

    return (
        <Document title="Projeto Geral do Empreendimento ou Atividade">
            <Page size="A4" style={styles.page} wrap>
              <Header
                logoEntity={process?.entidade?.logo_base64}
                entityName={process?.entidade?.nome}
                organName={process?.entidade?.nome_orgao_ambiental}
                LogoCounty={process?.entidade?.logo_municipio_base64}
                styles={styles}
              />
              <View style={{ marginTop: '-10px' }}>
                <Text style={[styles.title, { textAlign: 'center' }]}>
                  Projeto geral do Empreendimento ou Atividade
                </Text>
              </View>
              <View style={styles.section}>
                <InfoTable 
                  process={process}
                />
              </View>
              <View style={styles.section}>
                <Text style={styles.title}>
                  1. Introdução
                </Text>
                <Text style={styles.paragraph}>
                    {extractTextFromHTML(process?.projeto?.introducao)} 
                </Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.title}>
                  2. Desenvolvimento
                </Text>
                <Text style={styles.paragraph}>
                    {extractTextFromHTML(process?.projeto?.desenvolvimento)} 
                </Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.title}>
                  3. Conclusão
                </Text>
                <Text style={styles.paragraph}>
                    {extractTextFromHTML(process?.projeto?.conclusao)} 
                </Text>
              </View>
              <View style={styles.horizontalLine} />
              <View style={{ marginTop: '-10px' }}>
                <Text style={[styles.title, { textAlign: 'center' }]}>
                  Projeto geral do Empreendimento ou Atividade Parecer
                </Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.title}>
                  1. Parecer Introdução
                </Text>
                <Text style={styles.paragraph}>
                    {extractTextFromHTML(process?.projeto?.introducao_parecer || 'Sem parecer.')} 
                </Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.title}>
                  2. Parecer Desenvolvimento
                </Text>
                <Text style={styles.paragraph}>
                    {extractTextFromHTML(process?.projeto?.desenvolvimento_parecer || 'Sem parecer.')} 
                </Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.title}>
                  3. Parecer Conclusão
                </Text>
                <Text style={styles.paragraph}>
                    {extractTextFromHTML(process?.projeto?.conclusao_parecer || 'Sem parecer.')} 
                </Text>
              </View>
              <Footer
                  logoSiblam={process?.entidade?.src_siblam_logo_base64}
                  styles={styles}
              />
            </Page>
        </Document>
    );
  }


