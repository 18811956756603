import React, { useEffect } from 'react';
import { Link, Switch, Route, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { BasicContent } from '../components';
import { Loading, Paginate } from '~/components';
import { loadTipoLicencas, removerTipoLicenca } from '~/store/modules/tipo_licenca/action';
import Create from '../Create';
import Edit from '../Edit';
import Show from '../Show';

const List = () => {
  let location = useLocation();
  let modal = location.state && location.state.modal;
  const dispatch = useDispatch();
  const { tipo_licencas, meta } = useSelector(
    ({ TipoLicencaReducer }) => TipoLicencaReducer
  );

  useEffect(() => {
    dispatch(loadTipoLicencas());
  }, [dispatch]);

  const breadcrumb = {
    label: 'Lista',
    to: '/tipo_licenca',
    active: false,
  };

  const handleRemove = async (event) => {
    const id = event.target.value;
    Swal.fire({
      title: 'Remover?',
      text: 'Deseja Remover esse item?!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        try {
          await dispatch(removerTipoLicenca(id));
          await Swal.fire('Sucesso',
            'Removido com sucesso!',
            'success');
          dispatch(loadTipoLicencas());
        } catch (error) {
          await Swal.fire('Erro',
            'Erro ao Remover',
            'error');
        }
      }
    })
  };
  return (
    <>
      {modal && (
        <Switch>
          <Route
            children={<Create />}
            path="/tipo_licenca/create"
          />
          <Route
            children={<Show />}
            exact
            path="/tipo_licenca/:tipo_licenca_id/detalhe"
          />
          <Route
            children={<Edit />}
            exact
            path="/tipo_licenca/:tipo_licenca_id/edit"
          />
        </Switch>
      )}
      <BasicContent breadcrumb={breadcrumb}>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Lista</h2>

            <div className="card-tools">
              <Link
                className="btn btn-success"
                to={{
                  pathname: '/tipo_licenca/create',
                  state: { modal: true },
                }}
              >
                <i className="fa fa-plus" /> Novo
              </Link>
            </div>

          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-sm table-bordered table-hover table-striped">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Nome</th>
                    <th>Sigla</th>
                    <th>Qtd. de técnicos para analise</th>
                    <th className="text-right">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {tipo_licencas.data &&
                    tipo_licencas.data.map((tipo_licenca, index) => (
                      <tr key={index}>
                        <td>{tipo_licenca.id}</td>
                        <td>{tipo_licenca.nome}</td>
                        <td>{tipo_licenca.sigla}</td>
                        <td>{tipo_licenca.qtd_tec_para_analise}</td>
                        <td>
                          <div className="btn-group float-right">
                            <Link
                              className="btn btn-info"
                              to={{
                                pathname: `/tipo_licenca/${tipo_licenca.id}/detalhe`,
                                state: { modal: true },
                              }}
                            >
                              <FaEye /> Detalhes
                            </Link>
                            <Link
                              className="btn btn-default"
                              to={{
                                pathname: `/tipo_licenca/${tipo_licenca.id}/edit`,
                                state: { modal: true },
                              }}
                            >
                              <FaEdit /> Editar
                            </Link>
                            <button
                              className="btn btn-danger"
                              onClick={handleRemove}
                              value={tipo_licenca.id}
                            >
                              <FaTrash /> Remover
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {!tipo_licencas.data && <Loading />}
            </div>
          </div>
          <div className="card-footer clearfix">
            <Paginate
              load={loadTipoLicencas}
              meta={meta}
            />
          </div>
        </div>
      </BasicContent>
    </>
  );
};

export default List;
