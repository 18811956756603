import React from 'react';
import  Breadcrumb from '../Breadcrumb';
const Content = ({ title, breadcrumbs, children }) => {
  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{title}</h1>
            </div>
            <div className="col-sm-6">
              <Breadcrumb breadcrumbs={breadcrumbs} />
            </div>
          </div>
        </div>
      </section>
      <section className="content">{children}</section>
    </>
  );
};
export default Content;
