import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as Municipio from '~/services/Municipio';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadMunicipiosWorker({estado_id, params}) {
  try {
    const response = yield call(Municipio.load, estado_id, params);
    yield put(actions.getMunicipios(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* showMunicipioWorker({id}) {
  try {
    const response = yield call(Municipio.show, id);
    yield put(actions.getMunicipio(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updateMunicipioWorker({ municipio, id}) {
  try {
    const response = yield call(Municipio.update, id, municipio);
    yield put(actions.getMunicipio(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeMunicipioWorker({municipio}) {
  try {
    const response = yield call(Municipio.store, municipio);
    console.log(response);
    yield put(actions.getMunicipio(response));
    yield put(actions.getErros({save:true}));
    yield put(getErros({save:true}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* removerMunicipioWorker({id}) {
  try {
    const response = yield call(Municipio.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));
    console.log(response);
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherMunicipio() {
  yield takeLatest(actionType.LOAD_MUNICIPIOS, loadMunicipiosWorker);
  yield takeLatest(actionType.STORE_MUNICIPIO, storeMunicipioWorker);
  yield takeLatest(actionType.SHOW_MUNICIPIO, showMunicipioWorker);
  yield takeLatest(actionType.UPDATE_MUNICIPIO, updateMunicipioWorker);
  yield takeLatest(actionType.REMOVER_MUNICIPIO, removerMunicipioWorker);
}

export default function* saga() {
  yield all([watcherMunicipio()]);
}
