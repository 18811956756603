export function formatForMonthYear(value = 0) {
    let data = new Date(value);
         switch (data.getMonth()) {
            case 0:
                return `Janeiro/${data.getFullYear()}`;
            case 1:
                return `Fevereiro/${data.getFullYear()}`;
            case 2:
                return `Março/${data.getFullYear()}`;
            case 3:
                return `Abril/${data.getFullYear()}`;
            case 4:
                return `Maio/${data.getFullYear()}`;
            case 5:
                return `Junho/${data.getFullYear()}`;
            case 6:
                return `Julho/${data.getFullYear()}`;
            case 7:
                return `Agosto/${data.getFullYear()}`;
            case 8:
                return `Setembro/${data.getFullYear()}`;
            case 9:
                return `Outubro/${data.getFullYear()}`;
            case 10:
                return `Novembro/${data.getFullYear()}`;
            case 11:
                return `Dezembro/${data.getFullYear()}`;
            default:
        }
  }