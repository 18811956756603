import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineSchedule } from 'react-icons/ai';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { NovoAgendamento } from './components';
import { loadVistorias } from '~/store/modules/vistoria/action';
import formatDataHora, { formatData } from '~/utils/formatDataHora';
import { removerVistoria } from '../../../../store/modules/vistoria/action';

const AgendaVisionariaTecAmb = ({ processo_id, setAgendaVistoriaIsOk, onlyView }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { vistorias } = useSelector(({ VistoriaReducer }) => VistoriaReducer);
  const { relatorio_vistoria } = useSelector(({ RelatorioVistoriaReducer }) => RelatorioVistoriaReducer);
  let [show, setShow] = useState(false);


  useEffect(() => {
    if (processo_id) {
      dispatch(loadVistorias({
        where: `processo_id:${processo_id}`,
        with: 'motorista,veiculo'
      }));
    }
  }, [processo_id, dispatch, show]);

  const handleRemove = async (id) => {
    Swal.fire({
      title: 'Remover?',
      text: 'Deseja Remover esse item?!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        try {
          await dispatch(removerVistoria(id));
          await Swal.fire('Sucesso',
            'Removido com sucesso!',
            'success');
          await history.go();
        } catch (error) {
          await Swal.fire('Erro',
            'Erro ao Remover',
            'error');
        }
      }
    })
  };

  useEffect(() => {
    if(vistorias.data.length > 0){
      setAgendaVistoriaIsOk(true);
    }
  },[vistorias, setAgendaVistoriaIsOk]);


  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Agendar Vistoria Técnica Ambiental</h3>
        </div>
        <div className="card-body">
          <button
            className={`btn ${vistorias?.data?.length > 0 ? 'btn-primary' : 'btn-success'}  btn-block`}
            onClick={() => setShow(true)}
          >
            <AiOutlineSchedule /> Agendar Vistoria Técnica Ambiental
          </button>
        </div>
      </div>

      <Modal
        animation
        aria-labelledby="example-custom-modal-styling-title"
        dialogClassName="modal-xl"
        onHide={() => setShow(false)}
        show={show}
      >
        <Modal.Header closeButton>
          <Modal.Title>AGENDAMENTO DE VISTORIA TÉCNICA AMBIENTAL E MONITORAMENTO</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!onlyView && (
            <div className="text-center">
              <NovoAgendamento processo_id={processo_id}/>
            </div>
          )}
          {vistorias.data.length > 0 && (
            <Tabs
              id="noanim-tab-example"
              transition={false}
            >
              {vistorias.data.map((vistoria) => (
                <Tab
                  eventKey={formatData(vistoria.data_saida)}
                  key={vistoria.id}
                  title={formatData(vistoria.data_saida)}
                >
                  <table className="table table-striped table-hover table-bordered">
                    <thead>
                      <tr>
                        <th colSpan={2}> Dados do Agendamento </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Veículo</th>
                        <td>
                          <strong>Modelo: </strong>{ vistoria && vistoria.veiculo && vistoria.veiculo.modelo } {' '}
                          <strong>Ano: </strong>{ vistoria && vistoria.veiculo && vistoria.veiculo.ano_fabricacao }/{ vistoria && vistoria.veiculo && vistoria.veiculo.ano_modelo }{' '}
                          <strong>Placa: </strong>{ vistoria && vistoria.veiculo && vistoria.veiculo.placa }{' '}
                        </td>
                      </tr>
                      <tr>
                        <th>Motorista</th>
                        <td>
                          <strong>Nome: </strong>{ vistoria && vistoria.motorista && vistoria.motorista.nome } {' '}
                          <strong>CPF: </strong>{ vistoria && vistoria.motorista && vistoria.motorista.cpf }
                        </td>
                      </tr>
                      <tr>
                        <th>Data e Hora de saída</th>
                        <td>
                          { vistoria && formatDataHora(vistoria.data_saida)}
                        </td>
                      </tr>
                      <tr>
                        <th>Data e Hora de chegada</th>
                        <td>
                          { vistoria && vistoria.data_chegada !== null ? formatDataHora(vistoria.data_chegada) : 'Data/Hora não fornecida.' }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {!onlyView && (
                    <div>
                      {relatorio_vistoria && relatorio_vistoria.vistoria_id === vistoria.id ? null : (
                        <button
                          className="btn btn-danger"
                          onClick={() => handleRemove(vistoria.id)}
                          type="button"
                        >
                          <i className="fa fa-trash" /> Remover
                        </button>
                      )}
                    </div>
                  )}
                </Tab>
              ))}
            </Tabs>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <button
            className="btn btn-default"
            onClick={() => setShow(false)}
            type="button"
          >
            <i className="fa fa-window-close" /> Fechar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default AgendaVisionariaTecAmb;
