import * as actions from './actionType';

export const TipoLicencaReducer = (state = {select_tipo_licencas: [], tipo_licencas: [], status_response:null, tipo_licenca: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.TIPO_LICENCAS_DATA:
      return {
        ...state,
        tipo_licencas: action.tipo_licencas.data,
        links: action.tipo_licencas.data.links,
        meta: action.tipo_licencas.data.meta,
      };
    case actions.SELECT_TIPO_LICENCAS:
      return {
        ...state,
        select_tipo_licencas: action.tipo_licencas.data
      };
    case actions.GET_TIPO_LICENCA:
      return {
        ...state,
        tipo_licenca: action.tipo_licenca.data.data,
        status_response: action.tipo_licenca.status,
      };
    case actions.TIPO_LICENCA_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    case actions.CHANGE_STATUS_CODE:
      return {
        ...state,
        status_response: action.status,
      };
    default:
      return state;
  }
}
