import React from 'react';
import { useBarcode } from 'next-barcode';

const Barcode = ({value, ...options}) => {
    const { inputRef } = useBarcode({
        value: value,
        ...options
      });
      return(
        <svg ref={inputRef} />
      )
}
export default Barcode;