import React from 'react';
import Profile from '../Profile';
import SideBarNav from '../SideBarNav';
import imgLogo from '~/assets/images/logo1.jpeg';


export default function Sidebar({ name, photo }) {
  const pages = JSON.parse(localStorage.getItem('@menus'));

  return (
    <aside className="main-sidebar elevation-4 sidebar-light-success">
      <a
        className="brand-link navbar-success"
        href="/#"
      >
        <img
          alt="Siblam Logo"
          className="brand-image img-circle elevation-3"
          src={imgLogo}
          style={{opacity: .8}}
        />
        <span className="brand-text font-weight-light text-white">
          <strong>SIBLAM</strong>
        </span>
      </a>
      <div className="sidebar">
        <Profile
          name={name}
          photo={photo}
        />
        <SideBarNav pages={pages} />
      </div>
    </aside>
  );
}
