import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import { storeRespostaQuestao } from '~/store/modules/resposta_questao/action';
import { loadQuestaoAnalises } from '~/store/modules/questao_analise/action';
import * as Yup from 'yup';

const FormResposta = ({ questao_id, analise_id, params_load}) => {
  const dispatch = useDispatch();

  const [salvo, setSalvo] = useState(false);
  const [dataForm, setDataForm] = useState({
    valor: undefined,
    questao_analise_id: questao_id,
    analise_processo_id: analise_id,

  });
  const [errorsValidate, setErrorsValidate] = useState([]);

  const schema = Yup.object().shape({
    valor: Yup.number().min(0).max(10).required('Valor requerido'),
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!schema.isValidSync(dataForm)) {
      let yup_errors = {};
      schema.validate(dataForm, { abortEarly: false }).catch((err) => {
        for (let error of err.inner) {
          const { path, message } = error;
          Object.assign(yup_errors, { [path]: message });
        }
        setErrorsValidate(yup_errors);
      });
    } else {
      await dispatch(storeRespostaQuestao(dataForm));
      setSalvo(true);
      if (params_load && params_load.with) {
        await dispatch(loadQuestaoAnalises(params_load));
      }
    }
  };

  useEffect(() => {
    dispatch(loadQuestaoAnalises(params_load));
  },[dispatch, salvo, params_load]);

  return (
    <Form
      autoComplete="off"
      onSubmit={handleSubmit}
      style={{ width: '100%' }}
    >
      {!salvo && (
        <div className="form-group text-center">
          <label className="label">Valor</label>
          <Form.Control
            //autoFocus
            className="text-center"

            isInvalid={!!errorsValidate.valor}
            max="10"
            min="0"
            name="valor"
            onChange={(e) => setDataForm({ ...dataForm, valor: e.target.value })}
            required
            step="0.1"
            type="number"
          />
          <Form.Control.Feedback type="invalid">
            {errorsValidate.valor}
          </Form.Control.Feedback>
        </div>)}
      {!salvo && (
        <div className="form-group text-center">
          <button className="btn btn-success">Salvar</button>
        </div>
      )}
      {salvo && <>{dataForm.valor}</>}
    </Form>
  );
};

export default FormResposta;
