import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import styles from './styles';
import { Header, Footer, Table, Row, Col } from '~/components/PDF';
import formatPhone from '~/utils/formatPhone';

export default function RCCAPDF({
    period,
    entityName,
    organName,
    logoSiblam,
    logoEntity,
    consultants,
    logoCounty,
}) {
    return (
        <Document title="Relatório de Cadastro de Consultores Ambientais">
            <Page size="A4" style={styles.page} orientation="landscape" wrap>
                <Header
                    logoEntity={logoEntity}
                    entityName={entityName}
                    organName={organName}
                    LogoCounty={logoCounty}
                    styles={styles}
                />

                <View style={{ marginTop: '-10px' }}>
                    <Text style={[styles.title, { textAlign: 'center' }]}>
                        Relatório de Cadastro de Consultores Ambientais
                    </Text>
                </View>

                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <View
                        style={[
                            styles.section,
                            { alignItems: 'flex-end', flexGrow: 1 },
                        ]}
                    >
                        <View
                            style={{
                                borderTop: '1px solid black',
                                borderLeft: '1px solid black',
                                flexDirection: 'row',
                            }}
                        >
                            <Text style={[styles.cell, styles.semibold]}>
                                Emitido Em
                            </Text>
                            <Text style={[styles.cell, styles.th]}>
                                {new Date().toLocaleString('pt-BR', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                })}
                            </Text>
                        </View>
                    </View>
                </View>

                <View style={styles.section}>
                    <Table>
                        <Row header>
                            <Col
                                style={[
                                    styles.semibold,
                                    { width: '5%', fontSize: '10px' },
                                ]}
                            >
                                N°
                            </Col>
                            <Col
                                style={[
                                    styles.semibold,
                                    { width: '18%', fontSize: '10px' },
                                ]}
                            >
                                Nome
                            </Col>
                            <Col
                                style={[
                                    styles.semibold,
                                    { width: '18%', fontSize: '10px' },
                                ]}
                            >
                                Formação
                            </Col>
                            <Col
                                style={[
                                    styles.semibold,
                                    { width: '12%', fontSize: '10px' },
                                ]}
                            >
                                Fone
                            </Col>
                            <Col
                                style={[
                                    styles.semibold,
                                    { width: '22%', fontSize: '10px' },
                                ]}
                            >
                                E-mail
                            </Col>
                            <Col
                                style={[
                                    styles.semibold,
                                    { width: '25%', fontSize: '10px' },
                                ]}
                            >
                                Rede Social
                            </Col>
                        </Row>
                        {!consultants.length && (
                            <Row>
                                <Col>Nenhum consultor encontrado</Col>
                            </Row>
                        )}

                        {consultants.map((consultant, index) => (
                            <Row key={`table-one-key-${index + 1}`}>
                                <Col style={{ width: '5%', fontSize: '10px' }}>
                                    {(index + 1).toString().padStart(2, '0')}
                                </Col>
                                <Col style={{ width: '18%', fontSize: '10px' }}>
                                    {consultant.nome}
                                </Col>
                                <Col style={{ width: '18%', fontSize: '10px' }}>
                                    {consultant.doutorado ||
                                        consultant.mestrado ||
                                        consultant.especializacao ||
                                        consultant.formacao2 ||
                                        consultant.formacao ||
                                        'Não Informado'}
                                </Col>
                                <Col style={{ width: '12%', fontSize: '10px' }}>
                                    {formatPhone(consultant.telefone || '')}
                                </Col>
                                <Col style={{ width: '22%', fontSize: '10px' }}>
                                    {consultant.email}
                                </Col>
                                <Col style={{ width: '25%', fontSize: '10px' }}>
                                    {consultant.linkdin ||
                                        consultant.facebook ||
                                        consultant.twitter ||
                                        consultant.youtube ||
                                        'Não Informado'}
                                </Col>
                            </Row>
                        ))}
                    </Table>
                </View>

                <Footer logoSiblam={logoSiblam} styles={styles} />
            </Page>
        </Document>
    );
}
