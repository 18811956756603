import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';

import { BtnClear } from './components';

const FormSearch = () => {

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let [search, setSearch] = useState(
    urlParams.has('search') ? `${urlParams.get('search')}` : ''
  );
  const handleBusca = event => {
    setSearch(event.target.value);
  };

  return (
    <form className="form-inline ml-3">
      <div className="input-group input-group-sm">
        <input
          aria-label="Buscar"
          className="form-control form-control-navbar"
          name="search"
          onChange={handleBusca}
          placeholder="Buscar"
          type="search"
          value={search}
        />
        <div className="input-group-append">
          <button className="btn btn-navbar" type="submit">
            <FaSearch />
          </button>
          <BtnClear search={search !== ''} />
        </div>
      </div>
    </form>
  );
};

export default FormSearch;
