import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Loading } from '~/components';
import formatForMask from '~/utils/formatForMask';
import { FaPrint } from 'react-icons/fa';
import { BlobProvider } from '@react-pdf/renderer';
import CertidaoPDF from '../../../../Process/Detalhe/components/Certidao/PDF';

const Certidao = ({ processo }) => {
  let [show, setShow] = useState(false);

  return (
    <>
      {processo && processo.pessoa ? (
        <>
          <div className="col-md-4">
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h3 className="card-title w-100">
                  CERTIDÃO DE PROCESSO AMBIENTAL - CPA
                </h3>
                {processo?.pessoa && (
                  // <a
                  //   className="btn btn-info d-flex flex-row align-items-center"
                  //   href={`/processos/certidao-processo/${processo?.id}/imprimir`}
                  //   rel="noopener noreferrer"
                  //   target="_blank"
                  // >
                  //   <FaPrint style={{ marginRight: 5 }} /> Imprimir
                  // </a>
                  <BlobProvider document={
                        <CertidaoPDF
                            process={processo}
                        />
                    }>
                        {({ url }) => (
                            <a
                                href={url}
                                target="_blank"
                                className="btn btn-info d-flex flex-row align-items-center"
                                rel="noopener noreferrer"
                            >
                                <FaPrint style={{ marginRight: 5 }} /> Imprimir
                            </a>
                        )}
                    </BlobProvider>
                )}
              </div>
              <div className="card-body">
                <button
                  className="btn btn-primary btn-block"
                  onClick={() => setShow(true)}
                >
                  <i aria-hidden="true" className="fa fa-list" /> CERTIDÃO DE
                  PROCESSO AMBIENTAL - CPA
                </button>
              </div>
            </div>
          </div>

          <Modal
            animation
            aria-labelledby="example-custom-modal-styling-title"
            dialogClassName="modal-xl"
            onHide={() => setShow(false)}
            show={show}
          >
            <Modal.Header closeButton>
              <Modal.Title>CERTIDÃO DE PROCESSO AMBIENTAL - CPA</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="table-responsive">
                <table className="table table-bordered table-striped">
                  <tbody>
                    <tr>
                      <th>Processo</th>
                      <td>{processo.protocolo}</td>
                    </tr>
                    <tr>
                      <th>Empreendimento</th>
                      <td>
                        {' '}
                        {`${processo.pessoa.nome} - ${
                          processo.pessoa.cnpj
                            ? formatForMask(
                                processo.pessoa.cnpj,
                                '##.###.###/####-##'
                              )
                            : ''
                        } ${
                          processo.pessoa.cpf
                            ? formatForMask(
                                processo.pessoa.cpf,
                                '###.###.###-##'
                              )
                            : ''
                        }`}
                      </td>
                    </tr>
                    <tr>
                      <th>Porte Empreendimento</th>
                      <td>
                        {processo.porte_empreendimento &&
                          processo.porte_empreendimento.nome}
                      </td>
                    </tr>
                    <tr>
                      <th>Local</th>
                      <td>
                        {' '}
                        {processo.municipio.nome} - {processo.estado.uf}
                      </td>
                    </tr>
                    <tr>
                      <th>Atividade</th>
                      <td> {processo.atividade && processo.atividade.nome}</td>
                    </tr>
                    <tr>
                      <th>Tipo de licença</th>
                      <td> {processo.tipo_licenca.nome}</td>
                    </tr>
                    <tr>
                      <th>Técnico Consultor</th>
                      <td>
                        {processo.pessoa_responsavel_tecnico &&
                          processo.pessoa_responsavel_tecnico
                            .responsavel_tecnico &&
                          processo.pessoa_responsavel_tecnico
                            .responsavel_tecnico.pessoa_tecnico_profissional &&
                          processo.pessoa_responsavel_tecnico
                            .responsavel_tecnico.pessoa_tecnico_profissional
                            .nome}{' '}
                        -{' '}
                        {processo.pessoa_responsavel_tecnico &&
                        processo.pessoa_responsavel_tecnico
                          .responsavel_tecnico &&
                        processo.pessoa_responsavel_tecnico.responsavel_tecnico
                          .pessoa_tecnico_profissional &&
                        processo.pessoa_responsavel_tecnico.responsavel_tecnico
                          .pessoa_tecnico_profissional.cnpj
                          ? formatForMask(
                              processo.pessoa_responsavel_tecnico
                                .responsavel_tecnico.pessoa_tecnico_profissional
                                .cnpj,
                              '##.###.###/####-##'
                            )
                          : '' -
                            processo.pessoa_responsavel_tecnico
                              .responsavel_tecnico.pessoa_tecnico_profissional
                              .cpf
                          ? formatForMask(
                              processo.pessoa_responsavel_tecnico
                                .responsavel_tecnico.pessoa_tecnico_profissional
                                .cpf,
                              '###.###.###-##'
                            )
                          : ''}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table-responsive">
                <table className="table table-bordered table-striped table-hover">
                  <tbody>
                    <tr />
                    <tr>
                      <th className="text-center table-success">
                        O documento CPA, tem validade de certificação de
                        protocolo de processo ambiental.
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-default"
                onClick={() => setShow(false)}
                type="button"
              >
                <i className="fa fa-window-close" /> Fechar
              </button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};
export default Certidao;
