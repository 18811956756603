import * as actions from './actionType';

export const loadMenus = () => ({
  type: actions.LOAD_MENU,
});

export const loadSubMenus = (menu_id) => ({
  type: actions.LOAD_SUBMENU,
  menu_id
});

export const loadMenuTree = () => ({
  type: actions.LOAD_MENU_TREE,
});

export const getMenuTree = menu_tree => ({
  type: actions.MENU_TREE_DATA,
  menu_tree
});

export const getMenus = menus => ({
  type: actions.MENU_DATA,
  menus,
});

export const getSubMenus = sub_menus => ({
  type: actions.SUBMENU_DATA,
  sub_menus,
});
export const storeMenu = menu => ({
  type: actions.STORE_MENU,
  menu,

});
export const getMenu = menu => ({
  type: actions.GET_MENU,
  menu,
});

export const removerMenu = (id) => ({
  type: actions.REMOVER_MENU,
  id
});

export const showMenu = id => ({
  type: actions.SHOW_MENU,
  id,
});

export const updateMenu = (menu, id) => ({
  type: actions.UPDATE_MENU,
  menu,
  id
});

export const getErros = errors => ({
  type: actions.MENU_ERROR,
  errors,
});
