import * as actions from './actionType';

export const loadEstados = (params) => ({
  type: actions.LOAD_ESTADOS,
  params
});
export const getEstados = estados => ({
  type: actions.ESTADOS_DATA,
  estados,
});
export const storeEstado = estado => ({
  type: actions.STORE_ESTADO,
  estado,

});
export const getEstado = estado => ({
  type: actions.GET_ESTADO,
  estado,
});

export const removerEstado = (id) => ({
  type: actions.REMOVER_ESTADO,
  id
});

export const showEstado = id => ({
  type: actions.SHOW_ESTADO,
  id,
});

export const updateEstado = (estado, id) => ({
  type: actions.UPDATE_ESTADO,
  estado,
  id
});

export const getErros = errors => ({
  type: actions.ESTADO_ERROR,
  errors,
});
