import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as Mensagem from '~/services/Mensagem';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadMensagemsWorker({ processo_id, params }) {
  try {
    const response = yield call(Mensagem.load, processo_id, params);
    yield put(actions.getMensagems(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* storeMensagemWorker({processo_id, mensagem}) {
  try {
    const response = yield call(Mensagem.store, processo_id,  mensagem);
    yield put(actions.getMensagems(response));
    yield put(actions.getErros({save:true}));
    yield put(getErros({save:true}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}


function* responderMensagemWorker({mensagem_id, resposta}) {
  try {
    const response = yield call(Mensagem.responder, mensagem_id,  resposta);
    yield put(actions.getMensagems(response));
    yield put(actions.getErros({save:true}));
    yield put(getErros({save:true}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}


function* marcarComoLidoWorker({mensagem_id}) {
  try {
    yield call(Mensagem.marcar_como_lido, mensagem_id);
    yield put(actions.getErros({updated:true}));
    yield put(getErros({updated:true}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}


function* loadMensagensNLidasWorker() {
  try {
    const response = yield call(Mensagem.get_nao_lidas);
    yield put(actions.getMensagensNLidas(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* watcherMensagem() {
  yield takeLatest(actionType.LOAD_MENSAGEMS, loadMensagemsWorker);
  yield takeLatest(actionType.STORE_MENSAGEM, storeMensagemWorker);
  yield takeLatest(actionType.RESPONDER_MENSAGEM, responderMensagemWorker);
  yield takeLatest(actionType.MARACAR_COMO_LIDA, marcarComoLidoWorker);
  yield takeLatest(actionType.MENSAGENS_N_LIDAS_REQUEST, loadMensagensNLidasWorker);
}

export default function* saga() {
  yield all([watcherMensagem()]);
}
