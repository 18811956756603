import React  from 'react';
import { BasicContent } from '../components';
import { Link, useParams } from 'react-router-dom';
import schema from './schema';
import EntityForm from '../Form';

const Edit = () => {
  const { entidade_id } = useParams();

  return (
    <>
      <BasicContent
        breadcrumb={{
          label: 'Editar',
          to: `/entidade/${entidade_id}/edit`,
          active: false,
        }}
      >
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Editar</h2>
            <div className="card-tools">
              <Link
                className="btn btn-default"
                to={`/entidade/${entidade_id}/detalhe`}
              >
                <i className="fa fa-arrow-circle-left" /> Voltar
              </Link>
            </div>
          </div>

          <div className="card-body">
            <EntityForm
              action="update"
              validation={schema}
            />
          </div>

          <div className="card-footer">
            <Link
              className="btn btn-default"
              to={`/entidade/${entidade_id}/detalhe`}
            >
              <i className="fa fa-arrow-circle-left" /> Voltar
            </Link>
            <button
              className="btn btn-success float-right"
              form="form_entidade"
              type="submit"
            >
              <i className="fa fa-save" /> Salvar
            </button>
          </div>
        </div>
      </BasicContent>
    </>
  );
};

export default Edit;
