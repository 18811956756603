import * as actions from './actionType';

export const ProcessoReducer = (
  state = {
    select_processos: [],
    processos: {},
    cond_especifica: [],
    cond_geral: [],
    processo: {},
    errors: {},
    url_boleto: null,
    status_code: null,
    lista_processos: [],
    lista_processos_ref: [],
    lista_processos_ccla: null,
  },
  action
) => {
  switch (action.type) {
    case actions.PROCESSOS_DATA:
      return {
        ...state,
        processos: action.processos.data,
        links: action.processos.data.links,
        meta: action.processos.data.meta,
        status_response: action.processos.status,
      };
    case actions.TRAMITES_PROCESSOS_DATA:
      return {
        ...state,
        tramites: action.tramites.data.data,
        status_response: action.tramites.status,
      };

    case actions.CONDICIONANTES_PROCESSOS_DATA:
      return {
        ...state,
        cond_geral: action.condicionantes.data.cond_geral,
        cond_especifica: action.condicionantes.data.cond_especifica,
        status_response: action.condicionantes.status,
      };

    case actions.GET_PROCESSO:
      return {
        ...state,
        processo: action.processo.data.data,
        status_response: action.processo.status,
      };
    case actions.PROCESSO_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    case actions.SET_BOLETO:
      return {
        ...state,
        url_boleto: action.url,
      };
    case actions.CHANGE_STATUS_CODE:
      return {
        ...state,
        status_code: action.status,
      };
    case actions.LISTA_PROCESSOS_POR_USUARIOS:
      return {
        ...state,
        lista_processos: action.processos.data.data,
      };
    case actions.LIST_PROCESSOS_POR_REFERENCIA:
      return {
        ...state,
        lista_processos_ref: action.processos.data,
      };
    case actions.LISTA_PROCESSOS_CCLA:
      return {
        ...state,
        lista_processos_ccla: action.processos
      }
    default:
      return state;
  }
};
