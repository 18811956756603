/* eslint-disable indent */
import React from 'react';

import formatForMask from '~/utils/formatForMask';
import { formatData } from '~/utils/formatDataHora';
import { TableCustom, TdCustom, ThCustom } from './styles';

function InfoTable({ processo }) {
  return (
    <TableCustom>
    <tbody>
      <tr>
        <ThCustom>Processo</ThCustom>
        <TdCustom>{processo.protocolo}</TdCustom>
      </tr>
      <tr>
        <ThCustom>Empreendimento</ThCustom>
        <TdCustom>
          {' '}
          {`${processo?.pessoa?.nome} - ${
            processo?.pessoa?.cnpj
              ? formatForMask(processo?.pessoa?.cnpj, '##.###.###/####-##')
              : ''
          } ${
            processo?.pessoa?.cpf
              ? formatForMask(processo?.pessoa?.cpf, '###.###.###-##')
              : ''
          }`}
        </TdCustom>
      </tr>
      <tr>
        <ThCustom>Porte Empreendimento</ThCustom>
        <TdCustom>
          {processo.porte_empreendimento && processo.porte_empreendimento.nome}
        </TdCustom>
      </tr>
      <tr>
        <ThCustom>Local</ThCustom>
        <TdCustom>
          {' '}
          {processo?.municipio?.nome} - {processo?.estado?.uf}
        </TdCustom>
      </tr>
      <tr>
        <ThCustom>Atividade</ThCustom>
        <TdCustom> {processo?.atividade && processo.atividade.nome}</TdCustom>
      </tr>
      <tr>
        <ThCustom>Endereço da atividade</ThCustom>
        <TdCustom>
          {processo.logradouro} - {processo.bairro} -{' '}
          {processo.numero_endereco
            ? processo.numero_endereco
            : 'Nº não informado'}{' '}
          - {processo.cep} -{' '}
          {processo.complemento ? processo.complemento : 'Sem complemento.'}
        </TdCustom>
      </tr>
      <tr>
        <ThCustom>Tipo de licença</ThCustom>
        <TdCustom> {processo?.tipo_licenca?.nome}</TdCustom>
      </tr>
      <tr>
        <ThCustom>Técnico Consultor</ThCustom>
        <TdCustom>
          {processo?.pessoa_responsavel_tecnico &&
            processo?.pessoa_responsavel_tecnico?.responsavel_tecnico &&
            processo?.pessoa_responsavel_tecnico?.responsavel_tecnico
              ?.pessoa_tecnico_profissional &&
            processo.pessoa_responsavel_tecnico?.responsavel_tecnico
              ?.pessoa_tecnico_profissional.nome}{' '}
          -{' '}
          {processo?.pessoa_responsavel_tecnico &&
          processo?.pessoa_responsavel_tecnico?.responsavel_tecnico &&
          processo?.pessoa_responsavel_tecnico?.responsavel_tecnico
            ?.pessoa_tecnico_profissional &&
          processo?.pessoa_responsavel_tecnico?.responsavel_tecnico
            ?.pessoa_tecnico_profissional?.cnpj
            ? formatForMask(
                processo?.pessoa_responsavel_tecnico?.responsavel_tecnico
                  ?.pessoa_tecnico_profissional?.cnpj,
                '##.###.###/####-##'
              )
            : '' -
              processo?.pessoa_responsavel_tecnico?.responsavel_tecnico
                ?.pessoa_tecnico_profissional?.cpf
            ? formatForMask(
                processo?.pessoa_responsavel_tecnico?.responsavel_tecnico
                  ?.pessoa_tecnico_profissional?.cpf,
                '###.###.###-##'
              )
            : ''}
        </TdCustom>
      </tr>
      <tr>
        <ThCustom>Endereço da Empresa</ThCustom>
        <TdCustom>
          {processo?.pessoa?.logradouro},{processo?.pessoa?.bairro} -
          {processo?.pessoa?.cep} -{processo?.pessoa?.cidade}
        </TdCustom>
      </tr>
      {processo?.tramites?.some(item => item.tipo === "distribuicao") && (
        <tr>
          <ThCustom>Data de Protocolo</ThCustom>
          <TdCustom>
            {processo?.tramites
              .filter(item => item.tipo === "distribuicao")
              .map(item => formatData(item.created_at))}
          </TdCustom>
        </tr>
      )}
      </tbody>
    </TableCustom>
  );
}

export default InfoTable;
