import { LOGIN_ERROR, LOGIN_AUTH, CHANGE_STATUS_CODE } from './actionType';

const initialState = {
  user_error: {},
  user_auth: {},
  status_code: 0
}

export const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_ERROR:
      return {
        ...state,
        user_error: action.error
      }
    case LOGIN_AUTH:
      return {
        ...state,
        user_auth: action.auth
      }
    case CHANGE_STATUS_CODE:
      return {
        ...state,
        status_code: action.status_code
      }
    default:
      return state;
  }
}
