import React, { useEffect } from 'react';
import { Link, Switch, Route, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { BasicContent } from '../components';
import { Loading, Paginate } from '~/components';
import { loadMenus, removerMenu } from '~/store/modules/menu/action';
import Create from '../Create';
import Edit from '../Edit';

const List = () => {
  let location = useLocation();
  let modal = location.state && location.state.modal;
  const dispatch = useDispatch();
  const { menus } = useSelector(
    ({ MenuReducer }) => MenuReducer
  );

  useEffect(() => {
    dispatch(loadMenus());
  }, [dispatch]);

  const breadcrumb = {
    label: 'Lista',
    to: '/menu',
    active: false,
  };

  const handleRemove = async (event) => {
    const id = event.target.value;
    Swal.fire({
      title: 'Remover?',
      text: 'Deseja Remover esse item?!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        try {
          await dispatch(removerMenu(id));
          await Swal.fire('Sucesso',
            'Removido com sucesso!',
            'success');
          dispatch(loadMenus());
        } catch (error) {
          await Swal.fire('Erro',
            'Erro ao Remover',
            'error');
        }
      }
    })
  };

  return (
    <>
      {modal && (
        <Switch>
          <Route
            children={<Create />}
            path="/menu/create"
          />
          <Route
            children={<Edit />}
            exact
            path="/menu/:menu_id/edit"
          />
        </Switch>
      )}
      <BasicContent breadcrumb={breadcrumb}>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Lista</h2>

            <div className="card-tools">
              <Link
                className="btn btn-success"
                to={{
                  pathname: '/menu/create',
                  state: { modal: true },
                }}
              >
                <i className="fa fa-plus" /> Novo
              </Link>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-sm table-bordered table-hover table-striped">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Nome</th>
                    <th>Ícone</th>
                    <th>Path name</th>
                    <th className="text-right">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {menus.data && menus.data.map((menu, index) => (
                    <tr key={index}>
                      <td>{menu.id}</td>
                      <td>{menu.nome}</td>
                      <td className="text-center">
                        <i className={`fa fa-${menu.icon}`} />
                      </td>
                      <td>{menu.path_name}</td>
                      <td>
                        <div className="btn-group float-right">
                          <Link
                            className="btn btn-info"
                            to={`/menu/${menu.id}/detalhe`}
                          >
                            <FaEye /> Detalhes
                          </Link>
                          <Link
                            className="btn btn-default"
                            to={{
                              pathname: `/menu/${menu.id}/edit`,
                              state: { modal: true },
                            }}
                          >
                            <FaEdit /> Editar
                          </Link>
                          <button
                            className="btn btn-danger"
                            onClick={handleRemove}
                            value={menu.id}
                          >
                            <FaTrash /> Remover
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {!menus.data && <Loading />}
            </div>
          </div>
          <div className="card-footer clearfix">
            <Paginate
              load={loadMenus}
              meta={menus.meta}
            />
          </div>
        </div>
      </BasicContent>
    </>
  );
};

export default List;
