import { all, put, takeEvery } from 'redux-saga/effects';
import { api } from '~/services/api';
import { CNPJ_RESPONSE } from './actionType';
import { cadastro_pessoa_juridica_error } from './action';

function* cnpjWorker({ pessoa_juridica }) {
  try {
    const response = yield api.post('/pessoa_juridica', pessoa_juridica);
    console.log(response);
  } catch (err) {
    let server_errors = {};
    const response_errors = err.response.data.errors;
    for (let name of Object.keys(response_errors)) {
      Object.assign(server_errors, { [name]: response_errors[name][0] });
    }
    yield put(cadastro_pessoa_juridica_error(server_errors));
  }
}

function* watcherCnpJ() {
  yield takeEvery(CNPJ_RESPONSE, cnpjWorker);
}

export default function* saga() {
  yield all([watcherCnpJ()]);
}
