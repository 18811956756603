import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa';
import { Link, useParams, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Loading } from '~/components';
import { showProcesso, enviarParaApia } from '~/store/modules/processo/action';
import { loadListaDocumentos } from '~/store/modules/lista_documento/action';
import { loadDocumentoProcessos } from '~/store/modules/documento_processo/action';
import formatForMask from '~/utils/formatForMask';
import { CheckList } from './components';
import { ExtratoPublicacaoAmbiental } from '../../../../Detalhe/components';

const Passo4 = () => {
  let { processo_id } = useParams();
  let history = useHistory();
  const dispatch = useDispatch();
  const [processoShow, setProcessoShow] = useState();
  const [btnEnviar, setBtnEnviar] = useState(false);
  const [lista, setLista] = useState([]);
  const { processo } = useSelector(({ ProcessoReducer }) => ProcessoReducer);
  const { lista_documentos } = useSelector(
    ({ ListaDocumentoReducer }) => ListaDocumentoReducer
  );
  const { documento_processos } = useSelector(
    ({ DocumentoProcessoReducer }) => DocumentoProcessoReducer
  );

  useEffect(() => {
    dispatch(showProcesso(processo_id, {
      with: 'pessoa,estado,municipio,atividade,tipo_licenca,pessoa_responsavel_tecnico'
    }));


    dispatch(
      loadDocumentoProcessos({
        fields: 'id,nome,processo_id,anexo',
        where: `processo_id:${processo_id}`,
        raw_list: true,
      })
    );
  }, [processo_id, dispatch]);




  useEffect(() => {
    if (processo.id === parseInt(processo_id)) {
      setProcessoShow(processo);
    }
  }, [processo, processo_id]);


  useEffect(() => {
    if (processoShow) {
      dispatch(
        loadListaDocumentos({
          raw_list: true,
          where:`tipo_licenca_id:${processoShow?.tipo_licenca_id},doc_tecnica:false`,
          order_by: 'ordem:asc'
        })
      );
    }
  }, [processoShow, dispatch]);

  useEffect(() => {

    let checkList = [];
    if (documento_processos.data && lista_documentos.data) {

      for(let documento of lista_documentos.data) {

        let anexo = documento_processos.data.filter(
          ({ nome }) => nome.trim() === documento.nome.trim()
        );
        if (!documento.obrigatorio || anexo[0]) {
          checkList.push({ ...documento, checked: true, anexos: [...anexo] });
        } else {
          checkList.push({ ...documento, checked: false, anexos: [] });
        }
      }
    }
    setLista(checkList);
  }, [documento_processos, lista_documentos]);

  useEffect(()=>{
    if(lista[0]) {
      for (const {checked} of lista) {
        if (!checked) {
          setBtnEnviar(false)
          break;
        } else {
          setBtnEnviar(true)
        }
      }

    }
  }, [lista]);


  const handleEviarParaApia = async (event) => {
    event.preventDefault();

    Swal.fire({
      title: 'Enviar processo?',
      text:
        'Deseja enviar o processo para Análise Prévia de Impacto Ambiental?!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.value) {
        try {
          await dispatch(enviarParaApia(processo_id));
          await Swal.fire(
            'Sucesso',
            'Processo enviado para analise com sucesso!',
            'success'
          );
          await history.push('/processos');
        } catch (error) {
          //console.log(error);
          Swal.fire('Error', 'Error ao Receber os anexos ', 'error');
        }
      }
    });
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">
          {' '}
          Processo (
          {processoShow && processoShow.protocolo && processoShow.protocolo}) -
          Passo 4 - Anexe a documentação{' '}
        </h3>
        <div className="card-tools">
          <Link
            className="btn btn-light"
            to="/processos"
          >
            <FaArrowAltCircleLeft /> Cancelar
          </Link>
        </div>
      </div>
      <div className="card-body table-responsive">
        {processoShow &&
        processoShow.pessoa &&
        processoShow.atividade &&
        processoShow.id === parseInt(processo_id) ? (
            <>
              <table className="table table-striped table-hover table-sm">
                <tbody>
                  <tr>
                    <th>Empreendimento</th>
                    <td>
                      {' '}
                      {`${processoShow.pessoa.nome} - ${
                        processoShow.pessoa.cnpj
                          ? formatForMask(
                            processoShow.pessoa.cnpj,
                            '##.###.###/####-##'
                          )
                          : ''
                      } ${
                        processoShow.pessoa.cpf
                          ? formatForMask(
                            processoShow.pessoa.cpf,
                            '###.###.###-##'
                          )
                          : ''
                      }`}
                    </td>
                  </tr>
                  <tr>
                    <th>Local</th>
                    <td>
                      {' '}
                      {processoShow.municipio.nome} - {processoShow.estado.uf}
                    </td>
                  </tr>
                  <tr>
                    <th>Atividade</th>
                    <td> {processoShow.atividade.nome}</td>
                  </tr>
                  <tr>
                    <th>Tipo de Licença</th>
                    <td> {processoShow.tipo_licenca.nome}</td>
                  </tr>
                  <tr>
                    <th>Técnico Consultor</th>
                    <td>
                      {processoShow.pessoa_responsavel_tecnico &&
                      processoShow.pessoa_responsavel_tecnico
                        .responsavel_tecnico &&
                      processoShow.pessoa_responsavel_tecnico
                        .responsavel_tecnico.pessoa_tecnico_profissional &&
                      processoShow.pessoa_responsavel_tecnico
                        .responsavel_tecnico.pessoa_tecnico_profissional
                        .nome}{' '}
                    -

                      {' '} {processoShow.pessoa_responsavel_tecnico &&
                      processoShow.pessoa_responsavel_tecnico
                        .responsavel_tecnico &&
                      processoShow.pessoa_responsavel_tecnico
                        .responsavel_tecnico.pessoa_tecnico_profissional &&
                      processoShow.pessoa_responsavel_tecnico
                        .responsavel_tecnico.pessoa_tecnico_profissional.cnpj ? formatForMask(processoShow.pessoa_responsavel_tecnico
                          .responsavel_tecnico.pessoa_tecnico_profissional.cnpj, '##.###.###/####-##') : '' -
                            processoShow.pessoa_responsavel_tecnico
                              .responsavel_tecnico.pessoa_tecnico_profissional.cpf ? formatForMask( processoShow.pessoa_responsavel_tecnico
                            .responsavel_tecnico.pessoa_tecnico_profissional.cpf, '###.###.###-##') : ''
                      }
                    </td>
                  </tr>
                </tbody>
              </table>

              <hr />
                <div className="col-md-4 mx-auto text-center">
                    <ExtratoPublicacaoAmbiental processo_id={processo_id} type="REQUERIMENTO"/>
                </div>
              <div className="card">
                <div className="card-header">ANEXAR DOCUMENTOS</div>
                <div className="card-body">
                  <CheckList
                    lista={lista}
                    processo_id={processo_id}
                  />
                </div>
                <div className="card-footer">
                  <div className="form-group">
                    <Link
                      className="btn btn-default"
                      to="/processos"
                    >
                      <FaArrowAltCircleLeft /> Cancelar
                    </Link>
                    {btnEnviar && (
                      <button
                        className="btn btn-success"
                        onClick={handleEviarParaApia}
                        type="submit"
                      >
                      ENVIAR PARA TÉC. CONSULTOR PARA ANALISE{' '}
                        <FaArrowAltCircleRight />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Loading />
          )}
      </div>
    </div>
  );
};
export default Passo4;
