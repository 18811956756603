import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as Estado from '~/services/Estado';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadEstadosWorker({params}) {
  try {
    const response = yield call(Estado.load,params);
    yield put(actions.getEstados(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* showEstadoWorker({id}) {
  try {
    const response = yield call(Estado.show, id);
    yield put(actions.getEstado(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updateEstadoWorker({ estado,id}) {
  try {
    const response = yield call(Estado.update, id, estado);
    yield put(actions.getEstado(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeEstadoWorker({estado}) {
  try {
    const response = yield call(Estado.store,estado);
    console.log(response);
    yield put(actions.getEstado(response));
    yield put(actions.getErros({save:true}));
    yield put(getErros({save:true}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* removerEstadoWorker({id}) {
  try {
    const response = yield call(Estado.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));
    console.log(response);
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherEstado() {
  yield takeLatest(actionType.LOAD_ESTADOS, loadEstadosWorker);
  yield takeLatest(actionType.STORE_ESTADO, storeEstadoWorker);
  yield takeLatest(actionType.SHOW_ESTADO, showEstadoWorker);
  yield takeLatest(actionType.UPDATE_ESTADO, updateEstadoWorker);
  yield takeLatest(actionType.REMOVER_ESTADO, removerEstadoWorker);
}

export default function* saga() {
  yield all([watcherEstado()]);
}
