import * as actions from './actionType';

export const LegislacaoReducer = (state = {select_legislacoes: [], legislacoes: { data: [] }, legislacao: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.LEGISLACOES_DATA:
      return {
        ...state,
        legislacoes: action.legislacoes.data,
        links: action.legislacoes.data.links,
        meta: action.legislacoes.data.meta,
      };
    case actions.SELECT_LEGISLACOES:
      return {
        ...state,
        select_legislacoes: action.legislacoes.data
      };
    case actions.GET_LEGISLACAO:
      return {
        ...state,
        legislacao: action.legislacao.data.data,
        status_response: action.legislacao.status,
      };
    case actions.LEGISLACAO_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
