import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaInfoCircle } from 'react-icons/fa';
import { showResponsavelTecnico } from '~/store/modules/responsavel_tecnico/action';
import { Loading } from '~/components';
import FileInput from '~/components/FileInput';

const TipoAnotacao = ({ pessoa_responsavel_tecnico_id, setAnotacaoTecnica}) => {
  const dispatch = useDispatch();
  const [responsavelTecnico, setResponsavelTecnico] = useState();
  const [anotacoes, setAnotacoes] = useState({});
  const { responsavel_tecnico } = useSelector(
    ({ ResponsavelTecnicoReducer }) => ResponsavelTecnicoReducer
  );

  useEffect(() => {
    dispatch(showResponsavelTecnico(pessoa_responsavel_tecnico_id));
  }, [dispatch, pessoa_responsavel_tecnico_id]);

  useEffect(() => {
    if (
      responsavel_tecnico &&
      responsavel_tecnico.responsavel_tecnico &&
      responsavel_tecnico.id === parseInt(pessoa_responsavel_tecnico_id)
    ) {
      setAnotacoes({
        user_responsavel_tecnico_id: responsavel_tecnico.responsavel_tecnico.id,
      });
      setResponsavelTecnico(responsavel_tecnico);
    }
  }, [responsavel_tecnico, pessoa_responsavel_tecnico_id]);


  useEffect(() => {
    //console.log(responsavelTecnico);
  }, [responsavelTecnico]);


  useEffect(() => {
    setAnotacaoTecnica(anotacoes);
  }, [anotacoes, setAnotacaoTecnica]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setAnotacoes({
      ...anotacoes,
      [name]: value
    });
  }

  return (
    <>
      {responsavel_tecnico &&
      responsavel_tecnico.id === parseInt(pessoa_responsavel_tecnico_id) ? (
          <div className="table-responsive">
            <div
              className="alert alert-info d-flex justify-content-between
              align-items-center
              "
              role="alert"
            >
              <p className="m-0">
              Enviar arquivos de até <strong>5Mb</strong> nas ARTs e nos formatos de imagens suportados <strong>JPEG, JPG, PNG, PDF.</strong>
              </p>
              <FaInfoCircle />
            </div>
            <table className="table table-bordered table-striped table-hover table-sm">
              <thead>
                <tr>
                  <th
                    className="text-center"
                    colSpan="3"
                  >
                  Tipo de Anotação Técnica
                  </th>
                </tr>
              </thead>
              <tbody>
                {responsavel_tecnico.pode_elaboracao_e_estudo_projeto && (
                  <tr>
                    <th>Elaboração de Estudo e Projeto </th>
                    <td>
                      <div className="form-group">
                        <label>N. ART</label>
                        <input
                          className="form-control"
                          name="num_art_elaboracao_estudo_projeto"
                          onChange={handleChange}
                          required
                          type="text"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-group">
                        <label>Anexo</label>
                        <FileInput
                          accept=".jpeg, .jpg, .png, .pdf"
                          name="elaboracao_estudo_projeto_anexo"
                          data={anotacoes}
                          setData={setAnotacoes}
                          isRequired={true}
                        />
                      </div>
                    </td>
                  </tr>
                )}
                {responsavel_tecnico.pode_elaboracao_orcamento && (
                  <tr>
                    <th> Elaboração de Orçamento</th>
                    <td>
                      <div className="form-group">
                        <label>N. ART</label>
                        <input
                          className="form-control"
                          name="num_art_elaboracao_orcamento"
                          onChange={handleChange}
                          required
                          type="text"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-group">
                        <label>Anexo</label>
                        <FileInput
                          accept=".jpeg, .jpg, .png, .pdf"
                          name="elaboracao_orcamento_anexo"
                          data={anotacoes}
                          setData={setAnotacoes}
                          isRequired={true}
                        />
                      </div>
                    </td>
                  </tr>
                )}
                {responsavel_tecnico.pode_execucao_estudo_e_projeto && (
                  <tr>
                    <th> Implantação de Estudo e Projeto</th>
                    <td>
                      <div className="form-group">
                        <label>N. ART</label>
                        <input
                          className="form-control"
                          name="num_art_execucao_estudo_e_projeto"
                          onChange={handleChange}
                          required
                          type="text"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-group">
                        <label>Anexo</label>
                        <FileInput
                          accept=".jpeg, .jpg, .png, .pdf"
                          name="execucao_estudo_e_projeto_anexo"
                          data={anotacoes}
                          setData={setAnotacoes}
                          isRequired={true}
                        />
                      </div>
                    </td>
                  </tr>
                )}
                {responsavel_tecnico.pode_fiscalizacao_estudo_e_projeto && (
                  <tr>
                    <th> Execução de Estudo e Projeto</th>
                    <td>
                      <div className="form-group">
                        <label>N. ART</label>
                        <input
                          className="form-control"
                          name="num_art_fiscalizacao_estudo_projeto"
                          onChange={handleChange}
                          required
                          type="text"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-group">
                        <label>Anexo</label>
                        <FileInput
                          accept=".jpeg, .jpg, .png, .pdf"
                          name="fiscalizacao_estudo_projeto_anexo"
                          data={anotacoes}
                          setData={setAnotacoes}
                          isRequired={true}
                        />
                      </div>
                    </td>
                  </tr>
                )}
                {responsavel_tecnico.pode_implantacao_estudo_e_projeto && (
                  <tr>
                    <th> Fiscalização de Estudo e Projeto</th>
                    <td>
                      <div className="form-group">
                        <label>N. ART</label>
                        <input
                          className="form-control"
                          name="num_art_implantacao_estudo_projeto"
                          onChange={handleChange}
                          required
                          type="text"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-group">
                        <label>Anexo</label>
                        <FileInput
                          accept=".jpeg, .jpg, .png, .pdf"
                          name="implantacao_estudo_projeto_anexo"
                          data={anotacoes}
                          setData={setAnotacoes}
                          isRequired={true}
                        />
                      </div>
                    </td>
                  </tr>
                )}
                {responsavel_tecnico.pode_monitoramento_estudo_e_projeto && (
                  <tr>
                    <th> Monitoramento de Estudo e Projeto</th>
                    <td>
                      <div className="form-group">
                        <label>N. ART</label>
                        <input
                          className="form-control"
                          name="num_art_monitoramento_estudo_projeto"
                          onChange={handleChange}
                          required
                          type="text"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-group">
                        <label>Anexo</label>
                        <FileInput
                          accept=".jpeg, .jpg, .png, .pdf"
                          name="monitoramento_estudo_projeto_anexo"
                          data={anotacoes}
                          setData={setAnotacoes}
                          isRequired={true}
                        />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <Loading />
        )}
    </>
  );
};

export default TipoAnotacao;
