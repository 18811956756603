import * as actions from './actionType';

export const ProjetoProcessoReducer = (state = {select_projeto_processos: [], projeto_processos: [], projeto_processo: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.PROJETO_PROCESSOS_DATA:
      return {
        ...state,
        projeto_processos: action.projeto_processos.data,
        links: action.projeto_processos.data.links,
        meta: action.projeto_processos.data.meta,
      };
    case actions.SELECT_PROJETO_PROCESSOS:
      return {
        ...state,
        select_projeto_processos: action.projeto_processos.data
      };
    case actions.GET_PROJETO_PROCESSO:
      return {
        ...state,
        projeto_processo: action.projeto_processo.data.data,
        status_response: action.projeto_processo.status,
      };
    case actions.PROJETO_PROCESSO_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
