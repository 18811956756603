import * as Views from '~/views/Relatorio';

const basePath = '/relatorios';

export default [
    { component: Views.Reports, path: `${basePath}`, exact: true },
    { component: Views.RGLA, path: `${basePath}/relatorio-gestao-licenciamento-ambiental`, exact: true },
    { component: Views.RTLA, path: `${basePath}/relatorio-tecnico-licenciamento-ambiental`, exact: true },
    { component: Views.RAFA, path: `${basePath}/relatorio-arrecadacao-financeira-ambiental`, exact: true },
    { component: Views.RPLA, path: `${basePath}/relatorio-producao-licenciamento-ambiental`, exact: true },
    { component: Views.RCCA, path: `${basePath}/relatorio-cadastro-consultores-ambientais`, exact: true }
];
