import * as actions from './actionType';

export const loadMensagems = (processo_id, params) => ({
  type: actions.LOAD_MENSAGEMS,
  processo_id,
  params
});
export const getMensagems = mensagems => ({
  type: actions.MENSAGEMS_DATA,
  mensagems,
});

export const getSelectMensagem = mensagems => ({
  type: actions.SELECT_MENSAGEMS,
  mensagems,
});

export const storeMensagem = (processo_id, mensagem) => ({
  type: actions.STORE_MENSAGEM,
  processo_id,
  mensagem

});

export const responderMensagem = (mensagem_id, resposta) => ({
  type: actions.RESPONDER_MENSAGEM,
  mensagem_id,
  resposta

});

export const marcarComoLida = (mensagem_id) => ({
  type: actions.MARACAR_COMO_LIDA,
  mensagem_id

});

export const getMensagem = mensagem => ({
  type: actions.GET_MENSAGEM,
  mensagem,
});

export const loadMensagensNLidas = () => ({
  type: actions.MENSAGENS_N_LIDAS_REQUEST
});

export const getMensagensNLidas = (mensagems) => ({
  type: actions.GET_MENSAGENS_N_LIDAS,
  mensagems
});

export const removerMensagem = (id) => ({
  type: actions.REMOVER_MENSAGEM,
  id
});

export const showMensagem = (id, params) => ({
  type: actions.SHOW_MENSAGEM,
  id,
  params
});

export const updateMensagem = (mensagem, id) => ({
  type: actions.UPDATE_MENSAGEM,
  mensagem,
  id
});

export const getErros = errors => ({
  type: actions.MENSAGEM_ERROR,
  errors,
});
