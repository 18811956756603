import React, { useEffect } from 'react';
import { Link, Switch, Route, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { BasicContent } from '../components';
import { Loading, Paginate } from '~/components';
import { loadEstados, removerEstado } from '~/store/modules/estado/action';
import Create from '../Create';
import Edit from '../Edit';

const List = () => {
  let location = useLocation();
  let modal = location.state && location.state.modal;
  const dispatch = useDispatch();
  const { estados } = useSelector(
    ({ EstadoReducer }) => EstadoReducer
  );
  useEffect(() => {
    dispatch(loadEstados());
  }, [dispatch]);

  // useEffect(() => {

  // }, [estados]);

  const breadcrumb = {
    label: 'Lista',
    to: '/estado',
    active: false,
  };

  const handleRemove = async (event) => {
    const id = event.target.value;
    Swal.fire({
      title: 'Remover?',
      text: 'Deseja Remover esse item?!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        try {
          await dispatch(removerEstado(id));
          await Swal.fire('Sucesso',
            'Removido com sucesso!',
            'success');
          dispatch(loadEstados());
        } catch (error) {
          await Swal.fire('Erro',
            'Erro ao Remover',
            'error');
        }
      }
    })
  };
  return (
    <>
      {modal && (
        <Switch>
          <Route
            children={<Create />}
            path="/estado/create"
          />
          <Route
            children={<Edit />}
            exact
            path="/estado/:estado_id/edit"
          />
        </Switch>
      )}
      <BasicContent breadcrumb={breadcrumb}>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Lista</h2>

            <div className="card-tools">
              <Link
                className="btn btn-success"
                to={{
                  pathname: '/estado/create',
                  state: { modal: true },
                }}
              >
                <i className="fa fa-plus" /> Novo
              </Link>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-sm table-bordered table-hover table-striped">
                <thead>
                  <tr>
                    <th>id</th>
                    <th>Nome</th>
                    <th>UF</th>
                    <th>Código IBGE</th>
                    <th className="text-right">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {estados.data &&
                    estados.data.map((estado, index) => (
                      <tr key={index}>
                        <td>{estado.id}</td>
                        <td>{estado.nome}</td>
                        <td>{estado.uf}</td>
                        <td>{estado.cod_ibge}</td>
                        <td>
                          <div className="btn-group float-right">
                            <Link
                              className="btn btn-info"
                              to={`/estado/${estado.id}/detalhe`}
                            >
                              <FaEye /> Detalhes
                            </Link>
                            <Link
                              className="btn btn-default"
                              to={{
                                pathname: `/estado/${estado.id}/edit`,
                                state: { modal: true },
                              }}
                            >
                              <FaEdit /> Editar
                            </Link>
                            <button
                              className="btn btn-danger"
                              onClick={handleRemove}
                              value={estado.id}
                            >
                              <FaTrash /> Remover
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {!estados.data && <Loading />}
            </div>
          </div>
          <div className="card-footer clearfix">
            <Paginate
              load={loadEstados}
              meta={estados.meta}
            />
          </div>
        </div>
      </BasicContent>
    </>
  );
};

export default List;
