import React from 'react';
import { Link } from 'react-router-dom';
// import Swal from 'sweetalert2';
import { Paginate } from '~/components';
import {
  FaEdit, FaEye,
  FaPlusCircle, FaTimesCircle
} from 'react-icons/fa';
// import { loadTipoLicencas } from '~/store/modules/tipo_licenca/action';
// import Show from '../../../../Atividade/Show';
// import Edit from '../Edit';


const List = ({
  action,
  displayName,
  id,
  // createPath,
  createUrl,
  importDataUrl,
  title,
  labelCol1,
  labelCol2,
  labelCol3,
  valueCol1,
  valueCol2,
  valueCol3,
  values,
  detalhe,
  path_detalhe,
  handleActivation
}) => {
  // console.log("List values "+title+": ",values);
  let path_detalhe_ = ['', ''];
  if (detalhe) {
    path_detalhe_ = path_detalhe.split('*');
  }

  return (
    <div className="card">
      <div className="card-header">
        <h2 className="card-title">{title}</h2>
        <div className="card-tools">
          <Link
            className="btn btn-success mr-1"
            to={{
              pathname: createUrl,
              state: { modal: true }
            }}
          >
            <i className="fa fa-plus" /> Adicionar
          </Link>
          <Link
            className="btn btn-primary"
            to={{
              pathname: importDataUrl,
              state: { modal: true }
            }}
          >
            <i className="fa fa-plus-circle" /> Importar dados
          </Link>
        </div>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-bordered table-hover table-striped table-sm">
            <thead>
              <tr>
                {labelCol1 && (<th>{labelCol1}</th>)}
                {labelCol2 && (<th>{labelCol2}</th>)}
                {labelCol3 && (<th>{labelCol3}</th>)}
                <th className="text-right">Ações</th>
              </tr>
            </thead>
            <tbody>
              {values.data &&
                values.data.map((value, index) => (
                  <tr key={index}>
                    {
                      valueCol1 &&
                      (
                        <td>{value[valueCol1]}</td>
                      )
                    }

                    {
                      valueCol2 &&
                      (
                        <td>{value[valueCol2]}</td>
                      )
                    }

                    {
                      valueCol3 &&
                      (
                        <td>{value[valueCol3]}</td>
                      )
                    }

                    <td className="text-right">
                      <div className="btn-group">
                        {!value.deleted_at && (
                          <Link
                            className="btn btn-info"
                            to={{
                              pathname: `/meio_analise/${value.meio_analise_id}/tipo_meio_analise/${value.id}/edit`,
                              state: { modal: true },
                            }}
                          >
                            <FaEdit /> Editar
                          </Link>
                        )}
                        {
                          detalhe &&
                          (
                            <Link
                              className="btn btn-default"
                              to={{
                                pathname: path_detalhe_[0] + value.id + path_detalhe_[1]
                              }}
                            >
                              <FaEye /> Detalhes
                            </Link>
                          )
                        }

                        <button
                          className={`btn ${value.deleted_at ? 'btn-success' : 'btn btn-danger'}`}
                          onClick={() => handleActivation(value)}
                          type="button"
                        >
                          {
                            value.deleted_at ?
                              <><FaPlusCircle /> Ativar</> :
                              <><FaTimesCircle /> Desativar</>
                          }
                        </button>
                      </div>
                    </td>

                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="card-footer clearfix">
        <Paginate
          load={action}
          loadParans={{ where: `${displayName}:${id}` }}
          meta={values}
        />
      </div>
    </div>
  );
};

export default List;
