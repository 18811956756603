import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Loading } from '~/components';
import { FormCad } from '../../../components';
import initialData from '../../../Edit/initialData';
import schema from '../../../Edit/schema';
import { updateMenu, showMenu, loadSubMenus } from '~/store/modules/menu/action';

const Edit = () => {
  let history = useHistory();
  const [menuEdit, setMenuEdit] = useState(initialData);
  const [errorsValidate, setErrorsValidate] = useState([]);
  const [updated, setUpdated] = useState(false);
  const dispatch = useDispatch();
  const { menu_id } = useParams();
  const { sub_menu_id } = useParams();
  const { menu, errors } = useSelector(({ MenuReducer }) => MenuReducer);

  useEffect(() => {
    dispatch(showMenu(sub_menu_id));
  }, [dispatch, sub_menu_id]);

  useEffect(() => {
    if (menu.id === parseInt(sub_menu_id)) {
      setMenuEdit(menu);
    }
  }, [menu, sub_menu_id]);

  useEffect(() => {
    if (errors.status && errors.status === 422) {
      let server_errors = {};
      const response_errors = errors.data.errors;
      for (let name of Object.keys(response_errors)) {
        Object.assign(server_errors, { [name]: response_errors[name][0] });
      }
      setErrorsValidate(server_errors);
    }
    if(errors.update) {
      setUpdated(true);
    }
  }, [errors]);

  useEffect(() => {
    if (updated) {
      Swal.fire('Sucesso', 'Registro atualizado com sucesso!', 'success').then(
        () => {
          history.goBack();
          dispatch(loadSubMenus(menu_id));
        }
      );
    }
  }, [updated, history, dispatch, menu_id]);

  const handleClose = () => {
    history.push(`/menu/${menu_id}/detalhe`);
    dispatch(loadSubMenus(menu_id));
  };


  const handleSubmit = async event => {
    event.preventDefault();
    if (!schema.isValidSync(menuEdit)) {
      let yup_errors = {};
      schema.validate(menuEdit, { abortEarly: false }).catch(err => {
        for (let error of err.inner) {
          const { path, message } = error;
          Object.assign(yup_errors, { [path]: message });
        }
        setErrorsValidate(yup_errors);
      });
    } else {
      await dispatch(updateMenu(menuEdit, sub_menu_id));
    }

  };
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-xl"
      onHide={handleClose}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Sub Menu</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Editar</h2>
          </div>
          <div className="card-body">
            {menu.id === parseInt(sub_menu_id) ? (
              <FormCad
                errors={errorsValidate}
                menu={menuEdit}
                handleSubmit={handleSubmit}
                schema={schema}
                setMenu={setMenuEdit}
              />
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Link
          className="btn btn-default"
          to={`/menu/${menu_id}/detalhe`}
        >
          <i className="fa fa-window-close" /> Cancelar
        </Link>
        <button
          className="btn btn-success"
          form="form_menu"
          type="submint"
        >
          {' '}
          <i className="fa fa-save" /> Salvar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Edit;
