import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { loadMensagensNLidas } from '~/store/modules/mensagem/action';
import { useDispatch, useSelector } from 'react-redux';
import formatDataHora from '~/utils/formatDataHora';

const MensagensIcon = () => {
  let dispatch = useDispatch();
  const { user_auth } = useSelector(({ LoginReducer }) => LoginReducer);
  const { mensagens_n_lidas } = useSelector(
    ({ MensagemReducer }) => MensagemReducer
  );
  const [mensagens, setMensagens] = useState([]);
  const [show, setShow] = useState(false);
  const showRef = useRef(show);

  const handleShow = () => {
    showRef.current = !show;
    setShow((show) => !show);
  };

  useEffect(() => {
    window.addEventListener('click', (event) => {
      const element = document.querySelector('#message-box');

      if (event.target !== element && showRef.current) {
        showRef.current = false;
        setShow(false);
      }
    });

    return () =>
      window.removeEventListener('click', () => {
        showRef.current = false;
        setShow(false);
      });
  }, []);

  useEffect(() => {
    dispatch(loadMensagensNLidas());
  }, [dispatch]);

  useEffect(() => {
    if (mensagens_n_lidas && mensagens_n_lidas.data) {
      setMensagens(mensagens_n_lidas.data);
    }
  }, [mensagens_n_lidas]);

  const defLink = (mensagem) => {
    //tipo_user_id 7 - TEC_RESPONSAVEL
    if (user_auth.user.tipo_user_id === 7) {
      return `/analise-processo/minhas-analises/${mensagem.processo_id}`;
    } else if (user_auth.user.tipo_user_id === 5) {
      return `/analise-processo/${mensagem.processo_id}/detalhe`;
    } else {
      return `/processos/${mensagem.processo_id}/detalhe`;
    }
  };
  return (
    <li className={`nav-item dropdown ${show && 'show'}`}>
      <button
        className="nav-link"
        id="message-box"
        onClick={handleShow}
        style={{
          border: 'none',
          outline: 'none',
          cursor: 'pointer',
          background: 'none',
        }}
      >
        <i className="fas fa-comments">
          <span className="badge badge-danger navbar-badge">
            {mensagens.length}
          </span>
        </i>
      </button>
      <div
        className={`dropdown-menu dropdown-menu-lg dropdown-menu-right ${
          show && 'show'
        }`}
        style={{ maxHeight: 500, overflowY: 'auto' }}
      >
        {mensagens.map((mensagem) => (
          <Link
            className="dropdown-item"
            key={mensagem.id}
            to={defLink(mensagem)}
          >
            <div className="media">
              <img
                alt="User Avatar"
                className="img-size-50 mr-3 img-circle"
                src="https://abad.com.br/wp-content/uploads/2019/11/user-icon.svg"
              />
              <div className="media-body">
                <h3 className="dropdown-item-title">
                  {mensagem && mensagem.remetente && mensagem.remetente.nome}
                </h3>
                <p
                  className="text-sm"
                  style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {mensagem && mensagem.titulo}
                </p>
                <p className="text-sm text-muted">
                  {mensagem &&
                    mensagem.created_at &&
                    formatDataHora(mensagem.created_at)}
                </p>
              </div>
            </div>
          </Link>
        ))}

        {!mensagens.length && (
          <div className="media">
            <div className="media-body">
              <h6
                style={{
                  display: 'flex',
                  padding: '20px 0px',
                  marginBottom: '0px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Sem Novas Mensagens
              </h6>
            </div>
          </div>
        )}
      </div>
    </li>
  );
};

export default MensagensIcon;
