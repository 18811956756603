export const LOAD_VALORES_REFERENCIAS = 'LOAD_VALORES_REFERENCIAS';
export const LOAD_SELECT_VALORES_REFERENCIAS = 'LOAD_SELECT_VALORES_REFERENCIAS';
export const SELECT_VALORES_REFERENCIAS = 'SELECT_VALORES_REFERENCIAS';
export const VALOR_REFERENCIA_ERROR = 'VALOR_REFERENCIA_ERROR';
export const VALOR_REFERENCIA_GET_ERRORS = 'VALOR_REFERENCIA_GET_ERRORS';
export const VALOR_REFERENCIA_DATA = 'VALOR_REFERENCIA_DATA';
export const STORE_VALOR_REFERENCIA = 'STORE_VALOR_REFERENCIA';
export const GET_VALOR_REFERENCIA = 'GET_VALOR_REFERENCIA';
export const REMOVER_VALOR_REFERENCIA = 'REMOVER_VALOR_REFERENCIA';
export const SHOW_VALOR_REFERENCIA = 'SHOW_VALOR_REFERENCIA';
export const UPDATE_VALOR_REFERENCIA = 'UPDATE_VALOR_REFERENCIA';
export const ACTIVE_VALOR_REFERENCIA = 'ACTIVE_VALOR_REFERENCIA';
export const GET_CUSTOM_VALOR_REFERENCIA = 'GET_CUSTOM_VALOR_REFERENCIA';
