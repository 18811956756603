import * as actions from './actionType';

export const loadAtividades = (params) => ({
  type: actions.LOAD_ATIVIDADES,
  params
});
export const getAtividades = atividades => ({
  type: actions.ATIVIDADES_DATA,
  atividades,
});

export const getSelectAtividade = atividades => ({
  type: actions.SELECT_ATIVIDADES,
  atividades,
});

export const storeAtividade = atividade => ({
  type: actions.STORE_ATIVIDADE,
  atividade,

});
export const getAtividade = atividade => ({
  type: actions.GET_ATIVIDADE,
  atividade,
});

export const removerAtividade = (id) => ({
  type: actions.REMOVER_ATIVIDADE,
  id
});

export const showAtividade = (id, params) => ({
  type: actions.SHOW_ATIVIDADE,
  payload:{
    id:id,
    params:params
  }
});

export const updateAtividade = (atividade, id) => ({
  type: actions.UPDATE_ATIVIDADE,
  atividade,
  id
});

export const getErros = errors => ({
  type: actions.ATIVIDADE_ERROR,
  errors,
});
