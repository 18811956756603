import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as Gerente from '~/services/Gerente';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadGerentesWorker({ params }) {
  try {
    const response = yield call(Gerente.load, params);
    yield put(actions.getGerentes(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* showGerenteWorker({ id, params }) {
  try {
    const response = yield call(Gerente.show, id, params);
    yield put(actions.getGerente(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeGerenteWorker({ gerente }) {
  try {
    const response = yield call(Gerente.store, gerente);
    yield put(actions.getGerente(response));
    yield put(actions.changeStatus(response.status));
    yield put(actions.getErros({ save: true }));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* updateGerenteWorker({ id, gerente }) {
  try {
    const response = yield call(Gerente.update, id, gerente);
    yield put(actions.getGerente(response));
    yield put(actions.changeStatus(response.status));
    yield put(actions.getErros({ update: true }));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherGerente() {
  yield takeLatest(actionType.LOAD_GERENTES, loadGerentesWorker);
  yield takeLatest(actionType.SHOW_GERENTE, showGerenteWorker);
  yield takeLatest(actionType.STORE_GERENTE, storeGerenteWorker);
  yield takeLatest(actionType.UPDATE_GERENTE, updateGerenteWorker);
}

export default function* saga() {
  yield all([watcherGerente()]);
}
