import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import FileInput from '~/components/FileInput';
import paymentGateways from '../paymentGateways';
import {
    showEntidade, storeEntidade,
    updateEntidade
} from '~/store/modules/entidade/action';
import { loadEstados } from '~/store/modules/estado/action';
import { loadMunicipios } from '~/store/modules/municipio/action';
import initialData from './initialData';
import FinancialCredentials from './FinancialCredentials';
import EnvironmentalManagement from './EnvironmentalManagement';
import LegalAspect from './LegalAspect';
import formatCpfCnpj from '~/utils/formatCpfCnpj';
import formatCep from '~/utils/formatCep';
import buscaCep from '~/services/cep';
import formatPhone from '~/utils/formatPhone';
import removeMask from '~/utils/removeMask';

export default function EntityForm({ action, validation }) {
let history = useHistory();
const dispatch = useDispatch();
const { entidade_id } = useParams();
const { entidade, errors, status_response } = useSelector(({ EntidadeReducer }) => EntidadeReducer);
const { estados } = useSelector(({ EstadoReducer }) => EstadoReducer);
const { municipios } = useSelector(({ MunicipioReducer }) => MunicipioReducer);
const [errorsForm, setErrorsForm] = useState([]);
const [entity, setEntity] = useState(initialData);

    useEffect(() => {
        if (action === 'update' && entity.cep) {
            entity.cep = formatCep(entity.cep)
        }
    }, [action, entity.cep])

    useEffect(() => {
        if (status_response === 201 && action === 'store') {
        Swal.fire(
            'Sucesso',
            'Entidade criada com sucesso.'
        ).then(() => {
            history.push("/entidade");
        });
        }else if(status_response === 200 && action === 'update') {
            Swal.fire(
                'Sucesso',
                'Entidade atualizada com sucesso.'
            ).then(() => {
                history.go();
            });
        }
    }, [history, status_response, action]);


    useEffect(() => {
        dispatch(loadEstados({ raw_list: true }));
    }, [dispatch]);

    useEffect(() => {
        if(entity.estado_id) {
            dispatch(loadMunicipios(entity.estado_id, { raw_list: true }));
        }
    }, [dispatch, entity.estado_id]);

    useEffect(() => {
        if (parseInt(entidade_id)) {
            dispatch(showEntidade(entidade_id));
        }
    }, [dispatch, entidade_id]);

    useEffect(() => {
        if (action === 'update') {
            setEntity(entidade);
        }
    }, [entidade, action]);

    useEffect(() => {
        if (errors.errors) {
            let server_errors = {};
            const response_errors = errors.errors;

            for (let name of Object.keys(response_errors)) {
                Object.assign(server_errors, { [name]: response_errors[name][0] });
            }

            setErrorsForm(server_errors);
        }
    }, [errors]);

    const getErros = () => {
        if (!validation.isValidSync(entity)) {
            let yup_errors = {};

            validation.validate(entity, { abortEarly: false }).catch((err) => {
                for (let error of err.inner) {
                    const { path, message } = error;
                    Object.assign(yup_errors, { [path]: message });
                }

                setErrorsForm(yup_errors);
            });

            return errorsForm;
        }

        return false;
    };

    const handleChange = ({
        target: { name, value, files }
    }) => {
        setEntity({
            ...entity,
            [name]: files ? files[0] : value
        });
    };

    const handleStateChange = ({
        target: { value }
    }) => {
        setEntity({
            ...entity,
            estado_id: value,
        });
    };
    const handleBuscaCep = async (event) => {
        const { value } = event.target;
        const cep = value;
        try {
            const data = await buscaCep.get(`${cep}/json`);
            const endereco = data.data;
            const finded = {
                logradouro: endereco.logradouro,
                bairro: endereco.bairro,
            }
            Object.assign(entity, finded);
            setEntity({
                ...entity
            });
        } catch (err) {
            console.log(err);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setErrorsForm([]);

        if (!getErros()) {
            try {
                const form = new FormData();
                const entityWithoutSpecialCharacters = entity
                entityWithoutSpecialCharacters.telefone_fixo = removeMask(
                    entityWithoutSpecialCharacters.telefone_fixo
                );
                entityWithoutSpecialCharacters.cnpj = removeMask(
                    entityWithoutSpecialCharacters.cnpj
                )
                entityWithoutSpecialCharacters.telefone_secretaria = removeMask(
                    entityWithoutSpecialCharacters.telefone_secretaria
                )
                entityWithoutSpecialCharacters.telefone_movel = removeMask(
                    entityWithoutSpecialCharacters.telefone_movel
                )
                entityWithoutSpecialCharacters.cep = removeMask(
                    entityWithoutSpecialCharacters.cep
                )

                for (let key in entityWithoutSpecialCharacters) {
                    if (entityWithoutSpecialCharacters[key] !== null) {
                        form.append(key, entityWithoutSpecialCharacters[key]);
                    }
                }
                if (action === 'update' && entidade.id) {
                    form.append('_method', 'PUT');
                    await dispatch(updateEntidade(entidade.id, form));
                } else if (action === 'store') {
                    await dispatch(storeEntidade(form));
                }
            } catch (err) {
                console.log('response erros', err.response.data.errors);

                let server_errors = {};
                const response_errors = err.response.data.errors;

                for (let name of Object.keys(response_errors)) {
                    Object.assign(server_errors, { [name]: response_errors[name][0] });
                }

                console.log(server_errors);

                setErrorsForm(server_errors);
            }
        }
    };

    return (
        <Form id="form_entidade" onSubmit={handleSubmit} schema={validation}>
            <Row>
                {/* Logo da entidade*/}
                <Col md="3">
                    <div className="form-group">
                        <label htmlFor="nome" className="required">
                            Logo esquerda
                            {!!entity.logo && ' (Já Cadastrada)'}
                        </label>
                        <FileInput
                            id="logo"
                            isInvalid={errorsForm.logo}
                            data={entity}
                            dataError={errorsForm.logo}
                            name="logo"
                            setData={setEntity}
                        />
                    </div>
                </Col>

                {/* Logo do município */}
                <Col md="3">
                    <div className="form-group">
                        <label htmlFor="nome">
                            Logo direita
                            {!!entity.logo_municipio && ' (Já Cadastrada)'}
                        </label>
                        <FileInput
                            id="logo_municipio"
                            isInvalid={errorsForm.logo_municipio}
                            data={entity}
                            dataError={errorsForm.logo_municipio}
                            name="logo_municipio"
                            setData={setEntity}
                        />
                    </div>
                </Col>

                {/* Nome da entidade */}
                <Col md="3">
                    <div className="form-group">
                        <label htmlFor="nome"> Nome </label>
                        <Form.Control
                            id="nome"
                            isInvalid={!!errorsForm.nome}
                            name="nome"
                            onChange={handleChange}
                            placeholder="Nome da Entidade"
                            type="text"
                            value={entity.nome ?? ''}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errorsForm.nome}
                        </Form.Control.Feedback>
                    </div>
                </Col>

                {/* Meio de Pagamento */}
                <Col md="3">
                    <div className="form-group">
                        <label htmlFor="estado_id">Meio de Pagamento</label>
                        <Form.Control
                            as="select"
                            id="payment_gateway"
                            isInvalid={!!errorsForm.payment_gateway}
                            name="payment_gateway"
                            onChange={handleChange}
                            value={entity.payment_gateway ?? ''}
                        >
                            <option>Selecione</option>
                            {paymentGateways.map((paymentGateway, index) => (
                                <option key={`payment-method-${index}`} value={paymentGateway.value}>
                                    {paymentGateway.label}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {errorsForm.payment_gateway}
                        </Form.Control.Feedback>
                    </div>
                </Col>

                {/* CNPJ */}
                <Col md="6">
                    <div className="form-group">
                        <label htmlFor="cnpj"> CNPJ </label>
                        <Form.Control
                            id="cnpj"
                            isInvalid={!!errorsForm.cnpj}
                            name="cnpj"
                            onChange={handleChange}
                            placeholder="CNPJ da Entidade"
                            type="text"
                            maxLength="18"
                            minLength="18"
                            value={formatCpfCnpj(entity.cnpj ?? '')}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errorsForm.cnpj}
                        </Form.Control.Feedback>
                    </div>
                </Col>

                {/* E-mail */}
                <Col md="6">
                    <div className="form-group">
                        <label htmlFor="email"> E-mail </label>
                        <Form.Control
                            id="email"
                            isInvalid={!!errorsForm.email}
                            name="email"
                            onChange={handleChange}
                            placeholder="E-mail da Entidade"
                            type="text"
                            value={entity.email ?? ''}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errorsForm.email}
                        </Form.Control.Feedback>
                    </div>
                </Col>

                {/* Telefone Fixo */}
                <Col md="6">
                    <div className="form-group">
                        <label htmlFor="telefone_fixo"> Telefone Fixo </label>
                        <Form.Control
                            id="telefone_fixo"
                            isInvalid={!!errorsForm.telefone_fixo}
                            name="telefone_fixo"
                            onChange={handleChange}
                            placeholder="Telefone Fixo da Entidade"
                            type="text"
                            maxLength="15"
                            value={formatPhone(entity.telefone_fixo ?? '')}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errorsForm.telefone_fixo}
                        </Form.Control.Feedback>
                    </div>
                </Col>

                {/* Telefone Móvel */}
                <Col md="6">
                    <div className="form-group">
                        <label htmlFor="telefone_movel"> Telefone Móvel </label>
                        <Form.Control
                            id="telefone_movel"
                            isInvalid={!!errorsForm.telefone_movel}
                            name="telefone_movel"
                            onChange={handleChange}
                            placeholder="Telefone Móvel da Entidade"
                            type="text"
                            maxLength="15"
                            value={formatPhone(entity.telefone_movel ?? '')}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errorsForm.telefone_movel}
                        </Form.Control.Feedback>
                    </div>
                </Col>

                {/* Estado */}
                <Col md="6">
                    <div className="form-group">
                        <label htmlFor="estado_id">Estado</label>
                        <Form.Control
                            as="select"
                            id="estado_id"
                            isInvalid={!!errorsForm.estado_id}
                            name="estado_id"
                            onChange={handleStateChange}
                            type="number"
                            value={entity.estado_id ?? ''}
                        >
                            {estados.data && estados.data.map((estado) => (
                                <option key={estado.id} value={estado.id ?? ''}>
                                    {estado.nome}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {errorsForm.estado_id}
                        </Form.Control.Feedback>
                    </div>
                </Col>

                {/* Município */}
                <Col md="6">
                    {(entity.estado_id || estados.data) && (
                        <div className="form-group">
                            <label htmlFor="municipio_id">Município</label>
                            <Form.Control
                                as="select"
                                id="municipio_id"
                                isInvalid={!!errorsForm.municipio_id}
                                name="municipio_id"
                                onChange={handleChange}
                                type="number"
                                value={entity.municipio_id ?? ''}
                            >
                                {municipios.data && municipios.data.map((municipio) => (
                                    <option key={municipio.id} value={municipio.id ?? ''}>
                                        {municipio.nome}
                                    </option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {errorsForm.municipio_id}
                            </Form.Control.Feedback>
                        </div>
                    )}
                </Col>

                {/* CEP */}
                <Col md="2">
                    <div className="form-group">
                        <label htmlFor="cep"> CEP </label>
                        <Form.Control
                            id="cep"
                            isInvalid={!!errorsForm.cep}
                            name="cep"
                            onBlur={handleBuscaCep}
                            onChange={handleChange}
                            placeholder="CEP da Entidade"
                            type="text"
                            maxLength="9"
                            value={formatCep(entity.cep ?? '')}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errorsForm.cep}
                        </Form.Control.Feedback>
                    </div>
                </Col>

                {/* Logradouro */}
                <Col md="4">
                    <div className="form-group">
                        <label htmlFor="logradouro"> Logradouro </label>
                        <Form.Control
                            id="logradouro"
                            isInvalid={!!errorsForm.logradouro}
                            name="logradouro"
                            onChange={handleChange}
                            placeholder="Logradouro da Entidade"
                            type="text"
                            value={entity.logradouro ?? ''}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errorsForm.logradouro}
                        </Form.Control.Feedback>
                    </div>
                </Col>

                {/* Número */}
                <Col md="2">
                    <div className="form-group">
                        <label htmlFor="numero"> Número </label>
                        <Form.Control
                            id="numero"
                            isInvalid={!!errorsForm.numero}
                            name="numero"
                            onChange={handleChange}
                            placeholder="Número da Entidade"
                            type="text"
                            value={entity.numero ?? ''}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errorsForm.numero}
                        </Form.Control.Feedback>
                    </div>
                </Col>

                {/* Bairro */}
                <Col md="4">
                    <div className="form-group">
                        <label htmlFor="bairro"> Bairro </label>
                        <Form.Control
                            id="bairro"
                            isInvalid={!!errorsForm.bairro}
                            name="bairro"
                            onChange={handleChange}
                            placeholder="Bairro da Entidade"
                            type="text"
                            value={entity.bairro ?? ''}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errorsForm.bairro}
                        </Form.Control.Feedback>
                    </div>
                </Col>
            </Row>

            <FinancialCredentials
                entity={entity}
                errorsForm={errorsForm}
                handleChange={handleChange}
            />

            <EnvironmentalManagement
                entity={entity}
                setEntity={setEntity}
                errorsForm={errorsForm}
                handleChange={handleChange}
            />

            <LegalAspect
                entity={entity}
                errorsForm={errorsForm}
                handleChange={handleChange}
            />
        </Form>
    );
}
