import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Loading } from '~/components';
import { loadArquivo } from '~/store/modules/arquivo/action';

export default function ModalAnexos({
  title,
  description,
  path,
  show,
  handleHide,
}) {
  const { arquivo } = useSelector(({ ArquivoReducer }) => ArquivoReducer);
  const [anexo, setAnexo] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (path) {
      setAnexo(undefined);
      dispatch(loadArquivo(path));
    }
  }, [dispatch, path]);

  useEffect(() => {
    setAnexo(arquivo);
  }, [arquivo]);
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleHide}
      show={show}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {anexo && anexo.data && (
          <>
            <object
              aria-label="arquivo"
              data={`data:${anexo.data.mime_type};base64,${anexo.data.file}`}
              standby="Carregando..."
              style={{ height: '70vh', width: '100%' }}
              type={anexo.data.mime_type}
            />
            {description && <blockquote>{description}</blockquote>}
          </>
        )}
        {!anexo && <Loading />}
      </Modal.Body>
      <Modal.Footer>
        {anexo && anexo.data && (
          <a
            className="btn btn-default"
            download={`${anexo.data.name}.${anexo.data.extension}`}
            href={`data:${anexo.data.mime_type};base64,${anexo.data.file}`}
          >
            <i className="fa fa-download" /> Baixar
          </a>
        )}
        <button className="btn btn-default" onClick={handleHide}>
          <i className="fa fa-window-close" /> Fechar
        </button>
      </Modal.Footer>
    </Modal>
  );
}
