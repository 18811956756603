import * as Yup from 'yup';

const schema = Yup.object().shape({
    nome: Yup.string().required('Campo obrigatório.'),
    sigla: Yup.string().required('Campo obrigatório.'),
    qtd_tec_para_analise: Yup.string().required('Campo obrigatório.'),
    qtd_hora_tec_necessaria: Yup.string().required('Campo obrigatório.'),
    valor_hora_tec: Yup.string().required('Campo obrigatório.'),
    qtd_diarias: Yup.string().required('Campo obrigatório.'),
    valor_diaria: Yup.string().required('Campo obrigatório.'),
    valor_combustivel_vistoria: Yup.string().required('Campo obrigatório.'),
    qtd_viagem_vistoria: Yup.string().required('Campo obrigatório.'),
    validade: Yup.string().required('Campo obrigatório.'),
    expiration_unit: Yup.string().required('Campo obrigatório.'),
    expiration_quantity: Yup.number().required('Campo obrigatório.'),
});

export default schema;
