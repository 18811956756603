export const LOAD_LEGISLACOES = 'LOAD_LEGISLACOES';
export const LOAD_SELECT_LEGISLACOES = 'LOAD_SELECT_LEGISLACOES';
export const SELECT_LEGISLACOES = 'SELECT_LEGISLACOES';
export const LEGISLACAO_ERROR = 'LEGISLACAO_ERROR';
export const LEGISLACAO_GET_ERRORS = 'LEGISLACAO_GET_ERRORS';
export const LEGISLACOES_DATA = 'LEGISLACOES_DATA';
export const STORE_LEGISLACAO = 'STORE_LEGISLACAO';
export const GET_LEGISLACAO = 'GET_LEGISLACAO';
export const REMOVER_LEGISLACAO = 'REMOVER_LEGISLACAO';
export const SHOW_LEGISLACAO = 'SHOW_LEGISLACAO';
export const UPDATE_LEGISLACAO = 'UPDATE_LEGISLACAO';
export const ACTIVE_LEGISLACAO = 'ACTIVE_LEGISLACAO';
