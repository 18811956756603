import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import styles from './styles';
import { Header, Footer, Table, Row, Col } from '~/components/PDF';
import formatCep from '~/utils/formatCep';
import formatPhone from '~/utils/formatPhone';

export default function RADEPDF({
    period,
    entityName,
    organName,
    logoSiblam,
    logoEntity,
    data,
    logoCounty,
    activity
}) {
    return (
        <Document title="Relatório Ambiental de Desenvolvimento Econômico">
            <Page
                size="A4"
                style={styles.page}
                wrap={false}
                orientation="landscape"
            >
                <Header
                    logoEntity={logoEntity}
                    entityName={entityName}
                    organName={organName}
                    LogoCounty={logoCounty}
                    styles={styles}
                />

                <View style={{ marginTop: '-10px' }}>
                    <Text style={[styles.title, { textAlign: 'center' }]}>
                        Relatório Ambiental de Desenvolvimento Econômico
                    </Text>
                </View>

                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <View
                        style={[
                            styles.section,
                            { alignItems: 'flex-start', flexGrow: 1 },
                        ]}
                    >
                        {activity && (
                        <View
                            style={{
                                flexDirection: 'row',
                                borderTop: '1px solid black',
                                borderLeft: '1px solid black',
                            }}
                        >
                                <Text style={[styles.cell, styles.semibold]}>
                                    Atividade | CNAE
                                </Text>
                                <Text style={[styles.cell, styles.th]}>
                                    {activity.nome} | {activity.codigo}
                                </Text>
                        </View>
                            )}
                    </View>

                    <View
                        style={[
                            styles.section,
                            { alignItems: 'flex-end', flexGrow: 1 },
                        ]}
                    >
                        <View
                            style={{
                                borderTop: '1px solid black',
                                borderLeft: '1px solid black',
                                flexDirection: 'row',
                            }}
                        >
                            <Text style={[styles.cell, styles.semibold]}>
                                Período
                            </Text>
                            <Text style={[styles.cell, styles.th]}>
                                {period}
                            </Text>
                        </View>
                    </View>
                </View>

                <View style={styles.section}>
                    <Table>
                        <Row header>
                            <Col
                                style={[
                                    styles.semibold,
                                    { width: '3%', fontSize: '10px' },
                                ]}
                            >
                                N°
                            </Col>
                            <Col
                                style={[
                                    styles.semibold,
                                    { width: '24%', fontSize: '10px' },
                                ]}
                            >
                                CNAE / Atividade
                            </Col>
                            <Col
                                style={[
                                    styles.semibold,
                                    { width: '26%', fontSize: '10px' },
                                ]}
                            >
                                N° Processo
                            </Col>
                            <Col
                                style={[
                                    styles.semibold,
                                    { width: '20%', fontSize: '10px' },
                                ]}
                            >
                                Nome do Empreendimento
                            </Col>
                            <Col
                                style={[
                                    styles.semibold,
                                    { width: '19%', fontSize: '10px' },
                                ]}
                            >
                                Endereço
                            </Col>
                            <Col
                                style={[
                                    styles.semibold,
                                    { width: '8%', fontSize: '10px' },
                                ]}
                            >
                                Fone
                            </Col>
                        </Row>
                        {!data.length && (
                            <Row>
                                <Col style={{ textAlign: 'center' }}>Sem Dados Para O Período</Col>
                            </Row>
                        )}

                        {data.map(
                            (
                                {
                                    atividade,
                                    bairro,
                                    cep,
                                    cnae,
                                    logradouro,
                                    nome_empreendimento,
                                    numero_endereco,
                                    protocolo,
                                    fone,
                                },
                                index
                            ) => (
                                <Row key={`table-one-key-${index + 1}`}>
                                    <Col
                                        style={{
                                            width: '3%',
                                            fontSize: '10px',
                                        }}
                                    >
                                        {(index + 1).toString().padStart(2, '0')}
                                    </Col>
                                    <Col
                                        style={{
                                            width: '24%',
                                            fontSize: '10px',
                                        }}
                                    >
                                        {cnae} | {atividade}
                                    </Col>
                                    <Col
                                        style={{
                                            width: '26%',
                                            fontSize: '10px',
                                        }}
                                    >
                                        {protocolo}
                                    </Col>
                                    <Col
                                        style={{
                                            width: '20%',
                                            fontSize: '10px',
                                        }}
                                    >
                                        {nome_empreendimento}
                                    </Col>
                                    <Col
                                        style={{
                                            width: '19%',
                                            fontSize: '10px',
                                        }}
                                    >
                                        {`${logradouro}, ${numero_endereco} - ${bairro}, ${formatCep(
                                            cep || ''
                                        )}`}
                                    </Col>
                                    <Col
                                        style={{
                                            width: '8%',
                                            fontSize: '10px',
                                        }}
                                    >
                                        {formatPhone(fone || '')}
                                    </Col>
                                </Row>
                            )
                        )}
                    </Table>
                </View>

                <Footer logoSiblam={logoSiblam} styles={styles} />
            </Page>
        </Document>
    );
}
