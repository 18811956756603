import * as actions from './actionType';

export const loadAutorizacaoProcessos = (params) => ({
  type: actions.LOAD_AUTORIZACAO_PROCESSOS,
  params,
});

export const loadListAutorizacaoProcessos = (params) => ({
  type: actions.LOAD_LIST_AUTORIZACAO_PROCESSOS,
  params,
});

export const getAutorizacaoProcessos = (autorizacao_processos) => ({
  type: actions.AUTORIZACAO_PROCESSOS_DATA,
  autorizacao_processos,
});

export const getSelectAutorizacaoProcesso = (autorizacao_processos) => ({
  type: actions.SELECT_AUTORIZACAO_PROCESSOS,
  autorizacao_processos,
});

export const storeAutorizacaoProcesso = (autorizacao_processo) => ({
  type: actions.STORE_AUTORIZACAO_PROCESSO,
  autorizacao_processo,
});

export const getAutorizacaoProcesso = (autorizacao_processo) => ({
  type: actions.GET_AUTORIZACAO_PROCESSO,
  autorizacao_processo,
});

export const removerAutorizacaoProcesso = (id) => ({
  type: actions.REMOVER_AUTORIZACAO_PROCESSO,
  id,
});

export const showAutorizacaoProcesso = (id, params) => ({
  type: actions.SHOW_AUTORIZACAO_PROCESSO,
  id,
  params,
});

export const updateAutorizacaoProcesso = (autorizacao_processo, id) => ({
  type: actions.UPDATE_AUTORIZACAO_PROCESSO,
  autorizacao_processo,
  id,
});

export const getErros = (errors) => ({
  type: actions.AUTORIZACAO_PROCESSO_ERROR,
  errors,
});

export const changeStatus = (status) => ({
  type: actions.CHANGE_STATUS_CODE,
  status,
});
