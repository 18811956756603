import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Form, Button } from 'react-bootstrap';
import FileInput from '~/components/FileInput';
import Swal from 'sweetalert2';
import {
  storeFotoVistoria, removeFotoVistoria
} from '~/store/modules/foto_vistoria/action';
import { load } from '~/services/FotoVistoria';

export default function PhotoForm({ vistoria, handleOpenModalAnexo, onlyView }) {
  const dispatch = useDispatch();
  const [fotoVistoria, setFotoVistoria] = useState({});
  const [fotosVistoria, setFotosVistoria] = useState([]);

  const getFotosVistoria = useCallback(async () => {
    if (vistoria.relatorio && vistoria.relatorio.id) {
      const res = await load(vistoria.relatorio.id);
      setFotosVistoria(res.data);
    }
  }, [vistoria.relatorio]);

  useEffect(() => {
    getFotosVistoria();
  }, [getFotosVistoria]);

  const handleFotoVistoriaInput = event => {
    const { name, value } = event.target;
    setFotoVistoria({
      ...fotoVistoria,
      [name]: value
    });
  }

  const handleFotoVistoriaFormSubmit = (event, relatorioVistoriaId) => {
    event.preventDefault();

    const form = new FormData();

    for (let key in fotoVistoria) {
      form.append(key, fotoVistoria[key]);
    }
    form.append('relatorio_vistoria_id', parseInt(relatorioVistoriaId));

    Swal.fire({
      title: 'Deseja salvar esta foto?',
      icon: 'question',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCancelButton: true,
      showConfirmButton: true,
    }).then(async result => {
      if (result.value) {
        await dispatch(storeFotoVistoria(form));
        setFotoVistoria({});
        clearFormFotoVistoria();
        Swal.fire(
          'Sucesso',
          'Foto da vistoria salva com sucesso!',
          'success'
        ).then(async () => {
          await getFotosVistoria();
        });
      }
    }).catch(error => {
      Swal.fire('Erro', 'Sua foto não pôde ser salva', 'error');
      console.log(error);
    });
  }

  const clearFormFotoVistoria = () => {
    document.getElementById(`form-foto-vistoria-${vistoria.id}`).reset();
  }

  const handleFotoVistoriaRemove = foto_vistoria_id => {
    Swal.fire({
      title: 'Deseja remover esta foto?',
      icon: 'question',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCancelButton: true,
      showConfirmButton: true,
    }).then(async result => {
      if (result.value) {
        await dispatch(removeFotoVistoria(foto_vistoria_id));
        Swal.fire(
          'Sucesso',
          'Foto da vistoria removida com sucesso!',
          'success'
        ).then(async () => {
          await getFotosVistoria();
        });
      }
    }).catch(error => {
      Swal.fire('Erro', 'Sua foto não pôde ser removida', 'error');
      console.log(error);
    })
  }

  return (
    <Fragment>
      {vistoria.relatorio && vistoria.relatorio.id && (
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>SEÇÃO DE FOTOS DA VISTORIA</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {!onlyView && (
                  <td>
                    <form
                      id={`form-foto-vistoria-${vistoria.id}`}
                      onSubmit={
                        event => handleFotoVistoriaFormSubmit(
                          event, vistoria.relatorio.id
                        )
                      }
                    >
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>
                              Nome
                              <span className="required-input">*</span>
                            </Form.Label>
                            <Form.Control
                              name="nome"
                              onChange={handleFotoVistoriaInput}
                              required
                              type="text"
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>
                              Imagem
                              <span className="required-input">*</span>
                            </Form.Label>
                            <FileInput
                              accept=".jpeg, .jpg, .png"
                              data={fotoVistoria}
                              id="foto-vistoria"
                              isRequired
                              name="foto"
                              setData={setFotoVistoria}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Form.Group>
                        <Form.Label>Descrição</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="descricao"
                          onChange={handleFotoVistoriaInput}
                          rows={3}
                        />
                      </Form.Group>
                      <button
                        className="btn btn-success"
                        type="submit"
                      >
                        <i className="fa fa-save"/> Salvar
                      </button>
                    </form>
                  </td>
                )}
              </tr>
              {fotosVistoria.length > 0 && (
                <tr>
                  <td>
                    <b>IMAGENS</b>
                    <br/>
                    <hr/>
                    <div>
                      {fotosVistoria.map((foto_vistoria, index) => (
                        <div
                          className="btn-group"
                          key={`btn-foto-vistoria-${index}`}
                          style={{ margin: '5px' }}
                        >
                          <Button
                            onClick={() =>
                              handleOpenModalAnexo({
                                title: foto_vistoria.nome,
                                description: foto_vistoria.descricao,
                                path: foto_vistoria.foto
                              })
                            }
                            variant="default"
                          >
                            {foto_vistoria.nome}
                          </Button>
                          {!onlyView && (
                            <Button
                              onClick={() => handleFotoVistoriaRemove(foto_vistoria.id)}
                              variant="default"
                            >
                              <i
                                className="fas fa-times-circle"
                                style={{ color: 'red' }}
                                title="Remover"
                              />
                            </Button>
                          )}
                        </div>

                        // <div
                        //   className="btn btn-default"
                        //   key={`foto-vistoria-${index}`}
                        //   onClick={() =>
                        //     handleOpenModalAnexo({
                        //       title: foto_vistoria.nome,
                        //       description: foto_vistoria.descricao,
                        //       path: foto_vistoria.foto
                        //     })
                        //   }
                        // >
                        //   {foto_vistoria.nome} &nbsp;
                        //   <i
                        //     title="Remover"
                        //     style={{ color: 'red' }}
                        //     className="fas fa-times-circle"
                        //     onClick={() => handleFotoVistoriaRemove(foto_vistoria.id)}
                        //   >
                        //   </i>
                        // </div>
                      ))}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </Fragment>
  );
}
