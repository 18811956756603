import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import styles from './styles';
import { Header, Footer, Table, Row, Col } from '~/components/PDF';
import formatDataHora from '~/utils/formatDataHora';

export default function RPLAPDF({
    period,
    entityName,
    organName,
    logoSiblam,
    logoEntity,
    data,
    logoCounty,
}) {
    return (
        <Document title="Relatório de Produção de Licenciamento Ambiental">
            {data.map(({ evaluator, processes_analysis }, index) => (
                <Page
                    size="A4"
                    style={styles.page}
                    wrap={false}
                    key={`process-analysis-${index}`}
                >
                    <Header
                        logoEntity={logoEntity}
                        entityName={entityName}
                        organName={organName}
                        LogoCounty={logoCounty}
                        styles={styles}
                    />

                    <View style={{ marginTop: '-10px' }}>
                        <Text style={[styles.title, { textAlign: 'center' }]}>
                            Relatório de Produção de Licenciamento Ambiental
                        </Text>
                    </View>

                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <View
                            style={[
                                styles.section,
                                { alignItems: 'flex-start', flexGrow: 1 },
                            ]}
                        >
                            <View
                                style={{
                                    flexDirection: 'row',
                                    borderTop: '1px solid black',
                                    borderLeft: '1px solid black',
                                }}
                            >
                                <Text style={[styles.cell, styles.semibold]}>
                                    Analista
                                </Text>
                                <Text style={[styles.cell, styles.th]}>
                                    {evaluator.nome}
                                </Text>
                            </View>
                        </View>

                        <View
                            style={[
                                styles.section,
                                { alignItems: 'flex-end', flexGrow: 1 },
                            ]}
                        >
                            <View
                                style={{
                                    borderTop: '1px solid black',
                                    borderLeft: '1px solid black',
                                    flexDirection: 'row',
                                }}
                            >
                                <Text style={[styles.cell, styles.semibold]}>
                                    Período
                                </Text>
                                <Text style={[styles.cell, styles.th]}>
                                    {period}
                                </Text>
                            </View>
                        </View>
                    </View>

                    <View style={styles.section}>
                        <Table>
                            <Row header>
                                <Col
                                    style={[
                                        styles.semibold,
                                        { width: '5%', fontSize: '10px' },
                                    ]}
                                >
                                    N°
                                </Col>
                                <Col
                                    style={[
                                        styles.semibold,
                                        { width: '39%', fontSize: '10px' },
                                    ]}
                                >
                                    N° Processo
                                </Col>
                                <Col
                                    style={[
                                        styles.semibold,
                                        { width: '22%', fontSize: '10px' },
                                    ]}
                                >
                                    Data Recebimento
                                </Col>
                                <Col
                                    style={[
                                        styles.semibold,
                                        { width: '22%', fontSize: '10px' },
                                    ]}
                                >
                                    Data Finalização
                                </Col>
                                <Col
                                    style={[
                                        styles.semibold,
                                        { width: '8%', fontSize: '10px' },
                                    ]}
                                >
                                    Dias de Análise
                                </Col>
                            </Row>
                            {!processes_analysis.length && (
                                <Row>
                                    <Col>Sem Dados Para O Período</Col>
                                </Row>
                            )}

                            {processes_analysis.map(
                                (
                                    {
                                        protocolo,
                                        data_hora_inicio,
                                        data_hora_conclusao,
                                        dias_analise,
                                    },
                                    index
                                ) => (
                                    <Row key={`table-one-key-${index + 1}`}>
                                        <Col
                                            style={{
                                                width: '5%',
                                                fontSize: '10px',
                                            }}
                                        >
                                            {(index + 1)
                                                .toString()
                                                .padStart(2, '0')}
                                        </Col>
                                        <Col
                                            style={{
                                                width: '39%',
                                                fontSize: '10px',
                                                wordBreak: 'break-word',
                                            }}
                                        >
                                            <Text>{protocolo}</Text>
                                        </Col>
                                        <Col
                                            style={{
                                                width: '22%',
                                                fontSize: '10px',
                                            }}
                                        >
                                            {formatDataHora(data_hora_inicio)}
                                        </Col>
                                        <Col
                                            style={{
                                                width: '22%',
                                                fontSize: '10px',
                                            }}
                                        >
                                            {formatDataHora(
                                                data_hora_conclusao
                                            )}
                                        </Col>
                                        <Col
                                            style={{
                                                width: '10%',
                                                fontSize: '8',
                                            }}
                                        >
                                            {dias_analise}
                                        </Col>
                                    </Row>
                                )
                            )}
                        </Table>
                    </View>

                    <Footer logoSiblam={logoSiblam} styles={styles} />
                </Page>
            ))}
        </Document>
    );
}
