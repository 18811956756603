import { all, put,call, takeLatest } from 'redux-saga/effects';
import * as Veiculo from '~/services/Veiculo';
import  * as actionType from './actionType';
import * as actions from './action';
import { getErros } from '~/store/modules/errors/action';

function* loadVeiculosWorker({params}) {
  try {
    const response = yield call(Veiculo.load, params);
    yield put(actions.getVeiculos(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* loadSelectVeiculosWorker() {
  try {
    const response = yield call(Veiculo.select);
    yield put(actions.getSelectVeiculos(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));
  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({ data: error.message }));
    } else {
      yield put(getErros({ data: error.message }));
    }
  }
}

function* showVeiculoWorker({id}) {
  try {
    const response = yield call(Veiculo.show, id);
    yield put(actions.getVeiculo(response));
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* updateVeiculoWorker({ veiculo, id}) {
  try {
    const response = yield call(Veiculo.update, id, veiculo);
    yield put(actions.getVeiculo(response));
    yield put(actions.getErros({update:true}));
    yield put(getErros({update:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* storeVeiculoWorker({veiculo}) {
  try {
    const response = yield call(Veiculo.store, veiculo);
    yield put(actions.getVeiculo(response));
    yield put(actions.getErros({save:true}));
    yield put(getErros({save:true}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* removerVeiculoWorker({id}) {
  try {
    yield call(Veiculo.destroy, id);
    yield put(actions.getErros({}));
    yield put(getErros({}));

  } catch (error) {
    if (error.response) {
      yield put(actions.getErros(error.response));
      yield put(getErros(error.response));
    } else if (error.request) {
      yield put(getErros({data: error.message}));
    } else {
      yield put(getErros({data: error.message}));
    }
  }
}

function* watcherVeiculo() {
  yield takeLatest(actionType.LOAD_VEICULOS, loadVeiculosWorker);
  yield takeLatest(actionType.LOAD_SELECT_VEICULOS, loadSelectVeiculosWorker);
  yield takeLatest(actionType.STORE_VEICULO, storeVeiculoWorker);
  yield takeLatest(actionType.SHOW_VEICULO, showVeiculoWorker);
  yield takeLatest(actionType.UPDATE_VEICULO, updateVeiculoWorker);
  yield takeLatest(actionType.REMOVER_VEICULO, removerVeiculoWorker);
}

export default function* saga() {
  yield all([watcherVeiculo()]);
}
