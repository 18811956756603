export const LOAD_RESPOSTA_QUESTAOS = 'LOAD_RESPOSTA_QUESTAOS';
export const SELECT_RESPOSTA_QUESTAOS = 'SELECT_RESPOSTA_QUESTAOS';
export const RESPOSTA_QUESTAO_ERROR = 'RESPOSTA_QUESTAO_ERROR';
export const RESPOSTA_QUESTAO_GET_ERRORS = 'RESPOSTA_QUESTAO_GET_ERRORS';
export const RESPOSTA_QUESTAOS_DATA = 'RESPOSTA_QUESTAOS_DATA';
export const STORE_RESPOSTA_QUESTAO = 'STORE_RESPOSTA_QUESTAO';
export const GET_RESPOSTA_QUESTAO = 'GET_RESPOSTA_QUESTAO';
export const REMOVER_RESPOSTA_QUESTAO = 'REMOVER_RESPOSTA_QUESTAO';
export const SHOW_RESPOSTA_QUESTAO = 'SHOW_RESPOSTA_QUESTAO';
export const UPDATE_RESPOSTA_QUESTAO = 'UPDATE_RESPOSTA_QUESTAO';
