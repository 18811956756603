import * as actions from './actionType';

export const RespostaQuestaoReducer = (state = {select_resposta_questaos: [], resposta_questaos: [], resposta_questao: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.RESPOSTA_QUESTAOS_DATA:
      return {
        ...state,
        resposta_questaos: action.resposta_questaos.data,
        links: action.resposta_questaos.data.links,
        meta: action.resposta_questaos.data.meta,
      };
    case actions.SELECT_RESPOSTA_QUESTAOS:
      return {
        ...state,
        select_resposta_questaos: action.resposta_questaos.data
      };
    case actions.GET_RESPOSTA_QUESTAO:
      return {
        ...state,
        resposta_questao: action.resposta_questao.data.data,
        status_response: action.resposta_questao.status,
      };
    case actions.RESPOSTA_QUESTAO_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
