import React, { useEffect } from 'react';
import { Link, Switch, Route, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaEye } from 'react-icons/fa';
import { BasicContent } from '../components';
import { Loading, Paginate } from '~/components';
import formatForMask from '~/utils/formatForMask';
import { loadTecnicoProfissionais } from '~/store/modules/tecnico_profissional/action';
import Show from '../Show';

const List = () => {
  let location = useLocation();
  let modal = location.state && location.state.modal;
  const dispatch = useDispatch();
  const { tecnico_profissionais } = useSelector(
    ({ TecnicoProfissionalReducer }) => TecnicoProfissionalReducer
  );

  useEffect(() => {
    dispatch(loadTecnicoProfissionais());
  }, [dispatch]);

  useEffect(() => {
  }, [tecnico_profissionais]);

  const breadcrumb = {
    label: 'Lista',
    to: '/empreendimentos/tecnico_profissional',
    active: false,
  };

  return (
    <>
      {modal && (
        <Switch>
          <Route
            children={<Show />}
            exact
            path="/empreendimentos/tecnico_profissional/:tecnico_profissional_id/detalhe"
          />

        </Switch>
      )}
      <BasicContent breadcrumb={breadcrumb}>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Lista</h2>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-sm table-bordered table-hover table-striped">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Nome</th>
                    <th>CNPJ/CPF</th>
                    <th>Email</th>
                    <th className="text-right">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {tecnico_profissionais.data &&
                    tecnico_profissionais.data.map((tecnico_profissional, index) => (
                      <tr key={index}>
                        <td>{tecnico_profissional.id}</td>
                        <td>{tecnico_profissional.nome}</td>
                        <td>{tecnico_profissional.cnpj ? formatForMask(
                          tecnico_profissional.cnpj,
                          '##.###.###/####-##'
                        ): ''} {tecnico_profissional.cpf ? formatForMask(
                          tecnico_profissional.cpf,
                          '###.###.###-##'
                        )
                          : ''}</td>
                        <td>{tecnico_profissional.email}</td>
                        <td>
                          <div className="btn-group float-right">
                            <Link
                              className="btn btn-info"
                              to={{
                                pathname: `/empreendimentos/tecnico_profissional/${tecnico_profissional.id}/detalhe`,
                                state: { modal: true },
                              }}
                            >
                              <FaEye /> Detalhes
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {!tecnico_profissionais && <Loading />}
            </div>
          </div>
          <div className="card-footer clearfix">
            <Paginate
              load={loadTecnicoProfissionais}
              meta={tecnico_profissionais.meta}
            />
          </div>
        </div>
      </BasicContent>
    </>
  );
};

export default List;
