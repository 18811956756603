import React from 'react';
import styles from './styles';
import { Text } from '@react-pdf/renderer';

export default function Col({ children, widthPercentage, style }) {
  return (
    <Text
      style={[
        styles.tableCol,
        { width: `${widthPercentage ? widthPercentage * 5 : 100}%` },
        style
      ]}
    >
      {children}
    </Text>
  );
}
