import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { loadRecursos, addRecurso } from '~/store/modules/tipo_user/action';

const Recursos = () => {
  const dispatch = useDispatch();
  const { recursos_tipo_user, errors } = useSelector(({ TipoUserReducer }) => TipoUserReducer);
  const { tipo_user_id } = useParams();
  let history = useHistory();
  const [boxes, setBoxes] = useState([]);
  const [saved, setSaved] = useState(false);

  const [recursosIds, setRecursosIds] = useState([]);

  const handleCheck = event => {
    let value = event.target.value;
    if (event.target.checked) {
      setRecursosIds([...recursosIds, parseInt(value)]);
    } else {
      setRecursosIds(recursosIds.filter(item => parseInt(item) !== parseInt(value)));
    }
  };

  useEffect(() => {
    if (errors.save) {
      setSaved(errors.save);
    }
  }, [errors]);

  useEffect(() => {
    let recursos = [];
    if (recursos_tipo_user) {
      recursos_tipo_user.forEach(({ id, checked }) => {
        if (checked) {
          recursos.push(id);
        }
      });
      setBoxes(recursos_tipo_user);
    }
    setRecursosIds(recursos);
  }, [recursos_tipo_user]);

  useEffect(() => {
    if (saved) {
      Swal.fire('Sucesso', 'Menus atualizados com sucesso!', 'success').then(
        () => {
          dispatch(loadRecursos(tipo_user_id));
          setSaved(false);
          history.replace();
        }
      );
    }
  }, [dispatch, saved, tipo_user_id, history]);
  const handleSubmit = async event => {
    event.preventDefault();
    // console.log(recursosIds);
    await dispatch(addRecurso(tipo_user_id, { recurso_id: recursosIds }));
  };
  return (
    <div className="card">
      <div className="card-header">
        <h2 className="card-title">Permissões</h2>

        <div className="card-tools">
          <button
            className="btn btn-success"
            form="form_update_recursos"
            type="submit"
          >
            {' '}
            <i className="fa fa-sync" /> Atualizar
          </button>
        </div>
      </div>
      <div className="card-body table-responsive">
        <form id="form_update_recursos" onSubmit={handleSubmit}>
          <table className="table table-striped table-borderad table-hove">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Rota</th>
                <th>Permitir</th>
              </tr>
            </thead>
            <tbody>
              {boxes &&
                boxes.map(({ id, nome, nome_rota, checked }) => (
                  <tr key={id}>
                    <td>{nome}</td>
                    <td>{nome_rota}</td>
                    <td>
                      <Form.Check
                        className="mb-3"
                        defaultChecked={checked}
                        id={`recurso_id-${id}`}
                        label="Permitir"
                        name="recurso_id[]"
                        onClick={handleCheck}
                        type="switch"
                        value={id}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </form>
      </div>
      <div className="card-footer clearfix" />
    </div>
  );
};

export default Recursos;
