import * as actions from './actionType';

export const RecursoReducer = (state = {select_recursos: [], recursos: [], recurso: {}, errors: {}}, action) => {
  switch (action.type) {
    case actions.RECURSOS_DATA:
      return {
        ...state,
        recursos: action.recursos.data,
        links: action.recursos.data.links,
        meta: action.recursos.data.meta,
      };
    case actions.SELECT_RECURSOS:
      return {
        ...state,
        select_recursos: action.recursos.data
      };
    case actions.GET_RECURSO:
      return {
        ...state,
        recurso: action.recurso.data.data,
        status_response: action.recurso.status,
      };
    case actions.RECURSO_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
