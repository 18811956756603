import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormCad } from '../components';
import Swal from 'sweetalert2';
import initialData from './initialData';
import schema from './schema';
import {
  loadConfigAutorizacoes,
  changeStatus,
  storeConfigAutorizacao,
} from '~/store/modules/config_autorizacao/action';
import { listCustomTipoUsers } from '~/store/modules/tipo_user/action';

const Create = ({ entidade_id }) => {
  let history = useHistory();
  const [configAutorizacao, setConfigAutorizacao] = useState(initialData);
  const [saved, setSaved] = useState(false);
  const [errorsValidate, setErrorsValidate] = useState([]);
  const dispatch = useDispatch();
  const { status_response, errors } = useSelector(
    ({ ConfigAutorizacaoReducer }) => ConfigAutorizacaoReducer
  );
  const { tipo_users } = useSelector(({ TipoUserReducer }) => TipoUserReducer);

  useEffect(() => {
    if (errors.status && errors.status === 422) {
      let server_errors = {};
      const response_errors = errors.data.errors;
      for (let name of Object.keys(response_errors)) {
        Object.assign(server_errors, { [name]: response_errors[name][0] });
      }
      setErrorsValidate(server_errors);
    }
    if (errors.save) {
      setSaved(errors.save);
    }
  }, [errors]);

  useEffect(() => {
    dispatch(listCustomTipoUsers());
  }, [dispatch]);

  useEffect(() => {
    if (saved && status_response === 201) {
      Swal.fire('Sucesso', 'Registro salvo com sucesso!', 'success').then(
        () => {
          history.goBack();
          dispatch(
            loadConfigAutorizacoes({
              where: `entidade_id:${entidade_id}`,
            })
          );
          dispatch(changeStatus(null));
        }
      );
    }
  }, [status_response, history, dispatch, saved, entidade_id]);

  const handleClose = () => {
    history.goBack();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!schema.isValidSync(configAutorizacao)) {
      let yup_errors = {};
      schema.validate(configAutorizacao, { abortEarly: false }).catch((err) => {
        for (let error of err.inner) {
          const { path, message } = error;
          Object.assign(yup_errors, { [path]: message });
        }
        setErrorsValidate(yup_errors);
      });
    } else {
      const conf = configAutorizacao;
      conf.obrigatoria = Boolean(parseInt(configAutorizacao.obrigatoria));
      conf.entidade_id = parseInt(entidade_id);
      conf.tipo_user_id = parseInt(configAutorizacao.tipo_user_id);
      await dispatch(storeConfigAutorizacao(conf));
    }
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-xl"
      onHide={handleClose}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Configurações de Autorizações
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Novo</h2>
          </div>
          <div className="card-body">
            <FormCad
              config_autorizacao={configAutorizacao}
              errors={errorsValidate}
              handleSubmit={handleSubmit}
              schema={schema}
              setConfigAutorizacao={setConfigAutorizacao}
              tipo_users={tipo_users}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-default" onClick={handleClose}>
          <i className="fa fa-window-close" /> Cancelar
        </button>
        <button
          className="btn btn-success"
          form="form_config_autorizacao"
          type="submint"
        >
          {' '}
          <i className="fa fa-save" /> Salvar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Create;
