import React from 'react';
import { Content } from '~/layouts/Dash/components';

const Relatorio = () => {

  const breadcrumbs = [
    {
      label: 'Relatório',
      to: '/relatorio',
      active: false,
    }, {
      label: 'Mensal',
      to: '/relatorio',
      active: true,
    }
  ];
  return(
    <Content
      breadcrumbs={breadcrumbs}
      title="RELATÓRIO MENSAL DE LICENCIAMENTO AMBIENTAL"
    >
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">
            RMLA n. 01 / 2019
          </h3>

        </div>
        <div className="card-body">
          <table className="table">
            <tbody>
              <tr>
                <th>Órgão</th>
                <td>Secretaria de Estado de Meio Ambiente</td>
                <th>CNPJ</th>
                <td>{''}</td>
              </tr>
              <tr>
                <th>Empresa </th>
                <td>TUXTU Serviços, Estudos e Projetos Ltda.</td>
                <th>CNPJ</th>
                <td>13.288.423/0001-09</td>
              </tr>
              <tr>
                <th>Mês de Exercício </th>
                <td>Janeiro</td>
                <th>Ano de exercício</th>
                <td>2019</td>
              </tr>
              <tr>
                <th>Data de geração do relatório</th>
                <td colSpan="3">31.01.2019</td>
              </tr>
              <tr>
                <th>Data de aprovação do relatório</th>
                <td colSpan="3">31.01.2019</td>
              </tr>
              <tr>
                <th colSpan="2">Quantidade de processos conluídos no período do exercício</th>
                <td>X</td>
              </tr>
            </tbody>
          </table>
          <table className="table table-bordered table-hover">
            <thead>
              <tr>
                <th
                  className="text-center"
                  colSpan="4"
                >Discriminação</th>
              </tr>
              <tr>
                <th>Processo</th>
                <th>Data de Entrada</th>
                <th>Atividade</th>
                <th>Data de Saída</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr>
                <td />
                <td />
                <td />
                <td />
              </tr><tr>
                <td />
                <td />
                <td />
                <td />
              </tr><tr>
                <td />
                <td />
                <td />
                <td />
              </tr><tr>
                <td />
                <td />
                <td />
                <td />
              </tr><tr>
                <td />
                <td />
                <td />
                <td />
              </tr><tr>
                <td />
                <td />
                <td />
                <td />
              </tr>

            </tbody>
            <tfoot>
              <tr>
                <th colSpan="3">Valor de transporte de mobilidade urbana</th>
                <td>2354,66</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="card-footer text-center">
          <button
            className="btn btn-warning"
          >APROVAR RELATÓRIO</button>
        </div>
      </div>


    </Content>
  );
};

export default Relatorio;
