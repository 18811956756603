import React from 'react';
import styles from './styles';
import { View } from '@react-pdf/renderer';

export default function Row({ children, header }) {
  return (
    <View
      wrap={false}
      style={[
        styles.tableRow,
        header ? styles.tableHeader : {}
      ]}
    >
      {children}
    </View>
  );
}
