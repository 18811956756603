import React, { useEffect, useState } from 'react';
import { useParams,  Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { Loading } from '~/components';
import { BasicContent } from '../components';
// import { SubMenuList } from '../components/SubMenu';
// import { loadAtividades } from '~/store/modules/atividade/action';
import { showMotorista } from '~/store/modules/motorista/action';
import Create from '../../Atividade/Create';

const Show = () => {
  let history = useHistory();
  let location = useLocation();
  let modal = location.state && location.state.modal;
  const dispatch = useDispatch();
  const { motorista_id } = useParams();
  const [motoristaShow, setMotoristaShow] = useState({});

  const { motorista } = useSelector(({ MotoristaReducer }) => MotoristaReducer);


  useEffect(() => {
    dispatch(showMotorista(motorista_id,{
      with:'estado,municipio'
    }));
  }, [dispatch, motorista_id]);

  useEffect(() => {
    if (motorista.id === parseInt(motorista_id)) {
      setMotoristaShow(motorista);
    }
  }, [motorista, motorista_id]);

  const breadcrumb = {
    label: 'Detalhes',
    to: `motorista/${motorista_id}/detalhe`,
    active: false,
  };

  const handleClose = () => {
    history.goBack();
  };

  return (
    <>
      {modal && (
        <Switch>
          <Route
            children={<Create motorista_id={motorista_id}/>}
            exact
            path="/motorista/:motorista_id/atividade/novo"
          />
        </Switch>
      )}
      <BasicContent breadcrumb={breadcrumb}>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Detalhes</h2>
            <div className="card-tools">
              <button
                className="btn btn-default"
                onClick={handleClose}
              >
                <FaArrowAltCircleLeft /> Voltar
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              {motoristaShow.id === parseInt(motorista_id) ? (
                <table className="table table-hover table-striped">
                  <tbody>
                    <tr>
                      <th>id</th>
                      <td>{motorista.id}</td>
                    </tr>
                    <tr>
                      <th>Nome</th>
                      <td>{motorista.nome}</td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <Loading />
              )}
            </div>
          </div>
        </div>
      </BasicContent>
    </>
  );
};

export default Show;
