import * as actions from './actionType';

export const loadListaDocumentos = (params) => ({
  type: actions.LOAD_LISTA_DOCUMENTOS,
  params
});
export const getListaDocumentos = lista_documentos => ({
  type: actions.LISTA_DOCUMENTOS_DATA,
  lista_documentos,
});

export const getSelectListaDocumento = lista_documentos => ({
  type: actions.SELECT_LISTA_DOCUMENTOS,
  lista_documentos,
});

export const storeListaDocumento = lista_documento => ({
  type: actions.STORE_LISTA_DOCUMENTO,
  lista_documento,

});
export const getListaDocumento = lista_documento => ({
  type: actions.GET_LISTA_DOCUMENTO,
  lista_documento,
});

export const removerListaDocumento = (id) => ({
  type: actions.REMOVER_LISTA_DOCUMENTO,
  id
});

export const deleteListaDocumento = (id) => ({
  type: actions.DELETE_LISTA_DOCUMENTO,
  id
});

export const showListaDocumento = id => ({
  type: actions.SHOW_LISTA_DOCUMENTO,
  id,
});

export const activeListaDocumento = id => ({
  type: actions.ACTIVE_LISTA_DOCUMENTO,
  id,
});

export const updateListaDocumento = (lista_documento, id) => ({
  type: actions.UPDATE_LISTA_DOCUMENTO,
  lista_documento,
  id
});

export const getErros = errors => ({
  type: actions.LISTA_DOCUMENTO_ERROR,
  errors,
});

export const changeStatus = status => ({
  type: actions.CHANGE_STATUS_CODE,
  status,
});
