import React from 'react';
import { Form } from 'react-bootstrap';

const FormCad = ({ handleSubmit, schema, meioAnalise, setMeioAnalise, errors }) => {
  const handleChange = event => {
    const { name, value, files } = event.target;
    setMeioAnalise({
      ...meioAnalise,
      [name]: files ? files[0] : value,
    });
  };

  return (
    <Form
      id="form_meioAnalise"
      onSubmit={handleSubmit}
      schema={schema}
    >
      <div className="form-group">
        <label htmlFor="nome"> Nome </label>
        <Form.Control
          id="nome"
          isInvalid={!!errors.nome}
          name="nome"
          onChange={handleChange}
          placeholder="Nome"
          type="text"
          value={meioAnalise.nome || ''}
        />
        <Form.Control.Feedback type="invalid">
          {errors.meioAnalise}
        </Form.Control.Feedback>
      </div>
      {/* <div className="form-group">
        <label htmlFor="perguntas_apia_atia"> Tem APIA e ATIA? </label>
        <Form.Control
          as="select"
          id="perguntas_apia_atia"
          isInvalid={!!errors.perguntas_apia_atia}
          name="perguntas_apia_atia"
          onChange={handleChange}
          placeholder="Tem APIA e ATIA?"
          value={String(Number(meioAnalise.perguntas_apia_atia))}
        >
          <option value="1"> SIM</option>
          <option value="0">NÃO</option>
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {errors.perguntas_apia_atia}
        </Form.Control.Feedback>
      </div> */}
    </Form>
  );
};

export default FormCad;
