import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import { FaArrowAltCircleLeft, FaCircleNotch } from 'react-icons/fa';
import { IoMdSend } from 'react-icons/io';
import Swal from 'sweetalert2';
import { BasicContent } from '../components';
import { Loading } from '~/components';
import formatForMask from '~/utils/formatForMask';
import { ProgressBar } from '../../Process/List/styles';
import ModalLegislacoes from '~/components/ModalLegislacoes';

import {
    AnaliseTecImpacto,
    MovimentacaoProcesso,
    CheckList,
    ProjetoGeralEmpreendimento,
    // MemorialEmpreendimento,
    IndicadoresAmbientais,
    MensagensProcesso,
} from '~/views/Process/components';
import { TaxaAmbiental } from '~/views/Process/Detalhe/components';

import {
    showProcesso,
    enviarParaDistribuicao,
    gerarCobranca,
} from '~/store/modules/processo/action';
import { storeAnalise } from '~/store/modules/analise/action';
import { loadIndicadoresAmbientais } from '~/store/modules/indicadorAmbiental/action';
import { Certidao } from '../../Process/Detalhe/components';

const Show = () => {
    let { processo_id } = useParams();
    let history = useHistory();
    const dispatch = useDispatch();
    const { processo, status_code } = useSelector(
        ({ ProcessoReducer }) => ProcessoReducer
    );
    const { indicador_processo } = useSelector(
        ({ IndicadorProcessoReducer }) => IndicadorProcessoReducer
    );
    const { indicadores_ambientais } = useSelector(
        ({ IndicadorAmbientalReducer }) => IndicadorAmbientalReducer
    );

    const [processoShow, setProcessoShow] = useState();
    const [projetoIsOk, setProjetoIsOk] = useState(false);
    const [indicadoresIsOk, setIndicadoresIsOk] = useState(false);
    const [docsTecIsOk, setDocsTecIsOk] = useState(false);
    const [showLegisModal, setShowLegisModal] = useState(false);
    const [loading, setLoading] = useState(false);
    // const [memorialIsOk, setMemorialIsOk] = useState(false);
    const [awaitingPayment, setAwaitingPayment] = useState(false);
    const newFlowRef = useRef(false);

    useEffect(() => {
        const newFlowDate = new Date(process.env.REACT_APP_NEW_FLOW_DATE);
        const dateProcess = new Date(processoShow?.created_at);
        if (newFlowDate.getTime() > dateProcess.getTime()) {
            newFlowRef.current = true;
        } else {
            newFlowRef.current = false;
        }
    });

    useEffect(() => {
        if (status_code === 200 && awaitingPayment) {
            Swal.fire('Sucesso', 'Seu processo aguarda pagamento.').then(() => {
                history.go(`/analise-processo/minhas-analises/${processo_id}`);
            });
        }
    }, [history, processo_id, status_code, awaitingPayment]);

    useLayoutEffect(() => {
        dispatch(
            showProcesso(processo_id, {
                with:
                    'analise_previa,' +
                    'pessoa,' +
                    'estado,' +
                    'documentos,' +
                    'municipio,' +
                    'pessoa_responsavel_tecnico,' +
                    'tipo_licenca,' +
                    'status_processo,' +
                    'porte_empreendimento,' +
                    'atividade.legislacao_atividade;deleted_at is null,' +
                    'projeto,' +
                    'user_avaliador.pessoa_tecnico_profissional,' +
                    'entidade,' +
                    'grupo_atividades',
            })
        );
    }, [processo_id, dispatch]);

    useEffect(() => {
        dispatch(
            loadIndicadoresAmbientais({
                where: `processo_id:${processo_id}`,
            })
        );
    }, [processo_id, dispatch, indicador_processo]);

    useEffect(() => {
        if (processo?.id === parseInt(processo_id)) {
            setProcessoShow(processo);
        }
    }, [processo, processo_id]);

    useEffect(() => {
        if (indicadores_ambientais && indicadores_ambientais.length > 0) {
            indicadores_ambientais.forEach((indicador) => {
                if (indicador.valor) {
                    setIndicadoresIsOk(true);
                }
            });
        }
    }, [indicadores_ambientais]);

    const handleStartAnalise = async () => {
        try {
            let result = await Swal.fire({
                title: 'Iniciar Análise?',
                text: 'Deseja Iniciar Análise Prévia de Impacto Ambiental?!',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Sim!',
                cancelButtonText: 'Não',
            });
            if (result.value) {
                await dispatch(
                    storeAnalise({ processo_id, analise_previa: true })
                );
                await Swal.fire(
                    'Sucesso',
                    'Analise iniciada com sucesso!',
                    'success'
                );
                await history.go();
            }
        } catch (error) {
            await Swal.fire('Erro', 'Erro ao Iniciar Analise', 'error');
        }
    };
    const handleContinuar = async () => {
        try {
            let confirm = await Swal.fire({
                title: 'Continuar com o processo?',
                text: 'Será gerada sua taxa de pagamento.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim!',
                cancelButtonText: 'Não',
            });
            if (confirm.value) {
                setLoading(true);
                await dispatch(gerarCobranca(processo_id));
                setAwaitingPayment(true);
            }
        } catch (error) {
            await Swal.fire('Erro', 'Erro Gerar Taxa!', 'error');
            // console.error('Erro ao gerar texa: ', error);
        }
    };
    const handleCancelar = async () => {
        try {
            let confirm = await Swal.fire({
                title: 'Cancelar com o processo?',
                text: 'Deseja cancelar com seu processo?!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim!',
                cancelButtonText: 'Não',
            });
            if (confirm.value) {
                //alert('ok');
            }
        } catch (err) {
            //console.log(err);
        }
    };

    const handleEnviarDistribuicao = async () => {
        try {
            let result = await Swal.fire({
                title: 'Enviar para Distribuição?',
                text: 'Deseja enviar para central de distribuição de processo?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Sim!',
                cancelButtonText: 'Não',
            });
            if (result.value) {
                await dispatch(enviarParaDistribuicao(processo_id));
                await Swal.fire('Sucesso', 'Enviado com sucesso!', 'success');
                history.go();
            }
        } catch (error) {
            await Swal.fire('Erro', 'Erro ao Iniciar Analise', 'error');
        }
    };

    return (
        <BasicContent
            breadcrumb={{
                label: 'Minhas APIAs',
                to: '/analise-processo/minhas-analises',
                active: false,
            }}
            title="Detalhes Processo."
        >
            <ModalLegislacoes
                showLegisModal={showLegisModal}
                legislacoes={
                    processo && processo.atividade
                        ? processo.atividade.legislacao_atividade
                        : []
                }
                closeModal={() => setShowLegisModal(false)}
            />

            <div className="card">
                {processoShow &&
                processoShow.protocolo &&
                processoShow.pessoa_responsavel_tecnico ? (
                    <>
                        <div className="card-header">
                            <h3 className="card-title">
                                PROCESSO:{' '}
                                <strong>{processoShow.protocolo}</strong>
                            </h3>
                            <div className="card-tools">
                                <Link
                                    className="btn btn-default"
                                    to="/analise-processo/minhas-analises"
                                >
                                    <FaArrowAltCircleLeft />
                                    &nbsp; Voltar
                                </Link>
                            </div>
                        </div>
                        <div className="card-body table-responsive">
                            <table className="table table-hover table-striped table-bordered table-sm">
                                <tbody>
                                    <tr>
                                        <th>Processo</th>
                                        <td>{processoShow.protocolo}</td>
                                    </tr>
                                    <tr>
                                        <th>Empreendimento</th>
                                        <td>
                                            {' '}
                                            {`${processoShow.pessoa?.nome} - ${
                                                processoShow.pessoa?.cnpj
                                                    ? `CNPJ: ${formatForMask(
                                                          processoShow.pessoa
                                                              .cnpj,
                                                          '##.###.###/####-##'
                                                      )}`
                                                    : ''
                                            } ${
                                                processoShow.pessoa?.cpf
                                                    ? `CPF: ${formatForMask(
                                                          processoShow.pessoa
                                                              .cpf,
                                                          '###.###.###-##'
                                                      )}`
                                                    : ''
                                            }`}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Porte Empreendimento</th>
                                        <td>
                                            {processoShow.porte_empreendimento &&
                                                processoShow
                                                    .porte_empreendimento?.nome}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Local</th>
                                        <td>
                                            {' '}
                                            {
                                                processoShow.municipio?.nome
                                            } - {processoShow.estado?.uf}
                                        </td>
                                    </tr>
                                    {processoShow.entidade?.cnae_group ? (
                                        processoShow.grupo_atividades && (
                                            <>
                                                <tr>
                                                    <th>
                                                        Atividade Principal
                                                        Licenciada
                                                    </th>
                                                    <td>
                                                        {' '}
                                                        {processoShow.atividade &&
                                                            processoShow
                                                                .atividade
                                                                ?.nome}
                                                    </td>
                                                </tr>
                                                {processoShow.grupo_atividades.map(
                                                    (grupo, index) => (
                                                        <tr key={index}>
                                                            <th>
                                                                Atividade(s)
                                                                Secundária(s)
                                                                Licenciada(s)
                                                            </th>
                                                            <td>
                                                                {' '}
                                                                {grupo.nome}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </>
                                        )
                                    ) : (
                                        <tr>
                                            <th>Atividade</th>
                                            <td>
                                                {' '}
                                                {processoShow.atividade &&
                                                    processoShow.atividade
                                                        ?.nome}
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <th>Endereço da atividade</th>
                                        <td>
                                            {processoShow.logradouro} -{' '}
                                            {processoShow.bairro} -{' '}
                                            {processoShow.numero_endereco
                                                ? processoShow.numero_endereco
                                                : 'Nº não informado'}{' '}
                                            - {processoShow.cep} -{' '}
                                            {processoShow.complemento
                                                ? processoShow.complemento
                                                : 'Sem complemento.'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Tipo de licença</th>
                                        <td>
                                            {' '}
                                            {processoShow.tipo_licenca?.nome}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Técnico Consultor</th>
                                        <td>
                                            {processoShow.pessoa_responsavel_tecnico &&
                                                processoShow
                                                    .pessoa_responsavel_tecnico
                                                    ?.responsavel_tecnico &&
                                                processoShow
                                                    .pessoa_responsavel_tecnico
                                                    ?.responsavel_tecnico
                                                    .pessoa_tecnico_profissional &&
                                                processoShow
                                                    .pessoa_responsavel_tecnico
                                                    ?.responsavel_tecnico
                                                    .pessoa_tecnico_profissional
                                                    .nome}
                                            {' - '}
                                            {processoShow.pessoa_responsavel_tecnico &&
                                            processoShow
                                                .pessoa_responsavel_tecnico
                                                ?.responsavel_tecnico &&
                                            processoShow
                                                .pessoa_responsavel_tecnico
                                                ?.responsavel_tecnico
                                                .pessoa_tecnico_profissional &&
                                            processoShow
                                                .pessoa_responsavel_tecnico
                                                ?.responsavel_tecnico
                                                .pessoa_tecnico_profissional
                                                .cnpj
                                                ? `CNPJ: ${formatForMask(
                                                      processoShow
                                                          .pessoa_responsavel_tecnico
                                                          ?.responsavel_tecnico
                                                          .pessoa_tecnico_profissional
                                                          .cnpj,
                                                      '##.###.###/####-##'
                                                  )}`
                                                : '' -
                                                  processoShow
                                                      .pessoa_responsavel_tecnico
                                                      ?.responsavel_tecnico
                                                      .pessoa_tecnico_profissional
                                                      .cpf
                                                ? `CPF: ${formatForMask(
                                                      processoShow
                                                          .pessoa_responsavel_tecnico
                                                          ?.responsavel_tecnico
                                                          .pessoa_tecnico_profissional
                                                          .cpf,
                                                      '###.###.###-##'
                                                  )}`
                                                : ''}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Endereço da Empresa</th>
                                        <td>
                                            {processoShow.pessoa?.logradouro},
                                            {processoShow.pessoa?.bairro} -
                                            {processoShow.pessoa?.cep} -
                                            {processoShow.pessoa?.cidade}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Legislações</th>
                                        <td>
                                            <button
                                                type="button"
                                                className="btn btn-default"
                                                onClick={() =>
                                                    setShowLegisModal(true)
                                                }
                                            >
                                                Visualizar
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Status</th>
                                        <td>
                                            <span className="badge badge-success">
                                                {
                                                    processoShow.status_processo
                                                        ?.nome
                                                }
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Progresso</th>
                                        <td className="project_progress">
                                            <div className="progress progress-sm">
                                                <ProgressBar
                                                    ariaVolumemax="100"
                                                    ariaVolumemin="0"
                                                    ariaVolumenow={
                                                        (processoShow.passo *
                                                            100) /
                                                        20
                                                    }
                                                    className="progress-bar bg-green"
                                                    percents={
                                                        (processoShow.passo *
                                                            100) /
                                                        20
                                                    }
                                                    role="progressbar"
                                                />
                                            </div>
                                            <small>
                                                {(processoShow.passo * 100) /
                                                    20}
                                                % completo
                                            </small>
                                        </td>
                                    </tr>
                                    {processoShow &&
                                        processoShow.status_processo &&
                                        processoShow.status_processo?.id ===
                                            8 && (
                                            <>
                                                {projetoIsOk &&
                                                    docsTecIsOk &&
                                                    ((processoShow.tipo_licenca
                                                        ?.has_apia_atia &&
                                                        indicadoresIsOk) ||
                                                        !processoShow
                                                            .tipo_licenca
                                                            ?.has_apia_atia) && (
                                                        <>
                                                            {newFlowRef.current ? (
                                                                <>
                                                                    <tr>
                                                                        <td
                                                                            className="text-center"
                                                                            colSpan={
                                                                                2
                                                                            }
                                                                        >
                                                                            <button
                                                                                className="btn btn-success m-3"
                                                                                onClick={
                                                                                    handleEnviarDistribuicao
                                                                                }
                                                                            >
                                                                                {' '}
                                                                                Enviar
                                                                                para
                                                                                central
                                                                                de
                                                                                distribuição
                                                                                de
                                                                                processo{' '}
                                                                                <IoMdSend />{' '}
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <tr>
                                                                        <th
                                                                            className="text-center"
                                                                            colSpan="2"
                                                                        >
                                                                            DESEJA
                                                                            CONTINUAR
                                                                            O
                                                                            LICENCIAMENTO
                                                                            AMBIENTAL?{' '}
                                                                            <br />
                                                                            GERAR
                                                                            TAXA
                                                                            PARA
                                                                            PAGAMENTO?
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th
                                                                            className="text-center"
                                                                            colSpan="2"
                                                                        >
                                                                            {loading ? (
                                                                                <FaCircleNotch className="spinner" />
                                                                            ) : (
                                                                                <div className="btn btn-group">
                                                                                    <button
                                                                                        className="btn btn-success"
                                                                                        onClick={
                                                                                            handleContinuar
                                                                                        }
                                                                                    >
                                                                                        Sim
                                                                                    </button>
                                                                                    <button
                                                                                        className="btn btn-danger"
                                                                                        onClick={
                                                                                            handleCancelar
                                                                                        }
                                                                                    >
                                                                                        Não
                                                                                    </button>
                                                                                </div>
                                                                            )}
                                                                        </th>
                                                                    </tr>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                            </>
                                        )}
                                </tbody>
                            </table>
                        </div>
                    </>
                ) : (
                    <Loading />
                )}
            </div>

            <div className="row">
                <Certidao processo={processoShow} />
                {processoShow?.status_processo_id === 5 &&
                    !newFlowRef.current && (
                        <TaxaAmbiental processo={processoShow} />
                    )}
                {processoShow &&
                    processoShow.status_processo &&
                    processoShow.status_processo.id >= 8 && (
                        <>
                            <div className="col-md-4">
                                <ProjetoGeralEmpreendimento
                                    processo_id={processoShow.id}
                                    projeto={processoShow.projeto}
                                    setProjetoIsOk={setProjetoIsOk}
                                />
                            </div>
                            {/* <div className="col-md-4">
              <MemorialEmpreendimento
                processo_id={processoShow.id}
                setMemorialIsOk={setMemorialIsOk}
                tipo_licenca_id={processoShow.tipo_licenca.id}
              />
            </div> */}
                        </>
                    )}
                {processoShow && processoShow.tipo_licenca?.has_apia_atia && (
                    <div className="col-md-4">
                        {processoShow ? (
                            <>
                                {!processoShow.analise_previa ? (
                                    <>
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title">
                                                    Análise Prévia de Impacto
                                                    Ambiental
                                                </h3>
                                            </div>
                                            <div className="card-body">
                                                <button
                                                    className="btn btn-success btn-block"
                                                    onClick={handleStartAnalise}
                                                >
                                                    Iniciar Análise Prévia de
                                                    Impacto Ambiental
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {processoShow.tipo_licenca && (
                                            <AnaliseTecImpacto
                                                analise_id={
                                                    processoShow.analise_previa
                                                        .id
                                                }
                                                analise_previa
                                                processo_id={processoShow.id}
                                                tipo_licenca_id={
                                                    processoShow.tipo_licenca.id
                                                }
                                            />
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            <Loading />
                        )}
                    </div>
                )}

                <div className="col-md-4">
                    <MovimentacaoProcesso processo_id={processo_id} />
                </div>

                <div className="col-md-4">
                    <CheckList
                        // lista
                        // processo_id={processo_id}
                        setDocsTecIsOk={setDocsTecIsOk}
                    />
                </div>
                {processoShow && processoShow.tipo_licenca?.has_apia_atia && (
                    <>
                        {processoShow &&
                            indicadores_ambientais &&
                            processoShow.status_processo &&
                            processoShow.status_processo.id >= 8 && (
                                <div className="col-md-4">
                                    <IndicadoresAmbientais
                                        actions={
                                            processoShow.status_processo.id >= 9
                                                ? false
                                                : true
                                        }
                                        indicadores_ambientais={
                                            indicadores_ambientais
                                        }
                                        processo_id={processoShow.id}
                                    />
                                </div>
                            )}
                    </>
                )}

                <div className="col-md-4">
                    <MensagensProcesso processo={processoShow} />
                </div>
            </div>
        </BasicContent>
    );
};

export default Show;
