import React from 'react';
import { Row, Col, Tabs, Tab, Form } from 'react-bootstrap';
import FileInput from '~/components/FileInput';
import styles from './styles';
import maskCEP from '~/utils/formatCep'
import formatPhone from '~/utils/formatPhone'
import formatCpfCnpj from '~/utils/formatCpfCnpj'

export default function FieldsPT({
  fields, setFields, ufData,
  handleBuscaCep, handleChange
}) {
  return (
    <Tabs defaultActiveKey="dados_pessoais">
      <Tab title="Dados Pessoais" eventKey="dados_pessoais" style={styles.tab}>
        <Row>
          <Form.Group as={Col} md="6">
            <Form.Label>Nome Completo</Form.Label>
            <Form.Control
              // isInvalid={!!errors.nome}
              name="nome"
              // onChange={handleChange}
              placeholder="Nome"
              type="text"
              value={fields.nome || ''}
              readOnly
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.nome}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              // isInvalid={!!errors.email}
              name="email"
              onChange={handleChange}
              placeholder="E-mail"
              type="email"
              value={fields.email || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>CPF/CNPJ (Somente números)</Form.Label>
            <Form.Control
              // isInvalid={!!errors.cpf_cnpj}
              maxLength="18"
              name="cpf_cnpj"
              // onChange={handleChange}
              placeholder="CPF/CNPJ"
              type="text"
              value={formatCpfCnpj(fields.cpf_cnpj ?? '') || ''}
              readOnly
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.cpf_cnpj}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Anexo</Form.Label>
            <FileInput
              // dataError={errors.anexo_cpf_cnpj}
              name="anexo_cpf_cnpj"
              data={fields}
              // setData={setFields}
              disabled
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.anexo_cpf_cnpj}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>RG UF</Form.Label>
            <Form.Control
              as="select"
              // isInvalid={!!errors.uf_rg_id}
              name="uf_rg_id"
              // onChange={handleChange}
              placeholder="UF RG"
              type="text"
              value={fields.uf_rg_id || ''}
              disabled
            >
              {ufData[0] && ufData.map(uf =>
                <option key={uf.id} value={uf.id}>
                  {uf.uf}
                </option>)}
            </Form.Control>
            {/* <Form.Control.Feedback type="invalid">
              {errors.uf_rg_id}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>RG (Somente números)</Form.Label>
            <Form.Control
              // isInvalid={!!errors.rg}
              name="rg"
              // onChange={handleChange}
              placeholder="RG (Somente números)"
              type="text"
              value={fields.rg || ''}
              readOnly
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.rg}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>Anexo</Form.Label>
            <FileInput
              // dataError={errors.anexo_rg}
              name="anexo_rg"
              data={fields}
              // setData={setFields}
              disabled
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.anexo_rg}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>Data Nascimento</Form.Label>
            <Form.Control
              // isInvalid={!!errors.data_nasc}
              name="data_nasc"
              // onChange={handleChange}
              placeholder="Data Nascimento"
              type="date"
              value={fields.data_nasc || ''}
              readOnly
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.data_nasc}
            </Form.Control.Feedback> */}
          </Form.Group>

          <Form.Group as={Col} md="4">
            <Form.Label>Fone de contato</Form.Label>
            <Form.Control
              // isInvalid={!!errors.telefone}
              name="telefone"
              onChange={handleChange}
              placeholder="Fone de contato"
              type="text"
              maxLength="15"
              value={formatPhone(fields.telefone ?? '') || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.telefone}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>Número de WhatsApp</Form.Label>
            <Form.Control
              // isInvalid={!!errors.numero_whatsapp}
              name="numero_whatsapp"
              onChange={handleChange}
              placeholder="Número de WhatsAp"
              type="text"
              maxLength="15"
              value={formatPhone(fields.numero_whatsapp ?? '') || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.numero_whatsapp}
            </Form.Control.Feedback> */}
          </Form.Group>
        </Row>
      </Tab>
      <Tab title="Endereço" eventKey="endereco" style={styles.tab}>
        <Row>
          <Form.Group as={Col} md="2">
            <Form.Label>CEP</Form.Label>
            <Form.Control
              // isInvalid={!!errors.cep}
              name="cep"
              onBlur={handleBuscaCep}
              onChange={handleChange}
              placeholder="CEP"
              type="text"
              maxLength="9"
              value={maskCEP(fields.cep ?? '') || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.cep}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>Logradouro</Form.Label>
            <Form.Control
              // isInvalid={!!errors.logradouro}
              name="logradouro"
              onChange={handleChange}
              placeholder="Logradouro"
              type="text"
              value={fields.logradouro || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.logradouro}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="2">
            <Form.Label>Número</Form.Label>
            <Form.Control
              // isInvalid={!!errors.numero_endereco}
              name="numero_endereco"
              onChange={handleChange}
              placeholder="Número"
              type="text"
              value={fields.numero_endereco || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.numero_endereco}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>Bairro</Form.Label>
            <Form.Control
              // isInvalid={!!errors.bairro}
              name="bairro"
              onChange={handleChange}
              placeholder="Bairro"
              type="text"
              value={fields.bairro || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.bairro}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>Cidade</Form.Label>
            <Form.Control
              // isInvalid={!!errors.cidade}
              name="cidade"
              onChange={handleChange}
              placeholder="Cidade"
              type="text"
              value={fields.cidade || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.cidade}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>Estado</Form.Label>
            <Form.Control
              as="select"
              // isInvalid={!!errors.uf_endereco_id}
              name="uf_endereco_id"
              onChange={handleChange}
              placeholder="UF"
              type="text"
              value={fields.uf_endereco_id || ''}
            >
              {ufData[0] && ufData.map(uf =>
                <option key={uf.id} value={uf.id}>
                  {uf.uf}
                </option>)}
            </Form.Control>
            {/* <Form.Control.Feedback type="invalid">
              {errors.uf_endereco_id}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>Anexo</Form.Label>
            <FileInput
              // dataError={errors.anexo_endereco}
              name="anexo_endereco"
              data={fields}
              setData={setFields}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.anexo_endereco}
            </Form.Control.Feedback> */}
          </Form.Group>
        </Row>
      </Tab>
      <Tab title="Formações" eventKey="formacoes" style={styles.tab}>
        <Row>
          <Form.Group as={Col} md="6">
            <Form.Label>Formação</Form.Label>
            <Form.Control
              // isInvalid={!!errors.formacao}
              name="formacao"
              onChange={handleChange}
              placeholder="Formação"
              type="text"
              value={fields.formacao || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.formacao}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Anexo</Form.Label>
            <FileInput
              // dataError={errors.anexo_formacao}
              name="anexo_formacao"
              data={fields}
              setData={setFields}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.anexo_formacao}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Formação 2 (caso tenha)</Form.Label>
            <Form.Control
              // isInvalid={!!errors.formacao_2}
              name="formacao_2"
              onChange={handleChange}
              placeholder="Formação 2 (caso tenha)"
              type="text"
              value={fields.formacao_2 || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.formacao_2}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Anexo</Form.Label>
            <FileInput
              // dataError={errors.anexo_formacao_2}
              name="anexo_formacao_2"
              data={fields}
              setData={setFields}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.anexo_formacao_2}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Especialização</Form.Label>
            <Form.Control
              // isInvalid={!!errors.especializacao}
              name="especializacao"
              onChange={handleChange}
              placeholder="Especialização"
              type="text"
              value={fields.especializacao || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.especializacao}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Anexo</Form.Label>
            <FileInput
              // dataError={errors.anexo_especializacao}
              name="anexo_especializacao"
              data={fields}
              setData={setFields}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.anexo_especializacao}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Mestrado</Form.Label>
            <Form.Control
              // isInvalid={!!errors.mestrado}
              name="mestrado"
              onChange={handleChange}
              placeholder="Mestrado"
              type="text"
              value={fields.mestrado || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.mestrado}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Anexo</Form.Label>
            <FileInput
              // dataError={errors.anexo_mestrado}
              name="anexo_mestrado"
              data={fields}
              setData={setFields}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.anexo_mestrado}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Doutorado</Form.Label>
            <Form.Control
              // isInvalid={!!errors.doutorado}
              name="doutorado"
              onChange={handleChange}
              placeholder="Doutorado"
              type="text"
              value={fields.doutorado || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.doutorado}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Anexo</Form.Label>
            <FileInput
              // dataError={errors.anexo_doutorado}
              name="anexo_doutorado"
              data={fields}
              setData={setFields}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.anexo_doutorado}
            </Form.Control.Feedback> */}
          </Form.Group>
        </Row>
      </Tab>
      <Tab title="Redes Sociais" eventKey="redes_sociais" style={styles.tab}>
        <Row>
          <Form.Group as={Col} md="3">
            <Form.Label>Youtube</Form.Label>
            <Form.Control
              // isInvalid={!!errors.youtube}
              name="youtube"
              onChange={handleChange}
              placeholder="Youtube"
              type="text"
              value={fields.youtube || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.youtube}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="3">
            <Form.Label>Facebook</Form.Label>
            <Form.Control
              // isInvalid={!!errors.facebook}
              name="facebook"
              onChange={handleChange}
              placeholder="Facebook"
              type="text"
              value={fields.facebook || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.facebook}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="3">
            <Form.Label>Linkdin</Form.Label>
            <Form.Control
              // isInvalid={!!errors.linkdin}
              name="linkdin"
              onChange={handleChange}
              placeholder="Linkdin"
              type="text"
              value={fields.linkdin || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.linkdin}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="3">
            <Form.Label>Twiter</Form.Label>
            <Form.Control
              // isInvalid={!!errors.twiter}
              name="twiter"
              onChange={handleChange}
              placeholder="Twiter"
              type="text"
              value={fields.twiter || ''}
            />
            {/* <Form.Control.Feedback type="invalid">
              {errors.twiter}
            </Form.Control.Feedback> */}
          </Form.Group>
        </Row>
      </Tab>
    </Tabs>
  );
}
