import * as Yup from 'yup';

const schema = Yup.object().shape({
  responsavel_tecnico_id: Yup.number().integer().required('Valor requerido'),
  pode_elaboracao_e_estudo_projeto: Yup.boolean().required('Valor requerido'),
  pode_elaboracao_orcamento: Yup.boolean().required('Valor requerido'),
  pode_implantacao_estudo_e_projeto: Yup.boolean().required('Valor requerido'),
  pode_execucao_estudo_e_projeto: Yup.boolean().required('Valor requerido'),
  pode_fiscalizacao_estudo_e_projeto: Yup.boolean().required('Valor requerido'),
  pode_monitoramento_estudo_e_projeto: Yup.boolean().required('Valor requerido'),
});

export default schema;
