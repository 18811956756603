import * as Yup from 'yup';

const schema = Yup.object().shape({
  nome: Yup.string().required('Valor requerido'),
  especifica: Yup.boolean().required(),
  atividade_id: Yup.number()
    .when('especifica', {
      is: true,
      then: Yup.number().typeError('Valor requerido'),
      otherwise: Yup.number().nullable()
    }),
  tipo_licenca_id: Yup.number()
    .when('atividade_id', {
      is: (value) => !!value === true,
      then: Yup.number().typeError('Valor requerido'),
      otherwise: Yup.number().nullable()
    }),
  meio_analise_id: Yup.number()
    .when('tipo_licenca_id', {
      is: (value) => !!value === true,
      then: Yup.number().typeError('Valor requerido'),
      otherwise: Yup.number().nullable()
    })
});


export default schema;
