import React from 'react';
import { Content } from '~/layouts/Dash/components';


const BasicContent = ({breadcrumb, children}) => {

  const breadcrumbs = [
    ...breadcrumb
  ];
  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Meio de analise"
    >
      {children}
    </Content>
  );
}

export default BasicContent;
