import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormEdit } from '../components';
import Swal from 'sweetalert2';
import schema from './schema'
import { loadMotoristas, updateMotorista, showMotorista } from '~/store/modules/motorista/action';
import { Loading } from '~/components';
import removeMask from '~/utils/removeMask';

const Edit = ({entidade_id}) => {
  let history = useHistory();
  const [motorista_edit, setMotoristaEdit] = useState(null);
  const [saved, setSaved] = useState(false);
  const { motorista_id }  = useParams();
  const [errorsValidate, setErrorsValidate] = useState([]);
  const dispatch = useDispatch();
  const { status_response, errors, motorista } = useSelector(({ MotoristaReducer }) => MotoristaReducer);

  useEffect(() => {
    dispatch(showMotorista(motorista_id));
  }, [dispatch, motorista_id]);


  useEffect(() => {
    // console.log('errors Edit: ',errors);
    if (errors.status && errors.status === 422) {
      let server_errors = {};
      const response_errors = errors.data.errors;
      for (let name of Object.keys(response_errors)) {
        Object.assign(server_errors, { [name]: response_errors[name][0] });
      }
      setErrorsValidate(server_errors);
    }
    if(errors.update) {
      setSaved(errors.update);
    }
  }, [errors]);

  useEffect(() => {
    // console.log('Sucesso Edit motorista: ',status_response);
    if (motorista !== {}) {
      setMotoristaEdit({
        nome: motorista.nome,
        cpf: motorista.cpf,
      });
    }
  }, [motorista, status_response]);

  useEffect(() => {
    // console.log('Sucesso Edit Motorista: ',status_response);
    // console.log('Sucesso Edit Motorista: ',status_response);
    if (saved && status_response === 200) {
      Swal.fire('Sucesso', 'Registro editado com sucesso!', 'success').then(
        () => {
          history.goBack();
          dispatch(loadMotoristas({
            where:`entidade_id:${entidade_id}`,
          }));
        }
      );
    }
  }, [status_response, history, dispatch, saved, entidade_id]);


  const handleClose = () => {
    history.goBack();
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setErrorsValidate([]);
    if (!schema.isValidSync(motorista_edit)) {
      let yup_errors = {};
      schema.validate(motorista_edit, { abortEarly: false }).catch(err => {
        for (let error of err.inner) {
          const { path, message } = error;
          Object.assign(yup_errors, { [path]: message });
        }
        setErrorsValidate(yup_errors);
      });
    } else {
      const motora = motorista_edit;
      motora.entidade_id = parseInt(entidade_id);
      motora.cpf = removeMask(motora.cpf)
      // console.log('atividade_edit update: ',motora);
      await dispatch(updateMotorista(motora, motorista_id));
    }
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-xl"
      onHide={handleClose}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Motorista
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">Editar</h2>
          </div>
          <div className="card-body">
            {
              motorista_edit?
                (
                  <FormEdit
                    errors={errorsValidate}
                    handleSubmit={handleSubmit}
                    motorista={motorista_edit}
                    schema={schema}
                    setMotorista={setMotoristaEdit}
                  />
                ):
                (
                  <Loading />
                )
            }
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-default"
          onClick={handleClose}
        >
          <i className="fa fa-window-close" /> Cancelar
        </button>
        <button
          className="btn btn-success"
          form="form_motorista"
          type="submint"
        >
          {' '}
          <i className="fa fa-save" /> Editar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Edit;
